import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


const useAddFinancials = () => {
  const {stateApp} = useAppInfo()
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addFinancials = async (data,dataName) => {
    try {
    const requests = axios.post(`${BASE_URL}/addFinancials/`, {
          userId,
          username,
          data,
          dataName,
        })

      await Promise.all(requests);
      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addFinancials, error };
};

export default useAddFinancials;
import {
  Button,
  Paper,
  Typography,
  ClickAwayListener,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import { MenuOpen } from '@mui/icons-material';
import { navLinks } from 'nav/menu';
import LeftSidebarMainExpanded from './LeftSidebarMainExpanded';
import { createPortal } from 'react-dom';
import { throttle } from 'lodash';
import Logo from 'logo/Logo';
import ProfileSettings from 'nav/ProfileSettings';

export default function LeftSidebarMain() {
  const { dispatchSidebar, stateSidebar } = useAppInfo();
  const { sidebarLeftExpanded } = stateSidebar;
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.split('/')[1];
  const subPage = location.pathname.split('/')[2];
  const [currentSubPage, setCurrentSubPage] = useState();

  useEffect(() => {
    const currentPage = navLinks.find((page) => page.link === pageName);
    const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
    setCurrentSubPage(currentSubPage);
  }, [pageName, subPage]);

  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);
  const [navMenu, setNavMenu] = useState([]);
  const [paperPosition, setPaperPosition] = useState({ top: 0, left: 0 });

  const handleSidebar = (v) => {
    navigate(`${v}`);
    setNavMenu([]);
    setHoveredLinkIndex(null);
  };

  useEffect(() => {
    if (hoveredLinkIndex !== null) {
      const navDetails = navLinks[hoveredLinkIndex];
      setNavMenu(navDetails?.menu || []);
  
      const hoveredLink = document.getElementById(`link-${hoveredLinkIndex}`);
      if (hoveredLink) {
        const { top, left, height, width } = hoveredLink.getBoundingClientRect();
        const paperWidth = 200; // Assuming Paper width is 200px
        const paperHeight = 400; // Assuming Paper height is 200px
  
        let newLeft = left + width; // Position to the right of the link with a 10px margin
        let newTop = top;
  
        // Adjust horizontally if the Paper goes beyond the right edge
        if (newLeft + paperWidth > window.innerWidth) {
          newLeft = left - paperWidth - 0; // Position to the left of the link
        }
  
        // Adjust vertically if the Paper goes below the bottom edge
        if (newTop + paperHeight > window.innerHeight) {
          newTop = Math.max(0, window.innerHeight - paperHeight - 10); // Position at the maximum top that fits within the viewport
        }
  
        setPaperPosition({ top: newTop, left: newLeft });
      }
    }
  }, [hoveredLinkIndex]);

  // Throttled hover handler
  const handleHover = useCallback(
    throttle((index) => {
      setHoveredLinkIndex(index);
    }, 200), // 200ms throttle
    []
  );

  const handleMouseLeave = () => {
    setHoveredLinkIndex(null);
  };




  return (
    <>
      <div id="leftSidebarMain" className={!sidebarLeftExpanded && "left-sidebar"}>
        {!sidebarLeftExpanded && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                padding: '0rem',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              {/* <Logo/> */}

              <Button
                sx={{
                  minWidth: '0',
                  padding: '0',
                }}
                onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
              >
                <MenuOpen style={{ transform: 'rotate(180deg)' }} />
              </Button>
              {navLinks.map((option, index) => (
                <React.Fragment key={index}>
                  <Button
                    id={`link-${index}`}
                    aria-describedby={option.name}
                    onPointerEnter={() => handleHover(index)}
                    onMouseEnter={() => handleHover(index)}
                    onClick={() => {
                      if (!option.menu) {
                        handleSidebar(option.link);
                      }
                    }}
                    sx={{
                      position: 'relative',
                      minWidth: '0',
                      width: '100%',
                      flexDirection: 'column',
                      textTransform: 'none',
                      background: option.link === pageName && '#F8F8F8',
                      color: option.link !== pageName && '#ddd',
                      padding: '0.5rem',
                      borderRadius: 0,
                      '&:hover': {background:option.link === pageName ? '#F8F8F8' :"#fafafa",color:option.link !== pageName && 'black'}
                    }}
                  >
                   {option?.icon}  
                  </Button>
                </React.Fragment>
              ))}
              <div
                style={{
                  marginTop: 'auto',
                  paddingBottom: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/* <Logo/> */}
                <ProfileSettings/>
              </div>
            </div>
            
            {hoveredLinkIndex !== null && navMenu.length > 0 && (
              createPortal(
                <ClickAwayListener onClickAway={handleMouseLeave}>
                  <Paper
                    elevation={8}
                    onMouseLeave={handleMouseLeave}
                    onPointerLeave={handleMouseLeave}
                    style={{
                      position: 'absolute',
                      top: `${paperPosition.top}px`,
                      left: `${paperPosition.left}px`,
                      zIndex: 100000000,
                      minHeight: '60px',
                      background: 'white',
                      boxShadow: 'var(--boxShadowGeneral)',
                      boxSizing: 'border-box',
                      minWidth: '200px',
                      width: 'auto',
                      transition: 'ease-in-out',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.25rem',
                        padding: '0.5rem',
                        minWidth: '200px',
                      }}
                    >
                      <Typography variant="h6" sx={{ padding: '0.5rem' }}>
                        {navLinks[hoveredLinkIndex]?.name}
                      </Typography>
                      {navMenu.map((obj) => (
                        <Button
                          key={obj.name}
                          sx={{
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                            display: 'flex',
                            gap: '1rem',
                            color:
                              obj.name === currentSubPage?.name
                                ? 'var(--selectBlue)'
                                : '#5C5C5C',
                          }}
                          onClick={() =>
                            handleSidebar(
                              `${navLinks[hoveredLinkIndex]?.link}/${obj?.link}`
                            )
                          }
                        >
                          {obj?.icon}
                          {obj.name}
                        </Button>
                      ))}
                    </div>
                  </Paper>
                </ClickAwayListener>,
                document.body
              )
            )}
          </>
        )}

{(sidebarLeftExpanded) && 
        <LeftSidebarMainExpanded />}
        {/* {(sidebarLeftExpanded && !["Home",""].includes(pageName)) && 
        createPortal(<LeftSidebarMainExpanded />,document.body)
        } */}
      </div>
    </>
  );
}


{/* <Popover
id={id}
open={open}
anchorEl={anchorEl}
onClose={handleClose}
onMouseLeave={handleClose}
anchorOrigin={{
  vertical: 'center',
  horizontal: 'right',
}}
>

</Popover>  */}
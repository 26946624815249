import React, { useState } from 'react';

const Sparkline = ({ data, width = "200", height = "50", color, averageLine = true, area = false }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipValue, setTooltipValue] = useState(null);

  // Calculate the scaling factors for the sparkline
  const minValue = Math.min(...data);
  const maxValue = Math.max(...data);
  const xScale = width / (data.length - 1);
  const yScale = height / (maxValue - minValue);

  // Create an array of points for the sparkline
  const points = data.map((value, index) => {
    const x = index * xScale;
    const y = height - (value - minValue) * yScale;
    return { x, y, value };
  });

  // Calculate the median value
  const medianValue = data.reduce((acc, val) => acc + val, 0) / data.length;

  // Create the SVG path string for the sparkline
  const pathString = `M${points.map(point => `${point.x},${point.y}`).join(' L')}`;

  // Create the SVG path string for the area fill
  const areaPathString = `${pathString} L${width},${height} L0,${height} Z`;

  // Create the SVG path string for the median line
  const medianLineString = `M0,${height - (medianValue - minValue) * yScale} L${width},${height - (medianValue - minValue) * yScale}`;

  const handleMouseEnter = (e, index) => {
    setHoveredIndex(index);
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    setTooltipPosition({ x, y });
    setTooltipValue(data[index]);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
    setTooltipValue(null);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        onMouseLeave={handleMouseLeave}
      >
        {/* Area Fill */}
        {area && (
          <path
            d={areaPathString}
            fill={`url(#gradient-${color})`}
          />
        )}

        {/* Gradient Definition */}
        <defs>
          <linearGradient id={`gradient-${color}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: color, stopOpacity: 0.4 }} />
            <stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
          </linearGradient>
        </defs>

        {/* Sparkline */}
        <path
          d={pathString}
          fill="none"
          stroke={color}
          strokeWidth="2"
          onMouseMove={(e) => {
            const index = Math.floor(e.nativeEvent.offsetX / xScale);
            handleMouseEnter(e, index);
          }}
        />

        {/* Median Line */}
        {averageLine && 
        <path d={medianLineString} fill="none" stroke="black" strokeWidth="1" strokeDasharray="4" />
        }

        {/* Hovered data */}
        {hoveredIndex !== null && points[hoveredIndex] && (
          <>
            <circle
              cx={points[hoveredIndex].x}
              cy={points[hoveredIndex].y}
              r="4"
              fill={color}
              stroke="black"
              strokeWidth="1"
              style={{ zIndex: 1 }}
            />
          </>
        )}
      </svg>

      {/* Tooltip */}
      {tooltipValue !== null && (
        <div
          style={{
            position: 'absolute',
            left: tooltipPosition.x,
            top: tooltipPosition.y - 30,
            backgroundColor: 'white',
            padding: '4px 8px',
            border: '1px solid black',
            borderRadius: '4px',
            pointerEvents: 'none',
            transform: 'translate(-50%, -100%)',
            whiteSpace: 'nowrap',
            zIndex:"10000"
          }}
        >
          {tooltipValue?.toFixed(2)}
        </div>
      )}
    </div>
  );
};

export default Sparkline;



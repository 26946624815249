import React,{useEffect, useMemo, useState} from 'react'
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import { useAppInfo } from 'AppState';
import { formatNumber } from 'utils/utilGeneral';
import useFinancialMetricExchangeRate from './useFinancialMetricExchangeRate';


export default function PeerFundamentalTable({ data, metrics,growthMode}) {
  
  const {stateTicker} = useAppInfo()
  const peerSelected = stateTicker.peerSelected;
  
  const exRate = useFinancialMetricExchangeRate({metric:metrics})
  

  const rowData = useMemo(() => {
    // Sort dates
    const sortedDates = Array.from(new Set(data.map(obj => obj[dfin.period]))).sort();

    return peerSelected.map(ticker => {
      const row = { TICKER: ticker };
      sortedDates.forEach(date => {
        const obj = data.find(item => item[dfin.ticker] === ticker && item[dfin.period] === date);
        row[date] = obj ? obj[metrics] * obj[exRate] : null;
      });
      return row;
    });
  }, [peerSelected, data, metrics, exRate]);
    
    return (
      <TableGen trend logo 
      growthRate={{show:true,type:growthMode?"main":"sub"}}
      data={rowData} tableLayout='auto' height='auto'/>  
    )
  }
  
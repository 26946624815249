import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import React, { useEffect, useState } from 'react'

export default function TextCompany({onChange,item}) {
    const {stateStock} = useAppInfo()
    const [textCoValue, setTextCoValue] = useState();
    const {coInfo} = stateStock
    useEffect(() => {    
        const textCoValue =
          (item.ContentType === "Name" && coInfo?.[dfin.coName]) ||
          (item.ContentType === "Ticker" && coInfo?.[dfin.ticker]) ||
          (item.ContentType === "BusinessDescription" &&
            coInfo?.[dfin.businessDescription]);
        setTextCoValue(textCoValue);
      }, [coInfo]);

  return (
    <>
    {coInfo && <textarea
    id="textReport"
    // id={`${item.id}`}
    defaultValue={textCoValue}
    onChange={(e) => onChange(e.target.value)}
    onMouseDown={(e) => e.stopPropagation()}
    style={{
      // overflow:"hidden",
      wordBreak: "break-word",
      resize: "none",
      boxSizing: "border-box",
      outline: "none",
      border: "none",
      overflow:"hidden",
      ...item.style,
      width: "100%",
      height: "100%",
    }}
  />}
  </>
  )
}

import React from 'react';
import { useAppInfo } from 'AppState';
import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';


export default function PortfolioValue() {
  const {statePortfolio} = useAppInfo()
  const tOverview = statePortfolio.portfolioOverview
  const {portfolioValue} = statePortfolio


  return (
    <>
    <>
    {portfolioValue.length === 0 && <LoadingSkeletonChart chartType='area'/>}
    {portfolioValue.length>0 && 
    <TimeSeriesChart seriesName='Portfolio' 
    zoneRGseries={true}
    rgValueMin={tOverview["Investment Cost"]}
    chartType='areaspline' data={portfolioValue} marketInfoLoading={false}/>}
    </>
    </>
  );
}


import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen';
import { useAppInfo } from 'AppState';
import { Alert } from '@mui/material';
import {getSpecificArrayObjKeys } from "utils/utilGeneral";
import { dfin } from 'content/constants';
import PieChartCategory from 'components/Charts/PieChartCategory';
import { MetricSettingsBar, PopoverSettings } from 'components/Template/MetricSettings';
import PageSettingsModal from 'components/Template/PageSettingsModal';
import SelectBox from 'components/Inputs/SelectBox';
import TickerPositionCard from './TickerPositionCard';

export default function PortfolioCurrentPositions({positionType="All"}) {


  return (
    <>
    {positionType==="All" && <PortfolioCurrentPositionAll/>}

    {positionType==="Ticker" && <PortfolioCurrentPositionTicker/>}
  </>
  )
}

export function PortfolioCurrentPositionAll(){
  const { statePortfolio,stateTicker} = useAppInfo();
  const {portfolioTable} = statePortfolio

  const tDetails = getSpecificArrayObjKeys(portfolioTable, cols);
  const displayOptions = ["Chart & Table","Chart","Table"]
  const [displayType,setDisplayType] = useState(displayOptions[0])
  const [displaySettings,setDisplaySettings] = useState(false)

//   const topProfitLoss = getTopN(tDetails, 'Profit/Loss');
// const topProfitLossPercentage = getTopN(tDetails, 'Profit/Loss (%)');
// const topWeight = getTopN(tDetails, 'Weight');


  return (<>
      {!statePortfolio.portfolioLoaded && !tDetails.length > 0 && (
      <Alert style={{ marginTop: "1rem" }} severity="warning">
        You currently have no Holdings!
      </Alert>
    )}

    {tDetails.length > 0 && (

     <div className='containerColumnFlex'>
      <div style={{display:"flex"}}>
        <div style={{marginLeft:"auto"}}>
      <PopoverSettings displaySettings handleDisplaySettings={()=>setDisplaySettings(true)}/>
      </div>
        </div>
        
        {[displayOptions[0],displayOptions[1]].includes(displayType) && 
        <div style={{minHeight:"400px"}}>
        <PieChartCategory 
        width='100%'
        height='400px'
        ticker
        data={tDetails} category={"TICKER"} weightCategory={"Net Asset Value"} weightType='weighted'/>
        </div>}

      {[displayOptions[0],displayOptions[2]].includes(displayType) && <div style={{flex:1}}>
        <TableGen
          data={tDetails}
          // tableLayout="fixed"
          height="auto"
          logo={true}
        /></div>}
      </div>
    )}
      {displaySettings && <PageSettingsModal onClose={()=>setDisplaySettings(false)} title='Display Settings'>
          <SelectBox options={displayOptions} value={displayType} onChange={(e,v)=>setDisplayType(v)}></SelectBox>
        </PageSettingsModal>}

  </>)
}

export function PortfolioCurrentPositionTicker(){
  const { statePortfolio, stateTicker} = useAppInfo();
  const [tickerCurrentPosition,setTickerCurrentPosition] = useState([])
  const {portfolioTable} = statePortfolio
  const {tickerCode} = stateTicker

  useEffect(()=>{
    if (portfolioTable){
    const tickerTable = portfolioTable.filter(obj=>obj[dfin.tickerCode]===tickerCode)
    if (tickerTable.length>0){
    setTickerCurrentPosition(getSpecificArrayObjKeys(tickerTable,cols))}}
  },[portfolioTable,tickerCode])
  return (
    <>
    {tickerCurrentPosition.length>0 ? 
    <div className='containerColumnFlex'>
        <div style={{padding:"0.25rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
        {tickerCurrentPosition.map((obj,index)=>(
          <TickerPositionCard data={obj} key={index}/>
        ))}
        </div>
        {/* <TableGen
          data={tickerCurrentPosition}
          tableLayout="fixed"
          height="auto"
          logo={true}
        /> */}
      </div> : 
      <Alert severity='warning'>You have no current open positions for {stateTicker.tickerSelected}</Alert>
      }

      </>
  )
}


const getTopN = (array, key, n = 1) => {
  return array
    .filter(item => typeof item[key] === 'number') // Ensure the key value is a number
    .sort((a, b) => b[key] - a[key])
    .slice(0, n);
};




const cols = ["TICKER",
  "NAME",
  "LOGO URL",
  "investmentType",
  "transactionType",
  // "date",
  "Average Price",
  "quantity",
  "Investment Value",
  "Net Asset Value",
  "Current Price",
  "Profit/Loss",
  "Profit/Loss (%)",
  "Weight",
  "Holding Period (days)",
];
import { Button } from '@mui/material'
import React, { useState } from 'react'
import BorderDoubleBox from '../../animation/BorderDoubleBox'
import { useAppInfo } from 'AppState'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function GetStartedButton({sx={},icon=true}) {
    const {dispatchApp,stateApp} = useAppInfo()
    
    function handleGetStarted(){    
        dispatchApp({"loginModal":true})
    }
    const [mouseEnter,setMouseEnter] = useState(false)
  return (
    <>
    {!stateApp.isLoggedIn && 
        <div style={{display:"flex",alignItems:"center"}}>
        <Button variant="contained" endIcon={icon && <TrendingUpIcon />} onMouseEnter={()=>setMouseEnter(true)} onMouseLeave={()=>setMouseEnter(false)} onClick={handleGetStarted} 
        style={{padding:"0.5rem",fontWeight:"bold",...sx}}>
          Get Started 
          
          {/* <BorderDoubleBox/> */}
        </Button></div>}
    </>
  )
}



export default function UpdateAllocation(data){
    let updatedFields = data
    const total = updatedFields.reduce((total, fieldObj) => total + Number(fieldObj.amount), 0);
        
    updatedFields = updatedFields.map(fieldObj => {
        const currentAllocation = total === 0 ? 0 : ((fieldObj.amount / total) * 100).toFixed(2)
        return {
            ...fieldObj,
            currentAllocation: currentAllocation,
            differenceAllocation: currentAllocation - fieldObj.targetAllocation
          };
        })
        
     return updatedFields
    }
import React,{useEffect, useState} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Indicators from "highcharts/indicators/indicators-all.js";
import DragPanes from "highcharts/modules/drag-panes.js";
import AnnotationsAdvanced from "highcharts/modules/annotations-advanced.js";
import PriceIndicator from "highcharts/modules/price-indicator.js";
import FullScreen from "highcharts/modules/full-screen.js";
import StockTools from "highcharts/modules/stock-tools.js";
import "./TechnicalChart.module.css"
import { useAppInfo } from "AppState";
import {LinearProgress } from "@mui/material";
import useMultiTickerInfo from "data/ticker/useMultiTickerInfo";
import LoadingSkeletonChart from "components/Loading/LoadingSkeletonChart";


require("highcharts/modules/accessibility")(Highcharts);

// init the module
Indicators(Highcharts);
DragPanes(Highcharts);
AnnotationsAdvanced(Highcharts);
PriceIndicator(Highcharts);
FullScreen(Highcharts);
StockTools(Highcharts);



export default function TechnicalChart() {
    // const {priceData,marketInfoLoading} = useCompanyInfo()
    const {stateTicker} = useAppInfo()
    const tickerSelected = stateTicker.tickerSelected
    const {close,trendTable,marketInfoLoading,priceData} = useMultiTickerInfo([stateTicker.tickerSelected])
    
    const [ohlc,setOHLC] = useState([])
    const[volume,setVolume] = useState([])

    useEffect(()=>{
        const ohlc = [];
        const volume = [];
      
        const dataLength = priceData.length;

        for (let i = 0; i < dataLength; i += 1) {
            const date = new Date(priceData[i]["Date"]).getTime(); // Convert date to timestamp
    
            ohlc.push([
            date, // the date
            priceData[i]["Open"], // open
            priceData[i]["High"], // high
            priceData[i]["Low"], // low
            priceData[i]["Close"], // close
            ]);
    
            volume.push([
            date, // the date
            priceData[i]["Volume"], // the volume
            ]);
        }
     
        setOHLC(ohlc)
        setVolume(volume)

    },[priceData,stateTicker.tickerSelected])

    
   

    const options = 
        
        {        
        
        chart:{
            animation:false
        },
        rangeSelector: {
            selected: 3, // Set the default range to 6 months (0: All, 1: YTD, 2: 6M, 3: 3M, 4: 1M)
            buttons: [
                {
                    type: "week",
                    count: 1,
                    text: "1W",
                },
                {
                    type: "month",
                    count: 1,
                    text: "1M",
                },
                {
                    type: "month",
                    count: 3,
                    text: "3M",
                },
                {
                    type: "month",
                    count: 6,
                    text: "6M",
                },
                {
                    type: "ytd",
                    text: "YTD",
                },
                
                {
                    type: "all",
                    text: "All",
                },
               

            ],
        },
        yAxis: [
            {
                labels: {
                align: 'left',
                },
                height: '80%',
                resize: {
                enabled: true,
                },
            },
            {
                labels: {
                align: 'left',
                },
                top: '80%',
                height: '20%',
                offset: 0,
            },],
      
        series: [
            {
                type: "candlestick",
                id: `${tickerSelected} ohlc`,
                name: `${tickerSelected} Price`,
                data: ohlc,
                color: 'red',    // Color for bearish candles (closing price < opening price)
                upColor: 'green', // Color for bullish candles (closing price > opening price)
                dataGrouping: {
                    enabled: false,
                  },
            },
            {
                type: 'column',
                id: `${tickerSelected} volume`,
                name: `${tickerSelected} Volume`,
                data: volume,
                color:"#00c3ff",
                yAxis: 1,
            },
            ],
            tooltip: {
                shape: 'square',
                headerShape: 'callout',
                borderWidth: 0,
                shadow: false,
                positioner: function(width, height, point) {
                  var chart = this.chart,
                    position;
        
                  if (point.isHeader) {
                    position = {
                      x: Math.max(
                        // Left side limit
                        chart.plotLeft,
                        Math.min(
                          point.plotX + chart.plotLeft - width / 2,
                          // Right side limit
                          chart.chartWidth - width - chart.marginRight
                        )
                      ),
                      y: point.plotY
                    };
                  } else {
                    position = {
                      x: point.series.chart.plotLeft,
                      y: point.series.yAxis.top - chart.plotTop
                    };
                  }
        
                  return position;
                }
              },
            // stockTools :{
            //     gui:{
            //     enabled:true,buttons:["indicators"]}
            //   },
            credits: {
                enabled: false,
                 // Hide Highcharts.com credits
              },
              accessibility: {
                enabled: true, // Include the accessibility module
              },
      }
      
  
  return   <div className="chart_wrapper">
 {ohlc.length===0 && <LoadingSkeletonChart/>}
 {(marketInfoLoading && ohlc.length > 0) && <LinearProgress/>}
  {(ohlc.length > 0) && 
  <HighchartsReact
    highcharts={Highcharts}
    constructorType={"stockChart"}
    options={options}
    containerProps={{ style: { height: '100%',width:"100%"} }}
  />
 }
</div>
};



import React, { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Questionnaire ({data,title="Questionnaire",onSubmit}) {
  const [step, setStep] = useState(-1);
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState(); // Initialize answers with defa
  const [updatedData,setUpdatedData] = useState([])
  const {intro,disclaimer,questionnaire} = data
  const [questions,setQuestions] = useState(questionnaire)
  useEffect(()=>{
    const defaultAnswers = {}; // Initialize an object to hold default answers
    let defaultScore = 0
    // Loop through each question and set the default answer as the first option
    questions.forEach((question, index) => {
      defaultAnswers[index] = question.options[0].points;
      defaultScore += question.options[0].points
    });
    setScore(defaultScore)
    setAnswers(defaultAnswers)
    
  },[])

 
  
  const handleOptionSelect = (questionIndex, optionIndex) => {
    // Update the answers and score based on the option points
    const newAnswers = { ...answers, [questionIndex]: questions[questionIndex].options[optionIndex].points };
    setAnswers(newAnswers);
    const newScore = Object.values(newAnswers).reduce((total, points) => total + points, 0);
    setScore(newScore);
  
    // Update the optionSelected for the specific question
    const newQuestions = questions.map((question, index) => {
      if (index === questionIndex) {
        return { ...question, optionSelected: optionIndex };
      }
      return question;
    });
    setQuestions(newQuestions); // Assuming you have questions state initialized
  };
  
  function handleNext(){
    if (step < questions.length - 1) {
      setStep(step + 1);
    }
  };

  function handleBack(){
    if (step > -1) {
      setStep(step - 1);
    }
  };

  const progress = ((step + 1) / questions.length) * 100;


  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
      
      {step === -1 && 
        <div style={{ flex: 1, width: "80%", padding: "1rem", boxSizing: "border-box" }}>
          <h1 style={{ textAlign: "center", textTransform: "capitalize" }}>{title}</h1>
          <h3>{intro}</h3>
        </div>
      }
        
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", flex: 1 }}>      
        {step >= 0 && 
          <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "1rem", width: "80%", padding: "1rem", boxSizing: "border-box" }}>
            {/* <h2>{questions[step]?.type}</h2> */}
            <h2>{questions[step].question}</h2>
            
            {questions[step].options.map((option, optionIndex) => (
              <div key={optionIndex} style={{ display: "flex", alignItems: "center", width: "100%", gap: "1rem" }}>
                {questions[step].optionSelected === optionIndex && <CheckCircleIcon sx={{ color: "green" }} />}
                {questions[step].optionSelected !== optionIndex && <CheckCircleOutlineIcon 
                  onClick={() => handleOptionSelect(step, optionIndex)} />}
                <Button
                  className={`btnQuestionnaire ${questions[step].optionSelected === optionIndex && `activeButton`}`}
                  sx={{ flex: 1, justifyContent: "flex-start", color: "black" }}
                  onClick={() => handleOptionSelect(step, optionIndex)}
                >
                  {option.label}
                </Button>
              </div>
            ))}
          </div>
        }
      </div>
      
      <>
        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
          {step >= 0 && 
            <Button
              disabled={step <= 0}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
            >
              Back
            </Button>}
          <Button
            variant="contained"
            onClick={() => { step >= questions.length - 1 ? onSubmit({score:score, submitted:true,updatedQuestionnaire:questionnaire}) : handleNext() }}
            sx={{ mt: 1, mr: 1 }}
          >
            {step === -1 ? 'Start' : step >= questions.length - 1 ? "Finish" : "Next"}
          </Button>
        </div>
  
        <div style={{ marginTop: "1rem", width: "100%" }}>
          <progress value={progress} max="100" style={{ width: "100%" }}></progress>
        </div>
      </>
  
      {disclaimer && <Alert severity='warning'>{disclaimer}</Alert>}
    </div>
  );
};

import React from 'react';
import "./DragResize.css"

export default function ResizingBox({ handleMouseDownResize }) {
  // Define all resizer classes in an array
  const resizers = [
    'resizer-right', 'resizer-left', 'resizer-left-long', 'resizer-right-long',
    'resizer-bottom-long', 'resizer-top-long', 'resizer-top-long', // This seems duplicated, check if needed twice
    'resizer-both-bL', 'resizer-both-tL', 'resizer-both-tR', 'resizer-both-bR',
    'resizer-top', 'resizer-bottom'
  ];

  return (
    <>
      {resizers.map((resizer, index) => (
        <span key={index} className={resizer} onTouchStart={handleMouseDownResize} onMouseDown={handleMouseDownResize} />
      ))}
    </>
  );
}
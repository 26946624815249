import React, { useState } from 'react';
import { useAppInfo } from 'AppState';
import { formatNumber } from "utils/utilGeneral";
import BorderBox from 'components/Layout/BorderBox';
import { Button, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NetworthBarChart from './NetworthBarChart';
import AssetDetails from 'myFinancials/Asset/AssetDetails';
import LiabilityDetails from 'myFinancials/Liability/LiabilityDetails';
import NetworthProjection from './NetworthProjection';

export default function NetWorthBalanceSheet({header=true,expanded=true,barChart=false,border=false,projection=false}) {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats,currency} = stateMyFinancials;
    const { netWorth, totalAssets,totalGroupedAssets,totalLiabilities,totalGroupedLiabilities} = summaryStats;
    const [openAssets, setOpenAssets] = useState(false);
    const [openLiabilities, setOpenLiabilities] = useState(false);
    const noData = totalAssets === 0 && totalLiabilities === 0;

    function handleNoData(v) {
        dispatchMyFinancials({
            menuSel: v,
            mainMenuSel: "Networth",
            subMenuSel: "Details"
          });
    }


    return (
        <div style={{position:"relative",padding:"0.25rem"}}>
            
           <div>
                    {header && <div style={{padding:"1rem 0"}}>
                        <h3 style={{ fontWeight: "bold", color: "gray" }}>
                            
                        Net Worth: <span style={{ color: netWorth >= 0 ? "green" : "red" }}>
                            {currency?.symbol} {formatNumber(netWorth)}
                            </span>
                        </h3>
                    </div>}
                    {/* {barChart && <NetworthBarChart/>} */}

                    {projection && 
                    <div style={{height:"400px"}}>
                    <NetworthProjection/>
                    </div>}
                    
                    <div style={{display:"flex",gap:"0.5rem",width:"100%",boxSizing:"border-box",flexWrap:"wrap"}}>

                    <BalanaceSheet 
                    expanded={expanded}
                    type='assets' 
                    totalAmount={totalAssets}
                    fields={totalGroupedAssets}
                    border={border}
                    />

                    <BalanaceSheet 
                    expanded={expanded}
                    type='liabilities' 
                    totalAmount={totalLiabilities}
                    fields={totalGroupedLiabilities}
                    border={border}
                    />
                    </div>
                </div>

        </div>
    );
}



function BalanaceSheet({type="assets",fields,totalAmount,expanded=true,border=false}){
const {stateMyFinancials} = useAppInfo()
const {currency} = stateMyFinancials
const color = type==="assets"?"green":"red"
const [collapse,setCollapse] = useState(!expanded)
return (
    <div style={{width:"100%",display:"flex",gap:"1rem",flexDirection:"column"
        ,boxSizing:"border-box"
        // ,border:border?`3px solid ${color}`:"3px solid #f8f8f8"
        ,borderRadius:"5px",padding:"0.5rem",
        background:type==="assets"?"var(--colorSuccess)":"var(--colorWarning)"        
        }}>
    
    <div onClick={()=>setCollapse(!collapse)} style={{display:"flex",gap:"1rem"}}>
    <h4 style={{ fontWeight: "bold"}}>
    Total {type==="assets"?"Assets":"Liabilities"}: <span style={{ color:color }}>{currency?.symbol} {formatNumber(totalAmount)}</span>
        </h4>
        <div style={{marginLeft:"auto"}}>
        {collapse ? <ExpandMoreIcon onClick={()=>setCollapse(false)}/>:<ExpandLessIcon onClick={()=>setCollapse(true)}/>}
        </div>
        </div>
{!collapse && 
<BorderBox>
    {type==="assets" && <AssetDetails/>}
    {type==="liabilities" && <LiabilityDetails/>}
{/* <table>
    <thead><tr><th>Type</th><th>Amount {currency?.symbol}</th></tr></thead>
    <tbody>
    {fields.map((field, index) => (
<React.Fragment key={index}>
<tr style={{background:"#F8F8F8"}}>
<td>{field.type}</td>
<td>{field.totalAmount}</td>
</tr>
{field.items.length>1 && field.items.map((item, itemIndex) => (
<tr key={itemIndex}>
<td style={{ paddingLeft: '20px' }}>{item.name}</td>
<td>{item.amount}</td>
</tr>
))}
</React.Fragment>
))}
    </tbody>
</table> */}

</BorderBox>}
</div>
)
}


import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import TableGen from 'components/Table/TableGen'
import { dfin } from 'content/constants'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function IndSecConstituentsPerformance() {
  const {stateIndustry} = useAppInfo()
  const [performance,setPerformance] = useState([])
  const [performanceHistogram,setPerformanceHistogram] = useState([])
  
  useEffect(()=>{
    const PerfScreen = getSpecificArrayObjKeys(stateIndustry.constiInfo,[dfin.ticker,dfin.coName,dfin.coLogo,dfin.mScale,dfin.performance1D,dfin.performance1M,dfin.performance3M,dfin.performance6M,dfin.performance1Y])
    const perfHistogram = getSpecificArrayObjKeys(stateIndustry.constiInfoAll,[dfin.performance1D])
    setPerformance(PerfScreen)
    setPerformanceHistogram(perfHistogram)
  },[stateIndustry.constiInfo])

  return (
    <>
  
    {!performance.length>0 && <LoadingSkeletonTable/>}
    {performance.length>0 &&
    <>
    <TableGen pagination={false} addToWatchlist={true} 
    logo={true} tableLayout='auto' data={performance} 
    perfCols={[dfin.performance1D,dfin.performance1M,dfin.performance3M,dfin.performance6M,dfin.performance1Y]}/>
    </>
    }
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import BarChartPeers from 'components/Charts/BarChartPeers';
import { LinearProgress } from '@mui/material';
import SortArrow from 'components/Table/SortArrow';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';


export default function PerfVolaTable({tickerDict,close,marketInfoLoading,type="Performance"}) {
  const { stateStock} = useAppInfo();
  const handleTickerChange = useHandleTickerChange()
  const companySelected = stateStock.companySelected
  
  const [sortingOrder, setSortingOrder] = useState({ column: "5Y", ascending: true });
  const [sortColumn, setSortColumn] = useState("5Y");
  const [nperiod,setNperiod] = useState(null)
  const [timePeriods,setTimePeriod] = useState([])
  const [symbols,setSymbols] = useState([])
  const [tableData,setTableData] = useState([])
  const [barData,setBarData] = useState([])
  useEffect(()=>{
  if (close.length>0){
  const date = new Date(close[0]["Date"]);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  let timePeriods = [
    { label: "1W", days: 5 },
    { label: "1M", days: 30 },
    { label: "3M", days: 90 },
    { label: "6M", days: 180 },
    { label: "1Y", days: 360 },
    { label: "5Y", days: 1800 },
    { label: `Since ${year}`, days: close.length-1}
  ];
  if (type === "Performance"){
    timePeriods = [{ label: "1D", days: 1 },...timePeriods]
  }
  setTimePeriod(timePeriods)
  const symbols = Object.keys(close[0]).filter(symbol => symbol !== "Date");
  setSymbols(symbols)
}
  },[close])

  useEffect(()=>{
  if (timePeriods.length>0){
  const tp = timePeriods.filter(obj => obj.label === sortColumn);
  const nperiod = tp[0]["days"];
  setNperiod(nperiod)
  }
  },[timePeriods,sortColumn])
  
  useEffect(()=>{
    if (close.length>0 && timePeriods.length>0 && symbols.length>0){
    const performanceTable = symbols.map(symbol => {
      const symbolData = timePeriods.map(timePeriod =>
        type==="Performance"?
        calculatePerformance(timePeriod.days, close)[symbol]:calculateVolatility(timePeriod.days, close)[symbol]
      );
      return { symbol, symbolData };
    });
    const sortedPerformanceTable = performanceTable.slice().sort((rowA, rowB) => {
      const columnIndex = timePeriods.findIndex(timePeriod => timePeriod.label === sortingOrder.column);
      const valueA = rowA.symbolData[columnIndex];
      const valueB = rowB.symbolData[columnIndex];
  
      return (valueA - valueB) * (sortingOrder.ascending ? 1 : -1);
    });
    setTableData(sortedPerformanceTable)
  }
  },[symbols,timePeriods,close,sortingOrder])
 
  useEffect(()=>{
    if (close.length>0 && nperiod){
    let performanceData;
    if (type==="Performance"){
    performanceData = calculatePerformance(nperiod, close);}
    else{
        performanceData = calculateVolatility(nperiod, close);
    }
    const performanceBar = Object.keys(performanceData).map(symbol => ({
      TICKER: symbol,
      [type]: parseFloat(performanceData[symbol])
    }));
    setBarData(performanceBar)
  }
  },[nperiod,close])
 

  const toggleSortingOrder = (column) => {
    if (sortingOrder.column === column) {
      setSortingOrder({ column, ascending: !sortingOrder.ascending });
    } else {
      setSortingOrder({ column, ascending: true });
    }
    setSortColumn(column);
    
  };

  return (
    <div style={{ maxWidth: "100%" }}>
    {marketInfoLoading && <LoadingSkeletonTable/>}
    { (barData.length>0 && !marketInfoLoading) && <BarChartPeers 
    data={barData} metricSelected={[type]} dataLabel={true} 
    title={`${type} ${sortColumn} - close change %`}/>
  
      }
      {(tableData.length>0 && timePeriods.length>0 && !marketInfoLoading) &&
      <table style={{ tableLayout: "fixed" }}>
        <thead>
          <tr>
          <th>Ticker</th>
            <th>Name</th>
           
            {timePeriods.map(timePeriod => (
              <th
                style={timePeriod.label === sortColumn ? { background: "lightblue", color: "black" } : {}}
                key={timePeriod.label}
                onClick={() => toggleSortingOrder(timePeriod.label)}
              >
                {timePeriod.label} <SortArrow />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr
              style={row.symbol === companySelected ? {  border: "2px solid blue",fontWeight:"bold" } : {}}
              key={row.symbol}
              onClick={(e) => handleTickerChange({ticker:row.symbol})}
            >
               <td style={{position:"relative",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"noWrap"}}>
                <>
                {row.symbol} 

                </>
              </td> 

              <td style={{position:"relative",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"noWrap"}}>
                <>
                {tickerDict? tickerDict[row.symbol]:row.symbol} 

                </>
              </td> 

              {row.symbolData.map((value, index) => (
                <td key={index} style={value > 0 ? { color: "green" } : { color: "red" }}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      }
      
    </div>
  );
}



const calculatePercentageChange = (currentValue, previousValue) => {
  const change = ((currentValue - previousValue) / previousValue) * 100;
  return isFinite(change) ? change.toFixed(2) : "N/A";
};

const calculatePerformance = (days, close) => {
  const currentDateIndex = close.length - 1;
  if (currentDateIndex >= days) {
    const previousEntry = close[currentDateIndex - days];
    const result = {};
    for (const symbol in previousEntry) {
      if (symbol !== "Date") {
        result[symbol] = calculatePercentageChange(
          close[currentDateIndex][symbol],
          previousEntry[symbol]
        );
      }
    }
    return result;
  } else {
    return "N/A";
  }
};


const calculateVolatility = (days, data) => {
    const currentDateIndex = data.length - 1;
    
    if (currentDateIndex >= days) {
      const result = {};
      for (const symbol in data[0]) {
        if (symbol !== "Date") {
          const dailyReturns = [];
          for (let i = currentDateIndex - days + 1; i <= currentDateIndex; i++) {
            const currentPrice = parseFloat(data[i][symbol]);
            const previousPrice = parseFloat(data[i - 1][symbol]);
            dailyReturns.push(
              calculatePercentageChange(currentPrice, previousPrice)
            );
          }
          const volatility = calculateStandardDeviation(dailyReturns);
          result[symbol] = isNaN(volatility) ? "N/A" : volatility.toFixed(2);
        }
      }
     
      return result;
    } else {
      return "N/A";
    }
  };
  
  const calculateStandardDeviation = (values) => {
    const numericValues = values.map(parseFloat); // Convert to numbers
    const mean =
      numericValues.reduce((acc, value) => acc + value, 0) / numericValues.length;
    const squaredDifferences = numericValues.map((value) =>
      Math.pow(value - mean, 2)
    );

    const variance =
      squaredDifferences.reduce((acc, value) => acc + value, 0) /
      (numericValues.length - 1);
    return Math.sqrt(variance);
  };
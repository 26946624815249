import React from 'react'
import { useAppInfo } from 'AppState'
import GoalAdd from './GoalAdd'


export default function NoGoalData() {
    const {stateMyFinancials} = useAppInfo()
    const {goalsPeriodic} = stateMyFinancials;
    
    const totalGoalAmount = [...goalsPeriodic].reduce((total, obj) => total + obj.amount, 0);
    const noData = totalGoalAmount === 0;

  if (!noData){
    return null
  }

  return (
    <div style={{padding:"1rem",boxSizing:"border-box",position:"absolute",width:"100%",height:"100%"
      ,background:"rgba(0,0,0,0.05)",zIndex:4,display:"flex",gap:"1rem",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <GoalAdd variant='contained'/>
        </div> 
  )
}

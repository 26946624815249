import React, {useState } from "react";
import SelectBox from "components/Inputs/SelectBox";
import TableGen from "components/Table/TableGen";
import useBeta from "data/technicals/useBeta";
import LoadingSkeletonTable from "components/Loading/LoadingSkeletonTable";
import PageSettingsModal from "components/Template/PageSettingsModal";

export default function Beta({tickerList,tickerDict}) {
  
  const [dateFrequency,setDateFrequency] = useState("Daily")
  const [period,setPeriod] = useState(360)
  const {beta,betaSingle,betaLoading} = useBeta(tickerList,dateFrequency,period)
  const [betaSettings,setBetaSettings] = useState(false)
    
  function handleDateFreq(e,v){
    setDateFrequency(v)
    setBetaSettings(false)
  }

  function handlePeriod(e,v){
    setPeriod(v)
    setBetaSettings(false)
  }


  
  return ( 
  <div>
    {betaLoading && <LoadingSkeletonTable length={tickerList.length}/>}
    {(!betaLoading && beta.length>0) && 
      <TableGen 
      tickerDict={tickerDict}
      data={betaSingle} 
      color="redGreen" tableLayout="fixed"
      tableName="Beta"
      tableHeader={`Beta ${dateFrequency}, Close Price`}
      tableSettings={{metricSettings:true}}
      handleTableSettings={()=>setBetaSettings(true)}
      />}
    {betaSettings && <PageSettingsModal title="Beta Settings" onClose={()=>setBetaSettings(false)}>
    <SelectBox options={["Daily","Weekly","Monthly"]} id={"DateFreq Type"} value={dateFrequency} onChange={handleDateFreq} label={"Date Frequency"}/>
    <SelectBox options={[10,50,100,200,400,800,1600,3200]} id={"Period Type"} value={period} onChange={handlePeriod} label={"Period"}/>
      </PageSettingsModal>}
  
  </div>
  )
};
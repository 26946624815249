import React,{useState,useEffect} from 'react'
import { Button, IconButton, Paper, Tooltip } from "@mui/material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppInfo } from 'AppState';

export default function LeftSidebarFloating({header,headerDetails,children,sx={}}) {

  const {dispatchSidebar,stateSidebar} = useAppInfo()
  // const {sidebarLeftMenuOpen} = stateSidebar
  const [sidebarLeftMenuOpen,setSidebarLeftMenuOpen] = useState(true)


  function handleShowMenu(){
    setSidebarLeftMenuOpen(!sidebarLeftMenuOpen)
    // dispatchSidebar({sidebarLeftMenuOpen:!sidebarLeftMenuOpen})
  }
  
  const [overflowSelection,setOverflowSelection] = useState(false)
  

  return (
<>
  {/* <ResizableRight orientV={true}> */}
  
  {sidebarLeftMenuOpen && 
  <div
  onTouchStart={()=>setOverflowSelection(true)}
  onTouchEnd={()=>setOverflowSelection(false)}
  onMouseEnter={()=>setOverflowSelection(true)} 
  onMouseLeave={()=>setOverflowSelection(false)} 
  style={{width:"15%"
  ,minWidth:"250px",boxSizing:"border-box",height:"max-content",maxHeight:"100%",overflow:overflowSelection?"auto":"hidden",position:"sticky",top:0,...sx}}>
  <Paper elevation={0} style={{width:"100%",height:"100%",boxSizing:"border-box"
  ,background:"#fafafa"}}>
  <div style={{padding:"0.5rem",height:"100%",width:"100%",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
  <div  style={{display:"flex",alignItems:"center"
  ,marginBottom:"0.5rem",width:"100%",gap:"0.5rem",position:"sticky"
  ,top:0,background:"#fafafa",zIndex:1000}}>
  <MenuOpenIcon onClick={handleShowMenu}/>
  <div style={{display:"flex",flex:1,gap:"1rem",alignItems:"center"}}>
  <h4 style={{fontWeight:"bold",color:"gray",fontSize:"1.1rem"}}>{header}</h4>
  {headerDetails}
  </div>
  </div>
  <div style={{flex:1,width:"100%"}}>
  {children}
  </div>
  </div>
  </Paper>
  </div>
  }


{!sidebarLeftMenuOpen && <div>
  <Tooltip title={header}>
  <IconButton onClick={handleShowMenu} 
  sx={{color:"black",background:"#F8F8F8"}}>
  <MoreVertIcon />
  </IconButton>
  </Tooltip>
  </div>}



</>
  ) 
}
import React, { useEffect, useState } from 'react'
import useBondsScreener from 'data/bonds/useBondsScreener'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import {dfin } from 'content/constants'
import ScreenerTemplate from './ScreenerTemplate'
import { applyFilters } from './utilsScreener'
import { useAppInfo } from 'AppState'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function BondScreener() {
    const {stateScreener} = useAppInfo()
    const {bondsOverview} = useBondsScreener()
    const gBondsUS = bondsOverview && bondsOverview.filter(obj=>obj[dfin.exCode]==="GBOND" && obj[dfin.country]==="USA")
    const gBondsTicker = gBondsUS && gBondsUS.map(obj=>obj['CODE'])
    const [filteredData,setFilteredData] = useState([])
    useEffect(()=>{
     if (bondsOverview && bondsOverview.length>0){
     const data = getSpecificArrayObjKeys(bondsOverview,cols)
     const filteredData =  applyFilters(stateScreener.filtersBonds,data)
     setFilteredData(filteredData)
    }
    },[bondsOverview])

    function handleFilterUpdate(v){
      setFilteredData(v)
    } 
    
  return (
    <>
    <ScreenerTemplate loading={bondsOverview.length>0} filterName={"filtersBond"} data={getSpecificArrayObjKeys(bondsOverview,cols)} handleFilterUpdate={handleFilterUpdate}>
    {bondsOverview.length===0 && <LoadingSkeletonTable length={50}/>}
    {(filteredData.length>0) && <TableGen data={filteredData}/>}
    </ScreenerTemplate>
    </>
  )
}
const cols = [
    "TICKER",
    "NAME",
    "TYPE",
    "EXCHANGE SHORT NAME",
    "PERFORMANCE 1D",
    "PERFORMANCE 1M",
    "PERFORMANCE 3M",
    "PERFORMANCE 6M",
    "PERFORMANCE 1Y",
    "OPEN",
    "HIGH",
    "LOW",
    "CLOSE",
    "ADJUSTED CLOSE",
    "VOLUME",
    "EMA 50D",
    "EMA 200D",
    "HI 250D",
    "LO 250D",
    "AVGVOL 14D",
    "AVGVOL 50D",
    "AVGVOL 200D",
  ];

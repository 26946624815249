import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function PeriodSlider({header=null,
  min,max,step=1,defaultValue,onChange,handleInputChange,reportMode,period="Years",width="100px"}) {
  
  let defaultV = defaultValue 
  if (defaultValue>max){
    defaultV=max
  }
  
  if(reportMode){
    period="Quarters"
  }
  return (
    <Box sx={{ width:width,background:"white"}}>
    <div style={{display:"flex",gap:"0rem"}}>
    {header && <Typography sx={{flex:1}}>{header}</Typography>}
    <Input
    label = {period}
    defaultValue={defaultV}
      value={defaultV}
      sx={{justifySelf:"center",alignItems:"left",width:"auto"}}
      size="small"
      onChange={handleInputChange}
      inputMode="numeric"
      // onBlur={handleBlur}
      inputProps={{
        step: step,
        min: min,
        max: max,
        type: 'number',
        'aria-labelledby': 'input-slider',
      }}
    />
    {period} 
    </div>
      <Slider
        marks
        defaultValue={defaultV}
        onChange={onChange}
        valueLabelDisplay="auto"
        value={defaultV}
        step={step}
        min={min}
        max={max}
      />


    </Box>
  );
}


import React, { useEffect, useState, useRef, memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { dfin } from 'content/constants';
import MultiSelect from '../Inputs/MultiSelect';
import SelectBox from '../Inputs/SelectBox';
import Toggle from '../Inputs/Toggle';
import TuneIcon from '@mui/icons-material/Tune';
import Modal from '../Modal/Modal';
import LoadingSkeletonHeatmap from '../Loading/LoadingSkeletonHeatmap';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import PageSettingsModal from 'components/Template/PageSettingsModal';

const getColorBasedOnPerformance = (performance) => {
  if (performance > 10) {
    return "green" ; 
  } else if (performance > 5) {
    
    return '#33FF33'; 
  } else if (performance > 2) {
    return '#66FF66'; 
  } else if (performance > 0) {
    return '#9EFF99'; 
  } else if (performance > -5) {
    return '#FF6666'; 
  } else if (performance > -10) {
    return '#FF0000';
  } else {
    return '#990000'; // Even darker red for lesser than -10%
  }
};
// Initialize the treemap module
HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);

const TreeMap = memo(function TreeMap({type="All",showSettings=false,showSettingsOnHover=false,data}){
  const {stateIndustry,dispatchIndustry} = useAppInfo();
  const handleTickerChange = useHandleTickerChange()
  
  const [perfPeriod,setPerfPeriod] = useState(dfin.performance1D)
 

  const perfDict = {
    "1D":dfin.performance1D,
    "3M":dfin.performance3M,
    "6M":dfin.performance6M,
    "1Y":dfin.performance1Y
  }
  // const [performance, setPerformance] = useState([]);
  const [treemapData, setTreemapData] = useState([]);
  const [performance,setPerformance] = useState([])
  useEffect(()=>{
    if (!data){
    if (type==="All") {
      setPerformance(stateIndustry.constiInfoAll)
    }
    else{
      setPerformance(stateIndustry.constiInfo)
    }}
    else{
      setPerformance(data)
    }
  },[type,stateIndustry.constiInfoAll,stateIndustry.constiInfo,data])

  const chartRef = useRef(null);

  const navigate = useNavigate()
      
  function handleInfo(e,v){
    handleTickerChange({ticker:v})
    
  }

  function handleTrade(e,v){
      navigate("/Trade")
      handleTickerChange({ticker:v,modal:false})
  }

  useEffect(() => {
    const sectorMean = {};
    const industryMean = {};

    performance.forEach((entry) => {
      const sector = entry[dfin.gicSector];
      const industry = entry[dfin.gicIndustry];
      const performance = entry[dfin.marketCap];

      if (!sectorMean[sector]) {
        sectorMean[sector] = { total: 0, count: 0 };
      }
      sectorMean[sector].total += performance;
      sectorMean[sector].count++;

      if (!industryMean[industry]) {
        industryMean[industry] = { total: 0, count: 0 };
      }
      industryMean[industry].total += performance;
      industryMean[industry].count++;
    });

    const calculateMean = (meanData) => {
      for (const key in meanData) {
        meanData[key] = meanData[key].total;
      }
    };

    calculateMean(sectorMean);
    calculateMean(industryMean);

    // Transform data into hierarchical structure
    const newTreemapData = [];

    performance.forEach((entry) => {
      const sector = entry[dfin.gicSector];
      const industry = entry[dfin.gicIndustry];
      const ticker = entry.TICKER;
      const mCap = entry[dfin.marketCap];
      const perf = entry[perfPeriod]
      const close = entry["CLOSE"]
      const coName = entry[dfin.coName]
      // Add sector to treemapData
      if (!newTreemapData.some((item) => item.id === `id${sector}`)) {
        newTreemapData.push({
          id: `id${sector}`,
          name: `${sector}`,
          color: Highcharts.getOptions().colors[newTreemapData.length % Highcharts.getOptions().colors.length],
          value: Number(sectorMean[sector]),
          label:Number(sectorMean[sector]),
        });
      }

      // Add industry to treemapData
      if (!newTreemapData.some((item) => item.id === industry)) {
        
        newTreemapData.push({
          id: industry,
          name: `${industry}`,
          parent: `id${sector}`,
          value:Number(industryMean[industry]),
          label:Number(industryMean[industry])
        });
      }

      
      // Add tickers to treemapData
      if (industry) {
        newTreemapData.push({
          name: ticker,
          parent: industry,
          value: Math.abs(Number(mCap)),
          coName:coName,
          close: Number(close).toFixed(2),
          label: Number(perf).toFixed(2),
          color: getColorBasedOnPerformance(Number(perf)),
        });
      }
    });

    setTreemapData(newTreemapData);

  }, [performance,perfPeriod]);

  const [options,setOptions] = useState()
  // Configuration for the treemap chart

  useEffect(()=>{
    if (treemapData.length>0){
    const options = {
      series: [{
        name: 'Sectors',
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        alternateStartingDirection: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: true,
  
        },
        events: {
          click: function (event) {
            // Check if the clicked point is a leaf node (ticker)
            if (event.point.node.isLeaf) {
              // Call the handleTickerChange function and pass point.name as a prop
              handleTickerChange({ticker:event.point.name});
            }
          }
        },
        levels: [{
            level: 1,
            dataLabels: {align: 'left',
            verticalAlign: 'top',
                enabled: true
            },
            borderWidth: 5,
            levelIsConstant: false
        }, {
            level: 1,
            
            dataLabels: {
                style: {
                    fontSize: '20px',
                    padding:"1rem",
                    background:"fffff",
                },
                formatter: function () {
                  // Check if the point is a leaf node (ticker)
                    return this.point.name + '<br>'; // Display name and label for leaf nodes
                  
                }
            },
            
        },
        {
          level: 2,
          dataLabels: {
              style: {align: 'left',
              verticalAlign: 'top',
                  fontSize: '20px'
              },
              formatter: function () {
                // Check if the point is a leaf node (ticker)
                  return this.point.name + '<br>'; // Display name and label for leaf nodes
                
              }
          },
          
      },
      {
        level: 3,
        dataLabels: {
            align: 'center',
              verticalAlign: 'middle',
            style: {
              
                fontSize: '14px'
            },
            formatter: function () {
              // Check if the point is a leaf node (ticker)
                return this.point.name + '<br>'  + this.point.close  + '    ' + (this.point.label)+"%"; // Display name and label for leaf nodes
              
            }
        },
        
    }
      ,],
        accessibility: {
            exposeAsGroupOnly: true
        },
        data: treemapData
    },],
  exporting:{
    enabled:false
  },
  credits: {
    enabled: false, // Hide Highcharts.com credits
  },
      title: {
        text: '',
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          // Check the level of the current point

          if (this.point.node.isLeaf) {
              const company = this.point.name
              const tooltipContent = `
              <b>${this.point.coName}</b><br>
              <b>${this.point.name}</b><br>Close: ${this.point.close}
              <br>Performance: ${this.point.label}%
              <div style="display:flex;gap:0.5rem; margin-top:1rem">
                
                <button id="infoButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Company Info</button>
                <button id="tradeButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Trade</button>

              </div>
                `;
        
              setTimeout(() => {
                // Add event listeners to the buttons after rendering
                const infoButton = document.getElementById(`infoButton-${company}`);
                const tradeButton = document.getElementById(`tradeButton-${company}`);
        
                if (infoButton) {
                  infoButton.addEventListener('click', (e) => handleInfo(e,company));
                }
        
                if (tradeButton) {
                  tradeButton.addEventListener('click', (e) => handleTrade(e,company));
                }
              }, 0);
        
              return tooltipContent
            }
           
          else{
            return this.point.name
          }}
          ,
            style: {
              pointerEvents: 'auto',
            },

        },
      

    };
  
  setOptions(options)}
  },[treemapData])
  function handleCountry(e,v){
    dispatchIndustry({"countrySel":[v]})
   }

   function handleSectorChange(v){
    let sectorSel = v
    let sectorList = v
    if(v.includes("All")){
      sectorSel=["All"]
      sectorList = stateIndustry.uniqueSectors
    }
    dispatchIndustry({"sectorSel":sectorSel,
      "industrySel":industrySel.splice(0,5),"indSecMode":false})
    const industrySel = stateIndustry.secIndList.filter(obj=>sectorList.includes(obj[dfin.gicSector])).map((item) => item[dfin.gicIndustry])
  }

 function handleIndustryChange(v){
  let industrySel=v
  const sectorSel = stateIndustry.secIndList.filter(obj=>industrySel.includes(obj[dfin.gicIndustry])).map((item) => item[dfin.gicSector])
  dispatchIndustry({"sectorSel":sectorSel,"industrySel":industrySel,"indSecMode":true})
}

  function handleMarketCap(v){
    let marketCap = v;
    if (v.includes("All")){
        marketCap = ["Mega Cap","Large Cap","Mid Cap","Small Cap"]
    }
    dispatchIndustry({"mScaleSel":marketCap})
  }
  function handleToggle(){
    dispatchIndustry({"indSecMode":!stateIndustry.indSecMode})
  }
  const [showFilter,setShowFilter] = useState(false)
  function handleFilter(){
    setShowFilter(!showFilter)
  }


  return (
    <>
      {!options && <LoadingSkeletonHeatmap/>}
      {options &&
    <div className='containerColumnFlexNoGap'>
     
      
      <>
      <div 
      style={{display:"flex",gap:"1rem"
      ,alignItems:"center",width:"100%",padding:"0.5rem",boxSizing:"border-box"}}>
      {showSettings && 
      <>
      <Button onClick={handleFilter} variant='outlined' size='small'><TuneIcon /> 
            </Button>
      {showFilter &&
      <PageSettingsModal title='Heatmap Filter' onClose={()=>setShowFilter(false)}>
        <Toggle toggleValues={["Sector","Industry"]} toggleMode={stateIndustry.indSecMode} handleToggle={handleToggle}></Toggle>
            <SelectBox
            variant='outlined'
            label={"Market Region"}  
            options={["USA","India","Canada","UK"]} 
            value={stateIndustry.countrySel[0]} onChange={handleCountry}/>
        
            <MultiSelect label={"Market Cap"} 
        options={["All","Mega Cap","Large Cap","Mid Cap","Small Cap"]} 
        values={stateIndustry.mScaleSel} onChange={handleMarketCap}/>
        
        <MultiSelect label={"Sector"} options={["All",...stateIndustry.uniqueSectors]} values={stateIndustry.sectorSel} onChange={handleSectorChange}/>
        <MultiSelect label="Industry" options={stateIndustry.uniqueIndustries} values={stateIndustry.industrySel} onChange={handleIndustryChange} />
        </PageSettingsModal>
      }
      </>
      } 

        <div style={{marginLeft:"auto",display:"flex",gap:"1rem"}}>
        <div>
        <Tooltip title="Performance Period">
        <ButtonGroup size="small" aria-label="performance period" sx={{fontSize:"0.6rem"}}>
      {Object.keys(perfDict).map(pPeriod=>
        <Button size='small'
        sx={{fontSize:"0.6rem"}}
        style={perfDict[pPeriod]===perfPeriod?{color:"white",background:"black"}:{}} key={pPeriod} onClick={()=>setPerfPeriod(perfDict[pPeriod])}>{pPeriod}</Button>)}
      </ButtonGroup>
      </Tooltip>
      </div>
      </div>
      </div>

      <div style={{flex:1,width:"100%",height:"90%"}}>
      <HighchartsReact highcharts={Highcharts} options={options} 
      containerProps={{ style: { height: '100%' , width:"100%"} }}
      ref={chartRef} />
      </div>
      </>
    </div>}
    </>
  );
});

export default TreeMap;

import { calculateAmortizationSchedule, calculateMonthlyGoals, calculateMonthlyNetCashFlow, calculateNetCashFlow, calculateTimeHorizon, extrapolateCashflow } from './Utils/utils'
import {TotalAmount,TotalIncomeExpense,TotalNetWorth,TotalSummaryDist,TotalNetCash} from './Utils/Totals'
import { useEffect, useMemo, useRef } from 'react'
import { useAppInfo } from 'AppState'
import useGoalDetails from './Goals/useGoalDetails'
import useLiabilityAnalytics from './Liability/useLiabilityAnalytics'
import useCheckFinancialHealth from './useCheckFinancialHealth'

export default function useMyFinancialsDetails () {
const {statePortfolio,stateMyFinancials,dispatchMyFinancials} = useAppInfo()
const {portfolioResults,portfolio} = statePortfolio
const {assets,liabilities,incomeMonthly,expenseMonthly} = stateMyFinancials

const assetRef = useRef()
assetRef.current = [...assets]

useEffect(()=>{
    const incomeDist =  [...extrapolateCashflow({fields:incomeMonthly})]
    const expenseDist = [...extrapolateCashflow({fields:expenseMonthly})]
    const liabilityDist = liabilities.flatMap(obj => {
      const {amortizationSchedule}= calculateAmortizationSchedule(obj);
      return amortizationSchedule || [];
    });
    const assetDist = extrapolateCashflow({fields:assets,growthKey:"return",showVolatility:false})
    const assetReturnDist = extrapolateCashflow({fields:assets,growthKey:"return",amountType:"growth",showVolatility:false})
    const cashBalance = extrapolateCashflow({fields:assets.filter(asset=>asset?.type==="Cash")})
    const totalCashInflowDist = [...incomeDist,...assetReturnDist,...cashBalance]
    const totalCashOutflowDist = [...expenseDist,...liabilityDist]
    const networthDist = calculateMonthlyNetCashFlow(assetDist,liabilityDist,"Networth")
    const netCashflowDist = calculateNetCashFlow(totalCashInflowDist, totalCashOutflowDist);
    const netCashflowBeforeAssetReturnDist = calculateNetCashFlow([...incomeDist,...cashBalance],totalCashOutflowDist)
    const netCashflowAssetWidthdrawalDist = calculateNetCashFlow([...incomeDist,...assetDist], totalCashOutflowDist);

    const totalAssets = assets.reduce((total, field) => {return total + (Number(field.amount) || 0);}, 0);
    const totalLiabilities = liabilities.reduce((total, field) => {return total + (field.amount || 0);}, 0);
    const netWorth = totalAssets - totalLiabilities;
    const totalAssetTypeAmounts = totalAmountCategory(assets,"type")
    const totalAssetName = totalAmountCategory(assets,"name")
    const totalLiabilityTypeAmounts = totalAmountCategory(liabilities)
    const totalLiabilityName = totalAmountCategory(liabilities,"name")
    const totalGroupedAssets = groupByType(assets)
    const totalGroupedLiabilities = groupByType(liabilities)
    const {totalIncome,totalExpense} = TotalIncomeExpense(incomeDist,expenseDist)

    const totalNetCashflow = TotalSummaryDist(netCashflowDist)
    const totalNetCashflowBeforeReturns = TotalSummaryDist(netCashflowBeforeAssetReturnDist)
    const totalAssetReturnDist = TotalSummaryDist(assetReturnDist)
    
    const {totalAssetDist,totalLiabilityDist,totalNetworthDist} = TotalNetWorth({assetDist,liabilityDist})
    
    const cashflowDistribution = {
    incomeDist,
    expenseDist,
    netCashflowDist,
    netCashflowBeforeAssetReturnDist,
    netCashflowAssetWidthdrawalDist,
    assetDist,
    assetReturnDist,
    liabilityDist,
    networthDist,
    totalCashInflowDist,
    totalCashOutflowDist}
    

    const summary = {
      totalAssets,
      totalLiabilities
      ,netWorth:netWorth,
      totalIncome,totalExpense,totalNetCashflow,
      totalAssetTypes:totalAssetTypeAmounts,totalLiabilityTypes:totalLiabilityTypeAmounts,totalGroupedLiabilities
      ,totalAssetDist,totalAssetName,totalGroupedAssets
      ,totalLiabilityName,totalLiabilityDist,totalNetworthDist
      ,totalAssetReturnDist,totalNetCashflowBeforeReturns}
    

    dispatchMyFinancials({"summaryStats":summary,"cashflowDistribution":cashflowDistribution})
    
  },[assets,liabilities,incomeMonthly,expenseMonthly])

useGoalDetails()

useLiabilityAnalytics()
  
useCheckFinancialHealth()

useEffect(() => {
  if (portfolioResults) {
    // Create a new array to update assets, start with the current assets
    let assetUpdate = [...assetRef.current];  // Use ref to avoid circular updates

    const today = new Date()
    Object.entries(portfolioResults).forEach(([accountNameSel, portfolioDetails]) => {
      const nav = Number(portfolioDetails?.portfolioOverviewAll?.NAV || 0);
      const existingAssetIndex = assetUpdate.findIndex(obj => obj.id === accountNameSel);

      if (existingAssetIndex !== -1) {
        // Update existing asset
        assetUpdate[existingAssetIndex].amount = nav;
        assetUpdate[existingAssetIndex].date = today;  // Consider moving this out if not needed frequently
      } else {
        // Add new portfolio asset
        assetUpdate.push({
          id: accountNameSel,
          type: "Investment Portfolio",
          assetType: "Investment - Liquid",
          name: accountNameSel,
          amount: nav,
          date:today,  // Consider setting this once outside the loop if possible
          currentAllocation: 0,
          targetAllocation: 0,
          differenceAllocation: 0,
          return: 10,
          volatility: 10,
          forecastPeriod: 30,
          edit: false,
        });
      }
    });

    // Dispatch the updated assets list only once after all modifications
    dispatchMyFinancials({"assets":assetUpdate });
  }
}, [portfolioResults,assetRef]);

}




function totalAmountCategory(fields,key="type"){
  return fields.reduce((acc, item) => {
    acc[item[key]] = (Number(acc[item[key]]) || 0) + Number(item.amount);
    return acc;
  }, {});
}

function groupByType(assets) {
  const grouped = assets.reduce((acc, asset) => {
    if (!acc[asset.type]) {
      acc[asset.type] = {
        type: asset.type,
        totalAmount: 0,
        items: []
      };
    }
    acc[asset.type].items.push({ name: asset.name, amount: asset.amount });
    acc[asset.type].totalAmount += asset.amount;
    return acc;
  }, {});

  // Convert the object of grouped assets into an array
  return Object.values(grouped);
}

import { Filter } from '@mui/icons-material';
import { Button, Popover, Tooltip } from '@mui/material'
import ExcelDownloadButton from 'components/Buttons/ExcelDownloadButton';
import {PageMainContentTitle} from 'components/Font/Title'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { List } from '@mui/icons-material';
import TuneIcon from '@mui/icons-material/Tune';
import DownloadIcon from '@mui/icons-material/Download';
import { handleXLDownload } from 'components/Table/TableUtils';
import SelectBox from 'components/Inputs/SelectBox';

function MetricSettingsBar({
  children,
  title="",
  icon="list",
  contentSelection=false,
  contentOptions=[],
  contentValue="",
  handleContent,
  settingsTooltip="Settings",
  addMetric=false,
  addColumn=false,
  addTab=false,
  metricSettings=false,
  displaySettings=false,
  excelDownload=false,
  excelData=[],excelWbName="",
  mainSettings,mainSettingsContent,
  handleAddColumns,handleAddTabs,handleAddMetric,handleDisplaySettings,handleMetricSettings,childrenPopover}) {
  
  return (
    <div style={{display:"flex",gap:"0.4rem",alignItems:"center",flexWrap:"wrap",width:"100%",position:"sticky",left:0,zIndex:5,background:"white"}}>
        {title && <PageMainContentTitle>{title}</PageMainContentTitle>}
        {contentSelection && <SelectBox 
        width='200px'
        fontSize='1.3rem'
        options={contentOptions} value={contentValue} 
        onChange={(e,v)=>handleContent(v)}></SelectBox>}
        {children}
        <div style={{marginLeft:"auto",display:"flex",gap:"1rem",alignItems:"center"}}>
        {mainSettings && mainSettingsContent}
        <Tooltip title={settingsTooltip}>
        <PopoverSettings
        icon={icon}
        addMetric={addMetric}
        addColumn={addColumn}
        addTab={addTab}
        metricSettings={metricSettings}
        displaySettings={displaySettings}
        excelDownload={excelDownload}
        excelData={excelData}
        excelWbName={excelWbName}
        handleAddColumns={handleAddColumns}
        handleAddTabs={handleAddTabs}
        handleMetricSettings={handleMetricSettings}
        handleAddMetric={handleAddMetric}
        handleDisplaySettings={handleDisplaySettings}
        >
          {childrenPopover}
        </PopoverSettings>
        </Tooltip>
          </div>
    </div>
  )
}

function PopoverSettings({
  children,
  icon="list",
  addMetric=false,
  addColumn=false,
  addTab=false,
  metricSettings,
  displaySettings=false,
  excelDownloadType="data",
  excelDownload=false,
  excelData=[],excelWbName="",
  handleAddColumns,handleAddTabs,handleAddMetric,handleMetricSettings,handleDisplaySettings}){
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'metric-settings' : undefined;

  return ( 
    <>
    <Button aria-describedby={id} onClick={handleClick}>
    <List/>
  </Button>
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    onMouseLeave={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
  <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "0.5rem" }}>
      {addTab && <Button  
      sx={{justifyContent:"flex-start"}}
      startIcon={<AddIcon fontSize='small' />} onClick={handleAddTabs}>
        Add Tabs
      </Button>}

      {addColumn && <Button 
       sx={{justifyContent:"flex-start"}}
      startIcon={<AddIcon fontSize='small' />} onClick={handleAddColumns}>
        Add Columns
      </Button>}

      {addMetric && <Button 
       sx={{justifyContent:"flex-start"}}
      startIcon={<AddIcon fontSize='small' />} onClick={handleAddMetric}>
        Add Metric
      </Button>}

      {metricSettings && <Button 
       sx={{justifyContent:"flex-start"}}
      startIcon={<TuneIcon fontSize='small' />} onClick={handleMetricSettings}>
        Metric Settings
      </Button>}

      
      {children}

      {displaySettings && 
      <Button 
      sx={{justifyContent:"flex-start"}}
     startIcon={<Filter fontSize='small' />} onClick={handleDisplaySettings}>
       Display Settings
     </Button>}
      {(excelDownload && excelDownloadType !=="data") && 
         <Tooltip title="Download to Excel">
         <Button
           startIcon={<DownloadIcon fontSize='small'/>}
           style={{
             float: "right",
           }}
           onClick={() => handleXLDownload(excelWbName)}
         >
           Excel Download
         </Button>
       </Tooltip>
      }
      {(excelDownload && excelDownloadType==="data") && <ExcelDownloadButton wbName={excelWbName} data={excelData} />}
    </div>
  </Popover>
  </>)
}

export {MetricSettingsBar,PopoverSettings}
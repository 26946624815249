import React, { useEffect, useState, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';
import worldMapData from '@highcharts/map-collection/custom/world.geo.json';

// Initialize the highchartsMap module
highchartsMap(Highcharts);

const MapChart = React.memo(({ data, title }) => {
  const options = useMemo(() => ({
    chart: {
      map: 'custom/world', // Use the 'custom/world' map
      panning: false,
      panKey: 'drag',
      animation:false
    },
    title: {
      text: title
    },
    subtitle: {
      text: 'Source: World Bank'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        name: title,
        data: data,
        mapData: worldMapData, // Use the world map data
        joinBy: ["iso-a2","ISO 2"],
        states: {
          hover: {
            color: '#BADA55' // Change color on hover
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        }
      }]
  }), [data, title]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        constructorType={'mapChart'}
      />
    </div>
  );
});

export default MapChart;
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { eodHistorialApiKey } from '../keys';

export default function useRealTimePriceMultipleAccounts(tickersByAccount) {
  const [prices, setPrices] = useState({});
  const [loadingPrices, setLoadingPrices] = useState(false);

  useEffect(() => {
    
    if (tickersByAccount) {
    const accounts = Object.keys(tickersByAccount);
      const allPrices = {};
      setLoadingPrices(true);

      Promise.all(accounts.map(account => {
        const tickers = tickersByAccount[account];
        return Promise.all(tickers.map(ticker => {
          const storedPrice = sessionStorage.getItem(`price_${ticker}`);
          if (storedPrice) {
            allPrices[account] = allPrices[account] || {};
            allPrices[account][ticker] = JSON.parse(storedPrice);
            return Promise.resolve(); // Resolve immediately for stored prices
          } else {
            return fetchPrice(ticker).then(priceInfo => {
              allPrices[account] = allPrices[account] || {};
              allPrices[account][ticker] = priceInfo;
              sessionStorage.setItem(`price_${ticker}`, JSON.stringify(priceInfo));  // Store each price in sessionStorage
            });
          }
        }))
      })).then(() => {
        setPrices(allPrices);
        setLoadingPrices(false);
      }).catch(error => {
        console.error(error);
        setLoadingPrices(false);
      });
    }
  }, [tickersByAccount]);

  // Function to fetch price for a single ticker
  async function fetchPrice(ticker) {
    try {
      const response = await axios.get(`https://eodhd.com/api/real-time/${ticker}?api_token=${eodHistorialApiKey}&fmt=json`);
      const priceInfo = response.data;
      for (const key in priceInfo) {
        if (key !== "code" && key !== "timestamp") {
          priceInfo[key] = parseFloat(priceInfo[key]) || 0.00;
        }
      }
      return priceInfo;
    } catch (error) {
      console.error('Failed to fetch price for ticker:', ticker, error);
      return {};  // Return an empty object in case of error
    }
  }

  return { prices, loadingPrices };
}
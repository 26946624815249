import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import useInsiderTradeStats from 'data/financialModelingPrep/CompanyOwnership/useInsiderTradeStats'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys, transformDataForLineChartMulti } from 'utils/utilGeneral'
import TimeSeriesCategoryChart from 'components/Charts/TimeSeriesCategoryChart'
import usePriceChartEod from 'data/eod/usePriceChartEod'
import LineChartMulti from 'components/Charts/LineChartMulti'

export default function InsiderTransactionStats() {
  const {stateTicker} = useAppInfo()
  const {insiderTradeStats} = useInsiderTradeStats(stateTicker.tickerStock)
 
  // const chartData = tableData.length > 0 && getTableDataWithDateKey(tableData)
  const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:stateTicker.tickerStockCode}) 
    const [chartData,setChartData] = useState([])
    const [tableData,setTableData] = useState([])
    useEffect(()=>{
     
      if (priceInfo.length>0 &&  insiderTradeStats.length > 0){
        const tableData = getSpecificArrayObjKeys(insiderTradeStats,cols)
        setTableData(tableData)  
        const priceClose = transformDataForLineChartMulti({data:priceInfo,dateFieldName:"date",valueFieldName:"adjusted_close"})
        const chartData = getTableDataWithDateKey(tableData)
        const insiderTransactionData =["totalBought","totalSold"].map((metric) => ({
            name: metric,
            data: transformDataForLineChartMulti({data:chartData,dateFieldName:"date", valueFieldName:metric}),
            chartType:"column",
            color:metric ==="totalBought"?"green":"red"
        }));
        setChartData([{name:"Close",data:priceClose,chartType:"area"}, ...insiderTransactionData]);
      }},[priceInfo,insiderTradeStats])

  
  return (
    <>
        {chartData.length>0 && 
    <div style={{width:"100%",height:"500px",minHeight:"500px"}}>
    <LineChartMulti datasets={chartData}/>
    </div>}
    {insiderTradeStats.length>0 && <TableGen tableLayout='fixed' data={tableData}/>}

    </>
  )
}

function getTableDataWithDateKey(insiderTradeStats) {
  return insiderTradeStats.map(transaction => {
    // Get the year and quarter from the transaction
    const { year, quarter } = transaction;
    // Calculate the date based on year and quarter
    const date = calculateDate(year, quarter);
    // Create a new object with date as the key
    return {
      ...transaction,
      date // Add date as a new key to the transaction object
    };
  });
}

function calculateDate(year, quarter) {
  // Determine the month based on the quarter
  const month = quarter * 3;
  // Set the day to the last day of the month
  const day = new Date(year, month, 0).getDate();
  // Return a Date object
  return new Date(year, month - 1, day);
}

const cols = ["year", "quarter", "purchases", "sales", "buySellRatio", "totalBought", "totalSold", "averageBought", "averageSold"]

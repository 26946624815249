import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { dfin } from 'content/constants';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


export default function useScreenerCompany() {
    const {dispatchStock,stateTicker,stateStock} = useAppInfo()
    const peerSelected = stateTicker.peerSelected
    const [loadingScreenerCo, setLoadingScreenerCo] = useState(true);
    const [error, setError] = useState(null);
    
    const previousPeerSelectedRef = useRef([]);
    
    useEffect(() => {
      if (peerSelected.length>0){
     
      const isPeerSelectedEqual =
        peerSelected.every(element => previousPeerSelectedRef.current.includes(element));
      
      if (!isPeerSelectedEqual) {
        getData();
      previousPeerSelectedRef.current = peerSelected;
      } }
     
      
    }, [peerSelected]);
    
    async function getData() {
      
      try {
        
        setLoadingScreenerCo(true);
        const response = await axios.get(`${BASE_URL}/screenerCoInfo/`, {
          params: {
            ticker: JSON.stringify(peerSelected),
          },
        });
        const screenCoInfo = response.data.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
        const peerInfo = screenCoInfo.filter((row) => {
          return peerSelected.includes(row[dfin.ticker]);
        })
        dispatchStock({"screenInfo":peerInfo})
        const coInfo = peerInfo.find(obj => obj[dfin.ticker] === stateStock.companySelected)
        dispatchStock({"coInfo":coInfo})
        dispatchStock({"peerInfo":peerInfo})
    
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingScreenerCo(false);
      }
    }
}

import React from 'react'
import { useAppInfo } from 'AppState';
import {Paper} from '@mui/material';
import { menuIcons } from 'nav/menuIcons';
import RcRecentlySearched from './RcRecentlySearched';
import RcTopics from './RcTopics';
import { PageContentWithLeftMenuLayout } from 'layout/PageLayout';
import PageHeader from 'layout/PageHeader';


export default function RcLibrary() {
    const {stateResource} = useAppInfo()
    const {searchLearnHistory} = stateResource

  return (
    <PageContentWithLeftMenuLayout 
    header={<PageHeader headerIcon={menuIcons.Learn} title={"Learn"}/>}
    showMenu={false}
    > 

      <div style={{display:"flex",gap:"0.5rem",width:"100%",boxSizing:"border-box"
      ,padding:"0.5rem",flex:1}}>
      <Paper style={{display:"flex",flex:1,height:"100%",minWidth:"400px"}}>
          <RcTopics/>
      </Paper>
      
      {searchLearnHistory.length > 0 && 
      <Paper elevation={0} style={{width:"20%",height:"100%",background:"#fafafa"}}>
          <RcRecentlySearched/>
          </Paper>}
        </div>


    </PageContentWithLeftMenuLayout>
  )
}


import React from 'react';

import { useAppInfo } from 'AppState';
import ScatterUI from 'components/Charts/ScatterUI';
import { dfin } from 'content/constants';

export default function IndSecConstituentsScatter() {
  const {stateIndustry} = useAppInfo()
  const info = stateIndustry.constiInfo
   
    
  // Replace the following with actual data
  const loadingScreenerISConsti = false;
  
  return (
      <>
      {!loadingScreenerISConsti && info.length > 0 && (
    <ScatterUI addPeersBar={false} data={info} defaultMet={[dfin.marketCap,dfin.pe]}/>)}
   
    </>
    
    
  );
}


const initialStateIndustry = {
  infoSel:"Scatter",
  uniqueSectors:[],
  uniqueIndustries:[],
  indSecMode:false,
  secIndList:[],
    countrySel:["USA"],
    sectorSel:["Information Technology"],
    industrySel:["Software"],
    mScaleSel:["Mega Cap","Large Cap","Mid Cap","Small Cap","Micro Cap"],
    secInfo:[],
    loadingSecInfo:false,
    secOverview:[],
    loadingSecOverview:false,
    secConsti:[],
    loadingSecConsti:false,
    indConsti:[],
    loadingIndCosnti:false,
    constiInfo:[],
    constiInfoAll:[],
    indOverview:[],
    loadingIndOverview:false,
    industryAnnualInfo:[],
    loadingIndAnnualInfo:false,
    menuSel:"Overview",
    searchIndSec:false,
    sectorClose:[],
    sectorCloseLoaded:false,
    sectorTrendTable:[],
    analysisOptionSel:"Constituents"
  }
  
  export {initialStateIndustry}
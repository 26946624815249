import SelectMarket from 'components/Inputs/SelectMarket'
import React from 'react'

export default function PageHeader({headerIcon,marketSelect,title,toolkit,content}) {

  return (
    <div className='page_header_box'>
        <div className='page_header_title_box'>
           {headerIcon} {marketSelect && <SelectMarket/>} <h2 style={{fontWeight:"bold"}}>{title}</h2>
        </div>
        <div className='page_header_content_box'>
            {content}
        </div>
        <div className='page_header_toolkit'>
            {toolkit}
        </div>
    </div>
  )
}

import React from 'react';

import { useAppInfo } from 'AppState';
import TechnicalChart from 'components/Charts/TechnicalChart';
import SearchTickerDropdown from 'search/SearchTickerDropdown';
import Technicals from 'analytics/technicals/Technicals';
import CryptoDescription from 'analytics/ticker/crypto/CryptoDescription';
import CryptoProfile from 'analytics/ticker/crypto/CryptoProfile';
import CompanyNews from 'newsEvents/CompanyNews';

import GuideTemplate from '../Template/GuideTemplate';
import { assetCode } from 'content/constants';

export default function CryptoPicking({search=true}) {
  const {stateTicker,dispatchTicker} = useAppInfo()

  const contentAfterHeading = search && (
    <div
      style={{
        padding: "0 1rem",
        width: "50%",
        boxSizing: "border-box",
        marginTop: "0.05rem",
      }}
    >
      <SearchTickerDropdown
        tickerType={assetCode?.Crypto}
        defaultSearchTerm={stateTicker.tickerCrypto}
      />
    </div>
  );
  return (
    <GuideTemplate description={description} steps={steps} name={"Crypto Analysis Guide"} contentAfterHeading={contentAfterHeading}/>
  )
}

const description = `
Crypto analysis is an in-depth evaluation process that assesses various critical aspects of cryptocurrencies to guide investment decisions. This analysis includes reviewing the project's vision, developer activity, and technological innovation. Additionally, it examines market adoption, security measures, and compliance with regulatory standards. Technical analysis of price movements and volume, alongside sentiment analysis from community and market trends, provides insights into potential trading opportunities. Staying updated with the latest news and developments is also essential. This comprehensive approach ensures investors can make informed decisions about buying, selling, or holding a cryptocurrency, aligning with their investment objectives.
`

const steps = [
  {
    label: "Crypto Story & Vision",
    keyPoints: [
      "Explore the origins, mission, and strategic vision of the cryptocurrency to understand its foundational goals.",
      "Review the competency and track record of its developers or leadership team to gauge their ability to execute the project's vision."
    ],
    success: [
      "Clear, compelling vision supported by a roadmap with achievable milestones.",
      "Leadership has a proven track record of success in related projects."
    ],
    warning: [
      "Vague or frequently changing vision.",
      "Leadership lacks experience or has a poor reputation in the community."
    ],
    content: <CryptoDescription/>,
  },
  {
    label: "Community Activity and Developer Activity",
    keyPoints: [
      "Assess the level of engagement and activity within the community and by developers to determine support and potential for ongoing improvement.",
      "Monitor forums, social media, and GitHub commits for real-time insights into community and developer enthusiasm."
    ],
    success: [
      "High levels of active participation in development and community-driven events.",
      "Consistent growth in developer contributions and community size."
    ],
    warning: [
      "Notable declines in community participation or stagnation in developer activity.",
      "Reduced frequency of updates and community interaction."
    ],
    content: <CryptoProfile/>,
  },
  {
    label: "Technology and Innovation",
    keyPoints: [
      "Evaluate the underlying blockchain technology for advancements in scalability, security, and interoperability.",
      "Assess the frequency and impact of innovative features being introduced to the platform."
    ],
    success: [
      "Regular implementation of technological enhancements.",
      "Recognition for innovation within the crypto industry."
    ],
    warning: [
      "Technology falls behind industry advancements.",
      "Infrequent updates or lack of innovative features."
    ],
    content: [],
  },
  {
    label: "Market Adoption and Use Cases",
    keyPoints: [
      "Analyze the extent of market adoption and evaluate real-world applications of the cryptocurrency.",
      "Review partnerships, collaborations, and sectors adopting this cryptocurrency to understand its practical utility."
    ],
    success: [
      "Established use cases across diverse industries.",
      "Growing number of strategic partnerships enhancing market presence."
    ],
    warning: [
      "Limited traction within intended markets.",
      "Few or no real-world applications impacting adoption rates."
    ],
    content: [],
  },
  {
    label: "Security and Regulatory Compliance",
    keyPoints: [
      "Investigate the cryptocurrency's adherence to security protocols and its history of security incidents.",
      "Review its compliance with global regulatory standards and any ongoing legal issues."
    ],
    success: [
      "Strong track record of security without significant breaches.",
      "Fully compliant with existing financial and data protection regulations."
    ],
    warning: [
      "History of security breaches or unresolved vulnerabilities.",
      "Lack of clarity on regulatory compliance or ongoing legal challenges."
    ],
    content: [],
  },
  {
    label: "Technical Analysis - Price, Volume, and Indicators",
    keyPoints: [
      "Utilize technical analysis tools to study price movements, trading volumes, and market sentiment.",
      "Apply indicators such as Moving Averages, RSI, and MACD to identify trends and potential trading opportunities."
    ],
    success: [
      "Technical patterns suggest bullish trends and strong buying signals.",
      "Consistent volume increases accompanying price gains."
    ],
    warning: [
      "Bearish indicators such as consistent lower highs and increased sell-off volumes.",
      "Divergence between price increases and trading volumes suggesting potential reversals."
    ],
    content: (
      <>
        <TechnicalChart />
        <Technicals tabName={"Indicators"} />
      </>
    ),
  },
  {
    label: "Market Trends and Sentiment Analysis",
    keyPoints: [
      "Examine broader market trends and the sentiment within the crypto community to forecast potential movements.",
      "Utilize sentiment analysis tools to gauge investor attitudes and reactions to recent news or market events."
    ],
    success: [
      "Positive market sentiment correlating with upward price movements.",
      "Alignment with broader market trends indicating potential for sustained growth."
    ],
    warning: [
      "Negative sentiment potentially leading to decreased investor confidence and price declines.",
      "Misalignment with major market trends which may indicate riskier investments."
    ],
    content: [],
  },
  {
    label: "News and Developments",
    keyPoints: [
      "Stay informed on the latest developments, regulatory changes, and technological advancements within the crypto space.",
      "Monitor how news affects market dynamics and cryptocurrency valuations."
    ],
    success: [
      "Positive news driving increased investor interest and market valuation.",
      "Regular updates from the project maintaining investor confidence."
    ],
    warning: [
      "Adverse news resulting in significant market sell-offs.",
      "Frequent negative publicity affecting investor sentiment and trust."
    ],
    content: <CompanyNews type="list" />,
  },
  {
    label: "Call to Action",
    keyPoints: [
      "Decide whether to buy, sell, or hold based on comprehensive analysis and alignment with investment goals.",
      "Evaluate short-term trading opportunities based on technical analysis and market conditions."
    ],
    success: [
      "Identified clear signals to execute trades or investments aligning with your strategy."
    ],
    warning: [
      "Unclear or conflicting indicators leading to potential high-risk positions."
    ],
    link: { link: "/Crypto/Trade", name: "Trade" },
  },
];

import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';

import { Alert } from '@mui/material';

import CompanyCapitalStructure from '../Financials/Company/CompanyCapitalStructure';

export default function DiscountRate() {
    const {stateStock,stateTicker} = useAppInfo()
   
  const coInfo = stateStock.coInfo;
  const companySelected = stateStock.companySelected;
  const coAnnualInfo = stateStock.annualInfo.filter((obj) => obj[dfin.ticker] === companySelected);
  const fairValueSource = getSpecificArrayObjKeys(coAnnualInfo, [dfin.period,dfin.cfo, dfin.fcf,]);
  const debtMetrics = getSpecificArrayObjKeys(coAnnualInfo, [dfin.period,dfin.effectiveInterestRate,dfin.effectiveTaxRate]);
  const maxPeriod = coAnnualInfo.reduce((max, obj) => {
    const currentPeriod = obj.PERIOD;
    return currentPeriod > max ? currentPeriod : max;
  }, -Infinity);

  const currentPeriod = coAnnualInfo.find((obj) => obj[dfin.period] === maxPeriod);

  const debtPercent = !isNaN(currentPeriod?.[dfin.debtPercent]) ? parseFloat(currentPeriod[dfin.debtPercent] * 100).toFixed(2) : 'N/A';
  const roic = !isNaN(currentPeriod?.[dfin.roic]) ? parseFloat(currentPeriod[dfin.roic] * 100).toFixed(2) : 'N/A';
  const effectiveIntRate = !isNaN(currentPeriod?.[dfin.effectiveInterestRate]) ? parseFloat(currentPeriod[dfin.effectiveInterestRate] * 100).toFixed(2) : 'N/A';
  const effectiveTaxRate = !isNaN(currentPeriod?.[dfin.effectiveTaxRate]) ? parseFloat(currentPeriod[dfin.effectiveTaxRate] * 100).toFixed(2) : 'N/A';

  const [riskFreeRate,setRiskFreeRate] = useState(5)
  const [marketReturn,setMarketReturn] = useState(10)
  const [beta,setBeta] = useState(coInfo?.[dfin.beta])
  const [intRate,setIntRate] = useState(effectiveIntRate)
  const [taxRate,setTaxRate] = useState(effectiveTaxRate)
  const [debtPercentage,setDebtPercent] = useState(debtPercent)
  const [equityPercent,setEquityPercent] = useState((100 - debtPercent))
  const [wacc,setWACC] = useState()

  const [ke,setKe] = useState()
  const [kd,setKd] = useState()
 useEffect(()=>{
  setBeta(coInfo?.[dfin.beta])
 },[coInfo])
 useEffect(()=>{
  setIntRate(effectiveIntRate)
 },[effectiveIntRate])
 useEffect(()=>{
  setTaxRate(effectiveTaxRate)
 },[effectiveTaxRate])


  useEffect(()=>{
    const ke = Number(riskFreeRate) + (beta*(marketReturn - riskFreeRate))
    setKe(ke.toFixed(2))
    },
    [beta,marketReturn,riskFreeRate,companySelected])

  useEffect(()=>{
    let costOfDebt;
    if (!isNaN(taxRate) && !isNaN(intRate)) {
      costOfDebt = (intRate * (1 - taxRate / 100)).toFixed(2);
    } else {
      costOfDebt = 'N/A';
    }
    setKd(costOfDebt);
  }, [taxRate, intRate,companySelected]);

  useEffect(()=>{
    const WACC = (debtPercentage/100)*kd + (100 - debtPercentage)*(ke/100)
    setWACC(WACC.toFixed(2))
  },[kd,ke,equityPercent,debtPercentage,companySelected])

  return (
    <div style={{background:"white",padding:"0.25rem"}}>
    <div style={{width:"100%",border:"1px solid #ddd",padding:"2rem",borderRadius:"25px",boxSizing:"border-box"}}>
    <h2 style={{marginTop:"1rem",fontWeight:"bold"}}>{`Weigthed Average Cost of Capital (WACC) = ${wacc}%`}</h2>
    <h3 style={{fontWeight:"bold",color:"gray"}}>= Cost of Equity x Equity % + Cost of Debt x Debt %</h3>
    <Input label="Cost of Equity" id="kEquity" value={ke} onChange={setKe} />
    <Input label="Equity (%)" id="equityPercent" value={equityPercent} onChange={setEquityPercent} />
    <Input label="Cost of Debt" id="kDebt" value={kd} onChange={setKd} />
    <Input label="Debt (%)" id="debtPercent" 
    value={debtPercentage} onChange={setDebtPercent} />
    </div>
    <div style={{display:"flex",flexWrap:"wrap",gap:"1rem",marginTop:"1rem"}}>
    
    
    <div style={{width:"100%",border:"1px solid #ddd",padding:"2rem",borderRadius:"25px",boxSizing:"border-box"}}>
    <h2 style={{fontWeight:"bold"}}>{`Cost of Equity (%): ${ke}`}</h2>
    <h3 style={{fontWeight:"bold",color:"gray"}}>Calculated according to CAPM (Capital Asset Pricing Model)</h3>
    <h3 style={{fontWeight:"bold",color:"gray"}}>= Risk Free Rate (Rf) + Beta x (Market Return - Rf)</h3>
    <div style={{width:"100%"}}>
    <Input label="Risk Free Rate (%)" id="riskFreeRate" value={riskFreeRate} onChange={setRiskFreeRate} />
    <Input label="Market Return (%)" id="marketReturn" value={marketReturn} onChange={setMarketReturn} />
    <Input label="Beta" id="beta" value={beta} onChange={setBeta} />
    <Input label="Cost of Equity" id="kEquity" value={ke} onChange={setKe} />
    </div>
    </div>

    <div style={{width:"100%",border:"1px solid #ddd",padding:"2rem",borderRadius:"25px",height:"auto",boxSizing:"border-box"}}>
    <h2 style={{fontWeight:"bold"}}>{`Cost of Debt (%): ${kd}`}</h2>
    <h3 style={{fontWeight:"bold",color:"gray"}}>= Interest Rate x (1 - Effective Tax Rate/100)</h3>
    <div style={{width:"100%"}}>
    <Input label="Effective Intetest Rate (%)" id="intRate" value={intRate} onChange={setIntRate} />
    <Input label="Effective Tax Rate (%)" id="taxRate" 
    value={taxRate} onChange={setTaxRate} />
     <Input label="Cost of Debt" id="kDebt" value={kd} onChange={setKd} />
    </div>
    <div style={{width:"100%",height:"300px"}}>
    {debtMetrics && 
  <CompanyFinancialsUI hideOnHover={true} report={false} metricSel={[dfin.effectiveInterestRate]} title={"Interest Rate"} 
  period={dfin.period}/>}
  </div>
  <div style={{width:"100%",height:"300px"}}>
    {debtMetrics && 
  <CompanyFinancialsUI hideOnHover={true} report={false}  metricSel={[dfin.effectiveTaxRate]} title={"Tax Rate"} 
  period={dfin.period}/>}
  </div>
    </div>

    <div style={{width:"100%",border:"1px solid #ddd",padding:"2rem",borderRadius:"25px",height:"auto",boxSizing:"border-box"}}>
    <h2 style={{fontWeight:"bold"}}>{`Capital Structure`}</h2>
    <h3 style={{fontWeight:"bold"}}></h3>
    <Alert severity='warning'>Book Values of Debt and Equity are shown below for the ease of calculation. You must use Market Value of Debt and Equity!<br/>
    Market Value of Equity = Market Capitalization.<br/>
    Market Value of Debt = market price investors would be willing to buy a company’s debt for. This information is not be readily available, you will have to use bond valuation to calculate the market value for tradable and non-traddable company bond.</Alert>
    <div style={{width:"100%"}}>
    <Input label="Equity (%)" id="equityPercent" value={equityPercent} onChange={setEquityPercent} />
    <Input label="Debt (%)" id="debtPercent" 
    value={debtPercentage} onChange={setDebtPercent} />
    </div>
    <div style={{width:"100%"}}>
    <CompanyCapitalStructure/>
    </div>
    </div>

    </div>
    </div>
  )
}

const Input = ({ label, id, value, onChange, step = 0.1, min = 0, max = 100 }) => (
  <div style={{ display: 'flex',width:"100%" }}>
    <label style={{ fontSize: '1.3rem', padding: '5px',minWidth:"40%" }} htmlFor={id}>
      {label}:
    </label>
    <input
      style={{ fontSize: '1rem', padding: '2px' }}
      step={step}
      id={id}
      type="number"
      value={value}
      min={min}
      max={max}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
// <PeriodSlider handleInputChange={(e,v)=>setRiskFreeRate(v)} 
//     onChange={(e,v)=>setRiskFreeRate(v)} width={"100%"} min={0} max={100} step={0.1} 
//     defaultValue={riskFreeRate} period='Risk Free Rate'/>


// const costOfEquity = (5 + coInfo?.[dfin.beta]*5).toFixed(2)
// const WACC = (debtPercent/100)*costOfDebt + (100 - debtPercent)*(costOfEquity/100)
// const tableCostEquity = [
//   {"Metric" : "Risk free Rate","Current Level":`5%`},
//   {"Metric" : "Market Return","Current Level":`10%`},
//   {"Metric" : "Beta","Current Level":coInfo?.[dfin.beta]},
// {"Metric":"Cost of Equity","Current Level":costOfEquity}]

// const tableWACC = [
//  {"Metric" : "Debt","%":`${debtPercent}%`},
//  {"Metric" : "Equity","%":`${(100 - debtPercent).toFixed(2)}%`},
//  {"Metric" : "WACC","%":WACC},
// ]

// const tableCostDebt = [
// {"Metric":"Effective Interest Rate","%":`${effectiveIntRate}%`},
// {"Mteric":"Effective Tax Rate","%":`${effectiveTaxRate}%`},
// {"Metric" : "Cost of Debt","%":`${costOfDebt}%`},]
// const tableROIC = [
//   {"Metric" : "ROIC","%":coInfo?.[dfin.roic]*100},
//   {"Metric" : "WACC","%":WACC},
// ]
import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useMarketTickerInfo(tickerList=[]) {
const [previousPeerSelected,setPreviousPeerSelected] = useState([])
const closeRef = useRef([])
const [close, setClose] = useState([]);
const [trendTable, setTrendTable] = useState([]);
const [marketInfoLoading, setMarketInfoLoading] = useState(false);

useEffect(() => {
    const isPeerSelectedEqual = tickerList?.every((element) =>
        previousPeerSelected.includes(element)
    ) && tickerList.length === previousPeerSelected.length;

    if (tickerList.length > 0) {
    if (!isPeerSelectedEqual) {
        getPriceInfo(tickerList)
        setPreviousPeerSelected(tickerList);
      }}

    }, [tickerList]);

async function getPriceInfo(tickerList) {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/marketTickerInfo/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        },
    });
    
    const marketInfo = response.data
    setClose(JSON.parse(marketInfo["close"]))
    setTrendTable(JSON.parse(marketInfo["dayTable"]))
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {close,trendTable,marketInfoLoading,};
}
import React, { useState } from 'react'
import InvestmentPreferenceModal from './InvestmentPreferenceModal'
import { Button, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

export default function InvesmentPreferenceButton({variant="contained",size="large",label=true}) {
    
  const [modal,setModal] = useState(false)

  return (
    <>
    <Tooltip title="Investment Preferences">
    <Button variant={variant}
    sx={{textTransform:"none",textAlign:"left",fontSize:size==="large"?"1rem":"0.8rem",minWidth:"0",gap:"0.3rem"}}
    onClick={()=>setModal(true)}> 
    <TuneIcon fontSize="small"/>
    {label && "Investment Preferences"}
    </Button></Tooltip>

    {modal && 
    <InvestmentPreferenceModal onClose={()=>setModal(false)}/>}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import PieChartCategory from 'components/Charts/PieChartCategory'
import { assetCode, dfin } from 'content/constants'
import { useAppInfo } from 'AppState'
import BorderBox from 'components/Layout/BorderBox'
import TableGen from 'components/Table/TableGen'
import { PageMainContentTitle } from 'components/Font/Title'
import TextInput from 'components/Inputs/TextInput'
import InvesmentPreferenceButton from 'myFinancials/InvestmentPreference/InvesmentPreferenceButton'
import TabLine from 'components/Tabs/TabLine'
import { formatNumber } from 'utils/utilGeneral';
import { SetInvestmentPreferenceType } from 'myFinancials/InvestmentPreference/InvestmentPreferences'

export default function AssetAllocation({type="All",showEdit=true}) {
    const {statePortfolio,stateMyFinancials} = useAppInfo()
    
    const data = [...statePortfolio.portfolioTable]
        
    const [dataStock, setDataStock] = useState([]);
    const [dataEtf, setDataEtf] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabList, setTabList] = useState(["Asset Class"]);
  
    useEffect(() => {
      if (data) {
        const filteredStocks = data.filter(obj => ["Stocks", assetCode?.Stocks].includes(obj.investmentType));
        const filteredEtfs = data.filter(obj => ["ETF", assetCode?.ETF].includes(obj.investmentType));
        setDataStock(filteredStocks);
        setDataEtf(filteredEtfs);
      }
    }, []);
  
    useEffect(() => {
      let newTabList = ["Asset Class"];
      if (dataStock.length > 0) {
        newTabList.push("Stocks");
      }
      if (dataEtf.length > 0) {
        newTabList.push("Etf");
      }
      setTabList(newTabList);
    }, [dataStock, dataEtf]);

  return (
    <div className='containerColumnFlex'>
    {showEdit &&  
    <div style={{display:"flex",gap:"1rem"}}>
    <PageMainContentTitle>Asset Allocation</PageMainContentTitle>
    <div style={{marginLeft:"auto"}}><InvesmentPreferenceButton size='small' variant='highlight'/></div>
    </div>}

    <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}></TabLine>
    
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem"}}>

    {tabList[tabIndex]==="Asset Class" && 
    <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",width:"100%"}}>
    <PieChartCategory 
    title='Asset Class'
    showConnectors={false} weightType='weighted' weightCategory={["Net Asset Value"]} 
    height='400px' width='400px' data={data} category={["investmentType"]}/>
     <div style={{flex:1,minWidth:"600px"}}>
     <AssetAllocationTable data={data} category={["investmentType"]} showTargetAllocation={showEdit}/>
     </div>
    </div>}
   
    {(type === "All" && dataStock && tabList[tabIndex]==="Stocks") &&
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",width:"100%",boxSizing:"border-box"}}>
    {[dfin.sector,dfin.industry,dfin.mScale,dfin.country].map(obj=>
    <div style={{display:"flex",flexWrap:"wrap"
    ,width:"100%",boxSizing:"border-box",gap:"1rem"}} key={obj} >
     <PieChartCategory 
     showConnectors={false}
     height='400px' width='400px' 
     title={obj}
     weightType='weighted' 
     weightCategory={["Net Asset Value"]} data={dataStock} category={[obj]}/>
     <div style={{flex:1,minWidth:"600px"}}>
     <AssetAllocationTable data={dataStock} category={[obj]}/>
     </div>
    {/* <h3 style={{color:"gray"}}>{obj}</h3> */}
    </div>
    )}
    </div>
    }

    {(type === "All" && dataEtf && tabList[tabIndex]==="Etf") && 
      <div style={{display:"flex",flexDirection:"column",gap:"1rem",width:"100%",boxSizing:"border-box"}}>
    {[dfin.country].map(obj=>
    <div style={{display:"flex",flexWrap:"wrap",width:"100%",boxSizing:"border-box",gap:"1rem"}} key={obj} >
     <PieChartCategory 
     showConnectors={false}
     height='400px' width='400px' 
     title={obj}
     weightType='weighted' 
     weightCategory={["Net Asset Value"]} data={dataEtf} category={[obj]}/>
     <div style={{flex:1,minWidth:"600px"}}>
     <AssetAllocationTable data={dataEtf} category={[obj]}/>
     </div>
    {/* <h3 style={{color:"gray"}}>{obj}</h3> */}
    </div>
    )}
    </div>
    }
    </div>
    </div>
  )
}

function AssetAllocationTable({ data, category,showTargetAllocation=false}) {
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
  
  
  const {investmentPreference,investmentPreferenceRiskTolerance,investmentPreferenceType,currency} = stateMyFinancials
  const targetAllocations = investmentPreferenceType?.type === "Manual" ? investmentPreference : investmentPreferenceRiskTolerance
  const storeName = investmentPreferenceType?.type === "Manual" ? "investmentPreference" : "investmentPreferenceRiskTolerance"
  const tableData = WeightedCategoryData({ data, category });


  const handleTargetChange = (value,id) => {
    const updatedFields = targetAllocations.map((fieldObj, i) => {
      if (fieldObj.id === id) {
        return { 
          ...fieldObj,
          ["targetAllocation"]: value,
        };
      }
      return fieldObj;
    })
    dispatchMyFinancials({[storeName]:updatedFields})
  };


  const getTargetAllocation = (type) => {
    const target = targetAllocations.find(item => assetCode[item.type] === type);
    return target ? target.targetAllocation : 0;
  };

  const getTargetId = (type) => {
    const target = targetAllocations.find(item => assetCode[item.type] === type);
    return target ? target.id : "";
  };

  return (
    <BorderBox>
    {showTargetAllocation && <div style={{display:"flex",gap:"1rem"}}>
     <InvesmentPreferenceButton size='small' variant='text' label={false}/>
    <SetInvestmentPreferenceType label='Set Allocation based on your Risk Tolerance'/>
    </div>}
    <div style={{width:"100%",height:"100%",overflow:"auto"}}>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount ({currency?.symbol})</th>
            <th>Current Allocation (%)</th>
            {showTargetAllocation && 
            <>
            <th>Target Allocation (%)</th>
            <th>Difference in Allocation (%)</th></>}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.type}</td>
              <td>{formatNumber(row.value)}</td>
              <td>{row.percentage}</td>
              {showTargetAllocation && 
              <>
              <td>
                {index !== tableData.length - 1 ? (
                  <TextInput
                    type="number"
                    value={getTargetAllocation(row.type)}
                    onChange={(v) => handleTargetChange(v,getTargetId(row.type))}
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                {index !== tableData.length - 1
                  ? (getTargetAllocation(row.type) - row.percentage).toFixed(2)
                  : ""}
              </td></>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </BorderBox>
  );
}


function WeightedCategoryData({ data, weightCategory = "Net Asset Value", category}) {
  const result = {};
  let totalValue = 0;

  data.forEach(item => {
    const value = item[category];
    const weight = item[weightCategory] || 0;
    totalValue += weight;

    if (!result[value]) {
      result[value] = { type: value, value: 0, percentage: 0 };
    }

    result[value].value += weight;
  });

  Object.keys(result).forEach(key => {
    result[key].percentage = ((result[key].value / totalValue) * 100).toFixed(2);
  });

  const output = Object.values(result);
  output.push({ type: "Total", value: totalValue, percentage: "100.00" });

  return output;
}
import { useAppInfo } from 'AppState';
import { assetCode } from 'content/constants';

export default function useHandlePeers() {
    
    const {dispatchTicker,stateTicker} = useAppInfo()

    function handleAddToPeers(ticker) {
        let newPeers;
        let selectedItems = stateTicker.peerSelectedDisplay;
        if (selectedItems.includes(ticker)) {
          newPeers = selectedItems.filter((item) => item !== ticker);
          if (stateTicker.tickerType === assetCode?.Stocks) {
            dispatchTicker({"peerSelected":newPeers });
          }
          dispatchTicker({"peerSelectedDisplay":newPeers });
        } else {
          newPeers = [...selectedItems, ticker];
          if (stateTicker.tickerType === assetCode?.Stocks) {
            dispatchTicker({"peerSelected":newPeers });
          }
    
          dispatchTicker({"peerSelectedDisplay":newPeers });
        }
      }

    return {handleAddToPeers}
}

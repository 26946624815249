import { useAppInfo } from "AppState"
import { assetTypes } from "appState/initialStateMyFinancials"
import PieChartCategory from "components/Charts/PieChartCategory"
import FieldFormGen from "myFinancials/FormFields/FieldFormGen"
import { useEffect, useState } from "react"


export default function AssetAllocation ({showDetails=true,chartHeight="400px",allocationType="name"}){
    const {stateMyFinancials} = useAppInfo()
    const {assets} = stateMyFinancials

    
    return (
    <div style={{display:"flex",flexDirection:"column"}}>
    <div style={{width:"100%",height:chartHeight}}>  
    <PieChartCategory showConnectors={false} 
    weightType='weighted' weightCategory={"amount"} data={assets} category={allocationType}/>
    </div>
    {showDetails && 
    <div style={{flex:1}}>
    <FieldFormGen storeName={"assets"} 
      data={assets}
      showType={true}
      allocation={true}
      allocationDetails={true}
      typeOptions={assetTypes}
      editDetails={false}
      /></div>}
    </div>)
  }
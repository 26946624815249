// Helper function to calculate percentage change
const calculateReturns = (prices) => {
    const returns = [];
    for (let i = 1; i < prices.length; i++) {
        const ret = (prices[i].value - prices[i - 1].value) / prices[i - 1].value;
        returns.push({ date: prices[i].date, return: ret });
    }
    return returns;
};

// Helper function to calculate volatility
const calculateVolatility = (returns, periods) => {
    const meanReturn = returns.reduce((acc, cur) => acc + cur.return, 0) / returns.length;
    const squaredDiffs = returns.map(r => Math.pow(r.return - meanReturn, 2));
    const variance = squaredDiffs.reduce((acc, cur) => acc + cur, 0) / (returns.length - 1);
    return Math.sqrt(variance * periods);
};

// Helper function to calculate VaR
const calculateVaR = (returns, quantile = 0.05) => {
    const sortedReturns = returns.map(r => r.return).sort((a, b) => a - b);
    const index = Math.floor(quantile * sortedReturns.length);
    return sortedReturns[index];
};

function getPortfolioVsBenchmarkOverview(dataTicker, dataBenchmark, tickers, benchmark) {

    // Parse data and synchronize dates, dropping NaNs
    const parseData = (data, ticker) => {
        return data.map(entry => ({
            date: new Date(entry.Date),
            value: entry[ticker]
        })).filter(entry => entry.value !== undefined && !isNaN(entry.value));
    };

    // Merge the datasets based on dates
    const mergeData = (data1, data2) => {
        const merged = [];
        const data2Map = data2.reduce((acc, cur) => {
            acc[cur.Date] = cur;
            return acc;
        }, {});

        data1.forEach(entry1 => {
            const entry2 = data2Map[entry1.Date];
            if (entry2) {
                merged.push({ ...entry1, ...entry2 });
            }
        });

        return merged;
    };

    // Resample data by time period
    const resampleData = (data, period) => {
        const resampled = {};
        data.forEach(entry => {
            const date = entry.date;
            let periodKey;
            switch (period) {
                case 'Y':
                    periodKey = date.getFullYear();
                    break;
                case 'Q':
                    periodKey = `${date.getFullYear()}-Q${Math.floor(date.getMonth() / 3) + 1}`;
                    break;
                case 'M':
                    periodKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
                    break;
                case 'W':
                    const startOfYear = new Date(date.getFullYear(), 0, 1);
                    periodKey = `${date.getFullYear()}-W${Math.floor((date - startOfYear) / (7 * 24 * 60 * 60 * 1000)) + 1}`;
                    break;
                default:
                    periodKey = date.toISOString().split('T')[0];
                    break;
            }
            if (!resampled[periodKey]) {
                resampled[periodKey] = [];
            }
            resampled[periodKey].push(entry.value);
        });

        const result = [];
        Object.keys(resampled).forEach(key => {
            const values = resampled[key];
            result.push({
                date: key,
                value: values[values.length - 1] // Use the last value in the period
            });
        });

        return result;
    };

    const mergedData = mergeData(dataTicker, dataBenchmark);

    const detailedResults = [];
    let tableData = [];

    const calculateMetrics = (ticker, prices, benchmarkPrices) => {
        const annualReturns = calculateReturns(resampleData(prices, 'Y'));
        const quarterlyReturns = calculateReturns(resampleData(prices, 'Q'));
        const monthlyReturns = calculateReturns(resampleData(prices, 'M'));

        const dailyVolatility = calculateVolatility(calculateReturns(prices), 252);
        const weeklyVolatility = calculateVolatility(calculateReturns(resampleData(prices, 'W')), 52);
        const monthlyVolatility = calculateVolatility(calculateReturns(resampleData(prices, 'M')), 12);
        const quarterlyVolatility = calculateVolatility(calculateReturns(resampleData(prices, 'Q')), 4);
        const yearlyVolatility = calculateVolatility(calculateReturns(resampleData(prices, 'Y')), 1);

        const dailyVaR = calculateVaR(calculateReturns(prices)) * 100;
        const weeklyVaR = calculateVaR(calculateReturns(resampleData(prices, 'W'))) * 100;
        const monthlyVaR = calculateVaR(calculateReturns(resampleData(prices, 'M'))) * 100;
        const quarterlyVaR = calculateVaR(calculateReturns(resampleData(prices, 'Q'))) * 100;
        const yearlyVaR = calculateVaR(calculateReturns(resampleData(prices, 'Y'))) * 100;

        const covariance = calculateReturns(prices).map((ret, i) => ret.return * (calculateReturns(benchmarkPrices)[i] ? calculateReturns(benchmarkPrices)[i].return : 0)).reduce((acc, cur) => acc + cur, 0) / calculateReturns(prices).length;
        const benchmarkVariance = calculateReturns(benchmarkPrices).map(ret => Math.pow(ret.return, 2)).reduce((acc, cur) => acc + cur, 0) / calculateReturns(benchmarkPrices).length;
        const beta = covariance / benchmarkVariance;

        const ytdReturns = calculateReturns(prices).filter(r => new Date(r.date).getFullYear() === new Date().getFullYear());
        const ytdReturn = ytdReturns.reduce((acc, cur) => acc + cur.return, 0) * 100;

        const detailedMetrics = {
            ticker,
            annual_returns: annualReturns,
            quarterly_returns: quarterlyReturns,
            monthly_returns: monthlyReturns,
        };

        const formattedMetrics = {
            Particulars: [
                { Particulars: 'YTD Return', [ticker]: ytdReturn },
                { Particulars: 'Daily Volatility', [ticker]: dailyVolatility },
                { Particulars: 'Weekly Volatility', [ticker]: weeklyVolatility },
                { Particulars: 'Monthly Volatility', [ticker]: monthlyVolatility },
                { Particulars: 'Quarterly Volatility', [ticker]: quarterlyVolatility },
                { Particulars: 'Yearly Volatility', [ticker]: yearlyVolatility },
                { Particulars: 'Daily VaR', [ticker]: dailyVaR },
                { Particulars: 'Weekly VaR', [ticker]: weeklyVaR },
                { Particulars: 'Monthly VaR', [ticker]: monthlyVaR },
                { Particulars: 'Quarterly VaR', [ticker]: quarterlyVaR },
                { Particulars: 'Yearly VaR', [ticker]: yearlyVaR },
                { Particulars: 'Beta', [ticker]: beta },
            ],
            Ticker: ticker
        };

        return { detailedMetrics, formattedMetrics };
    };

    const benchmarkPrices = parseData(mergedData, benchmark);
    tickers.forEach(ticker => {
        const prices = parseData(mergedData, ticker);
        const { detailedMetrics, formattedMetrics } = calculateMetrics(ticker, prices, benchmarkPrices);
        detailedResults.push(detailedMetrics);
        tableData = [...tableData, ...formattedMetrics.Particulars];
    });

    // Also calculate metrics for the benchmark itself
    const { detailedMetrics: benchmarkDetailedMetrics, formattedMetrics: benchmarkFormattedMetrics } = calculateMetrics(benchmark, benchmarkPrices, benchmarkPrices);
    detailedResults.push(benchmarkDetailedMetrics);

    tableData = tableData.map(item => {
        const benchmarkMetric = benchmarkFormattedMetrics.Particulars.find(metric => metric.Particulars === item.Particulars);
        return { ...item, [benchmark]: benchmarkMetric[benchmark] };
    });

    return { detailedResults, tableData };
}

export { getPortfolioVsBenchmarkOverview };

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import TextInput from 'components/Inputs/TextInput';
import SelectBox from 'components/Inputs/SelectBox';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MultiSelect from 'components/Inputs/MultiSelect';
import { useAppInfo } from 'AppState';
import { marketScaleOptions } from 'content/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useAddFinancials from 'data/user/financials/useAddFinancials';
import { dividendOptions, liquidityOptions, riskOptions } from 'content/contentInvestmentPreference';

const FieldFormInvestmentPreferences = ({data,storeName,profileCompletion=false}) => {
  const {dispatchMyFinancials,stateMyFinancials} = useAppInfo()
  const [fields, setFields] = useState([]);
  const [savedId,setSavedIds] = useState([])
  const [unsavedId,setUnsavedId] = useState([])
  const [expand,setExpand] = useState({id:"",show:false})
  const {addFinancials} = useAddFinancials()
  

  useEffect(() => {
    if (data.length>0){
    setFields(data);
    setSavedIds(data.map(obj=>obj.id))}
    // else{
    //   setFields([defaultField ])
    // }
    
  }, [data]);

  const handleChange = (name,value,id) => {
    // const { name, value } = event.target;
    const updatedFields = fields.map((fieldObj, i) => {
      if (fieldObj.id === id) {
        return { 
          ...fieldObj,
          [name]: value,
        };
      }
      return fieldObj;
    });
    if (!unsavedId.includes(id)) {
        setUnsavedId([...unsavedId, id]);
        setSavedIds(savedId=>savedId.filter(obj=>obj !== id))
      }
    setExpand({id:id,show:true})
    setFields(updatedFields);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    dispatchMyFinancials({[storeName]:fields})
    
    if (profileCompletion){
    const progressUpdate = stateMyFinancials?.profileCompletion
    progressUpdate[storeName] = true
    dispatchMyFinancials({"profileCompletion":progressUpdate});
    addFinancials( progressUpdate,"profileCompletion")
    }
    setTimeout(()=>addFinancials(fields,storeName),1000)
    setUnsavedId([])
};

  function handleStocksChange(name,values,id){
    let valueUpdate = values
    if (Array.isArray(values) && values.includes("All")){
      valueUpdate = ["All"];
    }
    const updatedFields = fields.map(asset => {
      if (asset.type === "Stocks") {
        return {
          ...asset,
          criterias: {
            ...asset.criterias,
            [name]: valueUpdate
          }
        };
      }
      return asset;
    });
    
    if (!unsavedId.includes(id)) {
      setUnsavedId([...unsavedId, id]);
      setSavedIds(savedId=>savedId.filter(obj=>obj !== id))
    }
    setFields(updatedFields)
  }

  return (
    <>
    <form onSubmit={handleSubmit}>
      <div style={{display:"flex",flexDirection:"column",gap:"1rem",width:"100%",boxSizing:"border-box",padding:"1rem 0"}}>
      <div style={{ width: "100%" }}>
            <table style={{ width: "100%", borderCollapse: "collapse",tableLayout:"fixed"}}>
                <thead>
                    <tr>
                      <th>Selection</th>
                      <th>Asset Type</th>
                      <th>Target Allocation</th>
                        {/* <th>General Return</th> */}
                        <th>Risk Level</th>
                        <th>Liquidity</th>
                        {/* <th>Current Allocation</th> */}
                        
                        <th>Primary Objective</th>
                        <th>ESG</th>
                        <th>More Details</th>
                        {/* <th>Difference in Allocation</th> */}
                    </tr>
                </thead>
                <tbody>
                    {fields.map((fieldObj, index) => (
  <React.Fragment key={index}>
  <tr>
      <td onClick={() => handleChange("selected",fieldObj?.selected ? false:true,fieldObj.id)}>
          <Button>
              {fieldObj.selected ? <CheckCircleIcon sx={{color: "green"}}/> : <AddCircleOutlineIcon />}
          </Button>
      </td>
      
      <td>{fieldObj.type}</td>
      {/* <td>{fieldObj.currentAllocation}</td> */}
      <td>
          <TextInput sx={{width:"100%"}} min={0} max={100} type='number' value={fieldObj.targetAllocation} onChange={(v) => handleChange("targetAllocation",Number(v),fieldObj.id)} />
      </td>
      {/* <td style={{whiteSpace:"break-spaces"}}>{fieldObj.return}</td> */}
      <td><SelectBox value={fieldObj.risk} options={riskOptions}
      onChange={(e,v) => handleChange("risk",v,fieldObj.id)}/></td>
       <td><SelectBox value={fieldObj.liquidity} options={liquidityOptions}
      onChange={(e,v) => handleChange("liquidity",v,fieldObj.id)}/></td>
      <td><MultiSelect variant='standard' 
      limitTags={0} options={fieldObj.primaryObjectiveOptions} values={fieldObj.primaryObjectiveSelected} onChange={(v)=>handleChange("primaryObjectiveSelected",v,fieldObj.id)}/></td>
      <td><SelectBox width='100%' options={["Not Important","Important","Very Important"]} value={fieldObj.esg} onChange={(e,v)=>handleChange("esg",v,fieldObj.id)}/></td>
      <td>
      {(fieldObj?.criterias && fieldObj.type === "Stocks") && <Button onClick={()=>setExpand({id:fieldObj.id,show:!expand.show})}>
        {(fieldObj.id === expand.id && expand.show) ?  <>Hide details<KeyboardArrowUpIcon/></> : <>More details <KeyboardArrowDownIcon/></>}
        
        </Button>}
      </td>
  </tr>
  {(fieldObj?.criterias && expand.id === fieldObj.id && expand.show && fieldObj.type === "Stocks") && (
      <tr>
          <td colSpan={8} style={{border:"1px solid #ddd"}}>
              More details about {fieldObj.type}
              {/* Render additional details here */}
              {fieldObj.type === "Stocks" && 
              <Stocks handleChange={(n,v)=>handleStocksChange(n,v,fieldObj.id)} data={fields}/>
              }
          </td>
      </tr>
  )}
</React.Fragment>

                    ))}
                </tbody>
            </table>
        </div>
     
      <div style={{display:"flex",gap:"1rem",position:"sticky",bottom:"0",padding:"0.5rem 0",background:"white",zIndex:"1000"}}>

      {(fields.length>0 && unsavedId.length>0) && 
      <div>
      <Button variant='contained' type='submit'>Save</Button></div>}
      </div>
      </div>
    </form>

    </>
  );
};

export default FieldFormInvestmentPreferences;


function Stocks ({handleChange,data}){
  
  const {stateIndustry,stateMyFinancials} = useAppInfo()
  const criterias = data.filter(obj=>obj.type==="Stocks")[0]?.criterias
  const marketCap = criterias?.["Market Cap"] 
  const industry = criterias?.["Industry"]
  const marketRegion = criterias?.["Market Region"]
  const dividend = criterias?.["Dividend"]
  const sector = criterias?.["Sector"]
  const derivative = criterias?.["Derivatives"]
  return (
    <div style={{padding:"1rem",display:"flex",gap:"1rem",display:"flex"}}>

<div style={{width:"50%"}}>
<MultiSelect  tags={true} limitTags={5}  label={"Market Cap"} 
      options={["All",...marketScaleOptions]} 
      values={marketCap} onChange={(v)=>handleChange("Market Cap",v)}/>

<MultiSelect  tags={true} limitTags={5}  label="Sector" 
    options={["All",...stateIndustry.uniqueSectors]} values={sector} 
    onChange={(v)=>handleChange("Sector",v)}/>
    
    <MultiSelect  tags={true} limitTags={5}  label="Industry" 
    options={["All",...stateIndustry.uniqueIndustries]} values={industry} 
    onChange={(v)=>handleChange("Industry",v)}/>

  {/* <MultiSelect tags={true} limitTags={5} label="Market Region" 
    options={["All","USA","Canada","India","UK"]} values={marketRegion} 
    onChange={(v)=>handleChange("Market Region",v)}/> */}
</div>
<div style={{width:"50%"}}>
    <SelectBox width='100%' label={"Dividend"} options={dividendOptions}
    value={dividend} onChange={(e,v)=>handleChange("Dividend",v)}
    />
     <SelectBox width='100%' label={"Derivatives"} options={["Hedging","Hedging + Speculation","Speculation"]}
    value={derivative} onChange={(e,v)=>handleChange("Derivatives",v)}
    />
    </div>
      </div>
  )
}
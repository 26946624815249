import React from 'react';

import { useAppInfo } from 'AppState';
import {
  defaultMetrics,
  dfin,
} from 'content/constants';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';

import CompanyEarningTrends from '../Financials/Company/CompanyEarningTrends';
import CompanyVsIndustryUI from '../VsIndustry/CompanyVsIndustryUI';

export default function GrowthRate() {
    const {stateStock,stateTicker} = useAppInfo()
   
    const coInfo = stateStock.coInfo;
    const companySelected = stateStock.companySelected;
    const coAnnualInfo = stateStock.annualInfo.filter((obj) => obj[dfin.ticker] === companySelected);
  return (
    <>
    {coAnnualInfo.length>0 && <CompanyFinancialsUI report={false} metricView={"growth"} 
    hideOnHover={true} metricSel={[dfin.fcf,dfin.cfo,dfin.netIncome]}/>}
    
    <CompanyVsIndustryUI defaultMet={defaultMetrics.Growth}/>
    
    <CompanyEarningTrends/>
   
    </>
  
  )
}

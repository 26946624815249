import Modal from 'components/Modal/Modal'
import React from 'react'

export default function AddFinancialsModal({title,children,onClose}) {
  return (
    <Modal 
    sx={{width:"90%",height:"70%"}} 
    onClose={onClose}>

    <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%",gap:"1rem"}}>
    <h2>{title}</h2>
    <div style={{flex:1,overflow:"auto",display:"flex",flexDirection:"column"}}>
    {children}
    </div>

    
    </div>
    </Modal>
  )
}
import React, { useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import {assetCode, dfin } from 'content/constants';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import { useAppInfo } from "AppState";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./SearchMain.module.css";
import useSearchTicker from "data/ticker/useSearchTicker";
import LogoTicker from 'components/LogoTicker';


export default function SearchTickerDropdown({tickerType,searchBar="outlined",defaultSearchTerm,required=true,handleTicker,handleTickerType="default"}) {
  const handleTickerChange = useHandleTickerChange()
  const [search, setSearch] = useState(false);
 
  
  const [searchTerm, setSearchTerm] = useState();

  useEffect(()=>{
    if (defaultSearchTerm){
      setSearchTerm(defaultSearchTerm)
    }
  },[defaultSearchTerm])
  const dropdownRef = useRef(null);

  function handleSearch() {
    setSearch(!search);
  }

  function handleSearchChange(event) {
    setSearch(true);
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  }

  function handleTickerSelected(e, obj) {
    setSearchTerm(obj[dfin.ticker])
    setSearch(false);
    const tickerSelected = obj[dfin.tickerCode]
    if (handleTickerType==="default"){
    handleTickerChange({ticker:tickerSelected,modal:false});}
    else{
      handleTicker(obj)
    }
  }


  useEffect(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const shouldDisplayAbove = window.innerHeight - rect.bottom < 0;
      const newTop = shouldDisplayAbove ? `-${rect.height}px` : '100%';
      dropdownRef.current.style.top = newTop;
    }
  }, [search]);
  
  return (
    <>
      <div
        style={{
          background: 'white',
          position: 'relative',
          display: 'flex',
          width: '100%',
          overflow: 'visible',
          boxSizing: 'border-box',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ flex: 1 }}>
          <TextField
           variant={searchBar}
            name="ticker"
            type="search"
            placeholder={'Select Ticker, eg. AAPL or Apple'}
            label="Select Ticker"
            required={required}
            // error={stateTicker.tickerInfo.filter(obj => obj[dfin.ticker]===searchTerm).length>0?false:true}
            // helperText={stateTicker.tickerInfo.filter(obj => obj[dfin.ticker]===searchTerm).length===0&&"Enter a valid tikcer"}
            onClick={handleSearch}
            value={searchTerm?searchTerm.toUpperCase():searchTerm}
            onChange={handleSearchChange}
            size='small'
            sx={{width:"100%",marginTop:"0.3rem"}}
            InputLabelProps={{
                shrink: true, // Ensures that the label is always positioned at the top
              }}
          />
        </div>

        {search && (
          <div
            ref={dropdownRef}
            style={{
              padding: '0',
              boxShadow: 'var(--boxShadowGeneral)',
              boxSizing: 'border-box',
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              zIndex: '100000',
              background: 'white',
              height: '300px',
            }}
          >
            <TickerDropdownTable 
            tickerType={tickerType} 
            searchTerm={searchTerm} 
            handleTickerSelected={handleTickerSelected} />
          </div>
        )}
      </div>
    </>
  );
}



function TickerDropdownTable({tickerType, searchTerm, handleTickerSelected }) {
  const {stateTicker,stateSearch,} = useAppInfo();

  const tickerInfo = stateTicker.tickerInfo;
  const [filteredList, setFilteredList] = useState([]);
  const tableColumns = ["Ticker", "Name", "Exchange"];
  const tableRows = ["TICKER", "NAME", "EXCHANGE"];


  useEffect(() => {
    const filteredResults = tickerInfo.filter(
      (item) => item[dfin.country] === "USA"
    );

    const sortedResults = filteredResults.sort(
      (a, b) => b[dfin.marketCap] - a[dfin.marketCap]
    );
    const topResults = sortedResults.slice(0, 50);

    setFilteredList(topResults);
  }, [stateSearch.searchClick]);

  const [loading, setLoading] = useState(false);
  const searchInfo = useSearchTicker(searchTerm);

  useEffect(() => {
    let filteredResults = ["All",assetCode?.Stocks].includes(tickerType)?tickerInfo.filter(obj=>obj["COUNTRY"]==="USA"):tickerInfo
    if (searchTerm !== "" && searchTerm) {
      
      filteredResults = tickerInfo.filter(
        (item) =>
          (item[dfin.ticker] &&
            item[dfin.ticker].toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item[dfin.coName] &&
            item[dfin.coName].toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  const sortedResults = filteredResults.sort(
      (a, b) => b[dfin.marketCap] - a[dfin.marketCap]
    );
    
    const topResults =sortedResults.filter(
      (item) => item["TYPE"] === tickerType).slice(0, 20);
    setFilteredList(topResults);

  }, [searchTerm, searchInfo.searchedTicker,tickerType]);

 
  return (
    <>
      <div
        style={{
          maxHeight: "100%",
          overflow: "auto",
          marginTop: "0.5rem",
          width: "100%",
          width: "100%",
        }}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              {tableColumns.map((colHeader) => (
                <th key={colHeader}>{colHeader}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <LinearProgress />
            ) : (
              filteredList.map((obj, index) => (
                <tr
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={(e) => handleTickerSelected(e, obj)}
                  key={index}
                >
                  {tableRows.map((row, rowIndex) => (
                    <td key={rowIndex}>
                      {row !== dfin.ticker ? (
                        obj[row]
                      ) : (
                        <div className={styles.logoTick}>
                          <LogoTicker  tickerSel={obj[dfin.ticker]}/>
                          {obj[row]}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import PieChart from 'components/Charts/PieChart';
import TabLine from 'components/Tabs/TabLine';
import { dfin } from 'content/constants';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';
import CompanyVsIndustryUI from '../../VsIndustry/CompanyVsIndustryUI';
import {PageMainContentTitle} from 'components/Font/Title';

export default function CompanyCapitalStructure() {
  const { stateStock ,stateTicker} = useAppInfo();
  const {annualInfo} = stateStock
  const [statatementInfoCo,setStatementInfoCo] = useState([])

  useEffect(()=>{
    const statatementInfoCo = annualInfo
    .filter((obj) => obj[dfin.ticker] === stateStock.companySelected)
    .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
    .slice(0, 10);
    setStatementInfoCo(statatementInfoCo)
  },[stateStock.companySelected,annualInfo])
  
  const tabList = ["Standalone","vs Industry"]
  const [tabIndex,setTabIndex] = useState(0)


  return (
    <div className='containerColumnFlexNoGap'>
       <PageMainContentTitle>Capital Structure</PageMainContentTitle>
       
    <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
{tabIndex === 0 && 
<div style={{flex:1}}>
<div style={{width:"100%",display:"flex",height:"40%",flexWrap:"wrap"}}>
        
        {statatementInfoCo.length>0 && <PieChart
         width={"33%"}
       data={statatementInfoCo}
       title={"Capital Structure"}
       metricSelected={[dfin.equity,dfin.liability]}
       
     />}
          {statatementInfoCo.length>0 && <PieChart
          width={"33%"}
       data={statatementInfoCo}
       title={"Liability"}
       metricSelected={["SHORT TERM DEBT","LONG TERM DEBT","OTHER LIAB"]}
       
     />}
      {statatementInfoCo.length>0 && <PieChart
       width={"33%"}
       data={statatementInfoCo}
       title={"Equity"}
       metricSelected={["CAPITAL STOCK","ACCUMULATED OTHER COMPREHENSIVE INCOME"]}
         />}
     </div>
   
       <div style={{width:"100%",flex:1}}>
       <CompanyFinancialsUI
       report={false}
       chart={"area"}
       metricSel={["EQUITY %","DEBT %",]}
     /></div>
     </div>
     }
  {tabIndex === 1 && 
   <CompanyVsIndustryUI defaultMet={[dfin.de,dfin.debtPercent]}/>}
   </div>
  )
}

import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import React from 'react'

export default function NetworthStepsToGrow() {
  return (
    <div style={{flex:1,minWidth:"400px",padding:"0.25rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
    <div sx={{display:"flex",flexDirection:"column",gap:"0.5rem",height:"auto"}}>
    <h3 style={{fontWeight:"bold",color:"green",padding:"0.5rem"}}>Steps to grow your Networth</h3>
    <AccordionTSCustom title={"1. Allocate Net Cash periodically to build long-term assets"}></AccordionTSCustom>
    <AccordionTSCustom title={"2. Reinvest returns on savings & investments"}></AccordionTSCustom>
    <AccordionTSCustom title={"3. Build & Manage Portfolio that are diversified and meet your risk profile & goals"}></AccordionTSCustom>
    <AccordionTSCustom title={"4. Manage Liabilities"}></AccordionTSCustom>
    </div>
    
    </div>
  )
}

import { useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

const useFindDefaultPortfolio = () => {
  const { dispatchPortfolio,stateApp} = useAppInfo()
  const userId = stateApp.userId

  const findPortfolio = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/findDefaultPortfolio`);
      const portfolio = response.data["PortfolioDefault"];
      dispatchPortfolio({
        portfolioDefaultLoaded: true,
        portfolioDefault: portfolio
      });
    } catch (err) {
      dispatchPortfolio({ portfolioDefaultLoaded: true });
    }
  };

  useEffect(() => {
    if (userId) {
      findPortfolio();
    }
  }, [userId]);

  return null; 
};

export default useFindDefaultPortfolio;

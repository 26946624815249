import React from 'react'
import MovingAverageCross from './MovingAverageCross'
import RSI from './RSI'
import OBVpeers from './OBVpeers'
import ATRpeers from './ATRpeers'

export default function IndicatorList({indicator,tickerList}) {
  
  const indicatorList = {
    "EMA":<MovingAverageCross tickerList={tickerList} maType='EMA'/>,
    "SMA": <MovingAverageCross tickerList={tickerList} maType='SMA'/>,
    "RSI":<RSI tickerList={tickerList}/>,
    "ATR":<ATRpeers tickerList={tickerList}/>,
    "OBV":<OBVpeers tickerList={tickerList}/>,
   
  }
  return (
    <>
    {indicatorList[indicator]}
    </>
  )
}

import { useEffect, useRef } from "react";
import { assetCode, dfin } from "content/constants";
import { useAppInfo } from "AppState";

export default function usePeerFilter() {
  const {
    stateTicker,
    dispatchTicker,
    stateWatchlist,
    statePortfolio,
  } = useAppInfo()
  const { peerSelected,peerSelectedDisplay, tickerInfo } = stateTicker;
  const { tickerSelected } = stateTicker;
  const {watchlistData,watchlistSelected} = stateWatchlist
  const watchlistTickers = watchlistData[watchlistSelected]
  const { portfolioSel,tickerSelected:tickerSelectedPortfolio} = statePortfolio;

  const peersIn = stateTicker.peersIn;
  const prevPeersIn = useRef(null);

  useEffect(() => {
    if (!peersIn) return; // Skip if peersIn is null or empty

    if (stateTicker.tickerType === assetCode?.Stocks) {
      let tickerRow = tickerInfo.find(
        (row) => row[dfin.ticker] === tickerSelected
      );
      let sector = tickerRow?.[dfin.sector];
      let industry = tickerRow?.[dfin.industry];
      let mScale = tickerRow?.[dfin.mScale];
      let country = tickerRow?.[dfin.country];
      

      if (!["CUSTOM", "WATCHLIST", "PORTFOLIO"].includes(peersIn)) {
        let tickersInCategory;

        if (peersIn === "INDUSTRY" || peersIn === "SECTOR") {
          const filterCondition = peersIn === "INDUSTRY" ? industry : sector;
          const column = peersIn === "INDUSTRY" ? dfin.industry : dfin.sector;

          tickersInCategory = tickerInfo
            .filter(
              (row) =>
                row[column] === filterCondition && row[dfin.country] === country
            )
            .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
            .map((row) => row[dfin.ticker]);
        } else {
          tickersInCategory = tickerInfo
            .filter((row) => row[dfin.country] === country)
            .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
            .map((row) => row[dfin.ticker]);
          // && row[dfin.mScale] === mScale)
        }

        if (
          prevPeersIn.current !== peersIn ||
          !peerSelected.includes(tickerSelected)
        ) {
          const selectedIndex = tickersInCategory.indexOf(tickerSelected);

          const startIndex = Math.max(0, selectedIndex - 6);
          const endIndex = Math.min(
            tickersInCategory.length,
            selectedIndex + 5
          );
          const newPeerSel = tickersInCategory.slice(startIndex, endIndex);

          dispatchTicker({"peerSelected":newPeerSel,"peerSelectedDisplay":newPeerSel});
        }
      }
      else if (peersIn === "CUSTOM") {
        const newPeerSel = peerSelected.includes(tickerSelected)
          ? peerSelected
          : [tickerSelected, ...peerSelected];
        dispatchTicker({"peerSelected":newPeerSel,"peerSelectedDisplay":newPeerSel});
      }
      else if (peersIn === "WATCHLIST") {
        const peers = watchlistTickers;
        const newPeerSel = peers.includes(tickerSelected)
          ? peers
          : [tickerSelected, ...peers];
  
          dispatchTicker({"peerSelected":newPeerSel,"peerSelectedDisplay":newPeerSel});
      }
      else if (peersIn === "PORTFOLIO") {
        const peers = tickerSelectedPortfolio
  
        const newPeerSel = peers.includes(tickerSelected)
          ? peers
          : [tickerSelected, ...peers];
  
          dispatchTicker({"peerSelected":newPeerSel,"peerSelectedDisplay":newPeerSel});
      }
    } 
    else{
    if (peersIn === "CUSTOM") {
      const newPeerSel = peerSelectedDisplay.includes(tickerSelected)
        ?  peerSelectedDisplay
        : [tickerSelected, ...peerSelectedDisplay];
        dispatchTicker({"peerSelectedDisplay":newPeerSel});
    }
    else if (peersIn === "WATCHLIST") {
      const peers = watchlistTickers;
      const newPeerSel = peers.includes(tickerSelected)
        ? peers
        : [tickerSelected, ...peers];
        dispatchTicker({"peerSelectedDisplay":newPeerSel});
    }
    else if (peersIn === "PORTFOLIO") {
      const peers = tickerSelectedPortfolio

      const newPeerSel = peers.includes(tickerSelected)
        ? peers
        : [tickerSelected, ...peers];
        dispatchTicker({"peerSelectedDisplay":newPeerSel});
    }
    else if (peersIn ==="ASSET CLASS") {
      const tickerType = tickerInfo
        .filter((row) => (row["TYPE"] === stateTicker.tickerType))
        .map((obj) => obj[dfin.ticker])
        .splice(0, 10);
      const newPeerSel = Array.from(new Set([tickerSelected, ...tickerType]));
      if (newPeerSel.length>0){
        dispatchTicker({"peerSelectedDisplay":newPeerSel});
    }
    }}
    prevPeersIn.current = peersIn;
  }, [peersIn, tickerSelected, tickerInfo, watchlistTickers,tickerSelectedPortfolio]);
}

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useEconSeries(seriesName) {
  
  const [econSeries, setEconSeries] = useState([]);
  const [loadingEconSeries, setLoadingEconSeries] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    if (seriesName !== undefined){
    getEconInfo();}
  },[seriesName]);

  async function getEconInfo() {
    setLoadingEconSeries(true);
    try {
      const response = await axios.get(`${BASE_URL}/econSeries/`, {
        params: {
          series: seriesName,
        },
      });
      
      setEconSeries(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEconSeries(false);
    }
  }
  

  return { econSeries, loadingEconSeries };
}

import React from 'react'
import Modal from './Modal/Modal'
import { useAppInfo } from 'AppState'

export default function NoTickerInfoModal() {
  const {dispatchTicker,stateTicker} = useAppInfo()
  return (
    <>
    {stateTicker.noInfo && 
    <Modal width="70%" height="50%" 
        onClose={()=>{dispatchTicker({"noInfo":false})}}>
    <div style={{display:"flex",flexDirection:"column",width:"100%",alignItems:"center",height:"100%"}}>
    <h1 style={{fontWeight:"bold"}}>Sorry, Currently No Info Available for this Ticker.</h1>
    <img 
    height="80%"
    width = "100%"
    style={{objectFit:"cover"}}
    src="https://images.unsplash.com/photo-1526374965328-7f61d4dc18c5?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D">

    </img>
    </div>
    </Modal>}
    </>

  )
}

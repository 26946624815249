import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TextInput from 'components/Inputs/TextInput';
import SelectBox from 'components/Inputs/SelectBox';
import { Alert, Button } from '@mui/material';
import BorderBox from 'components/Layout/BorderBox';
import SelectCurrency from 'components/Inputs/SelectCurrency';
import { formatNumber } from 'utils/utilGeneral';

const InvestmentProjection = () => {
  const [currency, setCurrency] = useState("USD");
  const [salary, setSalary] = useState(10000);
  const [salaryType, setSalaryType] = useState('monthly');
  const [growthRate, setGrowthRate] = useState(2);
  const [investmentType, setInvestmentType] = useState('percent');
  const [investmentPercent, setInvestmentPercent] = useState(5);
  const [investmentValue, setInvestmentValue] = useState(0);
  const [investmentReturn, setInvestmentReturn] = useState(10);
  const [volatility, setVolatility] = useState(2);
  const [years, setYears] = useState(20);
  const [chartOptions, setChartOptions] = useState({});
  const [finalValue,setFinalValues] = useState({income:0,investment:0})
  useEffect(() => {
    calculateProjections();
  }, []);

  const handleInputChange = (name, value) => {
    switch (name) {
      case 'currency':
        setCurrency(value);
        break;
      case 'salary':
        setSalary(parseFloat(value));
        break;
      case 'salaryType':
        setSalaryType(value);
        break;
      case 'growthRate':
        setGrowthRate(parseFloat(value));
        break;
      case 'investmentType':
        const investmentValueUpdated = investmentType === "percent" ? salary * investmentPercent / 100 : investmentValue;
        const investmentPercentUpdated = investmentType !== "percent" ? (investmentValue / salary) * 100 : investmentPercent;
        setInvestmentType(value);
        setInvestmentValue(parseFloat(investmentValueUpdated));
        setInvestmentPercent(parseFloat(investmentPercentUpdated));
        break;
      case 'investmentPercent':
        setInvestmentPercent(parseFloat(value));
        break;
      case 'investmentValue':
        setInvestmentValue(parseFloat(value));
        break;
      case 'investmentReturn':
        setInvestmentReturn(parseFloat(value));
        break;
      case 'volatility':
        setVolatility(parseFloat(value));
        break;
      case 'years':
        setYears(parseInt(value, 10));
        break;
      default:
        break;
    }
  };

  const calculateProjections = () => {
    let projections = [];
    let incomeProjections = [];
    let currentInvestment = 0;
    const annualGrowthRate = Math.pow((1 + growthRate / 100), 1);
    const annualReturnRate = Math.pow((1 + investmentReturn / 100), 1);
    const monthlyVolatility = Math.pow((1 + volatility / 100), 1 / 12) - 1;

    for (let i = 1; i <= years; i++) {
      const annualIncome = salaryType === 'monthly' ? salary * 12 : salary;
      const income = annualIncome * Math.pow(annualGrowthRate, i - 1);
      incomeProjections.push(income);

      const investment = investmentType === 'percent'
        ? income * (investmentPercent / 100)
        : investmentValue;

      currentInvestment = (currentInvestment + investment) * annualReturnRate;
      currentInvestment *= 1 + monthlyVolatility * 12;
      projections.push(currentInvestment);
    }

    const finalIncome = incomeProjections[incomeProjections.length - 1];
    const finalInvestment = projections[projections.length - 1];
    setFinalValues({income:finalIncome,investment:finalInvestment})

 
    setChartOptions({
      chart:{
        type:"area",
      },
      title: {
        text: 'Income and Investment Projection',
      },
      xAxis: {
        categories: Array.from({ length: years }, (_, i) => i + 1),
        title: {
          text: 'Years',
        },
      },
      yAxis: {
        title: {
          text: 'Value',
        },
      },
      series: [
        {
          name: 'Income',
          data: incomeProjections,
        },
        {
          name: 'Investment Value',
          data: projections,
          color:"green"
        },
      ],
      credits: {
        enabled: false
      }
    });

  };

  return (
    <div style={{ display: "flex", width: "100%"
    , gap: "1rem", flexWrap: "wrap", padding: "0.25rem", boxSizing: "border-box"}}>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", 
        width: "15%", minWidth: "300px",height:"auto"}}>
        
        <InputBoxWrapper>
        <h4 style={{fontWeight:"bold"}}>Projection</h4>
        <SelectCurrency label={"Currency"} variant='standard' value={currency} onChange={(v)=>handleInputChange("currency",v?.code)}/>
        <SelectBox 
          label={"Period"} 
          options={["annually","monthly"]}
          value={salaryType} 
          onChange={(e, v) => handleInputChange("salaryType", v)}
        />
  
        <TextInput 
          type='number' 
          label='Years' 
          value={years} 
          onChange={(v) => handleInputChange("years", v)}
        /></InputBoxWrapper>

        <InputBoxWrapper>
        <h4 style={{fontWeight:"bold"}}>Salary</h4>

        <TextInput 
          type='number' 
          label='Amount' 
          value={salary} 
          onChange={(v) => handleInputChange("salary", v)}
        />
       
        <TextInput 
          type='number' 
          label='Growth (% annual)' 
          value={growthRate} 
          onChange={(v) => handleInputChange("growthRate", v)}
        />
        </InputBoxWrapper>

        <InputBoxWrapper>
        <h4 style={{fontWeight:"bold"}}>Investment Info</h4>
        <div style={{display:"flex",gap:"1rem"}}>
        <SelectBox 
          label={"Investment Type"} 
          options={["percent", "absolute"]}
          value={investmentType} 
          onChange={(e, v) => handleInputChange("investmentType", v)}
        />
  
        <TextInput 
          type='number' 
          label={investmentType==="percent"?"Investment Percent of Income":'Investment Value'} 
          value={investmentType==="percent"?investmentPercent:investmentValue} 
          onChange={(v) => handleInputChange(investmentType==="percent"?"investmentPercent":"investmentValue", v)}
        />
        </div>
        <TextInput 
          type='number' 
          label='Investment Return (% annual)' 
          value={investmentReturn} 
          onChange={(v) => handleInputChange("investmentReturn", v)}
        />
        
        <TextInput 
          type='number' 
          label='Volatility (% annual)' 
          value={volatility} 
          onChange={(v) => handleInputChange("volatility", v)}
        />
        </InputBoxWrapper>

        <Button variant='contained' onClick={calculateProjections}>Calculate</Button>
      </div>
      <div style={{ flex: 1, minWidth: "500px",display:"flex",flexDirection:"column",gap:"1rem"}}>
        <BorderBox sx={{flex:1,minHeight:"400px"}}>
        <HighchartsReact 
        highcharts={Highcharts} 
        options={chartOptions} 
        containerProps={{ style: { height: '100%' , width:"100%"} }}
        />
        </BorderBox>
        <div style={{padding: '1rem', background:"var(--colorSuccess)", borderRadius: '4px' }}>
        <h4 style={{fontWeight:"bold"}}>Power of Compounding</h4>
        <Alert severity='success'>
        {`With a ${salaryType} income of ${currency} ${formatNumber(salary)} at an annual growth rate of ${growthRate}%, your income will be ${currency} ${formatNumber(finalValue?.income)} in ${years} years. `}
            </Alert>
            <Alert severity='success'>
        {`Whereas your investment will be ${currency} ${formatNumber(finalValue?.investment)} in ${years} years with a compounding return of ${investmentReturn}% and considering a volatility of ${volatility}%. `}
            </Alert>
       <Alert severity='success'> 
        {finalValue?.investment> finalValue?.income && `Your investment exceeds your income by ${currency} ${formatNumber(finalValue?.investment - finalValue?.income)}.`}
        The impact of compounding investment returns has significantly increased your investment value over time.
       </Alert>
       <Alert severity='success'> 
        {finalValue?.investment> finalValue?.income && `Your investment exceeds your income by ${currency} ${formatNumber(finalValue?.investment - finalValue?.income)}.`}
        The impact of compounding investment returns has significantly increased your investment value over time.
       </Alert>
       <Alert severity='success'> 
       {`${investmentType === 'percent' ? "Allocating a percentage of your income to investments is a good strategy": "Consider Allocating a percentage of your income instead of investing a fixed value"} as it scales with your income and ensures consistent investment growth. `}
        </Alert>

        </div>
      </div>
    </div>
  );
};

export default InvestmentProjection;


function InputBoxWrapper({children}){
    return (
        <div style={{height:"auto",display:"flex"
        ,flexDirection:"column",gap:"0.5rem",border:"1px solid #ddd",borderRadius:"4px",padding:"0.25rem"}}>
            {children}
        </div>
    )
}
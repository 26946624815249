import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';

export default function useCheckAlertsMyFinancials() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const [warnings,setWarnings] = useState([]);
    
    const {summaryStats, assets, incomeMonthly, liabilities, cashflowDistribution} = stateMyFinancials;
    const {totalAssets, totalLiabilities, totalNetCashflow, totalExpense, totalIncome} = summaryStats;

    // Calculate totals based on types
    const totalCashSavings = assets.reduce((total, asset) => {
        if (["Cash", "Saving", "Investment - Liquid"].includes(asset.type)) {
            return total + asset.amount;
        }
        return total;
    }, 0);

    const totalCreditCard = liabilities.reduce((total, liab) => {
        if (["Credit Card"].includes(liab.type)) {
            return total + liab.amount;
        }
        return total;
    }, 0);
    
    // Define warning scenarios
    function checkEmergencyFund() {
        const emergencyFundRatio = totalCashSavings / totalExpense?.y;  // Monthly expense
        const emergencyFundThreshold = emergencyFundRatio < 0.75; // Less than 3 months is a common warning threshold
        return emergencyFundThreshold ? {
            type: "Crisis Management",
            issue: "Emergency Fund",
            warning: ["Your cash & cash equivalents is less than nine months of expenses."],
            action: ["Consider saving more to cover at least six months of living expenses."],
            priority: "Medium"
        } : null;
    }

    function checkCreditCardDebt() {
        const highDebtThreshold = totalCreditCard > 0; // 30% threshold
        return highDebtThreshold ? {
            type: "Debt Management",
            issue: "Credit Card Debt",
            warning: ["Credit card debt has very high interest rate!","Interest Rates are compounded frequently."],
            action: ["Consider paying off the credit card debt as soon as possible.","Check other debt options that may be more suitable for your needs."],
            priority: "High"
        } : null;
    }

    function checkHighCreditCardDebt() {
        const debtIncomeRatio = totalCreditCard / totalIncome?.m;
        const highDebtThreshold = debtIncomeRatio > 0.3; // 30% threshold
        return highDebtThreshold ? {
            type: "Debt Management",
            issue: "High Credit Card Debt",
            warning: ["Your credit card debt is more than 30% of your income.",`Total Credit Card Debt: ${totalCreditCard}`],
            action: ["Consider paying off the credit card debt as soon as possible.","Check other debt options that may be more suitable for your needs."],
            priority: "High"
        } : null;
    }

    function checkNegativeCashflow() {
        const negativeCashFlow = totalNetCashflow?.m < 0
        return negativeCashFlow ? {
            type: "Cashflow Management",
            issue: "Negative Cashflow",
            warning: ["You have negative cashflow."],
            action: ["Consider budgeting expense.","It is a good idea to have periodic savings."],
            priority: "High"
        } : null;
    }
    // Accumulate warnings
  
    useEffect(()=>{
        let warnings = []
        const emergencyFundWarning = checkEmergencyFund();
        const creditCardDebtWarning = checkHighCreditCardDebt();
        const creditCard = checkCreditCardDebt()
        const negativeCashflow = checkNegativeCashflow()

        if (emergencyFundWarning) {
            warnings.push(emergencyFundWarning);
        } 
        if (creditCardDebtWarning){
            warnings.push(creditCardDebtWarning);
        }
        if (creditCard){
            warnings.push(creditCard)
        }
        if (negativeCashflow) {
            warnings.push(negativeCashflow);
        } 
        dispatchMyFinancials({"warnings":warnings})
        setWarnings(warnings)
    
    },[cashflowDistribution,liabilities,assets,totalCashSavings])


}

import React from 'react'

export default function InfoBox({children,sx={}}) {
  return (
    <div style={{ padding: "1rem", backgroundColor: "#f8f9fa", 
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",...sx}}>

            {children}
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GetStartedButton from './product/GetStartedButton';

const useCases = [
  {
    personality: 'Student',
    image: 'https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2380&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Track your spending habits',
      'Budget for student loans',
      'Save for short-term goals',
      'Learn about Investing',
      'Recieve guidance',      
    ],
  },
  {
    personality: 'Young Professional',
    image: 'https://plus.unsplash.com/premium_photo-1661774433750-b5275770d4ba?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Get a financial health check',
      'Build a Investment Portfolio',
      'Budget Expenses',
      'Create a plan for house purchase',
      'Connect with a professional',
      'Recieve guidance',      
    ],
  },
  {
    personality: 'Retirement Planning',
    image: 'https://images.unsplash.com/photo-1464998857633-50e59fbf2fe6?q=80&w=2369&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Retirement planning tools',
      'Optimize tax-saving investments',
      'Investment Opportunities aligned with Portfolio Strategy',
      'Connect with a professional',
    ],
  },
  {
    personality: 'Professional Investor',
    image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Portfolio Management',
      'Investment opportunities',
      'Analyze Markets & Economy',
      'Fundamental & Quantitative Analysis',
      'Valuation models'
    ],
  },
  {
    personality: 'Professional Trader',
    image: 'https://plus.unsplash.com/premium_photo-1661371241897-3202947ace30?q=80&w=2532&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    features: [
      'Real-time market data',
      'Screeners',
      'Trade Opportunities',
      'Technical Analysis & Backtesting',
      'Trade execution',
      'Risk management tools',
    ],
  },
];

const UseCases = () => {
  const [visibleSection, setVisibleSection] = useState(0);
  const sectionsRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionsRef.current.indexOf(entry.target);
          setVisibleSection(index);
        }
      });
    }, options);

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <Box sx={{ padding: '4rem 8rem', display: 'flex', width: "100%", boxSizing: "border-box", 
    gap: '2rem', justifyContent: "center", alignItems: "flex-start",position:"relative"}}>
      {/* User's Box */}
     
      <Box
  sx={{
    backgroundColor: '#fafafa',
    borderRadius: '10px',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'sticky',
    top: '100px',
    flex: "0 0 400px",
    height: '500px',
  }}
> 
<img
    src={useCases[visibleSection].image}
    alt={useCases[visibleSection].personality}
    style={{
      flex: 1,        
      height:"80%",  // Allows image to take the available space dynamically
      width: "100%",     // Make sure it fills the width of the container
      objectFit: "cover",// Ensure the image covers the box without distortion
      borderRadius: '10px',
    }}
  />
  <div style={{position:"absolute",bottom:"0",zIndex:3,backdropFilter:"blur(20px)"
    ,padding:"1rem",display:"flex",flexDirection:"column"
    ,alignItems:"center",justifyContent:"center",width:"100%",background:"#fafafa",boxSizing:"border-box"}}>
  <Typography 
    variant="h3" 

    sx={{ 
      textAlign: 'center', 
      opacity:0.5,
      marginTop: '1rem',  // Adds space between the image and the text
    }}
  >
    {useCases[visibleSection].personality}
  </Typography>

  <GetStartedButton sx={{borderRadius:"25px",marginTop:"1rem",padding:"0.5rem 1rem"}} icon={false}/>
  </div>

</Box>

      {/* Features */}
      <Box sx={{ flex: 1,padding:"2rem",position:"relative"}}>

        {useCases.map((useCase, index) => (
          <Box
            key={index}
            ref={(el) => (sectionsRef.current[index] = el)}
            sx={{
              opacity: visibleSection === index ? 1 : 0.5,
              transform: visibleSection === index ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
              minHeight: '80vh',
              padding: '2rem',
              display: 'flex',
              flexDirection: 'column',
              borderRadius:"4px",
              justifyContent: 'flex-start', // Ensures that the text is vertically centered
            }}
          >
            <Box sx={{ textAlign: 'left',display:"flex",flexDirection:"column",gap:"1.5rem"}}>
              {useCase.features.map((feature, featureIndex) => (
                <div key={featureIndex} style={{display:"flex",gap:"2rem",alignItems:"center"}}>
                  <CheckCircleIcon/>
                  <Typography variant="h4">
                  {feature}
                </Typography>
                </div>
               
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UseCases;

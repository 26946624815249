import React, { useState,useRef,useEffect, useMemo } from 'react';
import SearchBar from 'components/Inputs/SearchBar';
import TabGeneral from 'components/Tabs/TabGeneral';
import { assetCode, dfin } from 'content/constants';
import styles from "./SearchMain.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { useAppInfo } from 'AppState';
import useSearchTicker from 'data/ticker/useSearchTicker';


export default function SearchTickerAddNewWatchlist({handleAddToWatchlist,selected}) {
  const {stateTicker} = useAppInfo();
  const tickerInfo = stateTicker.tickerInfo
  const [selectedItems,setSelectedItems] = useState([])
  const tickerType = Object.values(assetCode)
  const tabList = ["All", ...tickerType];
  const [tabIndex, setTabIndex] = useState(0);
  const tableColumns = ["Ticker", "Name", "Exchange","Add to Watchlist"];
  const tableRows = ["TICKER", "NAME", "EXCHANGE"];
  const [searchTerm,setSearchTerm] = useState()
  
  
  useEffect(()=>{
    setSelectedItems(selected)
  },[selected])


  function handleTabChange(index) {
    setTabIndex(index);
  }


  const searchInfo = useSearchTicker(searchTerm);

  const filteredList = useMemo(() => {
    const tickerT = tabList[tabIndex];
    
    // Filter and sort the full list
    let filteredResults = ["All", assetCode?.Stocks].includes(tickerT) ? tickerInfo.filter((obj) => obj["COUNTRY"] === "USA"): tickerInfo;  
    let topResults;

    if (searchTerm !== "" && searchTerm && searchInfo.searchedTicker) {
      filteredResults = [...searchInfo.searchedTicker]
      topResults = [...filteredResults].sort(
        (a, b) => b[dfin.marketCap] - a[dfin.marketCap])
    }
    else {
      topResults = [...filteredResults].sort(
        (a, b) => b[dfin.marketCap] - a[dfin.marketCap])
    }
  
    // Apply tab filtering

    if (tickerT === "All") {
      return topResults.slice(0, 200);
    } else {
      const filteredByTab = topResults.filter(
        (item) => item["TYPE"] === tickerT
      );
      return filteredByTab.slice(0, 200);
    }
  }, [searchTerm,tabIndex, tickerInfo]);

  function handleSearchChange(event) {
    const searchTerm = event.target.value.toLowerCase();    
    setSearchTerm(searchTerm)
    
  }


  return (
      <div style={{ display: "flex", flexDirection: "column", maxWidth: "100%",width:"100%",flex:1,overflow:"auto"}}>
        <SearchBar onInput={handleSearchChange} />
        <div style={{marginTop:"1rem",marginBottom:"1rem"}}>
        <TabGeneral tabList={tabList} tabContent={[]} tabIndex={tabIndex} handleTabChange={handleTabChange} />
        </div>
        
        <div style={{maxHeight:"100%",overflow:"auto",marginTop:"1rem",width:"100%"}}>
        <table className={styles.table}>
          <thead>
            <tr>
              {tableColumns.map((colHeader) => (
                <th key={colHeader}>{colHeader}</th>
              ))}
            </tr>
          </thead>
            <tbody>
              {filteredList.map((obj, index) => (
                  <tr key={index} >
                   
                    {tableRows.map((row, rowIndex) => (
                      <td key={rowIndex}>
                        {row !== dfin.ticker ? (
                          obj[row]
                        ) : (
                         
                          <div className={styles.logoTick}>
                            <img src={obj[dfin.coLogo]} alt="Logo" height={20} width={20} />
                            {obj[row]}
                          </div>
                        )}
                      </td>
                    ))}
                     <td>  
                      
                     {selectedItems.includes(obj[dfin.ticker]) ? (
                              <CheckCircleOutlineIcon
                                style={{ color: "green" }}
                                onClick={() =>handleAddToWatchlist(obj[dfin.ticker])}
                              />
                              ) : (
                              <AddIcon onClick={() =>handleAddToWatchlist(obj[dfin.ticker])} />
                              )}
                    </td>
                  </tr>
                ))
              }
            </tbody>
        </table>
        </div>
      </div>
  );
}
import { doc, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebaseConfig/firebaseConfig";
import Message from "./Message";
import { useAppInfo } from "AppState";

const Messages = () => {
  const {stateChats} = useAppInfo()
  const [messages, setMessages] = useState([]);
  const { chatId } = stateChats

  useEffect(() => {
    if (chatId){
    const unSub = onSnapshot(doc(db, "chats", chatId), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });

    return () => {
      unSub();
    };}
  }, [chatId]);


  return (
    <div style={{flex:1}}>
     
      {messages.map((m) => (
        <Message message={m} key={m.id} />
      ))}
    </div>
  );
};

export default Messages;
import React, { useEffect, useState } from 'react';
import SearchNav from './SearchNav';
import Logo from 'logo/Logo';
import { Button, IconButton, MenuItem, Paper, Select, Typography } from '@mui/material';
import ProfileSettings from './ProfileSettings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { menuIcons } from './menuIcons';
import NavPage from './NavPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { navLinks } from './menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabLine from 'components/Tabs/TabLine';
import SelectMarket from 'components/Inputs/SelectMarket';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useAppInfo } from 'AppState';
import MenuIcon from '@mui/icons-material/Menu';

export default function NavNew() {
  const { dispatchSidebar, stateSidebar } = useAppInfo();
  const {sidebarLeftExpanded} = stateSidebar
  const navigate = useNavigate();
    const location = useLocation();
    const pageName = location.pathname.split('/')[1];
    const subPage = location.pathname.split('/')[2];
    const currentPage = navLinks.find((page) => page.link === pageName);
    const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
    const menu = currentPage?.menu ? currentPage?.menu.map(obj=>obj.name) : []
    const pageAbout = /(\/|^)(About|Pricing|Features|Contact|Product|Terms|Disclaimer|Support|Profile)(\/|$)/i.test(pageName);
   
    const [showMenu,setShowMenu] = useState(false)

    const handleSubPageChange = (v) => {
      const selectedSubPage = currentPage?.menu?.find((sub) => sub.name === v)?.link;
      navigate(`${pageName}/${selectedSubPage}`);
    };

    useEffect(()=>{
      setShowMenu(false)
    },[sidebarLeftExpanded])

  return (
    <div className="nav" style={{background:"white"}}>
    <div className="nav_wrapper">

    <>
    {/* <div style={{padding:"0 1rem"}}>
    <Button
                sx={{
                  marginLeft: 'auto',
                  minWidth: '0',
                  padding: 0,
                  flexDirection: 'column',
                  textTransform: 'none',
                }}
                // onMouseEnter={()=>dispatchSidebar({"sidebarLeftExpanded":true})}
                onClick={() => dispatchSidebar({"sidebarLeftExpanded":!sidebarLeftExpanded})}
              >
                <MenuIcon fontSize="medium"/>
              </Button>
    </div> */}
   {(["Home",""].includes(pageName) || pageAbout) && !sidebarLeftExpanded &&
   <div className='gradient_text' style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
            <Logo height='25px' width="150px" type='long'/>
            <Typography fontSize={"0.8rem"}>Beta</Typography>
        </div>}
        {(!["Home",""].includes(pageName) && !pageAbout) && !sidebarLeftExpanded &&
   <div className='gradient_text' style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
            <Logo/>
            {/* <Typography fontSize={"0.8rem"}>Beta</Typography> */}
        </div>}
      <div style={{flex:1,overflow:"hidden"}}>
      {(!["Home",""].includes(pageName) && currentPage?.name) &&
      <div>
      <Button onMouseEnter={()=>setShowMenu(true)} 
      endIcon={<ExpandMoreIcon/>}
      sx={{fontSize:"1.2rem",textTransform:"capitalize"}}>
        {currentPage?.name}: {currentSubPage?.name}

      </Button>
      </div>}
      </div>
      <div style={{marginLeft:"auto",display: 'flex', alignItems: 'center',gap:"0.75rem",padding:"0 0.5rem"}}>
      <SearchNav />
        
      {/* <SelectMarket label={false}/> */}
      
        {/* <IconButton>
        <NotificationsNoneIcon sx={{color:"gray"}}/>
        </IconButton> */}

      </div>
      {/* <div
          id="Profile"
          className="nav_profile"
          style={{padding:"0 0.5rem"}}
        >
          <ProfileSettings />
        </div> */}

      
    </>

     
   </div>
   {(showMenu && pageName !=="Home" && !sidebarLeftExpanded) && <Paper 
      onMouseLeave={()=>setShowMenu(false)}
      style={{top:"100%",width:"100%",left:"0px",position:"absolute"
      ,padding:"0.5rem",zIndex:1000,background:"#fafafa"
      ,display:"flex",flexDirection:"column",boxSizing:"border-box",gap:"1rem"
      ,flexWrap:"wrap"}}>
        {/* <h3>{currentPage?.name}</h3>
         */}
        <TabLine 
        variant='highlight'
        tabList={menu} 
        tabIndex={menu?.indexOf(currentSubPage?.name)}
        handleTabChange={(v)=>handleSubPageChange(menu[v])}/>
       
        </Paper>}
   
    </div>
  );
}

import React from 'react'
import MyFinancialsHeaderTemplate from '../Template/MyFinancialsHeaderTemplate'
import { menuIcons } from 'nav/menuIcons'

export default function RiskManagementHeader() {
  return (
    <MyFinancialsHeaderTemplate icon={menuIcons?.RiskManagement} name={"Risk Management"}>

    </MyFinancialsHeaderTemplate>
  )
}

import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";

export default function useRSI(tickerList,window,overBought,overSold,dateFrequency,) {

const [rsiOb, setRSIob] = useState([]);
const [rsiOs, setRSIos] = useState([]);
const [rsiN, setRSIn] = useState([]);
const [rsiStats, setRSIstat] = useState([]);
const [rsiLoading, setMarketInfoLoading] = useState(false);

useEffect(() => {
    getPriceInfo();
}, [tickerList,window,overBought,overSold,dateFrequency]);

async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/rsi/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        window:window,
        overBought:overBought,
        overSold:overSold,
        dateFrequency:dateFrequency,
        },
    });
   
    const marketInfo = response.data
    
    setRSIob(JSON.parse(marketInfo.overBought))
    setRSIos(JSON.parse(marketInfo.overSold))
    setRSIn(JSON.parse(marketInfo.neutral))
    setRSIstat(JSON.parse(marketInfo.stats))
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {rsiOb,rsiOs,rsiN,rsiStats, rsiLoading };
}
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import AssetProjections from './AssetProjections'
import AssetAllocation from './AssetAllocation'
import AssetStepsToGrow from './AssetStepsToGrow'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import DnsIcon from '@mui/icons-material/Dns';
import AssetOverview from './AssetOverview'
import ChecklistIcon from '@mui/icons-material/Checklist';

export default function AssetInfo({info,displayType="tabs"}) {
  const {stateMyFinancials} = useAppInfo()
  const [tabIndex,setTabIndex]  = useState(0)
  // const tabList = ["Details","Allocation","Projections","Simulated Projections"]

  const {assets,summaryStats,currency} = stateMyFinancials
  const {totalAssets} = summaryStats


  const tabContent = {
    "Overview":<AssetOverview/>,
    "Steps to grow Assets":<AssetStepsToGrow/>,
    "Allocation": <AssetAllocation/>,
    "Projections": <AssetProjections/>,
    "Simulated Projections":<AssetProjections projectionType='Simulated'/>
}

const tabList = [
  { label: "Overview", icon: <DnsIcon /> },
  { label: "Steps To Grow Assets", icon: <ChecklistIcon /> },
  { label: "Allocation", icon: <DonutSmallIcon /> },
  { label: "Projections", icon: <StackedLineChartIcon /> },
  { label: "Simulate Projections", icon: <SsidChartIcon /> }
];


  const [display,setDisplay] = useState("Overview")

  return (
    <>
       {tabContent[info]}

    </>
  )
}





import React, {
  memo,
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import PriceChartMulti from 'components/Charts/PriceChartMulti';
import ScatterUI from 'components/Charts/ScatterUI';
import TreeMap from 'components/Charts/TreeMap';
import { dfin } from 'content/constants';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import Beta from 'analytics/technicals/general/Beta';
import PerformanceClose from 'analytics/technicals/general/PerformanceClose';
import Volatility from 'analytics/technicals/general/Volatility';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';

import BarChartPeersUI from './BarChartPeersUI';
import LineChartReport from './LineChartReport';
import PieChartPeersUI from './PieChartPeersUI';
import Shape from './Shapes';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import Correlation from 'analytics/technicals/general/Correlation';
import { handleObjectDetails } from 'report/utilsReport';
import TextCompany from './TextCompany';

function DragObjects({ item, pageIndex }) {
  const { stateTicker, stateStock,stateReportBuilder, dispatchReportBuilder } = useAppInfo();
  const { pages } = stateReportBuilder;
  const {coInfo} = stateStock
  const [textCoValue, setTextCoValue] = useState();
  const peerSelected = stateTicker.peerSelectedDisplay;
  const { close, day, marketInfoLoading } = useMultiTickerInfo(peerSelected);
  const { priceInfo, loadingPriceInfo } = usePriceChartEod({
    tickerSel: stateTicker.tickerStockCode,
  });


  function updateObjectDetails(v){
    handleObjectDetails({contentUpdate:v, item, pageIndex, pages, dispatchReportBuilder});
  }


  return (
    <>
     {item.Type === "Shape" && (
        <Shape id={`${item.id}`} 
        item={item}
        color={
          item.ShapeColor ? item.ShapeColor : "black"
        }
        shapeType={item?.ShapeType ?item?.ShapeType:"rectangle"}
        />
      )}
      {item.Type === "Images" && item.Category !== "Company Logo" && (
        <img
          id={`${item.id}`}
          height={"100%"}
          width={"100%"}
          style={{ objectFit: "contain" }}
          src={item.ImgUrl}
          alt="icon"
        />
      )}
      {item.Type === "Images" && item.Category === "Company Logo" && coInfo && (
        <img
          id={`${item.id}`}
          height={"100%"}
          width={"100%"}
          style={{ objectFit: "contain" }}
          src={coInfo?.[dfin.coLogo]}
          alt="company logo"
        />
      )}

      {item.Type === "Text" && item.Category !== "Company Details" && (
        <textarea
          id="textReport"
          // id={`${item.id}`}
          defaultValue={item.Content}
          // value={item.Content}
          onChange={(e) => updateObjectDetails(e.target.value)}
          onMouseDown={(e) => e.stopPropagation()}
          style={{
            resize: "none",
            boxSizing: "border-box",
            outline: "none",
            border: "none",
            ...item.style,
            width: "100%",
            height: "100%",
          }}
        />
      )}

      {item.Type === "Text" && item.Category === "Company Details" && <TextCompany item={item} onChange={updateObjectDetails}/>}
      {/* {item.Type === "Excel" && (
      <Shape/>
      )} */}

      {/* {item.Type === "Excel" && <SpreadSheet />} */}
      {item.Type === "Scatter" && (
        <ScatterUI
          report
          showSettingsOnHover={true}
          defaultMet={item.MetricSelected.x ? [item.MetricSelected.x,item.MetricSelected.y]:[dfin.marketCap, dfin.pe]}
          data={stateStock.peerInfo}
          handleObjectDetails={updateObjectDetails}
          item={item}
        />
      )}
      {item.Type === "Pie" && (
        <PieChartPeersUI
          dataLabel={true}
          data={stateStock.peerInfo}
          item={item}
          metricSel={
            item.MetricSelected ? item.MetricSelected : [dfin.marketCap]
          }
        />
      )}
      {["Bar", "Column"].includes(item.Type) && (
        <BarChartPeersUI
          barColor={item?.Color}
          data={stateStock.peerInfo}
          type={item.Type === "Bar" ? "bar" : "column"}
          metricSel={
            item.MetricSelected ? item.MetricSelected : [dfin.marketCap]
          }
          item={item}
        />
      )}

      {item.Type === "Price Multi" && (
        <PriceChartMulti
          close={close}
          marketInfoLoading={marketInfoLoading}
        />
      )}

      {item.Type === "Financials" && (
        <CompanyFinancialsUI
          chart={item?.ChartType}
          metricView={item?.MetricView}
          reportType={item?.ReportType}
          periodLength={item?.PeriodLen}
          metricSel={item?.MetricSelected}
          peerModeUser={item?.PeerMode}
          hideOnHover={true}
          handleObjectDetails={updateObjectDetails}
          item={item}
        />
      )}
      {item.Type === "Line" && (
        <>
          {priceInfo.length > 0 && !loadingPriceInfo && (
            <LineChartReport
            item={item}
            chartColor={item.ChartColor?item.ChartColor:"blue"}
            ticker={
              item.TickerSelected ? item.TickerSelected : "AAPL"
            }
            />
          )}
        </>
      )}
      {item.Type === "Tree Map" && <TreeMap showSettings={true} />}
      {item.Type === "Correlation" && (
        <OverflowWrapper>
        <Correlation tickerList={stateTicker.peerSelectedDisplay}/></OverflowWrapper>
      )}
      {item.Type === "Volatility" && (
        <Volatility
          close={close}
          marketInfoLoading={marketInfoLoading}
          tickerList={peerSelected}
        />
      )}
      {item.Type === "Beta" && <Beta tickerList={peerSelected} />}
      {item.Type === "Performance" && (
        <PerformanceClose
          close={close}
          marketInfoLoading={marketInfoLoading}
          tickerList={peerSelected}
        />
      )}
    </>
  );
};


function OverflowWrapper({children}){
  return (
    <div style={{width:"100%",height:"100%",overflow:"auto"}}>
      {children}
    </div>
  )
}


export default DragObjects
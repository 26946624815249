const userTypes = [
    {"User":"New to Investing",
    "Description":"Student or Part time Trader/Investor",
    "Image":"https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1548&q=80",
    "GetStarted":"Get started to Learn & Apply",},
    
    {"User":"Trader",
    "Description":"Day Trader/Positonal Trader or Into Derivatives",
    "Image":"https://images.unsplash.com/photo-1642790261487-5b7e444c0dce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2329&q=80",
    "GetStarted":"Get started to Trade",},
    {"User":"Investor","Description":"Long-term Investor/Researcher","Image":"https://images.unsplash.com/photo-1444653389962-8149286c578a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGZpbmFuY2lhbCUyMGFuYWx5c2lzfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    "GetStarted":"Get started to Invest",},
    {"User":"Professional","Description":"Portfolio Manager/Financial Advisor", "Image":"https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1742&q=80","GetStarted":"Get started to Manage Funds",},
  ];
  

const productFeature1 = {
    "id":"pageScroll1",
    "Header":"Automate your finances and meet your goals.",
  "SubHeader":["Set Goals & Preferences","Project & Allocate"
  ,"Find Opportunities","Assess Risks","Simulate Scenarios","Get Insights"],
  "TagLine":""}

// const productFeature1 = {
//     "id":"pageScroll0",
//     "Header":"We understand you.",
//   "SubHeader":["Learn & Apply","Trade Strategies","Build Portfolios","Find Opportunities","Manage Wealth"],
//   "TagLine":""}


const productFeature2 = {
  "id":"pageScroll2",
  "Header":"Build portfolios that meet your goals & investing style.",
"SubHeader":["Find the Right Portfolio","Simulate Scenarios","Precision Timing & Risk Management Tools"],
"TagLine":""}

  
const productFeature3 = {
  "id":"pageScroll3",
  "Header":"Explore & analyze opportunities that fit your portfolio.",
"SubHeader":["Uncover Every Asset Class","Explore Diverse Market Regions",
"Conduct In-depth Analysis","Build Models"
],
"TagLine":"Timing is everything in Markets! Position yourself in the right markets at the right time with TakeStock's Market Calls, Insights & Framework."}

const productFeature4 = {"Header":"Create screeners, watchlists, reports & more...",
"id":"pageScroll4",
"SubHeader":["Quick Screeners","Extensive List of Filters","Unlimited Watchlists","Build Dynamic Reports"],
"TagLine":"To build a strong portfolio or trade successfully, it is essential you look at peers and other assets. Simply toggle to visualize & analyze the whole landscape with TakeStock's unique system design."}


// const productFeature4 = {
// "id":"pageScroll3",
// "Header":"Get Curated Information & Insights",
// "SubHeader":["Investment & Trade Picks","Get Timely Alerts",
// "Custom Layouts",
// "Visualize Metrics in multiple formats",
// "A.I Insights"],
// "TagLine":"We understand you and equip you with the right tools and framework enabling you to consistently make superior decisions. Get started with TakeStock's user friendly Dashboard."}

const productFeature5 = {"Header":"Start learning & applying",
"id":"pageScroll5",
"SubHeader":["End to end guidance",
"Extensive Resource","Original Content","Ask AI Assistant",],
"TagLine":"Investing can be challenging—both mentally and emotionally, not to mention financially. But it's not something to be feared; it's something to be understood. Get a solid understanding of investments and secure your financial future with TakeStock's End to End guidance."}


const productFeatures = [productFeature1,productFeature2,productFeature3,productFeature4,productFeature5]


export {productFeatures,userTypes}
const questions = [
    {
      type: "Financial Situation",
      question: "How would you describe your current financial situation?",
      options: [
        { label: "Stable with surplus income", points: 3 },
        { label: "Stable but limited disposable income", points: 2 },
        { label: "Unstable or limited income", points: 1 }
      ],
      optionSelected: 0
    },
      { 
        type: "Investment Objective",
        question: "What is your primary investment objective?",
        options: [
          { label: "Passive Income or Capital preservation" , points:1},
          { label: "Income generation (Regular income from investments)" , points:2 },
          { label: "Investing in New Opportunities" , points:3 },
        ],
        optionSelected: 0
      },
  
      {
        type: "Investment Objective",
        question: "What is your investment time horizon?",
        options: [
          { label: "Long-term", points: 3 },
          { label: "Medium-term", points: 2 },
          { label: "Short-term", points: 1 }
        ],
        optionSelected: 0
      },
      {
        type: "Investment Objective",
        question: "What percentage of your total investable assets are you willing to allocate to higher risk investments (e.g., stocks, cryptocurrencies)?",
        options: [
          { label: "Less than 25%", points: 1 },
          { label: "Between 25% and 50%", points: 2 },
          { label: "More than 50%", points: 3 }
        ],
        optionSelected: 0
      },
      {
        type: "Behavior",
        question: "How comfortable are you with the possibility of losing money in your investments?",
        options: [
          { label: "Very uncomfortable", points: 1 },
          { label: "Neutral", points: 2 },
          { label: "Comfortable", points: 3 }
        ],
        optionSelected: 0
      },
      {
        type: "Behavior",
        question: "Have you invested in the stock market or other risky assets before? If so, how did you feel when the value of your investments fluctuated?",
        options: [
          { label: "Felt very uneasy or panicked", points: 1 },
          { label: "Felt somewhat uneasy but stayed invested", points: 2 },
          { label: "Felt comfortable and confident", points: 3 }
        ],
        optionSelected: 0
      },
      {
        type: "Behavior",
        question: "How do you react to market downturns or periods of volatility?",
        options: [
          { label: "Panic and sell investments", points: 1 },
          { label: "Stay invested but feel nervous", points: 2 },
          { label: "See it as an opportunity to buy more or hold steady", points: 3 }
        ],
        optionSelected: 0
      },
  
      {
        type: "Behavior",
        question: "How important is capital preservation (protecting your initial investment) to you compared to the potential for higher returns?",
        options: [
          { label: "Very important", points: 1 },
          { label: "Moderately important", points: 2 },
          { label: "Less important, willing to take higher risks for higher returns", points: 3 }
        ],
        optionSelected: 0
      },
      {
        type: "Behavior",
        question: "Are you more interested in steady, consistent growth or are you comfortable with the potential for larger fluctuations in returns?",
        options: [
          { label: "Prefer steady growth", points: 1 },
          { label: "Comfortable with some fluctuations", points: 2 },
          { label: "Comfortable with larger fluctuations for potentially higher returns", points: 3 }
        ],
        optionSelected: 0
      },
      {
        type: "Behavior",
        question: "How would you feel if your investments lost 10% of their value in a short period of time?",
        options: [
          { label: "Very concerned and may sell", points: 1 },
          { label: "Concerned but would hold onto investments", points: 2 },
          { label: "Not concerned, see it as part of investing", points: 3 }
        ],
        optionSelected: 0
      }
    ];
    
    const intro = `In this assessment, we aim to gauge your comfort level with 
    taking risks in various financial scenarios. Understanding your risk tolerance 
    is crucial in making informed investment decisions that align with your 
    financial goals and personal preferences.`
    
    const disclaimer = `
    Please note that this questionnaire serves as a tool for self-assessment and educational purposes only. Your risk tolerance score is not a guarantee of investment success or failure. It's essential to consult with a qualified financial advisor 
    before making any investment decisions based on your risk tolerance assessment.
    `
    
  const questionaireRiskTolerance = {
    intro:intro,
    disclaimer:disclaimer,
    questionnaire:questions
  }

const calculateRiskCategory = (score) => {
    const scoreWeight = questions.length * 3; // assuming each question has 3 possible answers each weighing 1 point
    const totalScore = score / scoreWeight * 100;
    return riskInfo.find(info => totalScore <= info.maxPercentile);
};



const stockCriterias = {
  Dividend: ['Less Important'],
  Industry: ['All'],
  "Market Cap": ['All'],
  "Market Region": ['All'],
  Sector: ['All'],
  ESG: ['Important']
};

const riskInfo = [
  {
      category: "Extremely Risk Averse",
      toleranceLevel: "Very Low",
      maxPercentile: 10,
      description: "Prefer extremely safe investments with minimal risk and high liquidity. Ideal for those prioritizing capital preservation over returns.",
      assetClasses: [
          { mainType: "Savings", name: "Bank Savings Account", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} },
          { mainType: "Fixed Income", name: "Government bonds", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} },
          { mainType: "Fixed Income", name: "Treasury bills", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} },
          { mainType: "Fixed Income", name: "Money market funds", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} },
          { mainType: "Fixed Income", name: "Certificates of deposit", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} }
      ]
  },
  {
      category: "Very Risk Averse",
      toleranceLevel: "Low",
      maxPercentile: 20,
      description: "Prefer low-risk investments with a focus on capital preservation. Willing to accept minimal risk for stable returns.",
      assetClasses: [
          { mainType: "Savings", name: "Bank Savings Account", assetRisk: "Low", marketRisk: "Minimal", liquidity: "High", targetAllocation: 20, criterias: {} },
          { mainType: "Fixed Income", name: "Corporate bonds (investment grade)", assetRisk: "Low-Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 40, criterias: {} },
          { mainType: "ETF", name: "Fixed income funds", assetRisk: "Low-Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 20, criterias: {} },
          { mainType: "Mutual Fund", name: "Fixed income funds", assetRisk: "Low-Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 20, criterias: {} }
      ]
  },
  {
      category: "Moderately Risk Averse",
      toleranceLevel: "Moderate Low",
      maxPercentile: 30,
      description: "Prefer a balance between risk and return, favoring conservative investments with moderate risk for stable growth.",
      assetClasses: [
          { mainType: "ETF", name: "Balanced Etf", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 30, criterias: {} },
          { mainType: "Mutual Fund", name: "Balanced mutual funds", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 30, criterias: {} },
          { mainType: "Stocks", name: "Mega-Cap Stocks", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "High", targetAllocation: 15, criterias: stockCriterias },
          { mainType: "Stocks", name: "Dividend-paying stocks", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 15, criterias: stockCriterias }
      ]
  },
  {
      category: "Slightly Risk Averse",
      toleranceLevel: "Slightly Low",
      maxPercentile: 40,
      description: "Prefer some risk for higher returns while still favoring safer investments. Willing to take on limited risk.",
      assetClasses: [
          { mainType: "Stocks", name: "Dividend-paying stocks", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 50, criterias: stockCriterias },
          { mainType: "Mutual Fund", name: "Mixed asset funds", assetRisk: "Moderate", marketRisk: "Moderate", liquidity: "Moderate", targetAllocation: 50, criterias: {} }
      ]
  },
  {
      category: "Neutral Risk Tolerance",
      toleranceLevel: "Neutral",
      maxPercentile: 50,
      description: "Prefer a balanced portfolio with a mix of risk and return. Open to taking on moderate risk for potential growth.",
      assetClasses: [
          { mainType: "Stocks", name: "Total stock market index funds", assetRisk: "Moderate-High", marketRisk: "High", liquidity: "High", targetAllocation: 30, criterias: stockCriterias },
          { mainType: "ETF", name: "Mixed asset Etf", assetRisk: "Moderate-High", marketRisk: "High", liquidity: "High", targetAllocation: 35, criterias: {} },
          { mainType: "Mutual Fund", name: "Mixed asset funds", assetRisk: "Moderate-High", marketRisk: "High", liquidity: "High", targetAllocation: 35, criterias: {} }
      ]
  },
  {
      category: "Slightly Risk-Seeking",
      toleranceLevel: "Slightly High",
      maxPercentile: 60,
      description: "Prefer taking on more risk for potentially higher returns. Comfortable with some volatility in investments.",
      assetClasses: [
          { mainType: "Fixed Income", name: "High-yield bonds", assetRisk: "High", marketRisk: "High", liquidity: "Varied", targetAllocation: 50, criterias: {} },
          { mainType: "Stocks", name: "Small to mid-cap stocks", assetRisk: "High", marketRisk: "High", liquidity: "Moderate", targetAllocation: 50, criterias: stockCriterias }
      ]
  },
  {
      category: "Moderately Risk-Seeking",
      toleranceLevel: "Moderate High",
      maxPercentile: 70,
      description: "Prefer higher returns by taking on more risk. Open to significant volatility for growth potential.",
      assetClasses: [
          { mainType: "Stocks", name: "International stocks", assetRisk: "High", marketRisk: "High", liquidity: "Moderate", targetAllocation: 50, criterias: stockCriterias },
          { mainType: "Real Estate", name: "REITs", assetRisk: "High", marketRisk: "High", liquidity: "Moderate", targetAllocation: 50, criterias: {} }
      ]
  },
  {
      category: "Relatively High Risk-Seeking",
      toleranceLevel: "High",
      maxPercentile: 80,
      description: "Prefer high potential returns, accepting high volatility and risk. Comfortable with significant market fluctuations.",
      assetClasses: [
          { mainType: "Stocks", name: "Emerging market stocks", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Low", targetAllocation: 50, criterias: stockCriterias },
          { mainType: "Real Estate", name: "REITs", assetRisk: "High", marketRisk: "High", liquidity: "Moderate", targetAllocation: 10, criterias: {} },
          { mainType: "ETF", name: "Sector-specific funds", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Low", targetAllocation: 20, criterias: {} },
          { mainType: "Mutual Fund", name: "Sector-specific funds", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Low", targetAllocation: 20, criterias: {} }
      ]
  },
  {
      category: "Very High Risk-Seeking",
      toleranceLevel: "Very High",
      maxPercentile: 90,
      description: "Prefer very high potential returns with very high volatility and risk. Open to significant market fluctuations and potential for loss.",
      assetClasses: [
          { mainType: "Stocks", name: "Volatile Stocks", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 25, criterias: stockCriterias },
          { mainType: "Commodities", name: "Commodities", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 25, criterias: {} },
          { mainType: "Derivatives", name: "Derivatives", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 25, criterias: {} },
          { mainType: "Crypto", name: "Cryptocurrencies", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 25, criterias: {} }
      ]
  },
  {
      category: "Extremely Risk-Seeking",
      toleranceLevel: "Extremely High",
      maxPercentile: 100,
      description: "Prefer the highest potential returns with extreme volatility and risk. Accept significant market fluctuations and high risk of loss.",
      assetClasses: [
          { mainType: "Stocks", name: "Volatile Stocks", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 20, criterias: stockCriterias },
          { mainType: "Derivatives", name: "Derivatives", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 20, criterias: {} },
          { mainType: "Hedge Fund", name: "Venture capital", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 20, criterias: {} },
          { mainType: "Private Equity", name: "Venture capital", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 20, criterias: {} },
          { mainType: "Crypto", name: "Cryptocurrencies", assetRisk: "Very High", marketRisk: "Very High", liquidity: "Varied", targetAllocation: 20, criterias: {} }
      ]
  }
];


const riskToleranceInvestmentPreference = ["mainType","assetRisk","liquidity","targetAllocation","criterias"]



  
  export {questionaireRiskTolerance,riskInfo,calculateRiskCategory}
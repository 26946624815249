import React, { useState } from 'react'
import InvestmentPreferences from './InvestmentPreferences'
import Modal from 'components/Modal/Modal'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton'
import BorderBox from 'components/Layout/BorderBox'

export default function InvestmentPreferenceModal({onClose}) {
  const [preferenceSel,setPreferenceSel] = useState("Market Region")
  const preferenceList = ["Market Region","Time Horizon","Asset Allocation"]

  return (
    <Modal width='90%' height='80%' onClose={onClose}>

    <div style={{display:"flex",gap:"0.5rem",padding:"0.5rem 0.25rem",height:"100%",overflow:"auto"}}>
    <BorderBox sx={{width:"15%",minWidth:"200px"
      ,display:"flex",flexDirection:"column",height:"100%",gap:"0.5rem"}}>
        <h2 style={{paddingBottom:"1rem"}}>Investment Preferences</h2>
      {preferenceList.map((obj,index)=>
      <LeftSidebarButton 
      selected={preferenceSel === obj} 
      key={obj} name={obj} onClick={(v)=>setPreferenceSel(v)}/>)}
    </BorderBox>
    <div style={{display:"flex",flex:1,width:"100%",height:"100%"}}>

    <div style={{flex:1,minWidth:"200px"}}>
    <InvestmentPreferences info={preferenceSel}/>
    </div>
    </div>
    </div>
    </Modal>
  )
}

import React, { useEffect, useState } from 'react'
import PriceChartMulti from 'components/Charts/PriceChartMulti'
import { useAppInfo } from 'AppState'
import { sectorDict } from 'content/contentMarket'
import Correlation from 'analytics/technicals/general/Correlation'
import PerformanceClose from 'analytics/technicals/general/PerformanceClose'
import Volatility from 'analytics/technicals/general/Volatility'
import TrendTable from 'analytics/technicals/general/TrendTable'
import { LinearProgress, Paper } from '@mui/material'
import TabLine from 'components/Tabs/TabLine'

export default function IndSecOverview() {
  const {stateIndustry} = useAppInfo()
  const [tabIndex,setTabIndex] = useState(0)
  const {sectorClose,sectorCloseLoaded,sectorTrendTable} = stateIndustry
  const marketInfoLoading = sectorCloseLoaded
  const close = sectorClose
  const tabContent = []

  tabContent[0]=<>
    
  {sectorTrendTable.length>0 && 
    <TrendTable tickerPrice={close} marketInfoLoading={marketInfoLoading} 
  data={sectorTrendTable} tickerDict={sectorDict}/>}</>

  tabContent[1]=<>

  {close.length>0 && 
    <PriceChartMulti marketInfoLoading={marketInfoLoading} 
  close={close} tickerDict={sectorDict}/>}</>
  
  tabContent[2]=<>{close.length>0 && 
        <PerformanceClose marketInfoLoading={marketInfoLoading} 
        close={close} tickerList={Object.keys(sectorDict)} tickerDict={sectorDict}/>}</>
  tabContent[3]=<>{close.length>0 && 
            <Volatility marketInfoLoading={marketInfoLoading} 
            close={close} tickerList={Object.keys(sectorDict)} tickerDict={sectorDict}/>}</>

tabContent[4]=<>{close.length>0 && 
                <Correlation tickerList={Object.keys(sectorDict)} tickerDict={sectorDict}/>}</>


  return (
    <>
    {!close.length>0 && <LinearProgress/>}
    {(!marketInfoLoading && close.length>0) && 
    <div style={{display:"flex",flexDirection:"column",height:"calc(100%)",width:"100%"
    ,padding:"0.5rem",boxSizing:"border-box"}}>
      <Paper style={{display:"flex",flexDirection:"column",height:"calc(100%)",width:"100%"}}>
     <TabLine tabIndex={tabIndex} handleTabChange={(index)=>setTabIndex(index)} 
    tabList={["Table","Trend","Performance","Volatility","Correlation"]}/>
    <div style={{width:"100%"
    ,boxSizing:"border-box",display:"flex",gap:"0.45rem",flex:1,overflow:"auto"}}>
   
    <div style={{flex:1,height:"100%"}}>
    {tabContent[tabIndex]}
    </div>
    </div>
    </Paper>
   </div>}
   </>
  )
}

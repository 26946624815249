import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Animation from '../../animation/Animation';
import BorderDoubleBox from '../../animation/BorderDoubleBox';
import StockMarketLine from './StockMarketLine';


export default function ProductHighlight() {

  const introText = ["Financial Health","Goals","Portfolio","Trades","Opportunities","Analytics","Knowledge"]

    const [text, setText] = useState('');
    const [counter, setCounter] = useState(0);
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCounter((prev) => prev >= introText.length - 1 ? 0 : prev + 1);
      }, 5000);  // Increased duration to fully see the typewriter effect before it switches

      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setText(introText[counter]);
        setDisplayText('');
    }, [counter]);

    useEffect(() => {
        if (text.length > displayText.length) {
            const timeoutId = setTimeout(() => {
                setDisplayText(text.substr(0, displayText.length + 1));
            }, 150);  // Typing speed can be adjusted here

            return () => clearTimeout(timeoutId);
        }
    }, [displayText, text]);


    const [learnMoreEnter,setLearnMoreEnter] = useState(false)

    function handleLearnMore(){
      const scrollToProduct = () => {
        document.getElementById('productFeatures').scrollIntoView({ behavior: 'smooth' });
      };
      scrollToProduct()
    }

    return (
        <>
        
              <div style={{height:"calc(100vh - 100px)"
              ,display:"flex",flexDirection:"column",gap:"1rem"
              ,width:"100%",boxSizing:"border-box",position:"relative"
              ,background:"var(--bgWoodSmoke)",zIndex:200}}>
                <div style={{ paddingTop: "3rem", display: "flex", 
                width: "100%", boxSizing: "border-box", 
                gap: "0.5rem",flexDirection:"column",alignItems:"center"}}>
                    <h1 style={{ fontSize: "5rem", color: "white" }} className='gradient_text'>
                        Take stock of your
                    </h1>
                    <h1 key={text} className='fade-in-text' style={{ fontSize: "4.5rem", color: "white"}}>
                        {text}
                        
                    </h1>
                  
                </div>

                <StockMarketLine/>

                <div style={{display:'flex',flexDirection:"column",overflow:"hidden"
                ,position:"relative",alignItems:"center",flex:1,justifyContent:"center"}}>
                <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}>
                  <div className="grid-dots"></div>
                </div>
               
                <div 
                style={{width:"20%"}}>
                  <div style={{width:"100%"}}>
                <Animation type={text}/>
                </div>

                </div>

               
                  </div>

                    <div
                style={{padding:"3rem",display:"flex",flexDirection:"column"
                ,alignItems:"center",justifyContent:"center"}}>
                  <Button  
                   onMouseEnter={()=>setLearnMoreEnter(true)} 
                   onMouseLeave={()=>setLearnMoreEnter(false)}
                  onClick={()=>handleLearnMore()} sx={{color:"white",padding:"1rem",textTransform:"none"}}>
                  <Typography>  Learn More </Typography>
                  {learnMoreEnter && <KeyboardDoubleArrowDownIcon/>}
                  <BorderDoubleBox/>
                  </Button>
                 
                 
                  </div>
                  

                  </div>


        </>
    )
}



import { LinearProgress, Skeleton } from '@mui/material';
import React from 'react';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import Sparkline from './Sparkline';
import OverflowWrapper from 'components/Navigation/OverflowWrapper';

export default function TickerCardSparkline({ loading, tickerDict, close, width = "200", height = "40", title = true, trendTable, sx = {} }) {
  return (
    <OverflowWrapper>
      <div id="tickerCardSparkline" style={{ width: "100%", display: "flex", gap: "1rem", padding: "0.5rem", boxSizing: "border-box", ...sx }}>
        
        {close.length === 0 &&
          Object.keys(tickerDict).map((ticker) => (
            <Skeleton key={ticker} variant='rect' width={"200px"} height={"70px"} sx={{borderRadius: "5px" }} />
          ))
        }

        {close.length > 0 && Object.keys(tickerDict).map((ticker) => {
          const data = getSpecificArrayObjKeys(close, [ticker]).map((obj) => obj[ticker]).slice(-250);
          const lastValue = data[data.length - 1];
          const prevValue = data[data.length - 2];

          if (data && lastValue && prevValue) {
            const percentageChange = ((lastValue / prevValue - 1) * 100).toFixed(2);
            const isNegative = percentageChange < 0;
            const trendTableInfo = trendTable && trendTable.find(obj => obj.Ticker === ticker);
            const closeValue = trendTableInfo?.["Close"];
            const open = trendTableInfo?.["Open"];
            const low = trendTableInfo?.["Low"];
            const high = trendTableInfo?.["High"];
            const volume = trendTableInfo?.["Volume"];
            const week52high = trendTableInfo?.["52w High"];
            const week52Low = trendTableInfo?.["52w Low"];

            return (
              <div key={ticker}
                style={{ textAlign: "left", display: "flex", flexDirection: "column", minWidth: `${width}px`, borderRadius: "5px", padding: "0.25rem 0.5rem" }}>
                {title && <h4 style={{ fontWeight: "bold", textAlign: "left", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{tickerDict[ticker]}</h4>}
                <div style={{ display: "flex", gap: "0.5rem", alignItems: "left" }}>
                  <h4>{lastValue.toFixed(2)}</h4>
                  <h4 style={{ color: isNegative ? 'red' : 'green' }}>{percentageChange}%</h4>
                </div>
                <div style={{ flex: 1, width: `${width}px` }}>
                  <Sparkline
                    width={width}
                    height={height}
                    data={data}
                    color={isNegative ? 'red' : 'green'}
                    area
                  />
                </div>
                {trendTable && (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem", width: "100%", boxSizing: "border-box" }}>
                    <h5>{`52w Range: ${week52high && week52high.toFixed(2)} - ${week52Low && week52Low.toFixed(2)}`}</h5>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div key={ticker} style={{ width: "200px", boxSizing: "border-box", textAlign: "left" }}>
                <Skeleton variant='rect' sx={{ width: "200px", height: height, borderRadius: "5px" }} />
              </div>
            );
          }
        })}

      </div>
    </OverflowWrapper>
  );
}

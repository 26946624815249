import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useTrendConditions(tickerList) {

const [trendCondition, setData] = useState([]);
const [tcLoading, setMarketInfoLoading] = useState(false);


const [previousPeerSelected, setPreviousPeerSelected] = useState([]);

useEffect(() => {
  const isPeerSelectedEqual =
    tickerList.every((element) => previousPeerSelected.includes(element)) &&
    tickerList.length === previousPeerSelected.length;

  if (Array.isArray(tickerList) && tickerList.length > 0) {
    if (!isPeerSelectedEqual) {
      getInfo(tickerList);
      setPreviousPeerSelected(tickerList);
    }
  }
}, [tickerList]);


async function getInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/trendCondition/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        },
    });
    
    const info = response.data
    setData(info)
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {trendCondition, tcLoading };
}
import React from 'react'
import TreeMap from 'components/Charts/TreeMap'
import { useAppInfo } from 'AppState'

export default function IndSecConstituentsHeatmap() {
  const {stateIndustry} = useAppInfo()
  return (
    <TreeMap type='pagination' data={stateIndustry.constiInfo}/>
  )
}

import React from 'react';

import RiskToleranceQuestionnaire
  from 'resource/guidance/RiskManagement/RiskToleranceQuestionnaire';

import InvestmentObjectiveChecklist from '../InvestmentObjectiveChecklist';
import GuideTemplate from '../Template/GuideTemplate';

export default function PortfolioBuilderGuide() {
  return (
      <GuideTemplate description={description} steps={steps} name={"Portfolio Builder Guide"} />
  );
}

const description = `
Building a portfolio is a strategic process that begins with understanding your investment goals and risk tolerance. It involves selecting a mix of assets, such as stocks, bonds, and alternative investments like real estate or cryptocurrencies, to optimize returns while managing risk. Key steps include setting clear financial goals, aligning asset allocation with your risk profile, and employing diversification to mitigate risks. Regularly rebalancing your portfolio ensures it remains aligned with your objectives. Incorporating tax considerations and continuous review and adjustment of your investment strategy are crucial to adapt to changing market conditions and personal financial situations. This holistic approach aims to maximize long-term returns and meet your financial objectives.
`

const steps = [
  {
    label: "Understanding Investment Goals and Risk Tolerance",
    keyPoints: [
      `Before diving into portfolio construction, 
      it's crucial to understand your investment goals, time horizon, and risk tolerance. 
      Assessing these factors will help determine the appropriate investment strategy 
      and asset allocation.`,
      `Investment goals could include saving for retirement, purchasing a home, funding education,
      or simply growing wealth over time. Each goal may have a different time horizon and risk tolerance.`,
      `Risk tolerance refers to your willingness and ability to endure fluctuations in the value 
      of your investments. It's influenced by factors such as financial situation, investment experience, 
      and emotional resilience.`,
      `Consideration of risk tolerance involves understanding how you react to market volatility, 
      your comfort level with potential losses, and your investment time frame.`
    ],
    success: [
      "You have a clear understanding of your investment goals and objectives.",
      "You have assessed your risk tolerance and understand your comfort level with investment fluctuations.",
      "You have identified your investment time horizon, whether it's short-term, medium-term, or long-term.",
      "You recognize the importance of aligning your investment strategy with your goals and risk tolerance.",
      <RiskToleranceQuestionnaire/>,
    ],
    warning: [
      "Ignoring risk tolerance can lead to investments that are too aggressive or conservative for your comfort level, potentially resulting in poor performance or emotional stress.",
      "Failing to revisit and reassess your investment goals and risk tolerance regularly may result in a mismatch between your portfolio and your current financial situation or objectives.",
      "Overestimating your risk tolerance could lead to taking on excessive risk, while underestimating it may result in missed opportunities for growth.",
      "Investing without a clear understanding of your goals and risk tolerance increases the likelihood of making impulsive or emotional investment decisions."
    ],
    content: <><InvestmentObjectiveChecklist/></>,
  },
  {
    label: "Asset Allocation Strategy",
    keyPoints: [
      `Asset allocation involves distributing investments across different asset classes such as stocks, bonds, cash, and alternative investments. A well-diversified portfolio can help manage risk and optimize returns.`,
      `Considerations for asset allocation include:`,
      `1. Risk tolerance: Determine your tolerance for market fluctuations and potential losses. Higher-risk investments may offer greater potential returns but also carry increased volatility.`,
      `2. Investment goals: Align your asset allocation with your financial objectives and time horizon. Short-term goals may require a more conservative approach, while long-term goals may allow for greater exposure to equities.`,
      `3. Diversification: Spread investments across multiple asset classes to reduce concentration risk. Each asset class may perform differently under various market conditions, providing diversification benefits.`,
      `4. Market outlook: Consider current market conditions and economic outlook when allocating assets. Adjust allocations based on growth expectations, interest rates, and geopolitical factors.`,
      `5. Rebalancing: Regularly review and rebalance your portfolio to maintain target asset allocations. Rebalancing ensures that your portfolio remains aligned with your risk tolerance and investment objectives.`
    ],
    content: <></>,
    warning: [
      "Neglecting to regularly rebalance your portfolio to maintain your target asset allocation may lead to unintended deviations and exposure to unnecessary risk.",
      "Focusing too heavily on a single asset class or investment sector increases susceptibility to market downturns or sector-specific challenges.",
      "Failing to consider your investment time horizon, risk tolerance, and financial goals when determining asset allocation may result in an inappropriate portfolio mix.",
      "Relying solely on past performance or market trends to guide asset allocation decisions may lead to suboptimal outcomes, as past performance is not indicative of future results."
    ],
    success: [
      "A well-diversified portfolio can help mitigate risk and capture returns across various market conditions.",
      "Properly allocating assets based on your investment goals, risk tolerance, and time horizon can improve the likelihood of achieving your financial objectives.",
      "Regularly reviewing and adjusting your asset allocation to align with changing market conditions and personal circumstances can enhance portfolio resilience and performance.",
      "Implementing a strategic asset allocation framework tailored to your individual circumstances can provide a disciplined approach to managing investment risk and maximizing long-term returns."
    ],
  },
  {
    label: "Stock Selection Criteria",
    keyPoints: [
      `In addition to company fundamentals and growth potential, consider qualitative factors such as management quality, competitive advantage, and industry positioning when selecting stocks.`,
      `Develop a disciplined approach to stock selection, including thorough research, quantitative analysis, and evaluation of financial metrics.`,
      `Implement risk management strategies such as setting stop-loss orders and diversifying across sectors and industries to mitigate individual stock risk.`
    ],
    content: <></>,
    warning: [
      "Overemphasis on short-term market trends or speculative factors may lead to suboptimal investment decisions and increased portfolio volatility.",
      "Ignoring valuation metrics and focusing solely on growth potential may result in overvalued investments and potential losses."
    ],
    success: [
      "Implementing rigorous stock selection criteria can help identify high-quality investment opportunities and improve portfolio performance over the long term.",
      "Maintaining a diversified portfolio of well-researched stocks can reduce idiosyncratic risk and enhance risk-adjusted returns."
    ]
  },
  {
    label: "Bond Selection Criteria",
    keyPoints: [
      `When evaluating bonds, consider factors such as credit rating, maturity date, yield to maturity, and duration.`,
      `Assess the issuer's financial health and creditworthiness to minimize default risk.`,
      `Diversify bond holdings across different issuers, sectors, and maturities to reduce concentration risk.`,
      `Monitor interest rate movements and adjust bond durations accordingly to manage interest rate risk.`
    ],
    content: <></>,
    warning: [
      "Investing in lower-quality bonds or those with longer durations may offer higher yields but also carry increased credit and interest rate risk.",
      "Neglecting to regularly monitor bond holdings for credit rating changes or default risks may result in unexpected losses."
    ],
    success: [
      "Constructing a well-diversified bond portfolio with high-quality issuers and appropriate maturities can provide steady income and stability to the overall investment portfolio.",
      "Implementing active management strategies to adapt to changing market conditions and interest rate environments can enhance bond portfolio performance."
    ]
  },
  {
    label: "Other Asset Classes (Crypto, Real Estate, etc.)",
    keyPoints: [
      `Explore alternative asset classes such as cryptocurrencies, real estate, commodities, and private equity to diversify your portfolio and potentially enhance returns.`,
      `Understand the unique characteristics and risk profiles of each asset class before investing, including liquidity, volatility, and regulatory considerations.`,
      `Allocate capital to alternative investments based on their correlation with traditional asset classes and their potential contribution to overall portfolio risk and return objectives.`,
      `Monitor alternative investments closely and adjust allocations as needed to capitalize on opportunities or manage risks effectively.`
    ],
    content: <></>,
    warning: [
      "Investing in alternative asset classes may involve higher volatility, liquidity constraints, and regulatory uncertainties compared to traditional investments such as stocks and bonds.",
      "Neglecting due diligence and risk assessment when investing in alternative assets may lead to significant losses or unintended exposures."
    ],
    success: [
      "Including alternative asset classes in your investment portfolio can provide diversification benefits and enhance risk-adjusted returns, especially in periods of market volatility and economic uncertainty.",
      "Strategically allocating capital to alternative investments based on their unique risk-return characteristics and correlation with traditional asset classes can improve portfolio efficiency and resilience."
    ]
  },
  {
    label: "Risk Management and Diversification",
    keyPoints: [
      `Implement a risk management strategy that includes diversification across asset classes, geographic regions, and investment styles.`,
      `Regularly review portfolio holdings to ensure adequate diversification and mitigate concentration risk.`,
      `Consider incorporating defensive assets such as bonds, cash equivalents, and alternative investments to hedge against market downturns.`,
      `Monitor portfolio risk metrics such as volatility, correlation, and maximum drawdown to assess overall portfolio risk exposure.`
    ],
    content: <></>,
    warning: [
      "Over-concentration in any single asset class or sector may expose the portfolio to heightened risk.",
      "Neglecting to periodically rebalance the portfolio to maintain target asset allocations may result in unintended deviations and increased portfolio risk."
    ],
    success: [
      "Effective diversification can help reduce overall portfolio risk and enhance risk-adjusted returns, especially during periods of market volatility.",
      "Implementing risk management techniques such as asset allocation, diversification, and hedging strategies can help protect capital and preserve long-term investment objectives."
    ]
  },
  {
    label: "Unique Considerations (ESG, etc.)",
    keyPoints: [
      `Integrate Environmental, Social, and Governance (ESG) factors into your investment analysis and decision-making process to align your portfolio with sustainable and ethical principles.`,
      `Screen investments based on ESG criteria to identify companies with strong sustainability practices and responsible governance.`,
      `Engage with companies and investment managers to advocate for ESG integration and promote positive change.`,
      `Measure and report the impact of ESG considerations on investment performance and social outcomes to stakeholders.`
    ],
    content: <></>,
    success: [
      "Incorporating ESG considerations into investment decisions can help align your portfolio with values and principles beyond financial returns, contributing to positive social and environmental outcomes.",
      "Engaging with companies and investment managers on ESG issues can drive positive change and promote sustainable business practices, leading to improved long-term financial performance and societal impact."
    ]
  },
 
  {
    label: "Strategic and Tactical Asset Allocation",
    keyPoints: [
      `Strategic asset allocation involves establishing a long-term investment plan based on your financial goals and risk tolerance.`,
      `Tactical asset allocation involves making short-term adjustments to capitalize on market opportunities or mitigate risks.`,
      `Implement a disciplined approach to strategic asset allocation by periodically rebalancing your portfolio to maintain target asset allocations.`,
      `Monitor market trends, economic indicators, and geopolitical events to identify tactical opportunities and adjust asset allocations accordingly.`
    ],
    content: <></>,
    success: [
      "Implementing a strategic asset allocation framework tailored to your individual circumstances can provide a disciplined approach to managing investment risk and maximizing long-term returns.",
      "Tactically adjusting asset allocations based on short-term market conditions and opportunities can enhance portfolio performance and capitalize on emerging trends or risks."
    ]
  },
  {
    label: "Rebalancing",
    keyPoints: [
      `Regularly review your portfolio's asset allocation and adjust it as necessary to maintain your desired risk-return profile.`,
      `Rebalancing involves selling assets that have appreciated significantly and reinvesting in underperforming assets to maintain your target allocation.`,
      `Implement a systematic rebalancing strategy based on predefined thresholds or time intervals to ensure disciplined portfolio management.`,
      `Consider tax implications and transaction costs when rebalancing your portfolio to minimize expenses and maximize after-tax returns.`
    ],
    content: <></>,
    success: [
      "Regularly rebalancing your investment portfolio can help maintain your desired asset allocation and manage portfolio risk over time.",
      "Implementing a systematic rebalancing strategy can provide discipline and consistency in portfolio management, improving long-term investment outcomes."
    ]
  },
  {
    label: "Tax Considerations",
    keyPoints: [
      `Utilize tax-advantaged accounts such as 401(k)s, IRAs, and Roth IRAs to minimize taxes and maximize after-tax returns.`,
      `Strategically allocate investments across taxable and tax-advantaged accounts to optimize tax efficiency and asset location.`,
      `Implement tax-loss harvesting strategies to offset capital gains and reduce taxable income.`,
      `Be mindful of holding periods for investments to qualify for favorable tax treatment, such as long-term capital gains.`,
      `Consult with a tax advisor or financial planner to develop tax-efficient investment strategies and minimize tax liabilities.`,
    ],
    content: <></>,
    warning: [
      "Ignoring tax implications when making investment decisions may result in higher tax liabilities and reduced after-tax returns.",
      "Failing to utilize tax-advantaged accounts or tax-efficient investment strategies may limit opportunities to optimize tax savings.",
    ],
    success: [
      "Implementing tax-efficient investment strategies can help minimize taxes and maximize after-tax returns, enhancing overall portfolio performance.",
      "Regularly reviewing and adjusting investment holdings to optimize tax efficiency can lead to significant long-term tax savings and improved investment outcomes.",
    ],
  },
  {
    label: "Review and Adjustment",
    keyPoints: [
      `Regularly review your investment plan and portfolio performance to ensure alignment with your financial goals and objectives.`,
      `Evaluate changes in your financial situation, risk tolerance, and market conditions to determine if adjustments to your investment strategy are necessary.`,
      `Consider rebalancing your portfolio periodically to realign asset allocations with target percentages and manage portfolio risk.`,
      `Monitor the performance of individual investments and replace underperforming assets with better opportunities to enhance portfolio returns.`,
      `Document your investment decisions and review your investment plan regularly to track progress towards your financial goals and make informed adjustments.`,
    ],
    content: <></>,
    warning: [
      "Neglecting to review and adjust your investment plan regularly may result in missed opportunities or mismatches between your portfolio and financial objectives.",
      "Failing to adapt your investment strategy to changing market conditions or personal circumstances may lead to suboptimal investment outcomes and missed financial goals.",
    ],
    success: [
      "Regularly reviewing and adjusting your investment plan can help ensure that your portfolio remains aligned with your financial goals and risk tolerance over time.",
      "Making proactive adjustments to your investment strategy based on changing market conditions and personal circumstances can improve portfolio performance and increase the likelihood of achieving long-term financial success.",
    ],
  }
];

import React from 'react'
import { dfin } from 'content/constants'
import CompanyFinancialsTable from './CompanyFinancialsTable';
import FinancialsChart from 'analytics/ticker/stocks/Financials/Company/FinancialsChart';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';
import { calculateGrowthRates } from './../utils';

export default function CompanyFinancialsSelf(
{statementInfo,metricSelected=[],title="",statementMetric=[],handleMetricSelected,table=true,financialsSettings}

) {

  const {growthMode,reportMode,chartType,displayType} = financialsSettings



  return (
    <>
 {!statementInfo.length > 0 && <LoadingSkeletonChart/>}
{statementInfo.length > 0 && !growthMode && ["Chart","Chart & Table"].includes(displayType)  && (
          <div style={{height:displayType === "Chart"?"100%":"60%",position:"sticky",left:0}}>
          <FinancialsChart
            chartType={chartType}
            data={statementInfo}
            title={title}
            metricSelected={metricSelected}
            period={dfin.period}
          />
          </div>
        )}
        
        {statementInfo.length > 0 && growthMode && ["Chart","Chart & Table"].includes(displayType) && (
          <div style={{height:displayType === "Chart"?"100%":"60%",position:"sticky",left:0}}>
             <FinancialsChart
            chartType={chartType}
            growthMode={true}
            data={calculateGrowthRates({data:statementInfo,metrics:metricSelected,dateKey:[dfin.period]})}
            title={title}
            metricSelected={metricSelected}
            period={dfin.period}
          />
          </div>
        )}
      
        {(statementInfo.length > 0 && statementMetric.length>0 && ["Table","Chart & Table"].includes(displayType)) && (
          <CompanyFinancialsTable
            growthMode={growthMode}
            statementInfo={statementInfo}
            statementMetric={statementMetric}
            metricSelected={metricSelected}
            onChange={handleMetricSelected}
          />
        )}

    </>
  )
}

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LeftSidebar from "sidebar/LeftSidebar/LeftSidebar";
import { Paper } from "@mui/material";
import { useAppInfo } from "AppState";
import { menuMyFinancials } from "nav/menu";
import LeftSidebarPageMenu from "sidebar/LeftSidebar/LeftSidebarPageMenu";
import MyFinancialsInfo from "../../myFinancials/MyFinacialsInfo";
import FinancialPlanningOnboarding from "../../myFinancials/OnBoarding/FinancialPlanningOnboarding";

export default function MyFinancials() {
  const { stateMyFinancials, dispatchMyFinancials, stateApp } = useAppInfo();
  const { onboarding } = stateApp;
  const { menuSel, subMenuSel, mainMenuSel } = stateMyFinancials;
  const [menuOptions, setMenuOptions] = useState(menuMyFinancials);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const pathname = location.pathname;
  const subPage = pathname.split("/")[2];

  useEffect(() => {
    if (subPage) {
      const menuSel = menuMyFinancials.filter((obj) => obj.link === subPage)[0]
        ?.name;
      dispatchMyFinancials({ mainMenuSel: menuSel });
    } 
    // else {
    //   dispatchMyFinancials({ mainMenuSel: "Profile" });
    // }
  }, [subPage]);

  useEffect(() => {
    setLoading(true);
    const mainMenuOptions = menuMyFinancials.filter(
      (obj) => obj.name === mainMenuSel
    )[0];
    let menuContent = mainMenuOptions?.menuOptions;
    let contentList = [];
    if (!menuContent) {
      dispatchMyFinancials({menuSel: mainMenuSel, subMenuSel: "" });
      setMenuOptions([]);
    } else {
      const menuNames = menuContent.map((obj) => obj.name);

      if (!menuNames.includes(menuSel)) {
        let menuOptionSel = menuContent.map((obj) => obj.name)[0];
        dispatchMyFinancials({ menuSel: menuOptionSel });
        contentList = menuContent.filter((obj) => obj.name === menuOptionSel)[0]
          ?.content;
      } else {
        contentList = menuContent.filter((obj) => obj.name === menuSel)[0]
          ?.content;
      }

      if (!contentList.length > 0) {
        dispatchMyFinancials({ subMenuSel: "" });
      } else {
        if (!contentList.includes(subMenuSel)) {
          dispatchMyFinancials({ subMenuSel: contentList[0] });
        }
      }
      setMenuOptions(menuContent);
    }
    setLoading(false);
  }, [mainMenuSel]);

  function handleContentSel(n, v) {
    dispatchMyFinancials({ menuSel: n, subMenuSel: v });
  }

  function handleMenuSel(v) {
    dispatchMyFinancials({ menuSel: v });
    const infoSel = menuOptions
      .filter((obj) => obj.name === v)
      .map((obj) => obj.content);

    if (infoSel[0].length > 0) {
      dispatchMyFinancials({ subMenuSel: infoSel[0][0] });
    } else {
      dispatchMyFinancials({ subMenuSel: v });
    }
  }

  return (
    <>
     {/* <LeftSidebar
          name={"My Financials"}
          menuOptions={menuMyFinancials}
          contentSel={mainMenuSel}
        /> */}

      <div className="pageContentWrapper">
        
      <MyFinancialsInfo infoType={"header"} mainMenu={mainMenuSel} />

        <div className="containerLeftMenuAndContent">
          {!loading && menuOptions.length > 0 && (
            <LeftSidebarPageMenu
              name={`Information`}
              bigMenu={false}
              menuOptions={menuOptions}
              menuSel={menuSel}
              contentSel={subMenuSel}
              handleContentSel={handleContentSel}
              handleMenuSel={handleMenuSel}
            />
          )}
          <Paper
            elevation={menuOptions.length === 0 ? 0 : 1}
            className="containerMainContent"
          > 
            <MyFinancialsInfo
              mainMenu={mainMenuSel}
              menu={menuSel}
              subMenu={subMenuSel}
            />
          </Paper>
        </div>
      </div>

      {!onboarding?.financials && <FinancialPlanningOnboarding />}
    </>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useDividendHistory(ticker) {
  const [dividendHistory, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/historical-price-full/stock_dividend/${ticker}?apikey=${financialModelingPrepApiKey}
          `
        );
        setData(response.data.historical);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker) {
      fetchData();
    }
  }, [ticker]);

  return { dividendHistory };
}

import React, { useMemo, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import { dfin } from 'content/constants';
require("highcharts/modules/accessibility")(Highcharts);

const PieChartCategory = ({
  data,
  category,
  exporting = false,
  title = "",
  width = "100%",
  height = "100%",
  weightType = "count",
  weightCategory,
  showConnectors = true,
  legend={maxHeight:50,align:"center",width:"100%"},
  ticker=false
}) => {

  const {stateTicker} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const {tickerSelected} = stateTicker

  const [chartOptions, setChartOptions] = useState({});

  // Memoize calculations to avoid unnecessary recalculations
  const { counts, weights, totalWeight } = useMemo(() => {
    const counts = {};
    const weights = {};
    const totalWeight = weightCategory ? data.reduce((total, item) => total + (item[weightCategory] || 0), 0) : data.length;
    data.forEach(item => {
      const value = item[category];
      counts[value] = (counts[value] || 0) + 1;
      if (weightType === "weighted" && weightCategory) {
        const weight = item[weightCategory];
        weights[value] = (weights[value] || 0) + (weight || 0);
      }
    });
    return { counts, weights, totalWeight };
  }, [data, category, weightType, weightCategory]);

  // Prepare data for the chart
  const chartData = useMemo(() => {
    const selectedTickerIndex = ticker ? data.map((data) => data["TICKER"]).indexOf(tickerSelected) : null;
    
    return Object.entries(counts).map(([name, count], index) => {
      const item = data.find(d => d[category] === name);
      const weight = weightType === "weighted" ? weights[name] || 0 : 1;
      const isSelected = ticker && selectedTickerIndex === index;
      
      return {
        name,
        y: (weight / totalWeight) * 100, // Calculate percentage
        color: isSelected ? 'black' : item?.color || undefined, // Use item color or fallback to default
        sliced: isSelected,
        selected: isSelected,
      };
    });
  }, [counts, weights, totalWeight, weightType, tickerSelected, data, category]);

  // Update chart options
  useEffect(() => {
    const options = {
      chart: {
        type: 'pie',
        panning: false,
      },
      title: {
        text: title,
        // verticalAlign: 'middle',
      },
      series: [{
        innerSize: '60%',
        name: 'Percentage',
        colorByPoint: true,
        data: chartData,
        
      }],
      tooltip: {
        pointFormat: '<b>{point.name}</b>: {point.y:.1f}%',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: !showConnectors,
          dataLabels: {
            enabled: showConnectors,
            alignTo: 'connectors',
            distance: 10,
            style: {
              wordWrap: 'break-word !important',
              whiteSpace: "noWrap !important",
            },
            formatter: function () {
              return `<b>${this.point.name}</b><br/>${this.y.toFixed(1)}%`;
            },
          },
          events: {
            click: function (event) {
              const category = event.point.name;
              if (ticker){
              handleTickerChange({ticker:category})};
            },
          },
        },

      },
     
      exporting: {
        enabled: exporting,
      },
      credits: {
        enabled: false,
      },
      // legend: {
      //   align: "right",          // Aligns the legend to the right
      //   verticalAlign: "middle", // Vertically aligns the legend to the middle
      //   layout: "vertical",      // Arranges the legend items vertically
      //   maxHeight: "100%",           // Sets the maximum height for the legend
      // }
    };

    setChartOptions(options);
  }, [chartData, title, exporting, showConnectors]);

  return (
    <div style={{ height: height, overflowY: "auto", width: width }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions}
        containerProps={{ style: { height: '100%', width: "100%" } }} />
    </div>
  );
};

export default PieChartCategory;

import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import TickerCardSparkline from 'components/Charts/TickerCardSparkline';
import { intermarketBonds, intermarketCommodity, intermarketCrpto, intermarketEtf, intermarketForex, intermarketStocks } from 'content/contentMarket';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import MarketOverviewHeader from './MarketOverviewHeader';

const assetTypes = [
  { type: 'Stocks', dict: intermarketStocks, width: '50%' },
  { type: 'Fixed Income', dict: intermarketBonds, width: '25%' },
  { type: 'Forex', dict: intermarketForex, width: '20%' },
  { type: 'Crypto', dict: intermarketCrpto, width: '25%' },
  { type: 'Commodities', dict: intermarketCommodity, width: '40%' },
  { type: 'ETF & MF', dict: intermarketEtf, width: '30%' },
]

const MarketOverview = () => {
  const { stateMarket,dispatchMarket } = useAppInfo();
  const {trendTableInterMarket,closeInterMarket} = stateMarket
  const navigate = useNavigate();
  const [mouseEnter,setMouseEnter] = useState(false)
  const handleAssetType = (v) => {
    dispatchMarket({"assetClassSel":v})
    navigate("/Market")
  };

  const renderLayout = (type, dict,width) => (
    <div
      onMouseEnter={()=>setMouseEnter({type:type,enter:true})}
      onMouseLeave={()=>setMouseEnter({type:type,enter:false})}
      style={{
        border: '1px solid #ddd',
        width: width,
        background: 'white',
        boxShadow: (mouseEnter.type===type && mouseEnter.enter) && 'var(--boxShadowGeneral)',

      }}
    > 
      <div style={{display:"flex"}}>
      {/* <h3 style={{color:"gray",padding:"1rem"}}>{type}</h3> */}
      <Button
        size='large'
        onClick={() => handleAssetType(type)}
        style={{ border: 'none', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
      >
        {type} <NorthEastIcon />
      </Button>
      </div>
      <TickerCardSparkline sx={{ flexWrap: 'wrap' }} trendTable={trendTableInterMarket} close={closeInterMarket} tickerDict={dict} />
    </div>
  );
  const [tabIndex,setTabIndex] = useState(0)
  function handleTabChange(index){
    setTabIndex(index)
  }
  const [layout,setLayout] = useState("grid")

  return (
    <>
    <MarketOverviewHeader/>
    <div style={{ width: '100%', background: 'white', height: '90%', overflow: 'auto' }}>
      <div style={{float:"right",display:"flex",gap:"0.5rem"}}>
        <ButtonGroup size='small'>
        <Button style={layout==="list"?{background:"black",color:"white"}:{}} onClick={()=>setLayout("list")}><ViewListIcon/></Button>
        <Button style={layout==="grid"?{background:"black",color:"white"}:{}} onClick={()=>setLayout("grid")}><GridViewIcon/></Button>
        </ButtonGroup></div>

        {layout==="list" && trendTableInterMarket.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '1rem',
            gap: '1rem',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
          }}
        >
          {assetTypes.map(({ type, dict, width }) => renderLayout(type, dict, "100%"))}
        </div>
      )}

      {layout==="grid" && trendTableInterMarket.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '1rem',
            gap: '1rem',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
          }}
        >
          {assetTypes.map(({ type, dict, width }) => renderLayout(type, dict, width))}
        </div>
      )}
    </div>
    </>
  );
};

export default MarketOverview;

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { dfin, financialsAll} from 'content/constants';
import TabLine from '../Tabs/TabLine';
import MetricSelectionModal from './MetricSelectionModal';
import Modal from 'components/Modal/Modal';

export default function MetricStockFinancialCategory
({title,statementCategory,statementType,handleMetricChange,metricSelected,onClose}){
    const {stateStock,dispatchStock} = useAppInfo()
    const {metrics} = stateStock
    const metricFinancials = metrics?.metricFinancials
    const [statementTypeSel,setStatementTypeSel] = useState()
    const statements = ["All",...financialsAll.map(obj=>obj.value)]
    const statementTabs = ["All",...financialsAll.map(obj=>obj.label)]
   
    
    useEffect(()=>{
      if(statementType){
        setStatementTypeSel(statementType)
      }
    },[statementType])

    const [metricInfo,setMetricInfo] = useState([])

    useEffect(() => {
        const fetchData = async () => {
          let matchingCategory = metricFinancials.filter(
            (obj) =>
              (statementTypeSel !=="All" && obj[dfin.statement] === statementTypeSel) &&
              obj[dfin.statementCategory] !== null
          ).map(obj=>{return {name:obj.Metric,category:obj?.["Statement Category"]}});

          if (statementTypeSel ==="All"){
            matchingCategory = metricFinancials.map(obj=>{return {name:obj.Metric,category:obj?.["Statement"]}})
          }

          setMetricInfo(matchingCategory)
        };
    
        if (statementTypeSel) {fetchData();}
        //
      }, [statementTypeSel, metricFinancials]);
    
      

  const [tabIndex,setTabIndex] = useState(0)
  useEffect(()=>{
    if (!statementType){
      setStatementTypeSel(statements[tabIndex])}
  },[tabIndex])

  return (
    <Modal onClose={onClose}>
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%"}}>
    <h2 style={{padding:"0.25rem"}}>{title}</h2>
    {!statementType &&
      <div>
       <TabLine tabList={statementTabs} 
       tabIndex={tabIndex} handleTabChange={(index)=>setTabIndex(index)}/></div> }

       <MetricSelectionModal 
       modal={false}
       metricInfo={metricInfo}
       handleMetricSelected={handleMetricChange}
       metricSelected={metricSelected}/>
       </div>   
   </Modal>
  )
}
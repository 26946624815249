import React, { useEffect, useState } from 'react'
import SelectBox from './SelectBox';
import Modal from '../Modal/Modal';
import { Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SelectMarket({width="auto",label=true,sx,handleMarket,defaultMarket,defaultMarketOptions=["USA"]}) {
  const [marketRegion,setMarketRegion] = useState("USA")
  const [marketRegionModal,setMarketRegionModal] = useState(false)
  const options = defaultMarketOptions

  useEffect(()=>{
    if (defaultMarket){
      setMarketRegion(defaultMarket)
    }
  },[defaultMarket])

  function handleMarketChange(e, v) {
    setMarketRegionModal(false)
    setMarketRegion(v);
    if (typeof handleMarket === 'function') {
        handleMarket(v);
    }
}
const [countryCodeSel,setCountryCodeSel] = useState("us")

const countryCode = {
  "USA":"us",
  "India":"in",
  "Canada":"ca",
  "UK":"gb"
}
useEffect(()=>{
setCountryCodeSel(countryCode[marketRegion])
},[marketRegion])


return (
  <>
  <Tooltip title={"Market Region"}>
  <div 
  onClick={()=>setMarketRegionModal(!marketRegionModal)}
  style={{cursor:"pointer",display:"flex",gap:"0.5rem",alignItems:"center",position:"relative",width:width}}>
  <div 
  className={`fi fi-${countryCodeSel} fib`} style={{width:"30px",height:"30px",display:"flex",justifyContent:"center",alignItems:"center"}}>
  </div>
  {label && 
  <div style={{display:"flex",gap:"0.05rem",alignItems:"flex-end"}}>
  <h1 style={{fontSize:"1.5rem"}}>{marketRegion}</h1>
  <ExpandMoreIcon sx={{color:"gray"}} fontSize='small'/>
  </div>}
  </div>
  </Tooltip>
  
  {marketRegionModal && 
  <Modal onClose={()=>setMarketRegionModal(false)} height='200px' width='auto'>
  <div style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"2rem"}}>
  <h2>Select Market Region</h2>
  <SelectBox width={"100%"} options={options} value={marketRegion} onChange={handleMarketChange} label={"Market Region"} />
  </div>
  </Modal>}
  
  
  </>
  )
}
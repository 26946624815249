import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindScreener = () => {
  const { dispatchScreener,stateApp} = useAppInfo()
  const userId = stateApp.userId


  const findScreener = async () => {
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findScreener/`, {
          userId
        });
        // Process the response data as needed
       const screenerData = response.data;
      const screenerNames = Object.keys(screenerData);
      
      dispatchScreener({'screenerNamesUser': screenerNames,'screenerDataUser':screenerData});
      }
    } catch (err) {
      // Handle the error
    }
  };

  useEffect(() => {
    if (userId) {
      findScreener();
    }
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindScreener;




import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { eodHistorialApiKey } from '../keys';


export default function useRealTimePrice({ tickerSel }) {
  const [price, setPrice] = useState([]);
  const [loadingPrice, setLoadingPrice] = useState(false);

  useEffect(() => {
    if (tickerSel) {
      // Check if the price for the current ticker is in sessionStorage
      const storedPrice = sessionStorage.getItem(`price_${tickerSel}`);

      if (storedPrice) {
        setPrice(JSON.parse(storedPrice));
      } else {
        getMetricInfo();
      }
      // getMetricInfo()
    }
  }, [tickerSel]);

  // GET COMPANY DATA
  async function getMetricInfo() {
    setLoadingPrice(true);
    try {
      const responseMetric = await axios(
        `https://eodhd.com/api/real-time/${tickerSel}?api_token=${eodHistorialApiKey}&fmt=json`
      );
      const priceInfo = responseMetric.data
      for (const key in priceInfo) {
        // Check if the key is not "code" or "timestamp"
        if (key !== "code" && key !== "timestamp") {
          // Convert the value to float, or NaN if it's "NA"
          priceInfo[key] = parseFloat(priceInfo[key]) || 0.00;
        }
      }
      setPrice(priceInfo);

      // Store the fetched price in sessionStorage
      sessionStorage.setItem(`price_${tickerSel}`, JSON.stringify(responseMetric.data));
    } catch (error) {
      console.error(error);
    }
    setLoadingPrice(false);
  }

  return { price, loadingPrice };
}
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

const useFindWatchlist = () => {
  const { dispatchWatchlist,stateApp } = useAppInfo()
  const [watchlistData, setWatchlistData] = useState(null);
  const [error, setError] = useState(null);
  const userId = stateApp.userId

  // console.log("findWatchlist",username)
  useEffect(() => {
    const fetchData = async () => {
      dispatchWatchlist({"loadingWatchlist": false });
      try {
        const response = await axios.post(`${BASE_URL}/findWatchlist/`, {
          userId,
        });

        if (response.status === 200) {
          const wlData = response.data;      
          setWatchlistData(response.data);
          const wlName = Object.keys(wlData);
          dispatchWatchlist({
            "watchlistData":wlData,
            "watchlistSelected":wlName[0],
            "loadingWatchlist":true 
          });
          setError(null); // Clear any previous error
        } else if (response.status === 404) {
          // No data found, handle this case as needed
          setWatchlistData(null); // Clear any previous data
          setError("No Watchlist data found");
        } else {
          // Handle other response status codes if needed
          setWatchlistData(null); // Clear any previous data
          setError("Error retrieving Watchlist data");
          dispatchWatchlist({"loadingWatchlist": true });
        }
      } catch (err) {
        // Handle network or request error
        console.error("Error retrieving Watchlist data:", err);
        setWatchlistData(null); // Clear any previous data
        setError("Error retrieving Watchlist data");
        dispatchWatchlist({"loadingWatchlist":true });
      }
    };
    if (userId) {
      fetchData();
    }
    
  }, [userId]);

  return { watchlistData, error };
};

export default useFindWatchlist;

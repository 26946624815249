import { useAppInfo } from "AppState"
import { dfin } from "content/constants"
import React,{useState,useEffect} from 'react'
import Toggle from "components/Inputs/Toggle";
import IndSecFilter from "./IndSecFilter";
import IndSecNoInfo from "./IndSecNoInfo";
import TabLine from "components/Tabs/TabLine";
import IndSecConstituentsScatter from "./Constituents/IndSecConstituentsScatter";
import PaginationButtons from "components/PaginationButtons";
import IndSecConstituentsHeatmap from "./Constituents/IndSecConstituentsHeatmap";
import IndSecConstituentsTechnicals from "./Constituents/IndSecConstituentsTechnicals";
import IndSecConstituentsVisuals from "./Constituents/IndSecConstituentsVisuals";
import IndSecContent from "./IndSecContent";
import {Paper } from "@mui/material";
import LeftSidebarFloating from "sidebar/LeftSidebar/LeftSidebarFloating";
import LeftMenuCheckboxList from "sidebar/LeftSidebar/LeftMenuCheckboxList";




export default function IndSecLayout({handlePage,pageDetails}) {
  const {itemsPerPage,totalPages,currentPage} = pageDetails
  const [indSecChecklist,setIndSecChecklist] = useState([])
  const {stateIndustry,dispatchIndustry,stateStock,stateScreener} = useAppInfo()
  const {loadingStocks} = stateScreener
  const {analysisOptionSel} = stateIndustry
  const uniqueSectors = stateIndustry.uniqueSectors
  const secIndList = stateIndustry.secIndList
  const industrySel = stateIndustry.industrySel
  const sectorSel = stateIndustry.sectorSel
  const [tabIndex,setTabIndex] = useState(0)
  const menuNames = Object.keys(infoList)


  useEffect(() => {
    
    if (!stateIndustry.indSecMode) {
      const tabList = ["All", ...uniqueSectors];
      const sortedSectors = [...new Set(tabList)].sort(); // Sort in ascending order
      setIndSecChecklist(sortedSectors);
      if (sectorSel.length===0){
      dispatchIndustry({"sectorSel":[uniqueSectors.sort()[0]]})}
    } 
    else {
      
      if (sectorSel.includes("All")) {
        const industryAll = secIndList.map((item) => item[dfin.industry]);
        setIndSecChecklist(industryAll.sort());
        if (industrySel.length===0){
        dispatchIndustry({"industrySel":[industryAll[0]]})}
    
      } else {
        const secArray = secIndList.filter((item) =>
          sectorSel.includes(item[dfin.sector])
        );
        const industryInSec = secArray.map((item) => item[dfin.industry]);
        
        const sortedIndustries = [...new Set(industryInSec.sort())].sort((a, b) => {
          const aSelected = industrySel.includes(a);
          const bSelected = industrySel.includes(b);
          return bSelected - aSelected; // Descending order to move selected industries to the front
        });
        
        setIndSecChecklist(sortedIndustries);
        if (industrySel.length===0 || !industrySel.some(industry => sortedIndustries.includes(industry))){
       
        dispatchIndustry({"industrySel":[sortedIndustries[0]]})}
    
        // setTabList(industryInSec.sort())
      }
    }
  }, [stateIndustry.indSecMode, stateIndustry.secIndList,stateStock.companySelected,stateIndustry.sectorSel]);

  const handleCheckboxList = (event) => {
    const checkedVal = event.target.value;
  
    if (!stateIndustry.indSecMode) {
      if (event.target.checked) {
        if (checkedVal === "All") {
          dispatchIndustry({"sectorSel":["All"] });
        } else {
          const sectorSel = (prevSelected) => {
            if (prevSelected.includes("All")) {
              return prevSelected.filter((val) => val !== "All").concat(checkedVal);
            } else {
              return [...prevSelected, checkedVal];
            }
          };
          dispatchIndustry({"sectorSel":sectorSel(stateIndustry.sectorSel) });
        }
      } else {
        const updatedSelection = stateIndustry.sectorSel.filter((val) => val !== checkedVal);
        if (updatedSelection.length > 0) {
          dispatchIndustry({"sectorSel":updatedSelection });
        }
      }
    } else {
      if (event.target.checked) {
        dispatchIndustry({"industrySel":[...stateIndustry.industrySel, checkedVal] });
      } else {
        const updatedSelection = stateIndustry.industrySel.filter((val) => val !== checkedVal);
        if (updatedSelection.length > 0) {
          dispatchIndustry({"industrySel":updatedSelection });
        }
      }
    }
  };

 
  function handleIndustryToggle() {
    dispatchIndustry({"indSecMode":!stateIndustry.indSecMode});
  }

 
  const headerDetails =   <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",gap:"1rem"
  }}>
    <Toggle
      toggleMode={stateIndustry.indSecMode}
      handleToggle={handleIndustryToggle}
      toggleValues={["Sector", "Industry"]}
    />
    <IndSecFilter />
  </div>
  


  return (
<div style={{display:"flex",width:"100%",height:"100%"
,gap:"0.5rem",padding:"0.5rem",boxSizing:"border-box",overflow:"auto",flex:1}}>
  {/* <ResizableRight orientV={true}> */}
  
  <LeftSidebarFloating headerDetails={headerDetails}>
  <LeftMenuCheckboxList
  checkList={indSecChecklist}
  handleCheckbox={handleCheckboxList}
  selectedList={[...stateIndustry.sectorSel,...stateIndustry.industrySel]}
  />
  </LeftSidebarFloating>



<div style={{
  height: "100%",
  boxSizing: "border-box",
  minWidth: 0,
  flex: 1,
  width: "100%",display:"flex",flexDirection:"column"
}}>
  <Paper style={{
    flex: 1,
    display: "flex",
    flexDirection: "column",overflow:"auto"
  }}>
    <div style={{position:"sticky",top:"0",background:"white",zIndex:10}}>
    <TabLine tabList={menuNames} tabIndex={tabIndex} handleTabChange={(i) => setTabIndex(i)} />
    </div>
    <div style={{
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "auto" // Allow inner content to overflow within Paper
    }}>
      {(!stateIndustry.constiInfo.length>0 && !loadingStocks) && <IndSecNoInfo />}
      {stateIndustry.constiInfo.length>0 && infoList[menuNames[tabIndex]]}
    </div>

    {(stateIndustry.constiInfo.length>0 && analysisOptionSel !=="Summary") && 
    <PaginationButtons 
       itemsDisplaying={itemsPerPage}
       totalPages={totalPages} itemsPerPage={itemsPerPage} 
       currentPage={currentPage} handlePage={handlePage}/>}
  </Paper>
</div>

</div>
  ) 
}


const infoList = {
    // "Overview":(<IndSecOverview/>),
    "Performance":( <IndSecContent info={"Performance"}/>),
    "Financials":(<IndSecContent info={"Financials"}/>),
    "Technicals":(<IndSecContent info={"Technicals"}/>),
    "Value":(<IndSecContent info="Value"/>),
    "Market Stats":(<IndSecContent info="Market Stats"/>),
    "Growth":(<IndSecContent info="Growth"/>),
    "Size & Share":(<IndSecContent info="Size & Share"/>),
    "Heatmap": (<IndSecConstituentsHeatmap/>),
    "Scatter":( <IndSecConstituentsScatter/>),
    "Brand Visuals":(<IndSecConstituentsVisuals/>)
  }

  
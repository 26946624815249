import React, { memo, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";

require('highcharts/modules/accessibility')(Highcharts);

const PriceValueCustom = ({ data, columns = [dfin.period, 'FAIR VALUE'] }) => {
  const { stateTicker} = useAppInfo();
  const {close,tickerStock} = stateTicker
  const companySelected = tickerStock;

  const getYearFromDate = (dateString) => new Date(dateString).getFullYear();

  const pSelDataWithFV = useMemo(() => {
    const coAnnualInfo = data.filter((obj) => obj[dfin.ticker] === companySelected);
    const fvalue = getSpecificArrayObjKeys(coAnnualInfo, columns);

    return close.map((data) => {
      const year = getYearFromDate(data.Date);
      const matchingFV = fvalue.find((obj) => getYearFromDate(obj[dfin.period]) === year);
      return {
        ...data,
        FV: matchingFV ? matchingFV['FAIR VALUE'] : null,
      };
    });
  }, [close, data, companySelected]);

  const seriesData = useMemo(() => [
    { name: 'Fair Value', data: pSelDataWithFV.map((data) => [new Date(data.Date).getTime(), data.FV]), color: 'green' },
    { name: 'Close', data: pSelDataWithFV.map((data) => [new Date(data.Date).getTime(), data[companySelected]]), color: 'blue' },
  ], [pSelDataWithFV, companySelected]);

  const options = useMemo(() => ({
    chart: { zoomType: 'x',animation: false, },
    title: { text: `FV ${companySelected}` },
    xAxis: { type: 'datetime' },
    yAxis: { title: { text: 'Values' } },
    series: seriesData,
    plotOptions: { series: { marker: { enabled: false } } },
    credits: { enabled: false },
    accessibility: { enabled: false },
  }), [companySelected, seriesData]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {options && <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: '100%', width: '100%' } }} />}
    </div>
  );
};

export default PriceValueCustom;
import React, { useState } from 'react'
import Modal from 'components/Modal/Modal'
import { Button, Tooltip } from '@mui/material'
import { useAppInfo } from 'AppState'
import { dfin, marketScaleOptions } from 'content/constants'
import MultiSelect from 'components/Inputs/MultiSelect'
import TuneIcon from '@mui/icons-material/Tune';
import PageSettingsModal from 'components/Template/PageSettingsModal'

export default function IndSecFilter({btnType="small"}) {
    const {stateIndustry,dispatchIndustry} = useAppInfo()
    const [filters,setFilters] = useState(false)
    
function handleMarketCap(v){
    let mScaleSel = v
    if (v.includes("All")){
      mScaleSel = marketScaleOptions
    }
    dispatchIndustry({"mScaleSel":mScaleSel})
  }

  function handleSectorChange(v){
    let sectorSel = v
    if(v.includes("All")){
      sectorSel=["All"]
    }
    dispatchIndustry({"sectorSel":sectorSel,"indSecMode":false})
  }

 function handleIndustryChange(v){
  let industrySel=v
  const sectorSel = stateIndustry.secIndList.filter(obj=>industrySel.includes(obj[dfin.gicIndustry])).map((item) => item[dfin.gicSector])
  dispatchIndustry({"sectorSel":sectorSel,"industrySel":industrySel,"indSecMode":true})
}

  return (
    <>
    <Tooltip title="filters">
      {btnType==="small" && <TuneIcon onClick={()=>setFilters(!filters)}/>}
      {btnType !== "small" && <Button variant='contained' startIcon={<TuneIcon/>} 
      onClick={()=>setFilters(!filters)}>Filters</Button>}

      </Tooltip>
    {filters &&
    <PageSettingsModal title='Industry/Sector Filter' onClose={()=>setFilters(false)}>
      <MultiSelect label={"Market Cap"} 
      options={["All",...marketScaleOptions]} 
      values={stateIndustry.mScaleSel} onChange={handleMarketCap}/>
      <MultiSelect label="Sector" 
    options={["All",...stateIndustry.uniqueSectors]} values={stateIndustry.sectorSel} onChange={handleSectorChange} />
    
    <MultiSelect label="Industry" 
    options={stateIndustry.uniqueIndustries} values={stateIndustry.industrySel} 
    onChange={handleIndustryChange}/>

    </PageSettingsModal>
    }
    </>
  )
}

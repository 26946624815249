import React from 'react';
import YouTube from 'react-youtube';

const YoutubeEmbed = ({ videoId, height="100%", width="100%"}) => {
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;
  const iframeStyles = {
    height: height,
    width: width,
  };

  return (
    <div className="video-responsive">
      <iframe
        src={videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube Video"
        style={iframeStyles}
      ></iframe>
    </div>
  );
};

export default YoutubeEmbed;


// const YoutubeEmbed = ({ videoId, width, height }) => {
//   const opts = {
//     height: height || 360,
//     width: width || 640,
//     playerVars: {
//       autoplay: 0,
//       modestbranding: 1,
//       controls: 1,
//       showinfo: 0,
//       rel: 0,
//     },
//   };

//   const onReady = (event) => {
//     // Access to player in all event handlers via event.target
//     event.target.pauseVideo();
//   };

//   return (
//   <>
//   <YouTube videoId={videoId} opts={opts} onReady={onReady} />
//   </>
//   )
// };

// export default YoutubeEmbed;

// const YoutubeEmbed = ({ videoId, width, height }) => {
//   const playerRef = useRef(null);

//   useEffect(() => {
//     const scriptId = 'youtube-iframe-api-script';

//     const initializePlayers = () => {
//       if (window.YT && typeof window.YT.Player === 'function') {
//         if (!playerRef.current) {
//           playerRef.current = new window.YT.Player(`youtube-player-${videoId}`, {
//             videoId: videoId,
//             width: width || 640,
//             height: height || 360,
//             playerVars: {
//               autoplay: 0,
//               modestbranding: 1,
//               controls: 1,
//               showinfo: 0,
//               rel: 0,
//             },
//           });
//         }
//       } else {
//         // Retry initialization after a short delay if YT.Player is not yet available
//         setTimeout(initializePlayers, 100);
//       }
//     };

//     // Check if the script is already loaded
//     if (!document.getElementById(scriptId)) {
//       const script = document.createElement('script');
//       script.id = scriptId;
//       script.src = 'https://www.youtube.com/iframe_api';

//       document.head.appendChild(script);

//       script.onload = initializePlayers;

//       return () => {
//         const scriptElement = document.getElementById(scriptId);
//         if (scriptElement) {
//           scriptElement.remove(); // Remove the script when the component unmounts
//         }
//       };
//     } else {
//       initializePlayers(); // Initialize players if the script is already loaded
//     }
//   }, [videoId, width, height]);

//   return <div id={`youtube-player-${videoId}`} />;
// };

// export default YoutubeEmbed;
import React, { useState } from 'react'
import SelectBox from 'components/Inputs/SelectBox'
import { IconButton, InputBase, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MetricStockModal from 'components/Template/MetricStockModal';
import BarChartPeers from 'components/Charts/BarChartPeers';
import { useAppInfo } from 'AppState';
import { handleObjectDetails } from '../utilsReport';

export default function BarChartPeersUI({data,metricSel,dispatch,item,type,barColor}) {
    const {stateStock,stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const {pages,pageCurrent} =  stateReportBuilder
    const numCols = stateStock.metrics.numCols
    
    const [metricSelected,setMetricSelected] = useState(metricSel)
    const [modal,setModal] = useState(false)
    const [modalMetricSelected,setModalMetricSelected] = useState([])
    const [color,setColor] = useState(barColor)
    function handleModal(modalName){
        setModal(!modal)
        setModalMetricSelected(metricSelected)
    }
    
    function handleMetric(e,v){
        setMetricSelected(v)
        const updateContent = {MetricSelected:v}
        handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
      }
    function handleModalMetricValue(v){
        setMetricSelected(v)
        setModalMetricSelected(v)
        const updateContent = {MetricSelected:v}
        handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
      }
    
    const [mouseEnter,setMouseEnter] = useState(false)
    
      function handleColor(v){
        setColor(v)
        const updateContent = {Color:v}
        handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
      }

  return (
    <div onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)}
    style={{display:"flex",flexDirection:"column",boxSizing:"border-box",
    height:"100%",width:"100%",boxSizing:"border-box",background:"white",position:"relative"}}>
    {mouseEnter &&
    <div style={{width:"100%",display:"flex",position:"absolute",top:0,zIndex:"20",background:"white",alignItems:"center"}}>
     <Tooltip title="Metric Category"><IconButton  onClick={handleModal}><FormatListBulletedIcon/></IconButton></Tooltip>
    <SelectBox variant='standard' options={numCols} value={metricSelected} 
    onChange={handleMetric} label={"Metric"} size='large' width='100%'/>
    <Tooltip title="Color"><input type='color' value={color?color:"blue"} onChange={(e)=>handleColor(e.target.value)}></input></Tooltip>
    </div>}
    
    {modal && 
        <MetricStockModal 
        handleModalClose={handleModal} handleMetricSelected={handleModalMetricValue} 
        modalName={"Metrics"} metricSelected={[modalMetricSelected]}/>
      }
    <BarChartPeers color={color} type={type} exporting={false} title={true} data={data} metricSelected={[metricSelected]} dataLabel={true}/>
    </div>
  )
}

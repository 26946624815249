import React from 'react'
import { useAppInfo } from 'AppState'
import { Alert, Card, Skeleton } from '@mui/material'

export default function NoTickerInfo({infoName=""}) {
  const {stateTicker} = useAppInfo()

  return (
    <div style={{width:"100%",display:"flex"}}>
    <Card className='fade-in' style={{padding:"2rem",boxSizing:"border-box",width:"100%",border:"1px solid #ddd",borderRadius:"5px",margin:"1rem"}}>
    <Alert severity='warning' sx={{fontSize:"1rem",fontWeight:"bold"}} >
    {infoName} Information not available for {stateTicker.tickerSelected}!
    </Alert>
    </Card>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import SidebarContentTemplate from './SidebarContentTemplate'
import { dfin } from 'content/constants'
import Shape from '../Objects/Shapes'

export default function Elements() {
    const {stateTicker,stateReportBuilder,dispatchReportBuilder,stateStock} = useAppInfo()
   
    const content = (stateReportBuilder.elements);


    useEffect(() => {
      const content = [{Category:"Shapes", Type: 'Shape',Shape:<Shape shapeType='rectangle' height={"50px"} width={"50px"}/>, 
            style:{height:"50px",width:"50px",},ShapeType:"rectangle"},
            {Category:"Shapes", Type: 'Shape',Shape:<Shape shapeType='triangle' height={"50px"} width={"50px"}/>, 
            style:{height:"50px",width:"50px",},ShapeType:"triangle"},
            {Category:"Shapes", Type: 'Shape',Shape:<Shape shapeType='square' height={"50px"} width={"50px"}/>, 
            style:{height:"50px",width:"50px",},ShapeType:"square"},
            {Category:"Shapes", Type: 'Shape',Shape:<Shape shapeType='circle' height={"50px"} width={"50px"}/>, 
            style:{height:"50px",width:"50px",},ShapeType:"circle"},
            {Category:"Shapes", Type: 'Shape',Shape:<Shape shapeType='candlestick' height={"50px"} width={"50px"}/>, 
            style:{height:"50px",width:"50px",},ShapeType:"candlestick"}]  


        dispatchReportBuilder({"elements":content});
    }, [stateStock.peerInfo,stateTicker.images]);

  
  return (
    <>
        
     <SidebarContentTemplate content={content}/>

    </>
  )
}

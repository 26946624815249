import React, { useEffect, useState } from 'react'
import useIndexStock from 'data/company/useIndexStock'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import { dfin } from 'content/constants'
import { useAppInfo } from 'AppState'
import ScatterUI from 'components/Charts/ScatterUI'
import MarketBreadthIndex from './MarketBreadthIndex'
import TreeMap from 'components/Charts/TreeMap'
import { Button, Paper } from '@mui/material'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart'


export default function IndexStock({info}) {
  const {stateScreener,stateMarket} = useAppInfo()
  const {tickerSelected,tickerDict} = stateMarket
  const {indexStock} = useIndexStock()
  const [indexTable,setIndexTable] = useState()
  const [indexAllInfo,setIndexAllInfo] = useState()
  const [indexSel,setIndexSel] = useState(0)

  const indexList = {"^DJI":"Dow Jones Industrial Average",
  "^GSPC":"S&P 500 Index","^IXIC":"Nasdaq 100"}
  
  useEffect(()=>{
    setIndexSel(tickerDict[tickerSelected[0]])
  },[tickerDict,tickerSelected])

  useEffect(()=>{
    if (indexStock && indexSel){
        const indexConsti = indexStock.filter(obj=>obj["INDEX"]===indexSel && obj["COUNTRY"]==="USA").map(obj=>obj["TICKER"])
        const perftable =stateScreener.stocks && stateScreener.stocks.filter(obj=>indexConsti.includes(obj[dfin.ticker]))
        setIndexTable(getSpecificArrayObjKeys(perftable,[dfin.ticker,dfin.coName,dfin.gicIndustry,dfin.gicSector,dfin.mScale,dfin.coLogo,dfin.performance1D,dfin.performance1M,dfin.performance1Y]))
        setIndexAllInfo(perftable)
    }
  },[indexSel,indexStock])

  const tabContent = {"Market Breadth": indexAllInfo ? <MarketBreadthIndex data={indexAllInfo}/> : <LoadingSkeletonTable/>,
  "Constituents":indexTable ? <TableGen tableLayout='fixed' data={indexTable} logo={true}/> : <LoadingSkeletonTable/>,
  "Scatter": indexAllInfo ? <ScatterUI data={indexAllInfo} defaultMet={[dfin.marketCap,dfin.pe]}/> : <LoadingSkeletonChart/>,
  "Heatmap":  indexAllInfo && <TreeMap data={indexAllInfo}/>,
  }


  return (
    <div className="containerLeftMenuAndContent">
    <LeftSidebarFloating header={"Ticker List"}>
    {Object.entries(tickerDict).map(([ticker, name], index) => (
      <div style={{display:"flex",flexDirection:"column"}} key={index}>
        <Button onClick={()=>setIndexSel(name)} 
        style={{background:indexSel === name && "#F8F8F8",borderLeft:indexSel === name && 
        "4px solid var(--selectBlue)",fontSize:"1rem"
        ,justifyContent:"flex-start",textAlign:"left",textTransform:"none",color:indexList[ticker]?"black":"gray"}}
        disabled={!indexList[ticker]}>{name}</Button>
      </div>
    ))}
      </LeftSidebarFloating>

          <Paper className='containerMainContent'>
   
    <div className='containerColumnFlex1'>
    {tabContent[info]}
    </div>
    </Paper>
    
    </div>
  )
}
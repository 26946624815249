import React,{useState,useEffect} from 'react'
import axios from "axios";
import { eodHistorialApiKey } from '../keys';

export default function useEarningTrends({tickerSel}) {
    const [earningTrends, setEarningTrend] = useState([]);
    useEffect(() => {
        if (tickerSel){
            getMetricInfo();
        }
      }, [tickerSel]);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`https://eodhd.com/api/calendar/trends?symbols=${tickerSel}&api_token=${eodHistorialApiKey}&fmt=json`);
          
          setEarningTrend(responseMetric.data["trends"][0]);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {earningTrends}
    
}

import React, { useEffect, useRef, useState } from 'react';

export default function Video({ src,sx={},handleProgess}) {
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef(null); // Ref for accessing the video element
  

  return (
    <video
      ref={videoRef}
      width={"100%"}
      autoPlay
      muted
      loop
      controls={showControls}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      style={{
        boxShadow: "var(--boxShadowGeneral)",
        borderRadius: "5px",
        objectFit: "cover",
        border: "none",
        outline: "none",...sx
      }}
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}


function Progress(){
  const videoRef = useRef(null); // Ref for accessing the video element
  
  const [duration, setDuration] = useState(0); // State to store the video duration
  const [progress, setProgress] = useState(0); // State to store the percentage completed

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration); // Set the video duration when metadata is loaded
      }
    };

    const handleTimeUpdate = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        const percentage = (currentTime / videoRef.current.duration) * 100; // Calculate percentage
        setProgress(percentage); // Set progress state
      }
    };

    // Add event listeners
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Clean up event listeners when the component is unmounted
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  return (      
  <div style={{
    height: '10px',
    width: '100%',
    backgroundColor: '#e0e0df',
    borderRadius: '5px',
    margin: '20px 0',
  }}>
    <div style={{
      height: '100%',
      width: `${progress}%`,
      backgroundColor: '#76c7c0',
      borderRadius: '5px',
      transition: 'width 0.2s ease-in-out',
    }}></div>
  </div>)
}
import React, { useState } from 'react';
import { useAppInfo } from 'AppState';
import BarChartPeers from 'components/Charts/BarChartPeers';
import TabRow from 'components/Tabs/TabRow';
import { dfin } from 'content/constants';
import PeerDcf from './PeerDcf';


export default function PeerValue({type="all",valuationType="Price Multiple"}) {
  const {stateStock} = useAppInfo()
  const peerInfo = stateStock.peerInfo
  const tabList = ["Price Multiple","EV Multiple","DCF"]
  const tabContent=[];
  
  tabContent[0]=(<>
  {[dfin.pe,dfin.pb,dfin.ps].map(metric=>(
    <div key={metric}>
  <BarChartPeers data={peerInfo} metricSelected={[metric]} dataLabel={true} chartLegend={true}/> 
  </div>
  ))}
 
  </>)
  
  tabContent[1]=(<>
      {[dfin.evEbitda,dfin.evSales].map(metric=>(
      <div key={metric}>
      <BarChartPeers data={peerInfo} metricSelected={[metric]} dataLabel={true} chartLegend={true}/> 
      </div>
      ))}
     
      </>)
   
  tabContent[2] = <PeerDcf/>
  
  
  return (
    <>
    {type==="all" &&
    <TabRow tabList={tabList} tabContent={tabContent}/>}
    {type !=="all" && 
    tabContent[tabList.indexOf(valuationType)]
    }
  
    </>
  )
}



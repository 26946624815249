import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindReport = () => {
  const { dispatchReportBuilder, stateApp } = useAppInfo()
  const userId = stateApp.userId;

  const findReport = async () => {
    try {
      if (userId) {
        const response = await axios.post(`${BASE_URL}/findReport/`, {
          userId
        });
        const reportData = response.data;
        const reportNames = Object.keys(reportData);

        dispatchReportBuilder({
          pages: reportData?.[reportNames[0]] || [],
          reportLoaded: true,
          reportData: reportData,
          reportNameSel: reportNames[0],
          reportNames: reportNames
        });
      }
    } catch (err) {
      dispatchReportBuilder({ reportLoaded: true });
    }
  };

  useEffect(() => {
    if (userId) {
      findReport();
    }
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindReport;


import React from 'react';

import GuideTemplate from 'resource/guidance/Template/GuideTemplate';
import MarketForex from 'analytics/market/MarketForex';

export default function ForexAnalysis() {
  return (
    <GuideTemplate description={description} steps={steps} name={"Forex Analysis Guide"}/>
  )
}

const description = `
Forex trading involves understanding how different currencies are traded and what affects their prices. It starts with knowing the basics of the forex market, including who trades currencies and why prices change. Looking at popular currency pairs, like EUR/USD or USD/JPY, shows us the most actively traded currencies. It’s also helpful to see how different currencies move in relation to each other. By studying economic news and using charts to track price movements, traders can guess where prices might go next. Managing risk is crucial too, as it helps protect money from big losses, especially when using leverage, which can increase both profits and losses. Finally, staying aware of overall trader sentiment can provide extra clues on market direction. Understanding all these aspects helps traders make smarter decisions and hopefully, succeed in the forex market.
`

const steps = [
    {
      label: "Forex Market Overview",
      keyPoints: `Understanding the 
      forex market's structure, participants, 
      and key drivers is essential. 
      This provides insights into how currencies are traded, the factors influencing exchange rates, 
      and the dynamics of the forex market.`,
      content: <MarketForex info={"Trend Chart"} currencyBase={"USD"} quoteType={"Direct"}/>,
    },
    {
      label: "Major Currency Pairs",
      keyPoints:
        "Examining the major currency pairs traded in the forex market helps investors understand the most liquid and widely traded currencies. This includes pairs such as EUR/USD, USD/JPY, GBP/USD, and USD/CHF.",
      content: <MarketForex info={"Trend Table"} currencyBase={"USD"} quoteType={"Direct"}/>,
    },
    {
      label: "Currency Correlations",
      keyPoints: `Analyzing currency correlations helps investors identify relationships between different currency pairs. Understanding these correlations can aid in portfolio diversification and risk management.`,
      content: <MarketForex info={"Correlation"} currencyBase={"USD"} quoteType={"Direct"}/>,
    },
    {
      label: "Fundamental Analysis",
      keyPoints: `Conducting fundamental analysis involves assessing economic indicators, 
      central bank policies, geopolitical events, and 
      other macroeconomic factors that influence currency valuations. 
      This helps identify trends and potential trading opportunities in the forex market.`,
      warning: "Inaccurate interpretation of economic data or geopolitical events can lead to unexpected market reactions and losses.",
      success: "Accurate analysis of fundamental factors leads to profitable trading decisions.",
      content: [],
    },
    {
      label: "Technical Analysis",
      keyPoints:
        "Utilizing technical analysis involves analyzing price charts, trends, support and resistance levels, and various technical indicators to forecast future price movements in currency pairs. This helps identify entry and exit points for trades.",
      warning: "Reliance solely on technical indicators without considering fundamental factors can result in missed opportunities or false signals.",
      success: "Effective use of technical analysis enhances trading decisions and improves profitability.",
      content: [],
    },
    {
      label: "Risk Management",
      keyPoints: `Implementing risk management strategies is crucial in forex trading to protect capital and manage trading risks. This includes setting stop-loss orders, position sizing, and diversification strategies.`,
      warning: "Failure to implement proper risk management can result in significant losses, especially when using leverage.",
      success: "Effective risk management preserves capital and mitigates losses during adverse market conditions.",
      content: [],
    },
    {
      label: "Leverage and Margin",
      keyPoints: `Understanding leverage and margin allows traders to amplify their trading positions in the forex market. However, it also increases the potential for both gains and losses. Proper risk management is essential when using leverage.`,
      warning: "Excessive use of leverage can magnify losses and lead to margin calls, resulting in account liquidation.",
      success: "Prudent use of leverage enhances trading potential while managing risks effectively.",
      content: [],
    },
    {
      label: "Market Sentiment",
      keyPoints: `Analyzing market sentiment involves assessing the overall mood and sentiment of traders in the forex market. This includes sentiment indicators, positioning data, and market news.`,
      warning: "Overreliance on market sentiment without corroborating evidence from fundamental or technical analysis can lead to incorrect trading decisions.",
      success: "Accurate interpretation of market sentiment provides valuable insights and enhances trading strategies.",
      content: [],
    },
    {
      label: "Trading Strategies",
      keyPoints:
        "Developing and implementing effective trading strategies is essential for success in forex trading. This includes day trading, swing trading, trend following, and range trading strategies, among others.",
      warning: "Inconsistent or poorly executed trading strategies can lead to losses and erode trading capital over time.",
      success: "Consistent implementation of well-defined trading strategies leads to profitable trading outcomes.",
      content: [],
    },
    {
      label: "Call to Action",
      keyPoints: `After conducting thorough analysis and formulating a trading strategy, it's time to execute trades in the forex market. This involves placing orders based on your analysis and risk management principles.`,
      link: { link: "/Forex/Trade", name: "Trade" },
    },
  ];
  
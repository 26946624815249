import React, { useEffect } from 'react'
import { auth } from 'firebaseConfig/firebaseConfig';

export default function useAuthCheck({ dispatchApp, stateApp }) {
  useEffect(() => {
    const authCheck = auth.onAuthStateChanged(user => {
      if (user) {
        if (user?.emailVerified) {
          dispatchApp({
            authChecked:true,
            username: user?.displayName,
            displayName: user?.displayName,
            userId: user?.uid,
            googlePhoto: user?.photoURL,
            isLoggedIn: true,
            loginModal: false,
            userDetails: user
          });
        } else {
          alert("Verify your email address. Please check your inbox.");
        }
      } else {
        dispatchApp({
          authChecked:true,
          username: null,
          displayName: null,
          userId: null,
          googlePhoto: null,
          isLoggedIn: false,
          userDetails: null
        });
      }
    });

    return () => {
      authCheck(); // Unsubscribe from the listener when the component unmounts
    };
  }, []);
}


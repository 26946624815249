import React from 'react'
import { useAppInfo } from 'AppState';
import { getPerformanceColor } from '../Utils/utils';
import { formatNumber } from "utils/utilGeneral";
import MyFinancialsHeaderTemplate from '../Template/MyFinancialsHeaderTemplate';
import { menuIcons } from 'nav/menuIcons';

export default function CashflowHeader() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {summaryStats} = stateMyFinancials
    const {totalNetCashflow} = summaryStats
   const data = [{name:"Net Cashflow",values:totalNetCashflow,color:"variable"}]
   const summary = <>
   {data.map((obj,index)=>
            <React.Fragment key={index}>
              <div style={{display:"flex",gap:"0.2rem"}}>
            <h4 style={{fontWeight:"bold"}}>{obj.name}</h4>
          <>
          <h4 style={{color:obj.color ==="variable" ? getPerformanceColor(obj.values?.m) : obj.color}}>M: {formatNumber(obj.values?.m)}</h4>
          <h4 style={{color:obj.color ==="variable" ? getPerformanceColor(obj.values?.q) : obj.color}}>Q: {formatNumber(obj.values?.q)}</h4>
          <h4 style={{color:obj.color ==="variable" ? getPerformanceColor(obj.values?.y) : obj.color}}>Y: {formatNumber(obj.values?.y)}</h4>
          </>
          </div>
          </React.Fragment>
          )
        }</>
  return (
    <MyFinancialsHeaderTemplate name={"Cashflow"} icon={menuIcons?.Cashflow}></MyFinancialsHeaderTemplate>
  )
}

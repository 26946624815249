import React from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';

export default function IndSecConstituentsVisuals() {
  const {stateIndustry,stateStock} = useAppInfo()
  const info = stateIndustry.constiInfo 
  const dataBubble =  getSpecificArrayObjKeys(info,[dfin.gicIndustry,dfin.ticker,dfin.marketCap,dfin.coLogo])
  const defaultMet = [dfin.marketCap,dfin.revenue,dfin.ebitda]
  const metricOptions = stateStock.metrics.numCols
  return (
      <>
      {dataBubble.length>0 &&
    <PeerOverviewMetricComp
    data={info}
    infoName={"Brand Viusals"}
      storeId={"IndustrySectorVisuals"}
      defaultMet={defaultMet}
      metricOptions={metricOptions}
      chartTypeDefault={"BrandVisuals"}
      chartTypeOptions={["BrandVisuals"]}
    />
      }
    </>
    
    
  );
}
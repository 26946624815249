import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import ScreenerTemplate from './ScreenerTemplate'
import { applyFilters } from './utilsScreener'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function CryptoScreener() {
  const {stateScreener,dispatchScreener} = useAppInfo()

  const [filteredData,setFilteredData] = useState([])
  useEffect(()=>{
   if (stateScreener.crypto){
    const cryptoScreen = stateScreener.crypto && getSpecificArrayObjKeys(stateScreener.crypto, cols).sort((a, b) => {
      return a["CMC RANK"] - b["CMC RANK"];
    });
  const filteredData =  applyFilters(stateScreener.filtersCrypto,cryptoScreen)
   setFilteredData(filteredData)
  }
  },[stateScreener.crypto,stateScreener.filtersCrypto])

  function handleFilterUpdate(v){
    setFilteredData(v)
  } 
  
  return (
    <>
    {stateScreener.crypto.length===0 && !stateScreener.loadingCrypto && <LoadingSkeletonTable length={50}/>}
    {stateScreener.crypto.length>0 &&
     <ScreenerTemplate loading={true} 
     filterName={"filtersCrypto"} 
     data={getSpecificArrayObjKeys(stateScreener.crypto, cols)} 
     handleFilterUpdate={handleFilterUpdate}>
    {filteredData.length>0 && 
    <TableGen addToWatchlist logo data={filteredData}/>}
    </ScreenerTemplate>}
   </>
  )
}

const colsScreener = [
  'PRICE','MARKET CAPITALIZATION','PERCENT CHANGE 1H', 'PERCENT CHANGE 24H', 'CMC RANK',
'NUM MARKET PAIRS', 'DATE ADDED', 'MAX SUPPLY', 'CIRCULATING SUPPLY', 'TOTAL SUPPLY',
'INFINITE SUPPLY',
'TVL RATIO', 'VOLUME 24H', 'VOLUME CHANGE 24H',
'PERCENT CHANGE 7D',
'PERCENT CHANGE 30D', 'PERCENT CHANGE 60D', 'PERCENT CHANGE 90D',
'MARKET CAP', 'MARKET CAP DOMINANCE', 'FULLY DILUTED MARKET CAP', 'TVL',
'DATE LAUNCHED',
]

const cols = ["TICKER","LOGO URL",'NAME', 'PRICE','MARKET CAPITALIZATION','PERCENT CHANGE 1H', 'PERCENT CHANGE 24H', 'CMC RANK',
'NUM MARKET PAIRS', 'DATE ADDED', 'MAX SUPPLY', 'CIRCULATING SUPPLY', 'TOTAL SUPPLY',
'INFINITE SUPPLY',
'TVL RATIO', 'VOLUME 24H', 'VOLUME CHANGE 24H',
'PERCENT CHANGE 7D',
'PERCENT CHANGE 30D', 'PERCENT CHANGE 60D', 'PERCENT CHANGE 90D',
'MARKET CAP', 'MARKET CAP DOMINANCE', 'FULLY DILUTED MARKET CAP', 'TVL',
'DATE LAUNCHED',]
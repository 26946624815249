import React, { useEffect, useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import { useAppInfo } from 'AppState'
import FieldFormGen from '../FormFields/FieldFormGen'
import PieChartCategory from 'components/Charts/PieChartCategory'
import { defaultFieldBlank, liabilityTypes } from 'appState/initialStateMyFinancials'
import CashflowChart from '../Cashflow/CashflowChart'
import AmortizationTable from './AmortizationTable'
import { calculateAmortizationSchedule } from '../Utils/utils'
import LiabilityPaybackSummary from './LiabilityPaybackSummary'
import TableGen from 'components/Table/TableGen'
import { formatNumber, getSpecificArrayObjKeys } from "utils/utilGeneral"
import LiabilityManageSteps from './LiabilityManageSteps'
import LiabilityOverview from './LiabilityOverview'


export default function LiabilityInfo({info,displayType="tabs"}) {
  const {stateMyFinancials} = useAppInfo()
  const {liabilities,cashflowDistribution,summaryStats,currency} = stateMyFinancials
  const {totalLiabilities} = summaryStats
  const {liabilityDist} = cashflowDistribution
  const [tabIndex,setTabIndex]  = useState(0)
  const tabList = ["Overview","Steps to manage Liabilities","Payment Overview","Allocation","Projections","Amortization Schedule"]

  useEffect(()=>{
    if (info && displayType !== "tabs"){
      setTabIndex(tabList.indexOf(info))
    }
  },[info])

  const [cashflowData,setCashflowData] = useState([])

  useEffect(()=>{
    if (liabilityDist){
    
     setCashflowData(liabilityDist)
    }
  },[liabilityDist])


  const tabContent = {
    "Overview": <LiabilityOverview/>,
    "Steps to manage Liabilities":<LiabilityManageSteps/>,
    "Payment Overview":<LiabilityPaybackSummary/>,
    "Allocation":
    <div style={{display:"flex",flexDirection:"column"}}>
    <div style={{width:"100%",height:"400px"}}>  
    <PieChartCategory weightType='weighted' weightCategory={"amount"} data={liabilities} category={"name"}/>
    </div>
    {liabilities.length > 0 && 
    <div>
    <TableGen data={getSpecificArrayObjKeys(liabilities,["type","name","currentAllocation"])}/></div>}
    </div>,
    "Projections":<CashflowChart data={cashflowData}/>,
    "Amortization Schedule": <AmortizationTable/>,
  }

  return (
    <>
    
    {tabContent[info]}

    </>
  )
}


import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral'



export default function TimeSeriesCategoryChart ({ data , metricSelected,chartType="column"}) {
    const [seriesData,setSeriesData] = useState([])
    const [chartCategories,setChartCategories] = useState([])
  
    useEffect(() => {
  
        // Remove duplicate keys and filter out "Date"
        const sortData = data.sort((a, b) => {
          const dateA = new Date(a.date || a.DATE || a.Date);
          const dateB = new Date(b.date || b.DATE || b.Date);
          return dateA - dateB;})
        
        const chartData = metricSelected.map((metric) => ({
            name: metric,
            data: sortData.map((obj) => ({
              // x: obj[period], // Use the date string directly
              y: obj[metric],
            })),
          }));
          const categories = sortData.map((obj) => {
            const date = obj.date || obj.Date || obj.DATE;
            if (date) {
              const d = new Date(date);
              const day = String(d.getDate()).padStart(2, '0');
              const month = String(d.getMonth() + 1).padStart(2, '0');
              const year = d.getFullYear();
              return `${day}/${month}/${year}`;
            }
            return "";
          });
          setSeriesData(chartData)
          setChartCategories(categories)
        },[data])
  
    const chartOptions  = {
          chart: {
            type: chartType,
          },
  
          title: {
            text: '',
          },
          xAxis: {
            categories: chartCategories,
            crosshair: true,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Value',
            },
            labels: {
                formatter: function () {
                  return numeral(this.value).format('0.0a').toUpperCase();
                },
              },
          },
        //   tooltip: {
        //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        //     pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //       '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        //     footerFormat: '</table>',
        //     shared: true,
        //     useHTML: true,
        //   },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false
              }
            }
          },
          series: seriesData,
          credits:{enabled:false},
          exporting:{enabled:false}
        }
  
    return <div style={{ height: "100%", width:"100%",position:"relative"}} >
    {/* {series.length===0&& <LinearProgress/>} */}
   {seriesData.length>0&&
    <HighchartsReact highcharts={Highcharts} options={chartOptions} 
    containerProps={{ style: { height: '100%' , width:"100%"} }}/>}</div>;
  };
  
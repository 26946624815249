import { useAppInfo } from "AppState";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import { Button, Tooltip } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import { handleAddPage, handleDeletePage, handleMovePageDown, handleMovePageUp, handlePageBackground, handlePageName } from "../utilsReport";
import ColorPicker from "components/Inputs/ColorPicker";
import { useState } from "react";
import Modal from "components/Modal/Modal";
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import BackupIcon from '@mui/icons-material/Backup';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

export default function ObjectToolBox({pageNumber,page}){
    const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const {pageCurrent, pages} = stateReportBuilder;    

    const [backgroundColorSetting,setBackgroundColorSetting] = useState(false)
    const [backgroundPagesAll,setBackgroundPagesAll] = useState("page")

    function handleEdit() {
      dispatchReportBuilder({ stopEdit: !stateReportBuilder.stopEdit });
    }

    return(
      <div
          style={{
            width: "auto",
            color: "gray",
            display: "flex",
            gap: "1rem",zIndex:"1",alignItems:"center",
          }}
            >
                <div style={{display:"flex",gap:"1rem",alignItems:"flex-end",padding:"0.25rem"}}>
                  {/* Page {pageNumber}:  */}
                  <TextInput 
                  size="small"
                  variant="outlined"
                  placeholder={"page name"} 
                  label={`Pg ${page?.PageIndex + 1}`} required={false} 
                  fontSize={"small"}
                  value={`${page.PageName}`} 
                  onChange={(v)=>handlePageName(v,page.PageIndex,stateReportBuilder,dispatchReportBuilder)}/> 
                    <Tooltip title={!stateReportBuilder.reportSaved?"saving report":"report saved"}>
                      {!stateReportBuilder.reportSaved && <BackupIcon className="recording"/>}
                      {stateReportBuilder.reportSaved && <CloudDoneIcon sx={{color:"green"}}/>}
                    </Tooltip>
               
                </div>
                <div style={{marginLeft: "auto", 
                  display: "flex", gap: "1rem",alignItems:"center",background:"#f8f8f8"
                  ,borderRadius:"5px",padding:"0.25rem"}}>
                  
                  {!stateReportBuilder.stopEdit && <Tooltip title="edit"> <EditIcon onClick={handleEdit}/> </Tooltip>}
                  {stateReportBuilder.stopEdit && <Tooltip title="edit"> <EditOffIcon onClick={handleEdit}/> </Tooltip>}
                  <Button 
                  onClick={()=>setBackgroundColorSetting(!backgroundColorSetting)}
                  style={{background:page?.PageBackground || "#ffffff",border:"1px solid #ddd",width:"15px",height:"15px",minWidth:0}}>
                  </Button>
                  {page.PageIndex !== 0 && (
                    <Tooltip title={"Move Page Up"}> <ExpandLessIcon
                      onClick={() => handleMovePageUp(stateReportBuilder,dispatchReportBuilder)}
                    /></Tooltip>
                  )}
                  {page.PageIndex !== pages.length - 1 && (
                    <Tooltip title={"Move Page Down"}><ExpandMoreIcon
                      onClick={() => handleMovePageDown(stateReportBuilder,dispatchReportBuilder)}
                    /></Tooltip>
                  )}
                  <Tooltip title={"Delete Page"}>
                  <DeleteIcon onClick={(e) => handleDeletePage(e,stateReportBuilder,dispatchReportBuilder)} /></Tooltip>
                
                  <Tooltip title={"Insert Page"}>
                <Add onClick={()=>handleAddPage(stateReportBuilder,dispatchReportBuilder)} /></Tooltip>
                
                
                </div>
              
              
                {backgroundColorSetting && <Modal 
                  width="auto" height="auto"
                  onClose={()=>setBackgroundColorSetting(false)}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                  <h3>Background Color</h3>
                  <ColorPicker sx={{height:"50px",width:"50px"}} defaultColor={page?.PageBackground || "#ffffff"} 
                  onChange={(v)=>handlePageBackground(v,backgroundPagesAll,page.PageIndex,stateReportBuilder,dispatchReportBuilder)}/>
                   <label>
                <input
                    type="checkbox"
                    checked={backgroundPagesAll==="page"?false:true}
                    onChange={(e)=>setBackgroundPagesAll(e.target.value?"All":"page")}
                />
                Apply to All Pages
                  </label></div>
                  </Modal>}        
              
      </div>
    )
  }
const learnApply = {
    "Header":"Learn & Apply",
    "SubHeader":"Great for New Investors and Students",
    "PriceMonthly":29,
    "PriceAnnual":290,
     "KeyBenefits":["End to end guidance","Easily create Portfolios/Watchlist","Curated Education programs"],
   }
 
   const trader = {
    "Header":"Trader",
    "SubHeader":"Best for heavy trading activities",
    "PriceMonthly":49,
    "PriceAnnual":490,
    "KeyBenefits":["Trade Picks","Technical Charts","Technical Insights & tools","Strategy Back Testing"]
   }
   const investor = {
     "Header":"Investor",
    "SubHeader":"Best for Long term investors and Wealth creation",
    "PriceMonthly":99,
    "PriceAnnual":990,
    "KeyBenefits":["Investment Insights & Picks","Portfolio Analytics","Financial Modelling","Detailed Reports of Companies and Industries",]
   }
 
   const combo = {
     "Header":"Combo",
     "SubHeader":"Best for all types of Trade & Investing styles",
     "PriceMonthly":140,
     "PriceAnnual":1400,
     "KeyBenefits":["Complete Package","Investment & Trade Insights","Porfolio & Watchlist Analytics/Tracking"]
    }
   const expert = {
     "Header":"Professional",
     "SubHeader":"Great for Financial Advisors/Portfolio Managers",
     "PriceMonthly":490,
    "PriceAnnual":4900,
     "KeyBenefits":["Market Analytics","Multiple Portfolio Creations & Analytics","Report Builder","Network Builder"]
   }
   const productPricingList = [learnApply,trader,investor,combo,expert]

   export {productPricingList}
import React, { useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import CompanyExchangeReports from './CompanyExchangeReports'
import { Typography } from '@mui/material'

export default function CompanyExchangeFilings() {
  const [tabIndex,setTabIndex] = useState(0)
  const tabContent = []
  tabContent[0] = <CompanyExchangeReports reportType='10-k'/>
  tabContent[1] = <CompanyExchangeReports reportType='10-q'/>
  tabContent[2] = <CompanyExchangeReports reportType='8-k'/>
  tabContent[3] = <CompanyExchangeReports reportType='4'/>
  tabContent[4] = <CompanyExchangeReports reportType='3'/>
  return (
    <div className='containerColumnFlexNoGap'>
    <Typography variant='pageContentTitle'>Exchange Filings</Typography>
    <TabLine tabList={["10-K","10-Q","8-K","Form 4","Form 3"]} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
    <>
    {tabContent[tabIndex]}
    </>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import ReportBuilder from '../../report/ReportBuilder'
import { menuReport } from 'nav/menu'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PageComingSoon from '../PageComingSoon'
import useFindDefaultReport from 'data/user/reports/useFindDefaultReports'
import { PageMainLayout } from 'layout/PageLayout'
import EconomyReport from 'reportTakeStock/EconomyReport'
import TakeStockReportHome from '../../reportTakeStock/TakeStockReportHome';


export default function Reports() {

  useFindDefaultReport()

  const [infoSel,setInfoSel] = useState("TSReport")
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const subPage = pathname[2]
  

  useEffect(()=>{
   if (subPage){
   const menuSel = menuReport.filter(obj=>obj.link===subPage)[0]?.name
   setInfoSel(menuSel)}
   else{
    navigate(`/Report/TSReport`)
    setInfoSel("TSReport")
   }
  },[pathname])
 
  return (
    <PageMainLayout pageName='Reports' menuOptions={menuReport} menuSel={infoSel}>
    {subPage==="ReportBuilder" && <ReportBuilder/>}
      {subPage==="TSReport" && <TakeStockReportHome/>}
    </PageMainLayout>
  )
}


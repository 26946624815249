import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useRelativeStrength(ticker,benchmark) {

const [relativeStrength, setData] = useState([]);
const [rsLoading, setMarketInfoLoading] = useState(false);


const [previousPeerSelected, setPreviousPeerSelected] = useState([]);

useEffect(() => {
    getInfo();
}, [ticker,benchmark]);


async function getInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/relativeStrength/`, {
        params: {
        ticker: JSON.stringify(ticker),
        benchmark: JSON.stringify(benchmark),
        },
    });
    
    const info = response.data
    setData(info)
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {relativeStrength, rsLoading };
}
import React, { useState } from 'react';

import TabLine from 'components/Tabs/TabLine';
import { dfin } from 'content/constants';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';

import DiscountRate from './DiscountRate';
import FairValue from './FairValue';
import FcfScenario from './FcfScenario';
import GrowthRate from './GrowthRate';
import ForecastTable from './ForecastTable';

export default function DcfValue() {


  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Fair Value","Cashflow","Growth Rate","Discount Rate","Sensitivity"]
  const tabContent =[]
  function handleValueTab(index){
    setTabIndex(index)
  }
  tabContent[0] =  <FairValue/>
  
  tabContent[1] = <CompanyFinancialsUI hideOnHover={true} report={false} metricSel={[dfin.fcf,dfin.cfo]}/>
  
  tabContent[2] = <GrowthRate/>
  
  tabContent[3] = (
    <DiscountRate/>
  )
  tabContent[4]=(<FcfScenario/>)

  return (
    <div style={{maxWidth:"100%",boxSizing:"border-box",background:"white"}}>

        <TabLine tabContent={tabContent} tabIndex={tabIndex} tabList={tabList} handleTabChange={handleValueTab}/>
        
        <div style={{padding:"0.5rem"}}>
        {tabContent[tabIndex]}
        </div>
    </div>
  )
}

import React from 'react';

const RiskMitigationOptions = () => {
  const data = [
    {
      assetClass: 'Stocks',
      stopLoss: 'Fixed Percentage, Trailing Stop Loss, Guaranteed Stop Loss',
      insuranceHedging: 'Put Options, Portfolio Insurance, Equity Swaps',
      diversification: 'Market Cap Diversification, Industry Diversification, Sector Diversification, Geographic Diversification'
    },
    {
      assetClass: 'Forex',
      stopLoss: 'Fixed Percentage, Trailing Stop Loss',
      insuranceHedging: 'Options, Futures Contracts',
      diversification: 'Currency Diversification, Geographic Diversification'
    },
    {
      assetClass: 'Commodities',
      stopLoss: 'Fixed Amount, Based on Support and Resistance',
      insuranceHedging: 'Futures Contracts, Commodity Swaps',
      diversification: 'Commodity Type Diversification, Geographic Diversification'
    },
    {
      assetClass: 'Crypto',
      stopLoss: 'Trailing Stop Loss, Guaranteed Stop Loss',
      insuranceHedging: 'Put Options, Futures',
      diversification: 'Spread investments across different cryptocurrencies and tokens'
    },
    {
      assetClass: 'Fixed Income',
      stopLoss: 'Fixed Amount',
      insuranceHedging: 'Interest Rate Swaps, Credit Default Swaps',
      diversification: 'Diversification across different durations and credit qualities'
    },
    {
      assetClass: 'Real Estate',
      stopLoss: 'Not typically applicable',
      insuranceHedging: 'Property Insurance, Geographical Diversification',
      diversification: 'Type of Real Estate (Commercial, Residential, Industrial)'
    },
    {
      assetClass: 'ETFs & Mutual Funds',
      stopLoss: 'Fixed Percentage, Trailing Stop Loss',
      insuranceHedging: 'Put Options, Portfolio Insurance',
      diversification: 'Fund Type Diversification, Sector Diversification, Geographic Diversification'
    }
  ];

  return (
    <table>
      <thead>
        <tr>
          <th>Asset Class</th>
          <th>Stop Loss</th>
          <th>Insurance/Hedging</th>
          <th>Diversification</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.assetClass}</td>
            <td>{item.stopLoss}</td>
            <td>{item.insuranceHedging}</td>
            <td>{item.diversification}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RiskMitigationOptions;
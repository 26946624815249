import React from 'react'
import FinancialsRealFlow from 'resource/whiteboard/FinancialsRealFlow'
import { useAppInfo } from 'AppState'
import { Button } from '@mui/material'
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding'


export default function EndSlide() {
  const {stateApp} = useAppInfo()
  const {username,onboarding} = stateApp
  const UpdateOnboarding = useUpdateOnboarding()
  function handleSubmit(){
    const updatedOnboarding = {...onboarding,financials:true}
    UpdateOnboarding(username,updatedOnboarding)
    
  }
  
  return (
    <div style={{height:"100%",width:"100%"
    ,padding:"1rem",display:"flex",gap:"2rem",borderRadius:"4px",boxSizing:"border-box"}}>

    <div style={{display:"flex",flexDirection:"column"
    ,gap:"1rem",minWidth:"400px",width:"30%",background:"white",background:"white",padding:"1rem",borderRadius:"1rem"}}>
        
        <h1>{username},</h1>
        <h1 className='gradient_text-dark' style={{flex:1,fontSize:"3rem"}}>
            You are all set to start managing your financials and meeting your goals.</h1>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <Button 
            onClick={()=>{handleSubmit()}} variant='contained'>Continue to Dashboard</Button></div>
    </div>
    
    <div style={{flex:1,height:"100%"}}>
        <FinancialsRealFlow/>
    </div>

    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { dfin, performanceDict } from 'content/constants'
import Histogram from 'components/Charts/Histogram'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import AdvanceDeclineBar from 'components/Charts/AdvanceDeclineBar'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'
import { Button } from '@mui/material'


export default function MarketBreadthIndex({data}) {
  const [performancePeriod,setPerformancePeriod] = useState(dfin.performance1D)
  const [performance,setPerformance] = useState([])


  useEffect(()=>{
    if (data.length>0){
        
        const performance = getSpecificArrayObjKeys(data,[performancePeriod])
        setPerformance(performance)
       
    }

  },[performancePeriod,data])
  

  function handlePerformance(period){
    setPerformancePeriod(performanceDict[period])
  }

 
  return (
    <>
     {performance.length===0 && <LoadingSkeletonTable/>}
    {performance.length>0 &&
    <>
    <div style={{display:"flex",float:"right"}}>
        {Object.keys(performanceDict).map(period=>
        <Button
        style={performanceDict[period]===performancePeriod?{color:"white",background:"black"}:{}}
        onClick={()=>handlePerformance(period)} key={period}>{period}</Button>)}
    </div>
    <div style={{width:"100%",height:"50%"}}>

    <Histogram color='Performance' data={performance} rangeNumberFormat={"%"}/>
      <AdvanceDeclineBar performance={performance} performancePeriod={performancePeriod}/>
    </div>
    </>
    }
    </>
  )
}

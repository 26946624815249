import React from 'react'
import PortfolioInfoList from './Portfolio/PortfolioInfoList'
import InfoBox from 'components/Layout/InfoBox'
import { useAppInfo } from 'AppState'
import PortfolioAccountSelection from './Portfolio/PortfolioAccountSelection'

export default function MyPortfolioReport() {
   const {stateApp} = useAppInfo()
   const {username} = stateApp
   const info =  [
        "Overview",
        "Asset Allocation",
        "Portfolio vs Benchmark",
        "Current Positions",
        "Transactions", 
    ]
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
        
        {info.map(obj=>
        <InfoBox key={obj}>
        <h3 style={{padding:"0.5rem"}}>
         {obj}
        </h3>
        <PortfolioInfoList menu={obj}/>
        </InfoBox>

        )}
       
    </div>
  )
}

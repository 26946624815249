import React, { useEffect, useState } from 'react'
import useSenateHolding from 'data/financialModelingPrep/CompanyOwnership/useSenateHolding'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys, transformDataForLineChartMulti } from 'utils/utilGeneral'
import LineChartMulti from 'components/Charts/LineChartMulti'
import usePriceChartEod from 'data/eod/usePriceChartEod'
import {PageMainContentTitle} from 'components/Font/Title'

export default function SenateTransactions() {
    const {stateTicker} = useAppInfo()
    const {senateHolding} = useSenateHolding(stateTicker.tickerStock)
    const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:stateTicker.tickerStockCode}) 
    const [chartData,setChartData] = useState([])
    const [tableData,setTableData] = useState([])

    useEffect(()=>{
        if (priceInfo.length>0 && senateHolding.length>0>0){
        const tableData = getSpecificArrayObjKeys(senateHolding,columns)
        
        const priceClose = transformDataForLineChartMulti({data:priceInfo,dateFieldName:"date",valueFieldName:"adjusted_close"})
        const chartData = convertAmounts(tableData)
        const senateHoldingData = ["amountPurchase","amountSale"].map((metric) => ({
            name: metric,
            data: transformDataForLineChartMulti({data:chartData, dateFieldName:"transactionDate", valueFieldName:metric}),
            chartType:"column",
            color:metric==="amountPurchase"?"green":"red"
        }));
        setTableData(tableData)
        setChartData([{name:"Close",data:priceClose,chartType:"area"},...senateHoldingData]);
      }},[priceInfo,senateHolding])

  return (
    <>
    <PageMainContentTitle>Senate Transactions</PageMainContentTitle>
     <div style={{width:"100%",height:"400px",minHeight:"400px"}}>
    {chartData.length>0 && <LineChartMulti title='' datasets={chartData}/>}
    </div>
    {tableData.length>0 && 
    <TableGen data={tableData} tableLayout='fixed'/>}
    </>
  )
}

const columns =  ["representative","disclosureYear",
"disclosureDate",
"transactionDate",
"owner",
"type",
"amount",
"district",
"link",
"capitalGainsOver200USD"]


function convertAmounts(data) {
    data.forEach(item => {
        // Remove dollar sign and commas
        let amount = item.amount.replace(/\$|,/g, ''); 
        let numericAmount = 0;

        // Check if the amount includes a range (indicated by a dash)
        if (amount.includes('-')) {
            let parts = amount.split('-').map(part => parseFloat(part.trim()));
            numericAmount = (parts[0] + parts[1]) / 2; // Calculate the average of the range
        } else {
            numericAmount = parseFloat(amount); // Convert the amount directly to a number
        }

        // Set numeric amount based on the type of transaction
        if (item.type.includes('purchase')) {
            item.amountPurchase = numericAmount;
        } else if (item.type.includes('sale')) {
            item.amountSale = numericAmount;
        }

        // Always update the original amount to numeric for consistency
        item.amount = numericAmount;
    });
    return data;
}
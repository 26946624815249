import React, {
  useEffect,
  useState,
} from 'react';

import ChartSynchronous from 'components/Charts/ChartSynchronous';
import LineChartMulti from 'components/Charts/LineChartMulti';
import SelectBox from 'components/Inputs/SelectBox';
import TabsClose from 'components/Tabs/TabsClose';
import MetricStockFinancialCategory from 'components/Template/MetricStockFinancialCategory';
import Modal from 'components/Modal/Modal';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function FinancialsVsPrice() {
    const {stateStock,stateTicker} = useAppInfo()

    const {annualInfo,quarterInfo,companySelected,financialsSettings} = stateStock
    const {periodMode} = financialsSettings
    const [dataSet,setDataSet] = useState([])
    const [addInfo,setAddInfo] = useState(false)
    const [metricSelected,setMetricSelected] = useState([dfin.revenue,dfin.fcf,dfin.assets,dfin.cfo,dfin.netProfitMargin])
    const [statementInfo,setStatementInfo] = useState([]) 
    const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:stateTicker.tickerStockCode}) 
    const [chartTypeFinancials,setChartTypeFinancials] = useState("column")
    const [chartTypePrice,setChartTypePrice] = useState("area")
    const [reportType,setReportType] = useState("Quarterly")
    const [priceCloseData,setPriceCloseData] = useState([])
    useEffect(()=>{
      if (priceInfo.length>0){
      const priceClose = transformDataForChart(priceInfo,"date","adjusted_close")
      setPriceCloseData(priceClose)}
    },[priceInfo])
    
    useEffect(()=>{
      const report = reportType==="Quarterly"?quarterInfo:annualInfo
      if (!periodMode){
        const statementInfoCo = report.filter(obj=>obj[dfin.ticker]===companySelected)
        setStatementInfo(statementInfoCo)
      }
      else{
        const statementInfoCo = report.filter(obj=>obj[dfin.ticker]===companySelected)
        setStatementInfo(statementInfoCo)

      }
    },[annualInfo,companySelected,quarterInfo,reportType])
  
    useEffect(() => {
        if (statementInfo.length > 0 && priceCloseData.length>0) {
            const financialData = metricSelected.map((metric) => ({
                name: metric,
                data: transformDataForChart(statementInfo, "DATE", metric),
                chartType:chartTypeFinancials
            }));
            setDataSet([{name:"Close",data:priceCloseData,chartType:chartTypePrice}, ...financialData]);
        }
    }, [statementInfo,priceCloseData,metricSelected,chartTypePrice,chartTypeFinancials]);
    

  function handleMetricChange(value) {  
    let metricSel;    
    if (Array.isArray(value)) {
      // If value is an array, set it as the new state directly
      metricSel = value
     
    } else {
      if (!metricSelected.includes(value)) {
       metricSel = [...metricSelected,value]
       
      } else {
        metricSel = metricSelected.filter((item) => item !== value)
      }
      
    }
    setMetricSelected(metricSel)
    
  }
  const [subTabIndex,setSubTabIndex] = useState(0)
  function handleDeleteTab(index) {
   
    setMetricSelected((prevItems) => {
      // Filter out the item at the specified index
      const updatedItems = prevItems.filter((_, i) => i !== index);
      return updatedItems;
    });
    // Update the tabList and tabContent state with the updated values
  }
  const [displayType,setDisplayType] = useState("Tabs")

  function handleDispalayType(v){
    setDisplayType(v)
  }

  const [chartSettings,setChartSettings] = useState(false)
  
  return (
    <div className='containerColumnFlexNoGap'>
         <MetricSettingsBar
        title='Price vs Financials'
        addMetric
        displaySettings
        handleAddMetric={()=>setAddInfo(true)}
        handleDisplaySettings={()=>setChartSettings(true)}
        />
        <div>  
        <TabsClose
          handleSubTabChange={(v)=>setSubTabIndex(v)}
          subTabIndex={displayType==="Tabs"?subTabIndex:-1}
          tabList={metricSelected}
          tabContent={[]}
          deleteTab={true}
          handleDeleteTab={handleDeleteTab}
        ></TabsClose></div>
        
        <div className='containerColumnFlex1'>
        {(dataSet.length>0 && displayType==="Single Chart") && 
        <LineChartMulti  datasets={dataSet}/>}
        
        {(dataSet.length>0 && displayType==="Tabs") && 
        <LineChartMulti datasets={[dataSet[0],dataSet[subTabIndex + 1]]}/>
        }

        {(displayType==="Multiple Charts" && dataSet.length>0) && 
          <ChartSynchronous
            datasets={dataSet}
          />}
        </div>

        {chartSettings && 
        <Modal height='auto' width='auto' onClose={()=>setChartSettings(false)}>
        <div style={{display:"flex",gap:"1.4rem",flexDirection:"column"}}>
        <h2 style={{textAlign:"center",paddingBottom:"1rem"}}>Chart & Data Settings</h2>
        <SelectBox label={"Report Type"} options={["Annual","Quarterly"]} value={reportType} onChange={(e,v)=>setReportType(v)}></SelectBox>
        <SelectBox label={"Display Type"} options={["Tabs","Single Chart","Multiple Charts"]} value={displayType} onChange={(e,v)=>handleDispalayType(v)}></SelectBox>
        <SelectBox label={"Chart Price"} options={["line","area"]} value={chartTypePrice} onChange={(e,v)=>setChartTypePrice(v)}></SelectBox>
        <SelectBox label={"Chart Financials"} options={["line","area","column"]} value={chartTypeFinancials} onChange={(e,v)=>setChartTypeFinancials(v)}></SelectBox>
        </div>
        </Modal>
        }
        {addInfo &&
        <MetricStockFinancialCategory 
        onClose={()=>setAddInfo(false)}
        title={"Price vs Financials"} metricSelected={metricSelected} 
        handleMetricChange={handleMetricChange}/>}
        
    
    </div>
  )
}

const transformDataForChart = (data, dateFieldName, valueFieldName) => {
    return data.map((item) => ({
      date: new Date(item[dateFieldName]).getTime(),
      value: item[valueFieldName],
      chartType: "line"
    }));
  };
import React, { useState } from 'react';

import Modal from 'components/Modal/Modal';
import { questionaireRiskTolerance } from 'content/contentRiskTolerance';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from '@mui/material';

import Questionnaire from './Questionnaire';
import RiskToleranceResult from './RiskToleranceResult';

export default function RiskToleranceQuestionnaire() {
  const [modal,setModal] = useState(false)
  const [score,setScore] = useState(0)
  const [submit,setSubmit] = useState(false)

  function handleSubmit(data){
    const {score,submitted} = data
    setScore(score)
    setSubmit(submitted)
  }
  
  return (
    <>
    <Button onClick={()=>setModal(true)} endIcon={<ArrowOutwardIcon/>} variant='contained' sx={{marginTop:"1rem"}}>Test your risk tolerance
    </Button>
    {modal &&
    <Modal onClose={()=>setModal(false)} height='auto' sx={{maxHeight:"90%"}}>
    <div style={{maxHeight:"80%",overflow:"auto"}}>
    {!submit && <Questionnaire data={questionaireRiskTolerance} 
    title={"Test your Risk Tolerance"}
    onSubmit={handleSubmit}/>}
    {submit && <RiskToleranceResult questionnaire={questionaireRiskTolerance.questionnaire} score={score}/>}
    </div>
    </Modal>}
    </>
  )
}

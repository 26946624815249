import React, { useEffect } from 'react'
import EtfMfScreener from '../../screener/EtfMfScreener'
import BondScreener from '../../screener/BondScreener'
import CryptoScreener from '../../screener/CryptoScreener'
import ForexScreener from '../../screener/ForexScreener'
import StockScreener from '../../screener/StockScreener'
import { useAppInfo } from 'AppState'
import { useLocation } from 'react-router-dom'
import { menuScreener } from 'nav/menu'
import useFindScreener from 'data/user/screener/useFindScreener'
import { PageMainLayout } from 'layout/PageLayout'

export default function Screener() {
  const {stateScreener,dispatchScreener} = useAppInfo()
  useFindScreener()
  const location = useLocation()
  const pathName = location.pathname
  const subPage = pathName.split("/")[2]

  const content = {
    "Stocks": <StockScreener />,
    "Etf": <EtfMfScreener />,
    "Bonds": <BondScreener />,
    "Crypto": <CryptoScreener />,
    "Forex": <ForexScreener />
  }
  
  useEffect(()=>{
    if (subPage){
      dispatchScreener({"screenerType":subPage})
    }
  },[subPage])  


  
  return (
    <PageMainLayout 
    pageName='Screener'
    menuOptions={menuScreener} 
    menuSel={stateScreener.screenerType}
    >
    {content[stateScreener.screenerType]}
    </PageMainLayout>
  )
}

import { useAppInfo } from 'AppState';
import useAddWatchlist from 'data/user/watchlist/useAddWatchlist';

export default function useHandleWatchlist() {
    const {stateWatchlist,dispatchWatchlist,dispatchSidebar} = useAppInfo();
    const { addWatchlist, error } = useAddWatchlist(stateWatchlist.watchlistSelected);
    const watchlistData = stateWatchlist.watchlistData;
    const watchlistNames = Object.keys(watchlistData)
    const watchlistSelected = stateWatchlist.watchlistSelected;
    const selectedItems = watchlistData[watchlistSelected] || []
    const handleAddToWatchlist = (ticker) => {
    let newTickers;
    if (watchlistNames.length > 0) {

      if (!Array.isArray(ticker)){
      if (selectedItems.includes(ticker)) {
        newTickers = selectedItems.filter((item) => item !== ticker);
      } else {
        newTickers = [...selectedItems, ticker];
      }
    }
      else if (Array.isArray(ticker)){   
        newTickers = Array.from(new Set([...selectedItems, ...ticker]));
      }

    dispatchSidebar({"sidebarOpen":true,"showSidebarRight":true,"sidebarRightContent":"watchlist"});
    watchlistData[watchlistSelected] = newTickers;
    dispatchWatchlist({
      watchlistSelected:watchlistSelected,
      watchlistMenu: false,
      watchlistData: watchlistData,
  });
    addWatchlist(newTickers);
  } else {
    dispatchWatchlist({
      createWatchlist:true
    })  
  }
};
    
    return {handleAddToWatchlist}
}


function handleObjectDetails({
    newStyle,
    element,
    contentUpdate,
    pages,
    pageIndex,
    item,
    dispatchReportBuilder,
  }){
    let heightPercent;
    let widthPercent;
    let top;
    let left;
    if (element){
    const boxElm = element.getBoundingClientRect()
    const boxParent = element.parentElement.getBoundingClientRect()
  
    heightPercent  = (boxElm.height/boxParent.height)*100
    widthPercent = (boxElm.width/boxParent.width)*100
    // top = element.offsetTop
    // left = element.offsetLeft
    top = (element.offsetTop / boxParent.height) * 100;
    left = (element.offsetLeft / boxParent.width) * 100;
    }

   
    const heightWidth = heightPercent ? {height:`${heightPercent}%`,width:`${widthPercent}%`} :{}
    const updatedPages = pages.map((obj) => {
     if (obj.PageIndex === pageIndex) {
       const updatedPageContent = obj.PageContent.map((content) => {
         if (content.id === item.id) {
           return {
             ...item,
             ...contentUpdate,
             style:{...item.style,...newStyle,...heightWidth},
             position:element?{top:top,left:left}:{...item.position},
             
            };
         }
         return content;
       });
 
       return {
         ...obj,
         PageContent: updatedPageContent,
       };
     }
     return obj;
   }).sort((a, b) => a.PageIndex - b.PageIndex);
   
   dispatchReportBuilder({"pages":updatedPages });
 }



 const handleDeletePage = (e, stateReportBuilder, dispatchReportBuilder) => {
  const { pageCurrent, pages } = stateReportBuilder;
  const index = pageCurrent;
  e.stopPropagation();

  // Remove the page being deleted
  const updatedPages = pages.filter((obj, i) => obj.PageIndex !== index);

  // Reassign PageIndex to make indexes consecutive starting from 0
  const reindexedPages = updatedPages.map((obj, idx) => ({
    ...obj,
    PageIndex: idx
  }));

  // Update pages in state
  dispatchReportBuilder({"pages":reindexedPages });

  // Calculate the new current page
  setTimeout(() => {
    if (index >= reindexedPages.length) {
      // If the deleted page was the last one, set the current page to the new last page
      dispatchReportBuilder({"pageCurrent":reindexedPages.length - 1 });
    } else {
      // Otherwise, keep the same current page index
      dispatchReportBuilder({"pagesCurrent":index });
    }
  }, 0);
};


function handleAddPage(stateReportBuilder,dispatchReportBuilder) {
  const { pageCurrent, pages } = stateReportBuilder;
  const index = pageCurrent + 1;

  const existingPageIndex = pages.findIndex(
    (page) => page.PageIndex === index
  );

  const updatedPages =
    existingPageIndex !== -1
      ? pages.map((page) => {
          if (page.PageIndex >= index) {
            return { ...page, PageIndex: page.PageIndex + 1 };
          }
          return page;
        })
      : pages;

  const newPage = CreateNewPage(index)

  dispatchReportBuilder({
    "pages":
      existingPageIndex !== -1
        ? [
            ...updatedPages.slice(0, existingPageIndex),
            newPage,
            ...updatedPages.slice(existingPageIndex),
          ]
        : [...updatedPages, newPage],
  });

  setTimeout(() => {
    dispatchReportBuilder({"pageCurrent":index });

    const targetElement = document.getElementById(`reportPage${index}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    // const targetElementFooter = document.getElementById(`reportPageFooter${index}`);
    // if (targetElementFooter) {
    //   targetElementFooter.scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //   });
    // }

  }, 0);
}


const handleAddPageAtEnd = (stateReportBuilder,dispatchReportBuilder) => {
  const { pageCurrent, pages } = stateReportBuilder;
  const newIndex = pages.length 
  const newPage = CreateNewPage(newIndex)
  dispatchReportBuilder({"pages":[...pages,newPage],"pageCurrent":newIndex})
  setTimeout(() => {
    const targetElement = document.getElementById(`reportPage${newIndex}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

  }, 1000);

};


  
const handleMovePageUp = (stateReportBuilder,dispatchReportBuilder) => {
  const {pages,pageCurrent} = stateReportBuilder 
  const index = pageCurrent
  if (index > 0) {
    const newPages = pages
      .map((page) => {
        if (page.PageIndex === index) {
          // Decremnet the PageIndex value by 1
          return { ...page, PageIndex: page.PageIndex - 1 };
        } else if (page.PageIndex === index - 1) {
          // Increment the PageIndex value by 1 for the next page
          return { ...page, PageIndex: page.PageIndex + 1 };
        } else {
          return page;
        }
      })
      .sort((a, b) => a.PageIndex - b.PageIndex);

    dispatchReportBuilder({"pages":newPages });
    setTimeout(()=>dispatchReportBuilder({"pageCurrent":index - 1}),200);
  }
};
const handleMovePageDown = (stateReportBuilder,dispatchReportBuilder) => {
  const {pages,pageCurrent} = stateReportBuilder 
  const index = pageCurrent
  if (index < pages.length) {
    const newPages = pages
      .map((page) => {
        if (page.PageIndex === index) {
          // Increment the PageIndex value by 1
          return { ...page, PageIndex: page.PageIndex + 1 };
        } else if (page.PageIndex === index + 1) {
          // Decrement the PageIndex value by 1 for the next page
          return { ...page, PageIndex: page.PageIndex - 1 };
        } else {
          return page;
        }
      })
      .sort((a, b) => a.PageIndex - b.PageIndex);

    dispatchReportBuilder({"pages":newPages });
    setTimeout(()=>dispatchReportBuilder({"pageCurrent": index + 1}),200);
  }
};


function handlePageName(v,pageIndex,stateReportBuilder,dispatchReportBuilder){
  const {pages} = stateReportBuilder 
//    const pagesUpdate = pages.filter((obj, i) => obj.PageIndex !== index);
const pagesUpdated = pages
const pageUpdate = pagesUpdated.find(p => p.PageIndex === pageIndex);

// If the page is found, update the PageName
if (pageUpdate) {
    pageUpdate.PageName = v;
}
dispatchReportBuilder({"pages":pagesUpdated})

}

function handlePageBackground(v,type="All",pageIndex,stateReportBuilder,dispatchReportBuilder){
  const {pages} = stateReportBuilder 
  let pagesUpdated = pages

if (type==="All"){
pagesUpdated = pages.map(page => ({
  ...page,
  PageBackground: v
}));}
else{
const pageUpdate = pagesUpdated.find(p => p.PageIndex === pageIndex);

// If the page is found, update the PageName
if (pageUpdate) {
    pageUpdate["PageBackground"] = v;
}

}
// Dispatch the update to your state management
dispatchReportBuilder({"pages":pagesUpdated });

}

function CreateNewPage(pageIndex){
  return { PageIndex: pageIndex,PageBackground:"#ffffff", PageName: "", PageContent: [] }
}


 
export {handleObjectDetails,handleDeletePage,handleAddPage,handleAddPageAtEnd
  ,handleMovePageDown,handleMovePageUp,handlePageName,handlePageBackground,CreateNewPage}
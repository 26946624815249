import React, { useEffect, useMemo, useState } from "react";
import Modal from "components/Modal/Modal";
import { useAppInfo } from "AppState";
import QuickScreenModal from "./QuickScreenModal";
import useAddScreener from "data/user/screener/useAddScreener";
import { applyFilters } from "./utilsScreener";
import SelectBox from "components/Inputs/SelectBox";
import TuneIcon from '@mui/icons-material/Tune';
import { Badge, Button } from "@mui/material";
import FilterNew from "./FilterNew";
import { primaryFilter, screenFilters } from "content/contentScreenerStocks";
import ScreenerSettings from "./ScreenerSettings";

export default function StockFilters() {
  const { stateScreener, dispatchScreener,stateStock} = useAppInfo();
  const { filters } = stateScreener;
  const {metricScreener} = stateStock.metrics
  const filterModal = stateScreener.filterModal;
  const { addScreener } = useAddScreener();
  const screenCategories = useMemo(()=>{
    let screenerCategories = {}
    if (metricScreener){   
    screenerCategories["Primary"] = primaryFilter
    screenerCategories["Financials"] = metricScreener.filter(obj=>obj["Filter Category"]==="Financial").map(obj=>obj["Metric"])
    screenerCategories["Market Stats"] =metricScreener.filter(obj=>obj["Filter Category"]==="Market Stats").map(obj=>obj["Metric"])
    screenerCategories["Technicals"] = metricScreener.filter(obj=>obj["Filter Category"]==="Technical").map(obj=>obj["Metric"])
    screenerCategories["Value"]=metricScreener.filter(obj=>obj["Filter Category"]==="Value").map(obj=>obj["Metric"])
  }
  return screenerCategories
  },[metricScreener,primaryFilter])


  function handleFilterModal(v) {
    dispatchScreener({ filterModal: v });
  }

  function handleQuickScreenModal(v) {
    dispatchScreener({ quickScreenModal: v });
  }

  const handleUpdateFilteredData = () => {
    const filteredData = applyFilters(filters, stateScreener.stocks);

    dispatchScreener({
      filterData: filteredData,
      currentPage: 1
    });

    if (stateScreener.screenerNameSel) {
      addScreener(stateScreener.screenerNameSel);
    } else {
      dispatchScreener({ createScreen: true });
    }

    handleQuickScreenModal(false);
    handleFilterModal(false);
  };




  function handleScreenerNameFilter(e, v) {
    if (v){
    const filters = stateScreener.screenerDataUser[v];
    if (filters) {
      const filteredData = applyFilters(filters, stateScreener.stocks);

      dispatchScreener({
        screenerNameSel: v,
        filters: filters,
        filterData: filteredData,
        currentPage: 1
      });
    }}
  }

  const nFilters = Object.keys(stateScreener.filters).length;

  return (
    <>
      <div className="page_header_content_box">
        {stateScreener.screenerNamesUser.length > 0 && (
          <SelectBox
            variant="outlined"
            sx={{ alignSelf: "center" }}
            width="200px"
            label={"Saved Screeners"}
            value={stateScreener.screenerNameSel}
            options={stateScreener.screenerNamesUser}
            onChange={handleScreenerNameFilter}
          />
        )}
        <SelectBox
          variant="outlined"
          width="200px"
          label={"Data View"}
          options={["Table", "Scatter", "Heatmap", "Brand Visuals"]}
          value={stateScreener.dataView}
          onChange={(e, v) => { dispatchScreener({ dataView: v }) }}
        />
      </div>

      <div className="page_header_toolkit">
        <Badge badgeContent={nFilters} color="secondary" overlap="circular">
          <Button
            onClick={() => handleFilterModal(true)}
            variant={`${nFilters > 0 ? `contained` : `outlined`}`}
            sx={{ borderRadius: "5px", position: "relative", padding: "0.25rem", marginRight: "0.5rem", border: "1px solid #ddd" }}
          >
            <TuneIcon />
            Filters
          </Button>
        </Badge>

        <Button
          onClick={() => handleQuickScreenModal(true)}
          style={{ borderRadius: "5px", border: "1px solid #ddd", position: "relative" }}
        >
          Quick Screeners
        </Button>

        <ScreenerSettings/>
      </div>

      {filterModal && (
        <Modal onClose={handleUpdateFilteredData} width="80%" height="70%">
          <FilterNew
            filterScreener="filters"
            metrics={metricScreener.map(obj=>obj["Metric"])}
            screenCategories={screenCategories}
            data={stateScreener.stocks}
            onUpdateFilteredData={(v) => handleUpdateFilteredData(v, "filter")}
          />
        </Modal>
      )}

      {stateScreener.quickScreenModal && (
        <Modal onClose={() => handleUpdateFilteredData()} width="80%" height="70%">
          <QuickScreenModal onApplyFilters={() => handleUpdateFilteredData()} />
        </Modal>
      )}

    </>
  );
}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';
import { handleObjectDetails } from '../utilsReport';

function generateBullishHaramiData() {
    const data = [];

    // Previous downtrend
    for (let i = 1; i <= 10; i++) {
        data.push({
            open: 100 - (Math.random() * 5),
            close: 98 - (Math.random() * 5),
            high: 102 - (Math.random() * 5),
            low: 96 - (Math.random() * 5)
        });
    }

    // Bullish candlestick (small body) within the previous candle's body
    data.push({
        open: 97 + (Math.random() * 2),
        close: 99 + (Math.random() * 2),
        high: 100 + (Math.random() * 2),
        low: 96 + (Math.random() * 2)
    });

    // Confirmatory uptrend candle
    for (let i = 1; i <= 10; i++) {
        data.push({
            open: 100 + (Math.random() * 5),
            close: 102 + (Math.random() * 5),
            high: 105 + (Math.random() * 5),
            low: 98 + (Math.random() * 5)
        });
    }

    return data;
}

// Example usage:
const data = generateBullishHaramiData();

export default function Shape({shapeType="rectangle",width="100%",height="100%",color="black",item}){
    const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const {pages,pageCurrent} =  stateReportBuilder
    const [mouseEnter,setMouseEnter] = useState(false)
    const [shapeColor,setShapeColor] = useState(color)

    function handleShapeColor(v){
      const updateContent = {ShapeColor:v}
      handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
      setShapeColor(v)
    }
    
    return (<div onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)}
    style={{width:width,height:height,position:"relative"}}>
        {mouseEnter && 
        <div style={{position:"absolute",zIndex:"100",top:0,background:"white"}}>
            <input type='color' value={shapeColor} onChange={(e)=>handleShapeColor(e.target.value)}></input>
        </div>}
    {shapeType==="rectangle" && <Rectangle shapeColor={shapeColor}/>}
    {shapeType==="square" && <Square shapeColor={shapeColor}/>}
    {shapeType==="circle" && <Circle shapeColor={shapeColor}/>}
    {shapeType==="triangle" && <Triangle shapeColor={shapeColor}/>}
    {shapeType==="candlestick" && <CandlestickChart data={data}/>}
    </div>)
}


function Rectangle({shapeColor}) {
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <rect
            width="100%"
            height="100%"
            x="0"
            y="0"
            fill={shapeColor}
          />
          Sorry, your browser does not support inline SVG.
        </svg>
   
  )
}

const Square = ({shapeColor}) => {
    return (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="100%" height="100%" fill={shapeColor} />
      </svg>
    );
  }
  

const Circle = ({shapeColor}) => {
    return (
      <svg width="100%" height="100%">
        <circle cx="50%" cy="50%" r="40%" stroke="#ffffff" strokeWidth="0" fill={shapeColor} />
      </svg>
    );
  }


  const Triangle = ({ shapeColor }) => {
    const chartWidth = 100 
    const chartHeight = 100
    return (
        <svg
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        preserveAspectRatio="xMinYMin meet"
        width="100%"
        height="100%"
      >
<polygon points={`0,${chartHeight} ${chartWidth / 2},0 ${chartWidth},${chartHeight}`} fill={shapeColor}/>
        </svg>
    );
  };

  
const ArrowComponent = () => {
    return (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <polygon points="10,50 50,10 90,50" fill="orange" />
      </svg>
    );
  }

const Line = ({shapeColor}) => {
    return (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <line x1="10" y1="0" x2="90" y2="90" stroke={shapeColor} strokeWidth="3" />

      </svg>
    );
  }


  const CandlestickChart = ({ data }) => {
    // Define candle dimensions and chart height
    const candleWidth = 10;
    const candleMargin = 5; // Increase this value to increase the gap between candles
    const chartHeight = 200;
    
    // Calculate chart width based on data length and candle dimensions
    const chartWidth = (candleWidth + candleMargin) * data.length;
  
    // Find the highest and lowest values in the dataset
    const highest = Math.max(...data.map(item => item.high));
    const lowest = Math.min(...data.map(item => item.low));
  
    // Calculate vertical scaling
    const scale = chartHeight / (highest - lowest);
  
    return (
      <div style={{ width: '100%', height: '100%'}}>
        <svg
          viewBox={`0 0 ${chartWidth} ${chartHeight}`}
          preserveAspectRatio="xMinYMin meet"
          width="100%"
          height="100%"
        >
          {data.map((item, index) => {
            const x = index * (candleWidth + candleMargin);
            const y = chartHeight - (item.high - lowest) * scale;
            const height = (item.high - item.low) * scale;
            const fill = item.open < item.close ? '#00FF00' : '#FF0000';
            
            return (
              <g key={index}>
                {/* Render upper line of candle */}
                <line x1={x + candleWidth / 2} y1={y} x2={x + candleWidth / 2} y2={y + height} stroke="black" strokeWidth="1" />
                {/* Render lower line of candle */}
                <line x1={x + candleWidth / 2} y1={chartHeight - (item.low - lowest) * scale} x2={x + candleWidth / 2} y2={chartHeight - (item.high - lowest) * scale} stroke="black" strokeWidth="1" />
                {/* Render rectangle representing candle body */}
                <rect x={x} y={Math.min(chartHeight - (item.open - lowest) * scale, chartHeight - (item.close - lowest) * scale)} width={candleWidth} height={Math.abs((item.open - item.close) * scale)} fill={fill} />
                
              </g>
            );
          })}
          
        </svg>
      </div>
    );
  };
  

export {Shape}
import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useBeta(tickerList,dateFrequency,period) {
const [beta, setCorr] = useState([]);
const [betaSingle, setBetaSingle] = useState([]);
const [betaLoading, setMarketInfoLoading] = useState(false);


useEffect(() => {
    getPriceInfo();
}, [tickerList,dateFrequency,period]);


async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/beta/`, {
        params: {
        ticker: JSON.stringify([...tickerList,"SPY"]),
        dateFrequency:dateFrequency,
        period:period
        },
    });
   
    const marketInfo = response.data.betaMulti
    setCorr(JSON.parse(marketInfo))
    setBetaSingle(JSON.parse(response.data.betaSingle))
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {beta,betaSingle,betaLoading };
}
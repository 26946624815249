import React, { memo,useCallback, useState} from 'react';
import { Handle, NodeResizer, Position, useReactFlow } from 'reactflow';
import CloseIcon from '@mui/icons-material/Close';

export default function NodeTemplate({id, data,selected, isConnectable,children,resizer=false}) {
    const reactFlow = useReactFlow();

    const { deleteElements } = useReactFlow();
    const handleDelete = useCallback(() => {
        deleteElements({ nodes: [{ id }] });
      }, [id, deleteElements]);
    
    const [mouseEnter,setMouseEnter] = useState(false)
    return (
    <>
     {/* <NodeResizer minWidth={100} minHeight={300}/>
     */}
      <Handle
        type="target"
        position={Position.Bottom}
        style={{ background: '#555' ,color:"blue"}}
        // onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div style={{position:"relative",padding:"0.25rem"}} onMouseEnter={()=>setMouseEnter(true)} onMouseLeave={()=>setMouseEnter(false)}>
      {children}
      {mouseEnter && <button style={{position:"absolute",bottom:"-20px",background:"none",border:"none"}} 
      onClick={handleDelete}><CloseIcon fontSize='small'/></button>}
      </div>
     
      <Handle
        type="source"
        position={Position.Top}
        id="a"
        style={{background: '#555' }}
        isConnectable={isConnectable}
      />
      {/* <Handle
        type="source"
        position={Position.Right}
        id="b"
        style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    <Handle
        type="source"
        position={Position.Left}
        id="b"
        style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      /> */}
    </>
  )
}

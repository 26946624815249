import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import { fetchEconInfo } from 'data/economy/useEconInfo'
import React from 'react'
import { getSpecificArrayObjKeys } from 'utils/utilGeneral'

export default function useAssistantOpportunityFinder() {
    
    const {stateTicker,stateScreener,dispatchIndustry} = useAppInfo()
    
    function GetPortfolioFit(){
        
        return {"react_component":"PortfolioFit"}
      }


    function GetInvestmentOpportunity(){
        
        return {"react_component":"InvestmentOpportunity"}
      }

      

      function GetSectorSpecificOpportunity({ industry, sector}){
        const stocks = stateScreener.stocks.filter(obj => obj[dfin.country] === "USA" && 
          sector.includes(obj[dfin.gicSector]) && industry.includes(obj[dfin.gicIndustry])).sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]).slice(0,10);

        
        let cols = [dfin.coLogo,
          dfin.ticker,
          dfin.coName,"Close",dfin.performance1D,
          dfin.performance1M,dfin.performance3M]
        // Apply filtering logic based on the stockFilter

        // Select only specific fields for the filtered stocks
        const stocksFilter = getSpecificArrayObjKeys(stocks, cols);

        dispatchIndustry({sectorSel:sector,industrySel:industry,analysisOptionSel:"Constituents",countrySel:["USA"]})

        return {stocksFilter,"learn_more":"/Analytics/Industry","react_component":""}
      }


    return {GetPortfolioFit,GetInvestmentOpportunity,GetSectorSpecificOpportunity}
}

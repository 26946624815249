import React from 'react'
import useSenatorTransactionsFeed from 'data/financialModelingPrep/News/useSenatorTransactionsFeed'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"

export default function SenatorsTrading() {
  const {senatorsFeed} = useSenatorTransactionsFeed()
  const tableData = senatorsFeed.length>0 && getSpecificArrayObjKeys(senatorsFeed,cols)
  return (
    <>
    {senatorsFeed.length>0 && <TableGen tableLayout='fixed' data={tableData}/>}

    </>
  )
}

const cols =  ["assetDescription","ticker" ,"representative","district", "transactionDate","type", "amount", "capitalGainsOver200USD", "owner", "disclosureYear", "disclosureDate",  "link"]

import React, { useEffect,useState } from 'react'
import MetricStockModal from 'components/Template/MetricStockModal';
import { useAppInfo } from 'AppState';
import CompanyVsIndustry from './CompanyVsIndustry';
import { Button} from '@mui/material';
import { defaultMetrics, dfin } from 'content/constants';
import TabsClose from 'components/Tabs/TabsClose';
import Toggle from 'components/Inputs/Toggle';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from 'react-router-dom';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function CompanyVsIndustryUI({infoName,storeId,defaultMet,
  children,data,}) {
    const {stateStock,dispatchIndustry} = useAppInfo()
  const coInfo = stateStock.coInfo

    const [metricSelected, setMetricSelected] = useState(defaultMetrics.MarketStats);
    const [addMetrics, setAddMetrics] = useState(false);
    const [resetMetric, setResetMetric] = useState(false);
   

  
    function handleAddTabs() {
        setAddMetrics(!addMetrics);
      };

    function handleResetMetric(){
    setMetricSelected(defaultMet)
    sessionStorage.setItem(
      `${storeId}`,
      JSON.stringify(defaultMet)
    );
    }

    function handleDeleteTab(index) {
    setMetricSelected((prevItems) => {
        // Filter out the item at the specified index
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
    });
    // Update the tabList and tabContent state with the updated values
    }

    
  function handleMetricSelected(option) {

    setMetricSelected((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });

    if (metricSelected.length > 0) {
      sessionStorage.setItem(
        `${storeId}`,
        JSON.stringify(metricSelected)
      );
    }
  }


  
  useEffect(() => {
    
   setMetricSelected(defaultMet)
  }
    ,[infoName,defaultMet])
  
  const [displayView,setDisplayView] = useState(false)

  const navigate = useNavigate()

  function handleIndustry(){
    let indSecMode = true 
    if (comparisonWith === "Sector"){
      indSecMode = false
    }
    navigate("/Analytics/Industry")
    dispatchIndustry({
      sectorSel: [coInfo?.[dfin.gicSector]],
      industrySel: [coInfo?.[dfin.gicIndustry]],
      countrySel: [coInfo?.[dfin.country]],
      indSecMode: indSecMode,
      menuSel: "Performance"
    });
  }

  const [comparisonWith,setComparisonWith] = useState("Industry")
  
  const subTabContent = metricSelected.map((metric) => (
    <>
      <div style={{display:"flex",marginTop:"1rem"}}>
        <CompanyVsIndustry  metric={metric}  coInfo={coInfo} type={comparisonWith}/>
     </div>
     </>
       
     ));
  return (
    <div className='containerColumnFlexNoGap'>
        
        <MetricSettingsBar
        title={`${stateStock.companySelected} vs`}
        contentSelection
        contentOptions={["Industry","Sector"]}
        contentValue={comparisonWith}
        handleContent={(v)=>setComparisonWith(v)}
        addMetric
        handleAddMetric={handleAddTabs}
        childrenPopover={ 
          <>
          <Toggle toggleValues={["List","Tabs"]} toggleMode={displayView} handleToggle={()=>setDisplayView(!displayView)}/>
          </>
        }
        >
        <Button onClick={handleIndustry}>
        {comparisonWith==="Industry"?stateStock.coInfo?.[dfin.gicIndustry]:stateStock.coInfo?.[dfin.gicSector]}-{stateStock.coInfo?.[dfin.country]}
          <ArrowOutwardIcon/></Button>
        </MetricSettingsBar>

        
        {children}
   
    {displayView && 
    <TabsClose
            tabList={metricSelected}
            tabContent={subTabContent}
            tabStyle="subTabs"
            tabOrientation="row"
            addTab={true}
            handleAddTabs={handleAddTabs}
            deleteTab={true}
            handleDeleteTab={handleDeleteTab}
          >
          </TabsClose>}
          
    <div className='containerColumnFlex1'>
    {!displayView && metricSelected.map(metric=>
      <CompanyVsIndustry coInfo={coInfo} metric={metric} width={"60%"} histogram={false} key={metric} 
      type={comparisonWith}/>
    )
      }
          </div>

    {addMetrics &&
    <MetricStockModal
    checkbox
    modalName={"Stats vs Industry"}
    handleModalClose={handleAddTabs} 
    handleMetricSelected={handleMetricSelected}  
    metricSelected={metricSelected}/> 
      }
    </div>
  )
}

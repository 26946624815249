import React, { useState, useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

export default function useScreenerCrypto() {
  const { dispatchScreener} = useAppInfo()

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      dispatchScreener({"loadingCrypto":true });

      const response = await axios.get(`${BASE_URL}/screenerCrypto/`);
      const screenInfo = response.data;
      dispatchScreener({"crypto":screenInfo });
    } catch (error) {
      console.error(error);
    } finally {
      dispatchScreener({"loadingCrypto":false });
    }
  }
}

import React from 'react'
import NetCashflowInfo from './NetCashflowInfo'
import NetCashAllocation from './NetCashAllocation'
import LiabilityPayback from 'myFinancials/Liability/LiabilityPayback'
import ExpenseDetails from './ExpenseDetails'
import AssetReturnProjections from './../Asset/AssetReturnProjections';
import NetCashflowOverview from './NetCashflowOverview'
import Income from './Income'

export default function CashflowInfo({menu,subMenu}) {

  const content = {
    "Net Cashflow":<NetCashflowOverview displayType='main'/>,
    "Net Cash Allocation":<NetCashAllocation/>,
    "Income":<Income/>,
    "Return on Savings & Investment":<AssetReturnProjections/>,
    "Expense":<ExpenseDetails/>,
    "Liability Payback":<LiabilityPayback/>,
  }
  return (
    <>
    {content[menu]}
    </>
  )
}

import React, {useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import usePriceChartEod from 'data/eod/usePriceChartEod';

require("highcharts/modules/accessibility")(Highcharts);

const PriceValueChart = ({companySelected}) => {

  const {stateStock,stateTicker} = useAppInfo()
  const {priceInfo} = usePriceChartEod({tickerSel:companySelected})

  

  const coAnnualInfo = stateStock.annualInfo.filter((obj) => obj[dfin.ticker] === companySelected);
  const fvalue = getSpecificArrayObjKeys(coAnnualInfo, [dfin.period, dfin.fv30, dfin.fv15, dfin.fv45]);

  const getYearFromDate = (dateString) => new Date(dateString).getFullYear();

  const pSelDataWithFV = useMemo(() => {
    if (priceInfo){
    return priceInfo.map((data) => {
      const year = getYearFromDate(data.date);
      const matchingFV = fvalue.find((obj) => getYearFromDate(obj[dfin.period].toString()) === year);
      return {
        ...data,
        fv30: matchingFV ? matchingFV[dfin.fv30] : null,
        fv15: matchingFV ? matchingFV[dfin.fv15] : null,
        fv45: matchingFV ? matchingFV[dfin.fv45] : null,
      };
    });}
  }, [priceInfo, fvalue]);

  const seriesData =  useMemo(() => {
    
    return [
    {
      name: 'Fair Value 45',
      data: pSelDataWithFV.map((data) => [new Date(data.date).getTime(), data.fv45]),
      color: 'red',
    },
    {
      name: 'Fair Value 30',
      data: pSelDataWithFV.map((data) => [new Date(data.date).getTime(), data.fv30]),
      color: 'orange',
    },
    {
      name: 'Fair Value 15',
      data: pSelDataWithFV.map((data) => [new Date(data.date).getTime(), data.fv15]),
      color: 'green',
    },
    {
      name: 'Close',
      data: pSelDataWithFV.map((data) => [new Date(data.date).getTime(), data["adjusted_close"]]),
      color: 'blue',
    },
    // Add more series if needed
  ]})

  const options = useMemo(() => ({
    chart: {
      zoomType: 'x',
    },
    title: {
      text: `FV ${companySelected}`,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: seriesData,
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    exporting:{
      enabled:false
    },
    credits: {
      enabled: false, // Hide Highcharts.com credits
    },
    accessibility: {
      enabled: false, // Include the accessibility module
    },
  }), [companySelected, seriesData]);

  return (
  <div style={{ height: '100%' ,width:"100%"}}>
  {options && <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: '100%' ,width:"100%"} }}/>}
  </div>)
};

export default PriceValueChart;
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { getSpecificArrayObjKeys, numberDecimal } from "utils/utilGeneral"
import Histogram from 'components/Charts/Histogram'
import { dfin } from 'content/constants'
import AdvanceDeclineBar from 'components/Charts/AdvanceDeclineBar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Button } from '@mui/material'


export default function MarketBreadthIndSecDetail({ performancePeriod, type = "Sector", industry, sector }) {
  const { stateScreener, stateIndustry } = useAppInfo()

  const [performance, setPerformance] = useState([])
  const [performanceStats, setPerformanceStats] = useState()

  useEffect(() => {
    if (stateIndustry.constiInfoAll.length > 0) {
      let stateScreenFilter = stateIndustry.constiInfoAll;
      if (type === "Sector") {
        stateScreenFilter = stateIndustry.constiInfoAll.filter(obj => obj[dfin.gicSector] === sector);
      } else if (type === "Industry") {
        stateScreenFilter = stateIndustry.constiInfoAll.filter(obj => obj[dfin.gicIndustry] === industry);
      }
      const performance = getSpecificArrayObjKeys(stateScreenFilter, [performancePeriod]);

      setPerformance(performance)
      setPerformanceStats(calculatePerformanceStats(performance))
    }

  }, [stateIndustry.constiInfoAll, performancePeriod])

  let title = type === "Sector" ? sector : (type === "Industry" ? industry : "Consolidated Performance");

  const [expandMore, setExpandMore] = useState(false);

  const colorStyle = (value) => {
    return value > 0 ? { color: "green" } : { color: "red" };
  };

  return (
    <div style={{ width: "100%" }}>

      {performance.length > 0 &&
        <div style={{ width: "100%" }}>
          <div onClick={() => setExpandMore(!expandMore)} style={{ border: "1px solid #ddd", borderRadius: "5px", width: "100%", boxSizing: "border-box", padding: "0.25rem", boxSizing: "border-box" }}>

            <h3 style={{ color: "gray", fontWeight: "bold" }}>{title}</h3>
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center", width: "100%" }}>
              <div>
                <h4 style={{fontWeight:"bold"}}>Min: <span style={colorStyle(performanceStats?.min)}> {performanceStats && numberDecimal(performanceStats?.min)}</span></h4>
                <h4 style={{fontWeight:"bold"}}>Average: <span style={colorStyle(performanceStats?.mean)}>{performanceStats && numberDecimal(performanceStats?.mean)}</span></h4>
                <h4 style={{fontWeight:"bold"}}>Max:<span style={colorStyle(performanceStats?.max)}>{performanceStats && numberDecimal(performanceStats?.max)}</span> </h4>
              </div>
              <div style={{ flex: 1 }}>
                <AdvanceDeclineBar performance={performance} performancePeriod={performancePeriod} />
              </div>
              <Button  style={{ marginLeft: "auto",alignItems: "center", display: "flex" }}>
                {!expandMore && <><span>See Distribution</span> <ExpandMoreIcon/></>}
                {expandMore && <ExpandLessIcon />}
              </Button>
            </div>

            {expandMore && <Histogram color='Performance' data={performance} rangeNumberFormat={"%"} />}

          </div>

        </div>
      }
    </div>
  )
}

function calculatePerformanceStats(performances) {
  let minValue = Infinity;
  let maxValue = -Infinity;
  let sum = 0;

  performances.forEach((performanceObj) => {
    const performance = performanceObj[Object.keys(performances[0])[0]];
    minValue = Math.min(minValue, performance);
    maxValue = Math.max(maxValue, performance);
    sum += performance;
  });

  const meanValue = sum / performances.length;

  return {
    min: minValue,
    max: maxValue,
    mean: meanValue
  };
}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import useESGscore from 'data/financialModelingPrep/useESGscores'
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import EnergySavingsLeaf from '@mui/icons-material/EnergySavingsLeaf';
import { Button, Typography } from '@mui/material';
import TooltipInfo from 'components/Modal/TooltipInfo';
import { dfin } from 'content/constants';
import { formatNumber } from 'utils/utilGeneral';

export default function CompanyESGButton({coInfo}) {
  const { esgScore } = useESGscore(coInfo?.[dfin.ticker]);
  const [currentEsgScore, setCurrentEsgScore] = useState({});

  useEffect(() => {
    if (esgScore.length > 0) {
      const currentEsgScore = esgScore[0];
      setCurrentEsgScore(currentEsgScore);
    } else {
      setCurrentEsgScore({});
    }
  }, [esgScore]);

  const [color,setColor] = useState("gray"); // Default color for no data
  const [score,setScore] = useState("N/A"); // Default score for no data

  useEffect(()=>{
    if (Object.keys(currentEsgScore).length>0) {
      let color = "gray"
      let score = currentEsgScore.ESGScore;
      if (score < 30) {
          color = "red";
      } else if (score >= 30 && score <= 70) {
          color = "orange";
      } else {
          color = "green";
      }
      setScore(score)
      setColor(color)
  }
  },[currentEsgScore])
  
  const [mouseEnter,setMouseEnter] = useState(false)
  return (
    <>
      <Button onMouseEnter={()=>setMouseEnter(true)} 
      onMouseLeave={()=>setMouseEnter(false)}
      sx={{ display: "flex", flexDirection: "column" ,position:"relative"}}>
        <EnergySavingsLeaf sx={{ color: color }} />
        <h5 style={{ fontWeight: "bold" }}>ESG: {score !== "N/A" ? `${formatNumber(score/100*100)}%` : "-"}</h5>
        {mouseEnter && <TooltipInfo top={"100%"}>
          <div style={{display:"flex",flexDirection:"column",padding:"1rem",width:"auto",alignItems:"flex-start"}}>
          <Typography sx={{whiteSpace:"nowrap"}}>Environment Score: {currentEsgScore?.environmentalScore}</Typography>
          <Typography  sx={{whiteSpace:"nowrap"}}> Social Score :  {currentEsgScore?.socialScore} </Typography>
          <Typography  sx={{whiteSpace:"nowrap"}}>Governanace Score: {currentEsgScore?.governanceScore}</Typography>
          </div>
         
          </TooltipInfo>}
      </Button>
     
    </>
  );
}

const dataKeys = [
  "ESGScore",
  "acceptedDate",
  "cik",
  "companyName",
  "date",
  "environmentalScore",
  "formType",
  "governanceScore",
  "socialScore",
  "symbol",
  "url"
]
import React from 'react';

import EconSeries from 'analytics/economy/EconSeries';
import GuideTemplate from '../Template/GuideTemplate';

export default function EconomyVsMarket() {
    
  return (
    <>
        <GuideTemplate description={description} steps={steps} name={"Guide to Economy Factors affecting Financial Markets"}/>
    </>
  )
}

const transformDataForChart = (data, dateFieldName, valueFieldName) => {
    return data.map((item) => ({
      date: new Date(item[dateFieldName]).getTime(),
      value: item[valueFieldName],
      chartType: "line"
    }));
  };

  const description = `
The economic factors affecting markets are multifaceted and encompass a variety of elements that influence investment decisions and financial outcomes. <br/><br/> Key economic indicators such as monetary policy, interest rates, and fiscal measures play crucial roles in shaping the economic environment. For example, central banks manipulate money supply and interest rates to regulate economic growth, which in turn impacts market liquidity and investor sentiment. <br/><br/>Additionally, government policies regarding taxes, subsidies, and regulations can either foster or hinder economic stability and growth. Factors like GDP growth, inflation, and technological advancements also dictate market trends by affecting corporate earnings and investment opportunities. Understanding these dynamics is essential for predicting market movements and making informed investment decisions.
  `

  const steps = [
    {
        label: 'Monetary Policy - Money Supply',
        keyPoints: 'Favorable monetary policy involves central bank actions aimed at achieving price stability, maximum employment, and sustainable economic growth through interest rate adjustments and liquidity measures.',
        proxyMetric: 'Federal Funds Rate',
        warning: 'Excessive monetary stimulus may fuel asset price inflation without corresponding real economic growth, leading to financial imbalances and eventual market corrections.',
        success: 'Balanced and well-timed monetary policy responses to economic conditions contribute to stable financial markets, healthy credit conditions, and sustainable economic expansion, supporting stock market growth.'
        ,content:<><EconSeries seriesName={"M1SL"}/></>
      },
      {
        label: 'Monetary Policy - Interest Rates',
        keyPoints: 'Low interest rates refer to the monetary policy set by central banks to stimulate economic activity by making borrowing cheaper. This encourages businesses to invest in expansion and consumers to borrow for spending.',
      proxyMetric: '10-Year Treasury Yield',
      warning: 'Persistently low interest rates may lead to asset bubbles or excessive risk-taking in financial markets, increasing the likelihood of a market crash if rates rise suddenly.',
      success: 'Gradual and controlled increases in interest rates after a period of low rates indicate a healthy economy and are conducive to sustainable stock market growth.',
      content:<><EconSeries seriesName={"DFF"}/> <EconSeries seriesName={"REAINTRATREARAT10Y"}/></>
      },
    {
          label: 'Federal Policy - Taxes, Subsidies & Regulations',
          keyPoints: 'Federal policy refers to government decisions regarding fiscal, monetary, and regulatory measures aimed at influencing economic activity, employment, and price stability.',
          proxyMetric: 'Federal Policy Index',
          warning: 'Unfavorable federal policies, such as austerity measures or trade conflicts, may hinder economic growth, disrupt supply chains, and negatively impact corporate earnings, leading to stock market declines.',
          success: 'Well-designed and coordinated federal policies, including fiscal stimulus and regulatory reforms, support sustainable economic growth, job creation, and investor confidence, contributing to stock market resilience and prosperity.'
        },

    {
      label: 'Economic Growth',
      keyPoints: 'Strong economic growth reflects a robust expansion in a nation’s gross domestic product (GDP), indicating increased production, employment, and consumer spending.',
      proxyMetric: 'Gross Domestic Product (GDP) Growth Rate',
      warning: 'Overheating of the economy due to rapid growth may lead to inflationary pressures and potential interest rate hikes by central banks, which could dampen corporate profits and investor sentiment.',
      success: 'Sustained and balanced economic growth, supported by increases in productivity and wages, provides a solid foundation for corporate earnings growth and long-term stock market appreciation.'
      ,content:<><EconSeries seriesName={"GDP"}/></>
    },
    {
      label: 'Inflation',
      keyPoints: 'Low inflation refers to a stable and moderate increase in the general price level of goods and services over time, preserving the purchasing power of money.',
      proxyMetric: 'Consumer Price Index (CPI)',
      warning: 'Persistently low inflation, or deflation, may indicate weak consumer demand and economic stagnation, potentially leading to reduced business investment and declining stock prices.',
      success: 'Stable and controlled inflation within the central bank’s target range supports consumer purchasing power and business profitability, fostering sustainable economic growth and stock market performance.'
      ,content:<><EconSeries seriesName={"FPCPITOTLZGUSA"}/></>
    },
    
    {
      label: 'Political Environment',
      keyPoints: 'A stable political environment reflects the absence of significant political upheaval, governance issues, or policy uncertainty, providing a conducive climate for economic growth and investment.',
      proxyMetric: 'Political Stability Index',
      warning: 'Political instability, such as government dysfunction or geopolitical tensions, can undermine investor confidence and lead to market volatility as uncertainty increases.',
      success: 'Consistent and effective governance, coupled with transparent policies and smooth transitions of power, fosters investor confidence, economic stability, and long-term stock market prosperity.'
    },
    {
      label: 'Corporate Earnings',
      keyPoints: 'Positive corporate earnings indicate the profitability of publicly traded companies, reflecting their ability to generate revenues that exceed costs and deliver returns to shareholders.',
      proxyMetric: 'Earnings Per Share (EPS)',
      warning: 'Declining corporate earnings due to factors such as weak consumer demand, rising production costs, or increased competition may lead to lower stock prices and investor pessimism.',
      success: 'Sustained growth in corporate earnings, driven by strong sales, cost management, and innovation, validates business fundamentals and supports higher stock valuations and investor confidence.'
      ,content:<><EconSeries seriesName={"CP"}/></>
    },
    {
      label: 'Technological Advancements',
      keyPoints: 'Technological advancements refer to innovations, discoveries, and advancements in technology that enhance productivity, efficiency, and competitiveness across industries.',
      proxyMetric: 'Research & Development (R&D) Expenditure',
      warning: 'Disruption caused by rapid technological advancements may negatively impact traditional industries and companies unable to adapt, leading to stock price declines and investor uncertainty.',
      success: 'Investments in research and development, coupled with successful technological innovations, improve productivity, create new market opportunities, and drive long-term corporate growth and stock market performance.'
    },
    {
      label: 'Global Economic Conditions',
      keyPoints: 'Global economic conditions encompass the overall economic performance and trends across countries and regions, influencing international trade, investment flows, and corporate profitability.',
      proxyMetric: 'Global Purchasing Managers\' Index (PMI)',
      warning: 'Global economic downturns or recessions can reduce demand for goods and services, negatively affecting corporate earnings and stock market performance.',
      success: 'Synchronized economic expansions across major economies, supported by robust trade and investment flows, contribute to higher corporate earnings and global stock market growth.'
    },
    {
      label: 'Stable Currency',
      keyPoints: 'A stable currency maintains its value relative to other currencies over time, providing certainty and confidence to investors, businesses, and consumers in international transactions.',
      proxyMetric: 'Currency Exchange Rate Stability',
      warning: 'Currency volatility or devaluation can erode investor confidence, increase the cost of imports, and lead to inflationary pressures, negatively impacting stock market performance.',
      success: 'Stable exchange rates enhance predictability in international trade and investment, reduce currency-related risks for businesses, and support sustained economic growth and stock market stability.'
      ,content:<><EconSeries seriesName={"DTWEXBGS"}/></>
    },
    {
      label: 'Consumer Sentiment',
      keyPoints: 'Positive consumer sentiment reflects optimism and confidence among consumers regarding their financial prospects, employment opportunities, and overall economic conditions, driving higher consumer spending.',
      proxyMetric: 'Consumer Confidence Index (CCI)',
      warning: 'Declining consumer confidence may signal weakening consumer spending, leading to lower corporate revenues and reduced stock market returns.',
      success: 'Strong consumer confidence levels, supported by favorable economic conditions and low unemployment rates, drive increased consumer spending, higher corporate earnings, and sustained stock market growth.'
      
    },
    
  ];
  

// const econFactor = [
//     {
//       label: 'Interest Rates',
//       keyPoints: 'Low interest rates reduce borrowing costs for businesses, stimulating investment and economic growth.',
//       proxyMetric: '10-Year Treasury Yield',
//       content:<><EconSeries seriesName={"DFF"}/> <EconSeries seriesName={"REAINTRATREARAT10Y"}/></>
//     },
//     {
//       label: 'Economic Growth',
//       keyPoints: 'Robust economic expansion leads to higher corporate earnings and increased investor confidence.',
//       proxyMetric: 'Gross Domestic Product (GDP) Growth Rate',
//       content:<><EconSeries seriesName={"GDP"}/></>
//     },
//     {
//         label: 'Federal Policy',
//         keyPoints: 'Federal policy decisions, such as fiscal stimulus or regulatory changes, can significantly impact economic growth and stock market performance.',
//         proxyMetric: 'Federal Policy Index'
//       },
//     {
//       label: 'Inflation',
//       keyPoints: 'Moderate inflation preserves consumer purchasing power and supports corporate profitability.',
//       proxyMetric: 'Consumer Price Index (CPI)',
//       content:<><EconSeries seriesName={"FPCPITOTLZGUSA"}/></>
//     },
//     {
//       label: 'Monetary Policy',
//       keyPoints: 'Accommodative monetary policies, such as quantitative easing, provide liquidity to financial markets and encourage investment.',
//       proxyMetric: 'Federal Funds Rate',
//       content:<><EconSeries seriesName={"M1SL"}/><EconSeries seriesName={"DFF"}/></>
//     },
    
//     {
//       label: 'Political Environment',
//       keyPoints: 'Political stability fosters investor confidence and certainty, encouraging capital allocation to stocks.',
//       proxyMetric: 'Political Stability Index',
     
//     },
//     {
//       label: 'Corporate Earnings',
//       keyPoints: 'Strong corporate earnings growth indicates company profitability and drives stock market performance.',
//       proxyMetric: 'Earnings Per Share (EPS)',
//       content:<><EconSeries seriesName={"CP"}/></>
//     },
//     {
//       label: 'Technological Advancements',
//       keyPoints: 'Innovation and technological progress enhance productivity and create new market opportunities, benefiting stock prices.',
//       proxyMetric: 'Research & Development (R&D) Expenditure',
//       content:<><EconSeries seriesName={"CP"}/></>
//     },
//     {
//       label: 'Global Economic Conditions',
//       keyPoints: 'Favorable economic conditions worldwide support international trade and corporate profits, positively impacting stock markets.',
//       proxyMetric: 'Global Purchasing Managers\' Index (PMI)'
//     },
//     {
//       label: 'Stable Currency',
//       keyPoints: 'A stable currency enhances economic and financial market stability, attracting investors to stocks.',
//       proxyMetric: 'Currency Exchange Rate Stability'
//     },
//     {
//       label: 'Consumer Sentiment',
//       keyPoints: 'High consumer confidence leads to increased spending, boosting corporate revenues and stock market performance.',
//       proxyMetric: 'Consumer Confidence Index (CCI)'
//     }
//   ];
  
import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useTechnicalRating(tickerList,period="daily") {

const [technicalRating, setTechnicalRating] = useState([]);
const [tRLoading, setMarketInfoLoading] = useState(false);


const [previousPeerSelected, setPreviousPeerSelected] = useState([]);

useEffect(() => {
  getInfo()
}, [tickerList,period]);


async function getInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/technicalRating/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        period:period
        },
    });
    
    const info = response.data
    setTechnicalRating(info)
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {technicalRating, tRLoading };
}
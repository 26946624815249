import React from 'react'
import FieldFormOnboarding from '../FormFields/FieldFormOnboarding'
import { generateUniqueId } from "utils/utilGeneral"


export default function IncomeOnboarding() {
  const defaultField =  { id:generateUniqueId(), name: "Income from Salary (after Tax)", 
  type: "Periodic Income", amount: 5000, date: new Date(), currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}
  return (
    <FieldFormOnboarding name='Income' defaultField={defaultField} forecastPeriod={true} growthRate={true} 
    period={true} formName='Income' storeName={"incomeMonthly"} data={[]} 
    formHeading='Income Category' showType={false}/>
  )
}

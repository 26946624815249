const initialStateResource = {
    menuList:["Library"],
    itemSel:"Library",
    content:[],
    topicMenu:[],
    guidanceSel:"Market Analysis",
    guidanceMenuSel:"Market Analysis",
    sidebar:{open:false,name:"Guide"},
    searchLearnHistory:[],
    guideDisplay:"home",
    learnContentTopics:[],
}


const initialStateMindmap = {
        nodes:[],
        edges:[]
}

export {initialStateResource,initialStateMindmap}
import React, { useEffect, useState } from 'react'
import Toggle from 'components/Inputs/Toggle'
import SelectBox from 'components/Inputs/SelectBox'
import PeriodSlider from 'components/Inputs/PeriodSlider'
import { useAppInfo } from 'AppState'
import MetricStockFinancialCategory from 'components/Template/MetricStockFinancialCategory'
import PageSettingsModal from 'components/Template/PageSettingsModal'
import { MetricSettingsBar } from 'components/Template/MetricSettings'
import { dfin } from 'content/constants'
import { Button } from '@mui/material'

export default function FinancialsSettings({
    title="Financial Statements",
    settingsFor="Analytics",
    statementType,
    statementCategory,
    metricAll,
    metricSelected,
    handleMetricSelected,
    period,
    financialsType,
    commonSizeMetric,
    handleCommonSizeMetric,
    dates,
}) 


{ 
    const {dispatchStock,stateStock} = useAppInfo()
    const {peerMode,financialsSettings,metricSelectedCategories,coInfo} = stateStock
    const {reportMode,periodMode,periodLen,growthMode,chartType,singlePeriodDate,totalPeriods,displayType} = financialsSettings
    const [metricModal,setMetricModal] = useState(false)
    

  function handleResetMetric(){
    dispatchStock({"metricSelectedCategories":{...metricSelectedCategories,[`${statementType}`]:[]}})
  }
  

  function handlePeriodInputChange(event, v) {
    event.stopPropagation();
    dispatchStock({"financialsSettings":{
        ...financialsSettings,
        periodLen: Number(event.target.value)
      }});
  }
  

  
  function handleChartType(v) {
      dispatchStock({"financialsSettings":{
        ...financialsSettings,
        chartType: v
      }
    });    
  }

  function handleSinglePeriodDate(v){
    dispatchStock({financialsSettings:{...financialsSettings,singlePeriodDate:v}})
  }
  
  function handleDisplayType(v){
    dispatchStock({financialsSettings:{...financialsSettings,displayType:v}})
  }

  const [chartSetting,setChartSetting] = useState(false)
   

  return (
    <>
    <MetricSettingsBar
    title={title}
    addMetric={period==="Multi"}
    displaySettings
    handleAddMetric={()=>setMetricModal(true)}
    handleDisplaySettings={()=>setChartSetting(true)}
    mainSettings
    mainSettingsContent={<SettingsMain period={period}/>}
    >
       {financialsType === "commonSize" && 
      <div style={{width:"250px",display:"flex",alignItems:"center"}}>
      <SelectBox width='100%' 
      variant='outlined'
      label={"Common Size Base"} 
      options={metricAll} value={commonSizeMetric} onChange={(e,v)=>handleCommonSizeMetric(v)}/>
      </div>}


    </MetricSettingsBar>
     
      
    {metricModal && 
          <MetricStockFinancialCategory 
          onClose={()=>setMetricModal(false)}
          title={statementType}
          metricSelected={metricSelected}
          statementCategory={statementCategory}
          statementType={statementType} handleMetricChange={handleMetricSelected}/>}
    
    {chartSetting && 
    <PageSettingsModal onClose={()=>setChartSetting(false)} title='Display Settings'>
            {period!=="Multi" && <SelectBox 
            options={dates} value={singlePeriodDate} onChange={(e,v)=>handleSinglePeriodDate(v)} label={"Period"}/>}
            {period==="Multi" &&
            <div>
              {totalPeriods > 0 && (
                <PeriodSlider
                  max={totalPeriods}
                  min={1}
                  defaultValue={periodLen}
                  onChange={handlePeriodInputChange}
                  handleInputChange={handlePeriodInputChange}
                  reportMode={reportMode}
                />
              )}
            </div>}

            {period === "Multi" && <SelectBox 
            label={"Display Type"}
            options={["Chart & Table","Chart","Table"]} 
            value={displayType}
            onChange={(e,v)=>handleDisplayType(v)}
            ></SelectBox>}

            {period==="Multi" && ["Chart","Chart & Table"].includes(displayType) &&<SelectBox 
            label={"Chart Type"}
            value={chartType}
            onChange={(e,v)=>handleChartType(v)}
            options={["column","line","area","bar"]} />}


            
   </PageSettingsModal>}
  </>
  )
}


function SettingsMain({period}){

  const {dispatchStock,stateStock} = useAppInfo()
  const {peerMode,financialsSettings,metricSelectedCategories,coInfo} = stateStock
  const currencyCode = coInfo?.[dfin?.currencyCode]
  const currencySymbol = coInfo?.[dfin?.currencySymbol]
  const {reportMode,periodMode,periodLen,growthMode,chartType,singlePeriodDate,totalPeriods,} = financialsSettings

  function handleGrowth() {
    dispatchStock({"financialsSettings":{
        ...financialsSettings,
        growthMode: !growthMode
      }
    });
  }

  function handlePeerMode(){
    dispatchStock({"peerMode":!stateStock.peerMode})
  }

  
  function handleReportToggle() {
    dispatchStock({
     "financialsSettings":{
        ...financialsSettings,
        reportMode: !reportMode
      }
    });
  }

  return ( <div style={{marginLeft:"auto"}}>
    <div style={{display:"flex",gap:"1rem",padding:"0 0.5rem",alignItems:"center"}}>

    
  <div>
    <Toggle
      toggleMode={reportMode}
      handleToggle={handleReportToggle}
      toggleValues={["Annual", "Quarter"]}
    />
  </div>
  
  {period==="Multi" && 
  <div>
  <Toggle
    toggleMode={peerMode}
    handleToggle={handlePeerMode}
    toggleValues={["Company", "Peers"]}
  />
  </div>}
  
  {period==="Multi" &&
  <div>
    <Toggle
      toggleMode={growthMode}
      handleToggle={handleGrowth}
      toggleValues={["Trend", "Growth"]}
    />
  </div>}
  <Button variant='highlight'
    sx={{padding:"0.25rem",fontSize:"0.8rem"}}
    >Currency: {currencySymbol} {currencyCode}</Button>
    </div>
    </div>)
}
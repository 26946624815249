import { Avatar } from '@mui/material'
import React from 'react'
import { useAppInfo } from 'AppState'

export default function ProfileLogo({profileType="owner",imgSrc,displayName,showDisplayName=false}) {
  const {stateApp} = useAppInfo()

  return (
    <div style={{display:"flex",alignItems:"center",gap:"0.5rem"}}>
    {profileType === "owner" && 
    <Avatar 
    style={{height:"30px",width:"30px"}}>
    {(!stateApp.displayName) && "P"}
    {/* {(!stateApp.googlePhoto && stateApp.displayName) && stateApp.displayName?.split("")[0]} */}
    {stateApp.googlePhoto && <img src={stateApp.googlePhoto} 
    style={{objectFit:"cover"}} width={"100%"} height={"100%"}></img>}
    </Avatar>}
    {profileType !== "owner" && 
    <>
    <Avatar 
    style={{height:"30px",width:"30px"}}>
    {(!imgSrc && !displayName) && "P"}
    {(!imgSrc && displayName) && displayName?.split("")[0]}
    {imgSrc && 
    <img src={imgSrc} 
    style={{objectFit:"cover"}} width={"100%"} height={"100%"}></img>}
    </Avatar>
    </>
    }
    {showDisplayName && <h4 style={{fontWeight:"bold"}}>{profileType === "owner" ? stateApp.displayName : displayName}</h4>}
    </div>
  )
}

import { useAppInfo } from 'AppState'
import Notification from 'components/Notification'
import React from 'react'

export default function AppNotification() {
    const {stateApp,dispatchApp} = useAppInfo()
    const {notification} = stateApp

  return (
    <Notification autoHideDuration={1000*60} 
    message={notification?.message}
    open={notification?.open} 
    onClose={() => dispatchApp({"notification":{message:"",open:false}})}/>
  )
}

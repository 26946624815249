import React from 'react'
import useSecFilings from 'data/financialModelingPrep/News/useSecFilings'
import TableGen from 'components/Table/TableGen'

export default function ExchangeFilings() {
  const {secFilings} = useSecFilings()
  return (
    <>
    {secFilings.length>0 && 
    <TableGen data={secFilings}/>}
    </>
  )
}

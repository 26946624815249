import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useCompanyTranscripts(ticker,year=2024) {
  const [companyTranscripts, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v4/batch_earning_call_transcript/${ticker}?year=${year}&apikey=${financialModelingPrepApiKey}
          `
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker && year) {
      fetchData();
    }
  }, [ticker,year]);

  return { companyTranscripts };
}
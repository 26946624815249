
import { assetCode, dfin } from 'content/constants';
import React from 'react'
import { useAppInfo } from 'AppState';
import SelectBox from 'components/Inputs/SelectBox';

export default function PeerIn({sx={},width="100%"}) {
  const {stateWatchlist,statePortfolio,dispatchTicker,stateTicker} = useAppInfo()
  
  function handlePeersChange(v) {
    dispatchTicker({"peersIn":v})
  }
  
  let options = ["CUSTOM"]
  
  if (stateWatchlist.watchlistData){
    options = ["WATCHLIST",...options]
  }
  if (statePortfolio.portfolio){
    options = ["PORTFOLIO",...options]
  }

  if (stateTicker.tickerType===assetCode?.Stocks){
    options = ["INDUSTRY","SECTOR",dfin.mScale,...options]
  }
  if (stateTicker.tickerType!==assetCode?.Stocks){
    options = ["ASSET CLASS",...options]
  }

 
  
  return (
    
        <SelectBox
          variant='outlined'
          label={`${stateTicker.tickerSelected} Peers In`}
          value={stateTicker.peersIn}
          onChange={(e,v)=>handlePeersChange(v)}
          options={options}
          width={width}
          sx={{...sx}}
        >
        </SelectBox>

  )
}



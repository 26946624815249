import React from 'react'
import styles from "./PricingBox.module.css"


export default function PricingBox({info,onClick,value}) {
  const styling = {"flex":1.5,"border":"2px solid blue"}
  let buttonName = "Select Plan"
  if(info.Header ===value){
    buttonName="Selected Plan"
  }
  return (
    <div className={styles.card}  style={info.Header===value?styling:{}}>
    <div className={styles.cardContent}>
        <h2 style={{fontWeight:"bold"}}>{info.Header}</h2>
        <h4 style={{minHeight:"2.5rem"}}>{info.SubHeader}</h4>
        <h1 style={{marginTop:"1rem"}}>${info.PriceMonthly}/month</h1>
        <h3>${info.PriceAnnual}/year</h3>
        <button value={info.Header} onClick={onClick} className={styles.button}
        style={info.Header===value?{background:"var(--bgColor)",color:"white"}:{}}>
          {buttonName}
          </button>        

        <h4 style={{fontWeight:"bold",marginTop:"2rem"}}>Key Benefits:</h4>        
        
        <ul>
           {info.KeyBenefits.map(items=>
            <li>{items}</li>)}
        </ul>
    </div>
    </div>
  )
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useAddUpload = () => {
  const { dispatchApp,stateApp} = useAppInfo();
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);

  const addUpload = async (uploadName,uploadKey,uploadDetails) => {
    try {
      // Make use of setTimeout for a 3-second delay
      setTimeout(async () => {
        const response = await axios.post(`${BASE_URL}/addUploads/`, {
          userId,
          username,
          uploadData: uploadDetails,
          uploadName: uploadName,
          uploadKey
        });
        dispatchApp({'notification':{open:true,message:"Image Uploaded"}});
        setError('Success');
      }, 1000); // 3000 milliseconds = 3 seconds
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addUpload, error };
};

export default useAddUpload;
import { fetchEconInfo } from 'data/economy/useEconInfo'
import React from 'react'

export default function useAssistantEconomy() {
    
    async function GetEconOverview(){
        const econInfo = await fetchEconInfo()
      
        return econInfo
      }

    return {GetEconOverview}
}

import { Button } from '@mui/material';
import React, { useState } from 'react'
import TooltipInfo from '../Modal/TooltipInfo';

export default function CircularScore({score=0,colorScore,totalScore=0,title="Score",tooltipInfo}) {
    const strokeWidth = 15; // Fixed stroke width
    const viewBoxSize = 120; // ViewBox size which SVG uses to calculate relative positions
    const completionPercentage = (score/totalScore)*100
    const [hover,setHover] = useState(false)

    const colorGradient = {
        "Red": 0.3,
        "Yellow":0.5,
        "Green":0.7,
    }

  return (
    <div onMouseEnter={()=>setHover(true)} 
    onMouseLeave={()=>setHover(false)}
    style={{position:"relative"}}>
    <Button sx={{
        minWidth:"0",marginLeft:"auto",padding:0
        ,flexDirection:"column",textTransform:"none",color:"black"}}>
    <div style={{width:"25px",height:"24px"}}>
    <svg
    width="100%"
    height="100%"
    viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
    preserveAspectRatio="xMidYMid meet"
  
  >
    <circle
      cx={viewBoxSize / 2}
      cy={viewBoxSize / 2}
      r={(viewBoxSize - strokeWidth) / 2}
      fill="transparent"
      stroke="#F8F8F8"
      strokeWidth={strokeWidth}
    />
    <circle
      cx={viewBoxSize / 2}
      cy={viewBoxSize / 2}
      r={(viewBoxSize - strokeWidth) / 2}
      fill="transparent"
      stroke={completionPercentage<30 ? "red" : (completionPercentage>30 && completionPercentage<50) ? "orange":"green"}
      strokeWidth={strokeWidth}
      strokeDasharray={2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
      strokeDashoffset={(1 - completionPercentage / 100) * 2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
      transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
    />
    
    <text
      x="50%"
      y="50%"
      dy=".3em"
      textAnchor="middle"
      fontSize="2.5em"
      fill="#666"
    >
      {`${Math.round(completionPercentage,2)}%`}
    </text>
  </svg>
  </div>
  <span style={{ fontSize: "0.8rem" }}>{title}</span>
  </Button>
       {hover && (
        <TooltipInfo 
        width='300px'
        position="absolute" top="100%" 
        style={{zIndex:"10000"}}>
              {tooltipInfo}
        </TooltipInfo>
      )}
      </div>
  )
}

import { useAppInfo } from 'AppState'
import { defaultFieldBlank, expenseTypes } from 'appState/initialStateMyFinancials'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React from 'react'


export default function ExpenseFields({allocation=true,forecastPeriod=true}) {
    const {stateMyFinancials} = useAppInfo()
    const {expenseMonthly} = stateMyFinancials
  return (
    <FieldFormGen 
  defaultField={defaultFieldBlank.Expense} 
  showType
  typeOptions={expenseTypes}
  date={true}
  allocation={allocation} forecastPeriod={forecastPeriod} growthRate={forecastPeriod} period={true} 
  storeName={"expenseMonthly"} data={expenseMonthly} 
  formName='Expense' formHeading='Expense Category'/>
  )
}

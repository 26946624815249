import { dfin,growthMetric, technicalIndicators } from "./constants";



const qFinBalanced = {[growthMetric(dfin.revenue) ]:{filterType:"greaterThan",value:0.05},
[growthMetric(dfin.revenue,"1Y") ]:{filterType:"greaterThan",value:0.05},
[growthMetric(dfin.fcf) ]:{filterType:"greaterThan",value:0.05},
[dfin.ebitdaMargin]:{filterType:"greaterThan",value:0.20},
[dfin.netProfitMargin]:{filterType:"greaterThan",value:0.05},
[dfin.roic]:{filterType:"greaterThan",value:0.05},
[dfin.de]:{filterType:"lessThan",value:1.5},
[dfin.cr]:{filterType:"greaterThan",value:1.5}}

const qFinAggressive = 
{[growthMetric(dfin.revenue) ]:{filterType:"greaterThan",value:0.15},
[growthMetric(dfin.revenue,"1Y") ]:{filterType:"greaterThan",value:0.15},
[growthMetric(dfin.fcf) ]:{filterType:"greaterThan",value:0.10},
[dfin.ebitdaMargin]:{filterType:"greaterThan",value:0.30},
[dfin.netProfitMargin]:{filterType:"greaterThan",value:0.10},
[dfin.roic]:{filterType:"greaterThan",value:0.15},
[dfin.de]:{filterType:"lessThan",value:1.5},
[dfin.cr]:{filterType:"greaterThan",value:1.5}}

const quickScreenersCategories = [
  {Category:"Metrics",ScreenTypes:["Top Performance","Quality Financials (Balanced)",
  "Quality Financials (Aggressive)","Value","Growth","Dividend"]},
  {Category:"Market Cap Size",ScreenTypes:["Mega","Large","Mid","Small"]},
{Category:"Sector",ScreenTypes:["IT","HealthCare"]}
]



const quickScreeners = {
    "Top Performance":{[dfin.performance3M]:{filterType:"greaterThan",value:20},[dfin.performance1Y]:{filterType:"greaterThan",value:10}},
    "Quality Financials (Balanced)":qFinBalanced,
    "Quality Financials (Aggressive)":qFinAggressive,
    "Value":{[dfin.pe]:{filterType:"inBetween",value:[5,20]}},
    "Growth":{[dfin.pe]:{filterType:"inBetween",value:[5,20]}},
    "Dividend":{[dfin.dividendYield]:{filterType:"greaterThan",value:[5,20]}},
    };


const quickScreenersHome = {
      "Top Performance":{[dfin.performance3M]:{filterType:"greaterThan",value:20},[dfin.performance1Y]:{filterType:"greaterThan",value:10}},
      "Quality Financials (Balanced)":qFinBalanced,
      "Quality Financials (Aggressive)":qFinAggressive,
      "Value":{[dfin.pe]:{filterType:"inBetween",value:[5,20]}},
      "Growth":{[dfin.pe]:{filterType:"inBetween",value:[5,20]}},
      "Dividend":{[dfin.dividendYield]:{filterType:"greaterThan",value:[5,20]}},
      "Mega":{[dfin.mScale]:{filterType:"multiSelect",value:["Mega Cap"]}},
      "Mid":{[dfin.mScale]:{filterType:"multiSelect",value:["Mid Cap"]}},
      "Large":{[dfin.mScale]:{filterType:"multiSelect",value:["Large Cap"]}},
      "Small":{[dfin.mScale]:{filterType:"multiSelect",value:["Small Cap"]}},
      "IT":{[dfin.gicSector]:{filterType:"multiSelect",value:["Information Technology"]},[dfin.mScale]:{filterType:"multiSelect",value:["Mega Cap"]}},
      "Automobiles":{[dfin.gicIndustry]:{filterType:"multiSelect",value:["Automobiles"]}},
      };

 

  const quickScreenersDescription = {
    "Top Performance":["Perfromance greater than 10% YTD","Performance last 3 Months > 20%"],
    "Quality Financials (Balanced)":["Revenue Growth (1y & 5y) > 5% , EBITDA Margin > 20%, Net Income > 5%, Free Cash Flow > 0",
                          " Free Cashflow Growth (1y & 5y) > 5%", "ROIC > 8%", "Debt to Equity < 1, Current Ratio > 1.5"],
   "Quality Financials (Aggressive)":["Revenue Growth (1y & 5y) > 15% , Gross Profit Margin > 60%, EBITDA Margin > 40%, Net Income > 20%, Free Cash Flow > 0",
   " Free Cashflow Growth (1y & 5y) > 15%", "ROIC > 15%", "Debt to Equity < 1, Current Ratio > 1.5"],
    "Value":["PE Ratio in between 5 - 30"],
    "Growth":["Revenue Growth (1y & 5y) > 15%, Free Cashflow (1y & 5y) > 15% "],
    "Dividend":["Dividend Yield > 4%","Dividend Payout Ratio > 40%"],
    
  };

  // "IT":["IT Sector Mega Market Cap Scale Companies"],
  //   "Mega":["Mega Market Cap Size Companies"],
  //   "Large":["Mega Market Cap Size Companies"],
  //   "Mid":["Mid Market Cap Size Companies"],
  //   "Small":["Small Market Cap Size Companies"],
  //   "Healthcare":["Healthcare Sector"],
  //   "Automobiles":["Auto Industry Sector Mega Market Cap Scale Companies"]



  const primaryFilter = [dfin.gicSector,dfin.gicIndustry,dfin.country,dfin.exchange,dfin.marketCap,dfin.mScale]
  const financialFilter = [dfin.revenue,dfin.assets,
                         dfin.grossProfitMargin,
                         dfin.netProfitMargin,dfin.ebitdaMargin,dfin.ebitda,dfin.liability,dfin.equity,dfin.roic]
  const technicalFilter = [dfin.pe,dfin.ps,dfin.shortPercent]
  const valueFilter = [dfin.pe,dfin.ps,dfin.pb,dfin.ps]
  const screenFilters = {
      "Primary": primaryFilter,
      "Market Stats":[],
      "Financials":financialFilter,
      "Technicals":technicalFilter,
      "Value":valueFilter,
  }
  
  
  const pageColumns = {
    "Overview":[dfin.coLogo,dfin.ticker,dfin.coName,dfin.gicSector,dfin.gicIndustry,dfin.country,dfin.exchange,dfin.marketCap,dfin.mScale],
    "Financials":[dfin.coLogo,dfin.ticker,dfin.coName,dfin.revenue,dfin.assets,dfin.grossProfitMargin,dfin.netProfitMargin],
    "Technicals":[dfin.coLogo,dfin.ticker,dfin.coName,...technicalIndicators],
    "Value":[dfin.coLogo,dfin.ticker,dfin.coName,dfin.pe,dfin.ps,dfin.pb,dfin.evEbitda,dfin.evSales],
    "Performance":[dfin.coLogo,dfin.ticker,dfin.coName,dfin.performance1D,dfin.performance1M,dfin.performance3M,dfin.performance6M,dfin.performance1Y],
    // "Volatility":[dfin.ticker,dfin.coName,dfin.pe,dfin.ps,dfin.pb,dfin.evEbitda,dfin.evSales],
  }
  const scatterMetrics ={
    "Overview":[dfin.marketCap,dfin.pe],
    "Financials":[dfin.marketCap,dfin.revenue],
    "Technicals":[dfin.marketCap,dfin.pe],
    "Value":[dfin.marketCap,dfin.pe,],
    "Performance":[dfin.marketCap,dfin.pe,],
    // "Volatility":[dfin.marketCap,dfin.pe,],
  }  
export {quickScreenersDescription,quickScreeners,
  quickScreenersHome,
  primaryFilter,financialFilter,technicalFilter,valueFilter,screenFilters,pageColumns,scatterMetrics}
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Paper } from '@mui/material';

export default function SearchBar({onInput,placeholder="Search",children}) {
  return (
    <Paper elevation={0} 
    style={{display:"flex",gap:"0.5rem",padding:"0.5rem 0.5rem",alignItems:"center",borderRadius:"25px",background:"#fafafa"
    }}>
     <SearchIcon/>
     <>
    
    </>
    <div style={{flex:1,width:"100%",minWidth:0}}>
    <input type="search" placeholder={placeholder} 
    autoFocus 
    onInput={onInput}
    style={{width:"100%", fontSize:"1.3rem",height:"100%",border:"none",
    outline: 'none', 
    boxShadow: 'none',background:"none"}}/></div>
    
    {children}
  </Paper>
  )
}

import React, { useState } from 'react'
import { Button, Tooltip,Popover, IconButton, Typography } from '@mui/material';
import MetricStockModal from 'components/Template/MetricStockModal';
import AddIcon from '@mui/icons-material/Add';
import { dfin } from 'content/constants';
import { useAppInfo } from 'AppState';
import ExcelDownloadButton from 'components/Buttons/ExcelDownloadButton';
import { List } from '@mui/icons-material';
import CrudModal from 'components/Modal/CrudModal';

export default function ScreenerTableSettings() {

    const {stateScreener,dispatchScreener} = useAppInfo()
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'tablse-screener-settings' : undefined;
  
    const [addTabs, setAddTabs] = useState(false);
    const [addColumns, setAddColumns] = useState(false);
  
    function handleAddTabs() {
      setAddTabs(!addTabs);
    }
      
  
    function handleAddColumns() {
      setAddColumns(!addColumns);
    }
  
  
    function handleAddTabsUpdate(tabName) {
      const pageColumns = { ...stateScreener.pageColumns };
      const pageScatter = { ...stateScreener.scatterMetrics };
  
      pageColumns[tabName] = [dfin.ticker, dfin.coName];
      pageScatter[tabName] = [dfin.marketCap, dfin.pe];
  
      dispatchScreener({
        pageColumns: pageColumns,
        scatterMetrics: pageScatter
      });
  
      handleAddTabs();
    }
  
    
    function handleAddColumnUpdate(v) {
      const columns = stateScreener?.columns;
      const pageColumns = { ...stateScreener.pageColumns };
      let updatedColumns = [];
      if (columns.includes(v)) {
        updatedColumns = columns.filter(column => column !== v);
      } else {
        updatedColumns = [...columns, v];
      }
  
      dispatchScreener({
        columns: updatedColumns,
        pageColumns: {
          ...pageColumns,
          [stateScreener.pageHeaderSel]: updatedColumns
        }
      });
    }

    return (
      <>
  
        <Button aria-describedby={id} onMouseEnter={handleClick} onClick={handleClick}>
          <List/>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onMouseLeave={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", padding: "1rem" }}>
            <Button  
            sx={{justifyContent:"flex-start"}}
            startIcon={<AddIcon fontSize='small' />} onClick={handleAddTabs}>
              Add Tabs
            </Button>
            <Button 
             sx={{justifyContent:"flex-start"}}
            startIcon={<AddIcon fontSize='small' />} onClick={handleAddColumns}>
              Add Columns
            </Button>
            <ExcelDownloadButton wbName={"TS Screener Stocks"} data={stateScreener?.filterData} />
          </div>
        </Popover>
  
        {addColumns && (
          <MetricStockModal
            modalName={"Stock Screener Columns"}
            txtCols={true}
            metricSelected={stateScreener?.columns}
            handleModalClose={handleAddColumns}
            checkbox={true}
            handleMetricSelected={handleAddColumnUpdate}
          />
        )}
  
        {addTabs && (
          <CrudModal title={"Tabs"} 
          placeholder={"Add a tab name"}
          onClose={handleAddTabs}
          handleCreate={handleAddTabsUpdate}
          />
        )}
      </>
    );
  }
  
  
  
import React from 'react'
import useMAfeed from 'data/financialModelingPrep/News/useMAfeed'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import TableGen from 'components/Table/TableGen'

export default function MergerAcquisition() {
  const {maFeed} = useMAfeed()
  const tableData = maFeed.length>0 && getSpecificArrayObjKeys(maFeed,cols)
  return (
    <>
    {maFeed.length>0 &&
    <TableGen tableLayout='fixed' data={tableData}/>}
    </>
  )
}


const cols = [,"companyName","symbol","targetedSymbol","targetedCompanyName","acceptanceTime","transactionDate","url"]

import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import MyFinancialSteps from 'myFinancials/Template/MyFinancialSteps'
import React from 'react'

export default function AssetStepsToGrow() {
  return (
   <div>
    <h3 style={{padding:"1rem"}}>Steps to grow your Assets</h3>
    <div style={{display:"flex",gap:"0.5rem",flexDirection:"column",maxWidth:"700px",flex:1}}>
    <AccordionTSCustom title={"1. Allocate Net Cash periodically to build long-term assets"}></AccordionTSCustom>
    <AccordionTSCustom title={"2. Reinvest returns on savings & investments"}></AccordionTSCustom>
    <AccordionTSCustom  title={"3. Allocate Assets based on risk tolerance, investment preference and goals"}></AccordionTSCustom>
    <AccordionTSCustom  title={"4. Build & Manage Portfolio"}></AccordionTSCustom>
    <AccordionTSCustom  title={"5. Rebalance periodically to Target Allocation"}></AccordionTSCustom>
    <AccordionTSCustom title={"6. Find Opportunities to grow your Portfolio"}></AccordionTSCustom>
    </div>
  </div>
  )
}
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import SidebarContentTemplate from './SidebarContentTemplate'
import { dfin } from 'content/constants'
import useIconsReportBuilder from 'data/images/useIconsReportBuilder'

export default function Icons() {
    const {stateReportBuilder,dispatchReportBuilder,stateStock} = useAppInfo()
    useIconsReportBuilder()
    const content = (stateReportBuilder.icons);


    useEffect(() => {
        let imgDataUpdate = []
        let coLogos = []
        let otherIcons = []
        if (stateStock.peerInfo !== null){
       
        const imgData = stateStock.peerInfo.map(obj => ({
          Category: obj[dfin.ticker]===stateStock.companySelected?"Company Logo":"Other Company Logos",
          Type: "Images",
          id:obj[dfin.coLogo],
          ContentType:obj[dfin.ticker]===stateStock.companySelected?"Company Logo":"Other Company Logos",
          style:{height:"50px",width:"50px"},
          ImgUrl: obj[dfin.coLogo]
        }));
    
        // Filter out duplicate entries based on ImgUrl
        coLogos = imgData.filter(img => !content.some(item => item.ImgUrl === img.ImgUrl));
    
       }


        if (content.length > 0) {
          const imgData = content.map(obj => ({
            Category: "Icons",
            Type: "Images",
            ContentType: "Icons",
            id: obj.attribution,
            style:{height:"50px",width:"50px"},
            ImgUrl: obj.thumbnail_url
          }));
      
          // Filter out duplicate entries based on ImgUrl
          otherIcons = imgData.filter(img => !content.some(item => item.ImgUrl === img.ImgUrl));
        }
        
        imgDataUpdate= [...coLogos,...content,...otherIcons];
        
        dispatchReportBuilder({"icons":imgDataUpdate });
    }, [stateStock.peerInfo,content]);

  
  return (
    <>
        
     <SidebarContentTemplate content={content}/>

    </>
  )
}


import React, { useState } from 'react'
import Modal from 'components/Modal/Modal'


export default function SettingsSidebar() {
    
  const [settingsOpen,setSettingsOpen] = useState(true)
  function handleCloseModal(){
    setSettingsOpen(!settingsOpen)
  }
  
  return (
    <>
    {settingsOpen && 
    <Modal onClose={handleCloseModal}>

    </Modal>}
    </>
  )
}

import React, { useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { dfin } from 'content/constants';
import { useAppInfo } from 'AppState';

export default function useMetricInfo() {
    const {dispatchStock, stateStock} = useAppInfo()
    useEffect(() => {
        fetchMetrics();
    }, []);

    async function fetchMetrics() {
      const metricFinancials = await getMetricInfo();
      const metricScreener = await getMetricScreenerInfo();

      if (metricFinancials && metricScreener) {
          const metrics = {
              ...stateStock.metrics,
              ...metricScreener,
              ...metricFinancials
          };
          dispatchStock({"metrics":metrics });
      }
  }
    // GET COMPANY DATA
    async function getMetricInfo() {
        try {
            const response = await axios.get(`${BASE_URL}/metricInfo/`);
            const metricFinancialInfo = JSON.parse(response.data);

            const isMetrics = metricFinancialInfo
                .filter(obj => obj[dfin.statement] === dfin.incomeStatement)
                .map(obj => obj["Metric"]);

            const bsMetrics = metricFinancialInfo
                .filter(obj => obj[dfin.statement] === dfin.balanceSheet)
                .map(obj => obj["Metric"]);

            const cfMetrics = metricFinancialInfo
                .filter(obj => obj[dfin.statement] === dfin.cashFlow)
                .map(obj => obj["Metric"]);

            const ratioMetrics = metricFinancialInfo
                .filter(obj => obj[dfin.statement] === dfin.ratio)
                .map(obj => obj["Metric"]);

            const statementMetrics = {
                IS: isMetrics,
                BS: bsMetrics,
                CF: cfMetrics,
                Ratio: ratioMetrics
            };

            return { metricFinancials: metricFinancialInfo, metricFinancialStatement: statementMetrics };
        } catch (error) {
            console.error(error);
            return {};
        }
    }

    async function getMetricScreenerInfo() {
        try {
            const response = await axios.get(`${BASE_URL}/metricScreenerInfo/`);
            const metricScreener = JSON.parse(response.data);

            const numCols = metricScreener
                .filter(item => item["Metric Type"] === "numeric")
                .map(item => item["Metric"]);

            const txtCols = metricScreener
                .filter(item => item["Metric Type"] === "descriptive")
                .map(item => item["Metric"]);

            const growthCols = numCols.filter(item => /GROWTH/.test(item));
            const avgCols = numCols.filter(item => /AVERAGE/.test(item));
            // const avgCols = numCols.filter(item => item.includes("AVERAGE"));
            const scoreCols = numCols.filter(item => item.includes("RATING"));

            return { metricScreener, numCols, txtCols, avgCols, growthCols, scoreCols };
        } catch (error) {
            console.error(error);
            return {};
        }
    }
}

import React from 'react';
import SelectBox from 'components/Inputs/SelectBox';
import TableGen from 'components/Table/TableGen';
import { LinearProgress } from '@mui/material';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';

export default function PerformanceMulti({perfMultiTable,perfLoading,
  handleDateFreq,handlePeriod,period,dateFrequency,tickerDict}) {


  const periodOptions= Array.from({ length: 10 }, (_, index) => index + 1);
  const tableFilter = (<div style={{display:"flex", gap:"1rem"}}>
  <div><SelectBox variant='standard' options={["Yearly","Monthly","Quarterly",]} id={"DateFreq Type"} value={dateFrequency} onChange={handleDateFreq} label={"Date Frequency"}/>
</div>
<div><SelectBox variant='standard' options={periodOptions} id={"Period Type"} value={period} onChange={handlePeriod} label={"Years"}/>
</div>
</div>)

  return (
    <div>
      {(!perfMultiTable.length > 0 || perfLoading) && <LoadingSkeletonTable/>}
      {(perfMultiTable.length > 0 && !perfLoading) && (<TableGen 
      tickerDict={tickerDict}
      data={perfMultiTable} color='redGreen' 
      tableHeader={`Performance: ${dateFrequency} mean for ${period} years`}
      tableFilter={tableFilter} tableLayout='fixed'/>)}
    </div>
  );
}

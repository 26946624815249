
import { useEffect, useState } from "react";
import { financialModelingPrepApiKey } from "data/keys";
import axios from 'axios';

export default function useSecFilings() {
    const [secFilings, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(
            `https://financialmodelingprep.com/api/v4/rss_feed?limit=100&type=10&from=2024-01-01&to=2024-04-01&isDone=true&apikey=${financialModelingPrepApiKey}
            `
          );
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
        finally{ 
        setLoading(false)
        }
      };
  
     fetchData();
    }, []);
  
    return { secFilings };
  }
import { useAppInfo } from 'AppState';
import { useEffect } from 'react';


const useKeyboardShortcuts = () => {
  const {dispatchSearch,stateSearch} = useAppInfo()
  useEffect(() => {
    function handleCtrlKKeyPress(event) {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.key.toLowerCase() === ('k' || 'f')
      ) {
        event.preventDefault();
        dispatchSearch({"searchClick":true});
      }
    }

    document.addEventListener('keydown', handleCtrlKKeyPress);
    // document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleCtrlKKeyPress);
      // document.removeEventListener('keydown', handleKeyDown);
    };
  }, [stateSearch.searchClick]);
};

export default useKeyboardShortcuts;
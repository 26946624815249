import React from 'react'
import useHandleUserEvents from './useHandleUserEvents';
import { Button } from '@mui/material';

export default function DefaultMessageBox () {
    

    const defaultMessages = [
        "How am I doing financially?",
        "Can I afford a house worth $1 million?",
        "Am I beating the market?",
        "Find opportunities that align with my portfolio strategy",
    ];

    const { handleMessage, handleSend } = useHandleUserEvents();

  
    return (
        <
        >
            {defaultMessages.map((obj, index) => (
                <React.Fragment key={index}>
                    <Button 
                        onClick={() =>{handleSend(obj)}} 
                        style={{
                            background: "white",
                            width: "200px",
                            borderRadius: "10px",
                            padding: "1rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // background: "linear-gradient(135deg, #ffffff, #f0f0f0)",  // Lighter gradient
                            transition: "box-shadow 0.3s ease, transform 0.3s ease",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            // minHeight:"100px",
                            verticalAlign:"top",
                            cursor:"pointer",fontWeight:"bold"
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.2)";
                            e.target.style.transform = "translateY(-5px)";
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
                            e.target.style.transform = "translateY(0)";
                        }}
                    >
                        {obj}
                    </Button>
                </React.Fragment>
            ))}
        </>
    );
}

import React from "react";
import "./CompanyProfile.css";
import { useAppInfo } from "AppState";
import CompanyFinancialScore from "./CompanyFinancialScore";
import CompanyTechnicalScore from "./CompanyTechnicalScore";
import CompanyValueScore from "./CompanyValueScore";
import { dfin } from "content/constants";
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import useCompanyNews from "data/company/useCompanyNews";
import usePriceChartEod from "data/eod/usePriceChartEod";
import { formatNumber, numberDecimal } from 'utils/utilGeneral';
import { useNavigate } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Button } from "@mui/material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import TableGen from "components/Table/TableGen";
import LoadingSkeletonLine from "components/Loading/LoadingSkeletonLine";
import CompanyESGScoreBar from "./CompanyESGScoreBar";
import TimeSeriesChart from "components/Charts/TimeSeriesChart";
import useCompanyVsIndustry from "data/company/useCompanyVsIndustry";
import CompanyComments from "./CompanyComments";

export default function CompanyProfile() {
  const { stateTicker, stateStock,dispatchIndustry,dispatchTicker,dispatchMarket} = useAppInfo();
  
  const navigate = useNavigate()
  const {tickerStock} = stateTicker
  const {coInfo} = stateStock;
  const { priceInfo, loadingPriceInfo } = usePriceChartEod({
    tickerSel: stateTicker.tickerStockCode,
  });

  const { newsCo, newCoLoading } = useCompanyNews(stateStock.companySelected);


  const primaryInfo = [
    { name: "Ticker", value: coInfo?.[dfin.ticker] },
    { name: "Name", value: coInfo?.[dfin.coName] },
    { name: "Website", value: coInfo?.[dfin.coWebsite] },
    { name: "Exchange", value: coInfo?.[dfin.exchange] },
    { name: "Country", value: coInfo?.[dfin.country] },
    { name: "Sector", value: coInfo?.[dfin.gicSector] },
    { name: "Industry", value: coInfo?.[dfin.gicIndustry] },
    { name: "Market Cap", value: formatNumber(coInfo?.[dfin.marketCap]) },
    { name: "Market Scale", value: coInfo?.[dfin.mScale] },
    { name: "PE", value: numberDecimal(coInfo?.[dfin.pe]) },
    { name: "Dividend Yield", value: `${numberDecimal(coInfo?.[dfin.dividendYield])}%` },
    { name: "Beta", value: numberDecimal(coInfo?.[dfin.beta]) },
    { name: "Net Profit Margin", value: `${numberDecimal(coInfo?.[dfin.netProfitMargin]) * 100}%` },
    { name: "Net Profit", value: formatNumber(coInfo?.[dfin.netIncome]) },
    { name: "Ebitda", value: formatNumber(coInfo?.[dfin.ebitda]) },
    { name: "ROIC", value: `${numberDecimal(numberDecimal(coInfo?.[dfin.roic])) * 100}%` },
    // Add more metrics here as needed
];
  function handleLinkInternal(n, v) {
    let industrySel = coInfo?.[dfin.gicIndustry];
    let sectorSel = coInfo?.[dfin.gicSector];
    let indSecMode = false
    if (n === "Industry") {
      indSecMode =true
    }
    dispatchIndustry({
      industrySel: [industrySel],
      sectorSel: [sectorSel],
      menuSel: "Performance",
      countrySel: [coInfo?.[dfin.country]],
      indSecMode: indSecMode
    });
    navigate("/Analytics/Industry");
  }
  function handleNews() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "News & Events",
      subMenuSel: "News"
    });
  }
  
  function handleStatsIndustry() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Stats",
      subMenuSel: "vsIndustry"
    });
  }
  
  function handleStatsPeers() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Stats",
      subMenuSel: "vs Peers"
    });
  }
  
  function handleChart() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Chart",
      subMenuSel: "Techncial Chart"
    });
  }
  
  function handleAssetClass() {
    navigate("/Analytics/Market");
    dispatchMarket({ assetClassSel: "Stocks" });
  }
  
  function handlePeers() {
    navigate("/Analytics/Ticker");
    dispatchTicker({
      menuSel: "Peers",
      subMenuSel: "Peer Profile"
    });
  }

  return (

      
      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          height:"100%",
          gap: "0.5rem",
          boxSizing: "border-box",
          flexWrap:"wrap",padding:"0.25rem"
        }}
      >
        <div
          style={{
            borderRadius: "5px",
            width: "50%",
            minWidth:"300px",
            boxSizing: "border-box",
            flex:1
          }}
        >
          <>
          <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:"350px"
          ,height:"400px",maxHeight:"400px",border:"1px solid #F8F8F8",padding:"0.2rem"
          ,boxSizing:"border-box",background:"#F8F8F8",borderRadius:"5px",position:"relative"}}>
          <div style={{flex:1,width:"100%"}}>
          {!priceInfo.length > 0 && loadingPriceInfo && <LoadingSkeletonLine variant="rectangle" width="100%" height="100%"/>}
          {priceInfo.length > 0 && !loadingPriceInfo && (
              <TimeSeriesChart
                datePicker={false}
                chartType="line"
                seriesName="adjusted_close"
                data={getSpecificArrayObjKeys(priceInfo, [
                  "date",
                  "adjusted_close",
                ])}
              />
            )}
          </div>
          <div style={{float:"right",marginLeft:"auto",position:"absolute",right:0}}><Button sx={{minWidth:"0"}} onClick={handleChart}><FullscreenIcon/></Button></div>
          </div>
          <>
          
        </>
        <div style={{ width: "100%", position: "relative",marginTop:"0.5rem"}}>
        <TableGen tHead={false} tableLayout="auto" data={primaryInfo} pagination={false}/>
      </div>
        </>

        
        </div>

             <div
          style={{
            minWidth:"40%",
            flex:1,
            flexDirection: "column",
            display: "flex",
            gap: "0.25rem",
            boxSizing: "border-box",
          }}
        >

<div
          style={{
            display: "flex",
            flexDirection:"column",
            maxWidth: "100%",
            gap: "0.5rem",
            boxSizing: "border-box",
          }}
        >
          <CompanyComments tickerList={[tickerStock]}/>
          <CompanyFinancialScore coInfo={coInfo}/>
          <CompanyTechnicalScore coInfo={coInfo}/>
          <CompanyValueScore coInfo={coInfo}/>
          <CompanyESGScoreBar coInfo={coInfo}/>
        </div>
 
      <div style={{display:"flex",flexDirection:"column"
      ,alignItems:"flex-start",width:"100%",
      borderRadius: "5px",
      padding: "0.25rem",
      border: "2px solid #F8F8F8",
      boxSizing:"border-box",}}>
         {/* <Button sx={{width:"100%"}} onClick={handleStatsIndustry}>Stats vs Industry <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={handleStatsPeers}>Stats vs Peers <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button> */}
<Button sx={{width:"100%"}} onClick={handlePeers}>{coInfo?.[dfin.ticker]} Peers <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={() => handleLinkInternal("Industry")}>{coInfo?.[dfin.ticker]} Industry <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={() => handleLinkInternal("Sector")}>{coInfo?.[dfin.ticker]} Sector <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
<Button sx={{width:"100%"}} onClick={handleAssetClass}>{coInfo?.[dfin.ticker]} Asset Class: Stocks <ArrowOutwardIcon style={{ marginLeft: 'auto' }}/></Button>
        </div>
 
          <div
          style={{
            width: "100%",
            borderRadius: "5px",
            padding: "0.25rem",
            border: "2px solid #F8F8F8",
            boxSizing:"border-box",
           flex:1,
           overflow:"auto"
          }}
        >
          
            <Button onClick={handleNews} sx={{width:"100%"}}>
            News/Events
            <ArrowOutwardIcon  style={{ marginLeft: 'auto' }}/></Button>
          {!newCoLoading &&
            newsCo.map((obj) => (
              <div key={obj.title} style={{ padding: "0.5rem" }}>
                <a
                  style={{ textDecoration: "none" }}
                  key={obj.title}
                  href={obj.link}
                  target="blank"
                >
                  {obj.title}
                </a>
              </div>
            ))}
        </div>
        </div>
        


        
      </div>
  );
}

import React, { useEffect, useState } from 'react';
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Skeleton } from '@mui/material';
import Animation from 'animation/Animation';

require("highcharts/modules/accessibility")(Highcharts);




const dummyOptions = {
  column: {
    chart: { type: 'column' },
    title: { text: '' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: '' } },
    series: [{ name: 'Column Series', data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4] }]
  },
  line: {
    chart: { type: 'line' },
    title: { text: '' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: '' } },
    series: [{ name: 'Line Series', data: [34.9, 59.5, 98.4, 120.2, 139.0, 168.0, 125.6, 142.5, 204.4, 180.1, 89.6, 48.4] }]
  },
  multiline: {
    chart: { type: 'line' },
    title: { text: '' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: '' } },
    series: [
      { name: 'Series 1', data: [34.9, 59.5, 98.4, 120.2, 139.0, 168.0, 125.6, 142.5, 204.4, 180.1, 89.6, 48.4] },
      { name: 'Series 2', data: [24.9, 49.5, 88.4, 110.2, 129.0, 158.0, 115.6, 132.5, 194.4, 170.1, 79.6, 38.4] }
    ]
  },
  area: {
    chart: { type: 'area' },
    title: { text: '' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: '' } },
    series: [{ name: 'Area Series', data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4] }]
  },
  bar: {
    chart: { type: 'bar' },
    title: { text: '' },
    xAxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
    yAxis: { title: { text: '' } },
    series: [{ name: 'Bar Series', data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4] }]
  },
  pie: {
    chart: { type: 'pie' },
    title: { text: '' },
    series: [{ name: 'Pie Series', data: [{ name: 'Jan', y: 29.9 }, { name: 'Feb', y: 71.5 }, { name: 'Mar', y: 106.4 }, { name: 'Apr', y: 129.2 }, { name: 'May', y: 144.0 }, { name: 'Jun', y: 176.0 }] }]
  },
  stock: {
    chart:{animation:false},
    title: { text: '' },
    series: [{
      name: 'Stock Series',
      data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 216.4, 300,350],
      tooltip: { valueDecimals: 2 }
    }]
  }
};

export default function LoadingSkeletonChart({ width = '100%', height = '100%', chartType = 'stock' }) {
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setTimer(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ width, height,boxSizing:"border-box",overflow:"hidden", 
    position: 'relative', display: 'flex', justifyContent: 'center',}}>
      {timer && (
        <div style={{ width: '40%', height: '40%',padding:"2rem"}}>
        <Animation type='Chart' />
      </div>
      )}

      {/* {!timer && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.025)', backdropFilter: 'blur(3px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Skeleton variant="rectangular" width="90%" height="90%" sx={{ background: 'f7f7f7' }} />
        </div>
      )} */}
    </div>
  );
}

 {/* <HighchartsReact
          highcharts={Highcharts}
          constructorType={chartType==="stocks"&&"stockChart"}
          options={dummyOptions[chartType]}
          containerProps={{ style: { height: '100%',width:"100%"} }}
        /> */}
import React, { useEffect } from 'react'
import useAddWatchlist from './useAddWatchlist'
import { useAppInfo } from 'AppState'

export default function useAddDefaultWatchlist() {
    const {stateWatchlist,stateApp,dispatchWatchlist} = useAppInfo()
    const {addWatchlist} = useAddWatchlist("Default")
    const {loadingWatchlist,watchlistData} = stateWatchlist

    useEffect(()=>{
      if (stateApp.initialized && loadingWatchlist && Object.keys(watchlistData).length===0){
        const defaultTickers = ["AAPL","MSFT","GOOG","AMZN","NVDA","META","TSLA","WMT","BTC","SPY"]
        addWatchlist(defaultTickers)
        const wlData = {"Default":defaultTickers};
       
        const wlName = Object.keys(wlData);
        dispatchWatchlist({
          watchlistData: wlData,
          watchlistSelected: wlName[0],
      });
      }
      }
    ,[watchlistData,stateApp.initialized,loadingWatchlist])
    
}

import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { useAppInfo } from 'AppState';

export default function useUpdateOnboarding() {
    const {dispatchApp,stateApp} = useAppInfo()
    

    async function UpdateOnboarding(username,data) {

        try {
          const response = await axios.post(`${BASE_URL}/addOnboarding/`, {
            username: username,
            userId: stateApp.userId,
            onboarding: data
          });
          dispatchApp({
            onboarding: data,
            username: username,
            displayName: username
          });
          return response.data; // Return the response data if needed
        } catch (error) {
          console.error("Error updating onboarding status: ", error);
          return error; // Return the error object for further handling if needed
        }
      }
    
    return UpdateOnboarding
    
}

import React, { useState } from 'react';
import "./FinancialCalculator.css";
import { Button } from '@mui/material';

export default function FinancialCalculator() {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');

  const handleClick = (value) => {
    if (isOperator(value) && isOperator(input.slice(-1)) && input.slice(-1) !== value) {
      setInput(input.slice(0, -1) + value);
    } else {
      if (value === '%') {
        setInput(`${input}/100`);
      } else {
        setInput(input + value);
      }
    }
  };

  const isOperator = (ch) => ['+', '-', '*', '/', '%', '^', 'Math.sqrt('].includes(ch);

  const handleParenthesis = (value) => {
    if (value === '(' && /\d$/.test(input)) {
      setInput(input + '*(');
    } else {
      setInput(input + value);
    }
  };

  const calculateResult = () => {
    try {
      setResult(eval(input));
      setInput(eval(input).toString());
    } catch (error) {
      setResult('Error');
      setInput('Error');
    }
  };

  const handleClear = () => {
    setInput('');
    setResult('');
  };

  return (
    <div className="financial-calculator">
      <div className="display">{input || 0}</div>
      <div className="display" style={{height:"15px"}}>{result}</div>

      <div className="keypad">
        <div className="numbers">
          {Array.from({ length: 9 }, (_, i) => (
            <Button key={i+1} onClick={() => handleClick((i+1).toString())}>{i+1}</Button>
          ))}
          <Button onClick={() => handleClick('0')}>0</Button>
          <Button onClick={() => handleClick('.')}>.</Button>
        </div>
        <div className='commonOperations'>
          <Button onClick={() => handleClick('%')}>%</Button>
          <Button onClick={() => handleClick('Math.sqrt(')}>√</Button>
          <Button onClick={() => handleClick('**')}>^</Button>
          <Button onClick={() => handleClick('1/')}>1/x</Button>
          <Button onClick={() => handleParenthesis('(')}>(</Button>
        </div>
        <div className='commonOperations'>
          <Button onClick={() => handleClick('/')}>/</Button>
          <Button onClick={() => handleClick('*')}>x</Button>
          <Button onClick={() => handleClick('-')}>-</Button>
          <Button onClick={() => handleClick('+')}>+</Button>
          
        <Button onClick={() => handleParenthesis(')')}>)</Button>
        </div>
      </div>
      <div className='submit'>
        <Button onClick={handleClear}>Clear</Button>
        <Button onClick={calculateResult}>=</Button>
      </div>
    </div>
  );
}

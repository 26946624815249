import React, { useState } from "react";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';


const FullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const exitFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      setIsFullScreen(true);
    } else {
      exitFullScreen.call(doc);
      setIsFullScreen(false);
    }
  };

  return (
    <>
       {!isFullScreen && <FullscreenIcon sx={{fontSize:"1.7rem"}} onClick={toggleFullScreen}/>}
       {isFullScreen && <FullscreenExitIcon sx={{fontSize:"1.7rem"}} onClick={toggleFullScreen}/>}
    </>  
  );
};

export default FullScreen;

/* {isFullScreen ? (<FullscreenExitIcon/>): (<FullscreenIcon/>)} */
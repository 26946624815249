import React, { useState } from 'react'
import { Info } from '@mui/icons-material'
import { Alert, Button, Icon, Popover } from '@mui/material'

export default function PopoverInfo({title,children,color="gray",sxBtn={}}){
    
        
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'metric-settings' : undefined;
  
    return ( 
      <>
      <Button sx={{color:color?color:"var(--selectBlue)",minWidth:0,padding:"0.25rem",...sxBtn}} aria-describedby={id} onClick={handleClick}>
      <Info fontSize='small'/>
    </Button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onMouseLeave={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
    <div style=
    {{ display: "flex", flexDirection: "column", gap: "1rem", 
    padding: "0.5rem",background:"var(--colorInformation)",maxWidth:"300px",overflow:"auto"}}>
        <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
          <Icon/> <h3>{title}</h3></div>
        
        {children}
        
      </div>
    </Popover>
    </>)
  }
import React from 'react';
import { useAppInfo } from 'AppState';
import { formatNumber } from "utils/utilGeneral";
import { menuIcons } from 'nav/menuIcons';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const InfoLine = ({link,icon, label, value,currency="USD", isCurrency = false, isSuccess = null,isPercentage=false}) => {
  
  const navigate = useNavigate()
  let formattedValue = isCurrency ? formatNumber(value) : value;
  if (isPercentage){
  formattedValue = `${formatNumber(value)}%`
}
  const color = isSuccess !== null ? (isSuccess ? 'green' : 'red') : 'black';
  return (
    <Button onClick={()=>navigate(`/MyFinancials/${link}`)} 
    style={{ display: 'flex', justifyContent: 'space-between',width:"100%"
    ,padding:"0.05rem",color:"black"}}>
      <span style={{display:"flex"}}>{label}:</span>
      <span style={{ color }}>{isCurrency && currency} {formattedValue}</span>
      
    </Button>
  );
};

export default function MyFinancialsOverviewHome() {
  const { stateMyFinancials } = useAppInfo();
  const { summaryStats, goalAnalytics, warnings,currency,financialHealthScore} = stateMyFinancials;

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.25rem"}}>
      <InfoLine link={"Networth"} currency={currency?.symbol} label="Net Worth" value={summaryStats?.netWorth} isSuccess={summaryStats?.netWorth > 0} isCurrency />
      <InfoLine link={"Assets"} currency={currency?.symbol} label="Assets" value={summaryStats?.totalAssets} isSuccess={true} isCurrency />
      <InfoLine link={"Liabilities"} currency={currency?.symbol} label="Liabilities" value={summaryStats?.totalLiabilities} isSuccess={false} isCurrency />
      <InfoLine link={"FinancialHealth"} icon={menuIcons?.Health} 
      label="Financial Health"
      value={financialHealthScore} isPercentage 
      isSuccess={financialHealthScore > 60} 
      />
      <InfoLine link={"Goals"} icon={menuIcons?.Goals} 
      currency={currency?.symbol} label="Goals Success" 
      isPercentage
      value={goalAnalytics?.goalSuccessRateBeforeWithdrawal} 
      isSuccess={goalAnalytics?.goalSuccessRateBeforeWithdrawal > 80} />
    </div>
  );
}

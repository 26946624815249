
const sectorDict = {
    'XLE': 'Energy',
    'XLY': 'Consumer Discretionary',
    'XLI': 'Industrials',
    'XLF': 'Financials',
    'XLU': 'Utilities',
    'XLV': 'Health Care',
    'XLP': 'Consumer Staples',
    'XLC': 'Communication Services',
    'XLB': 'Materials',
    'XLRE': 'Real Estate',
    'XLK': 'Information Technology',
    'SPY': 'SPY'
  };

const commodities = {   
    "GC=F": "Gold",
    "SI=F": "Silver",
    "ALI=F":"Aluminium",
    "PL=F": "Platinum",
    "HG=F": "Copper",
    // "PA=F": "Palladium",
    "CL=F": "Crude Oil",
    "HO=F": "Heating Oil",
    "NG=F": "Natural Gas",
    "RB=F": "RBOB Gasoline",
    "BZ=F": "Brent Crude Oil",
    "ZC=F": "Corn Futures",
    "ZO=F": "Oat Futures",
    "KE=F": "KC HRW Wheat Futures",
    "ZR=F": "Rough Rice Futures",
    "ZM=F": "S&P Composite 1500 ESG Tilted I",
    "ZL=F": "Soybean Oil Futures",
    "ZS=F": "Soybean Futures",
    "GF=F": "WisdomTree International High D",
    "HE=F": "Lean Hogs Futures",
    "LE=F": "Live Cattle Futures",
    "CC=F": "Cocoa",
    "KC=F": "Coffee",
    "CT=F": "Cotton",
    "LBS=F": "Random Length Lumber Futures",
    "OJ=F": "Orange Juice",
    "SB=F": "Sugar #11",
    "GD=F":"S&P-GSCI Commodity Index"
    }
   
    const metals = {
        "GC=F": "Gold",
        "SI=F": "Silver",
        "ALI=F":"Aluminium",
        "PL=F": "Platinum",
        "HG=F": "Copper",
        "PA=F": "Palladium",
    }

    const energy = {
        "CL=F": "Crude Oil",
        "BZ=F": "Brent Crude Oil",
    "HO=F": "Heating Oil",
    "NG=F": "Natural Gas",
    "RB=F": "RBOB Gasoline",
    
    }
    

    const agri = {
        "ZC=F": "Corn Futures",
        "ZO=F": "Oat Futures",
        "KE=F": "KC HRW Wheat Futures",
        "ZR=F": "Rough Rice Futures",
        "ZM=F": "S&P Composite 1500 ESG Tilted I",
        "ZL=F": "Soybean Oil Futures",
        "ZS=F": "Soybean Futures",
        "GF=F": "WisdomTree International High D",
        "HE=F": "Lean Hogs Futures",
        "LE=F": "Live Cattle Futures",
        "CT=F": "Cotton",
        "LBS=F": "Random Length Lumber Futures",
        "SB=F": "Sugar #11",
        "CC=F": "Cocoa",
        "KC=F": "Coffee",
        "OJ=F": "Orange Juice",
    }
   
    const commodityDict = {
        "Energy":energy,
        "Metals":metals,
        "Agri":agri,
        "All":commodities
    }
    const commodityHeaderDict = {
        "GC=F": "Gold",
        "SI=F": "Silver",
        "HG=F": "Copper",
        "CL=F": "Crude Oil",
        "BZ=F": "Brent Crude Oil",
    }
    const futuresData = {
        "ES=F": "S&P Futures",
        "YM=F": "Dow Futures",
        "NQ=F": "Nasdaq Futures",
        "RTY=F": "Russell 2000 Futures",
        "ZB=F": "U.S. Treasury Bond Futures,Sep-",
        "ZN=F": "10-Year T-Note Futures,Sep-2023",
        "ZF=F": "Five-Year US Treasury Note Futu",
        "ZT=F": "2-Year T-Note Futures,Sep-2023",
        "GC=F": "Gold",
        "MGC=F": "Micro Gold Futures,Dec-2023",
        "SI=F": "Silver",
        "SIL=F": "Micro Silver Futures,Sep-2023",
        "PL=F": "Platinum Oct 23",
        "HG=F": "Copper Sep 23",
        "PA=F": "Palladium Sep 23",
        "CL=F": "Crude Oil",
        "HO=F": "Heating Oil Sep 23",
        "NG=F": "Natural Gas Sep 23",
        "RB=F": "RBOB Gasoline Sep 23",
        "BZ=F": "Brent Crude Oil Last Day Financ",
        "B0=F": "Mont Belvieu LDH Propane (OPIS)",
        "ZC=F": "Corn Futures,Dec-2023",
        "ZO=F": "Oat Futures,Dec-2023",
        "KE=F": "KC HRW Wheat Futures,Dec-2023",
        "ZR=F": "Rough Rice Futures,Nov-2023",
        "ZM=F": "S&P Composite 1500 ESG Tilted I",
        "ZL=F": "Soybean Oil Futures,Dec-2023",
        "ZS=F": "Soybean Futures,Nov-2023",
        "GF=F": "WisdomTree International High D",
        "HE=F": "Lean Hogs Futures,Oct-2023",
        "LE=F": "Live Cattle Futures,Oct-2023",
        "CC=F": "Cocoa Dec 23",
        "KC=F": "Coffee Dec 23",
        "CT=F": "Cotton Oct 23",
        "LBS=F": "Random Length Lumber Futures",
        "OJ=F": "Orange Juice Nov 23",
        "SB=F": "Sugar #11 Oct 23",
      };
    
    const crypto = {"BTC-USD":"Bitcoin USD","ETH-USD":"Ethereum USD",
    "USDT-USD":"Tether USD","BNB-USD":"BNB USD"}

    const cryptoAll = {
        "BTC-USD": "Bitcoin USD",
        "ETH-USD": "Ethereum USD",
        "USDT-USD": "Tether USD",
        "BNB-USD": "BNB USD",
        "XRP-USD": "XRP USD",
        "SOL-USD": "Solana USD",
        "USDC-USD": "USD Coin USD",
        "STETH-USD": "Lido Staked ETH USD",
        "ADA-USD": "Cardano USD",
        "DOGE-USD": "Dogecoin USD",
        "WTRX-USD": "Wrapped TRON USD",
        "TRX-USD": "TRON USD",
        "MATIC-USD": "Polygon USD",
        "LINK-USD": "Chainlink USD",
        "TON-USD": "Toncoin USD",
        "DOT-USD": "Polkadot USD",
        "AVAX-USD": "Avalanche USD",
        "WBTC-USD": "Wrapped Bitcoin USD",
        "DAI-USD": "Dai USD",
        "LTC-USD": "Litecoin USD",
        "SHIB-USD": "Shiba Inu USD",
        "BCH-USD": "Bitcoin Cash USD",
        "EOS-USD": "Wrapped EOS USD",
        "LEO-USD": "UNUS SED LEO USD",
        "OKB-USD": "OKB USD"
      };
      

    const forex = {
      "CAD=X":"USD/CAD",
      "JPY=X":"USD/JPY",
      "AUD=X":"USD/AUD",
      "INR=X":"USD/INR",
      "GBP=X":"USD/GBP",
      "DX-Y.NYB": "USD Index",
    }
    const bonds = {"^IRX":"13 Week Treasury Bill","^FVX":"Treasury Yield 5 Years",
    "^TNX":"Treasury Yield 10 Years","^TYX":"Treasury Yield 30 Years"}
    
    const stocks = {"^GSPC":"S&P 500 Index","^DJI":"Dow Jones Industrial Average","^IXIC": "Nasdaq 100","^VIX":"VIX"}
    const intermarket={"^DJI":"DJIA","^GSPC":"S&P 500 Index","^VIX":"VIX","^RUT":"Russell 2000",
        "^IXIC":"NASDAQ","^TNX":"10y T-Yield",
        "^IRX":"13 Week Treasury Bill",
        "DX-Y.NYB": "USD Index","BND":"Vanguard Total Bond Market ETF",
       "GD=F":"S&P-GSCI Commodity Index",
       "CL=F":"Crude Oil","GC=F":"Gold","SI=F":"Silver",
       "BTC-USD":"Bitcoin","ETH-USD":"Ethereum USD","VND":"Vanguard Real Estate ETF"}
       
    const intermarketStocks = {"^DJI":"DJIA","^GSPC":"S&P 500","^VIX":"VIX","^RUT":"RUSSELL 2000",
    "^IXIC":"NASDAQ",}
    const intermarketBonds = {"^TNX":"10y T-Yield","^IRX":"13 Week Treasury Bill"}
    const intermarketForex = {"DX-Y.NYB": "USD Index"}
    const intermarketCommodity={"GD=F":"S&P-GSCI Commodity Index",
    "CL=F":"Crude Oil","GC=F":"Gold","SI=F":"Silver",}
    const intermarketCrpto = {"BTC-USD":"Bitcoin","ETH-USD":"Ethereum USD"}
    const intermarketEtf = {"^DJI":"DJIA","^GSPC":"S&P 500","^RUT":"RUSSELL 2000",
    "^IXIC":"NASDAQ",}
    const marketList = {"Stocks": stocks,
      "Bonds": bonds,
    "Commodities":commodities,
    "Crypto":crypto,
    "Forex":forex,"InterMarket":intermarket}
    
    const marketHeaderDict = {
        ...intermarket
    }

    const marketAll = {
        ...stocks,...bonds,...commodities,...crypto,...forex
    }
    

    const tickerList = ["SPY","^DJI","^RUT","^IXIC"]
    // setf = 'XLE,XLY,XLI,XLF,XLU,XLV,XLP,XLC,XLB,XLRE,XLK,SPY'
    // sectorIndex = {'Energy':'XLE','Consumer Discretionary':'XLY', 'Industrial':'XLI', 'Financial Services':"XLF","Utilities":"XLU","Healthcare":"XLV","Consumer Staples":"XLP","Communication Services":"XLC",
    //                         "Basic Materials":"XLB","Real Estate":"XLRE","Technology":"XLK", "SPY":"SPY"}
                
    // intermarketUS={"DJIA":"^DJI","S&P 500 Etf":"SPY","RUSSELL 2000":"^RUT","NASDAQ":"^IXIC","Treasury Yield 10y":"^TNX","USD Index":"DX-Y.NYB","S&P-GSCI Commodity Index":"GD=F","Crude Oil":"CL=F","Gold":"GC=F","Silver":"SI=F","Bitcoin":"BTC-USD","iShares U.S. Real Estate ETF":"IYR"}
    
    // globalIndicesR={"North America":{"Dow Jones Industrial Average":"^DJI","S&P 500 Etf":"SPY","RUSSELL 2000":"^RUT","NASDAQ":"^IXIC","TSX Composite index":"^GSPTSE"},
    //                         "Europe":{"DAX PERFORMANCE INDEX":"^GDAXI","FTSE 100":"^FTSE","CAC 40":"^FCHI","IBEX 35":"^IBEX"},
    //                     "Asia":{"NIFTY 50":"^NSEI","SENSEX":"^BSESN","Nikkie 225":"^N225","SSE Composite Index": "000001.SS","Hang Seng Index":"^HSI"}}
    // globalIndices={"Dow Jones Industrial Average":"^DJI","S&P 500 Etf":"SPY","RUSSELL 2000":"^RUT","NASDAQ":"^IXIC","TSX Composite index":"^GSPTSE","DAX PERFORMANCE INDEX":"^GDAXI","FTSE 100":"^FTSE","CAC 40":"^FCHI","IBEX 35":"^IBEX",
    // "NIFTY 50":"^NSEI","SENSEX":"^BSESN","Nikkie 225":"^N225","SSE Composite Index": "000001.SS","Hang Seng Index":"^HSI"}
    const etf = {
            QQQ: "Invesco QQQ Trust",
            VONG: "Vanguard Russell 1000 Growth Index Fund",
            SMH: "VanEck Semiconductor ETF",
            SOXX: "iShares Semiconductor ETF",
            RDVY: "First Trust Rising Dividend Achievers ETF",
            ONEQ: "Fidelity Nasdaq Composite Index ETF",
            LMBS: "First Trust Low Duration Opportunities ETF",
            FV: "First Trust Dorsey Wright Focus 5 ETF",
            ANGL: "VanEck Fallen Angel High Yield Bond ETF",
            PKW: "Invesco BuyBack Achievers ETF",
            UITB: "VictoryShares Core Intermediate Bond ETF",
            FALN: "iShares Fallen Angels USD Bond ETF",
            CDC: "VictoryShares US EQ Income Enhanced Volatility Wtd ETF",
            QQQE: "Direxion NASDAQ-100 Equal Weighted Index Shares",
            VRIG: "Invesco Variable Rate Investment Grade ETF",
            USTB: "VictoryShares Short-Term Bond ETF",
            AIRR: "First Trust RBA American Industrial RenaissanceTM ETF",
            SDG: "iShares MSCI Global Sustainable Development Goals ETF",
            FEMS: "First Trust Emerging Markets Small Cap AlphaDEX Fund",
            VSDA: "VictoryShares Dividend Accelerator ETF",
            IUS: "Invesco RAFI Strategic US ETF",
            TUR: "iShares MSCI Turkey ETF",
            KBWP: "Invesco KBW Property & Casualty Insurance ETF",
            RTH: "VanEck Retail ETF",
            PSCC: "Invesco S&P SmallCap Consumer Staples ETF"
          };
          
    const etfMain = {
            SPY: "SPDR S&P 500 ETF Trust",
            VTI: "Vanguard Total Stock Market ETF",
            QQQ: "Invesco QQQ Trust Series I",
            VEA: "Vanguard FTSE Developed Markets ETF",
            BND: "Vanguard Total Bond Market ETF",
            IEFA: "iShares Core MSCI EAFE ETF",
            AGG: "iShares Core U.S. Aggregate Bond ETF",
            VWO: "Vanguard FTSE Emerging Markets ETF",
            VTV: "Vanguard Value ETF",
            VUG: "Vanguard Growth ETF",
            IWF: "iShares Russell 1000 Growth ETF",
            VIG: "Vanguard Dividend Appreciation ETF",
    }

export {crypto,etfMain,etf,bonds,marketAll,
    intermarket,
    sectorDict,
    forex,
    futuresData,commodities,marketList,agri,metals,
    energy,cryptoAll,commodityDict,commodityHeaderDict,
    marketHeaderDict,intermarketCommodity,intermarketBonds,
    intermarketEtf,
    intermarketStocks,intermarketForex,intermarketCrpto}
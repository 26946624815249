import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Typography,
  } from '@mui/material';
import FairValueMultipleScenario from 'analytics/ticker/stocks/Value/FairValueMultipleScenario';
import ForecastTable from 'analytics/ticker/stocks/Value/ForecastTable';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabLine from 'components/Tabs/TabLine';


export default function PeerDcfExplaination() {
    const [tabIndexInfo,setTabIndexInfo] = useState(0)
    
  return (
    <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Alert severity='info'>What is Fair Value 15, 30 & 45? - Learn more about Fair Value Multiple!</Alert></AccordionSummary>
    <AccordionDetails>
  <div style={{display:"flex",gap:"0.5rem",width:"100%"}}>
    
  <div style={{flex:1, background:"rgb(229, 246, 253)"}}>
  <Alert icon={<></>} severity='info'>
  <Typography style={{fontSize:"1.2rem"}}>Key Points</Typography></Alert>
  {fairValueMultipleExplaination.map((obj,index)=>
  <Alert key={index} icon={<CheckIcon/>} severity='info'>
    {obj.point}
  </Alert>
  )}
</div>
<div style={{width:"30%",background:"rgb(237, 247, 237)"}}>
<Alert icon={<></>} severity='success'>
<Typography style={{fontSize:"1.2rem"}}>Success Points</Typography></Alert>
{successPoints.map((obj,index)=>
  <Alert key={index} severity='success'>
    {obj.point}
  </Alert>
  )}
  </div>
  <div style={{width:"30%",background:"rgb(255, 244, 229)"}}>
  <Alert icon={<></>} severity='warning'>
  <Typography style={{fontSize:"1.2rem"}}>AlertMyFinancials</Typography>
  </Alert>
{warningPoints.map((obj,index)=>
  <Alert key={index} severity='warning'>
    {obj.point}
  </Alert>
  )}
  </div>
  </div>
  <TabLine tabList={["FV Multiple Table","FV Multiple Senstivity Table"]} tabIndex={tabIndexInfo} handleTabChange={(index)=>setTabIndexInfo(index)}/>
  {tabIndexInfo === 0 && 
  <ForecastTable fcfBeg={1} 
  forecastPeriod={10}
  discountRate={10} terminalRate={0} growthRate={8} nonOperatingValue={0.1} sharesOutstanding={1}/>
  }
  {tabIndexInfo === 1 && 
  <FairValueMultipleScenario/>}
  </AccordionDetails>
  </Accordion>
  )
}


const fairValueMultipleExplaination = [
    {
      "point": "Discounted Cash Flow is an absolute valuation method, meaning its value is examined based on an asset's fundamental characteristics without comparing it to other assets or market benchmarks."
    },
    {
      "point": "Calculating value with DCF requires a lot of steps. However, we have introduced a 'Fair Value Multiple' approach - which allows you to quickly compare fair value with price.",
      
    },
    {
      "point":"What is Fair Value Multiple and How it is Calculated?:"    
    },
    {
      "point": "Fair Value 19.4 is calculated using a starting Free Cash Flow of 1, assumed to be growing at 8%, with a terminal growth rate of 0%, and discounted at 10%. The non-operating value (including cash and debt) is assumed to be 1%. See the table below for detailed calulation."
    },
    {
      "point": "This calculation gives us a Total Value (Enterprise Value) of approximately 19.14 as you can see the table below. This number is referred to as Fair Value Multiple."
    },
    {
      "point": "Fair Value 19.14 = (Free Cash Flow x 19.14 - Non Operations Value)/ Total Shares Outstanding to arrive at Fair Value per Share."
    },
    {
      "point":"Similary, Fair Value 15 = (Free Cash Flow x 15 - Non Operations Value)/ Total Shares Outstanding"
    },
    
  ]
  
  const successPoints = [
    {
      "point": "By considering different scenarios of growth rates, discount rates, terminal growth rates, and forecast periods, most companies fall within a Fair Value Multiple range of 15 to 45."
    },
    {
      "point": "For example, Fair Value 45 - would be possible with a growth rate of 20% for a period of 10 years, with a discount rate of 10.25% and terminal growth rate of 0%."
    },
    {
      "point":"Fair Value 15, 30 and 45 creates a range bracket which is a great way to see the relationship of price with fair value for multiple securities."
    },
    {
      "point":"Play around with different growth rates, discount rates, forecast period and terminal growth rate in the FV Multiple Sensitivity Table to see its impact on the multiple."
    }
  ]
  
  const warningPoints = [
    {
      "point":"Even though the heading says DCF vs Peers - DCF is not really measured against peers."
    },
    {
      "point":"We have provided you with a comparison with peers to quickly see which companies are over or under valued."
    }
  ]
import React from 'react'
import { useNavigate } from 'react-router-dom';
import ProductFeatureTemplate from './ProductFeatureTemplate';
import Animation from '../../animation/Animation';
import GetStartedButton from './GetStartedButton';
import Video from 'components/Media/Video';

export default function ProductFeature1() {
  
  const content =  
  <div style={{width:"100%",padding:"1rem",position:"relative",
  boxSizing:"border-box",display:"flex"}}>

    <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"20%",alignItems:"center"}}>
      
    <div style={{width:"100%"}}>
    <Animation type='Goals'/>
    </div>
    <GetStartedButton/>
    </div>



    <div style={{flex:1,minWidth:"0",position:"relative"
    ,background:"var(--bgWoodSmoke)",padding:"0.5rem",borderRadius:"5px"}}>
      <Video src={"\FinancialPlanning.mp4"}/>
    </div>
</div>

  return (
    <ProductFeatureTemplate content={content} pageNumber={0} 
    id='pageScroll0' tagLineHeader={""}/>

   
  )
}

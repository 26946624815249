import React from 'react';
import { Skeleton } from '@mui/material';

export default function LoadingSkeletonNews ({length=5,variant="text",width="100%",height="20px"}) {
  return (
    <>
    { Array.from({ length: length}).map((_, index) => (
    <div key={index} style={{display:"flex",width:"100%",gap:"1rem",boxSizing:"border-box"}}>
    <Skeleton variant={"rectangle"} className='news_image'/>
    <div style={{flex:1}}>
    <Skeleton variant={variant} width={"60%"} height={height}/>
    {
   Array.from({ length: 2}).map((_, index2) => (
        <Skeleton variant={variant} key={index2} width={"20%"} height={height}/>))}
        
        </div>
    </div>))}
   
    </>

  );
};

import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LeftSidebar from 'sidebar/LeftSidebar/LeftSidebar';
import { menuAnalytics } from 'nav/menu';
import { PageMainLayout } from 'layout/PageLayout';

export default function Analytics() {
  const [infoSel,setInfoSel] = useState("Ticker")
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const subPage = pathname[2]


  useEffect(()=>{
   if (subPage){
   const menuSel = menuAnalytics.filter(obj=>obj.link===subPage)[0]?.name
   setInfoSel(menuSel)}
   else{
    navigate(`/Analytics/Ticker`)
    setInfoSel("Ticker")
   }
  },[pathname])
  
  return (
    <PageMainLayout
    pageName={"Explore/Analyze"} 
    menuSel={infoSel} 
    menuOptions={menuAnalytics}
    >
    <Outlet/>
    </PageMainLayout>
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import ImageUpload from './ImageUpload';
import useFindUpload from 'data/user/upload/findUpload';
import useDeleteUpload from 'data/user/upload/deleteUpload';
import { Delete } from '@mui/icons-material';

export default function Upload() {
    const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const {uploads} = stateReportBuilder
    const {dragging,dragId} = stateReportBuilder
    useFindUpload()
    const {deleteUpload} = useDeleteUpload()
   const handleDragStart = (e, obj) => {
    e.dataTransfer.setData('application/json', JSON.stringify(obj));
    dispatchReportBuilder({"dragging":true,"dragId":obj.Name})
  };

  const handleDragEnd = () => {
    dispatchReportBuilder({"dragging":false})
  };


      const handleSetImage = (v) => {
        const imgDataUpdate = {...uploads,...v}
        dispatchReportBuilder({"uploads":imgDataUpdate})
      }

      function handleDelete(n,v){
        deleteUpload(n,v)
      }

  return (
    <div style={{display:"flex",gap:"1rem",flexDirection:"column",padding:"1rem",height:"100%",width:"100%",boxSizing:"border-box"}}>
        <div style={{position:"sticy",top:0,background:"white",width:"100%",zIndex:"10"}}>
          <ImageUpload setImage={handleSetImage}/>
        </div>
        <div
        style={{
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexWrap: 'wrap',gap:"0.5rem"
        }}
      >
        
        {uploads && Object.entries(uploads).map(([name, url]) => (
  <div 
    key={name}
    style={{
      position: 'relative',
      width: "100%",
      background: dragging && dragId === `${name}` ? 'blue':"#ddd",
      padding: "0.2rem",
      borderRadius: "5px",
      height:"100px",
      overflow: 'hidden', // Ensure the absolutely positioned elements are contained within the div
    }}
  >
    <img
      src={url}  // Corrected from src={name} to src={url}
      draggable
      onDragStart={(e) => handleDragStart(e, {Name:name,Type: "Images", ImgUrl: url})}
      onDragEnd={handleDragEnd}
      style={{
        height: '100%',
        minHeight: "100px",
        width: "100%",
        objectFit: 'contain',
      }}
    />
    <div
      onClick={() => handleDelete(name, url)}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '2px',
        borderRadius:"5px",
        cursor: 'pointer',
        // display: 'none',  // Hide by default
        background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        color: 'white'
      }}
      // onMouseEnter={(e) => e.currentTarget.style.display = 'block'}
      // onMouseLeave={(e) => e.currentTarget.style.display = 'none'}
    >
      <Delete/>
    </div>
  </div>
))}
      </div>

    </div>
  )
}

import React from 'react';
import { Box, Typography } from '@mui/material';

const TextWithBorders = ({ text, icon,variant="body1",sx={}}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
      <Box width={"10px"} borderBottom="1px solid #ddd"></Box>
      <Box display="flex" alignItems="center" mx={2}>
        {icon && <Box mr={1} sx={{...sx}}>{icon}</Box>}
        <Typography variant={variant} sx={{ fontWeight: "bold",...sx}}>
          {text}
        </Typography>
      </Box>
      <Box flex="1" borderBottom="1px solid #ddd"></Box>
    </Box>
  );
};

export default TextWithBorders;
import React, { useMemo } from 'react'
import useEconInfo from 'data/economy/useEconInfo';
import MetricSelectionModal from 'components/Template/MetricSelectionModal';


export default function EconomyMetricSelection({
  searchBar=true,
  title="Economy Metric Selection",
  handleMetricSelected,
  metricSelected=[],onClose,checkbox=true,searchTerm,modal=true}) {
  
    const { econInfo } = useEconInfo();
    const metricInfo = useMemo(() => {
      return econInfo ? econInfo.map(({ Name, Category }) => ({ name: Name, category: Category })) : [];
    }, [econInfo]);
  
  return (
    <MetricSelectionModal 
    searchBar={searchBar}
    handleMetricSelected={handleMetricSelected}
    title={title}
    metricInfo={metricInfo}
    metricSelected={metricSelected}
    searchTerm={searchTerm}
    onClose={()=>onClose()}
    checkbox={checkbox}
    modal={modal}
    />
  )
}

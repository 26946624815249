import React, { useState, useRef, useEffect } from 'react';
import { Button, Paper } from '@mui/material';

const DraggableResizableBox = ({ id, left, top, width, height, children, moveBox, resizeBox, parentDimensions }) => {
  const boxRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    const initialX = e.clientX - (left / 100) * parentDimensions.width;
    const initialY = e.clientY - (top / 100) * parentDimensions.height;

    const handleMouseMove = (e) => {
      const newLeft = Math.max(0, Math.min(100 - width, ((e.clientX - initialX) / parentDimensions.width) * 100));
      const newTop = Math.max(0, Math.min(100 - height, ((e.clientY - initialY) / parentDimensions.height) * 100));
      moveBox(id, newLeft, newTop);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleResizeMouseDown = (e) => {
    e.stopPropagation(); // Prevent the drag handler from firing
    const initialX = e.clientX;
    const initialY = e.clientY;

    const handleResizeMouseMove = (e) => {
      const newWidth = Math.max(5, Math.min(100 - left, width + ((e.clientX - initialX) / parentDimensions.width) * 100));
      const newHeight = Math.max(5, Math.min(100 - top, height + ((e.clientY - initialY) / parentDimensions.height) * 100));
      resizeBox(id, newWidth, newHeight);
    };

    const handleResizeMouseUp = () => {
      document.removeEventListener('mousemove', handleResizeMouseMove);
      document.removeEventListener('mouseup', handleResizeMouseUp);
    };

    document.addEventListener('mousemove', handleResizeMouseMove);
    document.addEventListener('mouseup', handleResizeMouseUp);
  };

  return (
    <div
      ref={boxRef}
      onMouseDown={handleMouseDown}
      style={{
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        width: `${width}%`,
        height: `${height}%`,
        border: '1px solid black',
        padding: '0.5rem',
        cursor: 'move',
        backgroundColor: 'white',
        boxSizing: 'border-box',
      }}
    >
      {children}
      <div
        onMouseDown={handleResizeMouseDown}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '10px',
          height: '10px',
          backgroundColor: 'blue',
          cursor: 'nwse-resize',
        }}
      />
    </div>
  );
};

const Container = () => {
  const containerRef = useRef(null);
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const [boxes, setBoxes] = useState({
    1: { top: 0, left: 0, width: 30, height: 50, title: 'Box 1' },
    2: { top: 50, left: 30, width: 40, height: 20, title: 'Box 2' },
    3: { top: 70, left: 70, width: 50, height: 60, title: 'Box 3' },
  });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setContainerDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const savedBoxes = localStorage.getItem('boxes');
    if (savedBoxes) {
      setBoxes(JSON.parse(savedBoxes));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('boxes', JSON.stringify(boxes));
  }, [boxes]);

  const moveBox = (id, left, top) => {
    setBoxes((prevBoxes) => {
      const updatedBox = { ...prevBoxes[id], left, top };
      return ({ ...prevBoxes, [id]: updatedBox })
      // return adjustBoxes({ ...prevBoxes, [id]: updatedBox });
    });
  };

  const resizeBox = (id, width, height) => {
    setBoxes((prevBoxes) => {
      const updatedBox = { ...prevBoxes[id], width, height };
      return ({ ...prevBoxes, [id]: updatedBox });
    });
  };

  // Adjust box positions to avoid overlap and maintain a grid layout
  const adjustBoxes = (boxes) => {
    let adjustedBoxes = { ...boxes };

    // Sort boxes based on their top and left positions
    const keys = Object.keys(adjustedBoxes).sort((a, b) => {
      const boxA = adjustedBoxes[a];
      const boxB = adjustedBoxes[b];
      if (boxA.top === boxB.top) {
        return boxA.left - boxB.left;
      }
      return boxA.top - boxB.top;
    });

    // Adjust the positions to prevent overlap
    keys.forEach((key, index) => {
      let currentBox = adjustedBoxes[key];
      for (let nextIndex = index + 1; nextIndex < keys.length; nextIndex++) {
        const nextKey = keys[nextIndex];
        const nextBox = adjustedBoxes[nextKey];
        if (
          currentBox.left < nextBox.left + nextBox.width &&
          currentBox.left + currentBox.width > nextBox.left &&
          currentBox.top < nextBox.top + nextBox.height &&
          currentBox.top + currentBox.height > nextBox.top
        ) {
          if (currentBox.left + currentBox.width + nextBox.width <= 100) {
            nextBox.left = currentBox.left + currentBox.width;
          } else {
            nextBox.top = currentBox.top + currentBox.height;
            nextBox.left = 0;
          }
        }
      }
    });

    return adjustedBoxes;
  };

  const addBox = () => {
    const newId = Object.keys(boxes).length + 1;
    setBoxes((prevBoxes) => ({
      ...prevBoxes,
      [newId]: { top: 50, left: 50, width: 30, height: 30, title: `Box ${newId}` },
    }));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: "0.5rem", boxSizing: "border-box" }}>
      <Paper
        ref={containerRef}
        style={{ width: '100%', flex: 1, position: 'relative', overflow: 'auto' }}
      >
        {Object.keys(boxes).map((key) => {
          const { left, top, width, height, title } = boxes[key];
          return (
            <DraggableResizableBox
              key={key}
              id={key}
              left={left}
              top={top}
              width={width}
              height={height}
              moveBox={moveBox}
              resizeBox={resizeBox}
              parentDimensions={containerDimensions}
            >
              {title}
            </DraggableResizableBox>
          );
        })}
      </Paper>
      <Button onClick={addBox} style={{ marginTop: '10px' }}>
        Add Box
      </Button>
    </div>
  );
};

export default Container;


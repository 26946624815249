import React, { useEffect, useState } from 'react'
import { Close, Info } from '@mui/icons-material'
import { Alert, Button, Icon, IconButton, Popover, Tooltip } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function PopoverButton({openPopover,variant="text",iconSize="medium",title="",showLabel=false,children,sxBtn={},icon="dropdown",sxPopover={}}){
    
        
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(()=>{
        if (openPopover){
            handleOpen()
        }
    },[openPopover])
  
    const open = Boolean(anchorEl);
    const id = open ? 'popoverButton' : undefined;
  
    return ( 
      <>
      <Button sx={{textAlign:"left",...sxBtn,}} 
      variant={variant} aria-describedby={id} onClick={handleOpen}>
      {icon==="chart" &&<Tooltip title={title}><BarChartIcon fontSize={iconSize}/></Tooltip> }
      {icon==="edit" && <Tooltip title={title}><EditIcon sx={{color:"gray"}} fontSize={iconSize}/></Tooltip>}

      {showLabel && title}
      {icon==="dropdown" &&
      <span style={{marginLeft:"auto",display:"flex",alignItems:"center"}}>
       {!open ? <KeyboardArrowDownIcon fontSize={iconSize}/>:<KeyboardArrowUpIcon fontSize={iconSize}/>}</span>}
    </Button>
    
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
    //   onMouseLeave={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", 
    overflow:"auto",maxWidth:"45vw",maxHeight:"85vh",boxSizing:"border-box"}}>
       <div style={{display:"flex",gap:"1rem",alignItems:"center"
        ,position:"sticky",top:0,zIndex:5,background:"white",padding:"0.25rem"}}> 
        <h3 style={{flex:1}}>{title}</h3>
        <IconButton onClick={handleClose}><Close/></IconButton>
        </div>
        <div style={{padding:"0.25rem",flex:1}}>
        {React.cloneElement(children, { onClose: handleClose })}
        </div>   
      </div>
    </Popover>
    </>)
  }
import { Alert } from '@mui/material';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';
import React, { useState, useEffect } from 'react';
import IndSecFilter from './IndSecFilter';

export default function IndSecNoInfo() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isVisible ? (
        <>
          <Alert severity='warning'>No Information available for this selection</Alert>
          <Alert severity='success'>
            Try changing the filter or select another industry or sector.<br/><br/>
            <IndSecFilter btnType='big'/>
            </Alert>
          
         
          <LoadingSkeletonChart width='100%' height='auto' />
          
        </>
      ) : null}
    </div>
  );
}

import {useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";


const useFindLearnTopics = () => {

  const {dispatchResource} = useAppInfo()
  const [error, setError] = useState(null);
  const [loading,setLoading] = useState(true)

  
  const findCategory = async () =>
    {
      setLoading(true)
      try {
        const response = await axios.get(`${BASE_URL}/findCategory`);
          
    
        if (response.status === 200) {
          
          // Data found, set the resourceTopics state
  
          let data = response.data;
          dispatchResource({"learnContentTopics":data})
          setLoading(false)
          setError(null); // Clear any previous error
        } else if (response.status === 404) {
          // No data found, handle this case as needed
          setError("No data found");

        } else {
          // Handle other response status codes if needed
          setError("Error retrieving data");
         
        }
      } catch (err) {
        // Handle network or request error
        setError("Error retrieving Category data");
        
      }
    };

    useEffect(()=>{
        findCategory()
    },[])
 
};

export default useFindLearnTopics;
import { useAppInfo } from 'AppState';


export default function useAssistantPortfolioManager() {
        const {stateMyFinancials,statePortfolio} = useAppInfo()

        function GetPortfolioSummary() {
            const {portfolioResults} = statePortfolio
            const {investmentPreference} = stateMyFinancials

            const info =  [
              portfolioResults,investmentPreference,{"react_component":["chart_PortfolioValue","chart_PortfolioCurrentPosition"]}
          ]

            return info
          }
    
          async function GetPortfolioValue() {
            const {portfolioResults,portfolioTotal,portfolioValue} = statePortfolio
            const portfolioValue1y = portfolioValue?.slice(0,300)
            const info =  [
              portfolioTotal,portfolioResults,portfolioValue1y,{"react_component":["chart_PortfolioValue"]},
              {"learn_more":["/MyFinancials/Portfolio"]}
          ]

            return info
          }

             
          async function GetPortfolioHoldings() {
            const {portfolioResults} = statePortfolio
          
            const info =  [
              portfolioResults,{"react_component":["chart_PortfolioCurrentPosition"],"learn_more":["/MyFinancials/Portfolio"]}
          ]

            return info
          }

          async function GetPortfolioAssetAllocation() {
            const {portfolioResults} = statePortfolio
            const {investmentPreference} = stateMyFinancials

            return [{"react_component":["chart_PortfolioAssetAllocation"]},portfolioResults,investmentPreference,
              {"learn_more":["/MyFinancials/Portfolio"]}]
          }

          async function GetPortfolioVsBenchmark() {

            const info =  [{"react_component":["chart_PortfolioVsBenchmark"],"learn_more":["/MyFinancials/Portfolio"]}]

            return info
          }
        
        function GetPortfolioCharts ({component}){
            const infoOptions = {"Portfolio Value":"chart_PortfolioValue","Portfolio Current Position":"chart_PortfolioCurrentPosition",
                "Portfolio Asset Allocation":"chart_PortfolioAssetAllocation"}

                const filteredOptions = Object.keys(infoOptions)
                .filter(key => component.includes(key))  // Filter keys based on presence in the component array
                .reduce((acc, key) => {
                    acc[key] = infoOptions[key];  // Build the filtered object
                    return acc;
                }, {});

                return filteredOptions
        }
        return {
          GetPortfolioSummary,GetPortfolioValue,GetPortfolioHoldings,GetPortfolioAssetAllocation,
          GetPortfolioVsBenchmark,GetPortfolioCharts
          }
        
}

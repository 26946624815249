import React from 'react'
import TableGen from 'components/Table/TableGen'
import useOBV from 'data/technicals/useOBV'
import IndicatorTemplate from './IndicatorTemplate'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function OBVpeers({tickerList}) {

  const {OBV,OBVLoading} = useOBV(tickerList)

  return (
    <IndicatorTemplate indicatorName={"OBV (On Balance Volumne"}>
    {OBVLoading && <LoadingSkeletonTable length={tickerList.length}/>}
    {(!OBVLoading && OBV && OBV.length>0) && 
    <>
    <TableGen data={OBV}/>
    </>}
    </IndicatorTemplate>
  )
}

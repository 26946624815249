
import React,{useState,useEffect} from 'react'
import axios from "axios";

export default function useSenateHoldings() {
    const [senateHoldings, setSenateHoldings] = useState([]);
    useEffect(() => {
        getMetricInfo();
      }, []);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`https://house-stock-watcher-data.s3-us-west-2.amazonaws.com/data/all_transactions.json`);
          setSenateHoldings(responseMetric.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {senateHoldings}
    
}

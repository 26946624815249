import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import { dfin, performanceDict } from 'content/constants'
import { Button, ButtonGroup } from '@mui/material'
import MarketBreadthIndSecDetail from '../MarketBreadthIndSecDetail'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'
import { PageMainContentTitle } from 'components/Font/Title'


export default function IndSecPerformance() {
  const {stateIndustry} = useAppInfo()
  const [performancePeriod,setPerformancePeriod] = useState(dfin.performance1D)
  const [performance,setPerformance] = useState([])

  useEffect(()=>{
    
    if (stateIndustry.constiInfoAll.length>0){
        const performance =  getSpecificArrayObjKeys(stateIndustry.constiInfoAll,[performancePeriod])
        setPerformance(performance)
    }

  },[performancePeriod,stateIndustry.constiInfoAll])
  function handlePerformance(period){
    setPerformancePeriod(performanceDict[period])
  }
  let sector = stateIndustry.sectorSel
  if (stateIndustry.sectorSel.includes("All")){
    const uniqueSectors = stateIndustry.uniqueSectors  
    sector = uniqueSectors

}
  return (
    <>
     {performance.length===0 && <LoadingSkeletonTable/>}
    {performance.length>0 &&
    <>

    <div style={{padding:"0.5rem",display:"flex",gap:"1rem",alignItems:"center"}}>
    <PageMainContentTitle>Performance: Market Breadth</PageMainContentTitle>
    <ButtonGroup size="small" 
    style={{display:"flex",marginLeft:"auto",zIndex:"1000",background:"white",fontSize:"0.8rem"}}>
        {Object.keys(performanceDict).map(period=>
        <Button
        sx={{fontSize:"0.8rem"}}
        style={performanceDict[period]===performancePeriod?{color:"white",background:"black"}:{}}
        onClick={()=>handlePerformance(period)} key={period}>{period}</Button>)}
    </ButtonGroup>
    </div>
    <div style={{width:"100%",hieght:"50%"}}>
    <div style={{display:"flex",flexDirection:"column",gap:"1rem",flexWrap:"wrap",width:"100%",padding:"0 0.5rem",boxSizing:"border-box"}}>
    {!stateIndustry.indSecMode && sector.map(sector=>
    <div style={{minWidth:"100%",flex:1}} key={sector}>
    <MarketBreadthIndSecDetail sector={sector} performancePeriod={performancePeriod}/>
    </div>)}
    {stateIndustry.indSecMode && stateIndustry.industrySel.map(industry=>
    <div style={{minWidth:"100%",flex:1}} key={industry}>
    <MarketBreadthIndSecDetail type="Industry" industry={industry} performancePeriod={performancePeriod}/>
    </div>)}
    {((stateIndustry.indSecMode && stateIndustry.industrySel.length>1) || (!stateIndustry.indSecMode && stateIndustry.sectorSel.length>1))
    &&
    <MarketBreadthIndSecDetail type="Consolidate" performancePeriod={performancePeriod}/>}

    </div>
   
    </div>
    </>
    }
    </>
  )
}
import React, { useEffect, useState,useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useAppInfo } from 'AppState';
import { LinearProgress } from '@mui/material';
import Toggle from '../Inputs/Toggle';
import AddIcon from '@mui/icons-material/Add';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';

require("highcharts/modules/accessibility")(Highcharts);

// HighchartsBoost(Highcharts);

export default function PriceChartMulti({close,marketInfoLoading,tickerDict,
  chartType="line",
  benchmarkClose,
  navigator=true,scrollbar=true,zoneRGSeries,height="100%",width="100%"}) {
  const {stateStock,stateTicker} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const tickerSelected = stateTicker.tickerSelected
  const companySelected = stateStock.companySelected
  const [series, setSeries] = useState([]);
  const [toggleMode,setToggleMode] = useState(false)
  useEffect(() => {
    const formatChartData = (priceData) => {
      const formattedData = [];

      for (const data of priceData) {
        const date = new Date(data['Date']).getTime();
        const tickers = Object.keys(data).filter((key) => key !== 'Date');

        for (const ticker of tickers) {
          const closePrice = parseFloat(data[ticker]);
          const name = tickerDict && tickerDict[ticker] ? tickerDict[ticker] : ticker;
          formattedData.push({ date, name, close: closePrice });
        }
      }

      return formattedData;
    };

    if (close.length > 0) {
      const formattedData = formatChartData(close);
      const benchmarkData = benchmarkClose && formatChartData(benchmarkClose);
      const names = [...new Set(formattedData.map((data) => data.name))];
      const benchmarkNames =  benchmarkData && [...new Set(benchmarkData.map((data) => data.name))]
      const seriesData = names.map((name) => {
        const dataPoints = formattedData
          .filter((data) => data.name === name)
          .map((data) => [data.date, data.close]);
        const isHighlighted = name === tickerSelected;
        return {
          name: name,
          data: dataPoints,
          color: isHighlighted ? 'black' : '',
          lineWidth: isHighlighted ? 3 : 1,
          turboThreshold: 1000,
          zones:zoneRGSeries &&  [{
            value: 0,
            color: 'red'
          },
          {
            color: 'green'
          }
        ]
        };
      });
      
       
      const benchmarkSeries = benchmarkClose && benchmarkNames.map((name) => {
        const dataPoints = benchmarkData
          .filter((data) => data.name === name)
          .map((data) => [data.date, data.close]);
        return {
          name: `Benchmark: ${name}`,
          data: dataPoints,
          lineWidth: 1,
          turboThreshold: 1000,
        };
      });
   
      benchmarkSeries && seriesData.push(...benchmarkSeries)
      

      setSeries(seriesData);
    }
  }, [close, tickerDict,benchmarkClose,tickerSelected]);


  const options = useMemo(() => {
    if (series.length === 0) {
      // Return an empty options object if the series is not available yet
      return {};
    }
    return {
    chart: {
      reflow: true,
      type:chartType,
      animation: false,
      zoomType: 'xy',
      // boost: {
      //   enabled: true,
      //   seriesThreshold: 100,
      // },
    },
    
    lang: {
      zoomIn: '',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        dateTimeLabelFormats: {
          month: '%Y-%b',
          year: '%Y',
        },
      },
    },
    yAxis: {
      title: {
        text: 'Price',
      },
      labels: {
        format: !toggleMode?'{#if (gt value 0)}+{/if}{value}%':"",
      },
    },
    series: series,
    navigator : {
      enabled : navigator
                
    },
    scrollbar: {
      enabled : scrollbar
  },
    tooltip: {
      split: false,
      formatter: function () {
        const seriesName = this.series.name;
        const point = this.point;
        
        let ticker;
        let name = seriesName;
    
        if (tickerDict) {
          // Reverse the tickerDict to find the ticker for a given name
          const reversedTickerDict = Object.fromEntries(Object.entries(tickerDict).map(([key, value]) => [value, key]));
          ticker = reversedTickerDict[name] || seriesName;
        } else {
          ticker = seriesName;
        }
        
        let tooltipContent = `<b>${name}</b>`;
        
        if (tickerDict && ticker !== name) {
          tooltipContent += `<br/>${ticker}`;
        }
    
        tooltipContent += `<br/>${Highcharts.dateFormat('%Y-%m-%d', point.x)}<br/>${Highcharts.numberFormat(point.y, 2)}`;
    
        return tooltipContent;
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        compare: !toggleMode?'percent':"",
        showInNavigator: true,
        events: {
          click: function (event) {
            const seriesName = this.name;
            handleTickerChange({ticker:seriesName});
          },
        },
   
      },
    },
    

    rangeSelector: {
      selected:2
    },
    credits: {
      enabled: false, // Hide Highcharts.com credits
    },
    stockTools :{
      gui:{
      enabled:false}
    },
    accessibility: {
      enabled: false, // Include the accessibility module
    },
    

  }}, [series,toggleMode])
  


  return (
    <>
    
    <div style={{ height: height, width:width,position:"relative"}} >
    <div style={{position:"absolute",left:"1%",top:"50px",zIndex:"1",display:"flex",alignItems:"center"}}>
    {(series.length>0&& !marketInfoLoading) &&
    <Toggle toggleMode={toggleMode} toggleValues={["Percent","Value"]} handleToggle={()=>setToggleMode(!toggleMode)}/>
      }
    </div>
    {series.length===0 && <LoadingSkeletonChart chartType='multiline'/>}
    {(marketInfoLoading && series.length>0) && <div style={{position:"absolute",top:0,zIndex:"100",width:"100%"}}><LinearProgress/></div>}
    {series.length>0&&
    <HighchartsReact highcharts={Highcharts} 
    options={options} 
    constructorType={'stockChart'}
    containerProps={{ style: { height: '100%' , width:"100%"} }}

    />}

  </div>
  </>
  );
}

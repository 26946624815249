import axios from 'axios';
import { ref, deleteObject } from 'firebase/storage';
import { BASE_URL } from 'apiConfig';
import { storage } from 'firebaseConfig/firebaseConfig';
import { useAppInfo } from 'AppState';

const useDeleteUpload = () => {
  const { dispatchReportBuilder, stateReportBuilder, stateApp } = useAppInfo()
  const userId = stateApp.userId;

  const deleteUpload = async (uploadName, filePath) => {
    try {
      if (userId) {
        // Perform the API call to delete the record from the backend
        const response = await axios.post(`${BASE_URL}/deleteUploads/`, {
          userId,
          uploadName,
        });

        // If API call is successful, proceed to delete the file from Firebase
        const fileRef = ref(storage, filePath);
        await deleteObject(fileRef);
        // Update the local state to reflect the deletion
        const updatedUploads = { ...stateReportBuilder.uploads };
        delete updatedUploads[uploadName];
        dispatchReportBuilder({'uploads':updatedUploads });
      }
    } catch (error) {
      console.error("Error deleting upload:", error);
      // Optionally, update the state to indicate an error
    }
  };

  return { deleteUpload };
};

export default useDeleteUpload;

import React, { useState } from 'react'
import ColorPicker from 'components/Inputs/ColorPicker'
import CheckboxCutom from 'components/Inputs/CheckboxCustom'
import TextSettings from './TextSettings'
import { handleObjectDetails } from '../utilsReport'
import { useAppInfo } from 'AppState'
import { Settings } from '@mui/icons-material'
import Modal from 'components/Modal/Modal'

export default function ObjectSettings({onClickAway,item}){
    const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const [applyAllPages,setApplyAllPages] = useState(false)
    const [deletefromAllPages,setDeleteFromAllPages] = useState(false)
    const {pages} = stateReportBuilder


    function handleApplyToAllPages(v){
      setApplyAllPages(v)
      if (v){
        const pageUpdate = pages.map(page => ({
          ...page,
          PageContent: [...page.PageContent,item]
        }))
        dispatchReportBuilder({"pages":pageUpdate})
      }
    }
    function handleDeleteFromAllPages(v) {
        setDeleteFromAllPages(v);
        if (v) {
            const pageUpdate = pages.map(page => ({
                ...page,
                PageContent: page.PageContent.filter(content => content.Type !== item.Type)
            }));
            dispatchReportBuilder({"pages":pageUpdate });
        }
    }
    return (
     <Modal onClose={onClickAway}
            width='auto' height='auto' borderRadius='5px' sx={{padding:"1rem"}}>
            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            {/* <Button onClick={onClickAway}><Close/></Button> */}
            <div style={{display:"flex",alignItems:"center",gap:"0.5rem"}}><Settings/> <h3>Settings</h3></div>
            <div style={{border:"1px solid #ddd",borderRadius:"5px",padding:"0.2rem",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            <h4>Background Color</h4>
            <ColorPicker sx={{height:"25px",width:"25px"}} defaultColor={item?.style?.["background"]} 
            onChange={(v)=>handleBackgroundStyling(v,stateReportBuilder,dispatchReportBuilder)}/> 
              </div>
              <div style={{border:"1px solid #ddd",borderRadius:"5px",padding:"0.2rem",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                <CheckboxCutom defaultChecked={applyAllPages} 
                label={"Apply this Item to All Pages"} 
                onChange={handleApplyToAllPages}/>
              </div>
              <div style={{border:"1px solid #ddd",borderRadius:"5px",padding:"0.2rem",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
                <CheckboxCutom defaultChecked={deletefromAllPages} label={"Delete this Item to All Pages"} 
                onChange={handleDeleteFromAllPages}/>
              </div>
            { stateReportBuilder.objectInfocus.Type==="Text" &&
            <div style={{border:"1px solid #ddd",borderRadius:"5px",padding:"0.2rem",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            <h4>Text Settings</h4>
            <TextSettings/>
            </div>}
            </div>
            </Modal>
    )
  }
  
  
  function handleBackgroundStyling(v,stateReportBuilder,dispatchReportBuilder){
    const newStyle = {"background":v}
    const pageIndex = stateReportBuilder.pageCurrent
    const item = stateReportBuilder.objectInfocus
    const pages = stateReportBuilder.pages
    handleObjectDetails({newStyle,pages,pageIndex,item,dispatchReportBuilder})
  }
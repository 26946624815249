import React, { useMemo, useState } from 'react'
import { useAppInfo } from 'AppState'
import MetricSelectionModal from './MetricSelectionModal'

export default function MetricStockFinancials({metricSelected,handleMetricSelected,onClose}) {
    const {stateStock} = useAppInfo()
    const {metrics} = stateStock
    const metricsFinancialStatement = metrics?.metricFinancialStatement

  const metricInfo = useMemo(() => {
    const baseMetricCategoryList = {
      "Income Statement":metricsFinancialStatement?.IS,
      "Balance Sheet":metricsFinancialStatement?.BS,
      "Cash Flow Statement":metricsFinancialStatement?.CF,
      "Ratios":metricsFinancialStatement?.Ratio
    };
  
    return Object.entries(baseMetricCategoryList).flatMap(([category, names]) => 
      names ? names.map(name => ({ category, name })) : []
    );
  }, [metricsFinancialStatement]);
  
  
  
  return (
    <MetricSelectionModal 
    onClose={onClose}
    modal={true}
    title={"Metric Selection"}
    metricInfo={metricInfo}
    metricSelected={metricSelected} 
    handleMetricSelected={handleMetricSelected}/>

  )
}

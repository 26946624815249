import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { RecessionData } from 'content/contentEconomy';
import { assetCode, dfin } from 'content/constants';
import { useAppInfo } from 'AppState';
import { handleObjectDetails } from '../utilsReport';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import SearchTickerDropdown from 'search/SearchTickerDropdown';


require("highcharts/modules/accessibility")(Highcharts);
Highcharts.setOptions({
  plotOptions: {
    series: {
      animation: false,
    },
  },
  lang: { rangeSelectorZoom :"" } 
});

const LineChartReport = ({ ticker,item,chartColor="blue",chartType="area", title="", zoneRGseries=false, rgValueMin=0, recession=false, navigator=false }) => {
  const {stateReportBuilder,dispatchReportBuilder,stateTicker} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const [color,setColor] = useState(chartColor)
  const {pages,pageCurrent} =  stateReportBuilder
  const [tickerSel,setTickerSel] = useState()
  const [data,setData] = useState([])
  const [mouseEnter,setMouseEnter] = useState(false)
  const { close } = useMultiTickerInfo([tickerSel]);
 
  useEffect(()=>{
    if (close.length>0){
        setData(close)
    }
  },[close])

  useEffect(()=>{
    setTickerSel(stateTicker.tickerSelected)
  },[stateTicker.tickerSelected])

 function handleTicker(ticker){
  const updateContent = {TickerSelected:ticker}
  handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
  setTickerSel(ticker)
  let e = undefined
  handleTickerChange({ticker})
}
function handleColor(v){
  const updateContent = {ChartColor:v}
  handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
  setColor(v)  
}


  const lastDataPoint = data.length > 0 && data[data.length - 1];

  const seriesData = useMemo(
    () => ( data.length > 0) && [
      {
        name: tickerSel,
        data: data.map((entry) => [
          new Date(entry.Date || entry.date).getTime(),
          entry[tickerSel],
        
        ]),
        color:chartColor,
      },
    ],
    [data, tickerSel,chartColor]
  );

  const options = useMemo(
    () => (data.length > 0) && ({
      chart: {
        zoomType: 'x',
        type: chartType,
        animation: false
      },
      title: {
        text: tickerSel,
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
        showLastLabel: true,
        min: new Date(data[0].Date || data[0].date).getTime(),
        max: new Date(lastDataPoint.Date || lastDataPoint.date).getTime(),
        plotBands: recession && RecessionData.map((recession, index) => ({
          from: new Date(recession.from).getTime(),
          to: new Date(recession.to || recession.from).getTime(),
          color: index % 2 === 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
          label: {
            text: recession.label,
            rotation:90,
            verticalAlign:"bottom",
            y:-50
            
          },
          
        }))
        
      },
      yAxis: {
        title: {
          text: 'Values',
        },
        plotLines: zoneRGseries && [{
          value: rgValueMin, // Value where the horizontal line will be placed
          color: 'black', // Color of the line
          dashStyle: 'dot', // Dash style for dotted line
          width: 2, // Width of the line
          zIndex: 5, // zIndex to make sure it's above the grid
          label: {
            text: 'Investment Cost', // Optional label for the line
            style: {
              fontSize: '1rem', // Font size
              align: 'right',
              x: -20,
              backgroundColor: '#ddd' // Background color
            }
          }
        }],
      },
      series: seriesData,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
          zones: zoneRGseries && [{
            value: 0,
            color: 'red'
          },
          {
            color: 'green'
          }
        ]
        },
      },
      rangeSelector: {
        allButtonsEnabled: true,
        buttons:[
          {
            type: 'year',
            count: 1,
            text: '1y',
          },
          {
            type: 'year',
            count: 3,
            text: '3y',
          },
          {
            type: 'year',
            count: 5,
            text: '5y',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
          },
          {
            type: 'all',
            text: 'All',
          },
        ],
        // inputEnabled: mouseEnter,
        selected: 4,
        animation: false,
      },
      credits: {
        enabled: false,
      },
      annotations: null,
      navigator: {
        enabled: navigator
    },
      scrollbar: {
        enabled: false
    },

    stockTools :{
      gui:{
      enabled:false}
    },
    accessibility: {
      enabled: false, // Include the accessibility module
    },
    
    }),
    [data, seriesData, recession]
  );
  
  return (
    <div 
    onMouseEnter={()=>setMouseEnter(true)}
    onMouseLeave={()=>setMouseEnter(false)}
    style={{height:"100%",width:"100%",position:"relative"}}>
    
    {mouseEnter && 
    <div style={{position:"absolute",top:"0",width:"100%"
    ,zIndex:"100",background:"white",display:"flex",alignItems:"center"}}>
    {tickerSel && 
    <SearchTickerDropdown
    defaultSearchTerm={tickerSel}
    tickerType={assetCode?.Stocks} 
    handleTickerType='unique'
    handleTicker={(v)=>handleTicker(v[dfin.ticker])} />}
    <input value={color} type='color' onChange={(e)=>handleColor(e.target.value)}></input>
    </div>}

    <div style={{ flex: 1, height: '100%' ,width:"100%"}}>

       <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType="stockChart"
        containerProps={{ style: { height: '100%',width:"100%" } }}
      />
    </div>
    </div>
  );
};

export default LineChartReport;
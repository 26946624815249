import React, { useState } from 'react'
import { dfin, growthMetric } from 'content/constants';
import ScoreTemplate from './ScoreTemplate';
import CompanyVsIndustry from './../VsIndustry/CompanyVsIndustry';
import BorderBox from 'components/Layout/BorderBox';
import CompanyVsIndustryScores from '../VsIndustry/CompanyVsIndustryScores';

export default function CompanyFinancialScore({coInfo}) {
  
  const scoreTitle = "Financial"
  const scoreOverall = coInfo?.[dfin.fiancialRating]
  const scoreDetails = [
    { Title: "Growth", Value: coInfo?.[dfin.growthRating] },
    { Title: "Profitability", Value: coInfo?.[dfin.avgRating] },
    { Title: "Financial Stability", Value: coInfo?.[dfin.avgRating] },
    { Title: "Size", Value: coInfo?.[dfin.sizeRating] }
  ];

  const metrics = {
    "Size:": [dfin.revenue, dfin.ebitda, dfin.assets],
    "Growth": [
      growthMetric(dfin.revenue, "5Y"),
      growthMetric(dfin.fcf, "5Y"),
      growthMetric(dfin.assets, "5Y"),
    ],
    "Profitability:": [dfin.grossProfitMargin, dfin.ebitdaMargin, dfin.netProfitMargin],
    "Financial Stability:": [dfin.de, dfin.cr],
    "Return on Capital:": [dfin.roic],
  };
  


  const moreInfoContent = (
    <>

    {/* <RadarChart/> */}
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
  {Object.entries(metrics).map(([title, metricList]) => (
    <BorderBox sx={{ height: "auto" }} key={title}>
      <h4 style={{ fontWeight: "bold" }}>{title}</h4>
      {metricList.map((metric, index) => (
        <CompanyVsIndustry coInfo={coInfo} metric={metric} key={index} />
      ))}
    </BorderBox>
  ))}
</div>
    </>
  )
  return (
    <CompanyVsIndustryScores title={"Growth"} metricList={metrics["Growth"]} coInfo={coInfo}/>
  )
}

import React, { memo, useEffect, useState } from "react";
import SelectBox from "components/Inputs/SelectBox";
import TableGen from "components/Table/TableGen";
import useCorrelation from "data/technicals/useCorrelation";
import LoadingSkeletonTable from "components/Loading/LoadingSkeletonTable";
import PageSettingsModal from "components/Template/PageSettingsModal";


const Correlation = memo(function Correlation({tickerList,tickerDict}) {
  const [dateFrequency,setDateFrequency] = useState("Daily")
  const [period,setPeriod] = useState(360)
  const {corr,corrLoading} = useCorrelation(tickerList,dateFrequency,period)
  const [corrSetting,setCorrSetting] = useState(false)
  function handleDateFreq(e,v){
    setDateFrequency(v)
    setCorrSetting(false)
  }

  function handlePeriod(e,v){
    setPeriod(v)
    setCorrSetting(false)
  }

  return ( 
  <div style={{width:"100%",boxSizing:"border-box"}}>
{(corrLoading) && <LoadingSkeletonTable length={tickerList.length}/>
      }

  {(corr.length>0 && !corrLoading) &&
  <>
  <TableGen 
  tickerDict={tickerDict} 
  data={corr} 
  color="redGreen" 
  tableSettings={{metricSettings:true}}
  tableHeader={`Correlation ${dateFrequency}, Close Price`}  
  handleTableSettings={()=>setCorrSetting(true)}
  tableLayout="fixed"/>

  {corrSetting && <PageSettingsModal title="Correlation Settings" onClose={()=>setCorrSetting(false)}>
  <SelectBox  variant="standard" options={["Daily","Weekly","Monthly"]} 
  id={"DateFreq Type"} value={dateFrequency} onChange={handleDateFreq} label={"Date Frequency"}/>
   <SelectBox variant="standard" options={[10,50,100,200,400,800,1600,3200]} 
    id={"Period Type"} value={period} onChange={handlePeriod} label={"Period"}/>
    </PageSettingsModal>}
  </>
  }
  

  </div>
   
  
  )

});

export default Correlation
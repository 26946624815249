import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

export default function useScreenerForex() {
  const { dispatchScreener, stateScreener } = useAppInfo()

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      dispatchScreener({"loadingForex":true });

      const response = await axios.get(`${BASE_URL}/screenerForex/`, {
        params: {
          ticker: JSON.stringify(stateScreener.tickerList),
          page: 1,
        },
      });
      const data = response.data;
      dispatchScreener({"forex":data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatchScreener({"loadingForex":false});
    }
  }
}

import React, { useState, useEffect } from 'react';
import { useAppInfo } from 'AppState';
import { Paper, Box, Typography, Button } from '@mui/material';
import TooltipInfo from 'components/Modal/TooltipInfo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import BorderBox from 'components/Layout/BorderBox';
import CircleScore from 'components/Charts/CircleScore';
import OverviewBox from './Template/OverviewBox';
import AccordionTS from 'components/Surface/AccordionTS';
import PopoverButton from 'components/Modal/PopoverButton';
import TextWithBorders from 'components/Font/TextWithBorders';

export default function ProfileCompletion({displayType="button"}) {
  const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
  const [hover, setHover] = useState(false);
  const navigate = useNavigate()
  const {profileCompletion} = stateMyFinancials
  const totalItems = Object.keys(profileCompletion).length;
  const completedItems = Object.keys(profileCompletion).filter(key => profileCompletion[key]).length;
  const completionPercentage = Math.round((completedItems / totalItems) * 100);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const completed = [];
  const notCompleted = [];

  Object.entries(profileCompletion).forEach(([key, value]) => {
    if (Object.keys(itemsLink).includes(key)){
    if (value) {
      completed.push(key);
    } else {
      notCompleted.push(key);
    }}
  });

  
  function handleNavigate(link){
      navigate(`/MyFinancials/${link}`)
  }
  const strokeWidth = 15; // Fixed stroke width
  const viewBoxSize = 120; // ViewBox size which SVG uses to calculate relative positions


  return (
    <>
    {completionPercentage < 100 && 
    <>
    {displayType !=="button" && 
      <div style={{padding:"0.5rem",background:"#f8f8f8",height:"auto",width:"100%",boxSizing:"border-box"}}>
      <TextWithBorders text={"Profile Completion"}></TextWithBorders>
      <div style={{display:"flex",flexWrap:"wrap",gap:"1rem",alignItems:"center"}}>
        <CircleScore 
        width='60px'
        color={"blue"}
        title='completion'
        score={completedItems} 
        totalScore={totalItems}/>
       <div style={{flex:1,display:"flex",flexDirection:"column"}}>
  {notCompleted.length>0 && 
  <PopoverButton 
  showLabel
  title={"To be Completed"}>
  <>
  {notCompleted.map(obj=><Button 
   onClick={()=>handleNavigate(itemsLink[obj]?.link)}
  key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
  ,textAlign:"left",width:"100%"}}>
      <CheckCircleOutlineIcon fontSize='small' sx={{color:'gray'}}/>
      <Typography fontSize={"small"} onClick={()=>handleNavigate(itemsLink[obj]?.link)} variant="subtitle1" component="div">{itemsLink[obj]?.name}</Typography>
      <ArrowOutwardIcon fontSize='small' sx={{color:'gray',marginLeft:"auto"}}  onClick={()=>handleNavigate(itemsLink[obj]?.link)}/>
  </Button>)}
  </>
  </PopoverButton>
  }
  {completed.length>0 &&  <PopoverButton title='Completed' showLabel>
  <>
  {completed.map(obj=><Button 
   onClick={()=>handleNavigate(itemsLink[obj]?.link)}
  key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
  ,textAlign:"left",width:"100%"}}>
      <CheckCircleIcon fontSize='small' sx={{color:'green'}}/>
      <Typography sx={{color:"black"}} fontSize={"small"} variant="subtitle1" component="div">{itemsLink[obj]?.completedName}</Typography>
      <ArrowOutwardIcon  fontSize='small' sx={{color:'gray',marginLeft:"auto"}} />
  </Button>)}</>
        </PopoverButton>}
        </div>
        </div>
      </div>}
    {displayType==="button" && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", 
    alignItems: "center",position:"relative"}}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <Button sx={{
      minWidth:"0",marginLeft:"auto",padding:0
      ,flexDirection:"column",textTransform:"none",color:"blue"}}>
        <div style={{width:"25px",height:"24px"}}>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
        preserveAspectRatio="xMidYMid meet"
      
      >
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={(viewBoxSize - strokeWidth) / 2}
          fill="transparent"
          stroke="#F8F8F8"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={(viewBoxSize - strokeWidth) / 2}
          fill="transparent"
          stroke="blue"
          strokeWidth={strokeWidth}
          strokeDasharray={2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
          strokeDashoffset={(1 - completionPercentage / 100) * 2 * Math.PI * (viewBoxSize - strokeWidth) / 2}
          transform={`rotate(-90 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
        />
        <text
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
          fontSize="2.5em"
          fill="#666"
        >
          {`${Math.round(completionPercentage)}%`}
        </text>
      </svg></div>
      <span style={{ fontSize: "0.8rem" }}>Profile Completion</span>
      </Button>
     
      {hover && (
        <TooltipInfo 
        width='300px'
        position="absolute" top="100%" 
        sx={{zIndex:"10000"}}>
              <div style={{ padding: '10px',display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  <strong>Profile Completion: {`${Math.round(completionPercentage)}%`}</strong>
  <strong>Complete the following:</strong>
  {notCompleted.length>0 && notCompleted.map(obj=><Button 
   onClick={()=>handleNavigate(itemsLink[obj]?.link)}
  key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
  ,textAlign:"left",width:"100%"}}>
      <CheckCircleOutlineIcon fontSize='small' sx={{color:'gray'}}/>
      <Typography fontSize={"small"} onClick={()=>handleNavigate(itemsLink[obj]?.link)} variant="subtitle1" component="div">{itemsLink[obj]?.name}</Typography>
      <ArrowOutwardIcon fontSize='small' sx={{color:'gray',marginLeft:"auto"}}  onClick={()=>handleNavigate(itemsLink[obj]?.link)}/>
  </Button>)}
 
 
  {completed.length>0 && 
  <>
   <strong>Completed:</strong>
  {completed.map(obj=><Button 
   onClick={()=>handleNavigate(itemsLink[obj]?.link)}
  key={obj} style={{display:"flex",alignItems:"center",gap:"0.4rem",textTransform:"none"
  ,textAlign:"left",width:"100%"}}>
      <CheckCircleIcon fontSize='small' sx={{color:'green'}}/>
      <Typography sx={{color:"black"}} fontSize={"small"} variant="subtitle1" component="div">{itemsLink[obj]?.completedName}</Typography>
      <ArrowOutwardIcon  fontSize='small' sx={{color:'gray',marginLeft:"auto"}} />
  </Button>)}</>}
  
</div>
        </TooltipInfo>
      )}
    </div>}
    </>}
    </>
  );
}



const itemsLink = {assets:{link:"Assets",name:"Add Assets",completedName:"Assets Added"},
liabilities:{link:"Liabilities",name:"Liabilities",completedName:"Liabilities Added"},
portfolio:{link:"Portfolio",name:"Create New Investment Portfolio",completedName:"Investment Portfolio Created"}
,incomeMonthly:{link:"Cashflow",name:"Add Income",completedName:"Income Added"},
expenseMonthly:{link:"Cashflow",name:"Add Expense",completedName:"Expense Added"},
goalsPeriodic:{link:"Goals",name:"Add Goals",completedName:"Goals Added"},
netCashAllocate:{link:"Cashflow",name:"Allocate Cashflow",completedName:"Cashflow Allocated"},
investmentPreference:{link:"InvestmentPreferences",name:"Set Investment Preferences",completedName:"Investment Preference Set"}
,riskTolerance:{link:"RiskManagement",name:"Assess Risk Tolerance",completedName:"Risk Tolerance Assessed"}
}
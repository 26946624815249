function TotalNetWorth({ assetDist, liabilityDist }) {
  const assetTotals = TotalAmount(assetDist);
  const liabilityTotals = TotalAmount(liabilityDist);
  let netTotals = {};

  Object.keys(assetTotals).forEach(period => {
      netTotals[period] = assetTotals[period] - liabilityTotals[period];
  });

  return {
      totalAssetDist: assetTotals,
      totalLiabilityDist: liabilityTotals,
      totalNetworthDist: netTotals
  };
}

function TotalNetCash(inflowDist, outflowDist, beginningBalance = 0) {
  const inflowTotals = TotalAmount(inflowDist);
  const outflowTotals = TotalAmount(outflowDist);
  let netCashTotals = {};

  Object.keys(inflowTotals).forEach(period => {
      netCashTotals[period] = inflowTotals[period] - outflowTotals[period] + beginningBalance;
  });

  return netCashTotals;
}

function TotalIncomeExpense(incomeDist, expenseDist) {
  const incomeTotals = TotalAmount(incomeDist);
  const expenseTotals = TotalAmount(expenseDist);
  
  return { 
      totalIncome: incomeTotals, 
      totalExpense: expenseTotals 
  };
}

function TotalSummaryDist(data, beginningBalance = 0) {
  const totals = TotalAmount(data);
  Object.keys(totals).forEach(period => {
      totals[period] += beginningBalance;
  });
  return totals;
}

function TotalAmount(data) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;

    let totals = {
        m: 0, 
        q: 0, 
        y: 0, 
        '3y': 0, 
        '5y': 0, 
        '10y': 0, 
        '>10y': 0
    };

    data.forEach(item => {
        const itemDate = new Date(item.date);
        const itemYear = itemDate.getFullYear();
        const itemMonth = itemDate.getMonth();
        const itemQuarter = Math.floor(itemMonth / 3) + 1;
        const yearDiff = itemYear - currentYear;

        if (itemYear === currentYear) {
            if (itemMonth === currentMonth) {
                totals.m += item.amount;
            }
            if (itemQuarter === currentQuarter) {
                totals.q += item.amount;
            }
            totals.y += item.amount;
        }

        if (yearDiff < 3) {
            totals['3y'] += item.amount;
        }
        if (yearDiff < 5) {
            totals['5y'] += item.amount;
        }
        if (yearDiff < 10) {
            totals['10y'] += item.amount;
        }
        if (yearDiff >= 10) {
            totals['>10y'] += item.amount;
        }
    });

    return totals;
}

export {TotalAmount,TotalIncomeExpense,TotalNetWorth,TotalSummaryDist,TotalNetCash}
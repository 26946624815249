import React, { useEffect, useState } from 'react'
import Guidance from 'resource/guidance/Guidance'
import { useLocation} from 'react-router-dom'
import {menuResource } from 'nav/menu'
import Doodle from 'components/Drawing/Doodle'
import { Paper } from '@mui/material'
import Glossary from 'resource/Glossary'
import Learn from 'resource/Learn'
import Whiteboard from 'resource/whiteboard/Whiteboard'
import { PageMainLayout } from 'layout/PageLayout'

export default function Resource() {
  const [mainMenuSel,setMainMenuSel] = useState("Guide")
  const location = useLocation()
  const pathName = location.pathname
  const subPage = pathName.split("/")[2]
  
  const infoContent = {
    "Guide":<Guidance/>,
    "Learn":<Learn/>,
    "Glossary":<Glossary/>,
    "MindMap":<Whiteboard/>,
    "Doodle":
    <div style={{padding:"1rem",width:"100%",height:"100%",boxSizing:"border-box"}}>
      <Paper style={{width:"100%",height:"100%"
    ,position:"relative",boxSizing:"border-box"}}><Doodle/></Paper></div>
  }
 
  
  useEffect(()=>{
    if (subPage){
    const menuSel =  menuResource.filter(obj=>obj.link===subPage)[0]?.name
    setMainMenuSel(menuSel)}
    else{
      setMainMenuSel("Guide")
    }
  },[subPage])



  return (
   <PageMainLayout
   pageName='Resource'
   menuOptions={menuResource}
   menuSel={mainMenuSel}
   tsAssistant={true}
   >
    {infoContent[mainMenuSel]}

    </PageMainLayout>
  )
}

import React, { useEffect, useState } from 'react';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import MapChart from 'components/Charts/MapChart';
import useEconGlobal from 'data/economy/useEconGlobal';
import useEconGlobalIndicators from 'data/economy/useEconGloablIndicators';
import SelectBox from 'components/Inputs/SelectBox';
import Toggle from 'components/Inputs/Toggle';
import Globe from 'components/Charts/Globe';
import { Paper } from '@mui/material';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import LoadingSkeletonHeatmap from 'components/Loading/LoadingSkeletonHeatmap';

export default function EconMap() {
  
  const { econGlobal } = useEconGlobal();
  const { econGlobalIndicators } = useEconGlobalIndicators();
  const [globalIndicatorSel, setGlobalIndicatorSel] = useState();
  const [globalData, setGlobalData] = useState();
  const [tableData,setTableData] = useState([])
  const [mapData, setMapData] = useState([]);
  const [tabIndex,setTabIndex] = useState(0)
  const [yearSel,setYearSel] = useState("YR2022")
  const years = [ "YR2010", "YR2011", "YR2012", "YR2013", "YR2014", "YR2015", "YR2016", "YR2017", "YR2018", "YR2019", "YR2020", "YR2021", "YR2022"]
  const cols = [,"ISO 2","Country", "YR2010", "YR2011", "YR2012", "YR2013", "YR2014", "YR2015", "YR2016", "YR2017", "YR2018", "YR2019", "YR2020", "YR2021", "YR2022"];
  const categories = {
    G20: ['Argentina', 'Australia', 'Brazil', 'Canada', 'China', 'France', 'Germany', 'India', 'Indonesia', 'Italy', 'Japan', 'Mexico', 'Russia', 'Saudi Arabia', 'South Africa', 'South Korea', 'Turkey', 'United Kingdom', 'United States'],
    'North America': ['Canada', 'United States', 'Mexico'],
    "South America" : [
      "Argentina",
      "Bolivia (Plurinational State of)",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela (Bolivarian Republic of)"
    ],
    Europe: ['Germany', 'France', 'United Kingdom', 'Italy', 'Spain', 'Netherlands', 'Belgium', 'Switzerland', 'Austria', 'Portugal', 'Sweden', 'Denmark', 'Norway', 'Finland', 'Ireland', 'Greece', 'Poland', 'Czechia', 'Hungary', 'Romania'],
    Asia: ['China', 'India', 'Japan', 'South Korea', 'Indonesia', 'Saudi Arabia', 'Turkey'],
    Australia: ['Australia',"New Zealand"],
    Africa: ['South Africa', 'Nigeria', 'Kenya', 'Egypt', 'Algeria', 'Morocco'],
    World: []
  };
  const categoryList = Object.keys(categories)
  const econGlobalIndicatorsList = econGlobalIndicators.map(obj => obj.Indicator);

  useEffect(() => {
    if (econGlobalIndicators && econGlobal) {
      setGlobalIndicatorSel(econGlobalIndicatorsList[0]);
      const econG = econGlobal.filter(obj => obj.Indicator === econGlobalIndicatorsList[0]);
      setGlobalData( econG.sort((a, b) => a["Country"].localeCompare(b["Country"])));
      
    }
  }, [econGlobalIndicators,econGlobal]);

  useEffect(() => {
    if (globalIndicatorSel && econGlobal) {
      const econG = econGlobal.filter(obj => obj.Indicator === globalIndicatorSel);
      setGlobalData( econG.sort((a, b) => a["Country"].localeCompare(b["Country"])));
      const updatedMapData = econG.map(obj => ({
        value: obj["YR2022"],
        name: obj["ISO 2"],
        ...obj, // Preserve other properties
      }));
      setMapData(updatedMapData);
    }
  }, [globalIndicatorSel]);

  useEffect(() => {
    if (globalData) {
      const updatedMapData = globalData.map(obj => ({
        value: obj[yearSel],
        name: obj["ISO 2"],
        ...obj, // Preserve other properties
      }));
      setMapData(updatedMapData);
    }
  }, [globalData,yearSel]);
  

  function handleTabChange(index){
    setTabIndex(index)
  }

  useEffect(()=>{
    const categorySel = categoryList[tabIndex]
    if (globalData){
    if (categorySel !=="World"){
    const tableData = globalData.filter(obj=>categories[categorySel].includes(obj.Country))
    setTableData(getSpecificArrayObjKeys(tableData,cols))
    }
    else{
        setTableData(getSpecificArrayObjKeys(globalData,cols))
    }
    }
  },[tabIndex,globalData])
  
  const [toggleMode,setToggleMode] = useState(false)
  
  const headerDetails = <Toggle toggleMode={toggleMode} toggleValues={["Map","Globe"]} handleToggle={()=>setToggleMode(!toggleMode)}/>
  return (
    <div className='containerLeftMenuAndContent'>
      <LeftSidebarFloating headerDetails={headerDetails}>
      {(econGlobalIndicators && globalIndicatorSel) && 
      <div className='containerColumnFlex'>
      <SelectBox variant='standard' width='100%' onChange={(e, v) => setYearSel(v)} options={years} 
      label={"Year"} value={yearSel} />
      
      <SelectBox variant='standard' width='100%' 
      onChange={(e, v) => setGlobalIndicatorSel(v)} options={econGlobalIndicatorsList} 
      label={"Indicator"} value={globalIndicatorSel} />
       </div> }
       </LeftSidebarFloating>

      <Paper className='containerMainContent'>
     
      {mapData.length === 0 && <LoadingSkeletonHeatmap/>}
     
      {mapData.length > 0 && 
      <div className='containerColumnFlex1'>
      {!toggleMode && <MapChart data={mapData} title={`${globalIndicatorSel}`}/>}
      {toggleMode && <Globe data={mapData} title={`${globalIndicatorSel}`}/> }
      </div>}
      </Paper>
    </div>
  );
}


import React, { useState } from "react";
import { useAppInfo } from "AppState";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { quickScreeners, quickScreenersDescription } from "content/contentScreenerStocks";
import { dfin } from "content/constants";
import { TextFilter } from "./utilsScreener";
import styles from "./FilterFields.module.css"


export default function QuickScreenModal({onApplyFilters}) {
  const { stateScreener, dispatchScreener } = useAppInfo();
  
  const {filters,screenerDataUser,screenerNameSel} = stateScreener

  const handleFilterChange = (key, filterType, value) => {
    // Create a copy of the current filters
    const updatedFilters = { ...filters };

    // Create an updated filter object
    const updatedFilter = {
      filterType: filterType || "", // Use the provided filterType or an empty string
      value,
    };

    // Assign the updated filter object to the appropriate key in the filters
    updatedFilters[key] = updatedFilter;

    // Dispatch the updated filters
    
    const screenerDataUserUpdate = screenerDataUser 
    screenerDataUserUpdate[screenerNameSel] = updatedFilters
    dispatchScreener({"filters":updatedFilters});
    
  };


  function handleClearFilter(key) {
    const filterUpdate = filters;
    if (filters.hasOwnProperty(key)) {
      delete filterUpdate[key];
      dispatchScreener({"filters":filterUpdate });
    }
  }  

  function handleQuickScreeners(screen) {
    const updatedFilters = { ...stateScreener.filters };
    const currentQuickScreenSel = stateScreener.quickScreenSel;
    for (const key in quickScreeners[screen]) {
      // Assign the object from quickScreeners[screen] to the corresponding key in updatedFilters
      if (currentQuickScreenSel.includes(screen)){
        delete updatedFilters[key]
      }
      else{
      updatedFilters[key] = quickScreeners[screen][key];
    }}
     // Dispatch the updated filters

    const screenerDataUserUpdate = screenerDataUser 
    screenerDataUserUpdate[screenerNameSel] = updatedFilters
    dispatchScreener({"filters":updatedFilters,"screenerDataUser":screenerDataUserUpdate});
    
 

    if (currentQuickScreenSel.includes(screen)) {
      // Screen is already in the array, so remove it
      const updatedQuickScreenSel = currentQuickScreenSel.filter(item => item !== screen);
      dispatchScreener({'quickScreenSel':updatedQuickScreenSel });
    } else {
      // Screen is not in the array, so add it to the beginning
      const updatedQuickScreenSel = [screen, ...currentQuickScreenSel];
      dispatchScreener({'quickScreenSel':updatedQuickScreenSel });
    }
}

  function handleApplyFilters(){
    onApplyFilters()
   }
  return (
    <div style={{ width: "100%", height:"100%",display:"flex",flexDirection:"column",overflow:"auto"}}>
      <h2 style={{borderBottom:"1px solid #ddd",marginBottom:"0.2rem",position:"sticky",top:0,background:"white"}}>Quick Screeners</h2>

      <div style={{flex:1,display:"flex",flexWrap:"wrap",gap:"1rem"
        ,marginTop:"1rem",width:"100%",boxSizing:"border-box"}}>
      {[dfin.country,dfin.gicSector,dfin.gicIndustry, dfin.mScale].map((key) => (
          <div
            key={key}
            className={filters && filters[key] && filters[key].value ? styles.filterFieldCellActive : styles.filterFieldCell}>
            <label style={{ width: "30%" }}>{key}</label>
            <TextFilter data={stateScreener.stocks} 
            filters={filters}
            filterMetric={key} handleFilterChange={handleFilterChange}/>
            {Object.keys(filters).includes(key) && (
              <Tooltip title="Clear Filter">
                <RefreshIcon onClick={() => handleClearFilter(key)} />
              </Tooltip>
            )}
          </div>
        ))}
      {Object.keys(quickScreeners).map((screens) => 
        <div  key={screens} style={{width:"100%",display:"flex",gap:"1rem",borderBottom:"1px solid #ddd",padding:"1rem",boxSizing:"border-box"}}>
        <button onClick={()=>handleQuickScreeners(screens)} style={{display:"flex",gap:"0.3rem", alignItems:"center", width:"20%",borderRadius:"10px",padding:"1rem",background:"transparent",border:"1px solid #ddd",fontSize:"1rem"}}>
            
        {stateScreener.quickScreenSel.includes(screens) && <CheckCircleOutlineIcon style={{color:"green"}}/>}
      {!stateScreener.quickScreenSel.includes(screens) && <AddIcon/>}
            {screens}</button>
        <div style={{flex:1}}>
            {quickScreenersDescription[screens].map((desc,index)=>
                <h4 style={{color:"gray"}} key={index}>{desc}</h4>)}
        </div>
        </div>
      )}
     
    </div>
    <div 
    style={{display:"flex"
    ,justifyContent:"center",borderTop:"1px solid #ddd",paddingTop:"0.5rem",position:"sticky",bottom:0,background:"white"}}>
    <Button variant="contained" onClick={handleApplyFilters}>Apply Filters</Button>
    </div>
    </div>
  );
}

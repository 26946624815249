import { useAppInfo } from 'AppState'
import useAddPortfolio from './useAddPortfolio'
import { useEffect } from 'react'

export default function useAddDefaultPortfolio() {
    const {dispatchPortfolio,statePortfolio} = useAppInfo()
    const {addPortfolio} = useAddPortfolio()
    const {portfolioLoaded,portfolioDefault,portfolioDefaultLoaded,portfolio} = statePortfolio
  
    useEffect(()=>{
      if (portfolioLoaded && !portfolio && portfolioDefaultLoaded){
        dispatchPortfolio({
          portfolio: { "Default": portfolioDefault },
          portfolioSel: portfolioDefault,
          accountNameSel: "Default",
          accountNames: ["Default"]
        });
        addPortfolio("Default",portfolioDefault)
      }
      }
    ,[portfolioLoaded,portfolio,portfolioDefault,portfolioDefaultLoaded])
}

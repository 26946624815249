import React from 'react'
import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecValue() {
  const {stateIndustry} = useAppInfo()

  return (
        <IndSecMetricSummary
        infoName={"Value Summary"}
        defaultMet={[dfin.pe,
          dfin.ps,
          dfin.evSales,
          dfin.evEbitda,
          dfin.roic]}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
  )
}

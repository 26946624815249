import React, { useEffect, useState } from 'react';
import ProductFeatureTemplate from './ProductFeatureTemplate';
import Guidance from 'resource/guidance/Guidance';
import ChatGPTModal from '../../chatGPT/ChatGPTModal';
import Animation from '../../animation/Animation';
import GetStartedButton from './GetStartedButton';

export default function ProductFeature4() {

  const content = (
  
    <div style={{width:"100%",padding:"1rem",position:"relative",
    boxSizing:"border-box",display:"flex"}}>
       <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"20%",alignItems:"center"}}>
    <div style={{width:"100%"}}>
    <Animation type='Knowledge'/>
    </div>
    <GetStartedButton/>
    </div>
  <div style={{display:"flex",flexDirection:"column",boxSizing:"border-box",flex:1,minWidth:"0"
  ,overflow:"auto"}}>
  <Guidance/>
  <div style={{borderRadius:"5px",width:"100%",padding:"0 0.5rem",boxSizing:"border-box"}}>
  {/* <div style={{background:"white",borderRadius:"5px"}}>
    <ChatGPTModal/></div>
   */}
  </div>

  </div>
 
  </div>)
  return (
         <ProductFeatureTemplate content={content} pageNumber={4} id="pageScroll4"/>
  );
}
import {useState } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


const useFindGlossary = () => {
  
  const [glossaryList, setContent] = useState(null);
   
  const [error, setError] = useState(null);
  const [loading,setLoading] = useState(true)

  
  const findGlossary = async (letter) =>
    {
      setLoading(true)
      try {
        const response = await axios.get(`${BASE_URL}/findGlossary/`, {
          params: {
            letter: JSON.stringify(letter),
          },
        });
    
        if (response.status === 200) {
          
          // Data found, set the glossaryList state
  
          setContent(response.data);
          setLoading(false)
          setError(null); // Clear any previous error
        } else if (response.status === 404) {
          // No data found, handle this case as needed

          setContent(null); // Clear any previous data
          setError("No data found");

        } else {
          // Handle other response status codes if needed

          setContent(null); // Clear any previous data
          setError("Error retrieving data");
         
        }
      } catch (err) {
        // Handle network or request error
        setContent(null); // Clear any previous data
        setError("Error retrieving Watchlist data");
        
      }
    };


  return {findGlossary, glossaryList, error,loading};
};

export default useFindGlossary;
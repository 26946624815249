import React, { useEffect,useMemo,useState} from 'react'
import { useAppInfo } from 'AppState'
import TimeSeriesChart from 'components/Charts/TimeSeriesChart'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from "./EconSingleSeriesUI.module.css"
import useEconSeries from 'data/economy/useEconSeries';
import useEconInfo from 'data/economy/useEconInfo';
import InfoBox from 'components/Layout/InfoBox';

export default function EconSeries({seriesName}) {
  const { dispatchSidebar,stateSidebar } = useAppInfo();
  function handleInfoTab(e, value) {
    e.stopPropagation();
    dispatchSidebar({ "sidebarOpen": true,"sidebarRightContent":"information","infoContent":value});
  }

  const {econInfo} = useEconInfo()
  const econCategories = econInfo.filter(obj=>obj.id === seriesName)
  const { econSeries, loadingEconSeries } = useEconSeries(seriesName);
  const sortedEconSeries = useMemo(() => [...econSeries].sort((a, b) => new Date(a.Date) - new Date(b.Date)), [econSeries]);


  return (
    <>
      
      {(!loadingEconSeries && econCategories.length > 0 && sortedEconSeries.length > 0) && (
        <InfoBox>
          <table className={styles.table}>
            <thead>
              <tr>
                <th >Name</th>
                <th >Current Level</th>
                <th >Units</th>
                <th >Popularity</th>
                <th >Report Frequency</th>
                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    {econCategories[0]['Name']}
                    {stateSidebar.showInfoTag &&
                    <InfoOutlinedIcon
                      fontSize='small'
                      onClick={(e) => handleInfoTab(e, econCategories[0]['Name'])}
                    />}
                  </div>
                </td>
                <td>
                  {sortedEconSeries[sortedEconSeries.length - 1][seriesName]?.toFixed(2)}{' '}
                  {econCategories[0]['units'] === 'Percent' ? '%' : ''}
                </td>
                <td>{econCategories[0]['units']}</td>
                <td>{econCategories[0]['popularity']}</td>
                <td>{econCategories[0]['frequency']}</td>
              </tr>
            </tbody>
          </table>
          <TimeSeriesChart
          data={sortedEconSeries} seriesName={seriesName} 
          seriesLabel={econCategories[0]['Name']} 
          recession={true}
          navigator={true}
          />
        </InfoBox>
      )}
    </>
  );
}

import React from 'react'

import TableGen from 'components/Table/TableGen'
import useEarningsCalendar from 'data/company/useEarningsCalendar'
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { dfin } from 'content/constants';
import useEarningsCalendarEOD from 'data/eod/useEarningCalendarEOD';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';

export default function EarningsCalendar() {
  const {earningsCalendar} = useEarningsCalendar()
  
  // gives earnings for all tickers in a particular date range
  // const {earningsCalendarEOD} = useEarningsCalendarEOD()

  const earningsCalendarTicker = earningsCalendar && earningsCalendar
  .filter(obj => obj["EXCHANGE"] === "US")
  .sort((a, b) => {
    // Assuming "REPORT DATE" is a property in the objects you're sorting
    const dateA = new Date(a["REPORT DATE"]);
    const dateB = new Date(b["REPORT DATE"]);

    return dateB - dateA;
  });
  const cols = [dfin.ticker,dfin.coName,dfin.coLogo,dfin.mScale,"REPORT DATE", "DATE", "BEFORE AFTER MARKET", "CURRENCY", "ACTUAL", "ESTIMATE", "DIFFERENCE", "PERCENT"]

  return (
    <>
    {/* {earningsCalendarEOD.length>0 && <TableGen data={earningsCalendarEOD}/>} */}
    {earningsCalendar.length === 0 && <LoadingSkeletonTable length={50}/>}
    {earningsCalendar.length>0 && <TableGen logo={true} data={getSpecificArrayObjKeys(earningsCalendarTicker.splice(0,100),cols)}/>}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen'
import { useAppInfo } from 'AppState'
import useScreenerETF from 'data/screener/useScreenerETF'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import ScreenerTemplate from './ScreenerTemplate'
import { applyFilters } from './utilsScreener'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function EtfMfScreener() {
  const {stateScreener } = useAppInfo()
  useScreenerETF()

  const [filteredData,setFilteredData] = useState([])
  useEffect(()=>{
   if (stateScreener.etf && stateScreener.etf.length>0 && !stateScreener.loadingETF){
   const data = getSpecificArrayObjKeys(stateScreener.etf,cols)
   const filteredData =  applyFilters(stateScreener.filtersETF,data)
  
   setFilteredData(filteredData)
  }
  },[stateScreener.etf])

  function handleFilterUpdate(v){
    setFilteredData(v)
  } 
  return (
    <>
    
    <ScreenerTemplate filterName={"filtersETF"}
     loading={(stateScreener.etf.length>0 && !stateScreener.loadingETF)}
     data={getSpecificArrayObjKeys(stateScreener.etf,cols)} handleFilterUpdate={handleFilterUpdate}>
    {stateScreener.loadingETF && <LoadingSkeletonTable 
    length={50}
    height='20px' width='100%'/>}
    {(!stateScreener.loadingETF && filteredData.length>0) && 
    <TableGen 
    addToWatchlist logo
    data={filteredData}/>}
    </ScreenerTemplate>
    </>
  )
}

const cols = [
  "TICKER",
  "NAME",
  "TYPE",
  "EXCHANGE SHORT NAME",
  "MARKET CAPITALIZATION",
  "BETA",
  "PERFORMANCE 1D",
  "PERFORMANCE 1M",
  "PERFORMANCE 3M",
  "PERFORMANCE 6M",
  "PERFORMANCE 1Y",
  "OPEN",
  "HIGH",
  "LOW",
  "CLOSE",
  "ADJUSTED CLOSE",
  "VOLUME",
  "EMA 50D",
  "EMA 200D",
  "HI 250D",
  "LO 250D",
  "AVGVOL 14D",
  "AVGVOL 50D",
  "AVGVOL 200D",
];

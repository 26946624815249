import React, { useEffect, useState } from "react";
import TickerCardSparkline from "components/Charts/TickerCardSparkline";
import { useAppInfo } from "AppState";
import { marketHeaderDict } from "content/contentMarket";
import { assetCode, dfin } from "content/constants";
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import TableGen from "components/Table/TableGen";
import TabLine from "components/Tabs/TabLine";
import LoadingSkeletonTable from "components/Loading/LoadingSkeletonTable";

const MarketToday = () => {
  const { stateTicker,stateMarket} = useAppInfo();
  const { closeInterMarket } = stateMarket;
  const { tickerInfo } = stateTicker;
  const [tabIndex, setTabIndex] = useState(0);
  function handleTabChange(index) {
    setTabIndex(index);
  }
  const tabContent = [];
  const tickerStocks =
    tickerInfo &&
    tickerInfo
      .filter(
        (obj) => obj[dfin.country] === "USA" && obj["TYPE"] === assetCode?.Stocks
      )
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
      .splice(0, 10);
  const tickerCrypto =
    tickerInfo &&
    tickerInfo
      .filter((obj) => obj["TYPE"] === "CRYPTO")
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
      .splice(0, 10);
  const tickerETF =
    tickerInfo &&
    tickerInfo
      .filter((obj) => obj["TYPE"] === "ETF")
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])
      .splice(0, 10);
  const tickerForex =
    tickerInfo &&
    tickerInfo.filter(
      (obj) =>
        obj["TYPE"] === "Forex" &&
        ["USD", "EUR", "GBP", "CAD", "AUD", "JPY", "INR", "CHF"].includes(
          obj[dfin.ticker]
        )
    );
  const tickerBond =
    tickerInfo &&
    tickerInfo.filter(
      (obj) =>
        obj["TYPE"] === "BOND" && ["US10Y", "US5Y"].includes(obj[dfin.ticker])
    );
  tabContent[0] = tickerStocks && (
    <TableGen
      addToWatchlist
      pagination={false}
      tableLayout="fixed"
      logo={true}
      realTimePrice={true}
      data={getSpecificArrayObjKeys(tickerStocks, [
        dfin.ticker,
        dfin.coName,
        dfin.coLogo,
        "CODE",
      ])}
    />
  );
  tabContent[1] = tickerCrypto && (
    <TableGen
      addToWatchlist
      pagination={false}
      tableLayout="fixed"
      logo={true}
      realTimePrice={true}
      data={getSpecificArrayObjKeys(tickerCrypto, [
        dfin.ticker,
        dfin.coName,
        dfin.coLogo,
        "CODE",
      ])}
    />
  );
  tabContent[2] = tickerETF && (
    <TableGen
    addToWatchlist
      pagination={false}
      tableLayout="fixed"
      logo={true}
      realTimePrice={true}
      data={getSpecificArrayObjKeys(tickerETF, [
        dfin.ticker,
        dfin.coName,
        dfin.coLogo,
        "CODE",
      ])}
    />
  );
  tabContent[3] = tickerForex && (
    <TableGen
    addToWatchlist
      pagination={false}
      tableLayout="fixed"
      logo={true}
      realTimePrice={true}
      data={getSpecificArrayObjKeys(tickerForex, [
        dfin.ticker,
        dfin.coName,
        dfin.coLogo,
        "CODE",
      ])}
    />
  );
  tabContent[4] = tickerBond && (
    <TableGen
      addToWatchlist
      pagination={false}
      tableLayout="fixed"
      logo={true}
      realTimePrice={true}
      data={getSpecificArrayObjKeys(tickerBond, [
        dfin.ticker,
        dfin.coName,
        dfin.coLogo,
        "CODE",
      ])}
    />
  );
  return (
    <div id="categoryMkt" className="containerColumnFlex">
      <TickerCardSparkline
        tickerDict={marketHeaderDict}
        close={closeInterMarket}
      />

      <div style={{ marginTop: "1rem" }}>
        <TabLine
          tabList={["Stocks", "Crypto", "ETF", "Forex", "Bond"]}
          tabContent={[]}
          tabIndex={tabIndex}
          handleTabChange={handleTabChange}
        />
      </div>
      <div className="containerColumnFlex1">
        {!tickerInfo.length > 0 && <LoadingSkeletonTable/>}
        {tickerInfo.length > 0 && tabContent[tabIndex]}
      </div>
    </div>
  );
};

export default MarketToday;

import React, { useEffect, useState } from 'react'
import useTopInvestorsPortfolioHoldingSummary from 'data/financialModelingPrep/TopInvestors/useTopInvestorsPortfolioHoldingSummary'
import TableGen from 'components/Table/TableGen'
import useTopInvestorsPortfolioHoldingDates from 'data/financialModelingPrep/TopInvestors/usePortfolioHoldingDates'
import useTopInvestorsPortfolioHolding from 'data/financialModelingPrep/TopInvestors/usePortfolioHoldings'
import TimeSeriesCategoryChart from 'components/Charts/TimeSeriesCategoryChart'
import PieChartCategory from 'components/Charts/PieChartCategory'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import useTopInvestorsPortfolioHoldingIndustry from 'data/financialModelingPrep/TopInvestors/useTopInvestorPortfolioHoldingIndustry'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo'
import LineChartMulti from 'components/Charts/LineChartMulti'
import { useAppInfo } from 'AppState'
import TopInvestorHeader from './TopInvestorHeader'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import { Paper } from '@mui/material'
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton'


export default function TopInvestorsList({infoSel}) {
  const {statePortfolio} = useAppInfo()
  const {topInvestorSelCIK} = statePortfolio
  const {topInvestorHoldingSummary} = useTopInvestorsPortfolioHoldingSummary(topInvestorSelCIK)
  
  const {topInvestorHoldingDates} = useTopInvestorsPortfolioHoldingDates(topInvestorSelCIK)
  const [portfolioHoldingDates,setPortfolioHoldingDates] = useState([])
  const [portfolioHoldingDateSel,setPortfolioHoldingDateSel] = useState()
  const {topInvestorHolding} = useTopInvestorsPortfolioHolding(topInvestorSelCIK,portfolioHoldingDateSel)
  const {topInvestorHoldingIndustry} = useTopInvestorsPortfolioHoldingIndustry(topInvestorSelCIK,portfolioHoldingDateSel)
  const {close} = useMultiTickerInfo(["SPY"])
  useEffect(()=>{
    if (topInvestorHoldingDates.length>0){
        const dates = topInvestorHoldingDates.map(obj=>Object.values(obj))
        setPortfolioHoldingDates(dates)
        setPortfolioHoldingDateSel(dates[0])
    }
  },[topInvestorHoldingDates])

  const [datasets,setDataSets] = useState([])

  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Overview","Holdings","Analytics"]
 

  useEffect(()=>{
    if (close.length>0 && topInvestorHoldingSummary.length>0){
    const dataSet = [{name:"SPY",data:transformDataForChart(close,"Date","SPY"),chartType:"area"},{name:"marketValue",data:transformDataForChart(topInvestorHoldingSummary,"date","marketValue"),chartType:"area"}]
    setDataSets(dataSet)
  }
  },[close,topInvestorHoldingSummary])

  const tabContent = []
  tabContent[0] = <>
  {topInvestorHoldingSummary.length>0 && 
    <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%"}}>
    <div style={{height:"50%"}}>
    {<TimeSeriesCategoryChart metricSelected={["marketValue"]} chartType='area' data={topInvestorHoldingSummary}/>}
    </div>
    <div style={{flex:1,overflow:"auto"}}>
    <TableGen data={topInvestorHoldingSummary}/></div>
    </div>
    }
    </>
  
  tabContent[1] = <>
  {topInvestorHolding.length>0 && 
  <div style={{padding:"1rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
  <div style={{padding:"1rem",border:"1px solid #ddd",borderRadius:"5px",width:"100%",height:"50%",boxSizing:"border-box"}}>
  <h2 style={{color:"gray"}}>Asset Allocation: Securities</h2>
  <PieChartCategory height='100%' data={getSpecificArrayObjKeys(topInvestorHolding,["securityName","marketValue"])} category={"securityName"} weightCategory={"marketValue"} weightType="weighted"/>
  </div>
  <div style={{padding:"1rem",border:"1px solid #ddd",borderRadius:"5px",width:"100%",height:"50%",boxSizing:"border-box"}}>
  <h2 style={{color:"gray"}}>Asset Allocation: Industry</h2>
  {topInvestorHoldingIndustry.length>0 && <PieChartCategory data={topInvestorHoldingIndustry} category={"industryTitle"} weightCategory={"weight"} weightType="weighted"/>}
  </div>
  <TableGen height='auto' data={topInvestorHolding}/>
  </div>
  }
  </>

  tabContent[2] = <>
  {topInvestorHoldingSummary.length>0 && 
  <>
  <div style={{height:"60%",width:"100%"}}>
  {datasets.length>0 && <LineChartMulti title='Portfolio Value vs SPY' datasets={datasets}/>}
  </div>
    <div style={{height:"50%"}}>
  <TimeSeriesCategoryChart data={topInvestorHoldingSummary} metricSelected={["performance1yearRelativeToSP500Percentage"]}/>
  </div>
  <TableGen data={getSpecificArrayObjKeys(topInvestorHoldingSummary,analyticsKeys)}/>
  </>
  }
  </>


  useEffect(()=>{
    if (infoSel){
      setTabIndex(tabList.indexOf(infoSel))
    }
  },[infoSel])


  return (

    <div className='containerColumnFlex'>
    
    <TopInvestorHeader/>

    <div style={{flex:1, width:"100%"
    ,overflow:"auto",boxSizing:"border-box",display:"flex",padding:"0.5rem",gap:"0.5rem"}}>

    <LeftSidebarFloating header={"Information"}>
      <div style={{display:"flex",flexDirection:"column"}}>
      {tabList.map((obj,index)=>
      <LeftSidebarButton key={index} 
      selected={obj===tabList[tabIndex]}
      name={obj} 
      onClick={(v)=>setTabIndex(tabList.indexOf(v))}/>)}
   </div>
    </LeftSidebarFloating>

    
    <Paper
    style={{flex:1,height:"100%",overflow:"auto"}}>
    {tabContent[tabIndex]}
    </Paper>
    </div>
    </div>
  )
}


const analyticsKeys = [
    "date",
    "performance1year",
    "performancePercentage1year",
    "performance3year",
    "performancePercentage3year",
    "performance5year",
    "performancePercentage5year",
    "performanceSinceInception",
    "performanceSinceInceptionPercentage",
    "performanceRelativeToSP500Percentage",
    "performance1yearRelativeToSP500Percentage",
    "performance3yearRelativeToSP500Percentage",
    "performance5yearRelativeToSP500Percentage",
    "performanceSinceInceptionRelativeToSP500Percentage"
]

const transformDataForChart = (data, dateFieldName, valueFieldName) => {
  return data.map((item) => ({
    date: new Date(item[dateFieldName]).getTime(),
    value: item[valueFieldName],
    chartType: "line"
  }));
};
import React from 'react'
import { useAppInfo } from 'AppState'
import IndSecPerformance from './Summary/IndSecPerformance'
import IndSecConstituentsPerformance from './Constituents/IndSecConstituentsPerformance'
import IndSecSummaryFinancials from './Summary/IndSecSummaryFinancials'
import IndSecConstituentsFinancials from './Constituents/IndSecConstituentsFinancials'
import IndSecValue from './Summary/IndSecValue'
import IndSecConstituentsValue from './Constituents/IndSecConstituentsValue'
import IndSecSizeShare from './Summary/IndSecSizeShare'
import IndSecConstituentsSize from './Constituents/IndSecConstituentsSize'
import IndSecGrowth from './Summary/IndSecGrowth'
import IndSecConstituentsGrowth from './Constituents/IndSecConstituentsGrowth'
import IndSecMarkerStats from './Summary/IndSecMarketStats'
import IndSecConstituentsMarketStats from './Constituents/IndSecConstituentsMarketStats'
import IndSecConstituentsTechnicals from './Constituents/IndSecConstituentsTechnicals'
import IndSecTechnicals from './Summary/IndSecTechnicals'

export default function IndSecContent({info}) {
  const {stateIndustry} = useAppInfo()
  const {analysisOptionSel} = stateIndustry
  const analysisMode = analysisOptionSel === "Summary" ? true : false

  const infoList = {
    "Financials":analysisMode ? <IndSecSummaryFinancials/> : <IndSecConstituentsFinancials/>,
    "Technicals":analysisMode? <IndSecTechnicals/>:<IndSecConstituentsTechnicals />,
    "Performance":analysisMode? <IndSecPerformance/>:<IndSecConstituentsPerformance/>,
    "Value":analysisMode? <IndSecValue/>:<IndSecConstituentsValue/>,
    "Size & Share":analysisMode? <IndSecSizeShare/>:<IndSecConstituentsSize/>,
    "Growth":analysisMode?<IndSecGrowth/>:<IndSecConstituentsGrowth/>,
    "Market Stats":analysisMode?<IndSecMarkerStats/>:<IndSecConstituentsMarketStats/>
  }
  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%"}}>
   
    <div style={{flex:1,width:"100%",boxSizing:"border-box"}}>
    {infoList[info]}
    </div>
   
    </div>
  )
}

import React from 'react'
import ProductFeatureTemplate from './ProductFeatureTemplate';
import { productFeatures } from 'content/contentProduct';
import Animation from '../../animation/Animation';
import Video from 'components/Media/Video';
import GetStartedButton from './GetStartedButton';

export default function ProductFeature2() {
   
  const content = 
  <div style={{width:"100%",padding:"1rem",position:"relative",
  boxSizing:"border-box",display:"flex"}}>
 <div style={{flex:1,minWidth:"0",position:"relative"}}>
      <Video src={"\Portfolio.mp4"}/>
    </div>
    <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"20%",alignItems:"center"}}>
    <div style={{width:"100%"}}>
    <Animation type='Portfolio'/>
    </div>
    <GetStartedButton/>
    </div>
   
</div>

  return (
   <ProductFeatureTemplate 
   alignHeader='right'
   content={content} pageNumber={1} id='pageScroll1' tagLineHeader={productFeatures[0].tagLineHeader}/>

     

  )
}

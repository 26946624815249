import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Tooltip } from '@mui/material';

import React from 'react'

export default function SearchBar2({handleSearch,placeholder="Search"}) {
  return (
    <div style={{borderBottom:"1px solid #ddd",width:"100%",display:"flex"}}>
<IconButton type="button" aria-label="search">
    <SearchIcon />
  </IconButton>
  <InputBase
sx={{ ml: 1, flex: 1}}
placeholder={placeholder}
inputProps={{ 'aria-label': {placeholder} }}
onChange={(e) => handleSearch(e.target.value)}
/></div>
  )
}


import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { dfin } from 'content/constants';
import { formatNumber } from "utils/utilGeneral";

require("highcharts/modules/accessibility")(Highcharts);
Highcharts.setOptions({
	plotOptions: {
		series: {
			animation: false
		}
	}
});
export default function BarChartIndSec({data,categoryMetric,metricSelected, dataLabel, chartLegend,orientation="bar"}) {
    let chartOrientation = orientation.toLowerCase()
    
    const minChartHeight = 300; // Set a minimum chart height (in pixels)
    let chartHeight = `${Math.max(data.length, 1) * 20}px`; // Calculate the chart height
  
    // If the calculated height is smaller than the minimum height, use the minimum height instead
    if (parseInt(chartHeight) < minChartHeight) {
      chartHeight = `${minChartHeight}px`;
    }
  
    
    const uniqueSectors = [...new Set(data.map((data) => data[categoryMetric]))];
    
    const mScaleCategories = ["Mega Cap", "Large Cap", "Mid Cap", "Small Cap", "Micro Cap"];
    const uniqueMScale = [...new Set(data.map((data) => data[dfin.mScale]))];

    // Filter the mScale categories based on the desired order
    const orderedMScale = mScaleCategories.filter((category) => uniqueMScale.includes(category));
    
    const mScaleGroups = data.reduce((acc, obj) => {
      const mScale = obj[dfin.mScale];
      const marketCap = obj[metricSelected];
    
      if (!acc[mScale]) {
        acc[mScale] = [];
      }
      acc[mScale].push(marketCap);
      return acc;
    }, {});
    
    const chartData = {
      series: orderedMScale.map((mScale) => ({
        name: mScale,
        data: mScaleGroups[mScale],
      })),
      categories: uniqueSectors,
    };

    const options = {
      chart: {
        type: chartOrientation,
        zoomType: 'xy',
        reflow: true,
        height: chartHeight,
        panning: false,
        animation:false
      },
      title: {
        text: metricSelected,
      },
      series: chartData.series,
      xAxis: {
        categories: chartData.categories,
        tickInterval: 1,
        labels: {
          step: 1, // Display all X-axis labels
        },
        opposite: chartHeight > '500px' ? 1 : 0,
        offset: 0,
        lineWidth: 1,
        tickLength: 0,
        // plotBands: [{
        //   color: 'rgba(0,0,0,.25)',
        //   from: selectedTickerIndex - 0.5,
        //   to: selectedTickerIndex + 0.5,
        //   zIndex: 1,
        // }],
      },
      yAxis: {
        title: {
          text: 'Value',
        },
        // labels: {
        //   formatter: function () {
        //     return numeral(this.value).format('0.00a').toUpperCase();
        //   },
        // },
        opposite: chartHeight > '500px' ? 1 : 0,
        offset: 0,
        lineWidth: 1,
        tickLength: 0,
      },
      legend: {
        // enabled:false},
        enabled: chartLegend,
        layout: 'vertical', // Display the legends vertically
        align: 'right', // Align the legends to the right side
        verticalAlign: 'top', // Vertically align the legends to the middle
        borderWidth: 0, // Remove the border around the legends
        x: -10, // Adjust the horizontal position of the legends
        y: 10, // Adjust the vertical position of the legends
        itemStyle: {
          whiteSpace: 'normal', // Allow wrapping of legend items
        },
      },
      plotOptions: {
        chartOrientation: {
          stacking: 'normal', // Enable stacking for the bars
          dataLabels: {
            enabled: dataLabel, // Enable data labels for each bar
            formatter: function () {
              return formatNumber(this.y) // Format the data label values
            },
          },
        },},
        credits: {
          enabled: false, // Hide Highcharts.com credits
        },
        accessibility: {
          enabled: true, // Include the accessibility module
        },
    
    };
  
    return (
      <div style={{width:"100%",overflowY:"auto",flexGrow:1}}>
        
          <HighchartsReact highcharts={Highcharts} options={options} />
        
      </div>
    );
  }
  
import React from 'react';
import { Skeleton } from '@mui/material';
import useMarketNews from 'data/financialModelingPrep/News/useMarketNews';
import LoadingSkeletonNews from 'components/Loading/LoadingSkeletonNews';
import styles from "./MarketNews.module.css"

export default function MarketNews({info}) {
  const {news:newsStock} = useMarketNews()
  const {news:newsCrypto} = useMarketNews("Crypto")
  const {news:newsForex} = useMarketNews("Forex")
  const {news:newsGeneral} = useMarketNews("General")
  const newsInfo = {"Stocks":newsStock,"Crypto":newsCrypto,"Forex":newsForex,"General":newsGeneral}
  const news = newsInfo?.[info] || []
 
  return (
    <>
      <div
       className={styles.news_container}
      >
        {news.length === 0 && <LoadingSkeletonNews/>}
        {news.length>0 && news.map((obj,index) => (
            <div
              onClick={()=>{window.open(obj.url, '_blank')}}
              key={index} // Assuming there's an ID for each news item
              className={styles.news_content}
            >
              <div>
                {obj?.image ? (
                  <img src={obj?.image} className='news_image'/>
                ) : (
                  <Skeleton variant="rect" className='news_image' />
                )}
              </div>
              <div>
                <a href={obj.url} style={{ flex: 1 }} target="blank">
                  {obj.title}
                </a>

                <div style={{ gap: '0.5rem', marginTop: '0.5rem' }}>
                  <h5>
                    {new Date(obj.publishedDate).toLocaleDateString('en-US', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </h5>
                  <h5>Publisher: {obj.site}</h5>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
import React from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';

export default function IndSecConstituentsFinancials() {
  const {stateIndustry,stateStock} = useAppInfo()
  const info = stateIndustry.constiInfo

  const metricOptions = filterElementsWithoutGrowthAndAverage(stateStock.metrics.numCols)

  const defaultMet = [
    dfin.revenue,
    dfin.assets,
    dfin.ebitda,
    dfin.ebitdaMargin,
    dfin.netProfitMargin,
    ];
  const chartTypeOptions = ["Bar"];
  const chartTypeDefault = "Bar";
  
  

  // Replace the following with actual data
  const loadingScreenerISConsti = false;

  return (
      <>
      
      {!loadingScreenerISConsti && info.length > 0 && (
        
        <PeerOverviewMetricComp
    data={info}
    infoName={"Financials"}
      storeId={"IndSecFinancials"}
      defaultMet={defaultMet}
      metricOptions={metricOptions}
      chartTypeDefault={chartTypeDefault}
      chartTypeOptions={chartTypeOptions}
    />
  
)}
    
    </>

  );
}

function filterElementsWithoutGrowthAndAverage(arr) {
    const filteredArray = arr.filter(element => {
      const upperCaseElement = element.toUpperCase();
      return !(upperCaseElement.includes("GROWTH") || upperCaseElement.includes("AVERAGE"));
    });
  
    return filteredArray;
  }

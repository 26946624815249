import React, { useEffect, useState } from 'react';
import TableGen from 'components/Table/TableGen';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import useEconGlobal from 'data/economy/useEconGlobal';
import useEconGlobalIndicators from 'data/economy/useEconGloablIndicators';
import SelectBox from 'components/Inputs/SelectBox';
import TabLine from 'components/Tabs/TabLine';
import { Paper } from '@mui/material';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton';

export default function EconOverviewGlobal({sx={}}) {
  
  const { econGlobal } = useEconGlobal();
  const { econGlobalIndicators } = useEconGlobalIndicators();
  const [globalIndicatorSel, setGlobalIndicatorSel] = useState();
  const [globalData, setGlobalData] = useState();
  const [tableData,setTableData] = useState([])
  const [tabIndex,setTabIndex] = useState(0)
  
  const years = [ "YR2010", "YR2011", "YR2012", "YR2013", "YR2014", "YR2015", "YR2016", "YR2017", "YR2018", "YR2019", "YR2020", "YR2021", "YR2022"]
  const cols = [,"ISO 2","Country", "YR2010", "YR2011", "YR2012", "YR2013", "YR2014", "YR2015", "YR2016", "YR2017", "YR2018", "YR2019", "YR2020", "YR2021", "YR2022"];
  const categories = {
    G20: ['Argentina', 'Australia', 'Brazil', 'Canada', 'China', 'France', 'Germany', 'India', 'Indonesia', 'Italy', 'Japan', 'Mexico', 'Russia', 'Saudi Arabia', 'South Africa', 'South Korea', 'Turkey', 'United Kingdom', 'United States'],
    'North America': ['Canada', 'United States', 'Mexico'],
    "South America" : [
      "Argentina",
      "Bolivia (Plurinational State of)",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Guyana",
      "Paraguay",
      "Peru",
      "Suriname",
      "Uruguay",
      "Venezuela (Bolivarian Republic of)"
    ],
    Europe: ['Germany', 'France', 'United Kingdom', 'Italy', 'Spain', 'Netherlands', 'Belgium', 'Switzerland', 'Austria', 'Portugal', 'Sweden', 'Denmark', 'Norway', 'Finland', 'Ireland', 'Greece', 'Poland', 'Czechia', 'Hungary', 'Romania'],
    Asia: ['China', 'India', 'Japan', 'South Korea', 'Indonesia', 'Saudi Arabia', 'Turkey'],
    Australia: ['Australia',"New Zealand"],
    Africa: ['South Africa', 'Nigeria', 'Kenya', 'Egypt', 'Algeria', 'Morocco'],
    World: []
  };
  const categoryList = Object.keys(categories)
  const econGlobalIndicatorsList = econGlobalIndicators.map(obj => obj.Indicator);

  useEffect(() => {
    if (econGlobalIndicators && econGlobal) {
      setGlobalIndicatorSel(econGlobalIndicatorsList[0]);
      const econG = econGlobal.filter(obj => obj.Indicator === econGlobalIndicatorsList[0]);
      setGlobalData( econG.sort((a, b) => a["Country"].localeCompare(b["Country"])));
      
    }
  }, [econGlobalIndicators,econGlobal]);

  useEffect(() => {
    if (globalIndicatorSel && econGlobal) {
      const econG = econGlobal.filter(obj => obj.Indicator === globalIndicatorSel);
      setGlobalData( econG.sort((a, b) => a["Country"].localeCompare(b["Country"])));
      const updatedMapData = econG.map(obj => ({
        value: obj["YR2022"],
        name: obj["ISO 2"],
        ...obj, // Preserve other properties
      }));
      // setMapData(updatedMapData);
    }
  }, [globalIndicatorSel]);



  function handleTabChange(index){
    setTabIndex(index)
  }

  useEffect(()=>{
    const categorySel = categoryList[tabIndex]
    if (globalData){
    if (categorySel !=="World"){
    const tableData = globalData.filter(obj=>categories[categorySel].includes(obj.Country))
    setTableData(getSpecificArrayObjKeys(tableData,cols))
    }
    else{
        setTableData(getSpecificArrayObjKeys(globalData,cols))
    }
    }
  },[tabIndex,globalData])
  

  return (
    <>
    <div className='containerLeftMenuAndContent' style={{...sx}}>
     
     <LeftSidebarFloating header="Global Econ">
      <SelectBox width='100%' onChange={(e, v) => setGlobalIndicatorSel(v)} 
      options={econGlobalIndicatorsList} 
      label={"Indicator"} value={globalIndicatorSel} />

     {(econGlobalIndicators && globalIndicatorSel) && 
econGlobalIndicatorsList.map(obj=>
      <LeftSidebarButton selected={globalIndicatorSel===obj}
      onClick={(v)=>setGlobalIndicatorSel(v)}
      key={obj} name={obj}>
      </LeftSidebarButton>
     )}
    
      
      </LeftSidebarFloating>
    
     <Paper className='containerMainContent'>
            <div>
       <TabLine handleTabChange={handleTabChange} tabList={Object.keys(categories)} tabIndex={tabIndex} tabContent={[]}/>
       </div>
       <div className='conainterColumnFlex1'>
      {tableData && <TableGen 
      trend ={true}
      tableLayout='fixed' data={tableData}/>}
      </div>
      </Paper>
      </div>
    </>
  );
}


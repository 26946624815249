import React, { useRef, useState, useEffect } from 'react';
import styles from "./Resizer.module.css";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppInfo } from 'AppState';

export default function Resizer({ orientH = false, orientV = false, children, stylingContainer = {} }) {
  const { stateSidebar, dispatchSidebar } = useAppInfo();
  const resizable = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState({ width: 0, height: 0 });

  const handleMouseDown = (e) => {
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
    setIsDragging(true);
    setInitialPos({ x: clientX, y: clientY });
    setInitialSize({
      width: resizable.current.offsetWidth,
      height: resizable.current.offsetHeight
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const clientX = e.clientX || e.touches[0].clientX;
    const clientY = e.clientY || e.touches[0].clientY;
    const dragOffset = {
      x: clientX - initialPos.x,
      y: clientY - initialPos.y
    };
    if (orientH) {
      const newHeight = initialSize.height + dragOffset.y;
      resizable.current.style.height = `${newHeight}px`;
    }
    if (orientV) {
      const newWidth = initialSize.width - dragOffset.x;
      resizable.current.style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Combine touch and mouse events
  const eventHandlers = {
    onMouseDown: handleMouseDown,
    onMouseMove: handleMouseMove,
    onMouseUp: handleMouseUp,
    onTouchStart: handleMouseDown,
    onTouchMove: handleMouseMove,
    onTouchEnd: handleMouseUp
  };

  useEffect(() => {
    const removeEventListeners = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return removeEventListeners;
  }, [isDragging]);

  const styleContainer = { ...stylingContainer, flexDirection: orientH ? "column" : "row" };

  return (
    <div id="sidebarRightContainer" className={styles.container} 
    style={styleContainer} ref={resizable}>
      {orientV && <div className={isDragging ? styles.resizerVDrag : styles.resizerV} {...eventHandlers} />}
      <div className={styles.content}>
        {children}
      </div>
      {orientH && <div className={styles.resizerV} {...eventHandlers} />}
      <div style={{
        position: "absolute",
        borderRadius: "10px 0 0 10px",
        transform: "translateY(-50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        top: "50%",
        left: "-15px",
        height: "100px",
        width: "15px",
        background: "#eeeeee",
        color: "black",
        zIndex: 5
      }}>
        {!stateSidebar.showSidebarRight ? (
          <ExpandLessIcon sx={{ transform: "rotate(-0.25turn)" }} onClick={() => dispatchSidebar({"showSidebarRight":true })} />
        ) : (
          <ExpandMoreIcon sx={{ transform: "rotate(-0.25turn)" }} onClick={() => dispatchSidebar({"sidebarOpen":false })} />
        )}
      </div>
    </div>
  );
}


import React from 'react'
import { useAppInfo } from 'AppState'
import { defaultMetrics, dfin } from 'content/constants'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecMarkerStats() {
  const {stateIndustry} = useAppInfo()

  return (
        <IndSecMetricSummary
        infoName={"Market Stats Summary"}
        defaultMet={defaultMetrics.MarketStats}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useAppInfo } from 'AppState';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import useHandleUserEvents from './useHandleUserEvents';

export default function ChatContentArea({children}) {
    const [height, setHeight] = useState("500px");
    const [isResizing, setIsResizing] = useState(false);
    const [mouseEnterRZbar, setMouseEnterRZbar] = useState(false);
    const [initialPosH, setInitialPosH] = useState(0);
    const [initialSizeH, setInitialSizeH] = useState(0);
    const resizableContainer = useRef();
    const {handleShowContent,handleNewChat} = useHandleUserEvents()

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };
      }, [isResizing]);
    
      function handleMouseDown(e) {
        setIsResizing(true);
        setInitialPosH(e.clientY);
        setInitialSizeH(resizableContainer.current.offsetHeight);
      }
    
      function handleMouseMove(e) {
        if (!isResizing) return;
        let y = e.clientY > 70 ? e.clientY : 70
        const dragOffsetH = initialPosH - y;
        const newHeight = initialSizeH + dragOffsetH;
        resizableContainer.current.style.height = newHeight > 200 ? `${newHeight}px` : `200px`
        resizableContainer.current.style.top = newHeight > 200 ? `-${newHeight}px` : `-200px`
      }

      function handleMouseUp() {
        setIsResizing(false);
      }
  
  return (
    <div ref={resizableContainer}
    style={{
      height: height, width: "100%", position: "absolute", top: `-${height}`,
      background: "white", zIndex: "1000",
      boxSizing: "border-box", boxShadow: "var(--boxShadowTop)", overflow: "auto"
    }}
  >
    <div onPointerMove={handleMouseMove} onPointerUp={handleMouseUp} onPointerDown={handleMouseDown} onPointerEnter={() => setMouseEnterRZbar(true)} onPointerLeave={() => setMouseEnterRZbar(false)}
      style={{
        cursor: "row-resize",
        position: "sticky",
        top: 0,
        borderBottom: `2px solid ${!mouseEnterRZbar ? "#ddd" : "#00c3ff"}`,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        // height: "20px",
        background: "#F8F8F8",
        zIndex: 100,padding:"0.25rem 0.5rem",boxSizing:"border-box"
      }}
    >
      <div style={{display:"flex",gap:"1rem",justifyContent:"center",alignItems:"center"}}>
        <Button onClick={handleNewChat} sx={{padding:"0.15rem"}} startIcon={<AddIcon fontSize='small'/>}>New Chat</Button>
      </div>
      <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
        <DragHandleIcon />
      </div>

      <div>
        <Button sx={{ padding: 0, minWidth: "0", color: "black" }} onClick={() => handleShowContent(false)}>
          <CloseIcon />
        </Button>
      </div>
    </div>
    <div style={{ padding: "2rem 2rem", width: "100%", boxSizing: "border-box", flex: 1, overflow: "auto" }}>
      {children}
    </div>
  </div>
  )
}

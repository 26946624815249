
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useCompanyExchangeFilings(ticker,reportType="10-k") {
  const [companyExFilings, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/sec_filings/${ticker}?type=${reportType}&page=0&apikey=${financialModelingPrepApiKey}
          `
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker && reportType) {
      fetchData();
    }
  }, [ticker,reportType]);

  return { companyExFilings };
}
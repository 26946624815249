import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from 'data/keys';


export default function useCompanyPressRelease(ticker) {
  const [pressRelease, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v3/press-releases/${ticker}?apikey=${financialModelingPrepApiKey}
          `
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker) {
      fetchData();
    }
  }, [ticker]);

  return { pressRelease };
}
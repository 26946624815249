const dfin = {
    tickerCode:"CODE",
    date:"DATE",
    currencyCode:"CURRENCY CODE",
    currencySymbol:"CURRENCY SYMBOL",
    sector:"GIC SECTOR",
    exchange:"EXCHANGE",
    index:"INDEX",
    gicSector:"GIC SECTOR",
    gicIndustry:"GIC INDUSTRY",
    industry:"GIC INDUSTRY",
    marketCap:"MARKET CAPITALIZATION",
    country:"COUNTRY",
    mScale:"MARKET CAP SCALE",
    ticker:"TICKER",
    yfTicker:"YF TICKER",
    coName:"NAME",
    coLogo:"LOGO URL",
    businessDescription:"DESCRIPTION",
    coWebsite:"WEB URL",
    pe:"PERATIO",
    dividendYield:"DIVIDEND YIELD",
    beta:"BETA",
    fiancialRating:"FUNDAMENTAL OVERALL RATING",
    growthRating:"FUNDAMENTAL GROWTH RATING",
    avgRating:"FUNDAMENTAL AVG RATING",
    sizeRating:"FUNDAMENTAL SIZE RATING",
    period:"PERIOD",
    assets:"TOTAL ASSETS",
    liability:"TOTAL LIAB",
    equity:"TOTAL STOCKHOLDER EQUITY",
    revenue:"TOTAL REVENUE", 
    grossProfit:"GROSS PROFIT", 
    netIncome:"NET INCOME",
    ebitda:"EBITDA",
    incomeStatement:"IS",
    balanceSheet:"BS",
    cashFlow:"CF",
    roic:"ROIC",
    ebitdaMargin:"EBITDA MARGIN",
    fcf:"FREE CASH FLOW",
    ratio:"Ratio",
    statement:"Statement",
    statementCategory:"Statement Category",
    fv30:"FAIR VALUE (30)",
    fv15:"FAIR VALUE (15)",
    fv45:"FAIR VALUE (45)",
    pb:"PRICE BOOK MRQ",
    ps:"PRICE SALES TTM",
    eps:"EPS",
    netProfitMargin:"NET PROFIT MARGIN",
    grossProfitMargin:"GROSS PROFIT MARGIN",
    de:"DEBT TO EQUITY",
    cr:"CURRENT RATIO",
    evEbitda:"ENTERPRISE VALUE EBITDA",
    evSales:"ENTERPRISE VALUE REVENUE",
    metricFinancials:"Metric",
    cfo:"TOTAL CASH FROM OPERATING ACTIVITIES",
    cfi:"TOTAL CASHFLOWS FROM INVESTING ACTIVITIES",
    cff:"TOTAL CASH FROM FINANCING ACTIVITIES",
    shortRatio:"SHORT RATIO",
    shortPercent:"SHORT PERCENT",
    institutionHolding:"PERCENT INSTITUTIONS",
    performance1D:"PERFORMANCE 1D",
    performance1M:"PERFORMANCE 1M",
    performance3M:"PERFORMANCE 3M",
    performance6M:"PERFORMANCE 6M",
    performance1Y:"PERFORMANCE 1Y",
    close:"CLOSE",
    MoS:"MARGIN OF SAFETY",
    exCode:"EXCHANGE CODE",
    nonOperationsValue:"NON-OPERATIONS VALUE",
    sharesOs:"SHARES",
    debtPercent:"DEBT %",
    effectiveInterestRate:"EFFECTIVE INTEREST RATE",
    effectiveTaxRate:"EFFECTIVE TAX RATE",
    volume:"Volume"
}


const defaultMetrics = {}

defaultMetrics["MarketStats"] = [
dfin.marketCap,
dfin.pe,
dfin.pb,
dfin.dividendYield,
dfin.beta,
dfin.shortPercent,
dfin.institutionHolding]

defaultMetrics["Size"] = [dfin.revenue,dfin.ebitda,dfin.grossProfit,dfin.netIncome,dfin.assets]
defaultMetrics["Growth"] = [dfin.cfo,dfin.revenue,dfin.ebitda,dfin.grossProfit,dfin.netIncome,dfin.assets].map((metric)=>metric+" "+"5Y-GROWTH")
defaultMetrics["FinancialStability"] = [dfin.de,dfin.cr].map((metric)=>metric+" "+"5Y-AVERAGE")
defaultMetrics["Profit"] = [`${dfin.netProfitMargin} 5Y-AVERAGE`,
`${dfin.ebitdaMargin} 5Y-AVERAGE`,`${dfin.roic} 5Y-AVERAGE`]

defaultMetrics["Value"] = [dfin.pe,dfin.pb,dfin.ps,dfin.evEbitda,dfin.roic]

const performanceDict = {
    "1D":dfin.performance1D,
    "3M":dfin.performance3M,
    "6M":dfin.performance6M,
    "1Y":dfin.performance1Y
  }
  
const technicalIndicators = [
    "SIMPLE MOVING AVERAGE (20)",
    "SIMPLE MOVING AVERAGE (50)",
    "SIMPLE MOVING AVERAGE (200)",
    "BOLLINGER UPPER BAND (20)",
    "BOLLINGER LOWER BAND (20)",
    "AVERAGE DIRECTIONAL INDEX (14)",
    "AWESOME OSCILLATOR",
    "AVERAGE TRUE RANGE (14)",
    "COMMODITY CHANNEL INDEX (20)",
    "MACD LEVEL (12, 26)",
    "MACD SIGNAL (12, 26)",
    "MOMENTUM (10)",
    "RELATIVE STRENGTH INDEX (14)",
    "STOCHASTIC %K (14, 3, 3)",
    "STOCHASTIC %D (14, 3, 3)"
  ];

const technicalRatings = ["TECHNICAL RATING DAILY",
"MOVING AVERAGES RATING DAILY","OSCILLATORS RATING DAILY"
]

function growthMetric(metric, period="5Y"){
    return `${metric} ${period}-GROWTH`
}
const metricsGrowth = [`${dfin.revenue} 5Y-GROWTH`,`${dfin.netIncome} 5Y-GROWTH`,`${dfin.fcf} 5Y-GROWTH`]
// function averageMetric(metric, period="5Y"){
//     return `${metric} ${period}-AVERAGE`
// }

// const excludeInfoKeys = [dfin.ticker,dfin.coName,"ISIN","PRIMARY TICKER","CIK","FISCAL YEAR END","EMPLOYER ID NUMBER","IPO DATE","OPEN FIGI","EMPLOYER ID NUMBER","CURRENCY SYMBOL","CURRENCY NAME","LEI","CUSIP","INTERNATIONAL DOMESTIC","IS DELISTED","DESCRIPTION","ADDRESS","WEB URL","LOGO URL","UPDATED AT","IPODATE","PHONE","DATE","PERIOD","MOST RECENT QUARTER","YEAR","YEAR BS","YEARFACTOR","REPORT ANNUAL","REPORT QUARTER","REPORT OWNERSHIP","HOME CATEGORY"]
const descriptiveCols = [dfin.gicSector,dfin.gicIndustry,dfin.sector,dfin.industry,dfin.country,dfin.exchange,dfin.mScale]
const scatterColorMetrics = [dfin.gicSector,dfin.gicIndustry,dfin.sector,dfin.industry,dfin.country,dfin.exchange,dfin.mScale]
const metricsMarketStats = [dfin.marketCap,dfin.shortPercent,dfin.institutionHolding,dfin.pe,dfin.ps,dfin.beta,dfin.pb,dfin.evEbitda,dfin.evSales,"DIVIDEND YIELD"]
const metricValue = [dfin.pe,dfin.ps,dfin.beta,dfin.pb,dfin.evEbitda,dfin.evSales,dfin.roic]
const colsOverview = ["TICKER", 'NAME','TYPE', 'COUNTRY', 'CURRENCY', 'DATE',
'MARKET CAPITALIZATION', 'BETA', 'OPEN', 'HIGH', 'LOW', 'CLOSE',
'ADJUSTED CLOSE', 'VOLUME', 'EMA 50D', 'EMA 200D', 'HI 250D', 'LO 250D',
'AVGVOL 14D', 'AVGVOL 50D', 'AVGVOL 200D', '1 CLOSE', '30 CLOSE',
'90 CLOSE', '181 CLOSE', '360 CLOSE', 'PERFORMANCE 1D',
'PERFORMANCE 1M', 'PERFORMANCE 3M', 'PERFORMANCE 6M', 'PERFORMANCE 1Y',
 ]

 const colsScreener = [
  "TICKER",
  "NAME",
  "TYPE",
  "EXCHANGE SHORT NAME",
  "PERFORMANCE 1D",
  "PERFORMANCE 1M",
  "PERFORMANCE 3M",
  "PERFORMANCE 6M",
  "PERFORMANCE 1Y",
  "OPEN",
  "HIGH",
  "LOW",
  "CLOSE",
  "ADJUSTED CLOSE",
  "VOLUME",
  "EMA 50D",
  "EMA 200D",
  "HI 250D",
  "LO 250D",
  "AVGVOL 14D",
  "AVGVOL 50D",
  "AVGVOL 200D",
];

const financialStatementsInfo = [
  {
    label: "Income Statement",
    value: dfin.incomeStatement,
    default: [dfin.revenue, dfin.grossProfit, dfin.ebitda, dfin.netIncome],
  },
  {
    label: "Balance Sheet",
    value: dfin.balanceSheet,
    default: [dfin.assets, dfin.liability, dfin.equity],
  },
  {
    label: "Cash Flow",
    value: dfin.cashFlow,
    default: [dfin.cfo, dfin.cfi, dfin.cff],
  },
];

const financialsAll = [
  ...financialStatementsInfo,
  {label:"Ratios",value:dfin.ratio,default:[dfin.eps]}
]

const marketScaleOptions = ["Mega Cap","Large Cap","Mid Cap","Small Cap","Micro Cap"]

const assetCode = {
  "Stocks":"Common Stock",
  "ETF":"ETF",
  "Crypto":"CRYPTO",
  "Fixed Income":"BOND",
  "Forex":"Forex",
}

const assetMarket = {
  "ETF":"ETF & MF",
  "CRYPTO":"Crypto",
  "BOND":"Fixed Income",
  "Forex":"Forex",
  "Common Stock":"Stocks"
}


export {colsOverview,colsScreener,technicalRatings,
  marketScaleOptions,
  technicalIndicators,performanceDict,dfin,defaultMetrics, 
  descriptiveCols,metricValue, metricsMarketStats,growthMetric,financialStatementsInfo
  ,financialsAll,assetMarket,assetCode,metricsGrowth}

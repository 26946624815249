import { Button } from '@mui/material'
import React, { useState } from 'react'
import Modal from 'components/Modal/Modal';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function IndicatorTemplate({indicatorName,indicatorContent,showSettings=false,children,settingContent}) {
    const [settings,setSettings] = useState(false)
  return (
    <>
    <div>
    <MetricSettingsBar 
    title={indicatorName}
    metricSettings
    handleMetricSettings={()=>setSettings(true)}
    />

    {indicatorContent}
    {children}
    </div>
    {settings && 
        <Modal onClose={()=>setSettings(false)} height='auto' width="auto">
            <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
                <h2 style={{padding:"0.5rem 0"}}>{`${indicatorName} setting`}</h2>
                {settingContent}
            </div>
        </Modal>}
    </>
  )
}

import React from 'react'
import FieldFormOnboarding from '../FormFields/FieldFormOnboarding'
import { assetTypes, liabilityTypes } from 'appState/initialStateMyFinancials'
import { generateUniqueId } from "utils/utilGeneral"

export default function LiabilityOnboarding() {
  const defaultField = {id:generateUniqueId(), name: "Mort", type:"Mortgage", amount: 100000, currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0,interestRate:10,paybackPeriod:"Monthly",compounding:"Monthly",loanTerm:{y:25,m:0},date:new Date(),edit:true}
  
  return (
    <FieldFormOnboarding 
    name={"Liabilities"}
    defaultField={defaultField} 
    showType={true}
    interestRate={true}
    date={true}
    compounding={true}
    paybackPeriod={true} 
    loanTerm={true}
    typeOptions={liabilityTypes}  
    storeName={"liabilities"} data={[]} 
    formName='Liabilities' formHeading='Liability Type' 
    projectionType="liabilities"
    table={true}
    />
  )
}

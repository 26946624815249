import React from 'react';

import PriceChartMulti from 'components/Charts/PriceChartMulti';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import Beta from 'analytics/technicals/general/Beta';
import IndicatorUI from 'analytics/technicals/indicators/IndicatorUI';
import PerformanceClose from 'analytics/technicals/general/PerformanceClose';
import TrendTable from 'analytics/technicals/general/TrendTable';
import Volatility from 'analytics/technicals/general/Volatility';
import BondYieldCurve from 'analytics/ticker/bonds/BondYieldCurve';
import Correlation from 'analytics/technicals/general/Correlation';
import MarketToday from 'analytics/market/MarketToday';
import { intermarket } from 'content/contentMarket';
import ForexExchangeRate from 'analytics/ticker/forex/ForexExchangeRate';

export default function IntermarketReportInfo({info=""}) {
    const tickerList = Object.keys(intermarket)
    const tickerDict = intermarket
    const {close,trendTable,marketInfoLoading,priceData} = useMultiTickerInfo(tickerList)

 
  const reportContent = {
    "Market Today":<MarketToday/>,
    "Trend Table":<TrendTable key={tickerList[0]} 
    data={trendTable} tickerPrice={close} tickerDict={tickerDict} 
    marketInfoLoading={marketInfoLoading}/>,
    "Trend Chart":<PriceChartMulti 
    tickerDict={tickerDict}
    close={close} 
    marketInfoLoading={marketInfoLoading} height={"90vh"}/>,
    "Performance":<PerformanceClose
    tickerDict={tickerDict}
    tickerList={tickerList}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Volatility":<Volatility
    tickerDict={tickerDict}
    tickerList={tickerList}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Correlation":<Correlation
    tickerDict={tickerDict}
    tickerList={tickerList}/>,
    "Beta":<Beta tickerList={tickerList} tickerDict={tickerDict}/>,
    "Indicators":<IndicatorUI
    tickerDict={tickerDict}
    tickerList={tickerList}
    close={close} 
    marketInfoLoading={marketInfoLoading} />,
    "Yield Curve":<BondYieldCurve/>,
    "Exchange Rate":<ForexExchangeRate/>,
}
    
  return (
    <>
    {reportContent[info]}
    </>
  )
}

import React, { useState } from 'react'
import { Card, Typography, IconButton, Button, Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppInfo } from 'AppState';

export default function OnboardObjectives({username}) {
    const {dispatchApp,stateApp} = useAppInfo()
    const {onboarding} = stateApp
    const {objectives} = onboarding
 
    const handleObjectiveSelected = (v) => {
        const objectivesUpdate = objectives.includes(v) ? objectives.filter((obj) => obj !== v)
        : [...objectives, v]
        dispatchApp({"onboarding":{...onboarding,objectives:objectivesUpdate}})

      };
    
  return (
    <div style={{flex:1, display: "flex", flexDirection: "column", gap: "0.5rem", overflow: "auto", width: "100%", height:"70vh"}}>
    <h1 className='gradient_text' style={{ fontWeight: "bold", fontSize: "2.5rem", marginTop: "1rem", textAlign: "center" }}>
      {username}, how can TakeStock help you?
    </h1>

    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem", width: "100%", justifyContent: "center" }}>
      {objectivesData.map((objective, index) => (
        <Card
          onClick={() => handleObjectiveSelected(objective.name)}
          key={index}
          style={{ margin: 8, width: "25%", cursor: "pointer" }}
        >
          <div style={{ display: "flex", gap: "1rem", padding: "1rem", position: "relative", width: "100%", boxSizing: "border-box" }}>
            <div style={{ position: "relative", width: "50px", minWidth: "50px", display: "flex", alignItems: "start" }}>
              <IconButton sx={{ color: objectives.includes(objective.name) ? "green" : "gray" }}>
                <CheckCircleIcon />
              </IconButton>
            </div>
            <div>
              <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
                {objective.name}
              </Typography>
              <Typography variant="body2">
                {objective.description}
              </Typography>
            </div>
          </div>
        </Card>
      ))}
    </div>
  </div>
  )
}


const objectivesData = [
  { name: "Financial Planning", description: "Organize and understand your financial situation." },
  { name: "Meet Investing Goals", description: "Set and track your financial objectives." },
  { name: "Portfolio Management", description: "Manage and optimize your investment portfolio." },
  { name: "Trade", description: "Execute trades with tools and insights." },
  { name: "Find Investing Opportunities", description: "Discover new investing opportunities that align with your goals." },
  { name: "Analyze Markets & Economy", description: "Perform comprehensive data analysis to uncover hidden investment opportunities and risks." },
  { name: "Learn & Apply", description: "Acquire essential financial knowledge through guides and resources." }
];


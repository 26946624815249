import React, {useEffect, useState } from 'react'
import {menuOpportunities } from 'nav/menu';
import MarketCallStocks from 'opportunities/MarketCallStocks';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectMarket from 'components/Inputs/SelectMarket';
import SelectBox from 'components/Inputs/SelectBox';
import { useAppInfo } from 'AppState';
import { Alert, Button, Tooltip } from '@mui/material';
import MultiSelect from 'components/Inputs/MultiSelect';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import PortfolioFit from 'opportunities/PortfolioFit';
import InfoIcon from '@mui/icons-material/Info';
import Modal from 'components/Modal/Modal';
import { menuIcons } from 'nav/menuIcons';
import { PageContentWithLeftMenuLayout, PageMainLayout } from 'layout/PageLayout';


function Opportunities() {
  const {stateOpportunities,dispatchOpportunities} = useAppInfo()
  const {mScaleSel,investmentOptions,tradeOptions,investmentOptionSel,tradeOptionSel,assetClassSel} = stateOpportunities
  const [infoSel,setInfoSel] = useState("Investment")
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const subPage = pathname[2]


  useEffect(()=>{
   if (subPage){
   const menuSel = menuOpportunities.filter(obj=>obj.link===subPage)[0]?.name
   setInfoSel(menuSel)}
   else{
    navigate(`/Opportunities/Investment`)
    setInfoSel("Investment")
   }
  },[pathname])


  function handleAssetClass(v){
    dispatchOpportunities({"assetClassSel":v})
  }

  function handleMarketCap(v) {
    const mScaleSel = v.includes("All") ? ["Mega Cap", "Large Cap", "Mid Cap", "Small Cap", "Micro Cap"] : v;
    dispatchOpportunities({"mScaleSel":mScaleSel})
  }

  const pageHeader =  <div className='page_header_box' style={{alignItems:"center"}}>
                        <div className='page_header_title_box'>
                        {menuIcons?.[infoSel]} 
                        <h2>{infoSel}</h2>
                        <DisclaimerInfo infoSel={infoSel}/>
                        </div>
                        <div className='page_header_toolkit'>
                        <SelectBox variant="outlined" width="200px" label={"Data View"} options={["Information","Table","Scatter","Heatmap","Brand Visuals"]} 
                        value={stateOpportunities.dataView}
                        onChange={(e,v)=>{dispatchOpportunities({"dataView":v})}}
                        />
                        </div>
                      </div>

  return (
    <PageMainLayout 
    pageName='Opportunities'
    menuOptions={menuOpportunities}
    menuSel={infoSel} 
    >
      
      <PageContentWithLeftMenuLayout
      showMenu={false}
      header={pageHeader}
      >
     
     {subPage !== "PortfolioFit" && 
     <LeftSidebarFloating header={"Criterias"}>
        <div style={{display:"flex",flexDirection:"column",gap:"1.3rem"}}>
        <SelectMarket/>
      <SelectBox label={"Asset Class"} options={["Stocks"]} 
      variant='outlined' value={"Stocks"} onChange={
          (e,v)=>handleAssetClass(v)}/>
      
      {subPage === "Investment" && 
      <SelectBox variant='outlined' 
      label={"Investment Style"} 
      options={investmentOptions} value={investmentOptionSel} 
      onChange={(e,v)=>dispatchOpportunities({"investmentOptionSel":(v)})}/>}

      {subPage === "Trading" && <SelectBox variant='outlined' 
      label={"Trade Style"} 
      options={tradeOptions} value={tradeOptionSel} 
      onChange={(e,v)=>dispatchOpportunities({"tradeOptionSel":(v)})}/>}

      <MultiSelect variant='outlined' label={"Market Cap"} 
      options={["All","Mega Cap","Large Cap","Mid Cap","Small Cap","Micro Cap"]} 
      values={mScaleSel} onChange={handleMarketCap}/>
      </div>
         {/* <Button>More Filters</Button> */}

         
        </LeftSidebarFloating>}


        {subPage !=="PortfolioFit" && 
        <MarketCallStocks 
        strategy={subPage==="Investment"?investmentOptionSel:tradeOptionSel}/>}
        
        {subPage === "PortfolioFit" && <PortfolioFit/>}

      </PageContentWithLeftMenuLayout>
       
    </PageMainLayout>
  )
}


export {Opportunities}



function DisclaimerInfo({infoSel}){
  const [info,setInfo] = useState(false)

  return (<>
          <Tooltip title={`Information on ${infoSel}`}>
          <Button style={{minWidth:0}} onClick={()=>setInfo(true)}>
            <InfoIcon fontSize='small'/></Button></Tooltip>

  {info && 
  <Modal sx={{flexDirection:'column'}} width='50%' height='auto' onClose={()=>setInfo(false)}>
          {infoSel === "Investment" && <Alert severity='info'>
        <h2>Investment Opportunities</h2>
      <p>We provide opportunities for Long Term Investments across various asset classes, market regions, and investment styles.</p>
      <p>Our stock long-term investments are based on a strategy that combines fundamentals and quantitative analysis. You can select your factors for growth, value, or dividend.</p>
      <p>To find opportunities that are aligned more closely with your portfolio, use <strong>Portfolio Fit</strong>.</p>
      <p>If you are looking for a more custom filtering, check out <strong>Screeners</strong>.</p>
    </Alert>}
    {infoSel === "Trading" && <Alert severity='info'>
        <h2>Trading Opportunities</h2>
      <p>We provide opportunities for Trading across various asset classes, market regions, and trading styles.</p>
      <p>Our stock trading opportunities are based on a strategy that combines technicals, fundamentals and quantitative analysis. You can select your trading styles.</p>
      <p>To find opportunities that are aligned more closely with your portfolio, use <strong>Portfolio Fit</strong>.</p>
      <p>If you are looking for a more custom filtering, check out <strong>Screeners</strong>.</p>
    </Alert>}
    {infoSel === "Portfolio Fit" && 
    <Alert severity='info'>
    <h2>Portfolio Fit</h2>
  <p>These opportunities are based on your financials and current portfolio.</p>
  <p>We consider your net cash flow, goals, risk tolerance and investment preferences to provide you opportunities.</p>
  <p>If you are looking for a more custom filtering, check out <strong>Screeners</strong>.</p>
</Alert>
    }
    <Alert severity="warning">
      <strong>Disclaimer:</strong> This is a beta version. The opportunities provided may not be based on current data. 
      Trading and investing carry risks. Please conduct proper research before investing or trading, or consult a professional. 
      These opportunities are provided to facilitate further research.
    </Alert>
        </Modal>}
  </>)
}


import React from 'react'
import { useEffect, useState } from "react";
import { financialModelingPrepApiKey } from "data/keys";
import axios from 'axios';

export default function useTopInvestorsPortfolioHoldingSummary(cik) {
    const [topInvestorHoldingSummary, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(
            `
            https://financialmodelingprep.com/api/v4/institutional-ownership/portfolio-holdings-summary?cik=${cik}&page=0&apikey=${financialModelingPrepApiKey}
            `
          );
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
        finally{ 
        setLoading(false)
        }
      };
      if (cik){
     fetchData();}
    }, [cik]);
  
    return { topInvestorHoldingSummary };
  }
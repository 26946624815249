import React, { useEffect,useMemo,useState} from 'react'
import { useAppInfo } from 'AppState'
import TimeSeriesChart from 'components/Charts/TimeSeriesChart'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from "./EconSingleSeriesUI.module.css"
import useEconSeries from 'data/economy/useEconSeries';

export default function EconSingleSeriesUI({ econCategories }) {
  const { dispatchSidebar,stateSidebar } = useAppInfo();
  function handleInfoTab(e, value) {
    e.stopPropagation();
    dispatchSidebar({ "sidebarOpen": true,"sidebarRightContent":"information","infoContent":value});
  }

  const [seriesName, setSeriesName] = useState();

  useEffect(() => {
    if (econCategories.length > 0) {
      const seriesName = econCategories[0]['id'];
      setSeriesName(seriesName);
    }
  }, [econCategories]);
  
  const { econSeries, loadingEconSeries } = useEconSeries(seriesName);
  const sortedEconSeries = useMemo(() => [...econSeries].sort((a, b) => new Date(a.Date) - new Date(b.Date)), [econSeries]);


  return (
    <>
      
      {!loadingEconSeries && econCategories.length === 0 && (
        <div>Sorry, Info Not Available!</div>
      )}
      {(!loadingEconSeries && econCategories.length > 0 && sortedEconSeries.length > 0) && (
        <div className={styles.tableContainer} style={{border:"2px solid #F8F8F8",padding:"0.25rem",borderRadius:"5px",boxSizing:"border-box"}}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th >Name</th>
                <th >Current Level</th>
                <th >Units</th>
                <th >Frequency</th>
                <th >Popularity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    {econCategories[0]['Name']}
                    {stateSidebar.showInfoTag &&
                    <InfoOutlinedIcon
                      fontSize='small'
                      onClick={(e) => handleInfoTab(e, econCategories[0]['Name'])}
                    />}
                  </div>
                </td>
                <td>
                  {sortedEconSeries[sortedEconSeries.length - 1][seriesName].toFixed(2)}{' '}
                  {econCategories[0]['units'] === 'Percent' ? '%' : ''}
                </td>
                <td>{econCategories[0]['units']}</td>
                <td>{econCategories[0]['frequency']}</td>
                <td>{econCategories[0]['popularity']}</td>
              </tr>
            </tbody>
          </table>
          <TimeSeriesChart recession={true} data={sortedEconSeries} seriesName={seriesName} seriesLabel={econCategories[0]['Name']} />
        </div>
      )}
    </>
  );
}

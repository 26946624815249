import React, { useEffect, useState } from 'react'
import { Button, Card, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ProfileLogo from 'components/Logo/ProfileLogo'
import { useNavigate } from 'react-router-dom'
import MyFinancialsOverviewHome from 'myFinancials/MyFinancialsOverviewHome'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PortfolioMetricOverview from '../myFinancials/Portfolio/PortfolioMetricOverview'
import TextWithBorders from 'components/Font/TextWithBorders';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AlertButton from './AlertButton';
import ExtensionIcon from '@mui/icons-material/Extension';
import ApiIcon from '@mui/icons-material/Api';
import { menuIcons } from 'nav/menuIcons'
import ProfileCompletion from './ProfileCompletion'
import ThreePIcon from '@mui/icons-material/ThreeP';
import FinancialAdvisorConnect from './FinancialAdvisorConnect'

export default function MyFinancialCard() {
    const {stateApp,dispatchSidebar} = useAppInfo();
    const {username} = stateApp
    const navigate = useNavigate();

  
    function handleNavigate(path) {
      if (["Sector & Industry", "News/Events", "Notification & Alerts", "Peers", "Watchlist", "Notes", "Ticker", "Market/Asset Class", "Economy"].includes(path)) {
        if (path === "News/Events") {
          navigate("/News");
        } else if (["Notification & Alerts", "Peers", "Watchlist", "Notes"].includes(path)) {
          dispatchSidebar({"sidebarOpen":true,"showSidebarRight":true,"sidebarRightContent":path.toLowerCase() });
        } else {
          navigate(`/Analytics/${path}`);
        }
      } else {
        navigate(`/${path}`);
      }
    }
  
  
    const buttons = [
      { label: "My Financials", action: "MyFinancials/Overview",content:<MyFinancialsOverviewHome/>},
      { label: "Investment Portfolio", action: "MyFinancials/Portfolio",content:<PortfolioMetricOverview/>},
    ];
  

  return (
    <Card style={{ width: "100%",padding: "1rem",boxSizing:"border-box",flex:1}}>
    <div style={{ display: "flex",padding:"0 0.5rem",gap: "0.5rem",alignItems:"center",width:"100%"}}>
      <ProfileLogo />
      <h3 style={{fontWeight:"bold"}}>{username}</h3>
      {/* <IconButton style={{marginLeft:"auto"}}><MoreVertIcon/></IconButton> */}
    </div>
    <div style={{marginTop:"1rem",display: "flex", flexDirection: "column"
    , gap: "1rem", alignItems: "flex-start",width:"100%",flexWrap:"wrap"
    ,padding:"0 0.5rem",boxSizing:"border-box"}}>
       
      <ProfileCompletion displayType='main'/>

      {buttons.map((button, index) => (
        <div key={index} style={{display:"flex",flexDirection:"column",gap:"0.25rem",alignItems:"flex-start",width:"100%"}}>
        <Button 
        style={{
        padding:"0.5rem",
        borderRadius:0,
        textAlign:"left",justifyContent:"flex-start",borderBottom:"1px solid #ddd",width:"100%",fontWeight:"bold"}}  
        onClick={() => handleNavigate(button.action)}>
          {button.label}
          <ArrowOutwardIcon sx={{marginLeft:"auto",fontSize:"0.9rem"}}/>
        </Button>
        <div style={{width:"100%",paddingLeft:"0.75rem",boxSizing:"border-box"}}>
        {button.content}
        </div>
        </div>
      ))}

      <AlertButton/>
      <FinancialAdvisorConnect/>
    </div>
  </Card>
  )
}


const info = [
   
  // {
  //   category: "Market Impact & Opportunities",
  //   items: [
  //     // { title: "Market Impact on my financials", icon: <ApiIcon/>, link: "",navigate:"modal"},
  //     { title: "Opportunities that fit my portfolio", icon: <ExtensionIcon/>, link: "/Opportunities/PortfolioFit",navigate:"link"},
  //   ],
  // },
  // {
  //   category: "Risk Management & Scenarios",
  //   items: [
  //     { title: "Manage Risks", icon: menuIcons?.RiskManagement, link: "/MyFinancials/RiskManagement",navigate:"link"},
  //     { title: "Explore Scenarios", icon: menuIcons?.Scenarios, link: "/MyFinancials/Scenarios",navigate:"link"},
  //   ],
  // },
  {
    category: "Guide",
    items: [
      { title: "Steps to Manage Financials", icon: <ChecklistIcon/>, link: "",navigate:"link"},
      { title: "Steps to Realize Financial Goals", icon: <ChecklistIcon/>, link: "" ,navigate:"link"},
    ],
  },
];
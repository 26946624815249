import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange'
import React from 'react'

export default function useAssistantStockAanlyst() {
    const handleTickerChange = useHandleTickerChange()

    function GetStockFinancials({ticker}) {
      handleTickerChange({ticker:ticker,modal:false,goToPage:false})
        return null
      }

    function GetStockValuation({ticker}) {
        handleTickerChange({ticker:ticker,modal:false,goToPage:false})
        return null
      }

    function GetStatsVsIndustry({ticker}) {
      handleTickerChange({ticker:ticker,modal:false,goToPage:false})
        return null
      }

    return {GetStatsVsIndustry,GetStockFinancials,GetStockValuation}
}

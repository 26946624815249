import React from 'react'
import styles from "./ProductFeature.module.css"
import { useNavigate } from 'react-router-dom'
import { productFeatures } from 'content/contentProduct';
import { Button, Paper, Typography } from '@mui/material';
import { useAppInfo } from 'AppState';
import InfoBox from 'components/Layout/InfoBox';


export default function ProductFeatureTemplate({content,id="",
tagLineHeader,taglineContent,pageNumber,alignHeader="left"}) {
  const {stateApp,dispatchApp} = useAppInfo()
  const {isLoggedIn} = stateApp  
  const navigate = useNavigate()
    function handleGetStarted(){   
      if (!stateApp.isLoggedIn){
        dispatchApp({"loginModal":true})
      }
  }
  
  return (
    <div id={id} className={styles.container} 
    style={{background:"white"}}>
    <div key={pageNumber} className={`${styles.headerBox} fade-in-text`}>
        <h1 className='gradient_text-dark' 
        style={{fontSize:"4rem",width:"70%",textAlign:"left",}}>
          {productFeatures[pageNumber].Header}
        </h1>
        <div style={{display:"flex",zIndex:2
        ,flexWrap:"wrap",gap:"2rem",padding:"0",boxSizing:"border-box",alignItems:"center"}}>
        {productFeatures[pageNumber].SubHeader.map((header) => (
          <Button key={header} 
          className='fade-in-text'
          style={{textTransform:"none",fontSize:"1.2rem"}} onClick={handleGetStarted}>{header}</Button>
        ))}
      </div>
        
      </div>

      <InfoBox sx={{position:"relative"
      , boxSizing:"border-box", width:"100%", 
      display:"flex",flexDirection:"column",justifyContent:"center",alignContent:"center"
      ,padding:"2rem 0rem",borderRadius:"10px"
      ,overflow:"hidden",marginTop:"1rem",flex:1,zIndex:1}}>
 
      <div style={{zIndex:2,width:"100%",flex:1}}>
      {content}
      </div>
  
      </InfoBox>


    </div>
  )
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";

export default function useMultiTickerInfo(tickerList = []) {
  const [previousPeerSelected, setPreviousPeerSelected] = useState([]);
  const [close, setClose] = useState([]);
  const [trendTable, setTrendTable] = useState([]);
  const [priceData, setPriceData] = useState([]);
  const [marketInfoLoading, setMarketInfoLoading] = useState(false);

  useEffect(() => {
    const isPeerSelectedEqual =
      tickerList.every((element) => previousPeerSelected.includes(element)) &&
      tickerList.length === previousPeerSelected.length;

    if (Array.isArray(tickerList) && tickerList.length > 0) {
      if (!isPeerSelectedEqual) {
        getPriceInfo(tickerList);
        setPreviousPeerSelected(tickerList);
      }
    }
  }, [tickerList]);

  async function getPriceInfo(tickerList) {
    setMarketInfoLoading(true);
    try {
      const response = await axios(`${BASE_URL}/multiTickerInfo/`, {
        params: {
          ticker: JSON.stringify(tickerList),
        },
      });

      const marketInfo = response.data;

      const close = JSON.parse(marketInfo["close"]);
      const priceData = JSON.parse(marketInfo["data"])
      const trendTable = JSON.parse(marketInfo["dayTable"])
      if (tickerList.length === 1) {
        close.forEach((obj) => {
          if ("Close" in obj) {
            obj[tickerList[0]] = obj.Close;
            delete obj.Close;
          }
        });
      }

      setClose(close);
      setPriceData(priceData);
      setTrendTable(trendTable);
    } catch (error) {
      console.error(error);
    } finally {
      setMarketInfoLoading(false);
    }
    return { close, trendTable, marketInfoLoading, priceData}
  }
  return { close, trendTable, marketInfoLoading, priceData ,getPriceInfo};
}

import { Paper } from '@mui/material'
import ChatGPTModal from 'chatGPT/ChatGPTModal'
import React from 'react'
import LeftSidebar from 'sidebar/LeftSidebar/LeftSidebar'
import LeftSidebarPageMenu from 'sidebar/LeftSidebar/LeftSidebarPageMenu'

export function PageMainLayout({pageName="",sidebar=true,menuOptions,menuSel,children,tsAssistant=false}){
  return(
  <>
  {/* {sidebar && <LeftSidebar 
  name={pageName} 
  contentSel={menuSel} 
  menuOptions={menuOptions}/>} */}
  <div className="pageContentWrapper">
  {children}
  {/* {tsAssistant && <ChatGPTModal/>} */}
  </div>
  </>)
}

export function PageContentWithLeftMenuLayout({header,showMenu,children,menuName,menuOptions,menuSel,subMenuSel,handleContentSel,handleMenuSel}) {
  return (
    <>
      {header}

      <div className='containerLeftMenuAndContent'>
      {!showMenu && children}
      {showMenu &&
      <> 
      <LeftSidebarPageMenu 
        name={menuName}
        menuOptions={menuOptions} menuSel={menuSel} contentSel={subMenuSel}
            handleContentSel={handleContentSel} handleMenuSel={handleMenuSel}
            />
      <Paper elevation={1} className='containerMainContent'>
      <div className='containerColumnFlex1'>
      {children}
      </div>
      </Paper>
      </>}
      </div>
    </>
  )
}



import React, { useMemo } from 'react';

import TableGen from 'components/Table/TableGen';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import useEtfAssetAllocation from 'data/etf/useEtfAssetAllocation';
import useEtfGeneral from 'data/etf/useEtfGeneral';
import useEtfHoldings from 'data/etf/useEtfHoldings';
import useEtfSectorAllocation from 'data/etf/useEtfSectorAllocation';
import useEtfTopHoldings from 'data/etf/useEtfTopHoldings';
import useEtfWorldRegions from 'data/etf/useEtfWorldRegions';
import EtfMfAssetAllocation from './EtfMfAssetAllocation';
import EtfMfProfile from './EtfMfProfile';
import EtfMfSummaryStats from './EtfMfSummaryStats';

export default function EtfMfAnalytics({type="Asset Allocation"}) {
  const {stateTicker} = useAppInfo()
  const {tickerETFCode} = stateTicker
  const { generalData, loading } = useEtfGeneral(tickerETFCode);

  const { topHoldings, loadingTopHoldings } = useEtfTopHoldings(tickerETFCode);
  const topHoldingsX = useMemo(() => {
    if (!topHoldings) return null;

    return topHoldings.map(obj => {
      const tickInfo = stateTicker.tickerInfo.find(objT => objT.TICKER === obj.CODE);
      const { TICKER, CODE, ...rest } = obj;
      return {
        TICKER: CODE,
        [dfin.coLogo]: tickInfo?.[dfin.coLogo],
        ...rest,
      };
    });
  }, [topHoldings, stateTicker.tickerInfo]);

  const { holdingsAll, loadingHoldingsAll } = useEtfHoldings(tickerETFCode);
  const holdingsX = useMemo(() => {
    if (!holdingsAll) return null;

    return holdingsAll.map(obj => {
      const tickInfo = stateTicker.tickerInfo.find(objT => objT.TICKER === obj.CODE);
      const { TICKER, CODE, ...rest } = obj;
      return {
        TICKER: CODE,
        [dfin.coLogo]: tickInfo?.[dfin.coLogo],
        ...rest,
      };
    });
  }, [holdingsAll, stateTicker.tickerInfo]);


  // const { value, loadingValue } = useEtfValue(tickerETFCode);
  // const { growth, loadingGrowth } = useEtfGrowth(tickerETFCode);
  
  const { assetAllocation, loadingAssetAllocation } =useEtfAssetAllocation(tickerETFCode);
  const { worldRegions, loadingWorldRegions } = useEtfWorldRegions(tickerETFCode);
  const { sectorAllocation, loadingSectorAllocation } =useEtfSectorAllocation(tickerETFCode);

 
  const content = {
    "Profile":<EtfMfProfile generalData={generalData} />,
    "Summary":<EtfMfSummaryStats generalData={generalData} />,
  "Asset Allocation":<EtfMfAssetAllocation assetAllocation ={assetAllocation} worldRegions={worldRegions}
  sectorAllocation={sectorAllocation}/>,
"Top Holdings": topHoldingsX && <TableGen data={topHoldingsX} logo={true}/>,
"Holdings":holdingsX && <TableGen data={holdingsX} logo={true}/>};
  return (
    <>
      
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          height: "100%",
          overflow: "auto",
        }}
      >
       
        {content[type]}
      </div>
    </>
  );
}

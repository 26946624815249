import React, { useMemo, useState } from 'react';
import { useAppInfo } from 'AppState';
import { formatNumber, getDateString } from 'utils/utilGeneral';
import { Button, IconButton } from '@mui/material';
import { useHandleDeleteField } from 'myFinancials/FormFields/useFieldForm';
import { Delete } from '@mui/icons-material';
import PopoverButton from 'components/Modal/PopoverButton';
import CrudModal from 'components/Modal/CrudModal';
import CircleScore from 'components/Charts/CircleScore';
import UpdateAllocation from 'myFinancials/Utils/AllocationUpdate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddLiabilities from './AddLiabilities';
import LiabilityIndivdualEdit from './LiabilityIndividualEdit';
import Animation from 'animation/Animation';
import { defaultFieldBlank, liabilityTypes } from 'appState/initialStateMyFinancials';
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function LiabilityDetails() {
  const { stateMyFinancials } = useAppInfo();
  const { liabilities, currency, liabilityIndividualDetails } = stateMyFinancials;
  const currencySymbol = currency?.symbol;
  const { handleDeleteField } = useHandleDeleteField({ storeName: 'liabilities' });

  const groupedAssets = useMemo(() => {
    if (liabilityIndividualDetails) {
      const updateAllocationAsset = UpdateAllocation(liabilityIndividualDetails);
      const grouped = Object.values(
        updateAllocationAsset.reduce((acc, obj) => {
          const { type, amount, currentAllocation } = obj;

          if (!acc[type]) {
            acc[type] = {
              type: type,
              amount: 0,
              currentAllocation: 0,
              liabilities: [],
            };
          }

          acc[type].amount += amount;
          acc[type].currentAllocation += Number(currentAllocation);
          acc[type].liabilities.push(obj);

          return acc;
        }, {})
      );

      return grouped.sort((a, b) => {
        return liabilityTypes.indexOf(a.type) - liabilityTypes.indexOf(b.type);
      });
    }
    return [];
  }, [liabilityIndividualDetails]);

  const [confirmDelete, setConfirmDelete] = useState({ id: 0, title: '', delete: false });
  const [expandedRows, setExpandedRows] = useState(new Set());

  const toggleExpand = (index) => {
    setExpandedRows((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(index)) {
        newExpanded.delete(index);
      } else {
        newExpanded.add(index);
      }
      return newExpanded;
    });
  };

  const expandAll = () => {
    const allIndexes = new Set(groupedAssets.map((_, index) => index));
    setExpandedRows(allIndexes);
  };

  const collapseAll = () => {
    setExpandedRows(new Set());
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', 
    minWidth: '400px', flex: 1 }}>
      <MetricSettingsBar 
        childrenPopover={
        <>
        <Button onClick={expandAll}>Expand All</Button>
        <Button onClick={collapseAll}>Collapse All</Button>
        <AddLiabilities/>
        </>}
        title="Liability Overview"
            
            ></MetricSettingsBar>

      <table style={{tableLayout:"fixed"}}>
        <thead>
          <tr>
            <th style={{width:"250px"}}>Liabilities</th>
            {expandedRows.size === 0 && <th colSpan={8}></th>}
            {expandedRows.size > 0 && (
              <>
                <th>Paid (%)</th>       
                <th>Name</th>
                <th>Date</th>
                <th>Loan Amount</th>
                <th>Outstanding Amount</th>
                <th>Interest Rate</th>
                <th>Compounding</th>
                <th>Payback Period</th>
                <th>Edit/Delete</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {groupedAssets.map((group, index) => (
            <React.Fragment key={index}>
              <tr 
              onClick={(e) =>{e.stopPropagation();toggleExpand(index)} }
              style={{ background: 'white',minWidth:"250px"}}>
                <td rowSpan={expandedRows.has(index) ? group.liabilities.length + 1 : 1}
                style={{width:"250px"}}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '80px' }}>
                      <Animation type='Home' loop={false} />
                    </div>
                    <h4 style={{ flex: 1 }}>
                      {group.type}: {currencySymbol} {formatNumber(group.amount)}
                    </h4>
                    <Button sx={{ minWidth: '0', padding: '0.25rem', marginLeft: 'auto' }}>
                      {expandedRows.has(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Button>
                  </div>
                </td>
                {!expandedRows.has(index) &&
                <td colSpan={8}>
                  <h5 style={{fontWeight:"bold"}}>Show Details</h5>
                </td>}
                
              </tr>
              {expandedRows.has(index) &&
                group.liabilities.map((obj, indexAsset) => (
                  <tr key={indexAsset}>
                    <td>
                      <CircleScore
                        strokeEmpty='white'
                        title='Allocation'
                        color={'blue'}
                        width='40px'
                        height='40px'
                        totalScore={obj?.numberOfPayments}
                        score={obj?.paymentsDone}
                      />
                    </td>
                    <td>{obj.name}</td>
                    <td>{getDateString(obj?.date)}</td>
                    <td>
                    {currencySymbol} {formatNumber(obj.amount)}
                    </td>
                    <td>{formatNumber(obj?.outstandingAmount)}</td>
                    <td>{formatNumber(obj.interestRate)}</td>
                    <td>{obj?.compounding}</td>
                    <td>{obj?.paybackPeriod}</td>
                    <td>
                      <PopoverButton sxBtn={{ minWidth: '0' }} title='Edit/Details' iconSize='small' 
                      icon='edit'>
                        <LiabilityIndivdualEdit liabilityData={obj} />
                      </PopoverButton>
                      <IconButton disabled={!obj?.edit} onClick={() => setConfirmDelete({ delete: true, id: obj?.id, title: obj?.name })}>
                        <Delete fontSize='small'></Delete>
                      </IconButton>
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <div style={{display:"flex",position:"sticky",bottom:0,zIndex:"10000"}}>
      <AddLiabilities/>
      </div>
      {confirmDelete?.delete && (
        <CrudModal
          onClose={() => setConfirmDelete({})}
          title={confirmDelete?.title}
          modalType='delete'
          handleDelete={() => handleDeleteField(confirmDelete?.id)}
        />
      )}
    </div>
  );
}


export function LiabilityDetailsUI(){
  
    const {stateMyFinancials} = useAppInfo()
    const {liabilities,currency,liabilityIndividualDetails} = stateMyFinancials
    const currencySymbol = currency?.symbol
    const {handleDeleteField} = useHandleDeleteField({storeName:"liabilities"})

    const groupedAssets = useMemo(() => {
        if (liabilityIndividualDetails){
        const updateAllocationAsset = UpdateAllocation(liabilityIndividualDetails)
        const grouped = Object.values(
          updateAllocationAsset.reduce((acc, obj) => {
            const { type, amount, currentAllocation } = obj;
      
            if (!acc[type]) {
              acc[type] = {
                type: type,
                amount: 0,
                currentAllocation: 0,  // Initialize the allocation accumulator
                liabilities: [],
              };
            }
      
            acc[type].amount += amount;  // Add up the amount
            acc[type].currentAllocation += Number(currentAllocation);  // Accumulate the allocation
            acc[type].liabilities.push(obj);  // Maintain the order of liabilities
      
            return acc;
          }, {})
        );
      
        // Sort the grouped liabilities based on the order in liabilityTypes
        return grouped.sort((a, b) => {
          return liabilityTypes.indexOf(a.type) - liabilityTypes.indexOf(b.type);
        });}
        return []
      }, [liabilityIndividualDetails, liabilityTypes]);
      
    const [confirmDelete,setConfirmDelete] = useState({id:0,title:"",delete:false})
    const [expandAsset,setExpandAsset] = useState(true)
    
    return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",minWidth:"400px",flex:1,maxWidth:"800px"}}>
   <div style={{display:"flex",gap:"0.5rem",background:"#fafafa",padding:"0.25rem"}}>
    <h5 style={{fontWeight:"bold",color:"gray",width:"60px"}}>Payback</h5>
   <h5 style={{fontWeight:"bold",color:"gray",flex:1}}>Liability Details</h5>
   <h5 style={{fontWeight:"bold",color:"gray"}}>Settings</h5>
   <h5 style={{fontWeight:"bold",color:"gray"}} onClick={()=>setExpandAsset(!expandAsset)}>
    {expandAsset?"Collapse All":"Expand All"}</h5>

   </div>
    {groupedAssets.map((group, index) => (
  <div key={index} style={{background:"#fafafa",borderRadius:"5px",padding:"0.25rem",display:"flex"}}>
    <div style={{width:"100%"}}>
    
    <div style={{padding:"0.25rem 0",display:"flex",gap:"0.5rem",alignItems:"center"}}>
        <div style={{width:"60px"}} >
            <Animation type='Home' loop={false}/>
        {/* <CircleScore strokeEmpty="white" title="Allocation" color={"blue"} width="60px" height="60px" 
        totalScore={100} score={group?.currentAllocation}/> */}
     </div>
    <div style={{flex:1}}>
    <h4 style={{fontWeight:"bold"}}>{group.type}: {currencySymbol} {formatNumber(group.amount)}</h4>
    {/* <h5 style={{fontWeight:"bold"}}>Current Allocation: {formatNumber(group?.currentAllocation)}%</h5>
     */}
    </div>

    <Button onClick={()=>setExpandAsset(!expandAsset)} sx={{minWidth:"0",padding:"0.25rem"}}>
        {!expandAsset ? <KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}
            </Button>
    </div>
    {expandAsset && 
    <div  style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0 0.5rem",paddingLeft:"60px",width:"100%",boxSizing:"border-box"}}>
    {group.liabilities.map((obj, indexAsset) => (
      <div key={indexAsset} style={{display:"flex",width:"100%"
      ,gap:"0.5rem",background:"#f4f4f4",boxSizing:"border-box",borderRadius:"4px",padding:"0.25rem",alignItems:"center"}}>
         <CircleScore strokeEmpty="white"  title="Allocation" color={"blue"} width="40px" height="40px" 
        totalScore={obj?.numberOfPayments} score={obj?.paymentsDone}/>
        <div style={{flex:1}}>
          <h5 style={{fontWeight:"bold"}}>{obj.name}: {currencySymbol} {formatNumber(obj.amount)}</h5>
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <h6 style={{fontWeight:"bold",color:"gray"}}>Payments Made</h6>
        <h4 style={{fontWeight:"bold"}}>{obj?.paymentsDone}</h4>
        <h6>out of {obj?.numberOfPayments}</h6>
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <h6 style={{fontWeight:"bold",color:"gray"}}>Payment</h6>
        
        <h4 style={{fontWeight:"bold"}}>{currencySymbol}{formatNumber(obj?.periodicPayment)}</h4>
        <h6>{obj?.paybackPeriod}</h6>
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <h6 style={{fontWeight:"bold",color:"gray"}}>Intetrest Rate</h6>
        <h4 style={{fontWeight:"bold"}}>{formatNumber(obj?.interestRate)}% p.a</h4>
        <h6>compouded {obj?.compounding}</h6>
        </div>
        <PopoverButton 
        sxBtn={{minWidth:"0"}}
        title="Edit/Details"
        iconSize="small"
        variant="highlight"
        icon="edit">
            <LiabilityIndivdualEdit  liabilityData={obj}/>
        </PopoverButton>
        <IconButton disabled={!obj?.edit} onClick={()=>setConfirmDelete({delete:true,id:obj?.id,title:obj?.name})}><Delete fontSize="small"></Delete> 
        </IconButton>
      </div>
    ))}
    
    </div>}
    </div>
  </div>
))}
   {confirmDelete?.delete &&
    <CrudModal onClose={()=>setConfirmDelete({})} title={confirmDelete?.title} 
    modalType="delete" handleDelete={()=>handleDeleteField(confirmDelete?.id)}></CrudModal> }
    </div>
    )
  }


export function LiabilityDetailsMulti() {
    const {stateMyFinancials} = useAppInfo()
    const {liabilities,cashflowDistribution,summaryStats,currency} = stateMyFinancials
    
  return (
    <FieldFormGen 
    showType={true}
    interestRate={true}
    date={true}
    compounding={true}
    paybackPeriod={true} 
    loanTerm={true}
    defaultField={defaultFieldBlank.Liability}
    typeOptions={liabilityTypes}  
    storeName={"liabilities"} data={liabilities} 
    formName='Liabilities' formHeading='Liability Type' />
  )
}

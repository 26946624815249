import React, {
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import BondPrice from 'analytics/ticker/bonds/BondPrice';
import ForexExchangeRate from 'analytics/ticker/forex/ForexExchangeRate';
import TickerHeader from 'analytics/ticker/TickerHeader';
import TickerInfoList from 'analytics/ticker/TickerInfoList';
import { assetCode } from 'content/constants';
import { PageContentWithLeftMenuLayout } from 'layout/PageLayout';

export default function Ticker() {
  const {stateTicker,dispatchTicker} = useAppInfo()
  const {tickerType,tickerSelInfo} = stateTicker
  const [contentList,setContentList] = useState([])
  const menuOptions = stateTicker.menu
  const {menuSel,subMenuSel} = stateTicker
  useEffect(() => {
    if (menuOptions.length > 0) {
      let contentList = menuOptions.filter(obj => obj.name === stateTicker.menuSel)[0]?.content;
  
      if (!contentList) {
        const overviewContent = menuOptions.filter(obj => obj.name === "Overview")[0]?.content;
        dispatchTicker({
          menuSel: "Overview",
          subMenuSel: overviewContent[0],
          contentList: overviewContent
        });
        setContentList(overviewContent);
      } else {
        if (!contentList.length > 0) {
          dispatchTicker({
            menuSel: stateTicker.menuSel,
            subMenuSel: stateTicker.menuSel
          });
        } else if (!contentList.includes(stateTicker.subMenuSel)) {
          dispatchTicker({
            menuSel: stateTicker.menuSel,
            subMenuSel: contentList[0]
          });
        }
  
        setContentList(contentList);
        dispatchTicker({ contentList: contentList });
      }
    }
  }, [stateTicker.menuSel, menuOptions]);
  
  function handleContentSel(n, v) {
    dispatchTicker({
      menuSel: n,
      subMenuSel: v
    });
  }
  
  function handleMenuSel(v) {
    const infoSel = menuOptions.filter(obj => obj.name === v).map(obj => obj.content);
  
    dispatchTicker({
      menuSel: v,
      subMenuSel: infoSel[0].length > 0 ? infoSel[0][0] : v
    });
  }
  

  return (
    <PageContentWithLeftMenuLayout 
    header={<TickerHeader/>}
    showMenu={![assetCode?.Forex,assetCode?.["Fixed Income"]].includes(tickerType)}
    menuName={"Ticker Info"}
    menuOptions={menuOptions} menuSel={menuSel} subMenuSel={subMenuSel}
    handleContentSel={handleContentSel} handleMenuSel={handleMenuSel}
    >
      {tickerType === assetCode?.Forex && <ForexExchangeRate/>}
      {tickerType === assetCode?.["Fixed Income"] && <BondPrice header={false}/>}
      {![assetCode?.Forex,assetCode?.["Fixed Income"]].includes(tickerType) && <TickerInfoList assetType={tickerType} 
      infoName={stateTicker.subMenuSel}/>} 
    </PageContentWithLeftMenuLayout>
  )
}





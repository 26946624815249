import React from 'react'
import RiskTolerance from './RiskTolerance'
import RiskMitigationOptions from './RiskMitigationOptions'
import InvestmentCrisisRM from './InvestmentCrisisRM'

export default function RiskManagementInfo({info}) {
  const content = {
    "Risk Tolerance":<RiskTolerance/>,
    "Risk Mitigation Options":<RiskMitigationOptions/>,
    "Crisis Management":<InvestmentCrisisRM/>
  }
  return (
    <>
    {content[info]}
    </>
  )
}

import React from 'react'
import PortfolioValue from './PortfolioValue'
import PortfolioMetricOverview from './PortfolioMetricOverview'

export default function PortfolioOverview({displayType="main"}) {

  return (
    <div className="containerColumnFlex">
    <PortfolioMetricOverview displayType='bar' size={displayType==="main"?"large":"medium"}/>
    <PortfolioValue benchmarkSettings={false}/>
    </div>
  )
}
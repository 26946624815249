import React, { useEffect, useState } from 'react'
import TuneIcon from '@mui/icons-material/Tune';
import Modal from "components/Modal/Modal";
import { useAppInfo } from 'AppState';
import StockFilters from './StockFilters';
import { Badge, Button, Paper } from '@mui/material';
import { menuIcons } from 'nav/menuIcons';
import FilterNew from './FilterNew';

export default function ScreenerTemplate({loading=false,filterName,children,data=[],handleFilterUpdate}) {
  const {stateScreener} = useAppInfo()
  
  return (
    <>
    <div className='containerColumnFlex'>
    <div className='page_header_box'>
    <div className='page_header_title_box'>
    {menuIcons[stateScreener.screenerType]}
    <h2 style={{fontWeight:"bold"}}>{stateScreener.screenerType}</h2>
    </div>
    {filterName ==="filters" && <StockFilters/>}
    {loading && filterName !=="filters" && <NonStockFilters data={data} filterName={filterName} handleFilterUpdate={handleFilterUpdate}/>}
    </div>
    <div style={{height:"90%",width:"100%",flex:1,overflow:"auto",padding:"0.5rem",boxSizing:"border-box"}}>
      <Paper style={{height:"100%",width:"100%",overflow:"auto"}}>
          {children}</Paper>
    </div>
    </div>
    
    </>
  )
}

function NonStockFilters({data,filterName,handleFilterUpdate}){
  const [modal,setModal] = useState(false)
  const {stateScreener,dispatchScreener} = useAppInfo()
  
  const [nFilters,setnFilters] = useState(0)
  useEffect(()=>{
   const nFilters =  Object.keys(stateScreener[filterName]).length;
    setnFilters(nFilters)
  },[stateScreener[filterName]])

  return (
    <>
    
    <div
    style={{marginLeft:"auto", display: "flex", gap: "1rem",alignItems:"center"}}
  >
  <Badge
          badgeContent={nFilters} color="secondary"
          overlap="circular"
        >
  <Button
      onClick={()=>setModal(true)}
      variant={`${nFilters>0 ? `contained`:`outlined`}`}
      sx={{borderRadius: "5px",
      // border: "none",
      position: "relative",
      padding:"0.25rem",
      marginRight:"0.5rem",
    border:"1px solid #ddd"}}
      
    >      
      <TuneIcon/>
      Filters

    </Button>
    </Badge>
</div>

{modal && 
    <Modal onClose={()=>setModal(false)} width="70%">
    <FilterNew filterScreener={filterName} 
    handleModal={()=>setModal(false)} onUpdateFilteredData={handleFilterUpdate} 
    data={data}/>
    </Modal>}
   
</>
  )
}
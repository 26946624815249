import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';

export default function AccordionTS({ summary, details, children, 
  defaultOpen = false,variant="standard"}) {
  let sx = {}
  if (variant === "border"){
    sx = {border:"3px solid #fafafa",boxShadow:"none"}
  }
  return (
    <Accordion 
    sx={{...sx}}
    defaultExpanded={defaultOpen}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {details}
        </Typography>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}


import React, { useEffect, useState } from 'react';
import TextInput from 'components/Inputs/TextInput';
import { formatNumber } from 'utils/utilGeneral';
import { useAppInfo } from 'AppState';
import RatingBar from 'components/RatingBar';
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function CompanyVsIndustryScore({ title,scoreDetails,tableData}) {
  const { stateStock, dispatchStock } = useAppInfo();
 
  function handleWeightChange(value, index) {
    const updatedTableData = [...tableData];
    updatedTableData[index].Weight = parseFloat(value) || 0; // Ensure value is a number
    updateGlobalStateWithWeights(updatedTableData);
  }

  function handleMetricSelectionChange(index) {
    const updatedTableData = [...tableData];
    updatedTableData[index].selected = !updatedTableData[index].selected;
    updateGlobalStateWithSelections(updatedTableData);
  }

  function handleCategorySelectionChange() {
    const isSelected = stateStock.metricScores.find(category => category.category === title)?.selected;
    const updatedMetricScores = stateStock.metricScores.map(category => {
      if (category.category === title) {
        return {
          ...category,
          selected: !isSelected,
          metrics: category.metrics,
        };
      }
      return category;
    });

    dispatchStock({ metricScores: updatedMetricScores });
  }

  function updateGlobalStateWithWeights(updatedTableData) {
    dispatchStock({
      metricScores: stateStock.metricScores.map(category => {
        if (category.category === title) {
          return {
            ...category,
            metrics: category.metrics.map((metric, i) => {
              const updatedMetric = updatedTableData.find(data => data.metric === metric.metric);
              return { ...metric, weight: updatedMetric?.Weight };
            }),
          };
        }
        return category;
      }),
    });
  }

  function updateGlobalStateWithSelections(updatedTableData) {
    dispatchStock({
      metricScores: stateStock.metricScores.map(category => {
        if (category.category === title) {
          return {
            ...category,
            metrics: category.metrics.map((metric, i) => {
              const updatedMetric = updatedTableData.find(data => data.metric === metric.metric);
              return { ...metric, selected: updatedMetric?.selected };
            }),
          };
        }
        return category;
      }),
    });
  }

  const [open,setOpen] = useState(false)

  return (
    <div style={{width:"40vw"}}>
      <div onClick={()=>setOpen(!open)} 
      style={{background:"#f8f8f8",borderRadius:"5px",padding:"0.25rem",width:"100%",boxSizing:"border-box"}}>
        <div style={{display:"flex",gap:"0.5rem"}}>
        <input
          type="checkbox"
          checked={stateStock.metricScores.find(category => category.category === title).selected}
          onChange={handleCategorySelectionChange}
        />
        <h4 style={{fontWeight:"bold"}}>{title}: {scoreDetails?.Value}</h4>
        </div>
        {tableData.length>0 && <div style={{display:"flex",gap:"1rem",width:"100%",alignItems:"center"}}>
        <div style={{flex:1,minWidth:"10px"}}>
        <RatingBar score={scoreDetails?.Value} scoreMax={100}/></div>
        <Button sx={{minWidth:"0",padding:"0.25rem"}}>
        {!open ? <KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}
            </Button></div>}
      </div>
      {(tableData.length > 0 && open) &&
      <div style={{width:"100%"}}>
      <table>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
            <th>Score</th>
            <th>Weight</th>
            <th>Weighted Average Score</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((obj, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={obj.selected}
                  onChange={() => handleMetricSelectionChange(index)}
                /> {obj.metric}
              </td>
              <td>{formatNumber(obj.value)}</td>
              <td>{formatNumber(obj.score)}</td>
              <td>
                <TextInput
                  type="number"
                  value={obj.Weight}
                  onChange={(v) => handleWeightChange(v, index)}
                />
              </td>
              <td>{formatNumber(obj.score * obj.Weight)}</td>
            </tr>
          ))}
        </tbody>
      </table></div>}
    </div>
  );
}

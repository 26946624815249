import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxCutom({label,required,disabled,defaultChecked,onChange}) {
  return (
    <FormGroup>
      <FormControlLabel required={required} disabled={disabled} 
      control={<Checkbox checked={defaultChecked} onChange={(e)=>onChange(e.target.checked)}/>} label={label} />
    </FormGroup>
  );
}
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import SelectMarket from 'components/Inputs/SelectMarket';
import TickerCardSparkline from 'components/Charts/TickerCardSparkline';
import { useAppInfo } from 'AppState';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import { marketHeaderDict } from 'content/contentMarket';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button, ButtonGroup } from '@mui/material';
import TooltipInfo from 'components/Modal/TooltipInfo';

export default function MarketOverviewHeader() {
  const { stateMarket } = useAppInfo();
  const [mouseEnter, setMouseEnter] = useState({ ticker: "", mouseEnter: false });
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scroll,setScroll] = useState(false)
  const sectionRef = useRef(null);
  const contentBoxRef = useRef(null)
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  
  function handleNextButtonClick() {
    if (sectionRef.current) {
      // Calculate the next scroll position based on the visible width of the section
      const nextScrollLeft = sectionRef.current.scrollLeft + 100;
      // Use smooth scrolling
      sectionRef.current.scrollTo({ left: nextScrollLeft, behavior: 'smooth' });
    }
  }
  
  function handlePrevButtonClick() {
    if (sectionRef.current) {
      // Calculate the next scroll position based on the visible width of the section
      const nextScrollLeft = sectionRef.current.scrollLeft - 100;
      // Use smooth scrolling
      sectionRef.current.scrollTo({ left: nextScrollLeft, behavior: 'smooth' });
    }
  }
  
  useEffect(() => {
    const checkScroll = () => {
      if (sectionRef.current) {
        if (sectionRef.current.scrollWidth > sectionRef.current.offsetWidth) {
          setScroll(true);
        }
      }
    };

    // Use a setTimeout to delay the check after the initial render
    const timeoutId = setTimeout(checkScroll, 3);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [sectionRef.current]);

  function priceInfo(close, ticker) {
    const data = getSpecificArrayObjKeys(close, [ticker]).map((obj) => obj[ticker]).slice(-250);
    if (data) {
      const minValue = Math.min(...data)?.toFixed(2);
      const maxValue = Math.max(...data)?.toFixed(2);
      let lastValue = data[data.length - 1]?.toFixed(2);
      let prevValue = data[data.length - 2]?.toFixed(2);
      if (prevValue === lastValue) {
        prevValue = data[data.length - 3]?.toFixed(2);
      }
      const percentageChange = ((lastValue / prevValue - 1) * 100)?.toFixed(2);

      return { minValue, maxValue, lastValue, percentageChange };
    }
  }
  function handleMouseMove(event) {
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  }

  return (
    <div className='page_header_box' ref={contentBoxRef} >
      <SelectMarket />


      <div className='section' ref={sectionRef} style={{marginRight:"5rem"}} 
      onMouseMove={handleMouseMove}>
      
        {stateMarket.closeInterMarket.length > 0 &&
          Object.keys(marketHeaderDict).map((mktTicker) => {
            const { minValue, maxValue, lastValue, percentageChange } = priceInfo(
              stateMarket.closeInterMarket,
              mktTicker
            );
            const isMouseEnter = mouseEnter.ticker === mktTicker && mouseEnter.mouseEnter;

            return (
              <div
                key={mktTicker}
                onMouseEnter={() => setMouseEnter({ ticker: mktTicker, mouseEnter: true })}
                onMouseLeave={() => setMouseEnter({ ticker: mktTicker, mouseEnter: false })}
                style={{
                  paddingRight: "0.5rem",
                  borderRight: "1px solid #ddd",
                  flex: '0 0 auto', // Don't allow flex items to shrink
                }}
              >
                <h3 style={{ marginBottom: "0" }}>{marketHeaderDict[mktTicker]}</h3>
                <span style={{ marginRight: "0.5rem" }}>{lastValue}</span>
                <span style={{ color: percentageChange > 0 ? "green" : "red", fontWeight: "bold" }}>
                  {percentageChange}%
                </span>
                {isMouseEnter && (
                  <TooltipInfo
                    bottom={`-150px`}
                    left={`${tooltipPosition.x}px`}
                    borderRadius='5px'
                    width='250px'
                  >
                   <TickerCardSparkline
                        tickerDict={{ [mktTicker]: marketHeaderDict[mktTicker] }}
                        close={stateMarket.closeInterMarket}
                      />
                  </TooltipInfo>
                )}
   
              </div>
            );
          })}
          {scroll && 
          <ButtonGroup size='small' sx={{background:"white",position: 'absolute', 
          right: 0 ,display:"flex"}}>
          <Button onClick={handlePrevButtonClick}>
            <NavigateBeforeIcon/>
          </Button>
          <Button size='small' onClick={handleNextButtonClick} >
           <NavigateNextIcon/>
          </Button>
          </ButtonGroup>}
        </div>
      
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import IntermarketReportInfo from './IntermarketReportInfo';
import InfoBox from 'components/Layout/InfoBox';
import ContentMenu from './ContentMenu';
import StickyTabs from './StickyTabs';
import { Alert } from '@mui/material';
import ReportTemplateMarketEcon from './ReportTemplateMarketEcon';

export default function IntermarketReport() {

  const reportMarket = [
    { id: "market-today", name: "Market Today", component: "Market Today" },
    { id: "trend-chart", name: "Asset Trends", component: "Trend Chart" },
    { id: "performance", name: "Performance", component: "Performance" },
    { id: "volatility", name: "Volatility", component: "Volatility" },
    { id: "correlation", name: "Correlation", component: "Correlation" },
    { id: "beta", name: "Beta", component: "Beta" },
    { id: "indicators", name: "Indicators", component: "Indicators" },
    { id: "yield-curve", name: "Yield Curve", component: "Yield Curve" },
    { id: "exchange-rate", name: "Exchange Rate", component: "Exchange Rate" },
  ];

  const overview = `Intermarket analysis examines the correlations and relationships between different financial markets, such as equities, bonds, commodities, and currencies. It is based on the idea that no market operates in isolation, and movements 
  in one market can have significant implications for others.
  Intermarket analysis is a powerful tool for understanding the broader market dynamics. By recognizing the relationships between different asset classes, traders and investors can make more informed decisions, anticipate market movements, and manage risk effectively. It’s an essential approach for anyone looking to navigate complex financial markets.
  `

  return (
    <ReportTemplateMarketEcon overview={overview} reportComponents={reportMarket}>
    
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {reportMarket.map((obj) => (
          <ReportSection key={obj.id} id={obj.id} name={obj.name} component={obj.component} />
        ))}
      </div>
        
    </ReportTemplateMarketEcon>
  );
}



const ReportSection = React.memo(({ id, name, component }) => {
    return (
      <div id={id}>
        <InfoBox>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <h3>{name}</h3>
            <div style={{ flex: 1 }}>
            <IntermarketReportInfo info={component} />
            </div>
          </div>
        </InfoBox>
      </div>
    );
  });
  
import React from 'react'
import EtfMfAnalytics from './etfMF/EtfMfAnalytics'

export default function EtfInfoList({info}) {
  const content = {
    "Profile":<EtfMfAnalytics type="Profile"/>,
    "ETF Summary":<EtfMfAnalytics type="Summary"/>,
    "Asset Allocation":<EtfMfAnalytics type="Asset Allocation"/>,
    "Holdings":<EtfMfAnalytics type="Holdings"/>,
    "Top Holdings":<EtfMfAnalytics type="Top Holdings"/>,
  }
  return (
    <>{content[info]}</>
  )
}

import { generateUniqueId } from "utils/utilGeneral";

const investmentPreferenceStockCriteria = {
    'Market Cap': ["All"],
    'Sector': ["All"],
    'Industry': ["All"],
    'Market Region': ["All"],
    "Dividend": ["Less Important"],
    "Derivatives": "Hedging"
}

const fieldInvestmentPreference = [
    {
        id:generateUniqueId(),
        type: "Stocks",
        information: {
            "Return Profile": "Capital Appreciation + Dividend",
            "Risk Profile": "High",
            "Liquidity": "High"
        },
        return: "Capital Appreciation + Dividend",
        criterias: investmentPreferenceStockCriteria,
        risk: "High",
        liquidity: "High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Trading", "Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "ETF",
        information: {
            "Return Profile": "Capital Appreciation + Dividend",
            "Risk Profile": "Moderate",
            "Liquidity": "High"
        },
        return: "Capital Appreciation + Dividend",
        risk: "Moderate",
        liquidity: "High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Mutual Fund",
        information: {
            "Return Profile": "Capital Appreciation + Dividend",
            "Risk Profile": "Moderate",
            "Liquidity": "Moderate-High"
        },
        return: "Capital Appreciation + Dividend",
        risk: "Moderate",
        liquidity: "Moderate-High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Fixed Income",
        information: {
            "Return Profile": "Capital Appreciation + Interest",
            "Risk Profile": "Moderate",
            "Liquidity": "Moderate-High"
        },
        criterias: {
            'Fixed Income Type': [],
            'Credit Rating': [],
            'Maturity': [],
            "Coupon Frequency": []
        },
        return: "Capital Appreciation + Interest",
        risk: "Moderate",
        liquidity: "Moderate-High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Real Estate",
        information: {
            "Return Profile": "Capital Appreciation + Rent",
            "Risk Profile": "Moderate",
            "Liquidity": "Low"
        },
        criterias: {
            'Type': [],
            'Location': [],
            'Property Type': [],
            "Market Value": []
        },
        return: "Capital Appreciation + Rent",
        risk: "Moderate",
        liquidity: "Low",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Commodities",
        information: {
            "Return Profile": "Capital Appreciation",
            "Risk Profile": "Moderate-High",
            "Liquidity": "Moderate-High"
        },
        criterias: {
            'Type': []
        },
        return: "Capital Appreciation",
        risk: "Moderate-High",
        liquidity: "Moderate-High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Trading", "Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Crypto",
        information: {
            "Return Profile": "Capital Appreciation",
            "Risk Profile": "Very High",
            "Liquidity": "High"
        },
        criterias: {
            'Platform': [],
            'Token Type': [],
            'Market Cap': []
        },
        return: "Capital Appreciation",
        risk: "Very High",
        liquidity: "High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading", "Long Term Investing"],
        primaryObjectiveSelected: ["Trading", "Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Forex",
        information: {
            "Return Profile": "Capital Appreciation",
            "Risk Profile": "Moderate-High",
            "Liquidity": "High"
        },
        return: "Capital Appreciation",
        risk: "Moderate-High",
        liquidity: "High",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Trading"],
        primaryObjectiveSelected: ["Trading"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Private Equity",
        information: {
            "Return Profile": "Capital Appreciation + Dividend",
            "Risk Profile": "Very High",
            "Liquidity": "Low"
        },
        criterias: {
            'Type': []
        },
        return: "Capital Appreciation + Dividend",
        risk: "Very High",
        liquidity: "Low",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    },
    {
        id:generateUniqueId(),
        type: "Hedge Fund",
        information: {
            "Return Profile": "Capital Appreciation + Dividend",
            "Risk Profile": "Very High",
            "Liquidity": "Low-Moderate"
        },
        return: "Capital Appreciation + Dividend",
        risk: "Very High",
        liquidity: "Low-Moderate",
        currentAllocation: 0,
        targetAllocation: 0,
        selected:false,
        primaryObjectiveOptions: ["Long Term Investing"],
        primaryObjectiveSelected: ["Long Term Investing"],
        esg: "Important"
    }
  ];

const liquidityOptions = ["Varied","Low","Low-Moderate","Moderate","Moderate-High","High","Very High"]
const riskOptions = ["Low","Low-Moderate","Moderate","Moderate-High","High","Very High"]
const dividendOptions =  ["Less Important","Moderately Important","Very Important"]
  
function DividendToValue(dividend){
    const dTV = {
      "Less Important":0,
      "Important":2,
      "Very Important":5
    }
    return dTV[dividend]
  }
  
  function LiquidityToVolume(volume){
    const rtvMap = {
      "Varied": 0,
      "Low":5,
      "Low-Moderate": 10,
      "Moderate": 15,
      "Moderate-High": 20,
      "High": 25,
      "Very High": 30
    }
  
    return rtvMap[volume]
  }
  
  function RiskToVolatility(risklevel){
    const rtvMap = {
      "Low": 5,
      "Low-Moderate": 10,
      "Moderate": 15,
      "Moderate-High": 20,
      "High": 25,
      "Very High": 30
    }
  
    return rtvMap[risklevel]
  }
  

export {fieldInvestmentPreference,
    investmentPreferenceStockCriteria,RiskToVolatility,LiquidityToVolume,DividendToValue,liquidityOptions,riskOptions,dividendOptions}
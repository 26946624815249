import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const OverflowWrapper = ({ children, sx = {}, containerSx = {} }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const checkOverflow = () => {
        setIsOverflowing(container.scrollWidth > container.clientWidth);
      };
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
      return () => window.removeEventListener('resize', checkOverflow);
    }
  }, [children]);

  const scrollTabs = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -container.clientWidth : container.clientWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div 
    style={{ position: 'relative',display:"flex",gap:"0.5rem"
    ,alignItems:"center",width:"100%",boxSizing:"border-box",overflow:"hidden",...sx}}>
     {isOverflowing && 
      <Button sx={{ minWidth: 0}} onClick={() => scrollTabs("left")}>
            <KeyboardArrowLeftIcon fontSize="large" />
          </Button>}
      <div ref={containerRef} style={{ display: 'flex', overflow:'hidden', ...containerSx }}>
        {children}
      </div>
      {isOverflowing && (
    <Button sx={{minWidth: 0}} onClick={() => scrollTabs("right")}>
    <KeyboardArrowRightIcon fontSize="large"/>
    </Button>
      )}
    </div>
  );
};

export default OverflowWrapper;

import { useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

const useFindPortfolio = () => {
  const {dispatchPortfolio,stateApp} = useAppInfo()
  const userId = stateApp.userId


  const findPortfolio = async () => {
    try {
      if (userId) {
        const response = await axios.post(`${BASE_URL}/findPortfolio/`, {
          userId
        });
        const portfolio = response.data;
        const accountNames = Object.keys(portfolio);
        const portfolioSel = portfolio[accountNames[0]];
        dispatchPortfolio({ portfolioLoaded: true });
        if (portfolio && accountNames.length > 0) {
          dispatchPortfolio({
            portfolio: portfolio,
            portfolioSel: portfolioSel,
            accountNameSel: accountNames[0],
            accountNames: accountNames
          });
        }
      }
    } catch (err) {
      dispatchPortfolio({ portfolioLoaded: true });
    }
  };

  useEffect(() => {
    if (userId) {
      findPortfolio();
    }
  }, [userId]);

  return null;
};

export default useFindPortfolio;

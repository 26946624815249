import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';


export default function useEtfWorldRegions(tickerSel) {
    const [worldRegions, setGeneralData] = useState([]);
    const [loadingWorldRegions, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
       getData();

    }, [tickerSel]);
    
    async function getData() {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/etfWorldRegion/`, {
          params: {
            ticker: JSON.stringify([tickerSel]),
          },
        });
        setGeneralData(response.data);
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    
    return {worldRegions,loadingWorldRegions}
}

import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';
import { useEffect } from "react";

export default function useIndustryFinancial() {

    const {stateIndustry,dispatchIndustry} = useAppInfo()

    useEffect(() => {  
      if (stateIndustry.industrySel && stateIndustry.countrySel){
        getData()}
    }, [stateIndustry.industrySel,stateIndustry.countrySel]);
    
    async function getData() {
      dispatchIndustry({"loadingIndAnnualInfo":true})
      try {
        const response = await axios.get(`${BASE_URL}/industryFinancialInfo/`, {
          params: {
            industry: JSON.stringify(stateIndustry.industrySel),
            country:JSON.stringify(stateIndustry.countrySel)
          },
        });
        
        dispatchIndustry({"industryAnnualInfo":response.data})
      } catch (error) {
        console.error(error);
      } finally {
        dispatchIndustry({"loadingIndAnnualInfo":false})
      }
    }
    
}
import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from 'AppState';

export default function useTickerInfo() {
    const {dispatchTicker} = useAppInfo()
    const [tickerInfoAll, setTickerInfo] = useState([]);
    
    useEffect(() => {
        getTickerInfo();
    }, []);


    async function getTickerInfo() {
        try {
        const response = await axios(`${BASE_URL}/tickers/`, {
            params: {
            page: 1,
            },
        });
        const tickerInfo = JSON.parse(response.data)
        setTickerInfo(tickerInfo);
        dispatchTicker({"tickerInfo":tickerInfo})
        } catch (error) {
        console.error(error);
        }
    }
    
    return ({tickerInfoAll})
  }
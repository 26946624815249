import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import SankeyModule from 'highcharts/modules/sankey';
import { dfin } from 'content/constants';
import { Alert } from '@mui/material';

// Register the Sankey module
SankeyModule(Highcharts);

const Sankey = ({ data, metric,statementType ,title=""}) => {
  const [sankeyData, setSankeyData] = useState([]);

  useEffect(() => {
    // Extract the necessary data from props.data and organize it for the Sankey chart
    const elements = metric || [];

    let sankeyChartData;
    if(statementType===dfin.incomeStatement){
        sankeyChartData = sankeyIncomeStatement(data)
    }
    else if (statementType===dfin.balanceSheet){
        sankeyChartData = sankeyBalanceSheet(data)
    }
    else if (statementType===dfin.cashFlow){
        sankeyChartData = sankeyCashFlow(data)
    }
    
    
    
    // elements.map((element) => ['Revenue', element, data[element] || 0]);

    setSankeyData(sankeyChartData);
  }, [data, metric,statementType]);

  const chartData = useMemo(() => {
    if (sankeyData.length > 0) {
      return {
        chart: {
          type:"sankey"
        },
        title: {
          text: title,
        },
        tooltip: {
          headerFormat: null,
          pointFormat: '{point.fromNode.name} \u2192 {point.toNode.name}: ${point.weight:,.2f}',
          nodeFormat: '{point.name}: ${point.sum:,.2f}',
        },
        credits: {
            enabled: false, // Hide Highcharts.com credits
          },
          accessibility: {
            enabled: false, // Include the accessibility module
          },
        series: [
          {
            type: 'sankey',
            data: sankeyData,
            linkColor: 'black', // color of the links
            nodeWidth: 5,
            nodePadding: 5,
            nodes: [
                { id: 'Revenue', color: 'lightblue' },
                {id:"Dereciation",color:"red"},
                ...sankeyData.map(([from, to]) => ({ id: to, color: getTypeColor("to",to) })),
                ...sankeyData.map(([from, to]) => ({ id: from, color: getTypeColor("from",from) })),
              ],
             
          },
        ],
      };
    } else {
      return null; // Return null when sankeyData is empty
    }
  }, [sankeyData]);


  return (
    <>
    <Alert severity='warning'>This is an experimental feature! The chart may not represent negative values correctly!</Alert>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartData}
      containerProps={{ style: { height: '100%', width: '100%' } }}
    />
    </>
  );
};

export default Sankey;

const getTypeColor = (idType="to",type) => {
  if (idType==="from"){
  if ( 
      ['LONG TERM INVESTMENTS', 'PROPERTY PLANT AND EQUIPMENT NET', 'INTANGIBLE ASSETS',
      "TOTAL NON CURRENT ASSETS",
      "GROSS PROFIT",
      'NON CURRRENT ASSETS OTHER', 'COMMON STOCK', 'RETAINED EARNINGS'].some(val => type.includes(val))) {
      return 'green';
  }
  if (["Cash and Equivalents",'Short Term Investments',
  'Net Receivables','Inventory'].some(val => type.includes(val))){
    return "#90EE90"
  }
  if (['LONG TERM DEBT', 'TOTAL CURRENT LIABILITIES', 'TOTAL NON CURRENT LIABILITIES',
  'NON CURRENT LIABILITIES TOTAL',"TOTAL LIAB",].some(val => type.includes(val))) {
      return 'red';
  }
  if (['TOTAL CURRENT LIABILITIES'].some(val => type.includes(val))) {
      return '#ffcccb';
  }}
  else{
  if (["EBIT","TOTAL NON CURRENT ASSETS", 'TOTAL CASH FROM OPERATING ACTIVITIES'].some(val => type.includes(val))) {
      return 'green';}
  if (["TOTAL CURRENT ASSETS"].some(val => type.includes(val))){
    return "#90EE90"
  }
  if (["TOTAL LIAB","Depreciation","Interest","Tax"].some(val => type.includes(val))) {
      return 'red';
  }
  }
  

};

function sankeyIncomeStatement(data){
    let sankeyChartData = [
        ["Revenue","GROSS PROFIT",data?.["GROSS PROFIT"]],
        ["Revenue","COGS",data?.["COST OF REVENUE"]],
        ["GROSS PROFIT","TOTAL OPERATING EXPENSES",data?.["TOTAL OPERATING EXPENSES"]],
        ["TOTAL OPERATING EXPENSES","SG&A",data?.["SELLING GENERAL ADMINISTRATIVE"]],
        ["TOTAL OPERATING EXPENSES","Other Operating Expense",data?.["OTHER OPERATING EXPENSES"]],
        ["TOTAL OPERATING EXPENSES","R&D",data?.["RESEARCH DEVELOPMENT"]],
        ["GROSS PROFIT","Operating Income",data?.["EBIT"]],
        ["Operating Income","EBITDA",data?.["EBITDA"]],
        ["EBITDA","Depreciation",data?.["DEPRECIATION AND AMORTIZATION"]],
        ["EBITDA","Interest",data?.["INTEREST EXPENSE"]],
        ["EBITDA","Tax",data?.["TAX PROVISION"]],
        ["EBITDA","Net Income",data?.["NET INCOME"]],
    ]
    return sankeyChartData
}

function sankeyBalanceSheet(data){
    let sankeyChartData = [
        // Current Assets
        ['Cash and Equivalents', 'TOTAL CURRENT ASSETS',  data?.['CASH']],
        ['Short Term Investments','TOTAL CURRENT ASSETS',  data?.['SHORT TERM INVESTMENTS']],
        ['Net Receivables','TOTAL CURRENT ASSETS',  data?.['NET RECEIVABLES']],
        ['Inventory','TOTAL CURRENT ASSETS',  data?.['INVENTORY']],
        // Add more current asset subsets as needed
  
        // Non-Current Assets
        [ 'LONG TERM INVESTMENTS','TOTAL NON CURRENT ASSETS', data?.['LONG TERM INVESTMENTS']],
        [ 'PROPERTY PLANT AND EQUIPMENT NET','TOTAL NON CURRENT ASSETS', data?.['PROPERTY PLANT AND EQUIPMENT NET']],
        [ 'INTANGIBLE ASSETS','TOTAL NON CURRENT ASSETS', data?.['INTANGIBLE ASSETS']],
        ["NON CURRRENT ASSETS OTHER","TOTAL NON CURRENT ASSETS",data?.['NON CURRRENT ASSETS OTHER']],
        // Add more non-current asset subsets as needed
  
        // Assets
        ['TOTAL CURRENT ASSETS','TOTAL ASSETS',  data?.['TOTAL CURRENT ASSETS']],
        ['TOTAL NON CURRENT ASSETS','TOTAL ASSETS', data?.['NON CURRENT ASSETS TOTAL']],
        ["TOTAL ASSETS",'TOTAL LIAB', data?.['TOTAL LIAB']],
        ["TOTAL ASSETS",'TOTAL EQUITY', data?.['TOTAL STOCKHOLDER EQUITY']],
        // Current Liabilities
        ['TOTAL CURRENT LIABILITIES', 'Short Term Debt', data?.['SHORT TERM DEBT']],
        ['TOTAL CURRENT LIABILITIES', 'ACCOUNTS PAYABLE', data?.['ACCOUNTS PAYABLE']],
        ['TOTAL CURRENT LIABILITIES', 'OTHER CURRENT LIAB', data?.['OTHER CURRENT LIAB']],
        // Add more current liability subsets as needed
  
        // Non-Current Liabilities
        ['TOTAL NON CURRENT LIABILITIES', 'Long Term Debt', data?.['LONG TERM DEBT']],
        // Add more non-current liability subsets as needed
  
        // Liabilities
        ['TOTAL LIAB', 'TOTAL CURRENT LIABILITIES', data?.['TOTAL CURRENT LIABILITIES']],
        ['TOTAL LIAB', 'TOTAL NON CURRENT LIABILITIES', data?.['NON CURRENT LIABILITIES TOTAL']],
  
        // Equity
        ['TOTAL EQUITY', 'Common Stock', data?.['COMMON STOCK']],
        ['TOTAL EQUITY', 'Retained Earnings', data?.['RETAINED EARNINGS']],
        // Add more equity subsets as needed
      ];
    return sankeyChartData
}

// function sankeyCashFlow(data){
//     let sankeyChartData = [
//         // CFO
//         ['TOTAL CASH FROM OPERATING ACTIVITIES', 'NET INCOME',  data?.['NET INCOME']],
//         ['TOTAL CASH FROM OPERATING ACTIVITIES','CHANGE TO INVENTORY',  data?.['CHANGE TO INVENTORY']],
//         ['TOTAL CASH FROM OPERATING ACTIVITIES',"CHANGE TO ACCOUNT RECEIVABLES",  data?.['CHANGE TO ACCOUNT RECEIVABLES']],
//         ['TOTAL CASH FROM OPERATING ACTIVITIES','OTHER NON CASH ITEMS',  data?.['OTHER NON CASH ITEMS']],
//         // Add more current asset subsets as needed
  
//          //CFI
//          [ 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES','CAPITAL EXPENDITURES', Math.abs(data?.['CAPITAL EXPENDITURES'])],
//          [ 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES','INVESTMENTS',Math.abs( data?.['INVESTMENTS'])],
//          [ 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES','OTHER CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['OTHER CASHFLOWS FROM INVESTING ACTIVITIES'])],
      
//          [ 'TOTAL CASH FROM FINANCING ACTIVITIES','NET BORROWINGS',Math.abs(data?.['NET BORROWINGS'])],
//          [ 'TOTAL CASH FROM FINANCING ACTIVITIES','ISSUANCE OF CAPITAL STOCK',Math.abs( data?.['ISSUANCE OF CAPITAL STOCK'])],
//          [ 'TOTAL CASH FROM FINANCING ACTIVITIES','DIVIDENDS PAID', Math.abs(data?.['DIVIDENDS PAID'])],
//          [ 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES','SALE PURCHASE OF STOCK', Math.abs(data?.['SALE PURCHASE OF STOCK'])],
//          [ 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES','OTHER CASHFLOWS FROM FINANCING ACTIVITIES', Math.abs(data?.['OTHER CASHFLOWS FROM FINANCING ACTIVITIES'])],
      
         
//         ];
//     return sankeyChartData
// }

function sankeyCashFlow(data){
  let sankeyChartData = [
      // CFO
      ['NET INCOME', 'TOTAL CASH FROM OPERATING ACTIVITIES', data?.['NET INCOME']],
      ['CHANGE TO INVENTORY', 'TOTAL CASH FROM OPERATING ACTIVITIES', data?.['CHANGE TO INVENTORY']],
      ['NET INCOME', 'TOTAL CASH FROM OPERATING ACTIVITIES', data?.['NET INCOME']],
      ['CHANGE TO ACCOUNT RECEIVABLES', 'TOTAL CASH FROM OPERATING ACTIVITIES', data?.['CHANGE TO ACCOUNT RECEIVABLES']],
      ['OTHER NON CASH ITEMS', 'TOTAL CASH FROM OPERATING ACTIVITIES', data?.['OTHER NON CASH ITEMS']],
      // Add more current asset subsets as needed

       //CFI
       ['CAPITAL EXPENDITURES', 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['CAPITAL EXPENDITURES'])],
       ['INVESTMENTS', 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['INVESTMENTS'])],
       ['OTHER CASHFLOWS FROM INVESTING ACTIVITIES', 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['OTHER CASHFLOWS FROM INVESTING ACTIVITIES'])],
       ['SALE PURCHASE OF STOCK', 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['SALE PURCHASE OF STOCK'])],
       ['OTHER CASHFLOWS FROM FINANCING ACTIVITIES', 'TOTAL CASHFLOWS FROM INVESTING ACTIVITIES', Math.abs(data?.['OTHER CASHFLOWS FROM FINANCING ACTIVITIES'])],

       ['NET BORROWINGS', 'TOTAL CASH FROM FINANCING ACTIVITIES', Math.abs(data?.['NET BORROWINGS'])],
       ['ISSUANCE OF CAPITAL STOCK', 'TOTAL CASH FROM FINANCING ACTIVITIES', Math.abs(data?.['ISSUANCE OF CAPITAL STOCK'])],
       ['DIVIDENDS PAID', 'TOTAL CASH FROM FINANCING ACTIVITIES', Math.abs(data?.['DIVIDENDS PAID'])],
       
  ];

  return sankeyChartData;
}
const currencyCodes = {
    "AED": "United Arab Emirates Dirham",
    "AFN": "Afghan Afghani",
    "ALL": "Albanian Lek",
    "AMD": "Armenian Dram",
    "ANG": "Netherlands Antillean Guilder",
    "AOA": "Angolan Kwanza",
    "ARS": "Argentine Peso",
    "AUD": "Australian Dollar",
    "AWG": "Aruban Florin",
    "AZN": "Azerbaijani Manat",
    "BAM": "Bosnia and Herzegovina Convertible Mark",
    "BBD": "Barbadian Dollar",
    "BDT": "Bangladeshi Taka",
    "BGN": "Bulgarian Lev",
    "BHD": "Bahraini Dinar",
    "BIF": "Burundian Franc",
    "BMD": "Bermudian Dollar",
    "BND": "Brunei Dollar",
    "BOB": "Bolivian Boliviano",
    "BRL": "Brazilian Real",
    "BSD": "Bahamian Dollar",
    "BTN": "Bhutanese Ngultrum",
    "BWP": "Botswana Pula",
    "BYN": "Belarusian Ruble",
    "BZD": "Belize Dollar",
    "CAD": "Canadian Dollar",
    "CDF": "Congolese Franc",
    "CHF": "Swiss Franc",
    "CLP": "Chilean Peso",
    "CNY": "Chinese Yuan",
    "COP": "Colombian Peso",
    "CRC": "Costa Rican Colón",
    "CUP": "Cuban Peso",
    "CVE": "Cape Verdean Escudo",
    "CZK": "Czech Republic Koruna",
    "DJF": "Djiboutian Franc",
    "DKK": "Danish Krone",
    "DOP": "Dominican Peso",
    "DZD": "Algerian Dinar",
    "EGP": "Egyptian Pound",
    "ERN": "Eritrean Nakfa",
    "ETB": "Ethiopian Birr",
    "EUR": "Euro",
    "FJD": "Fijian Dollar",
    "FKP": "Falkland Islands Pound",
    "FOK": "Faroe Islands Króna",
    "GBP": "British Pound Sterling",
    "GEL": "Georgian Lari",
    "GGP": "Guernsey Pound",
    "GHS": "Ghanaian Cedi",
    "GIP": "Gibraltar Pound",
    "GMD": "Gambian Dalasi",
    "GNF": "Guinean Franc",
    "GTQ": "Guatemalan Quetzal",
    "GYD": "Guyanaese Dollar",
    "HKD": "Hong Kong Dollar",
    "HNL": "Honduran Lempira",
    "HRK": "Croatian Kuna",
    "HTG": "Haitian Gourde",
    "HUF": "Hungarian Forint",
    "IDR": "Indonesian Rupiah",
    "ILS": "Israeli New Shekel",
    "IMP": "Isle of Man Pound",
    "INR": "Indian Rupee",
    "IQD": "Iraqi Dinar",
    "IRR": "Iranian Rial",
    "ISK": "Icelandic Króna",
    "JEP": "Jersey Pound",
    "JMD": "Jamaican Dollar",
    "JOD": "Jordanian Dinar",
    "JPY": "Japanese Yen",
    "KES": "Kenyan Shilling",
    "KGS": "Kyrgystani Som",
    "KHR": "Cambodian Riel",
    "KID": "Kiribati Dollar",
    "KIN": "Kinesian Dollar",
    "KRW": "South Korean Won",
    "KWD": "Kuwaiti Dinar",
    "KYD": "Cayman Islands Dollar",
    "KZT": "Kazakhstani Tenge",
    "LAK": "Laotian Kip",
    "LBP": "Lebanese Pound",
    "LKR": "Sri Lankan Rupee",
    "LRD": "Liberian Dollar",
    "LSL": "Lesotho Loti",
    "LYD": "Libyan Dinar",
    "MAD": "Moroccan Dirham",
    "MDL": "Moldovan Leu",
    "MGA": "Malagasy Ariary",
    "MKD": "Macedonian Denar",
    "MMK": "Myanmar Kyat",
    "MNT": "Mongolian Tugrik",
    "MOP": "Macanese Pataca",
    "MRU": "Mauritanian Ouguiya",
    "MUR": "Mauritian Rupee",
    "MVR": "Maldivian Rufiyaa",
    "MWK": "Malawian Kwacha",
    "MXN": "Mexican Peso",
    "MYR": "Malaysian Ringgit",
    "MZN": "Mozambican Metical",
    "NAD": "Namibian Dollar",
    "NGN": "Nigerian Naira",
    "NIO": "Nicaraguan Córdoba",
    "NOK": "Norwegian Krone",
    "NPR": "Nepalese Rupee",
    "NZD": "New Zealand Dollar",
    "OMR": "Omani Rial",
    "PAB": "Panamanian Balboa",
    "PEN": "Peruvian Nuevo Sol",
    "PGK": "Papua New Guinean Kina",
    "PHP": "Philippine Peso",
    "PKR": "Pakistani Rupee",
    "PLN": "Polish Złoty",
    "PYG": "Paraguayan Guarani",
    "QAR": "Qatari Rial",
    "RON": "Romanian Leu",
    "RSD": "Serbian Dinar",
    "RUB": "Russian Ruble",
    "RWF": "Rwandan Franc",
    "SAR": "Saudi Riyal",
    "SBD": "Solomon Islands Dollar",
    "SCR": "Seychellois Rupee",
    "SDG": "Sudanese Pound",
    "SEK": "Swedish Krona",
    "SGD": "Singapore Dollar",
    "SHP": "Saint Helena Pound",
    "SLL": "Sierra Leonean Leone",
    "SOS": "Somali Shilling",
    "SRD": "Surinamese Dollar",
    "SSP": "South Sudanese Pound",
    "STN": "São Tomé and Príncipe Dobra",
    "SYP": "Syrian Pound",
    "SZL": "Swazi Lilangeni",
    "THB": "Thai Baht",
    "TJS": "Tajikistani Somoni",
    "TMT": "Turkmenistani Manat",
    "TND": "Tunisian Dinar",
    "TOP": "Tongan Pa'anga",
    "TRY": "Turkish Lira",
    "TTD": "Trinidad and Tobago Dollar",
    "TVD": "Tuvaluan Dollar",
    "TWD": "New Taiwan Dollar",
    "TZS": "Tanzanian Shilling",
    "UAH": "Ukrainian Hryvnia",
    "UGX": "Ugandan Shilling",
    "USD": "United States Dollar",
    "UYU": "Uruguayan Peso",
    "UZS": "Uzbekistan Som",
    "VES": "Venezuelan Bolívar",
    "VND": "Vietnamese Đồng",
    "VUV": "Vanuatu Vatu",
    "WST": "Samoan Tala",
    "XAF": "Central African CFA Franc",
    "XCD": "East Caribbean Dollar",
    "XDR": "Special Drawing Rights",
    "XOF": "West African CFA Franc",
    "XPF": "CFP Franc",
    "YER": "Yemeni Rial",
    "ZAR": "South African Rand",
    "ZMW": "Zambian Kwacha",
    "ZWL": "Zimbabwean Dollar",
  };
  

  const currencyData = [
    { code: "AED", name: "United Arab Emirates Dirham", symbol: "د.إ" },
    { code: "AFN", name: "Afghan Afghani", symbol: "؋" },
    { code: "ALL", name: "Albanian Lek", symbol: "L" },
    { code: "AMD", name: "Armenian Dram", symbol: "֏" },
    { code: "ANG", name: "Netherlands Antillean Guilder", symbol: "ƒ" },
    { code: "AOA", name: "Angolan Kwanza", symbol: "Kz" },
    { code: "ARS", name: "Argentine Peso", symbol: "$" },
    { code: "AUD", name: "Australian Dollar", symbol: "$" },
    { code: "AWG", name: "Aruban Florin", symbol: "ƒ" },
    { code: "AZN", name: "Azerbaijani Manat", symbol: "₼" },
    { code: "BAM", name: "Bosnia and Herzegovina Convertible Mark", symbol: "KM" },
    { code: "BBD", name: "Barbadian Dollar", symbol: "$" },
    { code: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
    { code: "BGN", name: "Bulgarian Lev", symbol: "лв" },
    { code: "BHD", name: "Bahraini Dinar", symbol: ".د.ب" },
    { code: "BIF", name: "Burundian Franc", symbol: "FBu" },
    { code: "BMD", name: "Bermudian Dollar", symbol: "$" },
    { code: "BND", name: "Brunei Dollar", symbol: "$" },
    { code: "BOB", name: "Bolivian Boliviano", symbol: "Bs." },
    { code: "BRL", name: "Brazilian Real", symbol: "R$" },
    { code: "BSD", name: "Bahamian Dollar", symbol: "$" },
    { code: "BTN", name: "Bhutanese Ngultrum", symbol: "Nu." },
    { code: "BWP", name: "Botswana Pula", symbol: "P" },
    { code: "BYN", name: "Belarusian Ruble", symbol: "Br" },
    { code: "BZD", name: "Belize Dollar", symbol: "$" },
    { code: "CAD", name: "Canadian Dollar", symbol: "$" },
    { code: "CDF", name: "Congolese Franc", symbol: "FC" },
    { code: "CHF", name: "Swiss Franc", symbol: "Fr." },
    { code: "CLP", name: "Chilean Peso", symbol: "$" },
    { code: "CNY", name: "Chinese Yuan", symbol: "¥" },
    { code: "COP", name: "Colombian Peso", symbol: "$" },
    { code: "CRC", name: "Costa Rican Colón", symbol: "₡" },
    { code: "CUP", name: "Cuban Peso", symbol: "$" },
    { code: "CVE", name: "Cape Verdean Escudo", symbol: "$" },
    { code: "CZK", name: "Czech Republic Koruna", symbol: "Kč" },
    { code: "DJF", name: "Djiboutian Franc", symbol: "Fdj" },
    { code: "DKK", name: "Danish Krone", symbol: "kr" },
    { code: "DOP", name: "Dominican Peso", symbol: "$" },
    { code: "DZD", name: "Algerian Dinar", symbol: "د.ج" },
    { code: "EGP", name: "Egyptian Pound", symbol: "£" },
    { code: "ERN", name: "Eritrean Nakfa", symbol: "Nfk" },
    { code: "ETB", name: "Ethiopian Birr", symbol: "Br" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "FJD", name: "Fijian Dollar", symbol: "$" },
    { code: "FKP", name: "Falkland Islands Pound", symbol: "£" },
    { code: "FOK", name: "Faroe Islands Króna", symbol: "kr" },
    { code: "GBP", name: "British Pound Sterling", symbol: "£" },
    { code: "GEL", name: "Georgian Lari", symbol: "₾" },
    { code: "GGP", name: "Guernsey Pound", symbol: "£" },
    { code: "GHS", name: "Ghanaian Cedi", symbol: "₵" },
    { code: "GIP", name: "Gibraltar Pound", symbol: "£" },
    { code: "GMD", name: "Gambian Dalasi", symbol: "D" },
    { code: "GNF", name: "Guinean Franc", symbol: "FG" },
    { code: "GTQ", name: "Guatemalan Quetzal", symbol: "Q" },
    { code: "GYD", name: "Guyanaese Dollar", symbol: "$" },
    { code: "HKD", name: "Hong Kong Dollar", symbol: "$" },
    { code: "HNL", name: "Honduran Lempira", symbol: "L" },
    { code: "HRK", name: "Croatian Kuna", symbol: "kn" },
    { code: "HTG", name: "Haitian Gourde", symbol: "G" },
    { code: "HUF", name: "Hungarian Forint", symbol: "Ft" },
    { code: "IDR", name: "Indonesian Rupiah", symbol: "Rp" },
    { code: "ILS", name: "Israeli New Shekel", symbol: "₪" },
    { code: "IMP", name: "Isle of Man Pound", symbol: "£" },
    { code: "INR", name: "Indian Rupee", symbol: "₹" },
    { code: "IQD", name: "Iraqi Dinar", symbol: "ع.د" },
    { code: "IRR", name: "Iranian Rial", symbol: "﷼" },
    { code: "ISK", name: "Icelandic Króna", symbol: "kr" },
    { code: "JEP", name: "Jersey Pound", symbol: "£" },
    { code: "JMD", name: "Jamaican Dollar", symbol: "$" },
    { code: "JOD", name: "Jordanian Dinar", symbol: "د.ا" },
    { code: "JPY", name: "Japanese Yen", symbol: "¥" },
    { code: "KES", name: "Kenyan Shilling", symbol: "Sh" },
    { code: "KGS", name: "Kyrgystani Som", symbol: "с" },
    { code: "KHR", name: "Cambodian Riel", symbol: "៛" },
    { code: "KID", name: "Kiribati Dollar", symbol: "$" },
    { code: "KPW", name: "North Korean Won", symbol: "₩" },
    { code: "KRW", name: "South Korean Won", symbol: "₩" },
    { code: "KWD", name: "Kuwaiti Dinar", symbol: "د.ك" },
    { code: "KYD", name: "Cayman Islands Dollar", symbol: "$" },
    { code: "KZT", name: "Kazakhstani Tenge", symbol: "₸" },
    { code: "LAK", name: "Laotian Kip", symbol: "₭" },
    { code: "LBP", name: "Lebanese Pound", symbol: "ل.ل" },
    { code: "LKR", name: "Sri Lankan Rupee", symbol: "Rs" },
    { code: "LRD", name: "Liberian Dollar", symbol: "$" },
    { code: "LSL", name: "Lesotho Loti", symbol: "L" },
    { code: "LYD", name: "Libyan Dinar", symbol: "ل.د" },
    { code: "MAD", name: "Moroccan Dirham", symbol: "د.م." },
    { code: "MDL", name: "Moldovan Leu", symbol: "L" },
    { code: "MGA", name: "Malagasy Ariary", symbol: "Ar" },
    { code: "MKD", name: "Macedonian Denar", symbol: "ден" },
    { code: "MMK", name: "Myanmar Kyat", symbol: "K" },
    { code: "MNT", name: "Mongolian Tugrik", symbol: "₮" },
    { code: "MOP", name: "Macanese Pataca", symbol: "P" },
    { code: "MRU", name: "Mauritanian Ouguiya", symbol: "UM" },
    { code: "MUR", name: "Mauritian Rupee", symbol: "₨" },
    { code: "MVR", name: "Maldivian Rufiyaa", symbol: "Rf" },
    { code: "MWK", name: "Malawian Kwacha", symbol: "MK" },
    { code: "MXN", name: "Mexican Peso", symbol: "$" },
    { code: "MYR", name: "Malaysian Ringgit", symbol: "RM" },
    { code: "MZN", name: "Mozambican Metical", symbol: "MT" },
    { code: "NAD", name: "Namibian Dollar", symbol: "$" },
    { code: "NGN", name: "Nigerian Naira", symbol: "₦" },
    { code: "NIO", name: "Nicaraguan Córdoba", symbol: "C$" },
    { code: "NOK", name: "Norwegian Krone", symbol: "kr" },
    { code: "NPR", name: "Nepalese Rupee", symbol: "₨" },
    { code: "NZD", name: "New Zealand Dollar", symbol: "$" },
    { code: "OMR", name: "Omani Rial", symbol: "ر.ع." },
    { code: "PAB", name: "Panamanian Balboa", symbol: "B/." },
    { code: "PEN", name: "Peruvian Nuevo Sol", symbol: "S/." },
    { code: "PGK", name: "Papua New Guinean Kina", symbol: "K" },
    { code: "PHP", name: "Philippine Peso", symbol: "₱" },
    { code: "PKR", name: "Pakistani Rupee", symbol: "₨" },
    { code: "PLN", name: "Polish Złoty", symbol: "zł" },
    { code: "PYG", name: "Paraguayan Guarani", symbol: "₲" },
    { code: "QAR", name: "Qatari Rial", symbol: "ر.ق" },
    { code: "RON", name: "Romanian Leu", symbol: "lei" },
    { code: "RSD", name: "Serbian Dinar", symbol: "дин." },
    { code: "RUB", name: "Russian Ruble", symbol: "₽" },
    { code: "RWF", name: "Rwandan Franc", symbol: "RF" },
    { code: "SAR", name: "Saudi Riyal", symbol: "ر.س" },
    { code: "SBD", name: "Solomon Islands Dollar", symbol: "$" },
    { code: "SCR", name: "Seychellois Rupee", symbol: "₨" },
    { code: "SDG", name: "Sudanese Pound", symbol: "ج.س." },
    { code: "SEK", name: "Swedish Krona", symbol: "kr" },
    { code: "SGD", name: "Singapore Dollar", symbol: "$" },
    { code: "SHP", name: "Saint Helena Pound", symbol: "£" },
    { code: "SLL", name: "Sierra Leonean Leone", symbol: "Le" },
    { code: "SOS", name: "Somali Shilling", symbol: "Sh" },
    { code: "SRD", name: "Surinamese Dollar", symbol: "$" },
    { code: "SSP", name: "South Sudanese Pound", symbol: "£" },
    { code: "STN", name: "São Tomé and Príncipe Dobra", symbol: "Db" },
    { code: "SYP", name: "Syrian Pound", symbol: "£" },
    { code: "SZL", name: "Swazi Lilangeni", symbol: "E" },
    { code: "THB", name: "Thai Baht", symbol: "฿" },
    { code: "TJS", name: "Tajikistani Somoni", symbol: "ЅМ" },
    { code: "TMT", name: "Turkmenistani Manat", symbol: "T" },
    { code: "TND", name: "Tunisian Dinar", symbol: "د.ت" },
    { code: "TOP", name: "Tongan Pa'anga", symbol: "T$" },
    { code: "TRY", name: "Turkish Lira", symbol: "₺" },
    { code: "TTD", name: "Trinidad and Tobago Dollar", symbol: "$" },
    { code: "TVD", name: "Tuvaluan Dollar", symbol: "$" },
    { code: "TWD", name: "New Taiwan Dollar", symbol: "NT$" },
    { code: "TZS", name: "Tanzanian Shilling", symbol: "Sh" },
    { code: "UAH", name: "Ukrainian Hryvnia", symbol: "₴" },
    { code: "UGX", name: "Ugandan Shilling", symbol: "Sh" },
    { code: "USD", name: "United States Dollar", symbol: "$" },
    { code: "UYU", name: "Uruguayan Peso", symbol: "$" },
    { code: "UZS", name: "Uzbekistan Som", symbol: "so'm" },
    { code: "VES", name: "Venezuelan Bolívar", symbol: "Bs." },
    { code: "VND", name: "Vietnamese Đồng", symbol: "₫" },
    { code: "VUV", name: "Vanuatu Vatu", symbol: "VT" },
    { code: "WST", name: "Samoan Tala", symbol: "WS$" },
    { code: "XAF", name: "Central African CFA Franc", symbol: "Fr" },
    { code: "XCD", name: "East Caribbean Dollar", symbol: "$" },
    { code: "XDR", name: "Special Drawing Rights", symbol: "SDR" },
    { code: "XOF", name: "West African CFA Franc", symbol: "Fr" },
    { code: "XPF", name: "CFP Franc", symbol: "₣" },
    { code: "YER", name: "Yemeni Rial", symbol: "﷼" },
    { code: "ZAR", name: "South African Rand", symbol: "R" },
    { code: "ZMW", name: "Zambian Kwacha", symbol: "ZK" },
    { code: "ZWL", name: "Zimbabwean Dollar", symbol: "Z$" },
  ];

function FindCurrencySymbol(code){
  const currencySymbol = currencyData.find(currency => currency.code === code);
  
  return currencySymbol
}


export {currencyCodes,currencyData,FindCurrencySymbol}
  
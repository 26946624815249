import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


export default function useDeleteNote() {
  const {dispatchNotes,stateApp} = useAppInfo()
  const userId = stateApp.userId

  const [error, setError] = useState(null);
  
  const deleteNote = async (noteName) => {
    try {
      dispatchNotes({saved:false})
      const response = await axios.post(`${BASE_URL}/deleteNotes/`, {
        userId,
        noteName:noteName
      });
      dispatchNotes({saved:true})
      setError("Success")
    } catch (err) {
      setError('Error adding Note data');
    }
  };

  return { deleteNote, error };
};


import React from 'react';
import { Button, Skeleton } from '@mui/material';

export default function LoadingSkeletonTable({ length = 5, columns = 5 }) {
  return (
    <div style={{overflow:"hidden",height:"100%",width:"100%"}}>


  
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          {Array.from({ length: columns }).map((_, index) => (
            <th key={index} style={{ padding: '4px', borderBottom: '1px solid #ccc', width: `${100 / columns}%` }}>
              <Skeleton variant="text" width="100%" height="20px" />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: length }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <td key={colIndex} style={{ padding: '4px', borderBottom: '1px solid #ccc', width: `${100 / columns}%` }}>
                <Skeleton variant="rectangular" width="100%" height="20px" />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>

</div>
  );
}

import React, { useState } from 'react';

const ColorBar = ({ minValue, maxValue }) => {
  const [hoveredValue, setHoveredValue] = useState(null);

  const gradientStyle = {
    background: `linear-gradient(to right, red, #08FF08)`,
    width: '300px',
    borderRadius: '25px',
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'space-between', // To evenly distribute the labels
    color: 'white',
    height: '1.5rem',
    position: 'relative', // Add this to make tooltips relative to the container
  };

  const labelStyle = {
    // width: '20%', // Adjust this as needed
    marginLeft: '2%',
    marginRight: '2%',
    textAlign: 'center',
  };

  const tooltipStyle = {
    position: 'absolute',
    top: '-30px', // Adjust the tooltip position as needed
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '4px',
  };

  const handleMouseMove = (e) => {
    const barWidth = e.currentTarget.offsetWidth;
    const cursorX = e.nativeEvent.offsetX;
    const value = (cursorX / barWidth) * (maxValue - minValue) + minValue;
    setHoveredValue(value.toFixed(2));
  };
  const handleMouseOut = () => {
    setHoveredValue(null);
  };

  return (
    <div>
      <div style={gradientStyle} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}>
        <div
          style={labelStyle}
          className="label"
        >
          Min: {minValue.toFixed(2)}
          {hoveredValue !== null &&
          <div className="tooltip" style={tooltipStyle}>
             {hoveredValue}
          </div>}
        </div>
        <div> 0 </div>
        <div
          style={labelStyle}
          className="label"
        >
          Max: {maxValue.toFixed(2)}
         
        </div>
      </div>
    </div>
  );
};

export default ColorBar;

import { Paper } from '@mui/material'
import React from 'react'

export default function BorderBox({children,className,sx}) {
  return (
    <Paper className={`${className}`} 
    style={{borderRadius:"4px",width:"100%",height:"100%",boxSizing:"border-box",padding:"0.5rem",...sx}}>
        {children}
    </Paper>
  )
}

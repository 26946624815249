import React, { useState } from 'react'
import { useAppInfo } from 'AppState';
import { formatNumber } from "utils/utilGeneral";
import MyFinancialsHeaderTemplate from '../Template/MyFinancialsHeaderTemplate';
import { menuIcons } from 'nav/menuIcons';

export default function NetworthHeader({type="Networth",title="Networth",displayType="main"}) {
    const { stateMyFinancials, dispatchMyFinancials } = useAppInfo();
    const { summaryStats,currency} = stateMyFinancials;
   
 const { netWorth, totalAssets, totalLiabilities, totalAssetTypes, totalLiabilityTypes } = summaryStats;


 let summary = netWorth
 let icon = menuIcons?.Networth
 let color = "green"
 
 if (type === "Assets"){
  summary = totalAssets
  icon = menuIcons?.Asset
 }

 else if (type === "Liabilities"){
  summary = totalLiabilities
  icon = menuIcons?.Liability
  color = "red"
 }

 else {
  summary = netWorth
  icon = menuIcons?.Networth
  color = netWorth > 0 ? "green":"red"
 }
 
 return (
    <MyFinancialsHeaderTemplate name={title} 
    icon={icon}
    >
<h3 style={{color:color}}>{currency?.symbol} {formatNumber(summary)}</h3>
    </MyFinancialsHeaderTemplate>
  )
}
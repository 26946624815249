import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useEconMultiSeries(indicators=[]) {
  
  const [econMultiSeries, setEconSeries] = useState([]);
  const [loadingEconMultiSeries, setLoadingEconSeries] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    if (indicators.length>0){
    getEconInfo();}
  },[indicators]);

  async function getEconInfo() {
    setLoadingEconSeries(true);
    try {
      const response = await axios.get(`${BASE_URL}/econMultiIndicators/`, {
        params: {
          indicators:JSON.stringify(indicators),
        },
      });
      
      setEconSeries(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEconSeries(false);
    }
  }
  

  return { econMultiSeries, loadingEconMultiSeries };
}

import React from 'react'
import { useAppInfo } from 'AppState';
import useDividendHistory from 'data/financialModelingPrep/useDividendHistory';
import TableGen from 'components/Table/TableGen';
import TimeSeriesCategoryChart from 'components/Charts/TimeSeriesCategoryChart';
import NoTickerInfo from 'components/NoTickerInfo';

export default function Dividend() {
  const { stateTicker,stateStock} = useAppInfo();
  const {coInfo} = stateStock
  const { dividendHistory } = useDividendHistory(stateTicker.tickerStock);

  return (
    <>
    {dividendHistory.length>0 && 
    <>
    <div style={{height:"50%",width:"100%"}}>
    <TimeSeriesCategoryChart chartType='column' data={dividendHistory} 
    metricSelected={["dividend"]}/>
    </div>
    <div>
    <TableGen data={dividendHistory}/>
    </div>
    </>
    }
    {dividendHistory.length === 0 && <NoTickerInfo infoName='Dividend'/>}
    </>
  )
}

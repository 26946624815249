import React from 'react'
import TabsClose from 'components/Tabs/TabsClose'
import PeerFundamentalTable from './PeerFundamentalTable';
import PeerFundamentalChart from './PeerFundamentalChart';
import { LinearProgress } from '@mui/material';

export default function CompanyFinancialsPeers({data=[],handleMetricSelected,financialsSettings,metricSelected,table=true}) {
    
    const {growthMode,chartType,displayType} = financialsSettings
    
      
    function handleDeleteTab(index) {
      const metricUpdate = metricSelected.filter((_, i) => i !== index);
      handleMetricSelected(metricUpdate)
        // Update the tabList and tabContent state with the updated values
      }

      const subTabContent = metricSelected.map((metric) => (
        <>
          {!data.length === 0 && <LinearProgress />}
    
          {data.length > 0 && !growthMode && ["Chart","Chart & Table"].includes(displayType) && (
           <div style={{width:"100%",height:displayType === "Chart"?"100%":"60%"}}>          
            <PeerFundamentalChart
                chartType={chartType}
                data={data}
                metrics={metric}
              />
              </div>
    
          )}
    
          {data.length > 0 && growthMode && (
            <div style={{width:"100%",height:displayType === "Chart"?"100%":"60%"}}>
            <PeerFundamentalChart
             growthMode
             chartType={chartType}
              metrics={metric}
              data={data}
            />
            </div>
          )}
    
          {(data.length > 0 && table && ["Table","Chart & Table"].includes(displayType)) &&
            <PeerFundamentalTable
              growthMode={growthMode}
              metrics={metric}
              data={data}
            />
          }
        </>
      ));
  return (
    <TabsClose
    tabList={metricSelected}
    tabContent={subTabContent}
    deleteTab={true}
    handleDeleteTab={handleDeleteTab}
  ></TabsClose>
  )
}

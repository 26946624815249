import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useEconInfo() {
    const [econInfo, setEconInfo] = useState([]);
    useEffect(() => {
        getMetricInfo();
      }, []);
    
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`${BASE_URL}/econInfo/`);
          setEconInfo(JSON.parse(responseMetric.data));
        } catch (error) {
          console.error(error);
        }
      }
    
    return {econInfo}
    
}


export async function fetchEconInfo() {
  try {
    const responseMetric = await axios(`${BASE_URL}/econInfo/`);
    return JSON.parse(responseMetric.data);
  } catch (error) {
    console.error(error);
    return "Some issue finding the content."
  }
}

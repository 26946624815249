import React, { useEffect,useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PieChartPeers from 'components/Charts/PieChartPeers'
import { useAppInfo } from 'AppState';
import BarChartPeers from 'components/Charts/BarChartPeers';
import SelectBox from 'components/Inputs/SelectBox';
import TabsClose from 'components/Tabs/TabsClose';
import MetricStockModal from 'components/Template/MetricStockModal';
import Histogram from 'components/Charts/Histogram';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import {Button, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RefreshIcon from '@mui/icons-material/Refresh';
import PackedBubbleChart from 'components/Charts/PackedBubbleChart';
import SettingsIcon from '@mui/icons-material/Settings';
import Modal from 'components/Modal/Modal';
import AddPeersBar from 'peerList/AddPeersBar';
import { MetricSettingsBar } from 'components/Template/MetricSettings';
import PageSettingsModal from 'components/Template/PageSettingsModal';

export default function PeerOverviewMetricComp({infoName,storeId,defaultMet,
  metricOptions,chartTypeDefault="Bar",chartTypeOptions=["Bar","Pie","Bar & Pie"],
  children,data,addPeers=false}) {
    const {stateTicker,dispatchStock,stateStock} = useAppInfo()
    const peerSelected = stateTicker.peerSelected
    const {displayType} = stateStock.overviewSettings
    
    const [metricSelected, setMetricSelected] = useState([]);
    const [addMetrics, setAddMetrics] = useState(false);
    const [resetMetric, setResetMetric] = useState(false);
   

    const [selectedChartLayout, setChartLayout] = useState('Multiple Charts');
    const chartLayoutOptions = ["Multiple Charts","Single Chart"]
    const [selectedChartType, setChartType] = useState(chartTypeDefault);
    const chartType = chartTypeOptions
    const [dataLabelsChecked, setDataLabelsChecked] = useState(true);


    function handleAddTabs() {
        setAddMetrics(!addMetrics);
      };

    function handleResetMetric(){
    setMetricSelected(defaultMet)
    sessionStorage.setItem(
      `${storeId}`,
      JSON.stringify(defaultMet)
    );
    }

    function handleDeleteTab(index) {
    setMetricSelected((prevItems) => {
        // Filter out the item at the specified index
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
    });
    // Update the tabList and tabContent state with the updated values
    }

    
  const handleDataLabelsChange = (event) => {
    setDataLabelsChecked(event.target.checked);
    // Perform any additional logic or actions based on the checked state
  };

  const handleChartLayout = (e,v) => {
    setChartLayout(v);
  };

  const handleChartType = (e,v) => {
    setChartType(v);
  };

  function handleMetricSelected(option) {

    setMetricSelected((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });

    if (metricSelected.length > 0) {
      sessionStorage.setItem(
        `${storeId}`,
        JSON.stringify(metricSelected)
      );
    }
  }
  function chartKeysBrandVisual(metric) {
    const keys = {
      mainBubble: dfin.gicIndustry,
      mainBubbleSize: metric,
      subBubble: dfin.ticker,
      subBubbleSize: metric,
      imageURL: dfin.coLogo
    };
  
    return keys;
  }
  
  const subTabContent = metricSelected.map((metric) => (
    <>
    <h2 style={{display:"flex",justifyContent:"center",alignItems:"center"}}>{metric}</h2>
      <div style={{display:"flex"}}>
      {selectedChartType==="BrandVisuals" && <PackedBubbleChart data={getSpecificArrayObjKeys(data,[dfin.ticker,dfin.coLogo,dfin.gicIndustry,metric])} keys={chartKeysBrandVisual(metric)}/>}
      {selectedChartType==="Histogram" && <Histogram data={getSpecificArrayObjKeys(data,[metric])}/>}
      {(selectedChartType === "Bar" || selectedChartType === "Bar & Pie") && 
      (<BarChartPeers
         key={metric.id} // Use a unique identifier as the key
         data={data}
         metricSelected={[metric]}
         dataLabel={dataLabelsChecked}
         chartLegend={false}
       />)}
      
      {(selectedChartType === "Pie" || selectedChartType === "Bar & Pie") && 
        <PieChartPeers
         data={data}
         key={metric.id} // Use a unique identifier as the key
         metricSelected={[metric]}
         dataLabel={dataLabelsChecked}
         chartLegend={false}
       />}
     </div>
     </>
       
     ));
  
  useEffect(() => {
    
    const savedMetricSelected = sessionStorage.getItem(
      `${storeId}`
      );

    if (savedMetricSelected){
      setMetricSelected(JSON.parse(savedMetricSelected))
    }else{setMetricSelected(defaultMet)}

    }
    ,[peerSelected,infoName,defaultMet])

const dataView = chartType.includes("BrandVisuals")?"Chart":displayType

function handleDataView(v){
  dispatchStock({"overviewSettings":{...stateStock.overviewSettings,displayType:v}})
}
  
  const [chartSettings,setChartSettings] = useState(false)

  
  return (
    <>

    <div className='containerColumnFlexNoGap'>
        
        <MetricSettingsBar
        title={infoName}
        addMetric
        displaySettings
        handleAddMetric={handleAddTabs}
        handleDisplaySettings={()=>setChartSettings(!chartSettings)}
        />


        {children}

        
        {["Chart","Chart & Table"].includes(dataView) &&
        <>
           {selectedChartLayout !== "Multiple Charts" ? (
  <>
    <BarChartPeers
      data={data}
      metricSelected={metricSelected}
      dataLabel={dataLabelsChecked}
      chartLegend={true}
    />

    <div style={{display:"flex",flexWrap:"wrap",width:"100%"}} >
    {metricSelected.map((metric) => (
      <div style={{width:"50%",maxWidth:"50%",flex:1}}>
      <PieChartPeers
        data={data}
        key={metric} // Use a unique identifier as the key
        title={metric}
        metricSelected={[metric]}
        dataLabel={dataLabelsChecked}
        chartLegend={false}
      />
      </div>
    ))}
    </div>
  </>
) : null}
    
    {selectedChartLayout==="Multiple Charts" || chartType.includes("BrandVisuals")? (
    <div style={{height:"90%",width:"100%",flex:1,overflow:"auto"}}>
    <TabsClose
            tabList={metricSelected}
            tabContent={subTabContent}
            tabStyle="subTabs"
            tabOrientation="row"
            addTab={true}
            handleAddTabs={handleAddTabs}
            deleteTab={true}
            handleDeleteTab={handleDeleteTab}
          >
          </TabsClose>
          </div>):null}
          </>}
      {(["Table","Chart & Table"].includes(dataView) && data.length>0 && !chartType.includes("BrandVisuals")) && 
      <div style={{flex:1,width:"100%",overflow:"auto"}}>
      <TableGen data={getSpecificArrayObjKeys(data,[dfin.ticker,dfin.coName,...metricSelected])}/>
      </div>}

     {addPeers &&  <AddPeersBar/>}
    </div>
    {addMetrics &&
          <MetricStockModal
          modalName={infoName}
          handleModalClose={handleAddTabs} 
          handleMetricSelected={handleMetricSelected} 
          checkbox={true}  metricSelected={metricSelected}/> 
            }
      {chartSettings && 
      <PageSettingsModal title='Display Settings' onClose={()=>setChartSettings(false)}>
      <SelectBox options={["Chart","Table","Chart & Table"]} 
        value={dataView} onChange={(e,v)=>handleDataView(v)} label={"Data View"}/>
       {["Chart","Chart & Table"].includes(dataView) && <>
        <SelectBox options={chartLayoutOptions} 
        value={selectedChartLayout} onChange={handleChartLayout} label={"Chart Layout"}/>
       
        <SelectBox options={chartType} value={selectedChartType} 
        onChange={handleChartType} label={"Chart Type"}/>
        <div>
        <FormControlLabel
            control={
            <Checkbox
            checked={dataLabelsChecked}
            onChange={handleDataLabelsChange}
            />
            }
            label="Data Labels"/>
        </div>
        </>
        }
        </PageSettingsModal>}
    
    </>
  )
}

import React, { useEffect, useState } from 'react'
import styles from "./Pricing.module.css"
import PricingBox from './PricingBox'
import { useParams } from 'react-router-dom'
import { Alert } from '@mui/material'
import { productPricingList } from 'content/contentPricing'
import HomePageTemplate from 'home/HomePageTemplate'


export default function Pricing() {
  const [productSel,setProductSel] = useState("Learn & Apply")

  const {idProduct,idUserName} = useParams()
  useEffect(()=>{
    setProductSel(idProduct)
  },[])


  function handleProductType(e){
    setProductSel(e.target.value)
  }

  return (
    <HomePageTemplate pageName='Pricing'>
      <Alert severity='warning'>These are prospective pricing plans!</Alert>
      <div className={styles.cardsContainer}>
       {productPricingList.map((product)=>
       <PricingBox key={product} info={product} onClick={handleProductType} value={productSel}/>)}     
        </div>
    </HomePageTemplate>
  )
}

import React, {
  useEffect,
  useState,
} from 'react';

import { Paper } from '@mui/material';

import { useAppInfo } from 'AppState';
import SelectBox from 'components/Inputs/SelectBox';
import { menuEcon } from 'nav/menu';
import useEconInfo from 'data/economy/useEconInfo';
import EconomyInfoList from 'analytics/economy/EconomyInfoList';
import EconSearchSeriesUI from 'analytics/economy/EconSearchSeriesUI';
import PageHeaderIcons from 'nav/PageHeaderIcons';
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import { PageContentWithLeftMenuLayout } from 'layout/PageLayout';
import PageHeader from 'layout/PageHeader';

export default function Economy() {
  const { stateEcon, dispatchEcon } = useAppInfo();
  const { econInfo } = useEconInfo();
  const { menuSel, contentSel, econId } = stateEcon;
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const infoSel = menuEcon.filter((obj) => obj.name === menuSel);
    if (contentSel !== "search") {
      setContentList(infoSel[0]?.content);
    }
  }, [menuSel]);

  function handleMenuSel(v) {
    dispatchEcon({"menuSel":v });
    const infoSel = menuEcon
      .filter((obj) => obj.name === v)
      .map((obj) => obj.content);

    if (infoSel[0].length > 0) {
      dispatchEcon({"contentSel":infoSel[0][0] });
    } else {
      dispatchEcon({"contentSel":v });
    }
  }

  function handleContentSel(n, v) {
    dispatchEcon({"menuSel": n,"contentSel":v});
  }

  const pageHeader =  <PageHeader 
                        marketSelect={true} 
                        title={"Economy"} 
                        content={
                        <SelectBox width='200px' variant="outlined" label={"Content"} options={["Overview","Global"
                        ,"Macro Econ Indicators","vs Market","Map","Calendar"]} 
                        value={menuSel} onChange={(e,v)=>handleMenuSel(v)}/>}
                        toolkit={
                          <>
                          <PageHeaderIcons name="Explore"/>
                          <PageHeaderIcons name="Guide" guideType={"Economy"}/>
                          </>}
                          />
  

  return (
    <PageContentWithLeftMenuLayout
    showMenu={false}
    header={pageHeader}
    >
        {contentSel !== "search" && (
          <>
          {contentList.length>0 && 
          <LeftSidebarFloating header={menuSel}>
            {contentList.map((obj,index)=>
            <div style={{display:"flex",flexDirection:"column"}} key={index}>
              <LeftSidebarButton
              onClick={(v)=>handleContentSel(menuSel,v)}
              selected={obj===contentSel}
              name={obj}
              />
            </div>
            )}
            </LeftSidebarFloating>}
            
            <EconomyInfoList infoName={contentSel} />
          </>
        )}
        {contentSel === "search" && (
          <div className="containerColumnFlex1">
            <Paper>
            <EconSearchSeriesUI econInfo={econInfo} seriesName={econId} /></Paper>
          </div>
        )} 
    
    </PageContentWithLeftMenuLayout>
  );
}


import { formatNumber } from 'utils/utilGeneral';

function calculateGrowth(previous, current) {
  if (previous === 0) {
    return current === 0 ? 0 : 100;
  }
  return ((current - previous) / Math.abs(previous)) * 100;
}

// Function to calculate growth rates for an array of financial data
export function calculateGrowthRates({ data, metrics, dateKey = "DATE" }) {
  return data.map((current, index) => {
    if (index === 0) {
      // Initialize growth for the first entry
      let initialGrowth = { [dateKey]: current[dateKey] };
      metrics.forEach(metric => {
        initialGrowth[metric] = 0;
      });
      return initialGrowth;
    }

    const previous = data[index - 1];
    let growth = { [dateKey]: current[dateKey] };

    metrics.forEach(metric => {
      growth[metric] = formatNumber(calculateGrowth(previous[metric], current[metric]));
    });

    return growth;
  });
}


export function calculateGrowthRatePeers({ data, metrics, tickerKey = "TICKER", dateKey = "PERIOD" }) {
    // Group data by ticker
    const groupedData = data.reduce((acc, current) => {
      const ticker = current[tickerKey];
      if (!acc[ticker]) {
        acc[ticker] = [];
      }
      acc[ticker].push(current);
      return acc;
    }, {});
  

    Object.keys(groupedData).forEach(ticker => {
      groupedData[ticker].sort((a, b) => (a[dateKey]) - (b[dateKey]));
    });
  
    // Calculate growth rates for each group
    const growthRates = Object.keys(groupedData).flatMap(ticker => {
      const group = groupedData[ticker];
      return group.map((current, index) => {
        if (index === 0) {
          // Initialize growth for the first entry in the group
          let initialGrowth = { [tickerKey]: ticker, [dateKey]: current[dateKey] };
          metrics.forEach(metric => {
            initialGrowth[metric] = 0;
          });
          return initialGrowth;
        }
  
        const previous = group[index - 1];
        let growth = { [tickerKey]: ticker, [dateKey]: current[dateKey] };
  
        metrics.forEach(metric => {
          growth[metric] = formatNumber(calculateGrowth(previous[metric], current[metric]));
        });
  
        return growth;
      });
    });
  
    return growthRates;
  }
import React, { useEffect, useState } from 'react';
import { useAppInfo } from 'AppState';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { Alert, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from 'components/Inputs/TextInput';
import useFieldForm from './useFieldForm';
import { InputAmount, InputColor, InputCompounding, InputDate, InputForecastPeriod, InputGrowthRate, InputInterestRate, InputLoanTerm, InputName, InputPaybackPeriod, InputPeriod, InputPriority, InputReturnExpected, InputTargetAllocation, InputType, InputVolatility } from './FieldFormCells';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ColorPicker from 'components/Inputs/ColorPicker';
import { CheckBox } from '@mui/icons-material';
import CheckboxCutom from 'components/Inputs/CheckboxCustom';

function FieldFormGen({storeName,data=[],formName="Assets",
showType=false,typeOptions=[],date=false,period=false,allocation=false,
growthRate=false,returnExpected=false,interestRate=false,paybackPeriod=false,
compounding=false,loanTerm=false,volatility=false,forecastPeriod=false,showPriority=false
,editDetails=true,allocationDetails=false,defaultField,showCheckbox=false}) {
    
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {currency} = stateMyFinancials
    const {
      fields,
      savedId,
      error,
      handleChange,
      handleDeleteField,
      handleAddField,
      handleRemoveField,
      handleSubmit,
    } = useFieldForm({storeName,data,defaultField})

  return (
    <div style={{width:"100%",height:"100%",display:"flex",flexDirection:"column"}}>

      <form onSubmit={handleSubmit} 
      style={{padding:"0.5rem",display:"flex",
      flexDirection:"column",height:"100%",width:"100%",boxSizing:"border-box"}}>

        <div style={{width:"100%",flex:1}}>
          <table 
          style={{tableLayout:"fixed"}}>
              <thead>
                  <tr>
                  
                    {showType && <th>Type</th>}
                      <th>Name</th>
                      {showPriority && <th>Priority</th>}
                      <th>{`Amount (${stateMyFinancials?.currency?.symbol})`}</th>
                      
                      {date && <th>Date</th>}
                      {period && <th>Period</th>}
                     
                      {forecastPeriod && <th style={{whiteSpace:"break-spaces"}}>Forecast Period (Years)</th>}
                      {growthRate && <th style={{whiteSpace:"break-spaces"}}>Annual<br/>Growth/Inflation Rate</th>}
                      {returnExpected && <th style={{whiteSpace:"break-spaces"}}>Annual Expected Return</th>}
                      {interestRate && <th style={{whiteSpace:"break-spaces"}}>Annual Interest Rate</th>}
                      {volatility && <th style={{whiteSpace:"break-spaces"}}>Annual Expected Volatility</th>}
                      {compounding && <th style={{whiteSpace:"break-spaces"}}>Compounding</th>}
                      {paybackPeriod && <th>Payback</th>}
                      {loanTerm && <th style={{whiteSpace:"break-spaces"}}>Term (Year, Month)</th>}
                      {allocation && <>
                      <th style={{whiteSpace:"break-spaces"}}>Target Allocation (%)</th>
                      
                      </>}
                      {allocationDetails && 
                      <>
                      <th style={{whiteSpace:"break-spaces"}}>Current Allocation (%)</th>
                      <th style={{whiteSpace:"break-spaces"}}>Difference in Allocation (%)</th>
                      </>
                      }
                      {editDetails && <th>Edit</th>}
                  </tr>
              </thead>

              
              <tbody>
                  {fields.map((fieldObj, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                          
                          
                          {showType && 
                          <td>
                          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
                          {showCheckbox && <CheckboxCutom defaultChecked={fieldObj?.selected} onChange={()=>handleChange("selected",!fieldObj?.selected,fieldObj?.id)}/>}
                          <InputColor fieldObj={fieldObj} handleChange={handleChange}/>
                          {(fieldObj?.edit && editDetails) ?
                          <InputType fieldObj={fieldObj} handleChange={handleChange} typeOptions={typeOptions}/>
                          :
                          fieldObj?.type
                          }
                          </div>
                          </td>}

                          <td>
                          {(fieldObj?.edit && editDetails) ? 
                           <InputName fieldObj={fieldObj} handleChange={handleChange} typeOptions={typeOptions}/>
                          :
                          fieldObj?.name
                          }
                          </td>

                          {showPriority && 
                          <td>
                              <InputPriority fieldObj={fieldObj} handleChange={handleChange}/>
                          </td>}
                          
                          <td>
                          {(fieldObj?.edit && editDetails) ? 
                         <InputAmount fieldObj={fieldObj} handleChange={handleChange} 
                         currencySymbol={currency?.symbol}></InputAmount>
                            :
                          fieldObj?.amount
                          }
                          </td>

                          {date &&    
                          <td>
                          {(fieldObj?.edit && editDetails) ?
                          <InputDate fieldObj={fieldObj} handleChange={handleChange}/>
                              :
                              (fieldObj?.date instanceof Date ? fieldObj?.date.toLocaleDateString() : "Invalid date")
                              }
                          </td>
                            }

                            {period && <td>
                              <InputPeriod fieldObj={fieldObj} handleChange={handleChange}/>
                            </td>}
                            
                            {forecastPeriod && <td>
                              <InputForecastPeriod fieldObj={fieldObj} handleChange={handleChange}/>
                              </td>}
                          
                          {growthRate && 
                           <td>
                            <InputGrowthRate fieldObj={fieldObj} handleChange={handleChange}/>
                             
                           </td>}
                           {returnExpected && 
                           <td>
                            <InputReturnExpected fieldObj={fieldObj} handleChange={handleChange}/>
                           </td>}
                           {interestRate && <td>
                            <InputInterestRate fieldObj={fieldObj} handleChange={handleChange}/>
                           </td>}
                           {volatility && <td> 
                            <InputVolatility fieldObj={fieldObj} handleChange={handleChange}/>
                            </td>}
                           {compounding && 
                           <td>
                            <InputCompounding fieldObj={fieldObj} handleChange={handleChange}/>
                            </td>}
                            {paybackPeriod && 
                           <td>
                            <InputPaybackPeriod fieldObj={fieldObj} handleChange={handleChange}/>
                            </td>}
                           {loanTerm && 
                           <td>
                            <InputLoanTerm fieldObj={fieldObj} handleChange={handleChange}/>
                           </td>
                           }
                          {allocation &&
                          <>
                          <td>
                          <InputTargetAllocation fieldObj={fieldObj} handleChange={handleChange}/>
                          
                          </td>
                          </>}

                        {allocationDetails && 
                        <>
                        <td>
                              {fieldObj?.currentAllocation}
                          </td>
                          
                          <td>
                              {fieldObj?.differenceAllocation}
                          </td></>}


                          {editDetails && <td>
                            {(fieldObj?.edit && editDetails) ?
                              <div style={{display:"flex",alignItems:"center"}}>
                                {!savedId.includes(fieldObj?.id) && (
                                    <IconButton sx={{color:"gray"}} type="button" disabled={fields.length === 1} onClick={() => handleRemoveField(fieldObj?.id)}><CloseIcon /></IconButton>
                                )}
                                {savedId.includes(fieldObj?.id) && (
                                    <span><CloudDoneIcon fontSize='small' sx={{ color: "green" }} /></span>
                                )}
                                {savedId.includes(fieldObj?.id) && (
                                    <IconButton sx={{color:"gray"}} onClick={() => handleDeleteField(fieldObj?.id)}>
                                      <DeleteIcon fontSize='small'/></IconButton>
                                )}
                                </div>
                              :
                              <></>
                              }
                            </td>}
                      </tr>
                  ))}
              </tbody>
          </table>
        </div>
        {error && <Alert severity='warning'>{error}</Alert>}
        <div style={{display:"flex",gap:"1rem",position:"sticky",bottom:"0",padding:"0.5rem 0",background:"white",zIndex:"1000"}}>
      {editDetails && <div>
      <Button variant='contained' startIcon={<AddIcon/>} type="button" onClick={handleAddField}>{formName}</Button>
      </div>}
      {(fields.length>0 && (fields.length - savedId.length > 0)) && 
      <div>
      <Button variant='contained' type='submit'>Save</Button></div>}
      </div>

      </form>
      
    </div>
  );
}

export default FieldFormGen;


import React from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';

export default function IndSecConstituentsGrowth() {
    
    const {stateIndustry,stateStock} = useAppInfo()

    const info = stateIndustry.constiInfo
   
    
const metricOptions = findKeysWithGrowth(stateStock.peerInfo);
const defaultMet = [`${dfin.revenue} 5Y-GROWTH`,`${dfin.ebitda} 5Y-GROWTH`,`${dfin.netIncome} 5Y-GROWTH`,`${dfin.assets} 5Y-GROWTH`]
const chartTypeOptions = ["Bar", "Pie", "Bar & Pie"];
const chartTypeDefault = "Bar";
// Replace the following with actual data
  const loadingScreenerISConsti = false;

  return (
      <>
      
      {!loadingScreenerISConsti && info.length > 0 && (
        <PeerOverviewMetricComp
    data={info}
    infoName={"Growth"}
      storeId={"IndSecGrowth"}
      defaultMet={defaultMet}
      metricOptions={metricOptions}
      chartTypeDefault={chartTypeDefault}
      chartTypeOptions={chartTypeOptions}
    />
  
)}
    
    </>

  );
}


function findKeysWithGrowth(arr) {
const resultKeys = [];

arr.forEach(obj => {
  const keys = Object.keys(obj);

  keys.forEach(key => {
    if (key.toUpperCase().includes("GROWTH")) {
      resultKeys.push(key);
    }
  });
});

return resultKeys;
}
import React from 'react'
import { generateUniqueId } from "utils/utilGeneral"
import FieldFormOnboarding from '../FormFields/FieldFormOnboarding'
import { goalTypes } from 'appState/initialStateMyFinancials'

export default function GoalsOnboarding() {
  const defaultField = { id:generateUniqueId(), name: "Retirement", 
  type: "Retirement", period: "Monthly", date:new Date(), amount: 4000, growthRate: 2,forecastPeriod:30,edit:true}
  return (
    <FieldFormOnboarding
    defaultField={defaultField}
    name={"Goals"}
    forecastPeriod={true} date={true} showType={true} 
    typeOptions={goalTypes} growthRate={true} period={true} 
    formName='Goals' storeName={"goalsPeriodic"} data={[]} categoryName='Goals'/>
  )
}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'

export default function usePortfolioFitCrypto() {
    const {stateMyFinancials,stateStock} = useAppInfo()
    const {marketRegion,primaryMarketRegion, investmentPreferenceType,investmentPreference,investmentPreferenceRiskTolerance} = stateMyFinancials 
    
    const [portfolioFitScore,setPortfolioFitScore] = useState(0)
    const [portfolioFitCriteria,setPortfolioFitCriteria] = useState()
    let totalScore = Object.keys(criteriaScores).length
    const completionPercentage = (portfolioFitScore/totalScore)*100
   
      function calculateScore() {
        let score = 3;
        let investmentFilter = investmentPreferenceType?.type === "Manual" ? investmentPreference : investmentPreferenceRiskTolerance;
        const investmentCrypto = investmentFilter.find(obj => obj.type === "Crypto" && obj.selected === true);
        if (investmentCrypto){
            score += 1;
            criteriaScores['Asset Class'] = 1
          }
        return {score,criteriaScores}
        }

    useEffect(()=>{
      if (investmentPreference && investmentPreferenceRiskTolerance && marketRegion){
          const {score,criteriaScores} = calculateScore()
          setPortfolioFitScore(score)
          setPortfolioFitCriteria(criteriaScores)
      }
    },[investmentPreference && investmentPreferenceRiskTolerance && marketRegion,investmentPreferenceType])
    
 return {portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria}
}


const criteriaScores = {
    "Asset Class":0,
    MarketCap: 1,
    Risk:1,
    Liquidity: 1,
    Technicals:0,
  };
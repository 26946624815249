import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ResourceContentList from './ResourceContentList';
import ResourceContentTemplate from './ResourceContentTemplate';
import { useAppInfo } from 'AppState';
import useFindTopic from 'data/resource/useFindTopic';
import { Paper } from '@mui/material';

const RcContent = () => {
  let searchParams = new URLSearchParams(window.location.search);
  let idTopic = searchParams.get('topic');
  let idHeading = searchParams.get("heading")
  const { findTopics, resourceTopics, error, loading } = useFindTopic();
  
  const navigate = useNavigate()
  useEffect(() => {
    if (idTopic) {
      findTopics(decodeURIComponent(idTopic));
    }
  }, [idTopic]);

  const groupedContent = useMemo(() => {
    if (resourceTopics) {
    const result = {};
    if (!idHeading){
    const heading = encodeURIComponent(resourceTopics[0]["Heading"])
    const subTopic = encodeURIComponent(resourceTopics[0]["Sub Topic"])
   
    navigate({pathname:`/Resource/Learn/`,search:`?topic=${idTopic}&subTopic=${subTopic}&heading=${heading}`})
    }
    // Create a new dictionary with Sub Topic as key and an array of Headings as value
    for (const key in resourceTopics.sort((a, b) => a["Sub Topic Order"] - b["Sub Topic Order"] || a["Heading Order"] - b["Heading Order"])) {
      const subTopic = resourceTopics[key]['Sub Topic'];
      const heading = resourceTopics[key].Heading;

      if (!result[subTopic]) {
        result[subTopic] = [heading];
      } else {
        result[subTopic].push(heading);
      }
    }

    return result;}
  }, [resourceTopics]);


  return (
    <div className='containerColumnFlex'>
     
      <div className='containerLeftMenuAndContent'>
        
        {groupedContent &&  <ResourceContentList content={groupedContent} topic={idTopic} />}

        {(resourceTopics && !loading) && 
        <Paper elevation={0} style={{flex:1,minWidth:"0",height:"100%",width:"100%"
        ,padding:"0.25rem",boxSizing:"border-box",overflow:"auto"}}>
        <ResourceContentTemplate content={resourceTopics} /></Paper>}
      </div>
    </div>
  );
};

export default RcContent;



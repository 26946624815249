import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function SelectBox({
  id,
  options = [],
  value,
  onChange,
  label,
  size = "small",
  width = "100%",
  sx = {},
  fontSize = "1rem",
  variant = "standard",
  required = false,
  disabled=false
}) {
  
  // Ensure options is always an array
  const validatedOptions = Array.isArray(options) ? options : [];

  const getOptionLabel = (option) => {
    if (typeof option === 'number') {
      return option.toString();
    }
    return option;
  };

  const isOptionEqualToValue = (option, value) => {
    return option === value || option.toString() === value;
  };

  const stringValue = value === undefined ? '' : (typeof value === 'number' ? value.toString() : value);

  // Check if value is in the options
  const isValueInOptions = validatedOptions.some(option => isOptionEqualToValue(option, value));

  return (
    <Autocomplete
      disablePortal={false}
      disabled={disabled}
      value={isValueInOptions ? stringValue : null}
      onChange={(e, v) => { onChange(e, v); }}
      id={id}
      options={validatedOptions}
      sx={{
        width: width,
        margin: 0,
        boxSizing: "border-box",
        padding: "0.35rem 0 0 0",
        '& .MuiAutocomplete-inputRoot': {
          fontSize: fontSize
        }
      }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      slotProps={{
        popper: {
          sx: { zIndex: 100000000 }
        }
      }}
      renderInput={(params) => (
        <TextField
          required={required}
          variant={variant}
          {...params}
          label={label && label}
          sx={{ ...sx, boxSizing: "border-box" }}
          size={size}
        />
      )}
    />
  );
}


import React, { useEffect, useState } from 'react'
import SelectBox from 'components/Inputs/SelectBox'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import DatePicker from 'components/Inputs/DatePicker';
import TextInput from 'components/Inputs/TextInput';
import SearchTickerDropdown from 'search/SearchTickerDropdown';


const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Get yesterday's date
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export default function AddTransaction({handleTransactionDetails,id}) {
  const {statePortfolio} = useAppInfo()
  const [investmentType,setInvestmentType] = useState(assetCode?.Stocks)
  const [transactionType,setTransactionType] = useState("Buy")
  const [quantity,setQuantity] = useState(100)
  const [price,setPrice] = useState()
  const [tickerSelected,setTickerSelected]  = useState([])
  const {close} = useMultiTickerInfo(tickerSelected)
  const [date, setDate] = useState(formatDate(yesterday));
  const [tDetails,setTdetails] = useState([])

useEffect(() => {
    const newTransaction = {
        id:id,
        investmentType,
        transactionType,
        date,
        quantity,
        price,
        ...tDetails
    };
    handleTransactionDetails(newTransaction)
}, [investmentType, tickerSelected, transactionType, date, quantity, price]);



  function handleTicker(obj){
    setTdetails(obj)
    setTickerSelected([obj?.[dfin.ticker]])
      // setNameSelected([name])
  }
  useEffect(() => {
    if (close.length > 0) {
      const findDate = close.filter((obj) => obj.Date === date);
  
      if (findDate.length > 0) {
        const priceOnDate = findDate[0][tickerSelected[0]];
        setPrice(priceOnDate.toFixed(2));
      } else {
        // If findDate is empty, find the closest date and get the corresponding price
        const closestDate = close.reduce((prev, curr) => {
          const prevDiff = Math.abs(new Date(prev.Date) - new Date(date));
          const currDiff = Math.abs(new Date(curr.Date) - new Date(date));
          return prevDiff < currDiff ? prev : curr;
        });
  
        setPrice(closestDate[tickerSelected[0]].toFixed(2));
      }
    }
  }, [close, statePortfolio.tickerSelected, date]);


  function handleDate(v) {
    setDate(v);
  }

  function handleQuantityChange(v) {
    setQuantity(v);
  }


  function handlePriceChange(v) {
    setPrice(v);
  }


  const investmentTypeOptions = Object.values(assetCode)
  return (
    <div style={{width:"100%",display:"flex",flexDirection:"column",padding:"0.5rem",gap:"0rem",boxSizing:"border-box"}}>
    <div style={{display:"flex",gap:"1rem",width:"100%",boxSizing:"border-box",alignItems:"center",justifyContent:"center"}}>
      <SelectBox width="15%" size='small' options={investmentTypeOptions} 
      value={investmentType} onChange={(e,v)=>setInvestmentType(v)} label={"Investment Type"}/>
      <div style={{flex:1,minWidth:"200px"}}>
        <SearchTickerDropdown searchBar='standard' handleTickerType='unique' tickerType={investmentType} handleTicker={handleTicker}/>
        <h5></h5>
        </div>
      
      <SelectBox width='15%' size='small' options={["Buy","Sell"]} value={transactionType} onChange={(e,v)=>setTransactionType(v)} label={"Transaction Type"}/>
      <DatePicker sx={{width:"15%"}} date={date} handleDate={handleDate} weekends={false}/>
      <TextInput sx={{width:"15%"}}  label='Quantity' value={quantity} type='number' onChange={handleQuantityChange}/>
      <TextInput sx={{width:"15%"}}  label='Price' value={price} type='number' onChange={handlePriceChange}/>
  </div>
  <div>
  
  </div>
  </div>
  )
}

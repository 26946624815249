import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import React, { useEffect, useState } from 'react'
import { formatNumber, getSpecificArrayObjKeys } from 'utils/utilGeneral'

export default function useCalculateCompanyVsIndustryStatistics({metric,type="Industry",coInfo}) {
    const {stateScreener} = useAppInfo()
    const [comparisonWith,setComparisonWith] = useState(dfin.gicIndustry)   
    const [performance,setPerformance] = useState([])
    const [summary,setSummary] = useState({})

    useEffect(()=>{
        if (type !== "Industry"){
        setComparisonWith(dfin.gicSector)}
        else{
          setComparisonWith(dfin.gicIndustry)
        }
      },[type])

useEffect(() => {
    if (stateScreener.stocks.length > 0) {
      const stateScreenFilter = stateScreener.stocks.filter(
        (obj) =>
          obj[dfin.country] === coInfo?.[dfin.country] &&
          obj[comparisonWith] === coInfo?.[comparisonWith]
      );
      const performance = getSpecificArrayObjKeys(stateScreenFilter, [metric]);
  
      const values = performance.map((item) => item[metric]);
      const max = Math.max(...values);
      const min = Math.min(...values);
      const sum = values.reduce((acc, value) => acc + value, 0);
      const mean = sum / values.length;
      const companyFigure = coInfo?.[metric];
  
      // Calculate percentile
      const sortedValues = [...values].sort((a, b) => a - b);
      let rank;
      if (companyFigure !== undefined) {
        const ranks = sortedValues.reduce((acc, val, index) => {
          if (val === companyFigure) {
            acc.push(index + 1);
          }
          return acc;
        }, []);
        rank = ranks.length > 0 ? ranks.reduce((a, b) => a + b) / ranks.length : 0;
      } else {
        rank = 0;
      }
  
      const percentile = formatNumber((rank / sortedValues.length) * 100);
      
      const summary = {
        Company: companyFigure,
        Min: min,
        Max: max,
        Mean: mean,
        Percentile: percentile,
      };
  
      setSummary(summary);
      setPerformance(performance);
    }
  }, [stateScreener.stocks, metric, coInfo, comparisonWith])
  

  return {performance,summary}

}


export function useCalculateCompanyVsIndustryStatisticsMultipleMetrics({ metric, type = "Industry", coInfo }) {
  const { stateScreener } = useAppInfo();
  const [comparisonWith, setComparisonWith] = useState(dfin.gicIndustry);
  const [performance, setPerformance] = useState([]);
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    if (type !== "Industry") {
      setComparisonWith(dfin.gicSector);
    } else {
      setComparisonWith(dfin.gicIndustry);
    }
  }, [type]);

  useEffect(() => {
    if (stateScreener.stocks.length > 0) {
      const stateScreenFilter = stateScreener.stocks.filter(
        (obj) =>
          obj[dfin.country] === coInfo?.[dfin.country] &&
          obj[comparisonWith] === coInfo?.[comparisonWith]
      );

      const metrics = Array.isArray(metric) ? metric : [metric];
      const performanceArray = [];
      const summaryArray = [];

      metrics.forEach((metric) => {
        const performance = getSpecificArrayObjKeys(stateScreenFilter, [metric]);
        const values = performance.map((item) => item[metric]);
        const max = Math.max(...values);
        const min = Math.min(...values);
        const sum = values.reduce((acc, value) => acc + value, 0);
        const mean = sum / values.length;
        const companyFigure = coInfo?.[metric];

        // Calculate percentile
        const sortedValues = [...values].sort((a, b) => a - b);
        let rank;
        if (companyFigure !== undefined) {
          const ranks = sortedValues.reduce((acc, val, index) => {
            if (val === companyFigure) {
              acc.push(index + 1);
            }
            return acc;
          }, []);
          rank = ranks.length > 0 ? ranks.reduce((a, b) => a + b) / ranks.length : 0;
        } else {
          rank = 0;
        }

        const percentile = Number(formatNumber((rank / sortedValues.length) * 100));

        const summary = {
          Metric: metric,
          Company: companyFigure,
          Min: min,
          Max: max,
          Mean: mean,
          Percentile: percentile,
          Weight:1,
        };

        performanceArray.push(performance);
        summaryArray.push(summary);
      });

      setPerformance(performanceArray);
      setSummary(summaryArray);
    }
  }, [stateScreener.stocks, metric, coInfo, comparisonWith]);

  return { performance, summary };
}

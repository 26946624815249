import {  signOut } from "firebase/auth";
import { auth } from '../firebaseConfig/firebaseConfig';

function handleSignOut(){
      
    const logout = async () => {
      try {
        
        await signOut(auth).then(() => {
          // Sign-out successful.
          // navigate("/");
          window.location.reload()
          // setTimeout(()=>window.location.reload(),1000*5);
          
        
          }).catch((error) => {
          // An error happened.
          });
      } catch (error) {
        // setError('Failed to sign out. ' + error.message);
        console.error(error);
      }
    };
    logout()
  }


export {handleSignOut}
import React, {
  useEffect,
  useState,
} from 'react';

import ChartYieldCurve from 'components/Charts/ChartYieldCurve';

import { bonds } from 'content/contentMarket';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';

export default function BondYieldCurve() {
  const {close,trendTable,marketInfoLoading} = useMultiTickerInfo(Object.keys(bonds))

  const bondNewKey = {"^IRX": "3m", "^FVX": "5y", "^TNX": "10y", "^TYX": "30y"};

  const keyOrder = Object.keys(bondNewKey);
  const [yieldData,setYieldData] = useState([])
  const [period,setPeriod] = useState(1)
  const [date,setDate] = useState(1)
  useEffect(()=>{
    if (close.length>0){
    
    const yieldData = keyOrder.reduce((acc, key) => {
      if (close[close.length - period].hasOwnProperty(key)) {
        acc[bondNewKey[key]] = close[close.length - period][key];
      }
      return acc;
    }, {});
    setYieldData(yieldData) 
    setDate(close[close.length - period]["Date"])
  }
  },[close,period])
 
  return (
    <div style={{width:"40%",height:"95%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
 <ChartYieldCurve
 chartType='line' 
  title='Yield Curve' 
  data={[yieldData]} 
  seriesName={date}/>
  <input type='range' value={period} min={1} max={close.length-1} onChange={(e)=>setPeriod(e.target.value)}/>
  
  </div>
  )
}

import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { dfin } from 'content/constants';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


export default function useCompanyFinancials() {
const {dispatchStock,stateTicker} = useAppInfo()
const {peerSelected} = stateTicker

const [error, setError] = useState(null);

const previousPeerSelectedRef = useRef([]);
// peerSelected.length === previousPeerSelectedRef.current.length &&
useEffect(() => {
  const isPeerSelectedEqual = peerSelected.every(element => previousPeerSelectedRef.current.includes(element));

  if (!isPeerSelectedEqual) {
    getAnnualInfo();
    getQuarterInfo();
    previousPeerSelectedRef.current = peerSelected;
  }
}, [peerSelected]);

async function getAnnualInfo() {
  
  try {
    dispatchStock({"loadingFinancials":true})
    const response = await axios.get(`${BASE_URL}/annualInfo/`, {
      params: {
        ticker: JSON.stringify(peerSelected),
      },
    });

    const companyData = response.data;
    dispatchStock({"annualInfo":companyData.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])})
  } catch (error) {
    console.error(error);
  } finally {
    dispatchStock({"loadingFinancials":false})
  }
}

async function getQuarterInfo() {
  dispatchStock({"loadingFinancials":true})
  try {
    const response = await axios.get(`${BASE_URL}/quarterInfo/`, {
      params: {
        ticker: JSON.stringify(peerSelected),
      },
    });
    const companyData = response.data;
    dispatchStock({"quarterInfo":companyData.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap])})
  } catch (error) {
    console.error(error);
  } finally {
    dispatchStock({"loadingFinancials":false})
  }
}

    
}



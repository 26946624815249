import React, { useState } from 'react'
import ProfileLogo from 'components/Logo/ProfileLogo'
import MyFinancialsHeaderTemplate from './Template/MyFinancialsHeaderTemplate';

export default function MyFinancialsOverviewHeader() {

  return (
    <MyFinancialsHeaderTemplate icon={<ProfileLogo/>} name={"Financials Overview"}></MyFinancialsHeaderTemplate>
  )
}

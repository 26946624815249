import ChartCard from 'components/ChartCard';
import useEconInfo from 'data/economy/useEconInfo';
import useEconMultiSeries from 'data/economy/useEconMultiSeries';
import React, { useEffect, useMemo, useState } from 'react';
import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import { useAppInfo } from 'AppState';
import { generateRandomColor } from 'utils/utilGeneral';

export default function EconSeriesChartCard({ seriesName }) {
    const { dispatchSidebar, stateSidebar } = useAppInfo();

    function handleInfoTab(e, value) {
        e.stopPropagation();
        dispatchSidebar({ sidebarOpen: true, sidebarRightContent: 'information', infoContent: value });
    }

    const { econInfo } = useEconInfo();
    const econCategories = econInfo.filter((obj) => seriesName.includes(obj.id));
    const { econMultiSeries, loadingEconSeries } = useEconMultiSeries(seriesName);

    const [sortedEconSeries, setSortedEconSeries] = useState([]);

    useEffect(() => {
        if (econMultiSeries && econMultiSeries.length > 0) {
            const sortedSeries = [...econMultiSeries].sort((a, b) => new Date(a.Date) - new Date(b.Date));
            setSortedEconSeries(sortedSeries);
        }
    }, [econMultiSeries]);

    return (
        <ChartCard loading={loadingEconSeries} title={econCategories[0]?.['Name']}
        currentValue={econCategories[0]?.['Current Value']} 
        unit={econCategories[0]?.['units_short']}>
            {sortedEconSeries.length> 0  && 
            <TimeSeriesChart
                data={sortedEconSeries}
                seriesName={"Value"}
                seriesLabel={econCategories[0]?.['Name']}
                isDarkTheme
                recession={false}
                datePicker={false}
                navigator={false}
            />}
        </ChartCard>
    );
}

import React, { useState } from 'react';

// Generic Checklist Component
export default function Checklist ({ data, onChange }) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionToggle = (option) => {
      const selectedIndex = selectedOptions.indexOf(option);
      let newSelectedOptions = [...selectedOptions];
  
      if (selectedIndex === -1) {
        newSelectedOptions.push(option);
      } else {
        newSelectedOptions.splice(selectedIndex, 1);
      }
  
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions); // Notify parent component about changes
    };
  
    return (
      <div>
        {data.map((obj,index)=>
        <React.Fragment key={index}>
        <h3>{obj.question}</h3>
        {obj.options.map((option, index) => (
          <div key={index}>
            <input
              type="checkbox"
              id={option.label}
              checked={selectedOptions.includes(option)}
              onChange={() => handleOptionToggle(option)}
            />
            <label htmlFor={option.label}>{option.label}</label>
          </div>
        ))}
        </React.Fragment>
        )}
      </div>
    );
  };


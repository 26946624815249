const RecessionData = [
    { from: '1929-08-01', to: '1933-03-01', label: 'Great Depression', hoverText: 'August 1929 – March 1933\n3 years 7 months', },
    { from: '1937-05-01', to: '1938-06-01', label: 'Recession of 1937–1938', hoverText: 'May 1937 – June 1938\n1 year 1 month', },
    { from: '1945-02-01', to: '1945-10-01', label: 'Recession of 1945', hoverText: 'February 1945 – October 1945\n8 months', },
    { from: '1948-11-01', to: '1949-10-01', label: 'Recession of 1949', hoverText: 'November 1948 – October 1949\n11 months', },
    { from: '1953-07-01', to: '1954-05-01', label: 'Recession of 1953', hoverText: 'July 1953 – May 1954\n10 months', },
    { from: '1957-08-01', to: '1958-04-01', label: 'Recession of 1958', hoverText: 'August 1957 – April 1958\n8 months', },
    { from: '1960-04-01', to: '1961-02-01', label: 'Recession of 1960–1961', hoverText: 'April 1960 – February 1961\n10 months', },
    { from: '1969-12-01', to: '1970-11-01', label: 'Recession of 1969–1970', hoverText: 'December 1969 – November 1970\n11 months', },
    { from: '1973-11-01', to: '1975-03-01', label: '1973–1975 recession', hoverText: 'November 1973 – March 1975\n1 year 4 months', },
    { from: '1980-01-01', to: '1980-07-01', label: '1980 recession', hoverText: 'January 1980 – July 1980\n6 months', },
    { from: '1981-07-01', to: '1982-11-01', label: '1981–1982 recession', hoverText: 'July 1981 – November 1982\n1 year 4 months', },
    { from: '1990-07-01', to: '1991-03-01', label: 'Early 1990s recession', hoverText: 'July 1990 – March 1991\n8 months', },
    { from: '2001-03-01', to: '2001-11-01', label: 'Early 2000s recession', hoverText: 'March 2001 – November 2001\n8 months', },
    { from: '2007-12-01', to: '2009-06-01', label: 'Great Recession', hoverText: 'December 2007 – June 2009\n1 year 6 months', },
    { from: '2020-02-01', to: '2020-04-01', label: 'COVID-19 recession', hoverText: 'February 2020 – April 2020\n2 months', }
  ];

export {RecessionData}
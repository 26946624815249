import React from 'react';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';

export default function IndSecConstituentsSize() {
  const {stateIndustry,stateStock} = useAppInfo()
  const info = stateIndustry.constiInfo
   
    
  const metricOptions = filterElementsWithoutGrowthAndAverage(stateStock.metrics.numCols)

  const defaultMet = [
    dfin.marketCap,
    dfin.revenue,
    dfin.ebitda,
    dfin.assets,
    dfin.liability,
  ];
  const chartTypeOptions = ["Bar", "Pie", "Bar & Pie"];
  const chartTypeDefault = "Bar & Pie";
  

  // Replace the following with actual data
  const loadingScreenerISConsti = false;

  return (
      <>
      
      {!loadingScreenerISConsti && info.length > 0 && (
        <PeerOverviewMetricComp
    data={info}
    infoName={"Size & Share"}
      storeId={"IndSecSize"}
      defaultMet={defaultMet}
      metricOptions={metricOptions}
      chartTypeDefault={chartTypeDefault}
      chartTypeOptions={chartTypeOptions}
    />
  
)}
    
    </>

  );
}

function filterElementsWithoutGrowthAndAverage(arr) {
    const filteredArray = arr.filter(element => {
      const upperCaseElement = element.toUpperCase();
      return !(upperCaseElement.includes("GROWTH") || upperCaseElement.includes("AVERAGE"));
    });
  
    return filteredArray;
  }

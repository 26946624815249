import React, {useState } from 'react'
import TabsClose from 'components/Tabs/TabsClose';
import IndicatorList from './IndicatorList';
import MetricSelectionModal from 'components/Template/MetricSelectionModal';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function IndicatorUI({tickerList,tickerDict}) {
  const [indicatorList,setIndicatorList] = useState(["EMA","SMA","RSI","ATR","OBV"])
  const [indicatorSel,setIndicatorSel] = useState("EMA")

  function handleDeleteIndicator(index) {
    const updatedItems = indicatorList.filter((_, i) => i !== index);
    setIndicatorList(updatedItems)
    setIndicatorSel(updatedItems[updatedItems.length-1])
    // Update the tabList and tabContent state with the updated values
  }
  const [indicatorMenu,setIndicatorMenu] = useState(false)

  function handleIndicator(value) {  
    let indicator;    
    if (Array.isArray(value)) {
      // If value is an array, set it as the new state directly
      indicator = value
     
    } else {
      if (!indicatorList.includes(value)) {
       indicator = [...indicatorList,value]
       
      } else {
        indicator = indicatorList.filter((item) => item !== value)
      }
      
    }
    setIndicatorSel(indicator[0])
    setIndicatorList(indicator)
    
  }
 
  return (
    <div className='containerColumnFlex'>

    <MetricSettingsBar
    title="Technical Indicators"
    addMetric
    handleAddMetric={()=>setIndicatorMenu(true)}
    />
  
    <div>
    <TabsClose tabList={indicatorList} tabContent={[]} 
    deleteTab={true}
    subTabIndex={indicatorList.indexOf(indicatorSel)}
    handleSubTabChange={(v)=>setIndicatorSel(indicatorList[v])} 
    handleDeleteTab={handleDeleteIndicator}> </TabsClose>
    </div>
    {<IndicatorList tickerList={tickerList} tickerDict={tickerDict} indicator={indicatorSel}/>}

    {indicatorMenu && 
        <MetricSelectionModal 
        title={"Indicator Selection"}
        metricInfo={metricInfo} 
        metricSelected={indicatorList}
        handleMetricSelected={handleIndicator}
        onClose={()=>setIndicatorMenu(false)}
        />}
    </div>
  )
}


const metricInfo =  [
  { name: "EMA", category: "Trend" },
  { name: "SMA", category: "Trend" },
  { name: "RSI", category: "Momentum" },
  { name: "OBV", category: "Volume" },
  { name: "ATR", category: "Volatility" },
];
import React, { useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';

const ChartSynchronous = ({ datasets }) => {
  const charts = useMemo(() => {
    if (!datasets || datasets.length === 0) {
      return null;
    }

    return datasets.map((dataset, index) => {
      const options = {
        chart: {
          animation: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
        },
        yAxis: [
          {
            title: {
              text: 'Close',
            },
            labels: {
                formatter: dataset?.name !=="Close" && function () {
                  return numeral(this.value).format('0.0a').toUpperCase();
                },
              },
          },
        ],
        plotOptions: {
          series: {
            cursor: 'pointer',
            // point: {
            //   events: {
            //     mouseOver: function (e) {
            //       // Implement mouse over actions if needed
            //     },
            //   },
            // },
          },
        },
        credits:{enabled:false},
        accessibility: {
            enabled: false, // Include the accessibility module
          },
          exporting:{
            enabled:false,
          },
        series: [
          {
            name: dataset.name,
            data: dataset.data.map((dataPoint) => [dataPoint.date, dataPoint.value]).sort((a, b) => a[0] - b[0]),
            yAxis: 0,
            type: dataset.chartType,
          },
        ],
      };

      return (
        <div style={{width:"100%",height:"50%"}}>
        <HighchartsReact
        containerProps={{ style: { height: '100%',width:"100%" } }}
          highcharts={Highcharts}
          options={options}
          key={dataset.name}
        /></div>
      );
    });
  }, [datasets]);

  const handleMouseMove = (e) => {
    const firstChart = Highcharts.charts.find(chart => chart);
    if (!firstChart) return;
  
    const normalizedEvent = firstChart.pointer.normalize(e);
  
    Highcharts.charts.forEach((chart) => {
      if (!chart) return;
      const point = chart.series[0].searchPoint(normalizedEvent, true);
      if (point) {
        point.highlight(e);
      }
    });
  };

  return (
    <div style={{display:"flex",flexWrap:"wrap",width:"100%",height:"100%",overflow:"auto"}} 
    // onMouseMove={handleMouseMove}
    
    >
      {charts}
    </div>
  );
};

export default ChartSynchronous;
const morningStarRating = [ "MORNING STAR RATIO",
"MORNING STAR CATEGORY BENCHMARK",
"MORNING STAR SUSTAINABILITY RATIO"]


const headerKeys = ["CATEGORY","YIELD",
"MAX ANNUAL MGMT CHARGE",
"ONGOING CHARGE",
"NET EXPENSE RATIO",
"ANNUAL HOLDINGS TURNOVER",
"AVERAGE MKT CAP MIL","TOTAL ASSETS",]

const summaryStats = [
    "CATEGORY","YIELD",
"MAX ANNUAL MGMT CHARGE",
"ONGOING CHARGE",
"NET EXPENSE RATIO",
"ANNUAL HOLDINGS TURNOVER",
"AVERAGE MKT CAP MIL","TOTAL ASSETS",
"DIVIDEND PAYING FREQUENCY",
"HOLDINGS COUNT",
"INCEPTION DATE",
"DATE ONGOING CHARGE",
]

const technicals = [ 
"MORNING STAR RATIO",
"MORNING STAR CATEGORY BENCHMARK",
"MORNING STAR SUSTAINABILITY RATIO",
"BETA",
"1Y VOLATILITY",
"3Y VOLATILITY",
"3Y EXP RETURN",
"3Y SHARP RATIO",
"RETURNS YTD",
"RETURNS 1Y",
"RETURNS 3Y",
"RETURNS 5Y",
"RETURNS 10Y",
"52WEEK HIGH",
"52WEEK LOW",
"50DAY MA",
"200DAY MA",]

export {technicals,morningStarRating,headerKeys,summaryStats}
import React, { useState } from 'react'
import RatingBar from "components/RatingBar";
import styles from "./Score.module.css"
import { numberDecimal } from 'utils/utilGeneral';
import PopoverInfo from 'components/Modal/PopoverInfo';
import PopoverButton from 'components/Modal/PopoverButton';
import { formatNumber } from 'utils/utilGeneral';

export default function ScoreTemplate({scoreTitle,information,scoreOverall,scoreDetails,moreInfoContent,scoreMax=10}) {

  return (
        <>
        <div 
        style={{
        position:"relative",
        overflow:"visible",
        flex:1,
        boxSizing:"border-box",
        height:"100%"}}>
          <div className={styles.scoreWrapper}>
            <div style={{display:"flex",gap:"1rem"}}>
            <h4 style={{fontWeight:"bold",flex:1}}>
            {scoreTitle} Score: {formatNumber(scoreOverall/scoreMax * 100)}%</h4>
            
            <PopoverInfo title={`${scoreTitle} Score Information`}>
                {information}
              </PopoverInfo>
            </div>
            <RatingBar score={scoreOverall} scoreMax={scoreMax}/>
            
            <div  className={styles.subScore} >
            {scoreDetails.map(obj=>
            <h5 key={obj.Title}>{obj.Title}:{numberDecimal(obj.Value)}</h5>
            )}
            
            <PopoverButton 
            title={`${scoreTitle} Score Details`}
            sxBtn={{display:"flex",marginLeft:"auto"
            ,alignItems:"center",background:"#F8F8F8",padding:"0 0.2rem"
            ,minWidth:"0",textTransform: 'none'}}>
           <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
            <div className={styles.scoreWrapper} style={{maxWidth:"40vw"}}>
            <div style={{display:"flex",gap:"1rem"}}>
            <h4 style={{fontWeight:"bold",flex:1}}>
            {scoreTitle} Score: {formatNumber(scoreOverall/scoreMax * 100)}%</h4>
            <PopoverInfo title={`${scoreTitle} Score Information`}>
                {information}
              </PopoverInfo>
            </div>
            <RatingBar score={scoreOverall} scoreMax={scoreMax}/>
            <div  className={styles.subScore} >
            {scoreDetails.map(obj=>
            <h5 key={obj.Title}>{obj.Title}:{numberDecimal(obj.Value)}</h5>
            )}
            </div>
            </div>
            <div style={{flex:1}}>
            {moreInfoContent}
            </div>
            </div>
            </PopoverButton>
            
            </div>
            
          </div>
        </div>
                 
        

        </>
  )
}

import React from 'react';

import TableGen from 'components/Table/TableGen';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { colsSummary } from './cryptoKeys';

export default function CryptoDescription() {
    const {stateTicker,stateScreener} = useAppInfo()
    const { tickerCrypto, tickerCryptoCode } = stateTicker;
    const crypto = stateScreener.crypto.filter(
        (obj) => obj[dfin.ticker] === tickerCrypto
      );
    
    const tableDataSummary =
    crypto &&
    getSpecificArrayObjKeys(crypto, colsSummary).flatMap((obj) => {
      return Object.entries(obj).map(([key, value]) => {
        const table = {};
        table["Metric"] = key;
        table["Value"] = value;
        return table;
      });
    });
  return (
    <>
    <div style={{ width: "50%",padding:"0.25rem"}}>{crypto[0]?.["DESCRIPTION"]}</div>
    <div>
    {tableDataSummary && <TableGen data={tableDataSummary} />}</div>
  </>
  )
}

import React, { useState,useRef,useEffect } from 'react';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import LinearProgress from '@mui/material/LinearProgress';
import styles from "./SearchMain.module.css"
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


export default function SearchCompanyIndSec({searchTerm}) {
  const navigate = useNavigate()
  const navUrl = "/Analytics/Industry"

  const {dispatchSearch, stateTicker,dispatchIndustry,
  stateIndustry,stateSearch} = useAppInfo();
  const tickerInfo = stateTicker.tickerInfo
  const sectorList = Array.from(new Set(stateIndustry.secIndList.map(obj=>obj[dfin.sector])))
  const industryList = Array.from(new Set(stateIndustry.secIndList.map(obj=>obj[dfin.industry])))
  
  const [filteredList,setFilteredList] = useState([])
  const [filteredListSector,setFilteredListSector] = useState(sectorList)
  const [filteredListIndustry,setFilteredListIndustry] = useState(industryList)
  // const [searchTerm, setSearchTerm] = useState("");
  
  const tableColumns = ["Ticker", "Name",,"Sector", "Industry"];
  const tableRows = ["TICKER", "NAME",dfin.gicSector, dfin.gicIndustry];

 
  useEffect(()=>{
    const filteredResults = tickerInfo.filter(item => (
      item[dfin.country] ==="USA"
    ));
  
    const sortedResults = filteredResults.sort((a, b) => (
      b[dfin.marketCap] - a[dfin.marketCap]
    ));
    const topResults = sortedResults.slice(0, 50);
  
    setFilteredList(topResults)
  },[stateSearch.searchClick])

  const [loading, setLoading] = useState(false);

   useEffect(()=>{
    // const searchTerm = event.target.value.toLowerCase();
    // setSearchTerm(searchTerm);
    
    const filteredResults = tickerInfo.filter(item => (
      (item[dfin.ticker] && item[dfin.ticker].toLowerCase().includes(searchTerm))||
      (item[dfin.coName] && item[dfin.coName].toLowerCase().includes(searchTerm))||
      (item[dfin.gicIndustry] && item[dfin.gicIndustry].toLowerCase().includes(searchTerm))||
      (item[dfin.gicSector] && item[dfin.gicSector].toLowerCase().includes(searchTerm))
    ));
    const sectorFilter = sectorList.filter(item => (
      (item.toLowerCase().includes(searchTerm))
    ));

    const industryFilter = industryList.filter(item => (
      (item.toLowerCase().includes(searchTerm))
    ));
      
      

    const sortedResults = filteredResults.sort((a, b) => (
      b[dfin.marketCap] - a[dfin.marketCap]
    ));
  
    const topResults = sortedResults.slice(0, 20);
  
    setFilteredList(topResults)
    setFilteredListSector(sectorFilter)
    setFilteredListIndustry(industryFilter)
  },[searchTerm])


  function handleSelection(e, obj) {
    navigate(navUrl);
    dispatchIndustry({
      analysisOptionSel: "Constituents",
      sectorSel: [obj?.[dfin.gicSector]],
      industrySel: [obj?.[dfin.gicIndustry]],
      countrySel: [obj?.[dfin.country]],
      searchIndSec: false,
      menuSel: stateIndustry.menuSel === "Overview" ? "Performance" : stateIndustry.menuSel
    });
    dispatchSearch({"searchClick":false });
  }
  
  function handleIndustry(v) {
    navigate(navUrl);
    const sector = stateIndustry.secIndList.filter(obj => obj[dfin.gicIndustry] === v)[0]?.[dfin.gicSector];
    dispatchIndustry({
      industrySel: [v],
      analysisOptionSel: "Constituents",
      sectorSel: [sector],
      indSecMode: true,
      searchIndSec: false,
      menuSel: stateIndustry.menuSel === "Overview" ? "Performance" : stateIndustry.menuSel
    });
    dispatchSearch({"searchClick":false });
  }
  
  function handleSector(v) {
    navigate(navUrl);
    dispatchIndustry({
      analysisOptionSel: "Constituents",
      sectorSel: [v],
      indSecMode: false,
      searchIndSec: false,
      menuSel: stateIndustry.menuSel === "Overview" ? "Performance" : stateIndustry.menuSel
    });
    dispatchSearch({"searchClick":false });
  }
  
   
   
  return (
    <>
    <>
      <div style={{maxWidth: "100%",width:"100%",height:"100%",display:"flex",flexDirection:"column",gap:"1rem",overflow:"auto"}}>
        
        {filteredListSector.length>0 &&
        <div>
        <h3 style={{background:"#f7f7f7"
          ,padding:"0.5rem",fontSize:"1rem",fontWeight:"bold"}}>Sector</h3>
        {filteredListSector.sort().map(sector=>
        <Button onClick={()=>handleSector(sector)} key={sector}  style={{fontSize:"1rem",
        paddingLeft:"0.5rem",
        cursor:"pointer"}}>{sector}</Button>)}
        </div>}


        {filteredListIndustry.length>0 &&
        <div>
        <h3 style={{background:"#f7f7f7"
          ,padding:"0.5rem",fontSize:"1rem",fontWeight:"bold"}}>Industry</h3>
        <>
        {filteredListIndustry.sort().map(industry=>
        <Button onClick={()=>handleIndustry(industry)} key={industry} 
        style={{fontSize:"1rem",
        paddingLeft:"0.5rem",
        cursor:"pointer"}}>{industry}</Button>)}
        </>
        </div>}
        
       
        <div style={{width:"100%",}}>
        <h3 style={{background:"#f7f7f7",padding:"0.5rem",fontSize:"1rem",fontWeight:"bold"}}>Company's Industry/Sector you want to see</h3>
        <table className={styles.table}>
          <thead>
            <tr>
              {tableColumns.map((colHeader) => (
                <th style={{background:"white",color:"black"}} key={colHeader}>{colHeader}</th>
              ))}
            </tr>
          </thead>
            <tbody>
              {loading ? (
               <LinearProgress/>
              ) : (
                filteredList.map((obj, index) => (
                  <tr style={{cursor:"pointer"}} onClick={(e) => handleSelection(e, obj)} key={index} >
                   
                    {tableRows.map((row, rowIndex) => (
                      <td key={rowIndex}>
                        {row !== dfin.ticker ? (
                          obj[row]
                        ) : (
                         
                          <div className={styles.logoTick} >
                            <img src={obj[dfin.coLogo]} alt="Logo" height={20} width={20} />
                            {obj[row]}
                          </div>
                        )}
                      </td>
                    ))}
                    
                  </tr>
                ))
              )}
            </tbody>
        </table>
        </div>
      </div>
    </>
    </>
  );
}






import React from 'react'
import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecSummaryFinancials() {
  const {stateIndustry} = useAppInfo()

  return (
        <IndSecMetricSummary
        infoName={"Financials Summary"}
        defaultMet={[dfin.revenue,
          dfin.ebitda,
          dfin.fcf,
          dfin.netProfitMargin,
          dfin.assets,
          dfin.grossProfitMargin]}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
  )
}

import { useEffect} from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindDefaultReport = () => {

  const {dispatchReportBuilder} = useAppInfo()

  const findReport = async () => {
    try {
        const response =  await axios.get(`${BASE_URL}/findDefaultReports/`)
        // Process the response data as needed
       const reportData = response.data;
      dispatchReportBuilder({'defaultReports':reportData });
    } catch (err) {
      // Handle the error
      dispatchReportBuilder({'defaultReportsLoaded': true });
    }
  };

  useEffect(() => {
    findReport();
  }, []);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindDefaultReport;
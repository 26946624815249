import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { useAppInfo } from 'AppState';
import { generateUniqueId } from "utils/utilGeneral";

HighchartsTreemap(Highcharts);
HighchartsHeatmap(Highcharts);
export default function NetWorthHeatMap({title}) {
    const { stateMyFinancials } = useAppInfo();
    const { assets, liabilities} = stateMyFinancials;
    
    const processData = (items, parent) => items.map(item => ({
        id: item.id,
        name: `${item.name} (${item.amount})`,
        label:`${item.name}`,
        value: item.amount,
        parent
    }));

    const createHierarchy = (data, name,baseColor) => {
        const rootId = `id_${name}`;
        const types = [...new Set(data.map(item => item.type))];  // Unique types
        const hierarchy = types.map(type => {
            const typeId = `id_${type}`;
            const hue = Math.floor(Math.random() * 360); // Generate a random hue for color
            const color = `hsl(${hue}, 70%, 70%)`; // Generate a light pastel color
            return {
                id: typeId,
                name: type,
                parent: rootId,
                color: color,  // Color assigned here
                label: type
            };
        });
    
    

        const items = types.flatMap(type =>
            processData(data.filter(item => item.type === type), hierarchy.find(h => h.name === type).id)
        );

        return [{ id: rootId, name,color: baseColor}, ...hierarchy, ...items];
    };

    const assetData = createHierarchy(assets, "Assets","green");
    const liabilityData = createHierarchy(liabilities, "Liabilities","red");

    const finalData = [
        ...assetData,
        ...liabilityData
    ];

    const options = {
        series: [{
            type: 'treemap',
            name: "Networth",
            layoutAlgorithm: 'squarified',
            data: finalData,
            allowDrillToNode: true,
            alternateStartingDirection: true,
            animationLimit: 1000,
            levelIsConstant: false,
            dataLabels: {
              enabled: true,
      
            },
            levels: [
                {
                    level: 1,
                    dataLabels: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'top',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold'
                        }
                    },
                    borderWidth: 3
                },
                {
                    level: 2,
                    dataLabels: {
                        enabled: true
                    },
                    borderWidth: 2
                },
                {
                    level: 3,
                    dataLabels: {
                        enabled: true
                    },
                    borderWidth: 1
                }
            ]
        }],
        title: {
            text:title
        },
        credits:{enabled:false},
        exporting:{enabled:false}
    };

    return(
    <div style={{height:"100%",width:"100%"}}>
    <HighchartsReact 
    highcharts={Highcharts} 
    options={options} 
    containerProps={{ style: { height: '100%' , width:"100%"} }}
    />
    </div>)
}
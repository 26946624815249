import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Accordion } from '@mui/material';


export default function AccordionTSCustom({title,children,defaultOpen=false,sx={}}) {
  const [open,setOpen] = useState(defaultOpen)
  return (
    <div style={{borderRadius:"5px",padding:"0.25rem",background:"#fafafa",...sx}}>
        <div onClick={()=>setOpen(!open)} style={{display:"flex",gap:"1rem",padding:"0.25rem",alignItems:"center"}}>
        <h4 style={{fontWeight:"bold",flex:1}}>{title}</h4>
        <Button onClick={()=>setOpen(!open)} sx={{minWidth:"0",padding:"0.25rem"}}>
        {!open ? <KeyboardArrowDownIcon/>:<KeyboardArrowUpIcon/>}
            </Button></div>
        {open && children}
    </div>
  )
}

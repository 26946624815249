import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

export default function ChartYieldCurve ({ data ,title="", xAxisLabel="Name",yAxisLabel="Value",seriesName,chartType="column"}) {
  useEffect(() => {
    if (data && data.length > 0) {
      const firstItem = data[0];
      const keys = Object.keys(firstItem);

      const chartData = keys.map(key => ({
        name: key, // Use the key as the x-axis
        value: firstItem[key], // Use the corresponding value as the y-axis
      }));

      // Create chart
      Highcharts.chart('chart-container', {
        chart: {
          type: chartType,
        },
        title: {
          text: title,
        },
        xAxis: {
          categories: chartData.map(item => item.name),
          title: {
            text: xAxisLabel,
          },
        },
        yAxis: {
          title: {
            text: yAxisLabel,
          },
          min: 0, // Set minimum value for Y-axis
          max: 10, // Set maximum value for Y-axis
        },
        credits: {
            enabled: false, // Hide Highcharts.com credits
          },
        series: [
          {
            name: seriesName,
            data: chartData.map(item => item.value),
          },
        ],
      });
    }
  }, [data,chartType]);

  return <div id="chart-container" style={{ width: '100%', height: '100%' }}></div>;
};


import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./SearchButton.module.css";
import { Button, Tooltip } from "@mui/material";
export default function SearchButton({ onClick, placeholder = "" }) {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Tooltip>
        <button onClick={onClick} className={styles["searchButton"]}>
          <SearchIcon />
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Search {placeholder}
          </span>
        </button>
      </Tooltip>
    </div>
  );
}

import React from 'react'
import CryptoProfile from './crypto/CryptoProfile'
import CryptoDescription from './crypto/CryptoDescription'

export default function CryptoInfoList({info}) {

  const content = {
    "Summary":<CryptoDescription/>,
    "Profile":<CryptoProfile/>,
  }
  return (
    <>
    {content[info] || null}
    </>
  )
}

import { useAppInfo } from 'AppState'
import Sparkline from 'components/Charts/Sparkline'
import CashflowChart from 'myFinancials/Cashflow/CashflowChart'
import { calculateMonthlyNetCashFlow, calculateNetCashFlow } from 'myFinancials/Utils/utils'
import React, { useEffect, useState } from 'react'

export default function NetworthProjection({displayType="main"}) {
    const {stateMyFinancials} = useAppInfo()
    const {cashflowDistribution} = stateMyFinancials
    const {assetDist,liabilityDist,networthDist} = cashflowDistribution
    const [totalAssetDist,setTotalAssetDist] = useState([])
    const [totalLiabilityDist,setTotalLiabilityDist] = useState([])
    const [totalNetworthDist,setTotalNetworthDist] = useState([])
    useEffect(()=>{
      if (liabilityDist && assetDist){
        let liabilityEndBalance = liabilityDist.map(obj=>{return {amount:obj?.endingBalance,date:obj?.date,name:obj?.name}})
        setTotalAssetDist(calculateNetCashFlow(assetDist,"","Total Assets","income"))
        setTotalLiabilityDist(calculateNetCashFlow("",liabilityEndBalance,"Total Liabilities","expense"))
        setTotalNetworthDist(calculateMonthlyNetCashFlow(assetDist,liabilityEndBalance,"Total Networth"))
      }
     
    },[assetDist,liabilityDist])

  return (
    <>
    {totalNetworthDist.length>0 && 
    <>
    {displayType==="main" && 
    <CashflowChart chartType="area" data={[...totalNetworthDist,...totalAssetDist,...totalLiabilityDist]} title='Projected Networth'/>
    }
    {displayType !== "main" && 
     <div style={{width:"150px"}}>
     <Sparkline data={totalNetworthDist.map(obj=>obj.amount)} color={"green"} area averageLine={false}/>
     </div>
     
    }
     
      </>
    }    
    </> 
)
}

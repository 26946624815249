import React from 'react';

import AddPeersBar from 'peerList/AddPeersBar';
import Toggle from 'components/Inputs/Toggle';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import DcfValue from './DcfValue';
import CompanyVsIndustryUI from '../VsIndustry/CompanyVsIndustryUI';
import PeerValue from './PeerValue';
import { MetricSettingsBar } from 'components/Template/MetricSettings';

export default function Value({valuationType="Price Multiple"}) {
  const {stateStock,dispatchStock} = useAppInfo()
  const {peerMode} = stateStock
  const tabList = ["Price Multiple","EV Multiple","DCF"]
  
  const tabContent=[];

  tabContent[2] = (<DcfValue/>)

  tabContent[0] =<CompanyVsIndustryUI infoName={"Price Multiple vs Industry"} defaultMet={[dfin.pe,dfin.ps,dfin.pb]}/>
  tabContent[1] =<CompanyVsIndustryUI infoName={"EV Multiple vs Industry"} defaultMet={[dfin.evEbitda]}/>
  

  function handlePeerMode(){
    dispatchStock({"peerMode":!stateStock.peerMode})
  }
  let toggleValues = ["vs Industry/Sector","vs Peers"]
  if (valuationType === "DCF"){
    toggleValues = ["Company","vs Peers"]
  }
  return (
   <div className='containerColumnFlex'>
   
 <MetricSettingsBar
    title={`${valuationType} ${!peerMode ? toggleValues[0] : toggleValues[1]}`}
    childrenPopover={<Toggle
      toggleMode={peerMode}
      handleToggle={handlePeerMode}
      toggleValues={toggleValues}
    />}
    />
    
   <div className='containerColumnFlex1'>
   {peerMode && <PeerValue type='single' valuationType={valuationType}/>}
   {!peerMode && tabContent[tabList.indexOf(valuationType)]}
  </div>
   {peerMode && <AddPeersBar/>}
   </div>
  )
}
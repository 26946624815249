import React, { useState } from 'react';
import NodeTemplate from './NodeTemplate';
import { useReactFlow, useNodesState } from 'reactflow';
import { useAppInfo } from 'AppState';

export default function TextNode({ id, data, isConnectable,selected}) {
    const {stateMindmap,dispatchMindmap} = useAppInfo()
    const {nodes,edges} = stateMindmap

    const [inputValue, setInputValue] = useState(data.label || '');


    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        const updateNode =  nodes.map((node) => {
            if (node.id === id) {
                // it's important to create a new data object here to ensure React Flow detects the change
                return {
                    ...node,
                    data: {
                        ...node.data,
                        label: newValue
                    }
                };
            }
            return node;
        })
        
        dispatchMindmap({nodes:updateNode})
    };

    return (
        <NodeTemplate id={id} isConnectable={isConnectable} selected={selected} resizer={true}>
            <textarea 
                value={inputValue} 
                onChange={handleInputChange} 
                // style={{resize: "none"}}
            />
        </NodeTemplate>
    );
}

import { Alert, Paper, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import styles from './GuideContent.module.css';

export default function GuideStepContent({ steps, activeStep }) {
  return (
    <div className={styles.container}>
      <div className={styles.stepHeader}>
        <h2 className={styles.stepTitle}>
          {activeStep + 1}. {steps[activeStep]?.label}
        </h2>
        <div style={{ width: "100%", display: "flex",gap:"0.5rem",alignItems: "stretch",flexWrap:"wrap"}}>
          {steps[activeStep]?.keyPoints && (
            <div className={styles.keyPoints}>
              {!Array.isArray(steps[activeStep]?.keyPoints) ? (
                <Alert severity="info">
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: steps[activeStep]?.keyPoints.replace(/\n/g, '<br>'),
                    }}
                  />
                </Alert>
              ) : (
                <>
                  <Alert icon={<></>} severity="info" className={styles.alert}>
                    <Typography className={styles.alert}>
                      Key Points
                    </Typography>
                  </Alert>
                  {steps[activeStep]?.keyPoints.map((item, index) => (
                    <Alert icon={<CheckIcon fontSize="inherit" />} key={index} severity="info">
                      <Typography>{item}</Typography>
                    </Alert>
                  ))}
                </>
              )}
            </div>
          )}
          {steps[activeStep]?.success && (
            <div className={styles.success}>
              {!Array.isArray(steps[activeStep]?.success) ? (
                <Alert severity="success">
                  <Typography>{steps[activeStep]?.success}</Typography>
                </Alert>
              ) : (
                <>
                  <Alert icon={<></>} className={styles.alert}>
                    <Typography className={styles.alert}>
                      Success Points
                    </Typography>
                  </Alert>
                  {steps[activeStep]?.success.map((item, index) => (
                    <Alert key={index} severity="success">
                      <Typography>{item}</Typography>
                    </Alert>
                  ))}
                </>
              )}
            </div>
          )}
          {steps[activeStep]?.warning && (
            <div className={styles.warning}>
              {!Array.isArray(steps[activeStep]?.warning) ? (
                <Alert severity="warning">
                  <Typography>{steps[activeStep]?.warning}</Typography>
                </Alert>
              ) : (
                <>
                  <Alert icon={<></>} severity="warning" className={styles.alert}>
                    <Typography className={styles.alert}>
                      Warnings
                    </Typography>
                  </Alert>
                  {steps[activeStep]?.warning.map((item, index) => (
                    <Alert key={index} severity="warning">
                      <Typography>{item}</Typography>
                    </Alert>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(steps[activeStep]?.content && (!Array.isArray(steps[activeStep]?.content) || steps[activeStep]?.content.length > 0)) && (
        <div className={styles.content}>
          {steps[activeStep]?.content}
        </div>
      )}
    </div>
  );
}


import React, { useState, useEffect } from 'react';
import ReactFlow, { MiniMap, Controls, Panel } from 'reactflow';
import { Handle } from 'reactflow';
import dagre from 'dagre';
import MyFinancialsOverviewHome from 'myFinancials/MyFinancialsOverviewHome';


const initialNodes = [
  { id: 'networth', type: 'customNode', position: { x: 100, y: 5 }, data: { component: <div>Networth</div> }},
  { id: 'assets', type: 'customNode', position: { x: 50, y: 225 }, data: { component: <div>Assets:<br/>Cash<br/>+ Savings<br/> + Invesmtnets</div> }},
  { id: 'incomeInvestment', type: 'customNode', position: { x: 50, y: 400 }, data: { component: <div>Return on <br/>Investment & Savings</div> }},
  { id: 'income', type: 'customNode', position: { x: 50, y: 500 }, data: { component: <div>Income (Cash Inflow):<br/> Periodic Income <br/> + Infrequent Income <br/>  + Return on Invesmtnets</div> }},
  { id: 'liabilities', type: 'customNode', position: { x: 300, y: 225 }, data: { component: <div>Liabilities:<br/>Mortgage<br/>Car Loans<br/>Credit Card</div> }},
  { id: 'payments', type: 'customNode', position: { x: 300, y: 400 }, data: { component: <div>Periodic Payments:<br/>Pricipal + Interest</div> }},
  { id: 'expense', type: 'customNode', position: { x: 300, y: 500 }, data: { component: <div>Expense (Cash Outflow):<br/>Periodic Expenses<br/>Infrequent Expenses</div> }},
  { id: 'netCashflow', type: 'customNode', position: { x: 150, y: 700 }, data: { component: <div>Net Cashflow:<br/>Income (Cash Inflow)<br/>-Expense (Cash Outflow)</div> }},
  { id: 'netAllocateInvestment', type: 'customNode', position: { x: 100, y: 850 }, data: { component: <div>Invest or Save</div> }},
   { id: 'goals', type: 'customNode', position: { x: 400, y: 850 }, data: { component: <div>Financial Goals</div> }}
];

const initialEdges = [
  { id: 'e-networth-assets', source: 'networth', target: 'assets', animated: true },
  { id: 'e-networth-liabilities', source: 'networth', target: 'liabilities', animated: true },
  { id: 'e-investments-incomeInvestment', source: 'assets', target: 'incomeInvestment', animated: true },
  { id: 'e-incomeInvestment-income', source: 'incomeInvestment', target: 'income', animated: true },
  { id: 'e-income-cashflow', source: 'income', target: 'netCashflow', animated: true },
  { id: 'e-liabilities-payments', source: 'liabilities', target: 'payments', animated: true },
  { id: 'e-payments-expense', source: 'payments', target: 'expense', animated: true },
  { id: 'e-expense-cashflow', source: 'expense', target: 'netCashflow', animated: true },
  { id: 'e-netCashflow-netAllocateInvestment', source: 'netCashflow', target: 'netAllocateInvestment', animated: true },
// { id: 'e-netAllocateInvestment-assets', source: 'netAllocateInvestment', target: 'assets', animated: true },
  { id: 'e-netCashflow-goals', source: 'netCashflow', target: 'goals', animated: true }
];


const CustomNodeComponent = ({ data }) => {
    return (
      <div className="custom-node" style={{ padding: '10px', border: '1px solid black', borderRadius: '5px' }}>
        {data.component}
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <Handle type="source" position="bottom" style={{ borderRadius: 0 }} />
      </div>
    );
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
dagreGraph.setGraph({ rankdir: 'TB' }); // 'TB' for top to bottom layout

// Function to apply Dagre layout
const getLayoutedElements = (nodes, edges) => {
  dagreGraph.setNodes(nodes.map(node => {
    dagreGraph.setNode(node.id, { width: 200, height: 100 });
    return node;
  }));

  edges.forEach(edge => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return nodes.map(node => ({
    ...node,
    position: {
      x: dagreGraph.node(node.id).x - 10, // Adjusting node position based on computed layout
      y: dagreGraph.node(node.id).y - 75,
    }
  }));
};

const FinancialsFlow = () => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  useEffect(() => {
    const layoutedNodes = getLayoutedElements(nodes, edges);
    setNodes(layoutedNodes);
  }, []);

  return (
    <>
      <ReactFlow
        style={{background:"white",borderRadius:"5px"}}
        nodes={nodes}
        edges={edges}
        nodeTypes={{ customNode: CustomNodeComponent }}
        fitView
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
      >
         {/* <Panel position="top-left">
          <h1>Financials to Investment Goals</h1>
        </Panel> */}
        <Controls />
        {/* <MiniMap />
         */}
      </ReactFlow>
    </>
  );
};

export default FinancialsFlow;

import React, { useMemo, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { LinearProgress } from '@mui/material';
import { RecessionData } from 'content/contentEconomy';

require("highcharts/modules/accessibility")(Highcharts);

const TimeSeriesChart = ({
  seriesName,
  data,
  chartType = "area",
  title = "",
  zoneRGseries = false,
  rgValueMin = 0,
  recession = false,
  navigator = false,
  datePicker = true,
  color,
  animation = false,
  disableButtons = false, // New prop for disabling buttons
}) => {
  
  const lastDataPoint = data[data.length - 1];
 
  const seriesData = useMemo(
    () => [
      {
        name: seriesName,
        data: data.map((entry) => [
          new Date(entry.Date || entry.date).getTime(),
          entry[seriesName],
        ]),
        color: color,
        animation: animation,
      },
    ],
    [data, seriesName]
  );

  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
        type: chartType,
        animation: false,
      },
      title: {
        text: title,
      },
      xAxis: {
        type: 'datetime',
        endOnTick: true,
        showLastLabel: true,
        minRange: 1,
        plotBands: recession && RecessionData.map((recession, index) => ({
          from: new Date(recession.from).getTime(),
          to: new Date(recession.to || recession.from).getTime(),
          color: index % 2 === 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
          label: {
            text: recession.label,
            rotation: 90,
            verticalAlign: "bottom",
            y: -50
          },
        })),
      },
      yAxis: {
        title: {
          text: 'Values',
        },
        plotLines: zoneRGseries && [{
          value: rgValueMin,
          color: 'black',
          dashStyle: 'dot',
          width: 2,
          zIndex: 5,
          label: {
            text: 'Investment Cost',
            style: {
              fontSize: '1rem',
              align: 'right',
              x: -20,
              backgroundColor: '#ddd'
            }
          }
        }],
      },
      series: seriesData,
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
          zones: zoneRGseries && [{
            value: 0,
            color: 'red'
          },
          {
            color: 'green'
          }]
        },
      },
      rangeSelector: {
        allButtonsEnabled: true,
        inputEnabled: datePicker,
        selected: 5,
        animation: false,
        buttons: [
          {
            type: 'month',
            count: 1,
            text: '1m',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
          },
          {
            type: 'ytd',
            text: "YTD",
          },
          {
            type: 'year',
            count: 1,
            text: '1y',
          },
          {
            type: 'year',
            count: 5,
            text: '5y',
          },
          {
            type: 'year',
            count: 10,
            text: '10y',
          },
          {
            type: 'all',
            text: 'All',
          },
        ],
        buttonTheme: {
          style: {
            display: disableButtons ? 'none' : 'inline-block' // Hide buttons if disableButtons is true
          }
        }
      },
      credits: {
        enabled: false,
      },
      annotations: null,
      navigator: {
        enabled: navigator
      },
      scrollbar: {
        enabled: false
      },
      stockTools: {
        gui: {
          enabled: false
        }
      },
      accessibility: {
        enabled: false,
      },
    }),
    [data, seriesData, recession, disableButtons]
  );

  return (
    <div style={{ flex: 1, height: '100%', width: "100%" }}>
      {(!options) && <LinearProgress />}
      {options &&
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          constructorType="stockChart"
          containerProps={{ style: { height: '100%', width: "100%" } }}
        />}
    </div>
  );
};

export default TimeSeriesChart;


// Highcharts.setOptions({
//   plotOptions: {
//     series: {
//       animation: false,
//     },
//   },
//   lang: { rangeSelectorZoom :"" } 
// });

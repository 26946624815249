import React, { useEffect } from 'react';
import { useAppInfo } from 'AppState';
import { assetCode} from 'content/constants';
import ForexExchangeRate from './forex/ForexExchangeRate';
import TickerCommonInfoList from './TickerCommonInfoList';
import StocksInfoList from './StocksInfoList';
import CryptoInfoList from './CryptoInfoList';
import EtfInfoList from './EtfInfoList';

export default function TickerInfoList ({infoName}) {
 
 const {stateTicker} = useAppInfo()

 const {tickerType} = stateTicker 

return (
    <>
    <TickerCommonInfoList info={infoName}/>
    {tickerType===assetCode?.Stocks && <StocksInfoList info={infoName}/>}
    {tickerType===assetCode?.Crypto && <CryptoInfoList info={infoName}/>}
    {tickerType===assetCode?.ETF && <EtfInfoList info={infoName}/>}
    {tickerType===assetCode?.Forex && <ForexExchangeRate/>}
    </>
    )
}


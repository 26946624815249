import React from 'react'
import useATR from 'data/technicals/useATR'
import TableGen from 'components/Table/TableGen'
import IndicatorTemplate from './IndicatorTemplate'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function ATRpeers({tickerList}) {
 
  const {ATR,ATRLoading} = useATR(tickerList)

  return (
    <IndicatorTemplate indicatorName={"Average True Range (ATR)"}>
    {ATRLoading && <LoadingSkeletonTable length={tickerList.length}/>}
    
    {(!ATRLoading && ATR.length>0) && 
    <>
    <TableGen data={ATR}/>
    </>}
    </IndicatorTemplate>
  )
}

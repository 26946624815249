import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import PeriodSlider from "components/Inputs/PeriodSlider";
import SelectBox from "components/Inputs/SelectBox";
import TableGen from "components/Table/TableGen";
import useRSI from "data/technicals/useRSI";
import IndicatorTemplate from "./IndicatorTemplate";

export default function RSI({ tickerList }) {
  const [window, setWindow] = useState(14);
  const [dateFrequency, setDateFrequency] = useState("Daily");
  const [overBought, setOverbought] = useState(70);
  const [overSold, setOverSold] = useState(30);
  const { rsiOb, rsiOs, rsiN, rsiStats, rsiLoading } = useRSI(
    tickerList,
    window,
    overBought,
    overSold,
    dateFrequency
  );

  function handleDateFreq(e, v) {
    setDateFrequency(v);
  }

  function handleWindow(e, v) {
    setWindow(v);
  }

  function handleOverSold(event, v) {
    setOverSold(event.target.value === "" ? "" : Number(event.target.value));
  }

  function handleOverBought(event, v) {
    setOverbought(event.target.value === "" ? "" : Number(event.target.value));
  }

  const settingContent = (
    <>
      <div>
        <SelectBox
          options={["Daily", "Weekly", "Monthly"]}
          id={"DateFreq Type"}
          value={dateFrequency}
          onChange={handleDateFreq}
          label={"Date Frequency"}
        />
      </div>
      <div>
        <h3>Window</h3>
        <PeriodSlider
          max={200}
          min={1}
          defaultValue={window}
          onChange={handleWindow}
          handleInputChange={handleWindow}
          period={dateFrequency}
        />
      </div>
      <div>
        <h3>Overbought Level</h3>
        <PeriodSlider
          max={100}
          min={70}
          defaultValue={overBought}
          onChange={handleOverBought}
          handleInputChange={handleOverBought}
          period={dateFrequency}
        />
      </div>
      <div>
        <h3>Oversold Level</h3>
        <PeriodSlider
          max={50}
          min={1}
          defaultValue={overSold}
          onChange={handleOverSold}
          handleInputChange={handleOverSold}
          period={dateFrequency}
        />
      </div>
    </>
  );

  return (
    <>
      <IndicatorTemplate
        indicatorName={"RSI"}
        showSettings={true}
        settingContent={settingContent}
      >
        {rsiLoading && <LinearProgress />}
        <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
          {rsiOb.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                borderRadius: "5px",
                padding: "0.25rem",
              }}
            >
              <h3>Overbought</h3>
              <TableGen data={rsiOb} />
            </div>
          )}
          {rsiOs.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <h3>Oversold</h3> <TableGen data={rsiOs} />
            </div>
          )}

          {rsiN.length > 0 && (
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <h3>Neutral</h3> <TableGen data={rsiN} />
            </div>
          )}
        </div>
        {rsiStats.length > 0 && (
          <div
            style={{
              borderRadius: "5px",
              padding: "0.25rem",
              marginTop: "0.5rem",
            }}
          >
            <h2>Backtesting Summary Stats</h2>
            <TableGen data={rsiStats} />
          </div>
        )}
      </IndicatorTemplate>
    </>
  );
}

import React, { useEffect, useState } from 'react'

export default function useSetTimeoutRender() {
 
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShouldRender(true);
      }, 200);
  
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);
  
    if (!shouldRender) {
      return null; // Render nothing until the timer completes
    }
  
}

import useFindDefaultPortfolio from 'data/user/portfolio/useFindDefaultPortfolio'
import useFindPortfolio from 'data/user/portfolio/useFindPortfolio'
import usePortfolioMultipleSummary from './Portfolio/hooks/usePortfolioMultipleSummary'
import useAddDefaultPortfolio from 'data/user/portfolio/useAddDefaultPortfolio'
import usePortfolioTrendDetails from './Portfolio/hooks/usePortfolioTrendDetails'
import usePortfolioSummaryDetails from './Portfolio/hooks/usePortfolioSummaryDetails'

export default function useSetPortfolio() {

    useFindPortfolio()
    useFindDefaultPortfolio()
    
    usePortfolioSummaryDetails()
    usePortfolioTrendDetails()
    usePortfolioMultipleSummary() 
    useAddDefaultPortfolio() 
 
}

import React from 'react'
import EconOverviewLocal from './EconOverviewLocal'
import EconOverviewGlobal from './EconOverviewGlobal'
import EconomyCalendar from './EconomyCalendar';
import EconMap from './EconMap';
import PriceVsEconomy from './PriceVsEconomy';
import EconomyCategoryContent from './EconomyCategoryContent';
import { Paper } from '@mui/material';

export default function EconomyInfoList({infoName}) {
 const econInfoList  = {
    "Overview":<EconOverviewLocal/>,
    "Global":<EconOverviewGlobal/>,
    "Interest Rates": <EconomyCategoryContent category='Interest Rates'/>,
    "Inflation": <EconomyCategoryContent category="Inflation"/>,
    "Monetary":<EconomyCategoryContent category='Monetary'/>,
    "Business Indicators":<EconomyCategoryContent category='Business Indicators'/>,
    "Employment":<EconomyCategoryContent category='Employment'/>,
    "National Accounts":<EconomyCategoryContent category='National Accounts'/>,
    "Calendar":<Paper className='containerColumnFlexNoGap'><EconomyCalendar/></Paper>,
    "Map":<EconMap/>,
    "Economy vs Market":<PriceVsEconomy display='marketTabs' infoName={"Economy vs Market"}/>,
    "vs Market":<Paper className='containerColumnFlexNoGap'><PriceVsEconomy display='marketTabs' infoName={"Economy vs Market"}/></Paper>
 }
 return (
    <>
    {econInfoList[infoName]}
    </>
  )
}

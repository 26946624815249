import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useEconGlobal() {
    const [econGlobal, setEconInfo] = useState([]);
    useEffect(() => {
        getMetricInfo();
      }, []);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`${BASE_URL}/econGlobal/`);
          setEconInfo(responseMetric.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {econGlobal}
    
}

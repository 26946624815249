import React, { useEffect, useState } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Alert} from '@mui/material';
import YoutubeEmbed from 'components/Media/YoutubeEmbed';
import RcInfoGraphic from './RcInfoGraphic';
import ProfileLogo from 'components/Logo/ProfileLogo';
import styles from "./ResourceContentTemplate.module.css"

export default function ResourceContentTemplate({content}) {
  let searchParams = new URLSearchParams(window.location.search);
  let idHeading = searchParams.get('heading');
  const [tabIndex,setTabIndex] = useState(0)
  const [tabList,setTabList] = useState(["Key Takeaways","Video","Info Graphic"])
  const [takeawayDetails,setTakeawayDetails] = useState()
  const [contentSel,setContentSel] = useState()
  const [indexQ,setIndexQ]  = useState()

  function handleTab(index){
    setTabIndex(index)
  }
  
  const tabContent = []
 
  useEffect(()=>{
    if (content && idHeading){
    const contentHeader =content.filter(obj=>obj.Heading === idHeading)[0]
    const takeawayIndex = content.findIndex(({ Heading }) => Heading === idHeading);
    setIndexQ(takeawayIndex)
    if (contentHeader){
    setContentSel(contentHeader)
    setTakeawayDetails(contentHeader["Takeaways"])
    // if (contentHeader?.["Content"] === ""){
    //   // setTabList([])
    //   setTabIndex(0)
    // }
  
  }
  }
  },[idHeading,content])
 
 
  function handleNextContent(){
    let index = indexQ + 1
    // const takeawayDetails =content.filter(obj=>obj["Sub Topic"]===contentSel["Sub Topic"])[index]    
    // const encodedHeading = encodeURIComponent(takeawayDetails["Heading"]);
    // navigate(`/Resource/${takeawayDetails["Topic"]}/${encodedHeading}`)
    // setIndexQ(index)
  } 
  // const takeaways = takeawayDetails && formatKeyTakeaways(takeawayDetails["Data"].join("\n\n"),takeawayDetails["List Type"])
  tabContent[0] = <div dangerouslySetInnerHTML={{ __html: takeawayDetails}}></div>
  tabContent[1] = <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%"}}>
                    <div style={{flex:1,width:"100%"}}>
                   <YoutubeEmbed videoId={"VdX00QW8h0o"}/></div>
                   <Alert severity='warning'>This is just a demo example of how the videos will look.</Alert> </div>
  tabContent[2] = <>
  <Alert severity='warning'>This is just a demo example of how the info graphics will look.</Alert>
  <RcInfoGraphic/>
  </>
  
  // const calculateReadingTime = (text) => {
  //   // Assuming an average reading speed of 200 words per minute
  //   const wordsPerMinute = 200;
  //   const wordCount = text.split(/\s+/).length;
  //   const minutesToRead = Math.ceil(wordCount / wordsPerMinute);
  //   return minutesToRead;
  // };
  

  return (
    <div style={{height:"100%",background:"white",overflow:"auto",boxSizing:"border-box"
    ,display:"flex",flexDirection:"column",
    width:"100%",
    userSelect:"text"
    }}>
      {idHeading && <div style={{padding:"0.5rem 1rem",width:"100%",boxSizing:"border-box"}}>
      <h2 style={{fontWeight:"bold"}}>{idHeading}</h2>
      <div style={{display:"flex",gap:"2rem",color:"gray"
      ,alignItems:"center",marginTop:"0.5rem",width:"100%"}}>
      <div><div style={{display:"flex",gap:"0.5rem",}}>{contentSel?.["AuthorImage"] && <ProfileLogo imgSrc={contentSel?.["AuthorImage"]}/>}
      <h4>{contentSel?.["Author"]}</h4></div>
      <h5>last updated: {formatDate(contentSel?.["Date"])}</h5></div>
      <div style={{display:"flex",alignItems:"center",gap:"0.5rem",marginLeft:"auto"}}>
      
      <AccessTimeIcon fontSize='small'/> 
      <h4>{takeawayDetails && calculateReadingTime(takeawayDetails)} min read</h4>
      </div>
      
      </div>       
      </div>}
    
      <div className={styles.content} 
      style={{padding:"0.5rem 1rem",flex:1,width:"100%",boxSizing:"border-box",
      justifyContent:"center",display:"flex",flexDirection:"column"}}>       
          {tabContent[tabIndex]}
         
        </div>
    </div>
  )
}

function formatDate(date){
  const formattedDate =  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });
  return formattedDate
}

function calculateReadingTime(htmlString) {
  // Create a temporary DOM element to hold the HTML string
  let tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // Get the text content from the temporary element
  let textContent = tempElement.textContent || tempElement.innerText || "";

  // Calculate the number of words
  let wordCount = textContent.split(/\s+/).filter(function(word) {
      return word.length > 0;
  }).length;

  // Average reading speed in words per minute
  let wordsPerMinute = 250;

  // Calculate reading time in minutes
  let readingTimeMinutes = wordCount / wordsPerMinute;

  // Return reading time rounded to the nearest minute
  return Math.ceil(readingTimeMinutes);
}
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useAppInfo } from 'AppState';
import SelectBox from 'components/Inputs/SelectBox';
import useAddNote from 'data/user/notes/useAddNote';
import useDeleteNote from 'data/user/notes/useDeleteNote';

import BackupIcon from '@mui/icons-material/Backup';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';

import { debounce } from 'lodash';
import useFindNote from 'data/user/notes/useFindNote';
import CrudModal from 'components/Modal/CrudModal';
import TextArea from 'components/Inputs/TextArea';

export default function Notes() {
    const {stateNotes,dispatchNotes} = useAppInfo()
    useFindNote()
  

    const {loaded:noteLoaded,saved,dataSel,data:noteDataAll,nameSel,names:noteNames} = stateNotes
    const [saveNoteModal,setSaveNoteModal] = useState(false)
    const {addNote} =  useAddNote()
    const {deleteNote} = useDeleteNote()

    const debouncedSaveChanges = useCallback(
      debounce(() => {
        addNote(nameSel, dataSel);
      }, 2000),
      [nameSel, dataSel]
  );

  useEffect(() => {
      if (nameSel !== "") {
          debouncedSaveChanges();
      }
      return () => {
          debouncedSaveChanges.cancel();
      };
  }, [nameSel, dataSel]);

    
    function handleSaveNote(noteName){
      const noteDataUpdate = noteDataAll
      const noteDataSelNew = (noteLoaded && noteNames.length > 0) ? "" : dataSel
      setSaveNoteModal(false)
      noteDataUpdate[noteName] = noteDataSelNew
      dispatchNotes({dataSel:noteDataSelNew,data:noteDataUpdate,nameSel:noteName,names:[...noteNames,noteName]})
    }

    function handleDeleteNote(){
      deleteNote(nameSel)
      const noteNamesUpdate = noteNames.filter(obj=>obj!==nameSel)
      if (noteNamesUpdate.length>0){
        dispatchNotes({dataSel:noteDataAll[noteNamesUpdate[0]],nameSel:noteNamesUpdate[0],names:noteNamesUpdate})
      }
      else{
        dispatchNotes({dataSel:"",nameSel:"",names:[]})
      }
    
    }
  
    function handleNoteChange(e,v){
      dispatchNotes({dataSel:noteDataAll[v],nameSel:v})
    }

    function handleNoteDataChange(noteDataSelUpdate){
      let noteDataUpdate = noteDataAll
      if (nameSel !== ""){
        noteDataUpdate[nameSel] = noteDataSelUpdate
      }
      dispatchNotes({data:noteDataUpdate,dataSel:noteDataSelUpdate,saved:false})
    }

  return (
    <>
    <div style={{width:"100%",height:"100%",boxSizing:"border-box",display:"flex"
      ,flexDirection:"column",padding:"0.5rem",gap:"0.5rem"}}>
    <div style={{display:"flex",gap:"0.5rem",width:"100%",boxSizing:"border-box"}}>
    {noteNames.length>0 &&   
    <SelectBox 
    variant='outlined'
    sx={{flex:1,width:"100%"}} options={noteNames} 
    value={nameSel} onChange={handleNoteChange}/>}
      {noteLoaded && noteNames.length > 0 && 
  <div style={{display:"flex",alignItems:"center",gap:"1rem",boxSizing:"border-box"}}>     
      <Tooltip title={!saved?"saving note":"note saved"}>
  {!saved && <BackupIcon className="recording"/>}{saved && <CloudDoneIcon sx={{color:"green"}}/>}
       </Tooltip>
  <IconButton onClick={handleDeleteNote}><DeleteIcon fontSize='small'/></IconButton>
  </div>}
  </div>

  <TextArea
  placeholder={"Write Something"}
  value={dataSel}
  onChange={handleNoteDataChange}
  />

  <div style={{display:"flex",gap:"1rem"}}>
  <Button sx={{marginLeft:"auto"}} 
  onClick={()=>setSaveNoteModal(true)} variant='contained'>
  {noteLoaded && noteNames.length ===0 ? "Save Note": "New Note"}
  </Button>
  </div>
</div>
{saveNoteModal &&
         <CrudModal title={"Note"} 
         onClose={()=>setSaveNoteModal(false)}
         placeholder={"Enter a Note Name"} handleCreate={(v)=>handleSaveNote(v)}/>
}
</>
  )
}


import { useAppInfo } from 'AppState'
import { paybackPeriodOptions } from 'appState/initialStateMyFinancials'
import { calculateAmortizationSchedule } from 'myFinancials/Utils/utils'
import React, { useEffect, useState } from 'react'

export default function useLiabilityAnalytics() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {assets,liabilities,cashflowDistribution} = stateMyFinancials
    
  useEffect(()=>{
    const liabilityIndividualDetails = liabilities.flatMap(obj => {
      const {totalAmount,periodicPayment,totalInterest,totalPrincipal}= calculateAmortizationSchedule(obj);
      const loanTermAdjusted = obj.loanTerm?.y + Math.floor(obj.loanTerm?.m/12)
      const startDate = new Date(obj?.date);

    // Calculate the end date by adding the adjusted years
    const endDate = new Date(startDate.setFullYear(startDate.getFullYear() + loanTermAdjusted));
   
    const currentDate = new Date();
    const numberOfPayments = Math.floor(totalAmount / periodicPayment);
    const paymentsDone = Math.min(getPaymentsDone(new Date(obj?.date), currentDate, obj.paybackPeriod), numberOfPayments);
    const outstandingAmount =  (obj.amount - obj.amount * (paymentsDone/numberOfPayments))
   
    return {...obj,"loanAmount":obj.amount,
        "loanTerm (years)":loanTermAdjusted,totalInterest
        ,"totalPaybackAmount":totalAmount
        ,numberOfPayments,totalPrincipal,outstandingAmount
        ,periodicPayment,"endDate":endDate,paymentsDone};
    });
    

    // Calculate Total Outstanding Principal
    const totalOutstandingPrincipal = liabilityIndividualDetails.reduce((total, liability) => {
        return total + liability.outstandingAmount;
    }, 0);

    // Calculate Total Monthly Payments
    const totalMonthlyPayments = liabilityIndividualDetails.reduce((total, liability) => {
        const monthlyPayment = convertToMonthly(liability.periodicPayment, liability.paybackPeriod);
        return total + monthlyPayment;
    }, 0);

    // Create the dictionary of liability stats
    const liabilityStats = {
        totalOutstandingPrincipal,
        totalMonthlyPayments
    };
    
    dispatchMyFinancials({liabilityIndividualDetails,liabilityStats})
  },[liabilities])

}

const getPaymentsDone = (startDate, currentDate, paybackPeriod) => {
    let paymentsDone = 0;

    if (startDate>currentDate){
        return 0
    }

    switch (paybackPeriod) {
        case "Daily":
            paymentsDone = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));
            break;
        case "Weekly":
            paymentsDone = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24 * 7));
            break;
        case "Bi-Weekly":
            paymentsDone = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24 * 14));
            break;
        case "Semi-Monthly":
            paymentsDone = Math.floor(((currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth())) * 2);
            if (currentDate.getDate() >= 15) {
                paymentsDone += 1;
            }
            break;
        case "Monthly":
            paymentsDone = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth());
            break;
        case "Quarterly":
            paymentsDone = Math.floor(((currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth())) / 3);
            break;
        case "Semi-Annually":
            paymentsDone = Math.floor(((currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth())) / 6);
            break;
        case "Annually":
            paymentsDone = currentDate.getFullYear() - startDate.getFullYear();
            break;
        default:
            paymentsDone = 0; // Fallback if payback period is not recognized
    }

    return paymentsDone;
};


// Helper function for converting payments to monthly equivalent
const convertToMonthly = (payment, paybackPeriod) => {
    switch (paybackPeriod) {
        case "Daily":
            return payment * 30; // Assuming 30 days in a month
        case "Weekly":
            return payment * 4; // Approximately 4 weeks in a month
        case "Bi-Weekly":
            return payment * 2; // 2 Bi-Weekly payments in a month
        case "Semi-Monthly":
            return payment * 2; // 2 Semi-Monthly payments in a month
        case "Quarterly":
            return payment / 3; // 1 Quarterly payment divided by 3 months
        case "Semi-Annually":
            return payment / 6; // 1 Semi-Annual payment divided by 6 months
        case "Annually":
            return payment / 12; // 1 Annual payment divided by 12 months
        case "Monthly":
        default:
            return payment; // Already in monthly terms
    }
};
import { Button } from '@mui/material'
import React from 'react'
import useHandleTickerChange from './hooks/useHandleTickerChange';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { menuIcons } from 'nav/menuIcons';
import { useNavigate } from 'react-router-dom';

export default function DetailTradeTickerButton({position="right",ticker,buttonType="table",fontSize="1rem"}) {
  const navigate = useNavigate()
  const handleTickerChange = useHandleTickerChange()
    
  function handleInfo(e, v) {
    e.stopPropagation()
    handleTickerChange({ticker: v});
  }

  function handleTrade(e, v) {
    navigate("/Trade")
    e.stopPropagation()
    handleTickerChange({ticker:v,modal:false,navUrl:"/Trade/Order"});
  }

  return (
    <>
    {buttonType==="table" && 
    <div style={{
        zIndex: "50",
        position: "absolute",
        left: position === "left" && "30px",
        right: position === "right" && "0px",
        top: "50%", // Adjust this value as needed
        display: "flex",
        gap: "1rem",
        backgroundColor: "white",
        padding: "0.25rem",
        transform: "translateY(-50%)",
        borderRadius: "4px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
      }}>
        <Button
          sx={{padding:"0.1rem",minWidth:0,fontSize:"0.9rem"}}
          onClick={(e) => handleInfo(e, ticker)}
        >
          Detail
        </Button>
        <Button
          sx={{padding:"0.1rem",minWidth:0,fontSize:"0.9rem"}}
          onClick={(e) => handleTrade(e, ticker)}
        >
          Trade
        </Button>
      </div>}

      {buttonType === "main" && 
        <div style={{display:"flex",gap:"1rem",marginTop:"1rem"}}>
        <Button onClick={(e)=>handleInfo(e,ticker)} variant='contained' >
          Detailed
          <FullscreenIcon/>
          </Button>
        <Button onClick={(e)=>handleTrade(e,ticker)} variant='outlined'>Trade
        {menuIcons?.Trade}
        </Button>
        </div>}


      {buttonType === "outlined" && 
        <div style={{display:"flex",gap:"1rem",marginTop:"1rem"}}>
        <Button sx={{fontSize:fontSize}} onClick={(e)=>handleInfo(e,ticker)} variant='outlined' >
          Detailed
          <FullscreenIcon/>
          </Button>
        <Button sx={{fontSize:fontSize}} onClick={(e)=>handleTrade(e,ticker)} variant='outlined'>Trade
        {menuIcons?.Trade}
        </Button>
        </div>}
    </>
  )
}

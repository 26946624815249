import React from 'react';

import FinancialsFlow from 'resource/whiteboard/FinancialsFlow';
import GuideTemplate from '../Template/GuideTemplate';

export default function FinancialPlanningGuide() {
  return (
    <>
      <GuideTemplate description={description} steps={steps} name="Financial Planning Guide"/>
    </>
  )
}
const description = `
Financial planning is all about getting a clear picture of your financial health. It starts with figuring out your net worth—the difference between what you own and what you owe. From there, you keep track of your assets and debts, make sure your cash flow is healthy, and check that you're not spending more than you earn. It's also about managing your investments wisely, diversifying them to match your financial goals and risk comfort. Plus, it's important to have a solid plan for managing risks, like getting the right insurance to protect against big surprises. Regularly tweaking your plan helps you stay on track as your life and the economy change.
`
const steps = [
    {
        label: "Overview",
        keyPoints: [
            "Calculate net worth to understand your financial starting point.",
            "Track assets and liabilities to maintain a comprehensive financial profile.",
            "Ensure a positive cash flow by balancing income against expenses.",
            "Manage investments to align with your goals and risk tolerance.",
            "Incorporate risk management through adequate insurance and contingency planning."
        ],
        success: [
            "Achieving a holistic understanding of your financial status.",
            "Successfully using your financial plan to make informed decisions and adjustments."
        ],
        warning: [
            "Beware of mental accounting biases that can lead to suboptimal spending and saving decisions.",
            "Avoid overestimating investment returns or underestimating financial risks."
        ],
        content: <div style={{width:"100%",height:"800px"}}><FinancialsFlow/></div>,
    },
    {
        label: "Understanding Net Worth",
        keyPoints: [
            "Net worth is the difference between what you own (assets) and what you owe (liabilities).",
            "Accurate assessment of your net worth gives a clear picture of your financial health and is a starting point for planning.",
        ],
        success: [
            "Regularly updating your net worth to reflect changes in assets and liabilities.",
            "Using net worth as a baseline to track financial progress over time.",
        ],
        warning: [
            "Ignoring small assets or liabilities that can cumulatively have significant impacts.",
            "Failing to reassess net worth periodically.",
        ],
        content: [],
    },
    {
        label: "Assessing Assets and Liabilities",
        keyPoints: [
            "List all current assets, including cash, investments, real estate, and personal property.",
            "List all current liabilities, including mortgages, loans, and other debts.",
            "Understand how your assets and liabilities affect your financial stability and ability to invest.",
        ],
        success: [
            "Maintaining a detailed, up-to-date list of assets and liabilities.",
            "Analyzing how each asset can generate future income or appreciate in value.",
        ],
        warning: [
            "Overestimating asset values or underestimating liabilities.",
            "Not considering the liquidity of assets and how quickly you can convert them to cash if needed.",
        ],
        content: [],
    },
    {
        label: "Managing Cash Flow",
        keyPoints: [
            "Cash flow analysis involves tracking all sources of income versus expenses.",
            "Positive cash flow is essential for saving, investing, debt repayment and meeting your goals.",
        ],
        success: [
            "Creating a monthly budget to manage expenses and increase savings.",
            "Identifying and eliminating unnecessary expenses to improve cash flow.",
        ],
        warning: [
            "Not having a clear understanding of where money is spent each month.",
            "Failing to plan for irregular expenses that can disrupt cash flow.",
        ],
        content: [],
    },
    {
        label: "Income and Expense Analysis",
        keyPoints: [
            "Regular review of income sources and expense categories.",
            "Understanding the stability and sustainability of your income streams.",
        ],
        success: [
            "Finding ways to diversify income sources to reduce risk.",
            "Setting and adhering to a realistic expense budget.",
        ],
        warning: [
            "Relying on a single income source.",
            "Allowing expenses to exceed income, leading to debt accumulation.",
        ],
        content: [],
    },
    {
        label: "Investment Portfolio Management",
        keyPoints: [
            "Developing a diversified investment portfolio based on your financial goals and risk tolerance.",
            "Regular review and rebalancing of your portfolio to align with your financial objectives.",
        ],
        success: [
            "Achieving a balanced portfolio that reduces risk and maximizes returns.",
            "Utilizing tax-advantaged accounts and strategies to enhance investment growth.",
        ],
        warning: [
            "Neglecting portfolio diversification.",
            "Failing to adjust your investment strategy based on life changes or market conditions.",
        ],
        content: [],
    },
    {
        label: "Comprehensive Risk Management",
        keyPoints: [
            "Identifying potential financial risks and developing strategies to mitigate them.",
            "Incorporating insurance and other protective measures into your financial plan.",
        ],
        success: [
            "Effectively using insurance (life, health, property) to safeguard assets and income.",
            "Having contingency plans in place for unexpected financial setbacks.",
        ],
        warning: [
            "Underestimating the importance of risk management in preserving financial health.",
            "Not regularly reviewing risk strategies and coverage.",
        ],
        content: [],
    }
];
import React, { useState } from 'react';
import TextArea from 'components/Inputs/TextArea';
import { PageMainContentTitle } from 'components/Font/Title';

const InvestmentCrisisRM = () => {
    const [data] = useState({
        keyPoints: [
            "Diversify your investment portfolio to spread risk.",
            "Maintain an emergency fund equivalent to 6-12 months of expenses.",
            "Regularly review and adjust your investment allocations.",
            "Stay informed about market conditions and economic indicators."
        ],
        planning: [
            "Assess your risk tolerance and investment timeline.",
            "Develop a clear investment strategy based on your financial goals.",
            "Set up automatic contributions to investments and savings.",
        ],
        actions: [
            "Monitor your investments and rebalance your portfolio periodically.",
            "Keep calm and avoid panic selling during market downturns.",
            "Review and revise your risk management plan annually or after significant life changes."
        ]
    });
    const [crisisManagementPlan,setCrisisManagementPlan] = useState()

    return (
        <div style={{padding:"1rem"}}>
            <h2>Crisis Management Plan</h2>
            {/* <TextArea placeholder={"Notes"}></TextArea>

             */}
            <div>
                <h2>Key Points</h2>
                <ul>
                    {data.keyPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Planning Strategies</h2>
                <ul>
                    {data.planning.map((plan, index) => (
                        <li key={index}>{plan}</li>
                    ))}
                </ul>
            </div>
            <div>
                <h2>Actionable Steps</h2>
                <ul>
                    {data.actions.map((action, index) => (
                        <li key={index}>{action}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default InvestmentCrisisRM;
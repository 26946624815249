import { Button } from '@mui/material'
import { useAppInfo } from 'AppState'
import { assetTypes, defaultFieldBlank, liabilityTypes } from 'appState/initialStateMyFinancials'
import AddFinancialsModal from 'myFinancials/Utils/AddFinancialsModal'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useState } from 'react'

export default function AddLiabilities() {
const {stateMyFinancials} = useAppInfo()
const {liabilities} = stateMyFinancials
  const [addLiabilities,setAddLiabilities] = useState(false)

  return (
    <>
    <Button variant='highlight' onClick={()=>setAddLiabilities(true)}>+ Liabilities</Button>
    {addLiabilities && 
    <AddFinancialsModal title="Liabilities" onClose={()=>setAddLiabilities(false)}>
    <FieldFormGen 
    showType={true}
    interestRate={true}
    date={true}
    compounding={true}
    paybackPeriod={true} 
    loanTerm={true}
    defaultField={defaultFieldBlank.Liability}
    typeOptions={liabilityTypes}  
    storeName={"liabilities"} data={liabilities} formName='Liabilities' formHeading='Liability Type' />
    </AddFinancialsModal>}
    </>
  )
}
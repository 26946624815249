import React, { useEffect} from 'react'
import { useAppInfo } from 'AppState'

import useSecConstituents from "data/industry/useSecConstituents";
import useIndustryConstituents from "data/industry/useIndustryConstituents";
import useIndustryOverview from "data/industry/useIndustryOverview";
import useSectorInfo from "data/industry/useSectorInfo";
import useSectorOverview from "data/industry/useSectorOverview";
import useIndustryFinancial from "data/industry/useIndustryFinancial";
import { dfin } from 'content/constants';


export default function useIndSecDetails() {
    const {dispatchIndustry,stateIndustry,stateScreener} = useAppInfo()
    
    useEffect(()=>{
        dispatchIndustry({"secConsti":stateScreener.stocks,"indConsti":stateScreener.stocks})
    },[stateScreener.stocks])

    useIndustryFinancial();  
    useSectorInfo();
    useSectorOverview() 
    // useSecConstituents()
    // useIndustryConstituents()
    useIndustryOverview()

    useEffect(()=>{
    let infoX = []
   
    if(!stateIndustry.indSecMode){
        let sector = stateIndustry.sectorSel
        if (stateIndustry.sectorSel.includes("All")){
            const uniqueSectors = stateIndustry.uniqueSectors  
            sector = uniqueSectors
        
        }
        // if using useSecConstituents
        // infoX= stateIndustry.secConsti.filter(obj=>stateIndustry.mScaleSel.includes(obj[dfin.mScale]));}
        infoX = stateIndustry.secConsti.filter(obj=>stateIndustry.mScaleSel.includes(obj[dfin.mScale]) && 
        sector.includes(obj[dfin.gicSector]) && 
        stateIndustry.countrySel.includes(obj[dfin.country]));}
    else{
        // if using useSecConstituents
        // infoX= stateIndustry.indConsti.filter(obj=>stateIndustry.mScaleSel.includes(obj[dfin.mScale]));}
        infoX = stateIndustry.indConsti.filter(obj=>stateIndustry.mScaleSel.includes(obj[dfin.mScale]) && 
        stateIndustry.industrySel.includes(obj[dfin.gicIndustry]) && 
        stateIndustry.countrySel.includes(obj[dfin.country]));}
    
    if (infoX.length>0){
     
    infoX.sort((a, b) => {
        const marketCapA = a[dfin.marketCap];
        const marketCapB = b[dfin.marketCap];
        return  marketCapB - marketCapA;
    })

    
    dispatchIndustry({"constiInfoAll":infoX})

    }
    else {
        dispatchIndustry({"constiInfoAll":[]})

    }


    },[stateIndustry.indSecMode,stateIndustry.secConsti,stateIndustry.indConsti,
        stateIndustry.mScaleSel,
        stateIndustry.countrySel,
        stateIndustry.sectorSel,stateIndustry.industrySel,stateIndustry.mScaleSel])

    
}

import React, { useEffect, useRef, useState } from 'react'
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine';
import Logo from '../logo/Logo';
import { useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import ProfileLogo from 'components/Logo/ProfileLogo';
import ChatGPTAssistantBar from './ChatGPTAssistantBar';
import OpenAI from 'openai';
import GoalDetails from 'myFinancials/Goals/GoalDetails';
import Ticker from 'pages/dashboard/Ticker';
import { Button } from '@mui/material';
import Modal from 'components/Modal/Modal';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const openai = new OpenAI({
  apiKey:process.env.REACT_APP_OPEN_AI,
  dangerouslyAllowBrowser: true 
})


export default function ChatGPTModal() {
  const {stateApp} = useAppInfo()
  const navigate = useNavigate()
  const [chats,setChats] = useState([])      
  const [chatGptMessage,setChatGptMessage] = useState() 
  const [chatGptModal,setChatGptModal] = useState(false)
  const {displayName,googlePhoto} =  stateApp

  const [loading, setLoading] = useState(false);
    // const [audioUrl, setAudioUrl] = useState([]);
    // const audio = useRef()
    
    async function fetchData(message) {
      try {
        
        setLoading(true);
        const text = await openai.chat.completions.create({
          messages: [{"role": "system", "content":  "You are a friendly and helpful teaching assistant. You explain concepts in finance, economy in great depth using simple terms, and you give examples to help people learn. At the end of each explanation, where appropriate give an example to enhance the understanding."},
          ...chats,{ role: "user", content: message}],
          model: "gpt-4o-mini",
          stream: true,
          // max_tokens:100
        });
        
        // const responseText = text.choices[0].message.content
        const moreDetails = checkForRelatedQuestions(message);
        let fullResponse = ""; // To accumulate the full response

        // Process the streamed chunks
        for await (const chunk of text) {
          const content = chunk.choices[0]?.delta?.content || "";
          fullResponse += content;
    
          // Set chat state progressively
          setChats((prevChats) => [
            ...prevChats.slice(0, prevChats.length - 1),
            { role: "assistant", content: fullResponse },
          ]);
        }
    
        if (moreDetails) {
          setChats((prevChats) => [
            ...prevChats.slice(0, prevChats.length - 1),
            { role: "assistant", content: fullResponse, moreDetails },
          ]);
        }
    
       
        setLoading(false);
        
      } catch (error) {
        console.error(error);
        setLoading(false);
        setChats(prevChats => [...prevChats.slice(0,prevChats.length-1), { role: "assistant", content: "An error occurred. Please try again." }]);
      }
    }

    function handleSend(message) {
        if (message === "" || !message){
          setChats([...chats,{role:"assistant",content:"Hey there, How can I help you today?"}])
         }
        else{
        const updatedChats = [...chats, { role: "user", content: message },{role: "assistant",content:"#?$?#"}];
        setChats(updatedChats);
        fetchData(message);
        // RunAgent(message)
        setChatGptMessage("")
      }
      }
        
      
      
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    const [recording, setRecording] = useState(false);
    const startTranscription = () => {
      if (recording) {
        recognition.stop();
        setRecording(false);
      } else {
        recognition.start();
        recognition.onstart = () => {
          setRecording(true);
        };
        recognition.onresult = (event) => {
          const transcript = event.results[0][0].transcript;
          setChatGptMessage(transcript)
          setTimeout(() => {
            setChatGptModal(true);
            handleSend(transcript); 
          }, 100);
        };
        recognition.onend = () => {
          setRecording(false);
        };
      }
    };

   
  const chatRef = useRef()

  useEffect(()=>{
    chatRef.current?.scrollIntoView({behavior:"smooth"})
  },[chats])
  const [displayContent,setDisplayContent] = useState()
  function handleRelatedQuestionClick(question) {
    const componentToDisplay = relatedQuestions[question];
    // Logic to display the component on the page
    // For example, setting state to render the component
    setDisplayContent(componentToDisplay);
  }
  return (
    <>
    {displayContent && 
    <Modal onClose={()=>setDisplayContent()} width='90%' height='80%'>
    {displayContent}
    </Modal>}
    
    <ChatGPTAssistantBar chatGptModal={chatGptModal} 
      handleSend={handleSend}
      startTranscription={startTranscription}
      chatGptMessage={chatGptMessage}
      recording={recording}
      handleMessage={(v)=>{setChatGptMessage(v);}}
      handleModal={(v)=>setChatGptModal(v)}>
      {chats.length>0 && chats.map((obj,index)=>
      <React.Fragment key={index}>
      <div style={{display:"flex",width:"100%",flexDirection:obj.role==="user"?"row-reverse":"row"}}  ref={chatRef}>
      <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%"}}>
      {obj.role === "user" && <div style={{whiteSpace:"nowrap",display:"flex",flexDirection:"row-reverse"}}> <div style={{display:"flex",alignItems:"center",gap:"0.25rem"}}><ProfileLogo/><strong>{displayName}</strong></div></div>}
      {obj.role !== "user" && <h4 style={{whiteSpace:"nowrap",display:"flex",alignItems:"center",gap:"0.25rem"}}><Logo height='20px' width='20px'/> <strong>Assistant:</strong></h4>}
      
      {obj.content !=="#?$?#" && 
      <div style={{background:"#F8F8F8",borderRadius:"5px"
        ,padding:"1rem",width:"auto",maxWidth:"80%"
      ,marginLeft:obj.role==="user"&&"auto"}}>

      <div>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{obj.content}</ReactMarkdown>
      {(obj.moreDetails) && (
        <Button
          onClick={() =>
            handleRelatedQuestionClick(obj.moreDetails)
          }
        >
          Show More About This Topic
        </Button>
      )}
      </div>
    
      {/* <h4> {obj.content}</h4> */}
    
      </div>}
      {(obj.role !== "user" && obj.content ==="#?$?#" && loading) 
      &&<div style={{borderRadius:"5px",width:"auto",maxWidth:"80%"}}>
       <LoadingSkeletonLine/>
      </div>}
      </div>
      {/* {(obj.role !== "user" && audioUrl.length > 0) && (
        <div style={{marginTop:"0.5rem"}}>
        <audio controls ref={audio}>
          <source src={audioUrl.filter(obj=>obj.message === index)[0]?.url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        </div>
      )}
      */}
      </div><br/></React.Fragment>)}

     
     
      </ChatGPTAssistantBar>
      
    </>
      
    
     
  )
}

function checkForRelatedQuestions(responseText) {
  // Split the responseText into individual words and convert to lowercase
  const words = responseText.toLowerCase().split(/\s+/);

  // Loop through each word and check if it matches any of the questions
  for (const question in relatedQuestions) {
    // Split the question into words and convert to lowercase
    const questionWords = question.toLowerCase().split(/\s+/);

    // Check if any of the words in responseText match the question or if the whole question is found
    for (const word of words) {
      if (questionWords.includes(word) || responseText.toLowerCase().includes(question.toLowerCase())) {
        return question;
      }
    }
  }
  return null;
}

const relatedQuestions = {
  "What are my goals?": <GoalDetails/>,
  "How does Market Work?": <Ticker/>,
  // Add more questions and corresponding components here
};



async function RunAgent(message) {
  const [threadId,setThreadId] =useState()
  const [runId,setRunId] = useState()
  const [run,setRun] = useState(true)
  const assistant_id = "asst_4dgYQE5VxLmm2USxU5zZeZLr"
  const stream = await openai.beta.threads.createAndRun({
    assistant_id: assistant_id,
    thread: {
      messages: [{ role: "user", content: message }],
    },
    tools: [{"type": "retrieval"}],

    stream: true
  });
  // Extract the thread ID from the response
  for await (const event of stream) {
    if (event?.data?.status === "completed"){
      setRun(false)
      let threadId = event?.data?.thread_id
      const threadMessages = await openai.beta.threads.messages.list(
        threadId
      );
      if (threadMessages?.data){
      return threadMessages.data}
    }
    
  }
//   if (run){
//   const threadId = run?.thread_id;
//   const runId = run?.id
//   console.log("Run id",runId,"Thread id ",threadId)
//   // Set the thread ID using state setter function
//   setThreadId(threadId); 
//   setRunId(runId)
// }
}




const generateSpeech = async (assistantCurrentAnswer,index,audio) => {
  try {
    const mp3 = await openai.audio.speech.create({
      model: "tts-1",
      voice: "alloy",
      input: assistantCurrentAnswer,
    });

    // Convert the ArrayBuffer to a Blob
    const blob = new Blob([await mp3.arrayBuffer()], { type: "audio/mpeg" });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    // setAudioUrl([...audioUrl,{message:index,url:url}]);
    audio?.current?.pause();
    audio?.current?.load();
    audio?.current?.play();
  } catch (error) {
    console.error("Error generating speech:", error);
  }
};



//   useEffect(() => {

      
  //     const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  //     const recognition = new SpeechRecognition();
  
  //     recognition.continuous = true;
  //     recognition.interimResults = true; // Enable interim results
  
  //     recognition.onresult = (event) => {
  //         // Handle recognition results
  //         const transcript = event.results[event.results.length - 1][0].transcript;
  //         const lowerCaseTranscript = transcript.trim().toLowerCase();
          
  //                 // Check if transcript starts with any navigation name
  //       navigation.forEach((item) => {
  //         if (lowerCaseTranscript.startsWith(item.name.toLowerCase())) {
  //             navigate(`/${item.link}`);
  //         }
  //     });
  //     if (lowerCaseTranscript.startsWith('stop')) {
  //       audio.current?.pause()
  //     }
  //     // Check for other commands like "hey"
  //     if (lowerCaseTranscript.startsWith('hey')) {
  //         setChatGptMessage(transcript);
  //         setTimeout(() => {
  //           setChatGptModal(true);
  //           handleSend(transcript); 
  //         }, 1000);
  //         // setChatGptModal(true);
  //         // handleSend(transcript);
  //     }
  //     };
      
  //     recognition.onend = () => {
  //       // Restart recognition when it ends
  //       recognition.start();
  //     };
    
  //     recognition.onerror = (event) => {
  //       console.error('Speech recognition error:', event.error);
  //       // Optionally handle the error and decide whether to restart recognition or not
  //     };
    
  //     // Start the recognition process
  //     recognition.start();
    
  //     // Clean up function to stop recognition when the component unmounts
  //     return () => {
  //       recognition.stop();
  //     };
  // }, []);
  
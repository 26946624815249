import React, { useEffect, useState } from 'react'
import EconSeries from './EconSeries'
import { useAppInfo } from 'AppState'

export default function EconSearchSeriesUI({econInfo,seriesName}) {
  const {dispatchEcon} = useAppInfo()

  const [category,setCategory] = useState()
  useEffect(()=>{
    const econSeries = econInfo && econInfo.filter(obj=>obj.id===seriesName)[0]
    setCategory(econSeries?.Category)
  },[seriesName])

  function handleMenuSel(event){
    event.preventDefault(); // Prevents the default behavior of the link
    dispatchEcon({"menuSel":"Macro Econ Indicators","contentSel":category})
  }

  function handleContentSel(event){
    event.preventDefault(); // Prevents the default behavior of the link
    dispatchEcon({"menuSel":"Macro Econ Indicators","contentSel":category})
  }

  return (
    <>
    <div style={{display:"flex",padding:"1rem",gap:"0.5rem"}}>
    <a href='' onClick={handleMenuSel}>Macro Economy Indicators</a> /
<a href='' onClick={handleContentSel}>{category}</a> 
    </div>
    <EconSeries seriesName={seriesName}/>
    </>
  )
}

import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

export default function useSectorOverview() {
    const {stateIndustry,dispatchIndustry} = useAppInfo()
    const [error, setError] = useState(null);
    let sectorSelected = stateIndustry.sectorSel
    if (stateIndustry.sectorSel.includes("All")){
      sectorSelected = stateIndustry.uniqueSectors  
    }  
    useEffect(() => {  
      if (stateIndustry.sectorSel && stateIndustry.countrySel){
        getData()}   
    }, [stateIndustry.sectorSel, stateIndustry.countrySel]);
    
    async function getData() {
      
      try {
        dispatchIndustry({"loadingSecOverview":true})
        const response = await axios.get(`${BASE_URL}/sectorOverview/`, {
          params: {
            sectors: JSON.stringify(sectorSelected),
            country:JSON.stringify(stateIndustry.countrySel)
          },
        });

        dispatchIndustry({"secOverview":JSON.parse(response.data)})
      } catch (error) {
        setError(error);
      } finally {
        dispatchIndustry({"loadingSecOverview":false})
      }
    }
    
}

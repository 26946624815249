import React, { useState } from 'react'
import SelectBox from 'components/Inputs/SelectBox'
import { IconButton, InputBase, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MetricStockModal from 'components/Template/MetricStockModal';
import { useAppInfo } from 'AppState';
import PieChartPeers from 'components/Charts/PieChartPeers';
import { handleObjectDetails } from '../utilsReport';

export default function PieChartPeersUI({data,item,metricSel}) {
  const {stateStock,stateReportBuilder,dispatchReportBuilder} = useAppInfo()
  const {pages,pageCurrent} =  stateReportBuilder
    const numCols = stateStock.metrics.numCols
    
    const [metricSelected,setMetricSelected] = useState(metricSel)
    const [modal,setModal] = useState(false)
    const [modalMetricSelected,setModalMetricSelected] = useState([])
    function handleModal(modalName){
        setModal(!modal)
        
        setModalMetricSelected(metricSelected)

    }
    function handleMetric(e,v){
      setMetricSelected(v)
      const updateContent = {MetricSelected:v}
      handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
    }
  function handleModalMetricValue(v){
      setMetricSelected(v)
      setModalMetricSelected(v)
      const updateContent = {MetricSelected:v}
      handleObjectDetails({contentUpdate:updateContent,pages,pageIndex:pageCurrent,item,dispatchReportBuilder})
    }
    const [mouseEnter,setMouseEnter] = useState(false)
   
    return (
        <div onMouseEnter={()=>setMouseEnter(true)} 
        onMouseLeave={()=>setMouseEnter(false)}
        style={{display:"flex",flexDirection:"column",boxSizing:"border-box",
        height:"100%",width:"100%",boxSizing:"border-box",background:"white",position:"relative"}}>
        {mouseEnter &&
        <div style={{width:"100%",display:"flex",position:"absolute",zIndex:"20",background:"white"}}>
        <SelectBox variant='standard' options={numCols} value={metricSelected} 
        onChange={handleMetric} label={"Metric"} size='large' width='100%'/>
        <Tooltip title="Metric Category"><IconButton  onClick={handleModal}><FormatListBulletedIcon/></IconButton></Tooltip>
        </div>}
    {modal && 
        <MetricStockModal 
        handleModalClose={handleModal} handleMetricSelected={handleModalMetricValue} 
        modalName={"Metrics"} metricSelected={[modalMetricSelected]}/>
      }
    <PieChartPeers title={metricSelected} exporting={false} data={data} 
    metricSelected={[metricSelected]} dataLabel={true}/>
    </div>
  )
}
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function usePerformanceMulti(tickerList, dateFrequency, period) {

  const [perfMultiTable, setPerfMultiTable] = useState([]);
  const [perfLoading, setMarketInfoLoading] = useState(false);

  useEffect(() => {
    getPriceInfo();
  }, [tickerList,dateFrequency,period]);

  async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
      const response = await axios(`${BASE_URL}/performanceMulti/`, {
        params: {
          ticker: JSON.stringify(tickerList),
          dateFrequency: dateFrequency,
          period: period,
        },
      });

      const marketInfo = response.data;
      setPerfMultiTable(JSON.parse(marketInfo));
    } catch (error) {
      console.error(error);
    } finally {
      setMarketInfoLoading(false);
    }
  }
  return { perfMultiTable, perfLoading };
}

import React, { useState } from 'react'
import {
    Badge,
    Button,
    Tooltip,
  } from '@mui/material';
import Modal from 'components/Modal/Modal';
import AlertMyFinancials from './AlertMyFinancials';
import { useAppInfo } from 'AppState';
import { menuIcons } from 'nav/menuIcons';
import AlertSVG from 'svg/AlertSVG';

export default function AlertButton({displayType="main"}) {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {warnings,goalAnalytics,currency} = stateMyFinancials
    const [alertModal,setAlertModal] = useState(false)

  return (
    <>
    {displayType === "main" && <Button variant='highlight' onClick={()=>setAlertModal(true)} 
      sx={{display:"flex",gap:"0.5rem",color:warnings.length>0?"red":"green",fontSize:"0.9rem",justifyContent:"flex-start"}}>
      {warnings.length === 0 && 
      <span style={{color:"green"}}>{"You have no alerts"}</span>
      }
      
      {warnings.length>0  && 
      <>
      {menuIcons?.Alerts}
      <span style={{color:"red"}}>{`You have ${warnings.length} alerts`}</span>
      </>
      }
    </Button>}
    {(displayType === "header" && warnings.length> 0) && <Tooltip title="Alert">
    <Button 
    sx={{minWidth:"0",padding:0,display:"flex"
    ,flexDirection:"column"
    ,textTransform:"none",color:warnings.length>0 && "red"}} onClick={()=>setAlertModal(true)}>
      
      <Badge sx={{color:"red"}}
      overlap="circular"
      badgeContent={warnings.length}>{menuIcons?.Alerts}</Badge>
      <span style={{fontSize:"0.8rem"}}>Alert</span>  
    </Button></Tooltip>}

    {alertModal && 
    <Modal onClose={()=>setAlertModal(false)} width='90%'>
        <div style={{width:"100%",height:"100%",overflow:"auto"}}>
        <AlertMyFinancials/>
        </div>
    </Modal>
    }
    </>
  )
}

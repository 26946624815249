import React, { useEffect, useState } from 'react'
import SelectBox from 'components/Inputs/SelectBox'
import { Alert, Button, Typography } from '@mui/material'
import TooltipInfo from 'components/Modal/TooltipInfo'
import useTopInvestorsList from 'data/financialModelingPrep/TopInvestors/useTopInvestorsList'
import { useAppInfo } from 'AppState'
import { Info } from '@mui/icons-material'

export default function TopInvestorHeader() {
    const [info,setInfo] = useState(false)

    const {statePortfolio,dispatchPortfolio} = useAppInfo()
    const {topInvestorSel,topInvestorsOverview} = statePortfolio
    const {topInvestors} = useTopInvestorsList()
    const topInvestorsList = topInvestorsOverview.length>0 ? topInvestorsOverview.map(obj=>obj.name) : []

    useEffect(() => {
      if (topInvestors) {
        dispatchPortfolio({ topInvestorsOverview: topInvestors });
      }
    }, [topInvestors]);
    
    function handleInvestorSel(v) {
      const investorCIK = topInvestorsOverview.filter(obj => obj.name === v)[0]?.cik;
      dispatchPortfolio({
        topInvestorSel: v,
        topInvestorSelCIK: investorCIK
      });
    }

  return (
    <div className='page_header_box'>
    <h2 style={{fontWeight:"bold"}}>Investor:</h2>
    {topInvestorsList.length>0 && 
 
    <SelectBox width='400px' options={topInvestorsList} 
    value={topInvestorSel}
    variant='outlined'
    onChange={(e,v)=>handleInvestorSel(v)}>

    </SelectBox>}
    
    <Button onMouseEnter={()=>setInfo(true)} onMouseLeave={()=>setInfo(false)} sx={{position:"relative"}}>
        <Info/>
    {info && 
    <TooltipInfo width='400px' top={"100%"} left={"20%"}>
    <div style={{padding:"1rem"}}>
    <Typography sx={{textAlign:"left",textTransform:"none"}}>Information is provided according to the 13F filing with the SEC.</Typography>
    <Alert severity='info' sx={{textAlign:"left",textTransform:"none"}}>
        The 13F Form is a quarterly report filed by institutional investment managers in the United States with at least $100 million in assets under management. This form is required by the Securities and Exchange Commission (SEC) and provides information about the manager's investment holdings. Specifically, the form discloses the manager's long positions in publicly traded stocks, options, and convertible debt securities.
The information provided in the 13F form is intended to promote transparency in the financial markets by allowing investors and the public to see what stocks institutional investors are holding. It can be a valuable tool for tracking the investment strategies of prominent institutional investors such as hedge funds, mutual funds, and pension funds. However, it's important to note that the information in the 13F form is typically filed 45 days after the end of each quarter, so it may not always reflect the most current holdings of these institutions.
</Alert></div></TooltipInfo>}
</Button>
    </div>

  )
}

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Alert,
  Button,
  Paper,
} from '@mui/material';

import { useAppInfo } from 'AppState';
import CheckboxCutom from 'components/Inputs/CheckboxCustom';
import SelectBox from 'components/Inputs/SelectBox';
import { dfin } from 'content/constants';
import {
  DividendToValue,
  LiquidityToVolume,
  RiskToVolatility,
} from 'content/contentInvestmentPreference';
import { menuIcons } from 'nav/menuIcons';
import InvestmentPreferenceModal
  from 'myFinancials/InvestmentPreference/InvestmentPreferenceModal';
import {
  applyFilters,
  createFilterObject,
} from '../screener/utilsScreener';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import ScreenerStocksDataView from './ScreenerStocksDataView';
import LoaderScanner from 'components/Loading/LoaderScanner';
import LoadingSkeletonChart from 'components/Loading/LoadingSkeletonChart';
import useSetTimeoutRender from 'useSetTimeoutRender';

export default function PortfolioFit() {
  const {dispatchMyFinancials,stateMyFinancials,stateScreener,stateOpportunities} = useAppInfo()
  const {dataView} = stateOpportunities
  const {investmentPreference,investmentPreferenceRiskTolerance,investmentPreferenceType,marketRegion,primaryMarketRegion} = stateMyFinancials 
  const [assetClasses,setAssetClasses] = useState([])
  const [assetClassSel,setAssetClassSel] = useState("")
  const [stocks,setStocks] = useState([])

  useEffect(()=>{
    if (investmentPreference && investmentPreferenceRiskTolerance && marketRegion){
    let investmentFilter = investmentPreferenceType?.type === "Manual" ? investmentPreference:investmentPreferenceRiskTolerance
    const assetClassesAvailable = investmentFilter.filter(obj=>obj["selected"]===true).map(obj=>obj.type)
    setAssetClasses(assetClassesAvailable)
    setAssetClassSel(assetClassesAvailable[0])
    const investmentStocks = investmentFilter.filter(obj=>obj["type"]==="Stocks" && obj["selected"]===true)[0]
    const investmentStockCriteria = investmentStocks?.["criterias"]
    const riskLevel =  RiskToVolatility(investmentStocks?.["risk"])
    const liquidityLevel = LiquidityToVolume(investmentStocks?.["liquidity"])
    const dividendLevel = DividendToValue(investmentStockCriteria?.["Dividend"])
    const sector = investmentStockCriteria?.["Sector"]
    const industry = investmentStockCriteria?.["Industry"]
    const mScale = investmentStockCriteria?.["Market Cap"]
      
    const filterArrays = {
      ...createFilterObject(dfin.country, "multiSelect", marketRegion),
      ...createFilterObject(dfin.gicSector, "multiSelect", sector),
      ...createFilterObject(dfin.gicIndustry, "multiSelect", industry),
      ...createFilterObject(dfin.mScale, "multiSelect", mScale),
      ...createFilterObject(dfin.dividendYield, "greaterThan", dividendLevel),
      ...createFilterObject(dfin.dividendYield, "greaterThan", dividendLevel),
      ...createFilterObject(dfin.volume, "greaterThan",1000),
      ...createFilterObject(dfin.pe, "lessThan",80),
      ...createFilterObject(dfin.fiancialRating, "greaterThan",6),
    };
  
    if (stateScreener.stocks.length>0 && investmentStocks && investmentStockCriteria){
      const filteredTable = applyFilters(filterArrays,stateScreener.stocks)
      setStocks(filteredTable.sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]));
    }
    else{
      setStocks([])
    }  
  } 
  },[investmentPreference,
    investmentPreferenceRiskTolerance,investmentPreferenceType,marketRegion,stateScreener.stocks])

  
  const [investmentPreferenceModal,setInvestmentPrefernceModal] = useState(false)
  



  function handleCashAvailable(){

  }
  
  function handleDiversification(){

  }

  useSetTimeoutRender()
  return (
    <>
    <div style={{display:"flex",gap:"0.5rem",width:"100%",height:"100%",boxSizing:"border-box"}}>
    <LeftSidebarFloating header={"Criterias"}>
    <div style={{display:"flex",gap:"1rem",flexDirection:"column"}}>
    <Button startIcon={menuIcons?.Filter} 
    variant={"outlined"}
    sx={{textTransform:"none",textAlign:"left",fontSize:"1rem",whiteSpace:"nowrap",textOverflow:"ellipsis",overflow:"hidden",minWidth:0}}
    onClick={()=>setInvestmentPrefernceModal(true)}> Investment Preferences</Button>
  
    <SelectBox variant='outlined' label={"Asset Class"} options={assetClasses} value={assetClassSel} onChange={(e,v)=>setAssetClassSel(v)}/>
    <CheckboxCutom defaultChecked={true} label={"Consider Net Cash Available (for year)"} onChange={handleCashAvailable}/>
    <CheckboxCutom defaultChecked={true} label={"Diversification is Important"} onChange={handleDiversification}/>
    </div>
    </LeftSidebarFloating>
    <Paper elevation={dataView==="Information"?0:3} style={{flex:1,overflow:"auto"}}>
      {assetClasses.length === 0 && 
      <Alert severity='warning'>You currently have no Asset Class Selected. 
      Please select asset classes and set preferences.<br/><br/>
      <Button variant='contained' startIcon={menuIcons?.Filter} 
    sx={{textTransform:"none",textAlign:"left",fontSize:"1rem"}}
    onClick={()=>setInvestmentPrefernceModal(true)}> Investment Preferences</Button>
      </Alert>}

      {assetClassSel && 
      assetClassSel != "Stocks" && 
      <>
      <Alert severity='warning'>Sorry, currently portfolio fit is available only for stocks.</Alert>
      <LoadingSkeletonChart/>
      </>
      }
      {assetClassSel && assetClassSel === "Stocks" && stocks.length===0 && <LoaderScanner/>}
      {(assetClassSel && 
      assetClassSel === "Stocks" && stocks.length>0) && 
      <ScreenerStocksDataView dataView={dataView} data={stocks} 
      tableData={getSpecificArrayObjKeys(stocks,stockCols)}/>
      // <TableGen logo={true} data={getSpecificArrayObjKeys(stocks,stockCols)}/>
      }

    </Paper>
    </div>
    {investmentPreferenceModal && 
      <InvestmentPreferenceModal onClose={()=>setInvestmentPrefernceModal(false)}/>}
    </>
  )
}

const stockCols = [dfin.coLogo,dfin.ticker,dfin.coName,dfin.country,dfin.marketCap,dfin.fiancialRating,dfin.growthRating]

const stockTable = [
  {}
]

function ScreeningCriterias(){
  const {dispatchMyFinancials,stateMyFinancials,stateScreener} = useAppInfo()
  const {investmentPreference,
    investmentPreferenceRiskTolerance,investmentPreferenceType,marketRegion,primaryMarketRegion} = stateMyFinancials 
  let investmentFilter = investmentPreferenceType?.type === "Manual" ? investmentPreference:investmentPreferenceRiskTolerance
  
  const [stocks,setStocks] = useState([])

  useEffect(()=>{
    const investmentStocks = investmentFilter.filter(obj=>obj["type"]==="Stocks" && obj["selected"]===true)[0]
    const investmentCriteria = investmentStocks?.["crietras"]

    if (stateScreener.stocks && investmentStocks){
      const filteredTable = stateScreener.stocks
      .filter(obj=> marketRegion.includes(obj[dfin.country]))
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]);
      setStocks(filteredTable);
    }
    else{
      setStocks([])
    }
  },[investmentFilter])

  const assetClasses = []
  const volatility = RiskToVolatility("Low")

  return stocks

}


import { useAppInfo } from 'AppState'
import React, { useEffect, useState } from 'react'
import CheckboxCutom from 'components/Inputs/CheckboxCustom'
import BorderBox from 'components/Layout/BorderBox'
import CashflowChart from 'myFinancials/Cashflow/CashflowChart'

export default function GoalFunding() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {goalAnalytics,cashflowDistribution} = stateMyFinancials
    const {goalDist,netGoalCashflowDist,netGoalAferWithdrawal} = cashflowDistribution
    const [chartData,setChartData] = useState([])

    const {perGoalDetails,goalFunding} = goalAnalytics
    const postWithdrawal = goalFunding === "netCashAfterWithdrawal" ? true : false
    
    useEffect(()=>{
        if (netGoalCashflowDist && !postWithdrawal){
            setChartData(netGoalCashflowDist)
        }
        if (netGoalAferWithdrawal && postWithdrawal){
          setChartData(netGoalAferWithdrawal)
        }
      },[netGoalCashflowDist,netGoalAferWithdrawal,postWithdrawal])
      
      
    function handleGoalFunding(v){
        dispatchMyFinancials({goalAnalytics:{...goalAnalytics,goalFunding:v}})
      }
    
  return (
    <>
    <CheckboxCutom label={"Net Cashflow"} 
        defaultChecked={!postWithdrawal} 
        onChange={()=>handleGoalFunding("netCashBeforeReturn")}/>
        <CheckboxCutom 
        label={"Net Cashflow and Withdrawing Savings & Investments"} 
        defaultChecked={postWithdrawal} onChange={()=>handleGoalFunding("netCashAfterWithdrawal")}/>
            <div style={{height:"400px",minWidth:"400px"}}>
      
      <BorderBox>
      {goalDist && 
      <CashflowChart 
      data={[...goalDist,...chartData]}
      title={postWithdrawal? `
        Meeting Goals after withdrawing from Savings/Investments`:`Meeting Goals from Net Cashflow`}
      />}
      </BorderBox>
      </div>
      </>
  )
}

import styles from "./TabRow.module.css"
import React,{useState,useEffect} from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAppInfo } from "AppState";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Tab, Tabs, Tooltip, Typography } from "@mui/material";


export default function TabRow({tabList,tabContent=[],content=[],styleContent={},tabIndex,
  handleTabChange,width="100%"}) {


  const [tabIndexInternal, setTabIndex] = useState(0);
  useEffect(()=>{
    if (tabIndex){
      setTabIndex(tabIndex)
    }
  },[tabIndex])


  function handleTabChangeInternal(index) {
    setTabIndex(index);
    if (typeof handleTabChange === 'function') {
      handleTabChange(index);
  }
  }


  const [showTab,setShowTab] = useState(true)
  function handleShowTab(){
    setShowTab(!showTab)
  }

  const [showExpander,setShowExpander] = useState(false)

  
  return (
<div 
className={styles["tab-container"]} 
style={{width:(!showTab && tabContent.length===0) ? "0":width}}>
  {/* <ResizableRight orientV={true}> */}
  <div 
  onMouseEnter={()=>setShowExpander(true)} 
  onMouseLeave={()=>setShowExpander(false)} 
  id={"tabListRow"} className={styles["tab"]} 
  style={{width:!showTab ? "0px":tabContent.length===0&& "100%",
          
          position:"relative",overflow:"visible"}}>

{(showExpander || !showTab) &&
<div style={{position:"absolute",
 borderRadius:"0px 10px 10px 0px",
transform: "translateY(-50%)",
display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",
top:"50%",right:"-15px",height:"100px",width:"15px",background:"#F8F8F8"
,zIndex:"1000",color:"black",marginBottom:"15px"}}>
  
  {showTab && <ExpandLessIcon sx={{transform:"rotate(-0.25turn)"}} onClick={()=>handleShowTab(true)}/>}
  {!showTab &&  
  <Tooltip title="Expand Menu">
  <ExpandMoreIcon onClick={()=>handleShowTab(true)} 
  sx={{transform:"rotate(-0.25turn)"}}/></Tooltip>}
</div>}

    {showTab && 
    // <Tabs  orientation="vertical"
    // variant="scrollable">
    tabList.map((tabs, index) => (
           <Button
        style={{color:"black"}}
        key={`tab-${tabs}`}
        onClick={() => handleTabChangeInternal(index)}
        value={index}
        label={tabs}
        className={`${styles.button} ${tabIndexInternal === index && styles.activeButton}`}
      >
        <Typography sx={{textTransform:"none",whiteSpace:"pre-wrap",fontWeight:tabIndexInternal === index&&"bold"}}>{tabs}</Typography>
      
      </Button>
    ))
    
    // </Tabs>
    }

    
  </div>
  {/* </ResizableRight> */}
  {tabContent.length>0 && 
  <div className={styles["tab-content"]} style={styleContent}>
  {tabContent[tabIndexInternal]}

  </div>}
</div>
  ) 
}

import React from 'react'
import { dfin } from 'content/constants';
import { PortfolioFitStock } from './PortfolioFitTicker';
import TickerPriceBar from 'opportunities/TickerPriceBar';
import BorderBox from 'components/Layout/BorderBox';
import CompanyValueScore from 'analytics/ticker/stocks/Overview/CompanyValueScore';
import CompanyFinancialScore from 'analytics/ticker/stocks/Overview/CompanyFinancialScore';
import CompanyESGScoreBar from 'analytics/ticker/stocks/Overview/CompanyESGScoreBar';
import { Alert } from '@mui/material';
import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import LoadingSkeletonPage from 'components/Loading/LoadingSkeletonPage';
import DetailTradeTickerButton from 'analytics/ticker/DetailTradeTickerButton';
import useRealTimePrice from 'data/eod/useRealTimePrice';
import CompanyComments from 'analytics/ticker/stocks/Overview/CompanyComments';

export default function TickerCard({data}) {
  const { price } = useRealTimePrice({ tickerSel: data?.[dfin.tickerCode] });
  const { priceInfo, loadingPriceInfo } = usePriceChartEod({tickerSel: data?.[dfin.tickerCode]});

  return (
    <BorderBox sx={{height:"auto",display:"flex",flexDirection:"column",gap:"1rem"}}>
      
       <TickerPriceBar price={price} data={data}/>
       <div style={{display:"flex",gap:"1rem",width:"100%",flexWrap:"wrap"}}>
       
       <div style={{minWidth:"400px",flex:1,position:"relative",height:"400px",display:"flex",flexDirection:"column"}}>
      
       <div style={{flex:1,position:"relative"}}>
       {loadingPriceInfo && 
       <LoadingSkeletonPage variant="rect" width="100%" height="100%"/>}
       
       {priceInfo.length > 0 && !loadingPriceInfo && (
        <>
        <div style={{position:"absolute",top:"50px",left:"5px",zIndex:100}}>
        <PortfolioFitStock coInfo={data} displayType='large'/>
        </div>
       <TimeSeriesChart
                tickerChart
                datePicker={false}
                chartType="area"
                color={"green"}
                seriesName="adjusted_close"
                data={getSpecificArrayObjKeys(priceInfo, [
                  "date",
                  "adjusted_close",
                ])}
              /></>)}</div>
              <div style={{marginTop:"auto"}}>
            <DetailTradeTickerButton buttonType='outlined' fontSize='0.8rem' ticker={data?.[dfin.ticker]}/>
            </div>
        </div>
        {/* <TickerCardSparkline close={tickerPrice} 
        tickerDict={{ [data?.[dfin.ticker]]:data?.[dfin.coName]}}
        title={false}
        /> */}

        <div style={{gap:"0.5rem"
          ,minWidth:"400px",flex:1}}>
            <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
        <CompanyComments tickerList={[data?.[dfin.ticker]]}/>
        <CompanyFinancialScore coInfo={data}/>
        <CompanyValueScore coInfo={data}/>
        <CompanyESGScoreBar coInfo={data}/></div>
        </div>
      </div>
        

        
    </BorderBox>
  )
}

import React from 'react'
import styles from "./LoaderScanner.module.css"
import { Typography } from '@mui/material';
import Animation from 'animation/Animation';

export default function LoaderScanner() {
  return (
    <div className={styles.loader}>
    {/* <div className={styles.scanner}>
      <Logo height='100%' width='100%'/>
    </div> */}
    <Animation type='Searching'/>
    <Typography>Searching Opportunities</Typography>
  </div>
  )
}

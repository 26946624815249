import React, { useState } from 'react'
import VolatilityMulti from './VolatilityMulti'
import PerfVolaTable from './PerfVolaTable'
import TabLine from 'components/Tabs/TabLine'

export default function Volatility({close,marketInfoLoading,tickerList,tickerDict}) {

const tabContent = []
const [tabIndex,setTabIndex] = useState(0)
tabContent[0] = <PerfVolaTable type='Volatility' 
tickerDict={tickerDict} close={close} marketInfoLoading={marketInfoLoading}/>
tabContent[1]=<VolatilityMulti tickerDict={tickerDict} tickerList={tickerList}/>
function handlePerfTabChange(index){
  setTabIndex(index)
}
  return (
    <div className='containerColumnFlexNoGap'>
    <TabLine
    tabList={["Periodic","Seasonality"]} tabIndex={tabIndex} handleTabChange={handlePerfTabChange}/>
    <div className='conatinerColumnFlex1'> 
    {tabContent[tabIndex]}
    </div>
    </div>
  )
}



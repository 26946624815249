import { useAppInfo } from 'AppState'
import ProfileLogo from 'components/Logo/ProfileLogo'
import React from 'react'
import PortfolioAccountSelection from './Portfolio/PortfolioAccountSelection'

export default function ReportMoreHeaderDetails({reportType="Portfolio"}) {
    const {stateApp} = useAppInfo()

    const headerDetails = {
        "Portfolio":<><ProfileLogo/> 
        Portfolio
       <PortfolioAccountSelection/></>,
       "My Financials":<><ProfileLogo/> Financials Overview</>
    }
  return (
    <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
        {headerDetails[reportType]}
    </div>
  )
}


import React from 'react'
import { useEffect, useState } from "react";
import { financialModelingPrepApiKey } from "data/keys";
import axios from 'axios';

export default function useTopInvestorsPortfolioHoldingIndustry(cik,date) {
    const [topInvestorHoldingIndustry, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(
            `
            https://financialmodelingprep.com/api/v4/institutional-ownership/industry/portfolio-holdings-summary?cik=${cik}&date=${date}&apikey=${financialModelingPrepApiKey}

            `
          );
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
        finally{ 
        setLoading(false)
        }
      };
      if (cik,date){
     fetchData();}
    }, [cik,date]);
  
    return { topInvestorHoldingIndustry };
  }
import React from 'react';

import { menuIcons } from 'nav/menuIcons';
import PortfolioHeader from './Portfolio/PortfolioHeader';
import PortfolioInfoList from './Portfolio/PortfolioInfoList';
import MyFinancialsOverview from './MyFinancialsOverview';
import MyFinancialsOverviewHeader from './MyFinancialsOverviewHeader';
import AlertMyFinancials from './AlertMyFinancials';
import CashflowHeader from './Cashflow/CashflowHeader';
import CashflowInfo from './Cashflow/CashflowInfo';
import ExpenseInfo from './Cashflow/ExpenseInfo';
import GoalInfo from './Goals/GoalInfo';
import InvestmentPreferences
  from './InvestmentPreference/InvestmentPreferences';
import AssetInfo from './Asset/AssetInfo';
import LiabilityInfo from './Liability/LiabilityInfo';
import NetworthHeader from './NetWorth/NetworthHeader';
import RiskManagementHeader from './RiskManagement/RiskManagementHeader';
import RiskManagementInfo from './RiskManagement/RiskManagementInfo';
import ScenarioInfo from './Scenario/ScenarioInfo';
import MyFinancialsHeaderTemplate from './Template/MyFinancialsHeaderTemplate';
import NetWorth from './NetWorth/NetWorth';
import MyFinancialHealthCard from './MyFinancialHealthCard';
import Income from './Cashflow/Income';

export default function MyFinancialsInfo({infoType="content",mainMenu,menu,subMenu}) {

  const infoList = {
    "Overview": {
        content: <MyFinancialsOverview/>,
        header: <MyFinancialsOverviewHeader/>
    },
    "Alerts": {
        content: <AlertMyFinancials/>,
        header: "Alerts and Notifications"
    },
    "Networth": {
        content: <NetWorth info={menu}/>,
        header: <NetworthHeader title='Networth' type='Networth'/>
    },
    "Portfolio": {
        content: <PortfolioInfoList menu={menu} subMenu={subMenu}/>,
        header: <PortfolioHeader/>
    },
    "Assets": {
        content: <AssetInfo info={menu} displayType='sidebar'/>,
        header: <NetworthHeader title='Assets' type='Assets'/>
    },
    "Liabilities": {
        content: <LiabilityInfo info={menu} displayType='sidebar'/>,
        header: <NetworthHeader title='Liabilities' type='Liabilities'/>
    },
    "Cashflow": {
        content: <CashflowInfo menu={menu} subMenu={subMenu}/>,
        header: <CashflowHeader/>
    },
    "Income": {
        content: <Income/>,
        header: "Income Breakdown"
    },
    "Expense": {
        content: <ExpenseInfo info={subMenu}/>,
        header: "Expenses Tracking"
    },
    "Goals": {
        content: <GoalInfo info={menu}/>,
        header: <MyFinancialsHeaderTemplate icon={menuIcons?.Goals} name="Financial Goals"/>
    },
    "Financial Health":{
        content: <MyFinancialHealthCard/>,
        header: <MyFinancialsHeaderTemplate icon={menuIcons?.Health} name="Financial Health"/>
    },
    "Scenarios": {
        content: <ScenarioInfo info={menu}/>,
        header: <></>
    },
    "Investment Preferences": {
        content: <InvestmentPreferences info={menu}/>,
        header:<MyFinancialsHeaderTemplate icon={menuIcons?.InvestmentPreferences} name="Investment Preferences"/>
    },
    "Risk Management": {
        content: <RiskManagementInfo info={menu}/>,
        header: <RiskManagementHeader/>
    },
}
  return (
    <>{infoList?.[mainMenu]?.[infoType]}</>
  )
}


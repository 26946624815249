import React from 'react'
import { useAppInfo } from 'AppState'
import usePriceTarget from 'data/financialModelingPrep/usePriceTargets'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"

export default function PriceTargets() {
  const {stateTicker} = useAppInfo()
  const {priceTarget} = usePriceTarget(stateTicker.tickerSelected)
  const tableData = priceTarget.length > 0 && getSpecificArrayObjKeys(priceTarget,cols)
  return (
    <>
    {priceTarget.length > 0 && 
    <TableGen tableLayout='fixed' data={tableData}/>}
    </>
  )
}

const cols = ["publishedDate","analystName","analystCompany",
    "priceTarget",
    "adjPriceTarget",
    "priceWhenPosted"]
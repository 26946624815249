import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useDeleteWatchlistTicker = (watchlistSelected) => {
  const {stateApp} = useAppInfo()
  const userId = stateApp.userId

  const [error, setError] = useState(null);
  
  const deleteWatchlistTicker = async (watchlistTickers) => {
    try {
      const response = await axios.post(`${BASE_URL}/deleteTickerWatchlist/`, {
        userId,
        watchlistName:watchlistSelected,
        watchlistTickers:watchlistTickers,
      });

      setError("Success")
    } catch (err) {
      setError('Error adding Watchlist data');
    }
  };

  return { deleteWatchlistTicker, error };
};

export default useDeleteWatchlistTicker;
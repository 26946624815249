import React from 'react'
import { useAppInfo } from 'AppState';
import DeleteIcon from '@mui/icons-material/Delete';
import useDeleteWatchlist from 'data/user/watchlist/useDeleteWatchlist';

export default function WatchListMenu() {

 const { dispatchTicker, stateWatchlist, dispatchWatchlist } = useAppInfo();
    
    const {watchlistSelected,watchlistData} = stateWatchlist;
    
    const watchlistNames = Object.keys(watchlistData);
    const { deleteWatchlist, error } = useDeleteWatchlist();
    

    function handleWl(e, i) {
        dispatchTicker({"peersIn":"CUSTOM" });
        dispatchWatchlist({
            watchlistSelected: i,
            watchlistMenu: !stateWatchlist.watchlistMenu 
        });

    }

    function handleDelete(e, item) {
        deleteWatchlist(item);
        const updatedWatchlistData = { ...watchlistData };
        delete updatedWatchlistData[item];
        dispatchWatchlist({
            watchlistData: updatedWatchlistData,
            watchlistSelected: Object.keys(updatedWatchlistData)[0], // Select the first item or set to null if empty
        });
    }

    const defaultListContent = watchlistNames && watchlistNames.map((item) => (
        <tbody key={item}>
            <tr style={item === watchlistSelected ? { border: "2px solid blue" } : {}}>
                <td onClick={(e) => handleWl(e, item)} style={{ textAlign: "left" }}>
                    <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{item}</div>
                </td>
                <td>
                    <DeleteIcon fontSize='small' style={{ marginRight: "auto" }} onClick={(e) => handleDelete(e, item)} />
                </td>
            </tr>
        </tbody>
    ));

  return (
    <div style={{ display: "flex", flexDirection: "column", color: "black" }}>
        {(stateWatchlist.watchlistMenu && watchlistNames.length > 0) &&
            <div style={{ height: "auto", overflow: "auto" }}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    {defaultListContent}
                </table>
            </div>}
    </div>
  );
}

const currencyCategory = {
    World: ['USD', 'EUR', 'JPY', 'CNY', 'INR', 'CAD', 'AUD', 'CHF'],
    'North America': ['CAD', 'USD', 'MXN'],
    'South America': ['ARS', 'BOB', 'BRL', 'CLP', 'COP','GYD', 'PYG', 'PEN', 'SRD', 'UYU', 'VES'],
    Europe: ['EUR',  'GBP', 'CHF', 'SEK', 'DKK', 'NOK',  'PLN', 'CZK', 'HUF', 'RON'],
    Asia: ['CNY', 'INR', 'JPY', 'KRW', 'IDR', 'SAR', 'TRY'],
    Australia: ['AUD', 'NZD'],
    Africa: ['ZAR', 'NGN', 'KES', 'EGP', 'DZD', 'MAD'],
   
  };

export {currencyCategory}
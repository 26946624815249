import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import HomePageTemplate from '../../home/HomePageTemplate';

export default function Contact() {
  return (
    <HomePageTemplate pageName='Contact'>
    <div style={{display:"flex",gap:"2rem",marginTop:"1rem"
    ,flexDirection:"column",alignItems:"center",padding:"1rem",background:"#F8F8F8",borderRadius:"5px"}}>
  <div style={{display:"flex",gap:"2rem"}}>
  <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="mailto:admin@takestock.ai?subject=Inquiry&body=Hello%20there"
    style={{
      fontSize: "2rem",
      color: "black"
    }}
  >
    <EmailIcon fontSize="large" />
  </a>
  <h3 style={{color:"black"}}>admin@takestock.ai</h3>
  </div>

  <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="tel:+17782234263"
    style={{
      fontSize: "2rem",
      color: "black"
    }}
  >
    <PhoneIcon fontSize="large" />
  </a>
  <h3 style={{color:"black"}}>+1 778-223-4263</h3>
  </div>
  </div>
  <div style={{display:"flex",gap:"2rem",alignItems:"center"}}>
  <a
    href="https://twitter.com/TakeStockai"
    target='blank'
    style={{
      fontSize: "2rem",
      color: "black",
      display:"flex",
      gap:"1rem"
    }}
  >
     <XIcon style={{fontSize:"3rem"}} />
     
  </a>
  <a
    href="https://www.instagram.com/takestockai/"
    target='blank'
    style={{
      fontSize: "2rem",
      color: "black",
      display:"flex",
      gap:"1rem"
    }}
  >
    <InstagramIcon style={{fontSize:"3rem"}} />
  </a>
  <a
    href="https://www.youtube.com/@TakeStockai"
    target='blank'
    style={{
      fontSize: "2rem",
      color: "black",
      display:"flex",
      gap:"1rem"
    }}
  >
     <YouTubeIcon style={{color:"red",fontSize:"4rem"}}  />
     
  </a>
  </div>
  </div>
  </HomePageTemplate>
  
  )
}

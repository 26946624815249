import React, { useEffect, useState } from 'react'
import NewsEventsInfo from 'newsEvents/NewsEventInfo'
import SelectMarket from 'components/Inputs/SelectMarket'
import {menuNewsEvents } from 'nav/menu'
import { useAppInfo } from 'AppState'
import LeftSidebar from 'sidebar/LeftSidebar/LeftSidebar'
import LeftSidebarPageMenu from 'sidebar/LeftSidebar/LeftSidebarPageMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { Paper } from '@mui/material'

export default function News() {
  const { stateNews, dispatchNews } = useAppInfo()
  const [contentList, setContentList] = useState([])
  const {menuSel,contentSel } = stateNews
  const [menuOptions, setMenuOptions] = useState(menuNewsEvents)
  const [mainMenuSel, setMainMenuSel] = useState("News")
  const location = useLocation()
  const pathName = location.pathname
  const subPage = pathName.split("/")[2]

  useEffect(() => {
    if (subPage) {
      const menuSel = menuNewsEvents.filter(obj => obj.link === subPage)[0]?.name
      setMainMenuSel(menuSel)
    } else {
      setMainMenuSel("News")
    }
  }, [subPage])

  useEffect(() => {
    const mainMenuOptions = menuNewsEvents.filter(obj => obj.name === mainMenuSel)[0]
    let menuContent = mainMenuOptions?.menuOptions || []
    let contentList = []
    if (menuContent.length === 0) {
      dispatchNews({menuSel: mainMenuSel })
      setMenuOptions([])
    } else {
      const menuOptionSel = menuContent.map(obj => obj.name)[0]
      const contentList = menuContent.filter(obj => obj.name === menuOptionSel)[0]?.content
      dispatchNews({
        menuSel: menuOptionSel,
        contentSel: contentList.length > 0 ? contentList[0] : ""
      })
      setMenuOptions(menuContent)
    }
    setContentList(contentList)
  }, [mainMenuSel])

  function handleMenuSel(v) {
    const infoSel = menuOptions.filter(obj => obj.name === v).map(obj => obj.content)
    dispatchNews({
      menuSel: v,
      contentSel: infoSel[0].length > 0 ? infoSel[0][0] : ""
    })
  }

  function handleContentSel(n, v) {
    dispatchNews({
      menuSel: n,
      contentSel: v
    })
  }


  return (
    <>
      {/* <LeftSidebar
        name={"News/Events"}
        contentSel={mainMenuSel}
        menuOptions={menuNewsEvents} /> */}

      <div className='pageContentWrapper'>
          <div className="page_header_box">
            <div className='page_header_title_box'>
              <SelectMarket />
              <h2>{mainMenuSel}</h2>
            </div>
          </div>

          <div className='containerLeftMenuAndContent'>
            {menuOptions.length > 0 &&
              <LeftSidebarPageMenu
                bigMenu={false}
                name={"Menu"}
                menuOptions={menuOptions}
                menuSel={menuSel}
                contentSel={contentSel}
                handleContentSel={handleContentSel}
                handleMenuSel={handleMenuSel}
              />}
            <Paper elevation={0} className='containerMainContent'>
              <NewsEventsInfo menuSel={mainMenuSel} subMenuSel={menuSel} />
            </Paper>
          </div>
      </div>
    </>
  )
}

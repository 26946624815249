import { useState, useEffect, useRef } from 'react';

function useInView(options) {
  const ref = useRef(null);
  const [isInView, setInView] = useState(false);

  useEffect(() => {
    const observedElement = ref.current;
    if (!observedElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1, ...options } // Adjust threshold as needed
    );

    observer.observe(observedElement);

    return () => {
      observer.unobserve(observedElement);
    };
  }, [options]);

  return [ref, isInView];
}

export default useInView;
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import { DividendToValue, LiquidityToVolume, RiskToVolatility } from 'content/contentInvestmentPreference'

export default function usePortfolioFitStocks(coInfo) {
    const {stateMyFinancials,stateStock} = useAppInfo()
    const {marketRegion,primaryMarketRegion, investmentPreferenceType,investmentPreference,investmentPreferenceRiskTolerance} = stateMyFinancials 
    // const {coInfo} = stateStock

    let criteriaScores = {
      "Asset Class":0,
      "Market Region": 0,
      MarketCap: 0,
      Sector: 0,
      Industry: 0,
      Dividend: 0,
      Risk:0,
      Liquidity: 0,
      Financials: 0,
      Technicals:0,
    };
 
    function calculateScore() {
      let score = 0;
    
      // Destructure the necessary properties from coInfo
      const {
        [dfin.gicSector]: coSector,
        [dfin.gicIndustry]: coIndustry,
        [dfin.mScale]: coMarketCap,
        [dfin.dividendYield]: coDividend,
        [dfin.country]: coMarketRegion,
        [dfin.volume]: coVolume,
        [dfin.fiancialRating]: coFinancials,
      } = coInfo || {};
    
      let investmentFilter = investmentPreferenceType?.type === "Manual" ? investmentPreference : investmentPreferenceRiskTolerance;
    
      // Filter and destructure investmentStocks properties
      const investmentStocks = investmentFilter.find(obj => obj.type === "Stocks" && obj.selected === true);
      if (!investmentStocks) return { score, criteriaScores: {} };
    
      const { criterias: investmentStockCriteria, risk, liquidity } = investmentStocks;
    
      // Convert criteria values
      const riskLevel = RiskToVolatility(risk);
      const liquidityLevel = LiquidityToVolume(liquidity);
      const dividendLevel = DividendToValue(investmentStockCriteria?.Dividend);
      const sector = investmentStockCriteria?.Sector;
      const industry = investmentStockCriteria?.Industry;
      const mScale = investmentStockCriteria?.["Market Cap"];
  
      if (investmentStocks){
        score += 1;
        criteriaScores['Asset Class'] = 1
      }
      if (investmentStocks){
        score += 1;
        criteriaScores['Risk'] = 1
      }
      // Increment score based on sector and industry
      if (sector.includes(coSector) || sector.includes("All")) {
        score += 1;
        criteriaScores.Sector = 1;
      }
      if (industry.includes(coIndustry) || industry.includes("All")) {
        score += 1;
        criteriaScores.Industry = 1;
      }
      if (mScale.includes(coMarketCap) || mScale.includes("All")) {
        score += 1;
        criteriaScores.MarketCap = 1;
      }
      if (marketRegion && marketRegion.includes(coMarketRegion)) {
        score += 1;
        criteriaScores["Market Region"] = 1;
      }
      if (dividendLevel && coDividend >= dividendLevel) {
        score += 1;
        criteriaScores.Dividend = 1;
      }
      if (liquidityLevel && coVolume >= liquidityLevel) {
        score += 1;
        criteriaScores.Liquidity = 1;
      }
      if (coFinancials > 4) {
        score += 1;
        criteriaScores.Financials = 1;
      }
      else if (coFinancials > 6) {
        score += 2;
        criteriaScores.Financials = 2;
      }
      else if (coFinancials > 8) {
        score += 3;
        criteriaScores.Financials = 3;
      }
    
      return { score, criteriaScores };
    }

    const [portfolioFitScore,setPortfolioFitScore] = useState(0)
    const [portfolioFitCriteria,setPortfolioFitCriteria] = useState()

    useEffect(()=>{
      if (investmentPreference && investmentPreferenceRiskTolerance && marketRegion){
          const {score,criteriaScores} = calculateScore()
          setPortfolioFitScore(score)
          setPortfolioFitCriteria(criteriaScores)
      }
    },[coInfo,investmentPreference && investmentPreferenceRiskTolerance && marketRegion,investmentPreferenceType])
    
    let totalScore = Object.keys(criteriaScores).length
    const completionPercentage = (portfolioFitScore/totalScore)*100
  return {portfolioFitScore,totalScore,completionPercentage,portfolioFitCriteria}
}

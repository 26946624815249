import { Button, Typography } from '@mui/material'
import MarketToday from 'analytics/market/MarketToday'
import { useAppInfo } from 'AppState'
import TextWithBorders from 'components/Font/TextWithBorders'
import InfoBox from 'components/Layout/InfoBox'
import MyFinancialCard from 'myFinancials/MyFinancialCard'
import { menuIcons } from 'nav/menuIcons'
import React from 'react'
import { useNavigate } from 'react-router-dom'


export default function HomePage() {
  const {stateApp} = useAppInfo()
  const {username} = stateApp
  const navigate = useNavigate()

  const greeting = getGreeting()


  return (
    <>
    <div style={{display:"flex",flexDirection:"column"}}>
     
      {/* <ImageWithTextBottom text={username}
      imageUrl={"https://images.unsplash.com/photo-1445116572660-236099ec97a0?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
      ></ImageWithTextBottom> */}

      <div style={{display:"flex",gap:"2rem",padding:"2rem",
        flexWrap:"wrap",width:"100%",boxSizing:"border-box",minHeight:"90vh"}}>
      
      <div style={{minWidth:"350px",display:"flex",gap:"1rem",maxWidth:"350px",flexDirection:"column",height:"max-content"}}>
      <MyFinancialCard/>
      </div>
     

      <div style={{ flex: 1,display: 'flex', 
        flexDirection: 'column', gap: '2rem',boxSizing:"border-box",minWidth:"700px"}}>
        

        <Typography variant='h5' sx={{fontWeight:"bold"}}>
          {greeting} {username},
        </Typography> 
        
        <InfoBox>

        <div style={{display:"flex",flexDirection:"column",gap:"2rem"}}>

        
        {categories.map(({ category, categoryIcon, questions }) => (
          <div key={category}>
            <TextWithBorders text={category} icon={categoryIcon}/>
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap',paddingTop:"1rem"}}>
              {questions.map(({ text, link }) => (
                <InfoBoxCustom key={text} onClick={() => navigate(link)} 
                sx={{minWidth: '250px' }}>
                  <h4 style={{ fontWeight: 'bold' }}>{text}</h4>
                </InfoBoxCustom>
              ))}
            </div>
          </div>
        ))}
        <div>
       
        </div>
       
        </div>
        </InfoBox>
 
      </div>

     
      
      </div>
      <div style={{padding:"2rem",minHeight:"80vh"}}>
      <InfoBox sx={{backgroundColor: "#fafafa" }}>

<div style={{display: 'flex', gap: '2rem',flex:1,boxSizing:"border-box",flexWrap:"wrap"}}>
  {categoryExploreEvent.map(({ category, categoryIcon, questions }) => (
      <div key={category} style={{minWidth:"300px"}}>
        <TextWithBorders text={category} icon={categoryIcon}/>
        <div style={{ display: 'flex',flexDirection:"column",gap: '1rem', flexWrap: 'wrap',paddingTop:"1rem"}}>
          {questions.map(({ text, link }) => (
            <InfoBoxCustom 
            key={text} onClick={() => navigate(link)}>
              <h4 style={{ fontWeight: 'bold',color:"gray"}}>{text}</h4>
            </InfoBoxCustom>
          ))}
        </div>
      </div>
    ))}
  </div>
  </InfoBox>
  </div>
    </div>
  </>
  )
}

function getGreeting() {
  const currentHour = new Date().getHours();
  
  if (currentHour < 12) {
      return "Good morning";
  } else if (currentHour < 18) {
      return "Good afternoon";
  } else {
      return "Good evening";
  }
}

const images = ["https://images.unsplash.com/photo-1445116572660-236099ec97a0?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1466781783364-36c955e42a7f?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  "https://images.unsplash.com/photo-1620263827308-682251c1be2b?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
]

const categoryExploreEvent = [
  {
    category: "Explore & Analyze",
    categoryIcon: menuIcons?.Explore,
    questions: [
      { text: "Ticker", link: "/Analytics/Ticker" },
      { text: "Industry & Sector", link: "/Analytics/Industry" },
      { text: "Market", link: "/Analytics/Market" },
      { text: "Economy", link: "/Analytics/Economy" },
      { text: "Top Investors", link: "/Analytics/Investors" },
     
    ],
  },
  {
    category: "Screeners",
    categoryIcon: menuIcons?.Filter,
    questions: [
      { text: "Stocks", link: "/Screener/Stocks" },
      { text: "Crypto", link: "/Screener/Crypto" },
      { text: "Etf", link: "/Screener/Etf" },
      { text: "Bonds", link: "/Screener/Bonds" },
      { text: "Forex", link: "/Screener/Forex" },
    ],
  },
  {
    category: "News & Events",
    categoryIcon: menuIcons?.Calendar,
    questions: [
      { text: "Current News", link: "/NewsEvents/News" },
      { text: "Upcoming Earnings", link: "/NewsEvents/Calendar" },
      { text: "Recent IPO's", link: "/NewsEvents/Calendar" },
      { text: "Merger & Acquisitions", link: "/NewsEvents/MandA" },
      { text: "Trading Activities", link: "/NewsEvents/TradingActivities" },
    ],
  },
  {
    category: "Report",
    categoryIcon: menuIcons?.Report,
    questions: [
      { text: "TakeStock Reports", link: "/Report/TSReport" },
      { text: "Build Reports", link: "/Report/ReportBuilder" },
    ],
  },
  {
    category: "Learn & Apply",
    categoryIcon: menuIcons?.Learn,
    questions: [
      { text: "Guide", link: "/Resource/Guide" },
      { text: "Detailed Learning", link: "/Resource/Learn" },
    ],
  },
]

const categories = [
  
  {
    category: "Opportunities for me",
    categoryIcon: menuIcons?.Opportunity,
    questions: [
      { text: "Portfolio Fit", link: "/Opportunities/PortfolioFit"},
      { text: "Value Investing", link: "/Opportunities/Investment" },
      { text: "Short Term Trade Calls", link: "/Opportunities/Trading" },
    ],
  },
  {
    category: "My Financials",
    categoryIcon: menuIcons?.Asset,
    questions: [
      { text: "Financials Overview", link: "/MyFinancials/Overview" },
      { text: "Financial Health", link: "/MyFinancials/FinancialHealth" },
      { text: "My Goals", link: "/MyFinancials/Goals" },
      { text: "Explore financial scenarios.", link: "/MyFinancials/Scenarios" },
    ],
  },
  {
    category: "Reports",
    categoryIcon: menuIcons?.Report,
    questions: [
      { text: "My Financials", link: "/Report/TSReport/FinancialsOverview" },
      { text: "Portfolio", link: "/Report/TSReport/Portfolio" },
      { text: "Economy USA", link: "/Report/TSReport/EconomyUS" },
      { text: "Intermarket", link: "/Report/TSReport/Intermarket" },
    ],
  },
  // {
  //   category: "Guide me",
  //   categoryIcon: menuIcons?.Guide,
  //   questions: [
  //     { text: "How to Build a Portfolio", link: "/Guide" },
  //     { text: "How to Manage Risks", link: "/Guide" },
  //   ],
  // },
 
];



function InfoBoxCustom({ children, onClick, sx = {} }) {
  return (
    <div 
      onClick={onClick} 
      style={{ 
        padding: '1rem', 
        background: '#ffffff', 
        borderRadius: '12px', 
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
        transition: 'transform 0.2s, box-shadow 0.2s', 
        cursor: 'pointer', 
        ...sx 
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-4px)';
        e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
      }}
    >
      {children}
    </div>
  );
}
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the Highcharts More module
require("highcharts/modules/accessibility")(Highcharts);

HighchartsMore(Highcharts);


  
export default function PorfolioTransactionsChart ({ transactions }) {
    const formattedData = formatData(transactions);
  
    const options = {
      chart: {
        type: 'bubble',
        zoomType: 'xy',
        reflow: true,
      },
      title: {
        text: 'Investment Transactions'
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Price'
        }
      },
      series: formattedData,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.point.ticker}`;
            },
            style: {
              color: 'black',
              fontSize: '16px'
            },
            align: 'right',
            verticalAlign: 'middle'
          },
          tooltip: {
            pointFormat: '{point.name}: {point.y} ({point.transactionType})'
          }
        }
      },
      exporting:{
        enabled:false
      },
      credits: {
        enabled: false, // Hide Highcharts.com credits
      },
      accessibility: {
        enabled: false, // Include the accessibility module
      },
    };
  
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  };
  
  
  const formatData = (transactions) => {
    const groupedData = transactions.reduce((acc, transaction) => {
      const ticker = transaction.TICKER;
      if (!acc[ticker]) {
        acc[ticker] = [];
      }
      acc[ticker].push({
        x: new Date(transaction.date).getTime(),
        y: Number(transaction.price),
        name: transaction.NAME,
        ticker: transaction.TICKER,
        transactionType: transaction.transactionType,
        marker: {
          symbol: transaction.transactionType === 'Buy' ? 'triangle' : 'triangle-down',
          fillColor: transaction.transactionType === 'Buy' ? 'green' : 'red',
          radius: 4
        }
      });
      return acc;
    }, {});
  
    return Object.keys(groupedData).map(ticker => ({
      name: ticker,
      data: groupedData[ticker]
    }));
  };
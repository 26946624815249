import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen'
import { useAppInfo } from 'AppState'
import SelectBox from 'components/Inputs/SelectBox'
import BorderBox from 'components/Layout/BorderBox'
import { Alert } from '@mui/material'
import CashflowChart from 'myFinancials/Cashflow/CashflowChart'
import { calculateAmortizationSchedule } from 'myFinancials/Utils/utils'
import { PageMainContentTitle } from 'components/Font/Title'

export default function AmortizationTable() {
    const {stateMyFinancials} = useAppInfo()
    const {assets,liabilities} = stateMyFinancials

  const [chartData,setChartData] = useState([])
  const [tableData,setTableData]  = useState([])
  const [liabilityList,setLiabilityList] = useState([])
  const [liabilitySel,setLiabilitySel] = useState()
  const [noLiability,setNoLiability] = useState(false)
  useEffect(()=>{
    if (liabilities){
     const liabilityName = liabilities.filter(obj=>obj.amount > 0).map(obj=>obj.name)
     const liabilityNameSel = liabilityName[0]
     setLiabilityList(liabilityName)
     setLiabilitySel(liabilityNameSel)
     if (liabilityName.length === 0 || liabilities.length === 0){
        setNoLiability(true)
     }
     else{
        setNoLiability(false)
     }
    }
  },[liabilities])

  useEffect(()=>{
    if (liabilitySel){
     const liabilityDistSel = liabilities.filter(obj=>obj.name===liabilitySel)[0]
     const {amortizationSchedule} = calculateAmortizationSchedule(liabilityDistSel)
     const tableData = amortizationSchedule || []
     const chartData = tableData.flatMap(obj => [
        { name: "Total", amount: obj.amount, date: obj.date},
        { name: "Interest", amount: obj.interest, date: obj.date},
        { name: "Principal", amount: obj.principal, date: obj.date},
        
    ]);
     setTableData(tableData)
     setChartData(chartData)
    }
  },[liabilitySel])
  return (
    <>
    {noLiability && <Alert>You have no liability.</Alert>}
    {!noLiability && 
    <div style={{padding:"0.5rem",width:"100%",height:"100%",boxSizing:"border-box"}}>
         
    <SelectBox variant='outlined' width='40%' label={"Liability Type"} options={liabilityList} value={liabilitySel} onChange={(e,v)=>setLiabilitySel(v)}/>
    <div style={{width:"100%",height:"400px"}}>
    <CashflowChart data={chartData} chartType="area"/>
    
    </div>
    
    <TableGen height='auto' tableLayout='fixed' data={tableData}/>

    </div>}
    </>
  )
}

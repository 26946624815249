import React, {
  useEffect,
  useState,
} from 'react';

import PeriodSlider from 'components/Inputs/PeriodSlider';
import SelectBox from 'components/Inputs/SelectBox';
import Toggle from 'components/Inputs/Toggle';
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine';
import MetricStockFinancials from 'components/Template/MetricStockFinancials';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import PageSettingsModal from 'components/Template/PageSettingsModal';
import { PopoverSettings } from 'components/Template/MetricSettings';
import CompanyFinancialsPeers from './Financials/Peers/CompanyFinancialsPeers';
import CompanyFinancialsSelf from './Financials/Company/CompanyFinancialsSelf';

export default function CompanyFinancialsUI(
  {report=true, 
  hideOnHover,
  metricSel,
  periodLength,
  metricView,
  reportType,
  chart,
  peerModeUser,handleObjectDetails}) {

  const {stateStock} = useAppInfo()
  const {annualInfo,quarterInfo,loadingFinancials} = stateStock
  const { stateTicker } = useAppInfo();
  const {companySelected} = stateStock;
  const {peerSelected} = stateTicker
  const [peerMode,setpeerMode] = useState(peerModeUser)
  const [chartType,setChartType] = useState(chart?chart:"column")
  const [reportMode, setReportMode] = useState(reportType);
  const [periodLen, setPeriodLen] = useState(periodLength?periodLength:5); // period
  const [totalPeriods, setTotalPeriods] = useState(0); //total period
  const [growthMode, setGrowthMode] = useState(metricView);
  const [addMetrics,setAddMetrics] = useState(false)
  const [displaySettings,setDisplaySettings] = useState(false)

  const [statementInfo, setStatementInfo] = useState([]);
  const [metricSelected, setMetricSelected] = useState([
      dfin.revenue,
      dfin.grossProfit,
      dfin.ebitda,
      dfin.netIncome,
  ]);
 
  useEffect(()=>{
    if (metricSel.length>0){
    setMetricSelected(metricSel)}
  },[metricSel])

  
  useEffect(() => {
    let noOfPeriods;
    if (reportMode === false) {
      noOfPeriods = Array.from(
        new Set(
          annualInfo
            .filter((obj) => obj[dfin.ticker] === companySelected)
            .map((obj) => obj[dfin.period])
        )
      ).length;
    } else {
      noOfPeriods = Array.from(
        new Set(
          quarterInfo
            .filter((obj) => obj[dfin.ticker] === companySelected)
            .map((obj) => obj[dfin.period])
        )
      ).length;
    }
    if(!periodLength){
    if (noOfPeriods < 5) {
      setPeriodLen(noOfPeriods);
    } else {
      setPeriodLen(5);
    }}

    setTotalPeriods(noOfPeriods);
  }, [reportMode, companySelected, annualInfo, quarterInfo]);

  useEffect(() => {
    const filteredInfo = reportMode ? quarterInfo : annualInfo;
    if (!peerMode){
    const statementInfoCo = filteredInfo
      .filter((obj) => obj[dfin.ticker] === companySelected)
      .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
      .slice(0, periodLen);
    setStatementInfo(statementInfoCo);}
    else{
      const statementInfoCo = filteredInfo
      .sort((a, b) => b.PERIOD.toString().localeCompare(a.PERIOD.toString()))
    setStatementInfo(statementInfoCo);
    }
  }, [
    companySelected,
    peerSelected,
    reportMode,
    annualInfo,
    quarterInfo,
    periodLen,
    peerMode
  ]);


  function handleReportToggle() {
    setReportMode(!reportMode);
   
    if (report){
    const updateContent = {ReportType:!reportMode}
    handleObjectDetails(updateContent)}
  }


  function handlePeriodInputChange(event, v) {
    event.stopPropagation();
    setPeriodLen(event.target.value === "" ? "" : Number(event.target.value));
    if (report){
      const updateContent = {PeriodLen: Number(event.target.value)}
      handleObjectDetails(updateContent)}
  }

  function handleGrowth() {
    setGrowthMode(!growthMode);
    
    if (report){
    const updateContent = {MetricView:!growthMode}
    handleObjectDetails(updateContent)}
  }
  const [showSettings,setShowSettings] = useState(!hideOnHover)

  function handleMetricSelected(value){
    let metricSel;    
    if (Array.isArray(value)) {
      metricSel = value   
    } 
    else {
      if (!metricSelected.includes(value)) {
       metricSel = [...metricSelected,value]
       
      } else {
        metricSel = metricSelected.filter((item) => item !== value)
      }     
    }
    setMetricSelected(metricSel)
    const updateContent = {MetricSelected:metricSel}
    if (report){
    handleObjectDetails(updateContent)}
  }

 
  
  function handleChartType(v){ 
    setChartType(v)
    
    if (report){
    const updateContent = {ChartType:v}
    handleObjectDetails(updateContent)}

  }


  function handlePeerMode(){
    setpeerMode(!peerMode)
    
    if (report){
    const updateContent = {PeerMode:!peerMode}
    handleObjectDetails(updateContent)
    }
  }

  return (

    <div onMouseLeave={hideOnHover && (() => setShowSettings(false))}  
    onMouseEnter={hideOnHover && (() => setShowSettings(true))} 
    className='containerColumnFlex'>
      {showSettings &&             
      <div style={{position:"absolute",zIndex:"1",background:"white",top:"0px",right:"0px"}}>
       <PopoverSettings
             addMetric
             handleAddMetric={()=>setAddMetrics(true)}
             displaySettings
             handleDisplaySettings={()=>setDisplaySettings(true)}
             />
        </div> }
      {displaySettings && 
      <>
      <PageSettingsModal title='Display Settings' onClose={()=>setDisplaySettings(!displaySettings)}>
      
      <Toggle
          toggleMode={peerMode}
          handleToggle={handlePeerMode}
          toggleValues={["Company", "Peers"]}
        />
      <Toggle
                  toggleMode={reportMode}
                  handleToggle={handleReportToggle}
                  toggleValues={["Report Annual", "Quarter"]}
                />
        
        <Toggle
            toggleMode={growthMode}
            handleToggle={handleGrowth}
            toggleValues={["Trend", "Growth"]}
          />
        {totalPeriods > 0 && (
                    <PeriodSlider
                      max={totalPeriods}
                      min={1}
                      defaultValue={periodLen}
                      onChange={handlePeriodInputChange}
                      handleInputChange={handlePeriodInputChange}
                      reportMode={reportMode}
                    />
                  )}

    <SelectBox 
            label={"Chart Type"}
            value={chartType}
            onChange={(e,v)=>handleChartType(v)}
            options={["column","line","area","bar"]} /> 
      
        </PageSettingsModal>

        
      </>
    }
      {addMetrics && <MetricStockFinancials 
        onClose={()=>setAddMetrics(false)}
        handleMetricSelected={handleMetricSelected} 
        metricSelected={metricSelected}/>}

      {loadingFinancials && (
        <div style={{width:"100%",height:"100%"}}>
        <LoadingSkeletonLine length={1} width="100%" height="100%"/>
        </div>
      )}

      <div className='containerColumnFlex1'>
      {!peerMode &&  
      <CompanyFinancialsSelf
      statementInfo={statementInfo}
      table={false}
      metricSelected={metricSelected}
      handleMetricSelected={handleMetricSelected}
      financialsSettings={{growthMode,reportMode,chartType,displayType:"Chart"}}
      />
      }

        {(peerMode && statementInfo.length>0) && 
        <CompanyFinancialsPeers
        financialsSettings={{growthMode,reportMode,chartType,displayType:"Chart"}}
        metricSelected={metricSelected}
        data={statementInfo}
        handleMetricSelected={handleMetricSelected}
        table={false}
        />}
        </div>
    </div>
  );
}

import React, { useState } from 'react';

const DateRangePicker = ({onDateChange}) => {
  // Default values for From and To dates (you can set your own default values)
  const defaultFromDate = new Date();
  defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);
  const defaultFromDateP = defaultFromDate.toISOString().split('T')[0];

  const defaultToDate = new Date().toISOString().split('T')[0];


  // State variables to store the From and To dates
  const [fromDate, setFromDate] = useState(defaultFromDateP);
  const [toDate, setToDate] = useState(defaultToDate);

  // Handle changes in the From date input
  const handleFromDateChange = (event) => {
    const newFromDate = event.target.value;
    setFromDate(newFromDate);
    onDateChange({ from: newFromDate, to: toDate });
  };

  // Handle changes in the To date input
  const handleToDateChange = (event) => {
    const newToDate = event.target.value;
    setToDate(newToDate);
    onDateChange({ from: fromDate, to: newToDate });
  };

  return (
    <div style={{display:"flex",gap:"1rem",padding:"0.5rem"}}>
      <div style={{display:"flex",alignItems:"center",gap:"0.2rem"}}>
      <label style={{color:"black"}} htmlFor="fromDate">From:</label>
      <input
        style={{padding:"0.25rem"}}
        type="date"
        id="fromDate"
        value={fromDate}
        onChange={handleFromDateChange}
      /></div>

<div style={{display:"flex",alignItems:"center",gap:"0.2rem"}}>
      <label style={{color:"black"}} htmlFor="toDate">To:</label>
      <input
      style={{padding:"0.25rem"}}
        type="date"
        id="toDate"
        value={toDate}
        onChange={handleToDateChange}
      />
      </div>
    </div>
  );
};

export default DateRangePicker;
import React, { useEffect, useRef, useState } from 'react';
import styles from "./TooltipInfo.module.css";

export default function TooltipInfo({
  children,
  top = "100%",
  left = "auto",
  right = "auto",
  bottom,
  height = "auto",
  width = "auto",
  borderRadius = "5px",
  sx = {},
  arrow = false,
  arrowSide = "left"
}) {
  const tooltipRef = useRef(null);
  const [positionUpdate, setPositionUpdate] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const tooltipElement = tooltipRef.current;
    const pageContent = document.getElementById('contentWrapper');

    if (!tooltipElement || !pageContent) return;

    const tooltipRect = tooltipElement.getBoundingClientRect();
    const pageContentRect = pageContent.getBoundingClientRect();

    const { innerWidth, innerHeight } = window;

    const updatedPosition = {};

    if (tooltipRect.left < pageContentRect.left) {
      updatedPosition.left = `${pageContentRect.left}px`;
      updatedPosition.right = 'auto';
    }
    if (tooltipRect.right > pageContentRect.right) {
      updatedPosition.left = 'auto';
      updatedPosition.right = `${0}px`;
    }
    if (tooltipRect.top < pageContentRect.top) {
      updatedPosition.top = `${pageContentRect.top}px`;
    }
    if (tooltipRect.bottom > pageContentRect.bottom) {
      updatedPosition.top = 'auto';
      updatedPosition.bottom = `${innerHeight - pageContentRect.bottom}px`;
    }

    setPositionUpdate(updatedPosition);
    setShowTooltip(true); // Show the tooltip after position update
  }, [top, left, right, bottom]);

  return (
    <>
      <div
        ref={tooltipRef}
        className={`${styles.tooltipContainer} ${showTooltip ? styles.show : ''}`}
        style={{
          position: "absolute",
          top: top,
          width: width,
          height: height,
          left: left,
          right: right,
          borderRadius: borderRadius,
          ...sx,
          ...positionUpdate
        }}
      >
        {arrow && arrowSide === "left" && <span className={styles.triangle}></span>}
        {arrow && arrowSide === "top" && <span className={styles.triangleTop}></span>}
        {children}
      </div>
    </>
  );
}

import { Alert, Button } from '@mui/material'
import { assetTypes, defaultFieldBlank, goalTypes } from 'appState/initialStateMyFinancials'
import React, { useEffect, useMemo, useState } from 'react'
import { InputAmount, InputDate, InputForecastPeriod, InputGrowthRate, InputName, InputPeriod, InputPriority, InputReturnExpected, InputTargetAllocation, InputType, InputVolatility } from 'myFinancials/FormFields/FieldFormCells';
import useFieldForm from 'myFinancials/FormFields/useFieldForm';
import CrudModal from 'components/Modal/CrudModal';

export default function AssetIndividualEdit({assetData,onClose}) {

  const data = useMemo(() => [assetData], [assetData]); // Memoize assetData in an array
  const {
    fields,
    error,
    handleChange,
    handleDeleteField,
    handleAddField,
    handleRemoveField,
    handleSubmit,
  } = useFieldForm({storeName:"assets",data:data,defaultField:assetData})
  

  
  return (
  <>
  
{ <>
<div style={{display:"flex",gap:"1rem",width:"100%",flexWrap:"wrap",height:"100%"}}>
  <div style={{background:"#fafafa",width:"30%",minWidth:"300px",borderRadius:"5px"}}>
  <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  {fields.map((fieldObj,index)=>
  <form  onSubmit={(e)=>{handleSubmit(e);onClose()}} key={index} style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
  <InputType disabled={!fieldObj?.edit} typeOptions={assetTypes} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputName disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputDate disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputAmount disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputTargetAllocation label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputReturnExpected label fieldObj={fieldObj} 
  handleChange={handleChange} disabled={fieldObj?.type==="Cash"}/>
  <InputVolatility label fieldObj={fieldObj} handleChange={handleChange} disabled={fieldObj?.type==="Cash"}/>
  <InputForecastPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  {error && <Alert severity='warning'>{error}</Alert>}
  <Button variant='contained' type='submit'>Save</Button>
    </form>)}
  
  </div>
  
  </div>
 
</div>
</>}
</>
  )
}
import React, { useEffect, useState } from "react";
import styles from "./CompanyFinancialsTable.module.css";
import { dfin } from "content/constants";
import { formatNumber, getSpecificArrayObjKeys } from "utils/utilGeneral";
import BarSVG from "components/Charts/BarSVG";
import { calculateGrowthRates } from "../utils";

export default function CompanyFinancialsTable({
  statementInfo,
  statementMetric,
  metricSelected,
  onChange,
  growthMode
}) 
{

  const [uniqueDates,setUniqueDates] = useState([])
  const [cols,setCols] = useState([])
  const [selStatement,setSelStatement] = useState([])
  const [growthSelStatement,setGrowthSelStatment] = useState([])

  useEffect(() => {
    const uniqueDates = Array.from(
      new Set(statementInfo.map((item) => item.DATE))
    ).sort((a, b) => new Date(a) - new Date(b));

    const formattedDate = uniqueDates.map((item) => {
      const date = new Date(item);
      return date.toLocaleString('default', { month: 'short', year: '2-digit' });
    });

    const cols = ['Metric', 'Trend', ...formattedDate];
    let selStatement = getSpecificArrayObjKeys(statementInfo, [
      dfin.date,
      ...statementMetric,
    ]).sort((a, b) => new Date(a.DATE) - new Date(b.DATE));

    setUniqueDates(uniqueDates);
    setCols(cols);

    const growthSelStatement = calculateGrowthRates({ data: selStatement, metrics: statementMetric, dateKey: dfin.date });
    setGrowthSelStatment(growthSelStatement)
    setSelStatement(selStatement);
  }, [statementInfo, statementMetric, growthMode]);

 
  const [mouseEnter,setMouseEnter] = useState(false)
  const [metricHover,setMetricHover]  = useState(null)
  function handleMouseEnterTrend(metricHover,value){
    setMetricHover(metricHover)
    setMouseEnter(value)
  }

  return (
    <div>
      {selStatement.length>0 &&
      <table className={styles.table}>
        <thead>
          <tr>
            {cols.map((col, index) => (
              <th key={index}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {statementMetric.map((metric) => (
            <tr 
            onClick={()=>onChange(metric)} key={metric}>
              <td

               onMouseEnter={()=>handleMouseEnterTrend(metric,true)} onMouseLeave={()=>handleMouseEnterTrend(metric,false)}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontWeight: 400,
                }}
              >
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <input
                    type="checkbox"
                    name={metric}
                    onChange={()=>onChange(metric)}
                    checked={metricSelected.includes(metric)}
                  />
                  {metric}
                </div>
               
              </td>
              <td style={{position:"relative",overflow:(metricHover===metric && mouseEnter ) && "visible"}} onMouseEnter={()=>handleMouseEnterTrend(metric,true)} 
              onMouseLeave={()=>handleMouseEnterTrend(metric,false)}>
                <BarSVG data={selStatement.map(obj=>obj[metric])} height={20} width={50}/>
                {(metricHover===metric && mouseEnter ) &&
               <span style={{
                position: "absolute",
                display: "block",
                overflow: "auto",
                top: "50%",  // Adjust these values based on your preference
                left: "50%",
                background: "white",
                boxShadow:"var(--boxShadowGeneral)",
                padding: "1rem",
                zIndex: 100,
              }}>
                <h5>{metric}</h5>
                <BarSVG data={selStatement.map(obj=>obj[metric])} height={100} width={200}/>
                </span>}
                </td>

              {uniqueDates.map((date) => (
                <td key={date}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                  {!growthMode && formatNumber(selStatement.find((item) => item.DATE === date)[metric])}
                  {<span 
                  style={{fontSize:growthMode?"1rem":"0.8rem",color:growthSelStatement.find((item) => item.DATE === date)?.[metric] > 0 ? "green":"red"}}>
                  {growthSelStatement.find((item) => item.DATE === date)[metric]?.toLocaleString()}%
                  </span>}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>}
    </div>
  );
}

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    pageContentTitle: {
      ...createTheme().typography.h3,
      display:"block",
      fontSize:"1.3rem",
      fontWeight: 'bold',
      padding: '0.5rem',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'highlight' },
          style: {
            backgroundColor: '#f7f7f7',
            color:"var(--selectBlue)",
            '&:hover': {
              backgroundColor: '#f3f3f3',
            },
          },
        },
        {
          props: { variant: 'pageHeader' },
          style: {
            display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",
            fontSize:"0.8rem",
            minWidth:0,
            padding:"0.25rem"
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize:"1rem"
        },
      },
    },
  },
});

export default theme;

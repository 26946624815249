import { Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import { menuIcons } from 'nav/menuIcons'
import { dfin } from 'content/constants'
import SelectMarket from 'components/Inputs/SelectMarket'
import GuideModal from 'resource/guidance/GuideModal'

export default function PageHeaderIcons({name="Explore",guideType,onClick,title}) {
  const {dispatchSearch,stateTicker} = useAppInfo()
  const {tickerSelInfo} = stateTicker
  const [guideModal,setGuideModal] = useState(false)

  const types = {
    "Explore":    <Tooltip title="Explore">
    <Button onClick={()=>dispatchSearch({"searchClick":true})} 
    variant='pageHeader'>
        {menuIcons.Explore}
    Explore</Button>
    </Tooltip>,
    "Guide":<Tooltip title="Guide">
    <Button onClick={()=>setGuideModal(true)} variant='pageHeader' sx={{color:"var(--selectBlue)"}}>
        {menuIcons.Guide}
    Guide</Button>
    </Tooltip>,
     "Calendar":<Tooltip title="Calendar">
     <Button onClick={onClick} variant='pageHeader'>
         {menuIcons.Calendar}
     Calendar</Button>
     </Tooltip>,
     "Active Ticker":
     <Tooltip title={title?title:"Active Ticker"}>
     <Button 
     onClick={onClick}
     variant='pageHeader'>
     <img width={"20px"} height={"20px"} src={`${tickerSelInfo?.[dfin.coLogo]}`}></img>
      {tickerSelInfo?.[dfin.ticker]}
     </Button>
    
     </Tooltip>,
     "MarketRegion":<SelectMarket/>
  }
  return (
    <>
    {types[name]}

    {guideModal && <GuideModal guideType={guideType} onClose={()=>setGuideModal(false)}/>}
    </>
  )
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useDeletePortfolio = () => {
  const {stateApp} = useAppInfo()
  const userId = stateApp.userId
  const [error, setError] = useState(null);
  
  const deletePortfolio = async (accountName) => {
    try {
     
      const response = await axios.post(`${BASE_URL}/deletePortfolio/`, {
        userId,
        accountName:accountName
      });
      
      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { deletePortfolio, error };
};

export default useDeletePortfolio;
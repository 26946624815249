import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useEffect, useRef, useState } from "react";


export default function useTechnicalStats(tickerList,tickerSelected) {

const [tStats, setTstats] = useState([]);
const [tStatsLoading, setTStatsLoading] = useState(true);


// tickerList.length === previousPeerSelectedRef.current.length && 
useEffect(() => {
    if (tickerList.length>0){
    getPriceInfo();}
    
}, [tickerList,tickerSelected]);

async function getPriceInfo() {
    setTStatsLoading(true);
    try {
    const response = await axios(`${BASE_URL}/technicalStats/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        tickerSel:JSON.stringify(tickerSelected)
        },
    });
    
    const marketInfo = response.data
    const allStats = JSON.parse(marketInfo["techStats"])
    const pStats = JSON.parse(marketInfo["pStats"])
    const tStats = JSON.parse(marketInfo["tStats"])
    const oscStats = JSON.parse(marketInfo["oscStats"])
    const techStats = {allStats:allStats,pStats:pStats,tStats:tStats,oscStats:oscStats}

    setTstats(techStats)
    
    } catch (error) {
    console.error(error);
    } finally {
    setTStatsLoading(false);
    }
}
return {tStats,tStatsLoading };
}
import Highcharts, { chart } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import numeral from 'numeral';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { formatNumber } from "utils/utilGeneral";
import React, { useEffect, useMemo, useState } from 'react';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';

require("highcharts/modules/accessibility")(Highcharts);

export default function BarChartPeers({data,metricSelected, dataLabel, chartLegend,title, 
  color,
  type="bar",
  exporting=false,titleType="metricSelected"}) {

  const {stateStock} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const companySelected = stateStock.companySelected
  const minChartHeight = 300; // Set a minimum chart height (in pixels)
  let chartHeight = `${Math.max(data.length, 1) * 20}px`; // Calculate the chart height

  // If the calculated height is smaller than the minimum height, use the minimum height instead
  if (parseInt(chartHeight) < minChartHeight) {
    chartHeight = `${minChartHeight}px`;
  }

  const [chartData,setChartData] = useState()

  const [selectedTickerIndex,setSelectedTickerIndex] = useState(0)
  
  const [options,setOptions] = useState()

  useEffect(()=>{
    const sortedData = [...data]; // Create a copy of the original data array
    sortedData.sort((a, b) => b[metricSelected] - a[metricSelected]);
    
    const chartData = {
      series: metricSelected.map((metric) => ({
        name: metric,
        data: sortedData
        .map((data) => data[metric]),
        color:color&&color
        
      })),
      categories: sortedData.map((data) => data[dfin.ticker]),
    };

    setChartData(chartData)
    const selectedTickerIndex = sortedData.map((data) => data[dfin.ticker]).indexOf(companySelected);
    setSelectedTickerIndex(selectedTickerIndex)
  },[data,metricSelected,companySelected])


  useEffect(()=>{
  if (chartData){
  const options = {
    chart: {
      type: type,
      zoomType: 'xy',
      reflow: true,
      height: chartHeight,
      panning: false,
    },
    title: {
      text: title === "metricSelected"?title?.metricSelected[0]:title,
    },
    series: chartData.series,
    xAxis: {
      categories: chartData.categories,
      tickInterval: 1,
      labels: {
        step: 1, // Display all X-axis labels
        
      },
      opposite: chartHeight > '500px' ? 1 : 0,
      offset: 0,
      lineWidth: 1,
      tickLength: 0,
      plotBands: [{
        color: 'rgba(0,0,0,.25)',
        from: selectedTickerIndex - 0.5,
        to: selectedTickerIndex + 0.5,
        zIndex: 1,
      }],
    },
    yAxis: {
      title: {
        text: metricSelected,
      },
      labels: {
        formatter: function () {
          return numeral(this.value).format('0.00a').toUpperCase();
        },
      },
      opposite: chartHeight > '500px' ? 1 : 0,
      offset: 0,
      lineWidth: 1,
      tickLength: 0,
    },
    legend: {
      // enabled:false},
      enabled: chartLegend,
      layout: 'vertical', // Display the legends vertically
      align: 'right', // Align the legends to the right side
      verticalAlign: 'top', // Vertically align the legends to the middle
      borderWidth: 0, // Remove the border around the legends
      x: -10, // Adjust the horizontal position of the legends
      y: 10, // Adjust the vertical position of the legends
      itemStyle: {
        whiteSpace: 'normal', // Allow wrapping of legend items
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: dataLabel, // Enable data labels for each bar
          formatter: function () {
            return   formatNumber(this.y); // Format the data label values
          },
        },
        events: {
          click: function (event) {
            const category = event.point.category;
            handleTickerChange({ticker:category});
          },
          
        },
      },
    },
    tooltip: {
      formatter: function () {
        // `this.point.y` contains the data value for the current point
        const formattedValue = formatNumber(this.point.y);
        
        // `this.point.category` contains the X-axis category for the current point
        return `<b>${this.point.category}</b>: ${formattedValue}`;
      },
    },
    exporting:{
      enabled:exporting,
    },
    credits: {
      enabled: false, // Hide Highcharts.com credits
    },
    accessibility: {
      enabled: true, // Include the accessibility module
    },
  };
  setOptions(options)}
},[chartData,selectedTickerIndex,metricSelected])

  return (
    <div style={{width:"100%",overflowY:"auto",flexGrow:1,height:"100%"}}>
      {options && 
        <HighchartsReact highcharts={Highcharts} options={options} 
        containerProps={{ style: { height: '100%' , width:"100%"} }}/>
      }
    </div>
  );
}

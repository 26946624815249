import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useAddPortfolio = () => {
  const {dispatchPortfolio,stateApp} = useAppInfo()
  const userId = stateApp.userId
  const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addPortfolio = async (accountName,accountDetails) => {
    try {
      dispatchPortfolio({"portfolioSaved":false})
      const response = await axios.post(`${BASE_URL}/addPortfolio/`, {
        userId,
        username,
        accountName:accountName,
        accountDetails:accountDetails,
      });
      dispatchPortfolio({"portfolioSaved":true})
      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addPortfolio, error };
};

export default useAddPortfolio;
import React from 'react'
import FieldFormOnboarding from '../FormFields/FieldFormOnboarding'
import { generateUniqueId } from "utils/utilGeneral"


export default function ExpenseOnboarding() {
  const defaultField =  { id:generateUniqueId(), name: "Rent/Mortgage", type: "Periodic Expense", amount: 2000, date: new Date(), currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, period: "Monthly", growthRate: 2,forecastPeriod:30,edit:true}
  return (
    <FieldFormOnboarding name='Expense' defaultField={defaultField}
    forecastPeriod={true} growthRate={true} 
    period={true} formName='Expense' storeName={"expenseMonthly"} data={[]} 
    formHeading='Expense Category' showType={false}/>
  )
}

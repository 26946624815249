import { useEffect } from 'react';
import { BASE_URL } from 'apiConfig';
import axios from 'axios';
import { useAppInfo } from 'AppState';


const useFindNote = () => {
  const {dispatchNotes,stateApp} = useAppInfo()
  const userId = stateApp.userId


  const findNote = async () => {
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findNotes/`, {
          userId
        });;
        // Process the response data as needed
       const noteData = response.data;
       
      const noteNames = Object.keys(noteData);
      const noteDataSel = noteData[noteNames[0]]
      const updateNoteInfo = {loaded:true,
        names:noteNames,nameSel:noteNames[0],dataSel:noteDataSel,data:noteData}
      dispatchNotes(updateNoteInfo);
      }
    } catch (err) {
      // Handle the error
      dispatchNotes({loaded:true});
    }
  };

  useEffect(() => {
    if (userId) {
      findNote();
    }
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindNote;


import React from 'react';
import OverviewBox from 'myFinancials/Template/OverviewBox';
import NoGoalData from './NoGoalData';
import GoalsSuccessRate from './GoalsSuccessRate';
import GoalIndivdiualDetails from './GoalIndivdiualDetails';
import GoalAdd from './GoalAdd';


export default function GoalsOverview({displayType="home"}) {
  
  return (
      <>
      {displayType==="home" ?
      <OverviewBox
        header={"Financial Goals"}
        navigateUrl={'/MyFinancials/Goals'}
      >
        <GoalsOverviewDetials displayType={displayType}/>
      </OverviewBox>:
       <GoalsOverviewDetials displayType={displayType}/>
      }
      
      </>
      
       
        
     
  );
}

export function GoalsOverviewDetials({displayType="home"}){
 
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%',display:"flex",flexDirection:"column",gap:"1rem"}}>
<NoGoalData />
      
    <GoalsSuccessRate/>
    <GoalIndivdiualDetails edit={displayType==="home"?false:true}/>
    <div style={{display:"flex",gap:"1rem",position:"sticky",bottom:0,padding:"0.25rem"}}>
    <GoalAdd variant='highlight'/>
    </div>
   


    </div>
    
  )
}
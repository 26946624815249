import React, { useEffect, useRef, useState } from 'react';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import GuideStepContent from './GuideStepContent';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useAppInfo } from 'AppState';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListIcon from '@mui/icons-material/List';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { ArrowForwardIos } from '@mui/icons-material';

export default function StepperVerticalTab({ steps, activeStep, handleActiveStep, stepContent, name, contentAfterHeading }) {
  const { dispatchResource } = useAppInfo();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [contentMenuOpen, setContentMenuOpen] = useState(true);
  const guideStep = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (guideStep.current) {
        setIsOverflowing(guideStep.current.clientWidth < guideStep.current.scrollWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  useEffect(() => {
    if (guideStep.current && steps.length > 0) {
      const stepWidth = guideStep.current.scrollWidth / steps.length;
      const newScrollLeft = stepWidth * activeStep - (guideStep.current.clientWidth / 2) + (stepWidth / 2);
      guideStep.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
    }
  }, [activeStep, steps.length]);

  const handleStepChange = (step) => {
    handleActiveStep(step);
    if (step !== activeStep) window.location.hash = `step-${step}`;
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%', borderRadius: "5px", boxSizing: "border-box", gap: "0.5rem", padding: "0.25rem" }}>
      <Paper elevation={0} style={{ flex: 1, minWidth: 0, overflow: 'auto', height: "100%", width: "100%" }}>
        {activeStep >= 0 && (
          <>
            <div style={{ display: "flex", padding: "0.25rem", alignItems: "center", gap: "0.25rem" }}>
              <IconButton style={{ color: "black" }} onClick={() => { handleActiveStep(-1); dispatchResource({ "guideDisplay": "home" }); }}>
                <ArrowBackIosNewIcon fontSize='small' />
              </IconButton>
              <h2 style={{ fontWeight: "bold" }}>{name}</h2>
              
            </div>
            {contentAfterHeading}
          </>
        )}
        <GuideStepContent steps={steps} activeStep={activeStep} />
      </Paper>

      {!contentMenuOpen && (
        <div style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <ListIcon sx={{ float: "right" }} onMouseEnter={() => setContentMenuOpen(true)} onClick={() => setContentMenuOpen(true)} />
          {activeStep >= 0 && (
            <div style={{ position: 'sticky', marginTop: "auto", bottom: 0, width: '100%', zIndex: 1000, padding: '0.5rem', boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button disabled={activeStep === 0} onClick={() => handleStepChange(activeStep - 1)} sx={{ padding: "0.2rem", minWidth: 0 }}>
                <ArrowBackIos fontSize='small' />
              </Button>
              <Button variant="contained" onClick={() => handleStepChange(activeStep >= steps.length - 1 ? 0 : activeStep + 1)} sx={{ padding: "0.2rem", minWidth: 0 }}>
                <ArrowForwardIos fontSize='small' />
              </Button>
            </div>
          )}
        </div>
      )}

      {contentMenuOpen && (
        <Paper ref={guideStep} elevation={0} style={{ display: 'flex', flexDirection: 'column', width: '15%', height: '100%', padding: "0.25rem", background: "#fafafa" }}>
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <MenuOpenIcon onClick={() => setContentMenuOpen(false)} sx={{ transform: "rotate(180deg)" }} />
            <h3>Content</h3>
          </div>
          <div style={{ flex: '1', position: "sticky", top: 0, width: "100%" }}>
            {steps.map((step, index) => (
              <Button key={index} style={{ padding: '0.5rem 0.25rem', color: activeStep !== index ? 'black' : '', justifyContent: "flex-start", textTransform: "none", textAlign: "left", width: "100%" }} onClick={() => handleStepChange(index)}>
                <Typography style={{ fontSize: "0.9rem" }}>{index + 1}. {step.label}</Typography>
              </Button>
            ))}
          </div>
          {activeStep >= 0 && (
            <div style={{ position: 'sticky', bottom: 0, width: '100%', background: "#fafafa", zIndex: 1000, padding: '0.5rem', boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button disabled={activeStep === 0} onClick={() => handleStepChange(activeStep - 1)} sx={{ padding: "0.2rem" }}>Back</Button>
              <Button variant="contained" onClick={() => handleStepChange(activeStep >= steps.length - 1 ? 0 : activeStep + 1)} sx={{ padding: "0.2rem" }}>
                {activeStep >= steps.length - 1 ? 'Reset' : 'Next'}
              </Button>
            </div>
          )}
        </Paper>
      )}
    </div>
  );
}


// {isOverflowing && (
//     <div style={{ display: 'flex' }}>
//       <Button sx={{ padding: 0, minWidth: 0 }} onClick={handlePrevButtonClick}><KeyboardArrowLeftIcon fontSize="small" /></Button>
//       <Button sx={{ padding: 0, minWidth: 0 }} onClick={handleNextButtonClick}><KeyboardArrowRightIcon fontSize="small" /></Button>
//     </div>
//   )}
import React, { useEffect, useState } from 'react';
import styles from 'components/Template/MetricSelectionModal.module.css';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputBase } from '@mui/material';
import Modal from 'components/Modal/Modal';

export default function MetricSelectionModal({
    title,
    searchBar=true,
    handleMetricSelected,
    metricInfo,
    metricSelected = [],
    checkbox = true,
    onClose,
    searchTerm,
    modal = true
}) {
    const [metricCategories, setMetricCategories] = useState([]);
    const [metricCategorySel, setMetricCategorySel] = useState("All");
    const [filteredMetrics, setFilteredMetrics] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    // function handleResetMetric(){
    //     setMetricSelected(defaultMet)
    //     sessionStorage.setItem(
    //       `${storeId}`,
    //       JSON.stringify(defaultMet)
    //     );
    //     }

    useEffect(() => {
        if (searchTerm) {
            setSearchInput(searchTerm)
        }
    }, [searchTerm])

    useEffect(() => {
        if (metricInfo.length > 0) {
            const categories = new Set(metricInfo.map(metric => metric?.category));
            const metricCategories = ["All", ...categories];
            if (metricSelected.length > 0) {
                metricCategories.push("Selected");
            }
            setMetricCategories(metricCategories);
            setFilteredMetrics(metricInfo);
        }
    }, [metricInfo, metricSelected])

    useEffect(() => {
        let filtered = metricInfo;

        if (metricCategorySel !== "All") {
            if (metricCategorySel === "Selected") {
                filtered = metricInfo.filter(metric => metricSelected.includes(metric?.name));
            } else {
                filtered = metricInfo.filter(metric => metric?.category === metricCategorySel);
            }
        }

        if (searchInput) {
            filtered = filtered.filter(metric => metric?.name?.toLowerCase().includes(searchInput.toLowerCase()));
        }

        // Creating a set to ensure all metric names are unique.
        const uniqueMetrics = Array.from(new Set(filtered.map(metric => metric?.name)))
                                  .map(name => filtered.find(metric => metric.name === name));

        setFilteredMetrics(uniqueMetrics);
    }, [metricCategorySel, searchInput, metricSelected, metricInfo]);

    const content = (
        <div className={styles.modalWrapper}>
            <h2 className={styles.modalHeader}>{title}</h2>
            <div className={styles.flexContainer}>
                <div className={styles.leftPanel}>
                    {metricCategories.map(category => (
                        <Button
                            key={category}
                            onClick={() => setMetricCategorySel(category)}
                            variant={category === metricCategorySel ? "contained":"text"}
                            sx={{width:"100%"
                                ,textTransform:"none",justifyContent:"flex-start",fontSize:"1rem",textAlign:"left",wordWrap:"break-word"}}
                        >
                            {category}
                        </Button>
                    ))}
                </div>
                <div className={styles.rightPanel}>
                   {searchBar &&  
                   <div className={styles.searchBar}>
                        <IconButton type="button" aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{flex: 1}}
                            type='search'
                            placeholder="Search Metrics"
                            inputProps={{ 'aria-label': 'search metricInfo' }}
                            onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>}
                    <div className={styles.metricsListContainer}>
                        {filteredMetrics.map((metric, index) => (
                            <div key={`${metric?.name}-${index}`} 
                            className={styles.metricList}>
                                {checkbox && (
                                    <input
                                        checked={metricSelected.includes(metric?.name)}
                                        onChange={() => handleMetricSelected(metric?.name)}
                                        type='checkbox'
                                    />
                                )}
                                <Button
                                    onClick={() => handleMetricSelected(metric?.name)}
                                    className={`${styles.metricListButton} 
                                    ${metricSelected.includes(metric?.name) ? styles.activeButton : ''}`}
                                >
                                    {metric?.name}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );

    return modal ? <Modal onClose={onClose}>{content}</Modal> : content;
}

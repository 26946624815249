import { Alert, Paper } from '@mui/material'
import React from 'react'
import Animation from '../animation/Animation'

export default function PageComingSoon({name}) {
 
  return (
     <div style={{display:"flex"
          ,flexDirection:"column",justifyContent:"center"
    ,alignItems:"center",height:"100%",width:"100%",position:"relative",boxSizing:"border-box",padding:"1rem"}}>
      
    <div style={{display:"flex",justifyContent:"center"
    ,alignItems:"center",flexDirection:"column",gap:"1rem",width:"100%",zIndex:"1"}}>
      <div style={{width:"30%"}}>
      <Animation type='Chart'/>
      </div>
      <Alert severity='warning' 
        style={{fontWeight:"bold",display:"flex",alignSelf:"center",marginLeft:"1rem"}}
        >
          {/* {`${pageMain} ${pageName} are currently not available. Coming Soon!`} */}
          {`${name} are currently not available. Coming Soon!`}
          
          </Alert>

      </div>
    </div>

  )
}
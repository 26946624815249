import React, { useState } from 'react'
import ExpenseDetails from './ExpenseDetails'
import LiabilityPayback from '../Liability/LiabilityPayback'
import TabLine from 'components/Tabs/TabLine'
import CashflowPageHeader from './CashflowPageHeader'

export default function ExpenseInfo({info,displayType="tabs"}) {
  const [tabIndex,setTabIndex]  = useState(0)
  const tabList = ["Details","Liability Payback"]

  const content = {
    "Details":<ExpenseDetails/>,
    "Liability Payback":<LiabilityPayback/>
  }
  return (
    <div className='containerColumnFlex'>
    {displayType === "tabs" && <CashflowPageHeader type="Expense" title="Expense"/>}
    {displayType === "tabs" && <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>}
    <div className='containerColumnFlex1'>
    {content[tabList[tabIndex]]}
    </div>
    </div>
  )
}

import React, { useState,useEffect } from 'react'
import ScatterPlot from './ScatterPlot';
import { useAppInfo } from 'AppState';
import SelectBox from '../Inputs/SelectBox';
import { IconButton, InputBase, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MetricStockModal from '../Template/MetricStockModal';
import { dfin } from 'content/constants';
import AddPeersBar from '../../peerList/AddPeersBar';

export default function ScatterUI({report=false,defaultMet,data,showSettingsOnHover,addPeersBar=false,addPeers=false,handleObjectDetails}) {

    const {stateStock} = useAppInfo()
    const numCols = stateStock.metrics.numCols
    const [xAxis,setXaxis] = useState(defaultMet[0])
    const[yAxis,setYaxis] = useState(defaultMet[1])
    const scatterColorMetrics = [dfin.gicSector,dfin.gicIndustry,dfin.country,dfin.exchange,dfin.mScale]
    const [colorMetrics,setColorMetrics]=useState(dfin.gicSector)
    const[sizeMetrics,setSizeMetrics] = useState(dfin.marketCap)
    const [plotStyle,setPlotStyle] = useState("Bubble")
    const [showSettings,setShowSettings] = useState(!showSettingsOnHover)

     const [xModal, setXModal] = useState(false);
    const [yModal, setYModal] = useState(false);
    const [modalMetricSelected, setModalMetricSelected] = useState(defaultMet[1]);


    function handleModal(axis) {
      if (axis === 'X Axis') {
          setXModal(!xModal);
          setModalMetricSelected(xAxis);
      } else {
          setYModal(!yModal);
          setModalMetricSelected(yAxis);
      }
  }

  function handleModalMetricValue(axis, v) {
      if (axis === 'X Axis') {
          setXaxis(v);
          setXModal(false);
      } else {
          setYaxis(v);
          setYModal(false);
      }
      setModalMetricSelected(v);
  }
    useEffect(()=>{
      if(report){
        const updateContent = {MetricSelected:{x:xAxis,y:yAxis,
          colorMetrics:colorMetrics,sizeMetrics:sizeMetrics,plotStyle}}
          handleObjectDetails(updateContent)
      }
    },[xAxis,yAxis,colorMetrics,sizeMetrics,plotStyle])


    // useEffect(() => {
    //   // This effect will run whenever the defaultMet prop changes
    //   setXaxis(defaultMet[0]);
    //   setYaxis(defaultMet[1]);
    //   setModalMetricSelected(defaultMet[0])

    // }, [defaultMet]);

    
   

    function handleColorMetrics(e,v){
        setColorMetrics(v)
    }
    function handleSizeMetrics(e,v){
        setSizeMetrics(v)
    }

  return (
    <div  
    onMouseLeave={showSettingsOnHover && (() => setShowSettings(false))}  
    onMouseEnter={showSettingsOnHover && (() => setShowSettings(true))} 
    style={{display:"flex",flexDirection:"column",boxSizing:"border-box",
    height:"100%",width:"100%",boxSizing:"border-box",background:"white"}}>
    {showSettings && 
    <div style={{display:"flex",alignItems:"center", gap:"1rem",width:"100%",padding:!showSettingsOnHover&&"1rem",boxSizing:"border-box",
    position:showSettingsOnHover&&"absolute",zIndex:"1000",top:"0px",background:showSettingsOnHover&&"#eee",color:"white"}}>
    
    <SelectBox variant='standard' width='15%' label={"Chart Style"} 
    value={plotStyle} options={["Bubble","Points","Logo"]} onChange={(e,v)=>setPlotStyle(v)}/>

    <div style={{width:"20%",display:"flex"}}>
    <Tooltip title="Metric Category">
                        <IconButton onClick={() => handleModal("X Axis")}><FormatListBulletedIcon /></IconButton>
                    </Tooltip>
                    <SelectBox variant='standard' options={numCols} value={xAxis} onChange={(e, v) => setXaxis(v)} label={"X-Axis"} width='100%' />
    </div>
    <div style={{width:"20%",display:"flex"}}>
    <Tooltip title="Metric Category">
                        <IconButton onClick={() => handleModal("Y Axis")}><FormatListBulletedIcon /></IconButton>
                    </Tooltip>
                    <SelectBox variant='standard' options={numCols} value={yAxis} onChange={(e, v) => setYaxis(v)} label={"Y-Axis"} width='100%' />
    </div>
  
    <SelectBox variant='standard' options={numCols} value={sizeMetrics} onChange={handleSizeMetrics} label={"Size"} size='large' width='20%'/>
    
    
    <SelectBox variant='standard' options={scatterColorMetrics} value={colorMetrics} onChange={handleColorMetrics} label={"Color"} size='large' width='20%'/>
    
    </div>}

    <div style={{width:"100%",height:!showSettingsOnHover?"90%":"100%",flex:1}}>
    <ScatterPlot 
    plotStyle={plotStyle}
    addPeers={addPeers} 
    xAxis={xAxis} yAxis={yAxis} 
    colorMetrics={colorMetrics} sizeMetrics={sizeMetrics} data={data}/>
    </div>
    
    {xModal &&
                <MetricStockModal
                    handleModalClose={() => setXModal(false)} handleMetricSelected={(v) => handleModalMetricValue('X Axis', v)}
                    modalName={"X Axis"} metricSelected={[xAxis]} />}
            {yModal &&
                <MetricStockModal
                    handleModalClose={() => setYModal(false)} 
                    handleMetricSelected={(v) => handleModalMetricValue('Y Axis', v)}
                    modalName={"Y Axis"} metricSelected={[yAxis]} />}

    {((showSettingsOnHover && showSettings) || addPeersBar) && <AddPeersBar/>}
    </div>
  )
}

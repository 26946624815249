import React from 'react'
import ProductIntro from './ProductIntro'
import ProductFeature1 from './ProductFeature1'
import ProductFeature2 from './ProductFeature2'
import ProductFeature3 from './ProductFeature3'
import ProductFeature4 from './ProductFeature4'
import ProductFeature5 from './ProductFeature5'
export default function ProductFeatures() {
  return (
    <div id='productFeatures' style={{display:"flex",flexDirection:"column"
    ,boxSizing:"border-box",width:"100%"}}>
    <ProductFeature1/>
    <ProductFeature2/>
    <ProductFeature3/>
    <ProductFeature5/>
    <ProductFeature4/>
    {/* <ProductIntro/> */}
    </div>
  )
}

import React from 'react';

import PieChartGen from 'components/Charts/PieChartGen';
import TableGen from 'components/Table/TableGen';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import {
  excludeSpecificArrayObjKeys,
  getSpecificArrayObjKeys,
} from 'utils/utilGeneral';

export default function EtfMfAssetAllocation({assetAllocation,worldRegions,sectorAllocation}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "2rem",
          boxSizing: "border-box",
          flexDirection:"column"
        }}
      >
        {assetAllocation.length>0 && (
          <div style={{ width: "100%",padding:"1rem",boxSizing:"border-box",border:"1px solid #ddd",borderRadius:"5px"}}>
            <div style={{ width: "100%" ,height:"400px"}}>
              <PieChartGen
                title="Net Assets"
                data={getSpecificArrayObjKeys(assetAllocation, [
                  "NET ASSETS %",
                  "HOLDING TYPE",
                ])}
                categoryKey={"HOLDING TYPE"}
                metricKey={"NET ASSETS %"}
              />
            </div>
            <Accordion >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Table</AccordionSummary>
              <AccordionDetails>
            <TableGen
              sx={{ flex: 1 }}
              tableLayout="fixed"
              data={excludeSpecificArrayObjKeys(assetAllocation,["TICKER"])}
            />
            </AccordionDetails>
            </Accordion>
          </div>
        )}
        {worldRegions.length>0 && (
        <div style={{ width: "100%",padding:"1rem",boxSizing:"border-box",border:"1px solid #ddd",borderRadius:"5px"}}>
          
          <div style={{ width: "100%" ,height:"400px"}}>
              <PieChartGen
                title="World Regions"
                data={getSpecificArrayObjKeys(worldRegions, [
                  "RELATIVE TO CATEGORY",
                  "HOLDING TYPE",
                ])}
                categoryKey={"HOLDING TYPE"}
                metricKey={"RELATIVE TO CATEGORY"}
              />
            </div>
            <Accordion >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Table</AccordionSummary>
              <AccordionDetails>
            <TableGen
              sx={{ flex: 1 }}
              tableLayout="fixed"
              data={excludeSpecificArrayObjKeys(worldRegions,["TICKER"])}
            /></AccordionDetails>
            </Accordion>
            </div>
          )}
 
        {sectorAllocation.length>0 && (
          <div style={{ width: "100%",padding:"1rem",boxSizing:"border-box",border:"1px solid #ddd",borderRadius:"5px"}}>
            <div style={{ width: "100%" ,height:"400px"}}>
              <PieChartGen
                title="Sector Allocation"
                data={getSpecificArrayObjKeys(sectorAllocation, [
                  "RELATIVE TO CATEGORY",
                  "HOLDING TYPE",
                ])}
                categoryKey={"HOLDING TYPE"}
                metricKey={"RELATIVE TO CATEGORY"}
              />
            </div>
            <Accordion >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Table</AccordionSummary>
              <AccordionDetails>
            <TableGen
              sx={{ flex: 1 }}
              tableLayout="fixed"
              data={excludeSpecificArrayObjKeys(sectorAllocation,["TICKER"])}
            />
            </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </>
  )
}

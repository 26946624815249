

import React,{useState,useEffect} from 'react'
import axios from "axios";
import { eodHistorialApiKey } from '../keys';

export default function useDividendCalendar() {
    const [dividendCalendar, setDividendCalendar] = useState([]);
    useEffect(() => {
            getMetricInfo();
      }, []);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`https://eodhd.com/api/eod-bulk-last-day/US?api_token=${eodHistorialApiKey}&type=dividends&fmt=json`);
          setDividendCalendar(responseMetric.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {dividendCalendar}
    
}

import React from 'react';

import { intermarket } from 'content/contentMarket';
import PriceVsEconomy from 'analytics/economy/PriceVsEconomy';
import MarketInfoTemplate from 'analytics/market/MarketInfoTemplate';
import MarketOverview from 'analytics/market/MarketOverview';
import GuideTemplate from '../Template/GuideTemplate';

export default function MarketAnalysis() {
  return (
    <GuideTemplate description={description} steps={steps} name={"Market Analysis"}/>
  )
}

const tickerDict = intermarket
const tickerSelected = Object.keys(intermarket)

const description = `
Market Analysis is an essential process for investors to evaluate different asset classes and predict future market behaviors. It involves a detailed examination of various factors, including economic indicators, technological advancements, and regulatory environments, to identify investment opportunities and risks. 
<br/><br/>By understanding how these factors influence asset classes, investors can devise strategies that align with their risk tolerance and investment objectives. However, market analysis must be approached with caution, as relying solely on past trends without considering evolving market dynamics can lead to misleading conclusions. Effective market analysis combines historical data with current market insights to inform well-rounded investment decisions.`

const steps = [
  {
    label: "Introduction",
    keyPoints: [
      `Gathering information on different asset classes and their market trends helps investors make well-informed investment decisions. It provides a foundation for understanding market dynamics and identifying opportunities.`,
      `By understanding the performance, volatility, and correlation of asset classes, investors can construct balanced portfolios that align with their risk tolerance and investment objectives.`
    ],
    success: [
      "Understanding the importance of market analysis in making informed investment decisions.",
      "Recognizing the role of asset class characteristics in portfolio construction."
    ],
    warning: [
      "Avoid making investment decisions based solely on intuition or speculation.",
      "Be cautious of over-reliance on past performance as a predictor of future outcomes."
    ],
    content: [],
  },
  {
    label: "Asset Class Overview",
    keyPoints: [
      `Understanding different asset classes such as stocks, bonds, crypto, forex, commodities, and real estate is essential for portfolio diversification and risk management. Each asset class offers unique opportunities and risks.`,
      `Investors need to grasp the fundamental characteristics and market behavior of each asset class to optimize their investment strategies.`
    ],
    success: [
      "Recognizing the importance of diversification across various asset classes.",
      "Understanding the unique features and risks associated with different asset classes."
    ],
    warning: [
      "Avoid concentrating investments in a single asset class, which can increase portfolio vulnerability to market fluctuations.",
      "Be cautious of investing in asset classes without thorough research and understanding."
    ],
    content: <MarketOverview/>,
  },
  {
    label: "Trend & Historical Relationships",
    keyPoints: [
      "Analyzing historical relationships between different asset classes helps investors understand how they behave under various market conditions. This analysis can reveal patterns and correlations that inform investment decisions.",
      "For example, stocks and bonds may exhibit negative correlation during periods of economic uncertainty, providing diversification benefits to investors."
    ],
    success: [
      "Identifying logical and robust relationships between asset classes.",
      "Testing the continuity of historical relationships to ensure their relevance in current market conditions."
    ],
    warning: [
      "Avoid relying solely on historical relationships without considering changes in market dynamics.",
      "Be cautious of assuming that historical correlations will persist indefinitely without periodic reassessment."
    ],
    content: <div style={{height:"600px",width:"100%"}}><MarketInfoTemplate info={"Trend Chart"}  tickerDict={tickerDict}
      tickerList={tickerSelected} tickerSelected={tickerSelected}/></div>,
  },
  {
    label: "Impact of Economy",
    keyPoints: [
      `Assessing the impact of the economy on asset classes helps investors anticipate market trends and make informed investment decisions.`,
      `Economic indicators such as interest rates, money supply, GDP growth, inflation, and unemployment rates influence the performance of stocks, bonds, and other asset classes. Understanding these relationships is crucial for effective portfolio management.`
    ],
    success: [
      "Recognizing the importance of economic indicators in investment analysis.",
      "Understanding the concept of 'Never fight the Fed', indicating the significant influence of central bank policies on financial markets."
    ],
    warning: [
      "Avoid overlooking economic indicators or their potential impact on asset prices.",
      "Be cautious of assuming a direct and immediate correlation between economic indicators and asset prices, as market reactions may vary."
    ],
    content: <PriceVsEconomy tickerType={['SPY']}/>,
  },
  {
    label: "Regulation Environment",
    keyPoints: [
      `Understanding the regulatory environment is crucial as regulations can significantly impact asset class performance and market dynamics.`,
      `Changes in regulations, such as monetary policies, tax laws, or industry-specific regulations, can affect investor sentiment, market liquidity, and asset valuations.`,
      `For example, stricter regulations on a particular industry may lead to decreased profitability and lower asset prices.`,
      `Conversely, favorable regulatory changes or deregulation can stimulate investment activity and boost asset prices.`
    ],
    success: [
      "Recognizing the importance of regulatory factors in investment analysis.",
      "Adapting investment strategies to navigate changing regulatory landscapes."
    ],
    warning: [
      "Avoid overlooking the potential impact of regulatory changes on asset prices and market conditions.",
      "Be cautious of regulatory uncertainty and its potential to create market volatility."
    ],
    content: [], 
  },
  {
    label: "Technological Advancement",
    keyPoints: [
      `Technological advancements can significantly impact asset classes, influencing their performance and market dynamics.`,
      `Innovations such as blockchain technology, artificial intelligence, and high-frequency trading have transformed the way assets are traded and valued.`,
      `Technological advancements can create new investment opportunities, increase market efficiency, and attract more capital into specific asset classes.`,
      `For example, the adoption of blockchain technology has revolutionized the cryptocurrency market, leading to increased liquidity and investor participation.`,
      `Similarly, advancements in trading algorithms and automation have accelerated trading speeds and altered market dynamics.`,
    ],
    success: [
      "Recognizing the transformative impact of technology on financial markets and asset classes.",
      "Identifying investment opportunities arising from technological innovations."
    ],
    warning: [
      "Be cautious of technological disruptions that may create market inefficiencies or increased volatility.",
      "Avoid underestimating the regulatory implications of technological advancements, as regulatory responses can shape market outcomes."
    ],
    content: [], // You can add specific content related to technological advancements if needed.
  },
  {
    label: "Performance Evaluation",
    keyPoints: [
      `Evaluating the historical performance of different asset classes provides insights into their return potential and risk characteristics.`,
      `This analysis helps investors assess the relative attractiveness of each asset class in their investment portfolio and make informed allocation decisions.`
    ],
    success: [
      "Understanding the importance of performance evaluation in investment decision-making.",
      "Using historical performance data to inform portfolio allocation and risk management strategies."
    ],
    warning: [
      "Avoid relying solely on past performance as an indicator of future returns.",
      "Be cautious of extrapolating short-term performance trends into long-term investment strategies without considering fundamental factors."
    ],
    content: <MarketInfoTemplate info={"Performance"}  tickerDict={tickerDict}
      tickerList={tickerSelected} tickerSelected={tickerSelected}/>,
  },
  {
    label: "Volatility Assessment",
    keyPoints: [
      `Assessing the volatility of asset classes helps investors understand their risk exposure and potential for price fluctuations.`,
      `Volatility measures such as standard deviation or beta provide insights into the variability of returns and help investors manage risk in their portfolios.`
    ],
    success: [
      "Recognizing the importance of volatility assessment in risk management.",
      "Using volatility measures to adjust portfolio allocation and hedge against downside risk."
    ],
    warning: [
      "Avoid underestimating the significance of volatility in investment decision-making.",
      "Be cautious of relying solely on historical volatility measures without considering changing market conditions or unexpected events."
    ],
    content: <MarketInfoTemplate info={"Volatility"}  tickerDict={tickerDict}
      tickerList={tickerSelected} tickerSelected={tickerSelected}/>,
  },
  {
    label: "Correlation Analysis",
    keyPoints: [
      `Analyzing the correlation between asset classes helps investors construct diversified portfolios that can mitigate risk and enhance returns.`,
      `Correlation measures the degree of relationship between the returns of two asset classes and informs portfolio allocation decisions to achieve optimal risk-adjusted returns.`
    ],
    success: [
      "Understanding the role of correlation analysis in portfolio diversification.",
      "Using correlation data to build balanced portfolios and reduce overall investment risk."
    ],
    warning: [
      "Avoid assuming that correlation indicates causation.",
      "Be cautious of over-reliance on correlation analysis without considering other factors such as market fundamentals or macroeconomic trends."
    ],
    content: <MarketInfoTemplate info={"Correlation"}  tickerDict={tickerDict}
      tickerList={tickerSelected} tickerSelected={tickerSelected}/>,
  },
  {
    label: "Decision Making",
    keyPoints: [
      `This information aids investors in allocating their investment capital across different asset classes based on their risk-return profiles and market outlook.`,
      `It enables them to optimize their portfolio allocation, manage risk effectively, and pursue their financial goals by making informed investment decisions.`
    ],
    success: [
      "Using market analysis to inform investment decision-making.",
      "Implementing portfolio strategies that align with individual risk tolerance and investment objectives."
    ],
    warning: [
      "Avoid making hasty investment decisions without thorough analysis and consideration of risk factors.",
      "Be cautious of emotional biases or market euphoria that may influence decision-making."
    ],
    content: [],
  },
  ];
  
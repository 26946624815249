import React from 'react'
import { useAppInfo } from 'AppState'
import ProfileLogo from 'components/Logo/ProfileLogo'
import { Button, ClickAwayListener, IconButton, Paper } from '@mui/material'
import { createPortal } from 'react-dom'
import { Close } from '@mui/icons-material'
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding'
import { initializeFinancials } from 'appState/initialStateMyFinancials'
import useAddFinancials from 'data/user/financials/useAddFinancials'


export default function FpIntro() {
    const {dispatchMyFinancials,stateMyFinancials,stateApp,dispatchApp} = useAppInfo()
    const UpdateOnboarding = useUpdateOnboarding()
    const {username,onboarding} = stateApp
    const {addFinancials} = useAddFinancials()

    function handleClose(){
      dispatchApp({"notification":{message:"Default Financials Added",open:true}})
      setTimeout(()=>{ const updatedOnboarding = {...onboarding,financials:true}
      UpdateOnboarding(username,updatedOnboarding)},0) 

      // const initialData = initializeFinancials;
      // Object.entries(initialData).forEach(([name, value], index) => {
      //   dispatchMyFinancials({[name]:[...stateMyFinancials.name,...value]});
      //   setTimeout(()=>{addFinancials(value, name)},200*index);
      // });
  
    }

    function handleStart(){
      dispatchMyFinancials({"onBoardingStep":0})
     }
  return (
    <>
    {createPortal
    (<ClickAwayListener onClickAway={handleClose}><Paper 
      style={{position:"absolute",zIndex:"10000",top:"50%",left:"50%"
      ,transform:"translate(-50%,-50%)",display:"flex",
      flexDirection:"column",minWidth:"30%",maxWidth:"40%",width:"auto"
      ,minHeight:"30%",maxHeight:"80%",height:"auto",background:"var(--bgWoodSmoke)"
    ,padding:"2rem",boxSizing:"border-box",gap:"1rem"
    ,overflow:"auto",borderRadius:"4px"}}>

    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <ProfileLogo/>
      <h1 style={{color:"white"}}>{username}</h1>
      <IconButton style={{marginLeft:"auto"}} onClick={handleClose}><Close style={{color:"#F8F8F8"}}/></IconButton>
    </div>
    
   <div style={{flex:1}}>
    <h1 className={`fade-in gradient_text`} style={{fontSize:"3rem",flex:1}}>
      Let’s help you manage your financials and meet your goals.
    </h1>
    </div>
    
    <div className='fade-in' style={{display:"flex",gap:"1rem"}}>
      <Button onClick={handleStart} variant='contained'>Start Your Journey</Button>
      <Button onClick={handleClose}>Continue with Default</Button>
    </div>
    
    </Paper></ClickAwayListener>,document.body)}


    </>
  )
}


   {/* <div  style={{width:"50%",height:"100%"
   ,boxSizing:"border-box",overflow:"hidden"}}>
    <div className={`fade-in-simple`} key={`${contentKey}-visual`}
    style={{position:"relative",borderRadius:"4px"
    ,width:"100%",height:"100%",zIndex:"1000000"
    ,background:"#F8F8F8",padding:"1rem",boxSizing:"border-box",overflow:"hidden",display:"flex",flexDirection:"column",gap:"1rem"}}>
    
    <h1  key={contentKey}
    >
    {mainContent && mainContent?.description}
    </h1>

    <div style={{flex:1,height:"100%",width:"100%"}}>
    {mainContent && mainContent?.content}
    </div>

    </div>
    </div> */}

import React, { useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import PortfolioInfoList from 'myFinancials/Portfolio/PortfolioInfoList'
import { Paper } from '@mui/material'
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating'
import LeftSidebarButton from 'sidebar/LeftSidebar/LeftSidebarButton'
import PortfolioAccountSelection from 'myFinancials/Portfolio/PortfolioAccountSelection'
import { PageMainContentTitle } from 'components/Font/Title'

export default function Transactions() {
  const [tabIndex,setTabIndex] = useState(0)
  const [buttonIndex,setButtonIndex] = useState(0)
  const tabList = ["Ticker","All"]
  
  const buttonList = ["Current Position","Transaction History"]

  const tabContent = []
  tabContent[0] = <PortfolioInfoList menu={tabIndex === 0 ?"Current Position Ticker":"Current Positions"}/>
  tabContent[1] = <PortfolioInfoList menu={tabIndex === 0 ?"Transaction Ticker":"Transactions All"}/>

  return (
    <div style={{padding:"0.25rem",width:"100%"
    ,boxSizing:"border-box",display:"flex",gap:"0.5rem",height:"100%",overflow:"auto"}}>
<LeftSidebarFloating header={"Transactions"}>
    <PortfolioAccountSelection sx={{minWidth:"auto"}}/>
        <div style={{display:"flex",width:"100%",flexDirection:"column",gap:"0.5rem",marginTop:"1rem"}}>
        {buttonList.map((obj,index)=><LeftSidebarButton 
        key={index}
        selected={buttonList[buttonIndex]===obj}
        onClick={()=>setButtonIndex(index)}
        name={obj}/>)}
        </div>
    </LeftSidebarFloating>
    <Paper style={{display:"flex",flexDirection:"column",width:"100%",flex:1
    ,minWidth:0,overflow:"auto"}}>
        <PageMainContentTitle>{buttonList[buttonIndex]}</PageMainContentTitle>
        <TabLine tabIndex={tabIndex} 
        tabList={tabList}
        handleTabChange={(i)=>setTabIndex(i)}/>
        <div style={{width:"100%",height:"100%",flex:1,overflow:"auto"}}>
             {tabContent[buttonIndex]}
        </div>
    </Paper>
    
    </div>
  )
}

import React from 'react';
import ProductFeatureTemplate from './ProductFeatureTemplate';
import Video from 'components/Media/Video';
import Animation from '../../animation/Animation';
import GetStartedButton from './GetStartedButton';


export default function ProductFeature5() {
 
  const content =  
  <div style={{width:"100%",padding:"1rem",position:"relative",
  boxSizing:"border-box",display:"flex"}}>

    
<div style={{flex:1,minWidth:0}}>
    <Video src={"\Screener.mp4"}/>
    
    </div>
    

    <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"20%",alignItems:"center"}}>
    <div style={{width:"100%"}}>
    <Animation type='Searching'/>
    </div>
    <GetStartedButton/>
    </div>

    </div>

  return (
    
    <ProductFeatureTemplate content={content} pageNumber={3} id="pageScroll4"/>
   
  );
}

import { Alert } from '@mui/material';
import AccordionTSCustom from 'components/Surface/AccordionTSCustom';
import useCompanyVsIndustry from 'data/company/useCompanyVsIndustry';
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

export default function CompanyComments({ tickerList }) {
    const { statsVsIndustry, loading } = useCompanyVsIndustry({ tickerList: tickerList });
    const comments = !loading ? statsVsIndustry[0]?.Comments : {};
    const positiveComments = comments?.Positive || [];
    const negativeComments = comments?.Negative || [];

    const titleSuccess = <div style={{display:"flex",alignItems:"center",gap:"0.5rem"}}>
    <ThumbUpIcon fontSize='small' sx={{color:"green"}}/> 
    {loading && <></>}
    {!loading && <> 
    {positiveComments.length>0? `${positiveComments.length} major strengths`
    :"No major strengths found"}</>}</div>

    const titleWeakness = <div style={{display:"flex",alignItems:"center",gap:"0.5rem"}}><ThumbDownIcon fontSize='small' sx={{color:"red"}}/> 
            {loading && <></>}
            {!loading && <> 
            {negativeComments.length>0? `${negativeComments.length} major weaknesses`:"No major weakness found"}
            </>}
            </div>
    return (
        <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>  
        <AccordionTSCustom title={titleSuccess} 
        
        sx={{background:"var(--colorSuccess)"}}>
            <div style={{ background: "var(--colorSuccess)", borderRadius: "5px", padding: "0.25rem" }}>
                {/* <div><h4 style={{fontWeight:"bold"}}>Strengths</h4> </div> */}
                {positiveComments.length>0 ? positiveComments.map(( comments, index) => (
                    <div key={index}>
                       <Alert severity="success">{comments}</Alert>
                    </div>
                )) : <Alert severity="success">No major strengths found.</Alert>}
            
            </div></AccordionTSCustom>
            <AccordionTSCustom 
            title={titleWeakness}     
            sx={{background:"var(--colorWarning)"}}>
            <div style={{ background: "var(--colorWarning)", borderRadius: "5px", padding: "0.25rem" }}>
                {negativeComments.length>0 ? negativeComments.map((comments, index) => (
                    <div key={index}>
                       <Alert severity="warning">{comments}</Alert>
                    </div>
                )) : <Alert severity="warning">No major weaknesses found.</Alert>}
            </div>
            </AccordionTSCustom>
        </div>
    );
}


import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAppInfo } from "AppState";
import { Button, Tooltip } from "@mui/material";
import PeerIn from "./PeerIn";
import PeerPriceTable from "./PeerPriceTable";


function Peers() {
  
  const {dispatchSearch} = useAppInfo();

  function handleAddPeers(){
    dispatchSearch({"addPeers":true})
  }

  return (
    <div
     className="containerColumnFlex"
    >

        <div
          style={{
            display: "flex",
            alignItems: "center",
             gap:"0.5rem",padding:"0.25rem"
          }}
        >
          <PeerIn />
          <Tooltip title="Add Peers">
            <Button
            >
              <AddIcon onClick={() => handleAddPeers()} />
            </Button>
          </Tooltip>
        </div>

      <div
          style={{flex:1,overflow:"auto",width:"100%"}}
        >
          <PeerPriceTable/>
        </div>
    </div>
  );
}

export { Peers };

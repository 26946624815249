import React,{useEffect,useRef} from 'react';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import styles from "./Modal.module.css"
import { Button, ClickAwayListener } from '@mui/material';
import { createPortal } from 'react-dom';
import BackgroundVideo from '../Media/BackgroundVideo';

const Modal = ({ onClose,children,width="50%", height="70%" ,closeIcon=true,sx={}
,sxBackground={},blurBackground=false,backgroundVideo=false}) => {
  // Function to handle clicks outside the modal
  const modalBackgroundRef = useRef(null);

  // Function to handle clicks outside the modal
  const handleOutsideClick = (e) => {
    if (modalBackgroundRef.current === e.target) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    const appContent = document.getElementById('appLayout');
    const modalContainer = document.createElement('div');
    appContent.parentNode.insertBefore(modalContainer, appContent.nextSibling);

    return () => {
      modalContainer.remove();
    };
  }, []);

  return (
    <>
    {createPortal (
    <div
    id="modal"
    ref={modalBackgroundRef}
      className={styles["modalBackground"]}
      style={{...sxBackground,backdropFilter:blurBackground && "blur(10px)"}}
      onClick={handleOutsideClick}>
      {backgroundVideo && <div style={{position:"absolute",width:"100%",height:"100%"}}>
      <BackgroundVideo/>
      </div>}
      <div className={styles["modalContainer"]} style={{width:`${width}`,height:`${height}`,...sx}}>
      
      {closeIcon && <Button style={{background:"black",position:"absolute",top:5,right:"-40px",padding:"0",margin:"0",minWidth:"0"}}><CloseSharpIcon onClick={onClose} style={{color:"white"}}/></Button>}
      
        {children}
        
      </div>
     
    </div>, document.body)}
    </>
  );
};

export default Modal;
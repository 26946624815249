import React from "react";
import Messages from "./Messages";
import Input from "./Input";

const Chat = () => {
  return (
    <div style={{flex:1,width:"100%",display:"flex",flexDirection:"column"}}>
      <div style={{flex:1,width:"100%",overflow:"auto",padding:"0.5rem",boxSizing:"border-box"}}>
      <Messages />
      </div>
      <Input/>
    </div>
  );
};

export default Chat;
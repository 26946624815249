import { Typography } from '@mui/material'
import React from 'react'


export function PageMainContentTitle({children}) {
  return (
    <Typography variant='pageContentTitle'>{children}</Typography>
  )
}




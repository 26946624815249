import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { useAppInfo } from 'AppState';
import useShareholder from 'data/company/useShareholder';
import PieChartCategory from 'components/Charts/PieChartCategory';
import TabLine from 'components/Tabs/TabLine';
import {PageMainContentTitle} from 'components/Font/Title';

export default function CompanyShareholder() {
  const {stateStock,stateTicker} = useAppInfo()
  const companyName = stateStock.coInfo?.[dfin.coName]
  const companySelected = stateStock.companySelected
  const peerInfo = stateStock.peerInfo
  const peerSelected = stateTicker.peerSelected
  const {shareholders,loadingShareholders} = useShareholder(peerSelected)
  const [data,setData] = useState([])
  const [dataInstitution,setDataInstitution] = useState([])
  const [dataFund,setDataFund] = useState([])
  const [dataFocus,setDataFocus] = useState([])

  useEffect(()=>{ 
    if (shareholders.length>0 && !loadingShareholders){
    const coShareholder = shareholders.filter(obj => obj[dfin.ticker] === companySelected)
    const columnHeadings = coShareholder.length > 0 && Object.keys(coShareholder
      [0]).filter((key) => key !== dfin.ticker);
      
    const data =  coShareholder.length > 0 && getSpecificArrayObjKeys(coShareholder,columnHeadings)
    const dataInstitution = data.filter(obj=>obj["HOLDING TYPE"] === "Institutions")
    const dataFund = data.filter(obj=>obj["HOLDING TYPE"] === "Funds")
    setData(data)
    setDataFund(dataFund)
    setDataInstitution(dataInstitution)  
    
  }
  },[companySelected,peerInfo,shareholders])
  
  const [tabIndex,setTabIndex] = useState(0)
  const tabList = ["Overview","Institutions","Funds"]

  useEffect(()=>{
    if (data.length > 0){
    if (tabList[tabIndex] === "Overview"){
      setDataFocus(data)
    }
    else if (tabList[tabIndex] === "Institutions"){
      setDataFocus(dataInstitution)
    }
    else{
      setDataFocus(dataFund)
    }}
  },[tabIndex,data,dataInstitution,dataFund])
  

  return (
      <div className='containerColumnFlexNoGap'>
        <PageMainContentTitle>{companyName}'s Shareholders</PageMainContentTitle> 
        <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>

        <>{data.length > 0 && <>
  <div style={{display:"flex",width:"100%",gap:"0.5rem",height:"400px",minHeight:"400px"}}>
  {tabIndex === 0 && <PieChartCategory weightType='weighted' weightCategory={["CURRENT SHARES"]} category={["HOLDING TYPE"]} data={dataFocus}/>}
  <PieChartCategory weightType='weighted' weightCategory={["CURRENT SHARES"]} category={["NAME"]} data={dataFocus}/>
  </div>
   <TableGen sxWrapper={{height:"auto"}} data={dataFocus}/> 
  </>}</>
        </div>
  );
};


import React from 'react'
import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecSizeShare() {
  const {stateIndustry} = useAppInfo()

  return (
        <IndSecMetricSummary
        infoName={"Size & Share Summary"}
        defaultMet={[dfin.marketCap,dfin.revenue,dfin.assets,dfin.ebitda]}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
       
  )
}

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Alert, ClickAwayListener, IconButton, Snackbar } from '@mui/material';
import styles from "./Modal.module.css";

export default function ModalOnboarding({ targetRef="nav-links",children, closeIcon = true,onClose}) {
  const modalRef = useRef(null);

  const [targetRect, setTargetRect] = useState(null);
  const [childStyle, setChildStyle] = useState({});
  
  useEffect(() => {
    const target = document.getElementById(targetRef);
    if (target) {
      const rect = target.getBoundingClientRect();
      setTargetRect({
        height: `${rect.height}px`,
        width: `${rect.width}px`,
        top: `${rect.top}px`,
        left: `${rect.left}px`
      });

      // Calculate child style dynamically based on available space
      const childPosStyle = calculateDynamicChildPosition(rect);
      setChildStyle(childPosStyle);
    }
  }, [targetRef]);


  const [clickAway,setClickAway] = useState(false)
  return createPortal(
   <div 
   onClick={(e)=>{e.stopPropagation();setClickAway(true)}}
   ref={modalRef} 
   style={{ position: 'fixed', width: '100vw', height: '100vh', 
   left: 0, top: 0, backgroundColor: 'rgba(0,0,0,0.10)',
   zIndex: 1000}}>
    <>
        <>
      <div 
      className={styles.borderAnim}
      style={{ ...targetRect, position: 'absolute', zIndex: 10000000, boxSizing: "border-box"}} />

      <div style={{ margin: "1rem", position: 'absolute',background:"var(--bgWoodSmoke)", 
      borderRadius: "4px", padding: "1rem", zIndex: 10000000, ...childStyle }}>
        <div style={{ display: "flex", gap: "1rem"}}>
          <div style={{ flex: 1 }}>
            {children}
          </div>
          <div style={{ display: "flex", alignItems: "start" }}>
            {closeIcon && (
              <IconButton sx={{color:"white"}}>
                <CloseSharpIcon onClick={onClose} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      </>
      </>

      
      {/* <Snackbar 
            anchorOrigin={{  vertical: 'bottom',
            horizontal: 'center',}}
            open={clickAway} autoHideDuration={6000} onClose={() => setClickAway(false)}>
        <Alert onClose={() => setClickAway(false)} severity="warning" sx={{ width: '100%',fontSize:"1rem"}}>
          Do you want to end? 
        </Alert>
      </Snackbar> */}
               </div>,
    document.body
  );
};


function calculateDynamicChildPosition(rect) {
    const childWidth = 250;  // Fixed width for the modal
    const childHeight = 300; // Fixed height for the modal
    const margin = 30;       // Margin from the viewport edges
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
  
    const { height, width, top, left } = rect;
  
    // Define potential centered positions for the modal
    const positions = [
      {
        position: 'bottom',
        space: viewportHeight - top - height - childHeight - margin,
        style: {
          top: `${top + height + margin}px`,
          left: `${Math.max(margin, Math.min(left + (width / 2) - (childWidth / 2), viewportWidth - childWidth - margin))}px`
        }
      },
      {
        position: 'top',
        space: top - childHeight - margin,
        style: {
          top: `${top - childHeight - margin}px`,
          left: `${Math.max(margin, Math.min(left + (width / 2) - (childWidth / 2), viewportWidth - childWidth - margin))}px`
        }
      },
      {
        position: 'right',
        space: viewportWidth - left - width - childWidth - margin,
        style: {
          top: `${Math.max(margin, Math.min(top + (height / 2) - (childHeight / 2), viewportHeight - childHeight - margin))}px`,
          left: `${left + width + margin}px`
        }
      },
      {
        position: 'left',
        space: left - childWidth - margin,
        style: {
          top: `${Math.max(margin, Math.min(top + (height / 2) - (childHeight / 2), viewportHeight - childHeight - margin))}px`,
          left: `${left - childWidth - margin}px`
        }
      }
    ];
  
    // Sort positions by the available space in descending order
    positions.sort((a, b) => b.space - a.space);
    
    // Select the position with the most available space that can fit within the viewport
    for (let pos of positions) {
      if (pos.space > 0 && fitsWithinViewport(pos.style, childWidth, childHeight, viewportWidth, viewportHeight, margin)) {
        return pos.style;
      }
    }
  
    // If no suitable position found, adjust the modal to fit within the viewport, considering the margin
    return adjustToFitViewport(positions[0].style, childWidth, childHeight, viewportWidth, viewportHeight, margin);
}

function fitsWithinViewport(style, childWidth, childHeight, viewportWidth, viewportHeight, margin) {
    const calcLeft = parseFloat(style.left);
    const calcTop = parseFloat(style.top);

    return (
      calcLeft >= margin &&
      calcLeft + childWidth <= viewportWidth - margin &&
      calcTop >= margin &&
      calcTop + childHeight <= viewportHeight - margin
    );
}

function adjustToFitViewport(style, childWidth, childHeight, viewportWidth, viewportHeight, margin) {
    let adjustedStyle = {...style};
    const calcLeft = parseFloat(style.left);
    const calcTop = parseFloat(style.top);

    // Adjust position to keep the modal within the viewport and respect margins
    adjustedStyle.left = `${Math.max(margin, Math.min(calcLeft, viewportWidth - childWidth - margin))}px`;
    adjustedStyle.top = `${Math.max(margin, Math.min(calcTop, viewportHeight - childHeight - margin))}px`;

    return adjustedStyle;
}

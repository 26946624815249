import { Alert, Button } from '@mui/material'
import { defaultFieldBlank, goalTypes } from 'appState/initialStateMyFinancials'
import React, { useEffect, useMemo, useState } from 'react'
import { InputAmount, InputDate, InputForecastPeriod, InputGrowthRate, InputName, InputPeriod, InputPriority, InputType } from 'myFinancials/FormFields/FieldFormCells';
import useFieldForm from 'myFinancials/FormFields/useFieldForm';

export default function GoalIndividualDetailEdit({goalData,onClose}) {

  const data = useMemo(() => [goalData], [goalData]); // Memoize goalData in an array
  const {
    fields,
    error,
    handleChange,
    handleDeleteField,
    handleAddField,
    handleRemoveField,
    handleSubmit,
  } = useFieldForm({storeName:"goalsPeriodic",data:data,defaultField:goalData})
  

  
  return (
  <>
  
{ <>
<div style={{display:"flex",gap:"1rem",width:"100%",flexWrap:"wrap",height:"100%"}}>
  <div style={{background:"#fafafa",width:"30%",minWidth:"300px",borderRadius:"5px"}}>
  <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  {fields.map((fieldObj,index)=>
  <form  onSubmit={(e)=>{handleSubmit(e);onClose()}} key={index} style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
  <InputType typeOptions={goalTypes} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPriority label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputName label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputGrowthRate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputAmount label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputDate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputForecastPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  {error && <Alert severity='warning'>{error}</Alert>}
  <Button variant='contained' type='submit'>Save</Button>
    </form>)}
  
  </div>
  
  
  </div>
 
</div>
</>}
</>
  )
}
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
require("highcharts/modules/accessibility")(Highcharts);

export default function PieChart({
  data,
  metricSelected,
  dataLabel,
  title,
  width="100%",
  exporting=false
}) {
  
 
  const chartData = {
    series: metricSelected.map((metric) => ({
      name: metric,
      y: data[0][metric], // Access the 'data' property of each data item
    })),
  };
  
  const options = {
    chart: {
      type:'pie',
      panning: false,
    },
    title: {
      text: title,
    },
    series:[{
        innerSize: '20%',
        name: 'Percentage',
        colorByPoint: true,data:chartData.series}],
    tooltip: {
      pointFormat: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)', // Show name, value, and percentage on hover
    },
   
    accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: dataLabel,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        
        },
      },
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true,
            dataLabels: [{
              enabled: false,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              distance: -30, // Adjust the distance to move the labels outside the pie chart
              connectorShape: 'straight' // Use a straight connector line
            }],
        }
    },
    legend: {
      verticalAlign: 'bottom',
      align:"left",
      width: '100%',
      borderWidth: 0,
  },
  exporting:{
    enabled:exporting,
  },
    credits: {
      enabled: false, // Hide Highcharts.com credits
    },
    accessibility: {
      enabled: false, // Include the accessibility module
    },
  };

  return (
    <>
      <div style={{height:"100%",overflowY:"auto",width:width}}>
      
      <HighchartsReact highcharts={Highcharts} options={options} 
    containerProps={{ style: { height: '100%',width:"100%"} }}/>
  
  </div>
  </>
  );
}


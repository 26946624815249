import React from 'react';

import TableGen from 'components/Table/TableGen';

import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { summaryStats } from './etfkeys';

export default function EtfMfSummaryStats({generalData}) {
    
    const tableData = getSpecificArrayObjKeys(generalData, summaryStats).flatMap(obj => {
        return Object.entries(obj).map(([key, value]) => {
            const table = {};
            table["Metric"] = key;
            table["Value"] = value;
            return table;
        });
    });
  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",gap:"1rem"}}>
   
    <div style={{border:"3px solid #F8F8F8"
     ,borderRadius:"5px",width:"100%",boxSizing:"border-box",flexWrap:"wrap",padding:"0.3rem"}}>
     <h2 style={{color:"gray",fontWeight:"bold"}}>Description</h2>
     
     <p style={{width:"50%"}}>{generalData[0]?.["DESCRIPTION"]}</p>
     <h4>Country: {generalData[0]?.['COUNTRY NAME']}</h4>
     </div>

     <div style={{border:"3px solid #F8F8F8"
     ,borderRadius:"5px",width:"100%",boxSizing:"border-box",flexWrap:"wrap",padding:"0.3rem"}}>
    <h2 style={{color:"gray",fontWeight:"bold"}}>Links</h2>
     <div style={{display:"flex",gap:"1rem"}}>
   
    <div>
    <span>ETF Provider Url:</span><a target='blank' href={`${generalData[0]?.['COMPANY URL']}}`}>{`${generalData[0]?.['COMPANY URL']}`}</a>
    </div>
    <div>
    <span>ETF Url:</span><a target='blank' href={`${generalData[0]?.['ETF URL']}`}>{`${generalData[0]?.['ETF URL']}`}</a>
    </div>
    </div>
    </div>
    <div style={{border:"3px solid #F8F8F8"
     ,borderRadius:"5px",width:"100%",boxSizing:"border-box",flexWrap:"wrap",padding:"0.3rem"}}>
    <h2 style={{color:"gray",fontWeight:"bold"}}>Summary Metrics</h2>
    <TableGen tableLayout='fixed' data={tableData}/>
    </div>
    </div>
    
  )
}

import { intermarket } from "content/contentMarket"


const initialStateMarket = {
    assetClassSel:"All",
    assetClass : ["Today","Stocks","Crypto","ETF & MF","Fixed Income","Commodities","Forex","All"], 
    menuSel:"Trend Chart",
    contentSel:"Trend Chart",
    closeInterMarket : [],
    trendTableInterMarket:[],
    marketInfoLoadingInterMarket:false,
    tickerList:Object.keys(intermarket),
    tickerDict:intermarket,
    tickerSelected:Object.keys(intermarket),
    commodityCategorySel:"All",
    currencyCategorySel:"World",
}

export {initialStateMarket}
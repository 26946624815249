import { Paper } from '@mui/material'
import React from 'react'
import TrendConditions from './TrendConditions'
import TradeSignals from './TradeSignals'

export default function TradeConditions() {
  return (
    <div style={{width:"100%",height:"100%",padding:"0.25rem",boxSizing:"border-box"}}>
      <Paper style={{minHeight:"100%",width:"100%",display:"flex"
      ,flexDirection:"column",gap:"1rem",padding:"0.5rem",boxSizing:"border-box",overflow:"auto"}}>
        <h2 style={{fontWeight:"bold"}}>Trade Conditions</h2>
        <h2 style={{borderBottom:"1px solid #ddd"}}>Trend & Relative Strength</h2>
        <TrendConditions/>

        <h2 style={{borderBottom:"1px solid #ddd"}}>Technical Signals</h2>
        <TradeSignals/>
      </Paper>
    </div>
  )
}


import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import React from 'react';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';

require("highcharts/modules/accessibility")(Highcharts);

export default function PieChartPeers({
  data,
  metricSelected,
  dataLabel,
  chartLegend,
  title,
  exporting=false
}) {
  
  const {stateStock} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const companySelected = stateStock.companySelected
  const chartData = {
    series: metricSelected.map((metric) => ({
      name: metric,
      data: data.map((data) => data[metric]), // Access the 'data' property of each data item
    })),
    categories: data.map((data) => data[dfin.ticker]),
  };

  const selectedTickerIndex = data.map((data) => data[dfin.ticker]).indexOf(companySelected);
  
  const options = {
    chart: {
      type:'pie', // Change the chart type to 'pie'
      // height: '400', // Set the height to fill the container
      panning: false,
      options3d: {
        enabled: true,
        alpha: 45
    }
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)', // Show name, value, and percentage on hover
    },
   
    accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 45,
        dataLabels: {
          enabled: dataLabel,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        
        },
        events: {
          click: function (event) {
            const category = event.point.name;
            handleTickerChange({ticker:category});
          },
        },
      },
    },
    series: [
      {
        innerSize:"20%",
        name: 'Value',
        data: chartData.series[0].data.map((value, index) => ({
          name: chartData.categories[index],
          y: value,
          color: selectedTickerIndex === index ? 'black' : undefined, // Set the color of the selected ticker to black
          sliced:  selectedTickerIndex === index ? true : false,
          selected:  selectedTickerIndex === index ? true : false,
        })),
      },
    ],
    exporting:{
      enabled:exporting,
    },
    credits: {
      enabled: false, // Hide Highcharts.com credits
    },
    accessibility: {
      enabled: false, // Include the accessibility module
    },
  };

  return (
    <div style={{height:"100%",overflowY:"auto",width:"100%"}}>
      
    <HighchartsReact highcharts={Highcharts} options={options} 
    containerProps={{ style: { height: '100%',width:"100%"} }}/>
  
  </div>
  );
}


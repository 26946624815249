import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig/firebaseConfig";
import { useAppInfo } from "AppState";
import { Alert, ClickAwayListener } from "@mui/material";
import ProfileLogo from "components/Logo/ProfileLogo";
import TextInput from "components/Inputs/TextInput";


const Search = () => {
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);

  const { stateApp,dispatchChats } = useAppInfo();
  const {userDetails} = stateApp
  const [users, setUsers] = useState([]); // Changed to handle multiple users
  const [usersDropdown,setUsersDropdown] = useState(true)
  useEffect(() => {
    const handleSearch = async () => {
      // Clear current users and error state at the beginning of a new search
      setUsers([]);
      setErr(false);

      if (username.trim() === "") {
        return; // Avoid searching when the input is empty
      }
  
      const searchString = username;
      const endString = searchString.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
     
      const q = query(
        collection(db, "users"),
        where("displayName", ">=", searchString),
        where("displayName", "<=", searchString + "\uf8ff"),
        // where("displayName", "<", endString)
      );

      try {
        const querySnapshot = await getDocs(q);
        const foundUsers = [];
        querySnapshot.forEach((doc) => {
          foundUsers.push(doc.data());
        });
        setUsers(foundUsers);
      } catch (error) {
        console.error("Search error:", error);
        setErr(true);
      }
    };
    if(username !== ""){
    setUsersDropdown(true)}
    else {
      setUsersDropdown(false)
    }
    handleSearch();
  }, [username]); // Dependency array, re-run the effect when username changes


  const handleSelect = async (user) => {
    dispatchChats({"chatting":true})
    setUsersDropdown(false)
    //check whether the group(chats in firestore) exists, if not create
    if (user.uid === userDetails.uid){
      return alert("This is your account!")
    }
    const combinedId =
      userDetails.uid > user.uid
        ? userDetails.uid + user.uid
        : user.uid + userDetails.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", userDetails.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: userDetails.uid,
            displayName: userDetails.displayName,
            photoURL: userDetails.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) {}
    
    dispatchChats({"user":user,"chatId":combinedId})
    setUser(null);
    setUsername("")
  };
  
  return (
    <div style={{position:"relative",width:"100%",flex:1}}>
      <>
        <TextInput
          type="search"
          size="small"
          variant="outlined"
          placeholder="Find a user"
          onChange={(v) => setUsername(v)}
          value={username}
        />
      </>
      {err && <span>User not found!</span>}
      {usersDropdown && 
      <ClickAwayListener onClickAway={()=>setUsersDropdown(false)}>
      <div style={{display:"flex",flexDirection:"column",zIndex:"100",
      background:"white",boxSizing:"border-box",
      padding:"1rem",boxShadow:"var(--boxShadowGeneral)",gap:"0.5rem",
      width: "100%",position:"absolute",top:"100%"}}>
      {users.length===0 && <Alert severity="warning">No Users Found!</Alert>}
      {(users.length>0) && users.map((user,index) => (
        <div key={index} style={{cursor:"pointer",display:"flex",gap:"0.5rem"}} onClick={() => handleSelect(user)}>
          <ProfileLogo profileType="nonOwner" imgSrc={user.photoURL} displayName={user?.displayName}/>
          <h4 style={{ color: "black",fontWeight:"bold"}}>{user.displayName}</h4>
        </div>
      ))}
      </div>
      </ClickAwayListener>}
      
    </div>
  );
};
export default Search;
import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import MultiSelect from 'components/Inputs/MultiSelect';
import SelectMarket from 'components/Inputs/SelectMarket';
import { dfin, performanceDict } from 'content/constants';
import TableGen from 'components/Table/TableGen';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { useAppInfo } from 'AppState';

export default function Hotlist() {
  const {stateScreener} = useAppInfo()
  const [hotlistType,setHotListType] = useState("Top Performers")
  const [tableToDisplay,setTableToDisplay] = useState([])
  const [marketCapSel,setMarketCapSel] = useState(["Mega Cap"])
  const [table,setTable] = useState([])
  const [perfPeriod,setPerfPeriod] = useState("3M")
  const [nTicks,setNTicks] = useState(10)
  
  const [dropdownMode,setDropdownMode] = useState(true)
  const defaultCols = [dfin.coLogo,dfin.ticker,"CLOSE"]
  const [cols,setCols] = useState(defaultCols)
  useEffect(() => {
    if (stateScreener.stocks){
      const filteredTable = stateScreener.stocks
      .filter(obj=> obj[dfin.country] === "USA" && marketCapSel.includes(obj[dfin.mScale]))
      .sort((a, b) => b[dfin.marketCap] - a[dfin.marketCap]);
      setTable(filteredTable);
    }
  }, [marketCapSel, stateScreener.stocks]);

  useEffect(()=>{
    let tableToDisplay = table
    const performancePeriod = performanceDict[perfPeriod]
    if (hotlistType==="Top Performers"){
      tableToDisplay = tableToDisplay.filter(obj=>obj[performancePeriod]>0).sort((a,b)=>b[performancePeriod] - a[performancePeriod])
      setCols([...defaultCols,performancePeriod])
    }
    else if (hotlistType==="Top Losers"){
      tableToDisplay = tableToDisplay.filter(obj=>obj[performancePeriod]<0).sort((a,b)=>a[performancePeriod] - b[performancePeriod])
      setCols([...defaultCols,performancePeriod])
    }
    else if (hotlistType==="Top Value"){
      tableToDisplay = tableToDisplay.sort((a,b)=>b[dfin.MoS] - a[dfin.MoS])
      setCols([...defaultCols,dfin.pe,dfin.MoS])
    }
    else if (hotlistType==="Top Growth"){
      tableToDisplay = tableToDisplay.sort((a,b)=>b[dfin.growthRating] - a[dfin.growthRating])
      setCols([...defaultCols,dfin.growthRating])
    }
    else if (hotlistType==="Top Dividend"){
      tableToDisplay = tableToDisplay.sort((a,b)=>b[dfin.dividendYield] - a[dfin.dividendYield])
      setCols([...defaultCols,dfin.dividendYield])
    }
    else{
      tableToDisplay = tableToDisplay
      setCols([...defaultCols,dfin.performance1D,dfin.marketCap])
    }
    setTableToDisplay(tableToDisplay.splice(0,nTicks))
  },[hotlistType,table,nTicks])

  function handleMarketCap(v) {
    const mScaleSel = v.includes("All") ? ["Mega Cap", "Large Cap", "Mid Cap", "Small Cap", "Micro Cap"] : v;
    setMarketCapSel(mScaleSel);
  }
  const hotListOptions = ["Top Performers","Top Losers","Top Value","Top Growth","Top Dividend","Top Market Cap"]
  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%"}}>
   
   <div style={{padding:"0.25rem",display:"flex"}}>
    <Button variant='highlight' sx={{flex:1}} onClick={()=>setDropdownMode(!dropdownMode)}>
    
      <h4 style={{flex:1}}>{`Hotlist Type: ${hotlistType} ${["Top Performers","Top Losers"].includes(hotlistType)? perfPeriod:""}`}</h4>
      
      {dropdownMode && <ExpandMoreIcon />}
      {!dropdownMode && <KeyboardArrowUpIcon />}
    </Button>
    </div>
    {dropdownMode && 
    <div style={{flex:1,overflowY:"auto",width:"100%",height:"100%"}}>
    {tableToDisplay.length>0 && <TableGen tableStyle='tableTicker' tableLayout='fixed' 
    logo={true} 
    data={getSpecificArrayObjKeys(tableToDisplay,cols)}/>}
    </div>}

    {!dropdownMode && 
    <div style={{overflowY:"auto",width:"100%",height:"90%"}}>
    
    <h3 style={{padding:"1rem"}}>Hotlist Categories</h3>
    {hotListOptions.map(option=>
    <div style={{display:"flex"}} key={option}>
    <Button 
    style={{width:"100%",border:"none",fontSize:"1rem"
    ,padding:"1rem",textAlign:"left",borderRadius:"0",alignItems:"left",
    justifyContent:"flex-start"}} 
    key={option} 
    onClick={()=>{
      setHotListType(option);
      setDropdownMode(true);
    }}>{option}</Button>
    {["Top Performers","Top Losers"].includes(option) && 
    <select key={option} defaultValue={perfPeriod} values={perfPeriod} onChange={(e)=>setPerfPeriod(e.target.value)}>
      {Object.keys(performanceDict).map(perfOptions=><option key={perfOptions}>{perfOptions}</option>)}
      </select>}
    </div>)}
    
    <div style={{display:"flex",alignItems:"center"}}>
    <h3 style={{padding:"1rem 0.2rem 1rem 1rem"}}>Number of Tickers to Display:</h3>
    <div>
    <input onChange={(e)=>setNTicks(e.target.value)} type='number' min={5} max={50} value={nTicks}></input>
    </div>
    </div>
    <h3 style={{padding:"1rem"}}>Filters</h3>
    <div style={{width:"100%",maxWidth:"100%",display:"flex",flexWrap:"wrap"}}>
    
    <SelectMarket/>    
    <MultiSelect label={"Market Cap"} 
    options={["All","Mega Cap","Large Cap","Mid Cap","Small Cap","Micro Cap"]} 
    values={marketCapSel} onChange={handleMarketCap}/>
    </div>
    </div>}
    </div>
  )
}

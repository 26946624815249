import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { numberDecimal } from 'utils/utilGeneral';

const ForecastTable = ({ fcfBeg, discountRate, growthRate, forecastPeriod, terminalRate, nonOperatingValue, sharesOutstanding }) => {
  // Function to calculate present value
  const calculatePV = (value, discountRate, period) => {
    return numberDecimal(value / Math.pow(1 + discountRate / 100, period));
  };

  // Calculate forecast data
  const [forecastData, setForecastData] = useState([]);
  const [terminalValue, setTerminalValue] = useState();
  const [totalValue, setTotalValue] = useState();
  const [discountedTV, setDiscountedTV] = useState();

  useEffect(() => {
    let currentFCF = fcfBeg;
    let forecastData = [];
    for (let i = 0; i <= forecastPeriod; i++) {
      forecastData.push(currentFCF);
      if (i < forecastPeriod) {
        currentFCF *= (1 + growthRate / 100); // Grow FCF with growth rate
      }
    }
    
    const terminalValue = (currentFCF * (1 + growthRate / 100)) / (discountRate / 100 - terminalRate / 100);
    const discountedTerminalValue = numberDecimal(terminalValue / Math.pow(1 + discountRate / 100, forecastPeriod));
    const totalValue = forecastData.reduce((acc, curr, index) => acc + parseFloat(calculatePV(curr, discountRate, index)), 0) + parseFloat(discountedTerminalValue);
    setForecastData(forecastData);
    setTerminalValue(terminalValue);
    setDiscountedTV(discountedTerminalValue);
    setTotalValue(numberDecimal(totalValue));
  }, [fcfBeg, growthRate, forecastPeriod, terminalRate,discountRate]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Free Cash Flow (growing @ {growthRate}%)</TableCell>
            <TableCell>Discount Rate</TableCell>
            <TableCell>Present Value of Free Cash Flow</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {forecastData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index}</TableCell>
              <TableCell>{numberDecimal(row)}</TableCell>
              <TableCell>{discountRate}%</TableCell>
              <TableCell>{calculatePV(row, discountRate, index)}</TableCell>
            </TableRow>
          ))}
          {/* Terminal value row */}
          <TableRow>
            <TableCell colSpan={3}>Discounted Terminal Value</TableCell>
            <TableCell>{discountedTV}</TableCell>
          </TableRow>
          {/* Total value row */}
          <TableRow>
            <TableCell colSpan={3}>Total Value (Enterprise Value)</TableCell>
            <TableCell>{totalValue}</TableCell>
          </TableRow>

          {/* Non-Operating Value deduction */}
          <TableRow>
            <TableCell colSpan={3}>Non-Operating Value (+ Cash & Cash Equivalents - Debt)</TableCell>
            <TableCell>{numberDecimal(nonOperatingValue)}</TableCell>
          </TableRow>
          {/* Non-Operating Value deduction */}
          <TableRow>
            <TableCell colSpan={3}>Total Equity Value</TableCell>
            <TableCell>{numberDecimal(parseFloat(totalValue) + parseFloat(nonOperatingValue))}</TableCell>
          </TableRow>
          {/* Shares outstanding */}
          <TableRow>
            <TableCell colSpan={3}>Shares Outstanding</TableCell>
            <TableCell>{sharesOutstanding}</TableCell>
          </TableRow>
          {/* Fair Value */}
          <TableRow>
            <TableCell colSpan={3}>Fair Value per Share</TableCell>
            <TableCell>{numberDecimal((parseFloat(totalValue) + parseFloat(nonOperatingValue)) / sharesOutstanding)}</TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ForecastTable;

import { initialStateChats,initialStateHome,initialStateNotes,initialStateSearch,initialStateSidebar, initialStateWatchlist } from "./initialState";
import { initialStateApp } from "./initialStateApp";
import { initialStateEcon } from "./initialStateEcon";
import { initialStateIndustry } from "./initialStateIndustry";
import { initialStateMarket } from "./initialStateMarket";
import { initialStateOpportunities } from "./initialStateOpportunities";
import { initialStateMyFinancials } from "./initialStateMyFinancials";
import { initialStatePortfolio } from "./initialStatePortfolio";
import { initialstateReportBuilder } from "./initialStateReportBuilder";
import { initialStateMindmap, initialStateResource } from "./initialStateResource";
import { initialStateScreener } from "./initialStateScreener";
import { useReducer } from "react";
import { initialStateNews } from "./initialStateNews";
import { initialStateStock, initialStateTicker } from "./initialStateTicker";
import { initialStateAssistant } from "./initialStateAssistant";


const createGenericReducer = (initialState) => (state = initialState, action) => {
  // Check if action is a non-empty object
  if (action && typeof action === 'object' && Object.keys(action).length > 0) {
    return Object.keys(action).reduce((newState, key) => {
      // Only process keys that exist in the initial state
      
      if (initialState.hasOwnProperty(key)) {
        const currentValue = state[key];
        const actionValue = action[key];
        // Handle different types of values
        if (Array.isArray(currentValue) && Array.isArray(actionValue)) {
          newState[key] = actionValue; // Replace array completely
        } else if (typeof currentValue === 'object' && currentValue !== null &&
                   typeof actionValue === 'object' && actionValue !== null) {
          if (Object.keys(actionValue).length === 0) {
            newState[key] = {}; // Set newState to an empty object if actionValue is empty
          } else {
            newState[key] = {...actionValue }; // Merge objects
          }
        } else if (actionValue === null) {
          newState[key] = null; // Set newState to null if actionValue is null
        } else {
          newState[key] = actionValue; // Replace non-objects and handle other cases
        }
      }
      return newState;
    }, { ...state });
  }
  return state;
};

// export default createGenericReducer;




const reducerApp = createGenericReducer(initialStateApp)
const reducerSidebar = createGenericReducer(initialStateSidebar)
const reducerNotes = createGenericReducer(initialStateNotes)
const reducerWatchlist = createGenericReducer(initialStateWatchlist)
const reducerChats = createGenericReducer(initialStateChats)  
const reducerEcon = createGenericReducer(initialStateEcon)  
const reducerMarket = createGenericReducer(initialStateMarket)
const reducerOpportunities = createGenericReducer(initialStateOpportunities)
const reducerResource = createGenericReducer(initialStateResource)
const reducerMindmap = createGenericReducer(initialStateMindmap)
const reducerScreener = createGenericReducer(initialStateScreener)
const reducerIndustry = createGenericReducer(initialStateIndustry)
const reducerReportBuilder = createGenericReducer(initialstateReportBuilder)
const reducerNews = createGenericReducer(initialStateNews)
const reducerPortfolio = createGenericReducer(initialStatePortfolio)
const reducerMyFinancials = createGenericReducer(initialStateMyFinancials)
const reducerStock = createGenericReducer(initialStateStock)
const reducerTicker = createGenericReducer(initialStateTicker)
const reducerHome = createGenericReducer(initialStateHome)
const reducerSearch = createGenericReducer(initialStateSearch)
const reducerAssistant = createGenericReducer(initialStateAssistant)

function useCombineReducer(){
    const [stateHome,dispatchHome] = useReducer(reducerHome,initialStateHome)
    const [stateSearch,dispatchSearch] = useReducer(reducerSearch,initialStateSearch)
    const [stateTicker, dispatchTicker] = useReducer(reducerTicker, initialStateTicker);
    const [stateSidebar, dispatchSidebar] = useReducer(reducerSidebar,initialStateSidebar);
    const [stateStock, dispatchStock] = useReducer(reducerStock,initialStateStock);
    const [stateIndustry, dispatchIndustry] = useReducer(reducerIndustry,initialStateIndustry);
    const [stateOpportunities, dispatchOpportunities] = useReducer(reducerOpportunities,initialStateOpportunities);
    const [stateMarket, dispatchMarket] = useReducer(reducerMarket,initialStateMarket);
    const [stateWatchlist, dispatchWatchlist] = useReducer(reducerWatchlist,initialStateWatchlist);
    const [stateScreener, dispatchScreener] = useReducer(reducerScreener,initialStateScreener);
    const [stateReportBuilder, dispatchReportBuilder] = useReducer(reducerReportBuilder,initialstateReportBuilder);
    const [statePortfolio, dispatchPortfolio] = useReducer(reducerPortfolio,initialStatePortfolio);
    const [stateResource,dispatchResource] = useReducer(reducerResource,initialStateResource)
    const [stateEcon,dispatchEcon] = useReducer(reducerEcon,initialStateEcon)
    const [stateMyFinancials,dispatchMyFinancials] = useReducer(reducerMyFinancials,initialStateMyFinancials)
    const [stateNotes, dispatchNotes] = useReducer(reducerNotes, initialStateNotes);
    const [stateChats,dispatchChats] = useReducer(reducerChats,initialStateChats)
    const [stateMindmap,dispatchMindmap] = useReducer(reducerMindmap,initialStateMindmap)
    const [stateNews,dispatchNews] = useReducer(reducerNews,initialStateNews)
    const [stateAssistant,dispatchAssistant] = useReducer(reducerAssistant,initialStateAssistant)
    return {
      stateSidebar,
      stateTicker,
      stateStock,
      stateResource,
      stateWatchlist,
      dispatchWatchlist,
      dispatchResource,
      dispatchStock,
      dispatchTicker,
      dispatchSidebar,
      stateIndustry,
      stateReportBuilder,
      dispatchReportBuilder,
      dispatchIndustry,
      stateScreener,
      dispatchScreener,
      statePortfolio,
      stateMarket,
      dispatchMarket,
      dispatchPortfolio,
      stateEcon,
      dispatchEcon,
      stateOpportunities,
      dispatchOpportunities,
      stateMyFinancials,dispatchMyFinancials,
      stateNotes,dispatchNotes,
      stateChats,dispatchChats,
      stateMindmap,dispatchMindmap,
      stateNews,dispatchNews,
      stateHome,dispatchHome,
      stateSearch,dispatchSearch,
      stateAssistant,dispatchAssistant
    }
  }

export {
  createGenericReducer,
  useCombineReducer,
  reducerApp}
import React, { useState } from "react";
import TabGeneral from "components/Tabs/TabGeneral";
import { Alert } from "@mui/material";
import HomePageTemplate from "home/HomePageTemplate";
import Pricing from "home/pricing/Pricing";


export default function Profile() {
  const [tabIndex, setTabIndex] = useState(0);

  function handleTabChange(index) {
    setTabIndex(index);
  }
  const tabContent = [];
  const tabList = [
    "Your Account",
    "Login & Security",
    "Message Preference",
    "Privacy Settings",
    "Billing & Plans",
    "Data Plans",
  ];
  tabContent[4] = <Pricing />;

  return (
    <HomePageTemplate pageName="Profile">
      <div style={{ padding: "0rem 0rem 1rem 0rem", alignSelf: "center" }}>
        <TabGeneral
          tabList={tabList}
          tabContent={[]}
          tabIndex={tabIndex}
          handleTabChange={handleTabChange}
        />
      </div>
      {tabContent[tabIndex]}
      <Alert
        severity="warning"
        sx={{ marginTop: "2rem", height: "200px", fontSize: "2rem" }}
      >
        Profile Settings are not available in the Beta Version!
      </Alert>
    </HomePageTemplate>
  );
}

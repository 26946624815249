import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { dfin } from 'content/constants'
import { Alert } from '@mui/material'
import TabLine from 'components/Tabs/TabLine'
import SelectBox from 'components/Inputs/SelectBox'
import PortfolioTransactionTickerChart from './PortfolioTransactionTickerChart'
import PorfolioTransactionsChart from './PortfolioTransactionsChart'
import { PageMainContentTitle } from 'components/Font/Title'


export default function PortfolioTransactions({type="All",uiType="portfolio"}) {

  return (
    <>
    {type === "All" && <PortfolioTransactionsAll uiType={uiType}/>}
      
    {type === "Ticker" && <PortfolioTransactionsTicker/>}

      </>
  )
}


export function PortfolioTransactionsAll({uiType="portfolio"}){
  // this component is used in Portfolio and Trade
  const {statePortfolio,stateTicker} = useAppInfo()
  const {portfolioTransactionTable,close,tickerSelected} = statePortfolio

  const [tabIndex,setTabIndex] = useState(0)

  useEffect(()=>{
    if (tickerSelected.length === 1){
      setTabIndex(1)
    }
  },[tickerSelected])
  
  return (
    <>
    {portfolioTransactionTable.length > 0 && (
        <div className='containerColumnFlex'>
          
          {uiType === "Portfolio" && <PageMainContentTitle>Transactions</PageMainContentTitle>}

          {(uiType==="portfolio" && tickerSelected.length>1) && <TabLine tabList={["All","Ticker"]} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}/>}
          
          {(tabIndex===0) && 
          <>
          <PorfolioTransactionsChart transactions={portfolioTransactionTable}/>
          <TableGen
            data={portfolioTransactionTable}
            tableLayout="fixed"
            height="auto"
            logo={true}
          />
          </>
          }
          
          
          {(uiType==="portfolio" && tabIndex === 1) && <PortfolioTransactionsTicker type='portfolio'/>}
          
        </div>)}
    </>
  )
}

export function PortfolioTransactionsTicker({type="ticker"}){
  const {statePortfolio,stateTicker} = useAppInfo()
  const {portfolioTransactionTable,close,tickerSelected:tickerSelectedPortfolio} = statePortfolio
  const [tickerTable,setTickerTable] = useState([])
  const [loadingTable,setLoadingTable] = useState(true)
  const {tickerSelected} = stateTicker
  
  const [tickerSelectedTransaction,setTickerSelectedTransaction] = useState()

  useEffect(()=>{
    let tickerPortfolio = tickerSelectedPortfolio.includes(tickerSelected) ? tickerSelected : tickerSelectedPortfolio[0]
    let tickerSelectedForTransaction = type === "portfolio" ? tickerPortfolio : tickerSelected
    setTickerSelectedTransaction(tickerSelectedForTransaction)
  },[tickerSelected,tickerSelectedPortfolio])

  useEffect(()=>{
    setLoadingTable(true)
    if (tickerSelectedTransaction){
    const table = portfolioTransactionTable.filter(obj=>obj[dfin?.ticker]===tickerSelectedTransaction) || []
    setTickerTable(table)
    setLoadingTable(false)}
  },[portfolioTransactionTable,tickerSelectedTransaction])

  
  return (
    <>
    {(!loadingTable && tickerTable.length === 0) && <Alert severity='warning'>You currently don't have any transactions with {tickerSelected}.</Alert>}
      {(!loadingTable && tickerTable.length > 0) && (
        <div className='containerColumnFlex'>
          {type==="portfolio" && <div style={{padding:"0.25rem"}}>
          <SelectBox variant='outlined' 
          width='250px'
          label={"Ticker"} options={tickerSelectedPortfolio} value={tickerSelectedTransaction} onChange={(e,v)=>setTickerSelectedTransaction(v)}/>
          </div>}
          <PortfolioTransactionTickerChart priceData={close} 
          transactions={portfolioTransactionTable} ticker={tickerSelectedTransaction}/>
          <TableGen
            data={tickerTable}
            tableLayout="fixed"
            height="auto"
            logo={true}
            showSelection={false}
          />
        </div>
      )  }
       </>
  )
}


import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import SidebarContentTemplate from './SidebarContentTemplate';
import { dfin } from 'content/constants';


export default function Text() {
    const {stateTicker,stateReportBuilder,dispatchReportBuilder,stateStock} = useAppInfo()

    const [content,setContent] = useState([
      {Category:"Header", 
      Content:"Header",
      Type: 'Text',position:{x:100,y:100},style:{fontSize:"24px",
      fontWeight:"bold",height:"100px",width:"200px"}, 
      Button: 'Header' },
      {Category:"Sub Header", 
      Content:"Sub Header",position:{x:100,y:100},
      style:{fontSize:"20px",height:"100px",width:"200px",background:"transparent"},
      Type: 'Text', Button: 'Sub Header' },
      {Category:"Text", 
      Content:"Text",
      style:{fontSize:"16px"},
      Type: 'Text', Button: 'Text Box' }])

    useEffect(()=>{
    if (stateStock.peerInfo){
    const companyHeader = []
    stateStock.peerInfo.map((obj)=>
    {obj[dfin.ticker]===stateStock.companySelected &&
    companyHeader.push(
      {Category:"Company Details", 
      Content:obj[dfin.ticker],
      ContentType:"Ticker",
      position:{x:100,y:100},
      Type: 'Text',style:{fontSize:"20px",height:"100px",width:"200px"}, Button: obj[dfin.ticker]})})
    const companyNameHeader = []
    stateStock.peerInfo.map((obj)=>
    {obj[dfin.ticker]===stateStock.companySelected &&
    companyNameHeader.push(
      {Category:"Company Details", 
      Content:obj[dfin.coName],
      ContentType:"Name",
      position:{x:100,y:100},
      Type: 'Text',style:{fontSize:"24px",height:"100px",width:"200px"}, Button: obj[dfin.coName]})})
    const companyDescription = []
      stateStock.peerInfo.map((obj)=>
      {obj[dfin.ticker]===stateStock.companySelected &&
      companyDescription.push(
        {Category:"Company Details", 
        ContentType:"BusinessDescription",
        Content:obj[dfin.businessDescription],
        Type: 'Text',style:{fontSize:"16px"}, Button: obj[dfin.businessDescription]})})
    
      setContent((prevContent) => {
          const uniqueCoNameHeader = companyNameHeader.filter(
            (img) => !prevContent.some((item) => item.Content === img.Content)
          );
          const uniqueCoHeader = companyHeader.filter(
            (img) => !prevContent.some((item) => item.Content === img.Content)
          );
          const uniqueCoDescription = companyDescription.filter(
            (img) => !prevContent.some((item) => item.Content === img.Content)
          );
    
          return [...uniqueCoNameHeader, ...uniqueCoHeader, ...uniqueCoDescription,...prevContent, ];
        });
      }
  
  },[stateStock.peerInfo])


  return (
    <>
       
        <SidebarContentTemplate content={content}/>

    </>
  )
}

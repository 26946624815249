const colsHeader = [
    "DATE LAUNCHED",
    "MAX SUPPLY",
    "CIRCULATING SUPPLY",
    "TOTAL SUPPLY",
    "NUM MARKET PAIRS",
    "MARKET CAPITALIZATION",
    "MARKET CAP DOMINANCE",
    "FULLY DILUTED MARKET CAP",
]
const colsSummary = [
    "DATE LAUNCHED",
    "MARKET CAPITALIZATION",
    "NUM MARKET PAIRS",
    "DATE ADDED",
    "MAX SUPPLY",
    "CIRCULATING SUPPLY",
    "TOTAL SUPPLY",
    "INFINITE SUPPLY",
    "MARKET CAP DOMINANCE",
    "MARKET CAP",
    "FULLY DILUTED MARKET CAP",
    "TVL",
  ];
  
  const cols = [
    "PRICE",
    "MARKET CAPITALIZATION",
    "PERCENT CHANGE 1H",
    "PERCENT CHANGE 24H",
    "CMC RANK",
    "TVL RATIO",
    "VOLUME 24H",
    "VOLUME CHANGE 24H",
    "PERCENT CHANGE 7D",
    "PERCENT CHANGE 30D",
    "PERCENT CHANGE 60D",
    "PERCENT CHANGE 90D",
  ];

const colsPrimary = ["TICKER",,'NAME','DATE LAUNCHED','MARKET CAPITALIZATION', 'CMC RANK', 'MAX SUPPLY',
'CIRCULATING SUPPLY', 'TOTAL SUPPLY',
'INFINITE SUPPLY',
'TVL RATIO', 
  'NUM MARKET PAIRS',
'MARKET CAP DOMINANCE', 'FULLY DILUTED MARKET CAP', 'TVL',
  ]

export {colsSummary,cols,colsHeader,colsPrimary}
import React from 'react';

import NoInfo from 'components/NoInfo';
import Modal from 'components/Modal/Modal';
import {useNavigate,} from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useAppInfo } from 'AppState';
import { menuIcons } from 'nav/menuIcons';
import BondPrice from './bonds/BondPrice';
import ForexExchangeRate from './forex/ForexExchangeRate';
import { Button } from '@mui/material';
import { assetCode } from 'content/constants';
import TickerHeader from './TickerHeader';
import CryptoProfile from './crypto/CryptoProfile';
import CompanyProfile from './stocks/Overview/CompanyProfile';
import EtfInfoList from './EtfInfoList';

export default function AssetModal() {
  const {stateTicker,dispatchTicker} = useAppInfo()
  const navigate = useNavigate()

  const tickerType = stateTicker.tickerType

  function handleModal(){
    dispatchTicker({"tickerModal":!stateTicker.tickerModal})
  }
 
  function handleDetailed(){
    navigate("/Analytics/Ticker")
    dispatchTicker({"tickerModal":!stateTicker.tickerModal})
    handleModal()
  }
  function handleTrade(){
    navigate("/Trade")
    handleModal()
  }
  
  return (
    <>
    {stateTicker.tickerModal &&
    <>
    <Modal height='70%' width='80%' onClose={handleModal}>
      
      <div className='containerColumnFlexNoGap'>
      <TickerHeader height="10%" />
      <div style={{width:"100%",flex:1,overflow:"auto"}}>
      {(tickerType ===assetCode?.Stocks)  && <CompanyProfile/>}
      {tickerType===assetCode?.ETF && <EtfInfoList info={"Profile"}/>}
      {tickerType===assetCode?.Crypto && <CryptoProfile/>}
      {tickerType===assetCode?.Forex && <ForexExchangeRate headerHeight='15%'/>}
      {tickerType===assetCode?.["Fixed Income"] && <BondPrice/>}
      {!Object.values(assetCode).includes(tickerType) && <NoInfo/>}
      </div>
      {Object.values(assetCode).includes(tickerType) &&
      <div style={{display:"flex",gap:"1rem",marginTop:"1rem"}}>
          <Button onClick={handleDetailed} variant='contained' >
            Detailed
            <FullscreenIcon/>
            </Button>
          <Button onClick={handleTrade} variant='outlined'>Trade
          {menuIcons?.Trade}
          </Button>
          </div>}
    </div>
      </Modal>
    </>
    }

    </>
  )
}


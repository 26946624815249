import React, { useState } from "react";
import { useAppInfo } from "AppState";
import { LinearProgress } from "@mui/material";
import TableGen from "components/Table/TableGen";
import useOptionsDerivatives from "data/company/useOptionsDerivatives";
import TabGeneral from "components/Tabs/TabGeneral";

export default function CompanyDerivativesOptions() {
  const { stateTicker ,stateStock} = useAppInfo();
  const companySelected = stateStock.companySelected;
  const [selectedExpiry, setSelectedExpiry] = useState("");
  const { putOption, callOption, expiryDates, optionsLoading } =
    useOptionsDerivatives(companySelected, selectedExpiry);
  
  const tabContentOptions = [];
  const [optionTabIndex, setOptionTabIndex] = useState(0);

  function handleOptionTabs(index) {
    setOptionTabIndex(index);
  }
  const handleExpiryChange = (event) => {
    setSelectedExpiry(event.target.value);
  };

  tabContentOptions[0] = (
    <>
      {optionsLoading && <LinearProgress />}
      {!optionsLoading && callOption && (
        <div style={{ marginTop: "1rem" }}>
          {" "}
          <TableGen data={callOption} height="500px" tableLayout="fixed" />
        </div>
      )}
    </>
  );

  tabContentOptions[1] = (
    <>
      {optionsLoading && <LinearProgress />}{" "}
      {!optionsLoading && callOption && (
        <div style={{ marginTop: "1rem" }}>
          {" "}
          <TableGen data={putOption} height="500px" tableLayout="fixed" />
        </div>
      )}
    </>
  );

  return (
    <div style={{ margin: "1rem" }}>
      <TabGeneral
        tabContent={tabContentOptions}
        tabList={["Call", "Put"]}
        tabIndex={optionTabIndex}
        handleTabChange={handleOptionTabs}
      >
        <div style={{ marginTop: "1rem", display: "flex", gap: "0.3rem" }}>
          <label htmlFor="expirySelect">Expiry date:</label>
          <select
            id="expirySelect"
            value={selectedExpiry}
            onChange={handleExpiryChange}
          >
            {expiryDates.map((exp, index) => (
              <option key={index} value={exp}>
                {exp}
              </option>
            ))}
          </select>
        </div>
      </TabGeneral>
    </div>
  );
}

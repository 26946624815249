import React from 'react'
import { useAppInfo } from 'AppState'
import FpIntro from './FpIntro'
import Modal from 'components/Modal/Modal'
import { Stepper, Step, StepLabel } from '@mui/material';
import AssetOnboarding from './AssetOnboarding'
import LiabilityOnboarding from './LiabilityOnboarding'
import IncomeOnboarding from './IncomeOnboarding'
import ExpenseOnboarding from './ExpenseOnboarding'
import GoalsOnboarding from './GoalsOnboarding'
import EndSlide from './EndSlide'
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding'


export default function FinancialPlanningOnboarding() {
  const {stateApp,stateMyFinancials} = useAppInfo()
  const UpdateOnboarding = useUpdateOnboarding()
  const {username,onboarding} = stateApp
  const {onBoardingStep} = stateMyFinancials
  const info = ["Assets","Liabilities","Income","Expense","Goals"]

  const steps = [
     {name:"Assets",content:<AssetOnboarding/>},
     {name:"Liabilities",content:<LiabilityOnboarding/>},
     {name:"Income",content:<IncomeOnboarding/>},
     {name:"Expense",content:<ExpenseOnboarding/>},
     {name:"Goals",content:<GoalsOnboarding/>},
     {name:"End",content:<EndSlide/>}
  ]

  function handleClose(){
    const updatedOnboarding = {...onboarding,financials:true}
    UpdateOnboarding(username,updatedOnboarding);
  }
  return (
    <>
    {onBoardingStep === -1 && <FpIntro/>}
    {onBoardingStep >= 0 && 
    <Modal 
    sx={{width:"90%",height:"90%",background:"var(--bgWoodSmoke)"}} 
    onClose={handleClose} closeIcon={false}>

    <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%"}}>
    <div style={{flex:1,padding:"1rem",overflow:"auto"}}>
    {steps[onBoardingStep]?.content}
    </div>

    {(onBoardingStep < info.length + 1) && 
    <div style={{padding:"1rem"}}>
    <Stepper activeStep={onBoardingStep} alternativeLabel>
      {info.map((label, index) => (
        <Step key={label}>
          <StepLabel style={{color:"white"}}><h4 style={{color:"white"}}>{label}</h4></StepLabel>
        </Step>
      ))}
    </Stepper>
    </div>}
    </div>
    </Modal>}
    </>
  )
}
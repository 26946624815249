import React from "react";
import Search from "./Search";
import { Button, IconButton } from "@mui/material";
import { useAppInfo } from "AppState";
import ProfileLogo from "components/Logo/ProfileLogo";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import styles from "./ContactList.module.css";

const ContactList = () => {
  const { stateChats, dispatchChats } = useAppInfo();
  const { chatting, chats, chatId } = stateChats;

  return (
    <div className={styles.contactListContainer}>
      <div className={styles.header}>
        {chatting && (
          <>
          <IconButton
            onClick={() => dispatchChats({ "chatting": false })}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <div className={styles.chattingProfile}>
            <ProfileLogo 
              profileType="nonOwner" 
              imgSrc={chats[chatId]?.userInfo?.photoURL} 
              displayName={chats[chatId]?.userInfo?.displayName}
            />
            <h4 className={styles.displayName}>
              {chats[chatId]?.userInfo?.displayName}
            </h4>
          </div>
          </>
        )}

        {!chatting && <Search />}
      </div>
    </div>
  );
};

export default ContactList;

import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useIndexStock() {
    const [indexStock, setIndexStock] = useState([]);
    
    useEffect(() => {
        getCompanyInfo();
    }, []);


    async function getCompanyInfo() {
        try {
        const response = await axios(`${BASE_URL}/indexStock/`);
        setIndexStock(response.data);
        } catch (error) {
        console.error(error);
        }
    }
    
    return ({indexStock}
    )
  }
  
import { Button} from '@mui/material'
import TextInput from 'components/Inputs/TextInput'
import Modal from 'components/Modal/Modal'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

export default function CrudModal({handleDelete,handleCreate,placeholder,
  title,onClose,modalType="create",deleteSettings=true,createButton="Save"}) {
  const [name,setName] = useState("")
  const [create,setCreate] = useState(false)
  const [deleteData,setDeleteData] = useState(false)

  return (
    <Modal onClose={()=>onClose(false)} height="auto" width="auto" sx={{borderRadius:"5px"}}>
    
    {(modalType === "settings" && !create && !deleteData) && 
    <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
    <h2>{title} Settings</h2>
    <Button variant="contained" startIcon={<AddIcon />} onClick={()=>setCreate(true)}>
      Create {title}
    </Button>
    <Button disabled={!deleteSettings} variant="contained" color="error" onClick={()=>setDeleteData(true)} startIcon={<DeleteIcon />}>
      Delete {title}
    </Button>
  </div>}

    {(modalType === "delete" || deleteData) && 
     <form  onSubmit={(e)=>{e.preventDefault();handleDelete();onClose(false)}} style={{ padding: "1rem" }}>
     <h2 style={{marginBottom:"2rem"}}>Are you sure you want to delete {title}?</h2>
     <div style={{display:"flex",gap:"1rem"}}>
     <Button type='submit'>Yes</Button>
     <Button variant='contained' onClick={()=>onClose(false)}>No</Button>
     </div>
   </form>}

    {(modalType === "create" || create) && 
    <form onSubmit={(e)=>{e.preventDefault();handleCreate(name);onClose(false)}} style={{ padding: "1rem",display:"flex",flexDirection:"column"}}>
      <h1>
        Create {title}</h1>
      <TextInput
        variant='outlined'
        required
        type="text"
        value={name}
        onChange={(v)=>setName(v)}
        placeholder={placeholder}
        fontSize={"1rem"}
        sx={{padding:"1rem 0"}}
      ></TextInput>

      <div>
      <Button disabled={(name === null && name.trim() === "")} 
      variant='contained' type='submit'>
       {createButton}
        </Button>
      </div>
    </form>}
  </Modal>
  )
}
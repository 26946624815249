import React, { useState } from 'react'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Button, Card, CardContent, ClickAwayListener, Paper, Tooltip, Typography } from '@mui/material';
import styles from './LeftSidebar.module.css';
import GridViewIcon from '@mui/icons-material/GridView';
import LeftSidebarFloating from './LeftSidebarFloating';

export default function LeftSidebarPageMenu({name,menuOptions=[], handleContentSel, 
  handleMenuSel, menuSel, contentSel,search=false,bigMenu=true}) {
  const [expandedStates, setExpandedStates] = useState({});

  const toggleExpansion = (header) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [header]: !expandedStates[header]
    }));
  };


  const [showSidebarExpander,setShowSidebarExpander] = useState(true)
  const [showBigMenu,setShowBigMenu] = useState(false)

  const headerDetails = bigMenu && <Tooltip title={"Expand Menu"}><Button 
  sx={{minWidth:0,color:"gray",padding:0,fontSize:"0.8rem",marginLeft:"auto"}} onClick={()=>setShowBigMenu(true)}><GridViewIcon fontSize='small'/></Button></Tooltip>


  return (
    <>
    <LeftSidebarFloating header={name} headerDetails={headerDetails}>
    <div 
    onMouseLeave={()=>setShowSidebarExpander(false)} 
    onMouseEnter={()=>setShowSidebarExpander(true)} 
    style={{position:"relative",height:"100%",width:"100%"}}>
    
   <Paper elevation={0} 
    className={`${styles.sidebarPaper}`} 
    style={{overflow:showSidebarExpander?"auto":"hidden"}}>
  
    <>
   
    {search && <div className={styles.searchBar}>
      <input placeholder='Search Information' className={styles.searchInput} type='search'></input>
    </div>}
    {Array.isArray(menuOptions) && menuOptions.map((obj, index) => (
      <div key={index}  
      className={menuSel === obj.name ? styles.menuActiveBorder : ''}>
      <div className={styles.menuHeader}>
          <Button className={styles.menuHeaderButton} 
          style={{color:menuSel !== obj.name && "black"}}
          onClick={() => {obj?.content.length > 0 ?  toggleExpansion(obj.name) : handleMenuSel(obj.name)}}>
            <Typography >{obj.name}</Typography>
          {(obj?.content && obj?.content.length > 0) && 
          <div style={{ marginLeft: "auto",alignSelf:"center",display:"flex",alignItems:"center"}}>
            {expandedStates[obj.name] ? (
              <ExpandLessIcon
                fontSize="medium"
                sx={{color:"gray"}}
              />
            ) : (
              <ExpandMoreIcon
                fontSize="medium"
                sx={{color:"gray"}}
              />
            )}
          </div>}</Button>
        </div>
        <>
          {expandedStates[obj.name] && obj?.content.map((subMenu, index) =>
            <div className={styles.subMenuContainer}>
              <SubdirectoryArrowRightIcon className={styles.smallIcon} />
              <Button className={styles.subMenuButton} onClick={() => handleContentSel(obj.name, subMenu)}>
                <Typography key={`${subMenu}${index}`} className={(subMenu === contentSel && obj.name === menuSel) ? styles.activeMenuText : styles.subMenuText}>{subMenu}</Typography></Button>
            </div>)}
        </>
      </div>
      
    ))}
    </>
    
    </Paper>



  </div>
  
  </LeftSidebarFloating>

{showBigMenu && 
  <ClickAwayListener onClickAway={() => setShowBigMenu(false)}>
  <Paper 
    elevation={8}
    style={{
      width: "100%",
      zIndex: "10000000",
      height: "auto",
      overflow: "auto",
      minHeight: "30%",
      maxHeight: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      borderRadius: "4px",
      padding: "1rem",
      borderTop:"1px solid #ddd",
      boxSizing: "border-box",
    }}
  >
    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
      <Typography variant="h5">{name}</Typography>
      <Button sx={{ marginLeft: "auto" }} onClick={() => setShowBigMenu(false)}>Close</Button>
    </div>
    <div style={{ display: "flex", flexWrap: "wrap", width: "100%", gap: "1rem", marginTop: "1rem" }}>
      {Array.isArray(menuOptions) && menuOptions.map((obj, index) => (
        <Card key={index} 
        style={{ minWidth: "150px", borderRadius: "4px", 
        boxShadow: obj.name === menuSel ? "0 4px 8px rgba(0, 0, 0, 0.2)" : 
        "0 1px 3px rgba(0, 0, 0, 0.1)",background:"#fafafa"}}>
          <CardContent>
            <Button 
              sx={{ color: "black", fontWeight: "bold"}} 
              onClick={() => { handleMenuSel(obj.name); setShowBigMenu(false); }}
            >
              <Typography  sx={{ color: "black", fontWeight: "bold"}} > {obj.name}</Typography>
            </Button>
            {obj?.content.map((subMenu, subIndex) => (
              <div key={subIndex} style={{ marginTop: "0.5rem" }}>
                <Button 
                  sx={{ color: subMenu === contentSel && obj.name === menuSel ? "primary.main" : "text.primary"}}
                  onClick={() => { handleContentSel(obj.name, subMenu); setShowBigMenu(false); }}
                >
                  <Typography 
                    className={(subMenu === contentSel && obj.name === menuSel) ? "activeMenuText" : "subMenuText"}
                  >
                    {subMenu}
                  </Typography>
                </Button>
              </div>
            ))}
          </CardContent>
        </Card>
      ))}
    </div>
  </Paper>
</ClickAwayListener>
}
</>
  )
}

import React, { useState } from 'react'
import TableGen from 'components/Table/TableGen'
import { useAppInfo } from 'AppState'
import {dfin, technicalIndicators } from 'content/constants'
import useTechnicalStats from 'data/technicals/useTechnicalStats'
import { Alert, LinearProgress } from '@mui/material'
import ScoreTemplate from './ScoreTemplate'

export default function CompanyTechnicalScore({coInfo}) {

  const {stateTicker} = useAppInfo()
  const tickerSelected = coInfo?.[dfin.ticker]
  const techIndicators = technicalIndicators.map(key => ({
    "TECHNICAL INDICATOR": key,
    "VALUE": coInfo?.[key]
  }));

  const {tStats,tStatsLoading} = useTechnicalStats(stateTicker.peerSelected,tickerSelected)  
  const [moreInfo,setMoreInfo] = useState(false)
  const scoreTitle = "Technical"
  const scoreOverall = coInfo?.["TECHNICAL OVERALL SCORE"]?.toFixed(2)
  const scoreDetails = [
    { Title: "Trend", Value: coInfo?.["MOVING AVERAGES OVERALL SCORE"]?.toFixed(2) },
    { Title: "Oscillator", Value: coInfo?.["OSCILLATORS OVERALL SCORE"]?.toFixed(2) },
  ];
  const moreInfoContent = ( (!tStatsLoading && tStats) && 
    <>
        {(tStatsLoading || !tStats?.allStats) && <LinearProgress/>}
     {(!tStatsLoading && tStats?.allStats) && <TableGen 
     tableLayout='fixed' 
     data={tStats["allStats"]} height='auto'/>}
    
    <h3>More Indicators</h3>
    {techIndicators && <TableGen 
     tableLayout='fixed' 
     data={techIndicators} height='auto'/>}
     </>    
  )
  
  return (
    <>
   <ScoreTemplate 
      information={information}
        scoreTitle={scoreTitle} scoreDetails={scoreDetails} scoreOverall={scoreOverall}
       moreInfoContent={moreInfoContent}/>
 
  </>
  )
}

const information =  <h4>How are Technical Scores Calculated<br/>
Technical Scores are based on equal weighted average of indicators in trend and oscillator.<br/>
Scores are: <br/>
Strong Sell - 0 <br/>
Sell - 3 <br/>
Neutral - 5 <br/>
Buy - 7 <br/>
Strong Buy - 10 <br/>
Following Indicators were used: EMA 20, EMA 50, EMA 200, RSI(14), MACD, Stochastic (14,3,3), Momentum(10), 
ATR, OBV<br/></h4>

import React, {useMemo} from 'react'
import { useAppInfo } from 'AppState'
import {descriptiveCols,  metricValue, metricsMarketStats  } from 'content/constants';
import MetricSelectionModal from './MetricSelectionModal';

export default function MetricStockModal
({handleModalClose,handleMetricSelected,modalName,metricSelected
  ,txtCols=false,checkbox=false,}) {
    const {stateStock} = useAppInfo()
    const {metrics} = stateStock
    const {numCols,growthCols,avgCols,metricFinancialStatement,metricScreener} = metrics
 

  const metricInfo = useMemo(() => {
    const baseMetricCategoryList = {
      "Market Stats": metricsMarketStats,
      "Size":numCols,
      "Growth": growthCols,
      "Averages": avgCols,
      "Value": metricValue,
      "Income Statement": metricFinancialStatement?.IS,
      "Balance Sheet": metricFinancialStatement?.BS,
      "Cash Flow Statement": metricFinancialStatement?.CF,
      "Ratios": metricFinancialStatement?.Ratio
    };
  
    if (txtCols) {
      baseMetricCategoryList["Descriptive"] = descriptiveCols;
    }
  
    return Object.entries(baseMetricCategoryList).flatMap(([category, names]) => 
      names ? names.map(name => ({ category, name })) : []
    );
  }, [metricsMarketStats, growthCols, avgCols, metricValue, metricFinancialStatement, txtCols, descriptiveCols]);
  
  
  
  return (
    <MetricSelectionModal 
    title={modalName}
    metricInfo={metricInfo} 
    handleMetricSelected={handleMetricSelected}
    checkbox={checkbox}
    onClose={handleModalClose}
    metricSelected={metricSelected}/>
  )
}

// import React, { useEffect, useState } from 'react'
// import { useAppInfo } from 'AppState'
// import styles from "./MetricSelectionModal.module.css"
// import SearchIcon from '@mui/icons-material/Search';
// import { IconButton, InputBase, Tooltip } from '@mui/material';
// import Modal from '../Utils/Modal';
// import { dfin, descriptiveCols,  metricValue, metricsMarketStats  } from 'content/constants';

// export default function MetricStockModal
// ({handleModalClose,handleMetric,modalName,metricSelected
//   ,txtCols=false,checkbox=false,handleCheckbox,metricCategory="All"}) {
//     const {stateStock} = useAppInfo()
//     const {metrics} = stateStock
//     const {numCols,growthCols,avgCols,metricFinancialStatement} = metrics
//     const [metricCategorySel,setMetricCategorySel] = useState()
//     const [metricList,setMetricList] = useState([])
//     const [searchInput,setSearchInput] = useState("")

//     let metricCategories = ["All","Market Stats","Growth","Averages","Value","Income Statement","Balance Sheet","Cash Flow Statement","Ratios"] 
//     if(txtCols){
//       metricCategories = ["All","Descriptive","Market Stats","Growth","Averages","Value","Income Statement","Balance Sheet","Cash Flow Statement","Ratios"]  
//     }

//     useEffect(()=>{
//       if (metricCategories.includes(metricCategory)){
//         setMetricCategorySel(metricCategory)
//       }
//       else{
//         setMetricCategorySel("All")
//       }
//     },[metricCategory])

   
    
//     let metricCategoryList = {
//       "All":numCols,
//       "Market Stats":metricsMarketStats,
//       "Growth":growthCols,
//       "Averages":avgCols,
//       "Value":metricValue,
//       "Income Statement":metricFinancialStatement?.IS,
//       "Balance Sheet":metricFinancialStatement?.BS,
//       "Cash Flow Statement":metricFinancialStatement?.CF,
//       "Ratios":metricFinancialStatement?.Ratio
//     }
//   if(txtCols){
//     metricCategoryList = {
//       "All":numCols,
//       "Descriptive":descriptiveCols,
//       "Market Stats":metricsMarketStats,
//       "Growth":growthCols,
//       "Averages":avgCols,
//       "Value":metricValue,
//       "Income Statement":metricFinancialStatement?.IS,
//       "Balance Sheet":metricFinancialStatement?.BS,
//       "Cash Flow Statement":metricFinancialStatement?.CF,
//       "Ratios":metricFinancialStatement?.Ratio
//     }
//   }

    
//     useEffect(()=>{
//       //   setMetricList(metricCategoryList[metricCategorySel])
//       if (metricCategorySel){
//         let filteredArray = metricCategoryList[metricCategorySel]
//         if (searchInput !==""){
//           filteredArray = filteredArray.filter(item => item.toLowerCase().includes(searchInput.toLowerCase()));}
//         setMetricList(filteredArray)
//       }
//       },[metricCategorySel])
  

//   function handleSearchMetric(searchInput) {
//       // Assuming your original array is named 'originalArray'
//       setSearchInput(searchInput)
//       const filteredArray = metricCategoryList[metricCategorySel].filter(item => item.toLowerCase().includes(searchInput.toLowerCase()));
//       setMetricList(filteredArray)
//     }

//   function handleMetricCategory(category){
//     setMetricCategorySel(category)
//   }

//   const metricInfo = useMemo(() => {
//     const baseMetricCategoryList = {
//       "Market Stats": metricsMarketStats,
//       "Growth": growthCols,
//       "Averages": avgCols,
//       "Value": metricValue,
//       "Income Statement": metricFinancialStatement?.IS,
//       "Balance Sheet": metricFinancialStatement?.BS,
//       "Cash Flow Statement": metricFinancialStatement?.CF,
//       "Ratios": metricFinancialStatement?.Ratio
//     };
  
//     if (txtCols) {
//       baseMetricCategoryList["Descriptive"] = descriptiveCols;
//     }
  
//     return Object.entries(baseMetricCategoryList).flatMap(([category, names]) => 
//       names ? names.map(name => ({ category, name })) : []
//     );
//   }, [metricsMarketStats, growthCols, avgCols, metricValue, metricFinancialStatement, txtCols, descriptiveCols]);
  
  
  
//   return (
//     <Modal width='70%' onClose={handleModalClose}>
//           <div className={styles.modalWrapper}>
//             <h2 className={styles.modalHeader}>{modalName} Metric Selection</h2>
//             <div className={styles.flexContainer}>
//               <div className={styles.leftPanel}>
//                 {metricCategories.map(category => (
//                   <button
//                      key ={category}
//                     onClick={() => handleMetricCategory(category)}
//                     className={`btn ${styles.btn} ${category === metricCategorySel ? (styles.activeButton): {}}`}
//                   >
//                     {category}
//                   </button>
//                 ))}
//               </div>
//               <div className={styles.rightPanel}>
//               <div style={{borderBottom:"1px solid #ddd",width:"100%"}}>
//                   <IconButton type="button" aria-label="search">
//                       <SearchIcon />
//                     </IconButton>
//                     <InputBase
//                 sx={{ ml: 1, flex: 1 }}
//                 placeholder="Search Metrics"
//                 inputProps={{ 'aria-label': 'search metrics' }}
//                 onChange={(e) => handleSearchMetric(e.target.value)}
//               /></div>
//                 <div className={styles.metricsListContainer}>
//                   {metricList.map(cols => (
//                     <div key={cols} style={{display:"flex",width:"100%",borderBottom: "1px solid #ddd"}}>
//                    {checkbox && (
//         <input
//           checked={metricSelected.includes(cols)}
//           onChange={() => handleCheckbox(cols)}
//           type='checkbox'
//         />
//       )}
//                       <button
//                       onClick={!checkbox ? (e) => handleMetric(e, cols, modalName) : ()=>handleCheckbox(cols)}
//                       className={`${styles.metricListButton} ${metricSelected.includes(cols)? styles.activeButton:{}}`} // Add any additional styles you need
                      
//                     >
//                       {cols}
//                     </button>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Modal>
//   )
// }



import React, { useState, useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

export default function useScreenerETF() {
  const { dispatchScreener, stateScreener } = useAppInfo()

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      dispatchScreener({"loadingETF":true });

      const response = await axios.get(`${BASE_URL}/etfScreener/`, {
        params: {
          ticker: JSON.stringify(stateScreener.tickerList),
          page: 1,
        },
      });
      const data = response.data;
      dispatchScreener({"etf":data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatchScreener({"loadingETF":false });
    }
  }
}
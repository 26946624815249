import React, { useEffect, useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import AssetProjection from './AssetProjection'

export default function AssetReturnProjections({chartHeight="400px",projectionType="Projection",editDetails=false,showDate=false,showField=true}) {

  const tabList = ["Projection","Simulated"]
  const [tabIndex,setTabIndex] = useState(0)

  return (
    <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
      <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}/>
      
    <AssetProjection projectionType={tabList[tabIndex]} amountType={"return"}/>
      </div>
  )

}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { IndSecInfoList } from '../../analytics/industry/IndSecInfoList'
import { sectorDict } from 'content/contentMarket'
import GetPriceInfo from 'data/ticker/GetPriceInfo'

export default function IndustrySector() {
  const {stateIndustry,dispatchIndustry} = useAppInfo() 
  const itemsPerPage = 50
  const [currentPage,setCurrentPage] = useState(1)
  
  // sector and industry list
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { close, trendTable, marketInfoLoading, priceData } = await GetPriceInfo(Object.keys(sectorDict)); // Await the asynchronous function
        dispatchIndustry({"sectorClose":close,"sectorTrendTable":trendTable,"sectorCloseLoaded":marketInfoLoading});
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(()=>{
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const infoX =  stateIndustry.constiInfoAll
  dispatchIndustry({"constiInfo":infoX.slice(indexOfFirstItem,indexOfLastItem)})

  },[currentPage,stateIndustry.constiInfoAll])
  
  
  useEffect(()=>{
    setCurrentPage(1)
    },[stateIndustry.indSecMode,stateIndustry.countrySel])


  return (
    <IndSecInfoList/>
  )

}

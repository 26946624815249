import BorderBox from 'components/Layout/BorderBox'
import LogoTicker from 'components/LogoTicker'
import { dfin } from 'content/constants'
import React from 'react'
import { formatNumber } from 'utils/utilGeneral'

export default function TickerPositionCard({data}) {

  const profitLossColor = data?.["Profit/Loss"]>0?"green":"red"
  return (
    <BorderBox sx={{height:"auto"}}>  
    <div style={{padding:"0.5rem 0",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
    <LogoTicker tickerSel={data?.[dfin.ticker]}/>
    <h3>{data?.[dfin.coName]} ({data?.[dfin.ticker]})</h3>
    </div>
    <h4 style={{fontWeight:"bold"}}>P/L: <span style={{color: profitLossColor}}>{formatNumber(data?.["Profit/Loss"])} ({data?.["Profit/Loss (%)"]})%</span></h4>
    </div>
    <table style={{tableLayout:"fixed"}}>
  <tbody>
    <tr>
      <td><h4>Quantity</h4></td>
      <td><h4>{data?.["quantity"]}</h4></td>
    </tr>
    <tr>
      <td><h4>Average Price</h4></td>
      <td><h4>{data?.["Average Price"]}</h4></td>
    </tr>
    <tr>
      <td><h4>Current Price</h4></td>
      <td><h4>{data?.["Current Price"]}</h4></td>
    </tr>
    <tr>
      <td><h4>Profit/Loss</h4></td>
      <td><h4><span style={{color: profitLossColor}}>{formatNumber(data?.["Profit/Loss"])}</span></h4></td>
    </tr>
    <tr>
      <td><h4>Profit/Loss(%)</h4></td>
      <td><h4><span style={{color: profitLossColor}}>{data?.["Profit/Loss (%)"]}%</span></h4></td>
    </tr>
    <tr>
      <td><h4>Investment Type</h4></td>
      <td><h4>{data?.["investmentType"]}</h4></td>
    </tr>
    <tr>
      <td><h4>Net Asset Value</h4></td>
      <td><h4>{formatNumber(data?.["Net Asset Value"])}</h4></td>
    </tr>
    <tr>
      <td><h4>Investment Cost</h4></td>
      <td><h4>{formatNumber(data?.["Investment Value"])}</h4></td>
    </tr>
    <tr>
      <td><h4>Holding Period</h4></td>
      <td><h4>{data?.["Holding Period (days)"]}</h4></td>
    </tr>
    <tr>
      <td><h4>Weight in Portfolio</h4></td>
      <td><h4>{formatNumber(data?.["Weight"] * 100)}%</h4></td>
    </tr>
  </tbody>
</table>

    </BorderBox>
  )
}


{/* <div style={{display:"flex",gap:"1rem"}}>
<h4>Quantity: {data?.["quantity"]}</h4>
<h4>Average Price: {data?.["Average Price"]}</h4>
<h4>Current Price: {data?.["Current Price"]}</h4>
<h4>Profit: <span style={{color:profitLossColor}}>{data?.["Profit/Loss"]}</span></h4>
<h4>Profit(%): <span style={{color:profitLossColor}}>{data?.["Profit/Loss (%)"]}</span></h4>
<h4>Investment Type: {data?.["investmentType"]}</h4>
<h4>Net Asset Value: {data?.["Net Asset Value"]}</h4>
<h4>Investment Cost: {data?.["Investment Value"]}</h4>
<h4>Holding Period: {data?.["Holding Period (days)"]}</h4>
<h4>Weight in Portfolio: {formatNumber(data?.["Weight"]*100)}%</h4>
</div> */}
import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { useAppInfo } from "AppState";
import { BASE_URL } from "apiConfig";

export default function useMovingAverage(periodSlow,periodFast,maType,dateFrequency,tickerList) {
    
const {stateTicker} = useAppInfo()
const [maCross, setMaCross] = useState([]);
const [maLoading, setMarketInfoLoading] = useState(false);
const previousPeerSelectedRef = useRef([]);

// tickerList.length === previousPeerSelectedRef.current.length && 
useEffect(() => {
    getPriceInfo();
}, [tickerList,periodFast,periodSlow,dateFrequency,maType]);

async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/maCross/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        periodSlow:periodSlow,
        periodFast:periodFast,
        maType:maType,
        dateFrequency:dateFrequency,
        },
    });
   
    const marketInfo = response.data
    
    setMaCross(JSON.parse(marketInfo))
    } catch (error) {
    
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {maCross, maLoading };
}
import React from 'react';
import PageComingSoon from 'pages/PageComingSoon';
import PortfolioCurrentPositions from './PositionsAndTransactions/PortfolioCurrentPositions';
import PortfolioHoldingSenate from './PortfolioHoldingSenate';
import PortfolioOverview from './PortfolioOverview';
import PortfolioTransactions, { PortfolioTransactionsAll, PortfolioTransactionsTicker } from './PositionsAndTransactions/PortfolioTransactions';
import PortfolioVsBenchmark from './PortfolioVsBenchmark';
import AssetAllocation from './AssetAllocation';
import PortfolioOverviewHome from './PortfolioOverviewHome';
import PortfolioAssetAnalytics from './PortfolioAssetAnalytics';

export default function PortfolioInfoList({menu,subMenu}) {
 


  const infoList = {
    "Overview":<PortfolioOverview/>,
    "Overview Home":<PortfolioOverviewHome/>,
    "Current Positions":<PortfolioCurrentPositions/>,
    "Transactions":<PortfolioTransactions/>,
    "Transactions All":<PortfolioTransactionsAll uiType='trade'/>,
    "Transaction Ticker":<PortfolioTransactionsTicker type='ticker'/>,
    "Current Position Ticker":<PortfolioCurrentPositions positionType='Ticker'/>,
    "Asset Allocation":<AssetAllocation/>,
    "Portfolio vs Benchmark":<PortfolioVsBenchmark/>,
    "Asset Analytics":<PortfolioAssetAnalytics info={subMenu}/>,
    "Report":<PageComingSoon name={"Insights"}/>,
    "Insights":<PageComingSoon name={"Insights"}/>,
    "Holdings - Senate":<PortfolioHoldingSenate/>,
  }
  
  return (
    <>
    {infoList[menu]}
    </>
  )
}


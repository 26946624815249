import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useAppInfo } from 'AppState';
import useAddUpload from 'data/user/upload/addUpload';
import { storage } from 'firebaseConfig/firebaseConfig';




const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImageUpload = ({ setImage }) => {

  const { addUpload, error } = useAddUpload()
  const {stateApp} = useAppInfo()
  const {userId} = stateApp
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const storageRef = ref(storage, `images/uploads/user/${userId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            // setPercent(percent);
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                // console.log(url);
                setImage({[file.name.split(".")[0]]:url})
                setTimeout(()=>addUpload("reportImages",file.name.split(".")[0],url),0)
              
            });
        }
    );
    
    }
  
  };

  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
    >
      Upload file
      <VisuallyHiddenInput
        type="file"
        accept="image/*"
        onChange={handleImageChange}
      />
    </Button>
  );
};

export default ImageUpload;

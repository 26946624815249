import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import PeerOverviewMetricComp from './PeerOverviewMetricComp'
import { defaultMetrics, dfin } from 'content/constants'
import TabLine from 'components/Tabs/TabLine'

export default function StatsVsPeers() {
  const {dispatchSidebar,stateStock} = useAppInfo()
  const data = stateStock.peerInfo
  const metricOptions = stateStock.metrics.numCols
  
  const tabInfo = [
    { name: "Market Stats", metric: defaultMetrics.MarketStats, metricCategory: "Market Stats" },
    { name: "Profit", metric: defaultMetrics.Profit, metricCategory: "Profit" },
    { name: "Value", metric: defaultMetrics.Value, metricCategory: "Value" },
    { name: "Financial Stability", metric: defaultMetrics.FinancialStability, metricCategory: "Averages" },
    { name: "Growth", metric: defaultMetrics.Growth, metricCategory: "Growth" },
    { name: "Size & Share", metric: defaultMetrics.Size, metricCategory: "Size & Share" }
  ];
  
  
  const [tabSel,setTabSel] = useState("Market Stats")
  const [defaultMet,setDefaultMet] = useState(defaultMetrics.MarketStats)
  const [metricCategory,setMetricCategory] = useState("Market Stats")
  useEffect(()=>{
    let tabInfoSel = tabInfo.filter(obj=>obj.name===tabSel)[0]
    setDefaultMet(tabInfoSel?.metric)
    setMetricCategory(tabInfoSel?.metricCategory)
  },[tabSel])


 
 
  const chartTypeOptions = ["Bar", "Pie", "Bar & Pie"];
  const chartTypeDefault = "Bar";
  const tabList = tabInfo.map(obj=>obj.name)

  return (
    <>
   
    <PeerOverviewMetricComp
      data={data}
      infoName={`Stats vs Peers`}
      storeId={`${tabSel}`}
      defaultMet={defaultMet}
      metricOptions={metricOptions}
      chartTypeDefault={chartTypeDefault}
      chartTypeOptions={chartTypeOptions}
      metricCategory={metricCategory}
      addPeers={true}>
       <TabLine height='40px' tabList={tabList} tabIndex={tabList.indexOf(tabSel)} handleTabChange={(i)=>setTabSel(tabList[i])}/>
    
   </PeerOverviewMetricComp>
    
    </>
  )
}
import React, { useState } from 'react'
import TabLine from 'components/Tabs/TabLine'
import LiabilityPaybackSummary from './LiabilityPaybackSummary'
import AmortizationTable from './AmortizationTable'

export default function LiabilityPayback() {
  const [tabIndex,setTabIndex] = useState(0)
  const tabContent = []
  tabContent[0] = <LiabilityPaybackSummary/>
  tabContent[1] = <AmortizationTable/>
  return (
    <div className='containerColumnFlex'>
    <TabLine tabList={["Payment Overview","Amortization Schedule"]} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
    <>
    {tabContent[tabIndex]}
    </>
    </div>
  )
}

import React, { useEffect, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import numeral, { options } from 'numeral';
import Highcharts from 'highcharts/highstock';
import { RecessionData } from 'content/contentEconomy';

require("highcharts/modules/accessibility")(Highcharts);

const LineChartMulti = ({ datasets,recession=false,title="Price vs Metric"}) => {

  const options =useMemo(() => ({
    chart:{
        animation: false,
    },

    title: {
      text: title,
    },
    xAxis: {
      type: 'datetime',
        plotBands:recession && RecessionData.map((recession, index) => ({
        from: new Date(recession.from).getTime(),
        to: new Date(recession.to || recession.from).getTime(),
        color: index % 2 === 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(255, 0, 0, 0.05)',
        label: {
          text: recession.label,
          rotation:90,
          verticalAlign:"bottom",
          y:-50
          
        },
        
        }))
      
    },
    yAxis: [
      {
        title: {
          text: "Close",
        },
        
      },
      {
        title: {
          text: "Value",
        },
        labels: {
            formatter: function () {
              return numeral(this.value).format('0.0a').toUpperCase();
            },
          },
        opposite: true, // This option places the axis on the opposite side
      },
    ],
    credits:{
        enabled:false
    },
    series: datasets.length>0 && datasets.map((dataset, index) => ({
      name: dataset?.name,
      data: dataset?.data.map((dataPoint) => [dataPoint.date, dataPoint.value]).sort((a, b) => a[0] - b[0]),
      yAxis: index === 0 ? 0 : 1, // Associate the first series with the left axis (index 0), and subsequent series with the right axes
      type: dataset?.chartType?dataset.chartType:"area", // Set the type to 'line' for the left axis and 'areaspline' for the right axis
      color:dataset?.color && dataset.color
    })),
    rangeSelector: {
      allButtonsEnabled: true,
      buttons: [
        {
          type: 'year',
          count: 1,
          text: '1y',
        },
        {
          type: 'year',
          count: 3,
          text: '3y',
        },
        {
          type: 'year',
          count: 5,
          text: '5y',
        },
        {
          type: 'year',
          count: 10,
          text: '10y',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
      selected: 2,
      animation: true,
    },
    navigator: {
      enabled: true
  },
  //   scrollbar: {
  //     enabled: false
  // },

  stockTools :{
    gui:{
    enabled:false}
  },
  legend:{
    enabled:true
  }
  }),[datasets])

  return (
    <div style={{ flex: 1, height: '100%' ,width:"100%"}}>  
    {options && <HighchartsReact
     highcharts={Highcharts}
     constructorType="stockChart"
     options={options}
     containerProps={{ style: { height: '100%',width:"100%" } }}
   />}
 </div>
    
  );
};

export default LineChartMulti;

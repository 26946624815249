import React, { useState, useRef, useEffect } from 'react';
import IntermarketReportInfo from './IntermarketReportInfo';
import InfoBox from 'components/Layout/InfoBox';
import ContentMenu from './ContentMenu';
import StickyTabs from './StickyTabs';
import { Alert } from '@mui/material';
import styles from "./ReportTemplateMarketEcon.module.css"

export default function ReportTemplateMarketEcon({overview,reportComponents,children}) {
  const [currentTab, setCurrentTab] = useState(0);
  const [isContentMenuVisible, setContentMenuVisible] = useState(true);
  const contentMenuRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setContentMenuVisible(entry.isIntersecting);
      },
      { rootMargin: '-50px 0px 0px 0px' }
    );

    if (contentMenuRef.current) {
      observer.observe(contentMenuRef.current);
    }

    return () => {
      if (contentMenuRef.current) {
        observer.unobserve(contentMenuRef.current);
      }
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles.container}>
      {!isContentMenuVisible && (
        <StickyTabs
          items={reportComponents}
          value={-1}
          onChange={handleTabChange}
        />
      )}

    <div className={styles.overviewContainer}>
    <InfoBox sx={{minWidth:"100px"}}>
        <div ref={contentMenuRef}>
        <ContentMenu  items={reportComponents} />
        </div>
        
        </InfoBox>
      <InfoBox sx={{flex:1}}>
        <div ref={contentMenuRef} >
          <div className={styles.overviewContent}>
            <h3 className={styles.heading}>Overview</h3>
            <div className={styles.textContainer}>
              {overview}
            </div>
          </div>
        </div>
      </InfoBox>
      </div>
      {children}
    </div>
  );
}


const reportContentDetailsSample = [
    { id: "market-today", name: "Market Today", component: "Market Today" },
    { id: "trend-chart", name: "Asset Trends", component: "Trend Chart" },
    { id: "performance", name: "Performance", component: "Performance" },
    { id: "volatility", name: "Volatility", component: "Volatility" },
    { id: "correlation", name: "Correlation", component: "Correlation" },
    { id: "beta", name: "Beta", component: "Beta" },
    { id: "indicators", name: "Indicators", component: "Indicators" },
    { id: "yield-curve", name: "Yield Curve", component: "Yield Curve" },
    { id: "exchange-rate", name: "Exchange Rate", component: "Exchange Rate" },
  ];
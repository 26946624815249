import React from 'react';

const RatingBar = ({ score , scoreMax=10}) => {
    let color;
    if (score > (scoreMax * 0.70)) {
        color = 'green';
    } else if (score >= (scoreMax * 0.40) && score <= (scoreMax * 0.70)) {
        color = 'orange';
    } else {
        color = 'red';
    }
    
    const width = (score / scoreMax) * 100 + '%';

    const barStyle = {
        background: color,
        borderRadius: '25px',
        width: width,
        height: '100%',
        margin: 0
    };

    return (
        <div style={{ background: '#ddd', borderRadius: '25px', width: '200px', height: '10px' }}>
            <div style={barStyle}></div>
        </div>
    );
};

export default RatingBar;

import React, { useEffect, useState, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';
import worldMapData from '@highcharts/map-collection/custom/world.topo.json';
import HighchartsMore from 'highcharts/highcharts-more';

// Initialize the highchartsMap module
highchartsMap(Highcharts);

// Initialize the highcharts-more module
HighchartsMore(Highcharts);

const getGraticule = () => {
  const data = [];

  // Meridians
  for (let x = -180; x <= 180; x += 15) {
    data.push({
      geometry: {
        type: 'LineString',
        coordinates: x % 90 === 0 ? [
          [x, -90],
          [x, 0],
          [x, 90]
        ] : [
          [x, -80],
          [x, 80]
        ]
      }
    });
  }

  // Latitudes
  for (let y = -90; y <= 90; y += 10) {
    const coordinates = [];
    for (let x = -180; x <= 180; x += 5) {
      coordinates.push([x, y]);
    }
    data.push({
      geometry: {
        type: 'LineString',
        coordinates
      },
      lineWidth: y === 0 ? 2 : undefined
    });
  }

  return data;
};

const Globe = React.memo(({data,title}) => {
  const [rotation, setRotation] = useState(-10);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Update rotation every second
//       setRotation(rotation => rotation + 20);
//     }, 5000);

//     // Clear the interval on component unmount
//     return () => clearInterval(interval);
//   }, []); // Empty dependency array ensures the effect runs only once on mount

  const options = useMemo(() => ({
    chart: {
      map: 'custom/world', // Use the 'custom/world' map
      panning: true,
      panKey: 'drag',
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 50
      }
    },
    mapView: {
      projection: {
        name: 'Orthographic',
        rotation: [0, rotation, 0]
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    title: {
      text: title
    },
    subtitle: {
      text: 'Source: World Bank'
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    colorAxis: {
      min: 0
    },
    series: [
      {
        name: 'Graticule',
        id: 'graticule',
        type: 'mapline',
        data: getGraticule(),
        nullColor: 'rgba(0, 0, 0, 0.05)',
        dataLabels: {
            enabled: false,
          },
        accessibility: {
          enabled: false
        },
        tooltip: {
            enabled: false // Disable tooltip for the graticule series
          },
      },
      {
        name: title,
        data: data,
        mapData: worldMapData, // Use the world map data
        joinBy: ["iso-a2","ISO 2"],
        states: {
          hover: {
            color: '#BADA55' // Change color on hover
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        }
      }]
  }), [rotation,data]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: '100%', width: '100%' } }}
        constructorType={'mapChart'}
      />
    </div>
  );
});

export default Globe;
import React from 'react';
import InfoBox from 'components/Layout/InfoBox';
import EconSeriesChartCard from 'analytics/economy/EconSeriesChartCard';
import useEconReportOverview from 'data/economy/useEconReportOverview';
import ReactMarkdown from 'react-markdown';
import PriceVsEconomy from 'analytics/economy/PriceVsEconomy';
import EconOverviewGlobal from 'analytics/economy/EconOverviewGlobal';
import ReportTemplateMarketEcon from './ReportTemplateMarketEcon';

export default function EconomyReport() {
  const { econReportOverview } = useEconReportOverview();
 
  const reportMarket = [
    {
      id: 'macroEconMetric',
      name: 'Macro Economy Metrics Overview'
    },
    {
      id: 'marketVSeconomy',
      name: 'Impact of Fed Moves on Market',
    },
    {
      id: 'economyGlobal',
      name: 'Global Economy Overview',
    },
  ];

  const overview = <ReactMarkdown>{econReportOverview?.['ReportOverview']}</ReactMarkdown>;


  return (
    <ReportTemplateMarketEcon reportComponents={reportMarket} overview={overview}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {reportMarket.map((obj) => (
          <ReportSection key={obj.id} id={obj.id} name={obj.name}/>
        ))}
      </div>
    </ReportTemplateMarketEcon>
  );
}

const ReportSection = React.memo(({ id, name}) => {
  return (
    <div id={id}>
      <InfoBox>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <h3>{name}</h3>
          <div style={{ flex: 1 }}>
            <EconomyInfo info={id}/></div>
        </div>
      </InfoBox>
    </div>
  );
});


function EconomyInfo({info}){
 
 
  const reportDetails = {
    "macroEconMetric":<MacroEconMetrics/>,
   'marketVSeconomy': <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100vh' }}>
   <PriceVsEconomy display="marketTabs" infoName="Federal Bank Moves & Impact on Market" />
 </div>,
 'economyGlobal': <div style={{ height: '100vh', width: '100%', overflow: 'auto' }}>
 <EconOverviewGlobal sx={{ height: '100%' }} />
</div>
  }

  return (
    <>
    { reportDetails[info]}
    </>
   )
}


function MacroEconMetrics (){
  const overviewIds = ['GDP', 'M1SL', 'DFF', 'FPCPITOTLZGUSA', 'T10YIE', 'UNRATE', 'PSAVERT', 'POPTHM'];

  return (
    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
        {overviewIds.map((obj) => (
          <EconSeriesChartCard key={obj} seriesName={[obj]} />
        ))}
      </div>
  )
}
import React from 'react'
import TableGen from 'components/Table/TableGen'
import { dfin } from 'content/constants';
import useDividendCalendar from 'data/eod/useDividendCalendar';
import { useAppInfo } from 'AppState';
import LoadingSkeletonTable from '../components/Loading/LoadingSkeletonTable';

export default function DividendCalendar() {
  const {stateTicker} = useAppInfo()
  const {dividendCalendar} = useDividendCalendar()
  const dividendCalendarTicker = dividendCalendar && dividendCalendar.map(obj => {
    const tickInfo = stateTicker.tickerInfo.find(objT => objT.TICKER === obj.code);
    const {code,...rest} = obj
    return {
      TICKER: code,
      [dfin.coLogo]: tickInfo?.[dfin.coLogo],
      ...rest,
    };
  });
  

  return (
    <>
    {dividendCalendarTicker.length === 0 && <LoadingSkeletonTable length={50}/>}
    {dividendCalendarTicker.length>0 && <TableGen logo={true} data={dividendCalendarTicker}/>}
    </>
  )
}

import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function Notification({open=false,message,alertType="success",onClose,}) {
  return (
    <Snackbar
            open={open}
            style={{top:"50px"}}
            autoHideDuration={1000*60}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert 
            onClose={onClose}
            severity={alertType} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
  )
}
import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useShareholder(peerSelected) {
    const [shareholders, setShareholders] = useState([]);
    const [loadingShareholders, setLoadingShareholders] = useState(false);
    const [error, setError] = useState(null);
    
    const previousPeerSelectedRef = useRef([]);
    
    useEffect(() => {
      const isPeerSelectedEqual =
        // peerSelected.length === previousPeerSelectedRef.current.length &&
        peerSelected.every(element => previousPeerSelectedRef.current.includes(element));
    
      if (!isPeerSelectedEqual) {
        getData();
        previousPeerSelectedRef.current = peerSelected;
      }
    }, [peerSelected]);
    
    async function getData() {
      setLoadingShareholders(true);
      try {
        const response = await axios.get(`${BASE_URL}/shareholderInfo/`, {
          params: {
            ticker: JSON.stringify(peerSelected),
          },
        });
        setShareholders(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingShareholders(false);
      }
    }
    
    return {shareholders,loadingShareholders}
}

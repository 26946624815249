import React from 'react';

const BarSVG = ({ data, width, height }) => {

  // Find the maximum value in the data for scaling
  const maxValue = Math.max(...data);

  // Calculate the scaling factor for the bars
  const yScale = height / maxValue;

  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      {data.map((value, index) => {
        // Calculate the position and height of each bar
        const x = index * (width / data.length);
        const barHeight = value * yScale;
        const y = height - barHeight;

        return (
          <rect key={index} x={x} y={y} width={width / data.length} height={barHeight} fill="blue" />
        );
      })}
    </svg>
  );
};

export default BarSVG;
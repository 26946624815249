import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import React from 'react'

export default function LiabilityManageSteps() {
  return (
   <div>
    <h3 style={{padding:"1rem"}}>Steps to Manage Liabilities</h3>
    <div style={{display:"flex",gap:"0.5rem",flexDirection:"column",maxWidth:"700px",flex:1}}>
        {liabilitySteps.map((step, index) => (
            <AccordionTSCustom key={index} title={step.title}>
                <ul>
                    {step.points.map((point, idx) => (
                        <li key={idx}>{point}</li>
                    ))}
                </ul>
            </AccordionTSCustom>
        ))}
    </div>
  </div>
  )
}


const liabilitySteps = [
    {
      title: "1. Create a Comprehensive List of Liabilities",
      points: [
        "Start by listing all your liabilities, including mortgages, loans, credit card debts, and any other financial obligations."
      ]
    },
    {
      title: "2. Prioritize Liabilities Based on Interest Rates",
      points: [
        "Focus on paying off high-interest debts first, such as credit card debt, while maintaining minimum payments on lower-interest liabilities."
      ]
    },
    {
      title: "3. Set Up a Repayment Plan",
      points: [
        "Develop a structured repayment plan that aligns with your income and financial goals.",
        "Consider using strategies like the snowball or avalanche method to manage debt."
      ]
    },
    {
      title: "4. Refinance or Consolidate Debt",
      points: [
        "Explore options to refinance high-interest loans or consolidate multiple debts into a single loan with a lower interest rate, making payments more manageable."
      ]
    },
    {
      title: "5. Build an Emergency Fund",
      points: [
        "Ensure you have an emergency fund to cover unexpected expenses, which can prevent you from taking on additional debt."
      ]
    },
    {
      title: "6. Allocate a Portion of Income to Debt Repayment",
      points: [
        "Regularly allocate a specific portion of your income towards debt repayment. Consistency is key to reducing liabilities over time."
      ]
    },
    {
      title: "7. Monitor and Adjust Spending",
      points: [
        "Review your spending habits and adjust where necessary to free up more money for debt repayment."
      ]
    },
    {
      title: "8. Periodically Review Liabilities",
      points: [
        "Regularly review your liabilities to ensure you are on track with your repayment plan and make adjustments as needed."
      ]
    },
    {
      title: "9. Avoid Taking on New Debt",
      points: [
        "Be cautious about taking on new debt unless it’s absolutely necessary and aligned with your long-term financial goals."
      ]
    },
  ];
  
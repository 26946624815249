import React, { createContext } from 'react'
import Resizer from './Resizer'
import Information from './Information';
import { WatchList } from 'watchlist/WatchList';
import { useAppInfo } from 'AppState';
import Hotlist from './Hotlist';
import Notes from './Notes';
import SettingsSidebar from './SettingsSidebar';
import Help from './Help';
import { Peers } from 'peerList/Peers';
import MarketNews from 'newsEvents/MarketNews';
import ChatHome from 'chat/ChatHome';
import FinancialCalculator from 'myFinancials/Calculator/FinancialCalculator';
import ComingSoon from 'components/ComingSoon';



export default function SidebarRightContent() {
    const {stateSidebar} = useAppInfo()
    const sidebarContent = {};
    sidebarContent["watchlist"] = (
      <WatchList/>
    );
    sidebarContent["peers"] = (
      <Peers/>
    );

    sidebarContent["notifications"] =<ComingSoon name={"Notifications"}/>
    sidebarContent["information"] = (<Information/>)
    sidebarContent["chat"] = <ChatHome/>
    sidebarContent["settings"] = (<SettingsSidebar openSettings={true}/>)
    sidebarContent["hotlist"] = (<Hotlist/>)
    sidebarContent["notes"] = (<Notes/>)
    sidebarContent["help"] = <ComingSoon name={"Help"}/>
    sidebarContent["news"] = (<MarketNews/>)
    // sidebarContent["calendar"] = (<Calendar/>)
    sidebarContent["calculator"] = <FinancialCalculator/>

  return (
    <Resizer orientV={true}>{sidebarContent[stateSidebar.sidebarRightContent]}</Resizer>
  )
}

import React, {
  useEffect,
  useState,
} from 'react';

import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import SelectBox from 'components/Inputs/SelectBox';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import useRealTimePrice from 'data/eod/useRealTimePrice';
import {getSpecificArrayObjKeys,numberDecimal,} from 'utils/utilGeneral';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import {WatchListAddButton} from 'watchlist/WatchListAddButton';

export default function ForexExchangeRate({headerHeight="8%"}) {
  const {stateTicker,dispatchTicker} = useAppInfo()
  const handleTickerChange = useHandleTickerChange()
  const {tickerForex,tickerForexCode} = stateTicker
  const tickerList = stateTicker.tickerInfo.filter(obj=>obj["TYPE"]==="Forex").map(obj=>obj[dfin.ticker])
  const [domesticTicker,setDomesticTicker] = useState(tickerForex)
  const [foreignTicker,setForeignTicker] = useState(tickerForex==="USD"?"EUR":"USD")

  const [quoteType,setQuoteType] = useState("Indirect")
  const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:tickerForexCode})
  const { price, loadingPrice } = useRealTimePrice({ tickerSel:tickerForexCode });

  useEffect(()=>{
    setDomesticTicker(tickerForex)
    setForeignTicker(tickerForex==="USD"?"EUR":"USD")
  },[tickerForex])


  useEffect(()=>{
    let tickerForexCode = domesticTicker+foreignTicker + ".FOREX"
    if (quoteType ==="Indirect"){
      tickerForexCode = foreignTicker + domesticTicker + ".FOREX"
    }

    dispatchTicker({"tickerForexCode":tickerForexCode})
  },[domesticTicker,foreignTicker,quoteType])



  return (
    <div className='containerColumnFlex'>
    <div className='page_header_box' style={{height:headerHeight}}>
      <div className='section'>
      <SelectBox size='small' sx={{width:"200px"}} label={"Domestic Currency"} 
      options={tickerList} value={domesticTicker} onChange={(e,v)=>handleTickerChange({ticker:v})}/>
      <SelectBox size='small' sx={{width:"200px"}} label={"Foreign Currency"} options={["USD","EUR"]} value={foreignTicker} onChange={(e,v)=>setForeignTicker(v)}/>
      <SelectBox size='small' sx={{width:"200px"}} label={"Quote Type"} options={["Direct","Indirect"]} value={quoteType} onChange={(e,v)=>setQuoteType(v)}/> 
      </div>
      <div className='section' style={{flex:1}}>
      <div style={{fontWeight:"bold",marginRight:"1rem"}}>
      {quoteType=="Direct" && `${domesticTicker}/${foreignTicker}`}
      {quoteType=="Indirect" && `${foreignTicker}/${domesticTicker}`}
      </div>
      {price && 
          <div
            style={{
              
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "1rem",
              zIndex: 5,
              background: "white",
              marginLeft:"1rem",
              
            }}
          >
            {/* <div>Ask:Bid</div> */}

            <div>
            {typeof price?.open === 'number' ? price.open.toFixed(2) : "N/A"}
          </div>
          
          <div
                style={{
                  color: price?.change > 0 ? "green" : "red",
                  display: "flex",
                  gap:"0.2rem"
                }}
              >
               {price?.change > 0 ? "▲" : "▼"} {numberDecimal(price?.change)} ({numberDecimal(price?.change_p)}%)
              </div>
             
            
          </div>}
          </div>
          <div className='page_header_toolkit'>
           <WatchListAddButton tickerName={stateTicker.tickerForex}/>
          </div>
  </div>
    
    <div className='containerColumnFlex1'>
      {(priceInfo.length>0 && !loadingPriceInfo) 
      && <TimeSeriesChart seriesName="adjusted_close" 
      data={getSpecificArrayObjKeys(priceInfo,["date","adjusted_close"])}/>}
      </div>
    </div>
  )
}

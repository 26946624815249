import React, { useRef, useState } from 'react';
import './Doodle.css'; // Create a separate CSS file for styling
import { Button, Paper, TextField, Slider, Tooltip, IconButton, Typography } from '@mui/material';
// import { ChromePicker } from 'react-color';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DrawIcon from '@mui/icons-material/Draw';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MoreVert } from '@mui/icons-material';
import TextInput from '../Inputs/TextInput';
import { createPortal } from 'react-dom';
import BrushIcon from '@mui/icons-material/Brush';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const Doodle = () => {
  const [drawing, setDrawing] = useState(false);
  const [currentPoints, setCurrentPoints] = useState([]);
  const [paths, setPaths] = useState([]);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [erasing, setErasing] = useState(false);
  const [strokeColor, setStrokeColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(3);
  const [opacity, setOpacity] = useState(1);
  const svgRef = useRef(null);
  const [drawSettings,setDrawSettings] = useState(false)
  const [drawType,setDrawType] = useState("pen")

  const getRelativeCoordinates = (e) => {
    const svgRect = svgRef.current.getBoundingClientRect();
    return { x: e.clientX - svgRect.left, y: e.clientY - svgRect.top };
  };

  const handlePointerDown = (e) => {
    e.preventDefault(); // Prevent any default action
    if (erasing) {
      setDrawing(true);
      erasePath(e);
      return;
    }
    setDrawing(true);
    const point = getRelativeCoordinates(e);
    setCurrentPoints([point]);
  };

  const handlePointerMove = (e) => {
    if (drawing) {
      e.preventDefault(); // Prevent any default action
      if (erasing) {
        erasePath(e);
        return;
      }
      const point = getRelativeCoordinates(e);
      setCurrentPoints((prevPoints) => [...prevPoints, point]);
    }
  };

  const handlePointerUp = (e) => {
    e.preventDefault(); // Prevent any default action
    setDrawing(false);
    if (!erasing) {
      if (currentPoints.length > 0) {
        setHistory((prevHistory) => {
          const lastState = prevHistory[prevHistory.length - 1];
          if (JSON.stringify(lastState) !== JSON.stringify(paths)) {
            return [...prevHistory, paths];
          }
          return prevHistory;
        }); // Save current paths to history before modification
        if (currentPoints.length === 1) {
          // Create a dot if there's only one point
          const newPath = [...currentPoints, { ...currentPoints[0], x: currentPoints[0].x + 1 }];
          setPaths((prevPaths) => [...prevPaths, { points: newPath, color: strokeColor, width: strokeWidth, opacity }]);
        } else if (currentPoints.length > 1) {
          setPaths((prevPaths) => [...prevPaths, { points: currentPoints, color: strokeColor, width: strokeWidth, opacity }]);
        }
        setCurrentPoints([]);
        setRedoStack([]);
      }
    }
  };

  const getSmoothPath = (points) => {
    if (points.length < 2) {
      return '';
    }
  
    const path = points.reduce((acc, point, index, array) => {
      if (index === 0) {
        // Handle first point with adjusted midpoint calculation
        const firstMidPoint = {
          x: point.x * 0 + points[1].x * 1,
          y: point.y * 0 + points[1].y * 1,
        };
        return `M ${point.x} ${point.y} S ${point.x} ${point.y}, ${firstMidPoint.x} ${firstMidPoint.y}`;
      }
  
      const prevPoint = array[index - 1];
      const midPoint = {
        x: (prevPoint.x + point.x) / 2,
        y: (prevPoint.y + point.y) / 2,
      };
  
      return `${acc} S ${prevPoint.x} ${prevPoint.y}, ${midPoint.x} ${midPoint.y}`;
    }, '');
  
    // Handle last point with adjusted midpoint calculation
    const lastPoint = points[points.length - 1];
    const lastMidPoint = {
      x: points[points.length - 2].x * 0 + lastPoint.x * 1, // Adjust weight factors for smoother end
      y: points[points.length - 2].y * 0 + lastPoint.y * 1,
    };
  
    // Smooth curve with adjusted weight for end
    return (
      path +
      ` S ${points[points.length - 2].x} ${points[points.length - 2].y}, ${lastMidPoint.x} ${lastMidPoint.y} S ${lastMidPoint.x} ${lastMidPoint.y}, ${lastPoint.x} ${lastPoint.y}`
    );
  };

  
  

  const handleUndo = () => {
    if (history.length > 0) {
      const lastState = history.pop();
      setRedoStack((prevRedo) => [paths, ...prevRedo]);
      setPaths(lastState);
      setHistory([...history]);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const restoredState = redoStack.shift();
      setHistory((prevHistory) => [...prevHistory, paths]);
      setPaths(restoredState);
      setRedoStack([...redoStack]);
    }
  };

  const toggleEraser = () => {
    setErasing(!erasing);
  };

  const erasePath = (e) => {
    const eraserSize = 10; // Adjust eraser size as needed
    const point = getRelativeCoordinates(e);
    setHistory((prevHistory) => {
      const lastState = prevHistory[prevHistory.length - 1];
      if (JSON.stringify(lastState) !== JSON.stringify(paths)) {
        return [...prevHistory, paths];
      }
      return prevHistory;
    });
    const newPaths = paths.filter((path) =>
      !path.points.some(
        (p) => Math.abs(p.x - point.x) < eraserSize && Math.abs(p.y - point.y) < eraserSize
      )
    );
    setPaths(newPaths);
    setRedoStack([]); // Clear the redo stack after erasing to prevent inconsistent states
  };

  const clearAll = () => {
    setPaths([]);
    setCurrentPoints([]);
    setRedoStack([]); // Clear redo stack as well
    setHistory((prevHistory) => [...prevHistory, paths]); // Add current state to history
  };

  const [edit,setEdit] = useState(true)

  return (
    <>

    <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",zIndex:edit?"10000":"0"}}>
      {edit && <svg
          ref={svgRef}
          className="doodle-container"
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerUp} // Ensures drawing stops when pointer leaves the svg
        >
          {paths.map((path, index) => (
            <path
              key={index}
              d={getSmoothPath(path.points)}
              stroke={path.color}
              strokeWidth={path.width}
              fill="none"
              opacity={path.opacity}
              style={{zIndex:"10000"}}
            />
          ))}
          <path
            d={getSmoothPath(currentPoints)}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            fill="none"
            opacity={opacity}
            // strokeDasharray="1,4" 
          />
        </svg>}
    </div>

    <>
    <div style={{ padding: "0.5rem", display: "flex", gap: "1rem", 
    position: "absolute", bottom: 0, width: "100%", boxSizing: "border-box", 
    justifyContent: "center", alignItems: "center"}}>
  <div style={{ display: 'flex', gap: '1rem', alignItems: "center", 
  padding: "0.5rem 1rem", borderRadius: "25px", width: "max-content", 
  boxSizing: "border-box",position:"absolute",left:0,top:0,zIndex:"10000"}}>
    <Button sx={{minWidth:0}} onClick={handleUndo}><UndoIcon fontSize='small' /></Button>
    <Button sx={{minWidth:0}} onClick={handleRedo}><RedoIcon fontSize='small' /></Button>
    <Button sx={{minWidth:0}} onClick={clearAll}><DeleteForeverIcon fontSize='small' /></Button>
    <Button onClick={()=>setEdit(!edit)}>{edit? "Stop Edit":"Edit"}</Button>
  </div>
  <div style={{ display: 'flex', gap: '1rem', alignItems: "center", 
  background: "white", padding: "0.5rem 1rem", borderRadius: "25px", 
  width: "max-content", boxSizing: "border-box",zIndex:edit?"10000":"0"}}>

    <Tooltip title="pen"><Button variant={(!erasing && drawType==="pen") && "contained"} sx={{minWidth:0}} 
    onClick={() => {setErasing(false);setDrawType("pen");setStrokeWidth(3);setOpacity(1);setStrokeColor("#000000")}}><DrawIcon /></Button>
    </Tooltip>
    <Tooltip title="brush"><Button variant={(!erasing && drawType==="brush") &&  "contained"} sx={{minWidth:0}} 
    onClick={() =>{setErasing(false);setStrokeWidth(8)
    ;setDrawType("brush");setOpacity(1);setStrokeColor("#000000")}}><BrushIcon/></Button>
    </Tooltip>
    <Tooltip title="highlighter"><Button variant={(!erasing && drawType==="highlighter") &&  "contained"} sx={{minWidth:0}} 
    onClick={() =>{setErasing(false);setStrokeWidth(12)
    ;setDrawType("highlighter");setOpacity(0.6);setStrokeColor("#ffff00")}}><DriveFileRenameOutlineIcon/></Button>
    </Tooltip>
    <Tooltip title="erase"><Button variant={erasing && "contained"} sx={{minWidth:0}} onClick={() => setErasing(true)}><AutoFixNormalIcon /></Button>
    </Tooltip>
  </div>
</div>
      {!drawSettings && 
      <IconButton sx={{position:"absolute",right:"5px",top:"5px",zIndex:"10000",background:"#ddd"}} onClick={()=>setDrawSettings(true)}><MoreVert/></IconButton>}
      {drawSettings && 
      <Paper style={{position:"absolute",right:"5px",top:"5px"
      ,padding:"1rem",zIndex:"50",display:"flex",flexDirection:"column"
      ,gap:"1rem",width:"100px",zIndex:"10000"}}>
      <div style={{display:"flex",alignItems:"center",gap:"1rem"}}>
      <Typography>Draw Settings</Typography>
      <ArrowForwardIosIcon fontSize='small' sx={{marginLeft:"auto"}} onClick={()=>setDrawSettings(false)}/>
      </div>
      <input type="color" value={strokeColor} onChange={(e) => setStrokeColor(e.target.value)} />
        <div>
        <TextInput
          label="Stroke Width"
          type="number"
          value={strokeWidth}
          onChange={(v) => setStrokeWidth(Number(v))}
          fontSize={"small"}
        />
        </div>
        <>
          <label>Opacity</label>
          <Slider
            sx={{width:"100%",maxWidth:"100%"}}
            value={opacity}
            min={0.1}
            max={1}
            step={0.1}
            onChange={(e, value) => setOpacity(value)}
            aria-labelledby="opacity-slider"
          />
        </>
      </Paper>}
      </>
      {/* ,document.body)} */}
    </>
  );
};

export default Doodle;





// import React, { useRef, useEffect, useState } from 'react';

// const Doodle = () => {
//   const canvasRef = useRef(null);
//   const [drawing, setDrawing] = useState(false);
//   const [context, setContext] = useState(null);

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext('2d');
//     setContext(ctx);
//   }, []);

//   const startDrawing = (e) => {
//     setDrawing(true);
//     const { offsetX, offsetY } = e.nativeEvent;
//     context.beginPath();
//     context.moveTo(offsetX, offsetY);
//   };

//   const draw = (e) => {
//     if (!drawing) return;
//     const { offsetX, offsetY } = e.nativeEvent;
//     context.lineTo(offsetX, offsetY);
//     context.stroke();
//   };

//   const stopDrawing = () => {
//     setDrawing(false);
//     context.closePath();
//   };

//   return (
//     <canvas
//       ref={canvasRef}
//       onMouseDown={startDrawing}
//       onMouseMove={draw}
//       onMouseUp={stopDrawing}
//       onMouseOut={stopDrawing}
//       width="1000px" height="1000px"
//       style={{ border: '1px solid #000',overflow:"hidden",marginRight:"100px"}}
//     />
//   );
// };

// export default Doodle;
import React from 'react'
import HomePageTemplate from '../../home/HomePageTemplate'
import Terms from '../../home/terms/Terms'

export default function TermsConditions() {
  return (
<HomePageTemplate pageName='Terms'>
<Terms/>
</HomePageTemplate>
  )
}

import React, { useEffect, useState } from 'react'
import TableGen from 'components/Table/TableGen';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import useManagement from 'data/company/useManagement';
import { useAppInfo } from 'AppState';
import useCompanyExecutives from 'data/financialModelingPrep/CompanyOwnership/useCompanyExecutives';
import {PageMainContentTitle} from 'components/Font/Title';

export default function CompanyManagement () {
  const {stateTicker,stateStock} = useAppInfo()
  const companySelected = stateStock.companySelected
  const companyName = stateStock.coInfo?.[dfin.coName]
  const peerSelected = stateTicker.peerSelected
  const {mgmtInfo,loadingMgmtInfo} = useManagement(peerSelected)
  const {executives,loading} = useCompanyExecutives(stateTicker.tickerStock)
  const [data,setData] = useState([])
 
  
  useEffect(()=>{ 
    if (mgmtInfo.length>0 && !loadingMgmtInfo){
    const coMgmt = mgmtInfo.filter(obj => obj[dfin.ticker] === companySelected)
    const columnHeadings = coMgmt.length > 0 && Object.keys(coMgmt[0]).filter((key) => key !== dfin.ticker);
    const data = coMgmt.length > 0 && getSpecificArrayObjKeys(coMgmt,columnHeadings)
    setData(data)}
  },[companySelected,mgmtInfo])

  return (
    <div className='containerColumnFlex'>
      <PageMainContentTitle>{companyName}'s Management</PageMainContentTitle> 
      {executives.length === 0 && <TableGen data={data} height='500px' tableLayout='fixed'/>}
      {executives.length > 0 && <TableGen data={executives}/>}
      </div>
  );
};
import React, { useState,useRef,useEffect } from 'react';
import SearchInfo from './SearchInfo';
import SearchTicker from './SearchTicker';
import SearchBar from 'components/Inputs/SearchBar';
import { useLocation } from 'react-router-dom';
import SelectBox from 'components/Inputs/SelectBox';
import SearchEconomy from 'search/SearchEconomy';
import SearchCompanyIndSec from './SearchCompanyIndSec';

export default function SearchUI({type="All",addType="watchlist"}) {
  const [searchType,setSearchType] = useState("Ticker")
  const [searchTerm,setSearchTerm] = useState("")
  const location = useLocation()
  const path = location.pathname.split("/")
  useEffect(() => {
    // Check the `path` variable and set search type accordingly
    if (type==="All"){
    switch (true) {
      case path.includes("Industry"):
        setSearchType("Industry/Sector");
        break;
      case path.includes("Ticker"):
        setSearchType("Ticker");
        break;
      case path.includes("Resource"):
        setSearchType("Resource");
            break;
      case path.includes("Economy"):
        setSearchType("Economy");
                break;
      default:
        setSearchType("Ticker")
        break;
    }}
    else{
      setSearchType(type)
    }
  }, [type]); 


  
  function handleSearchChange(event) {
    const searchTerm = event.target.value.toLowerCase(); 
    setSearchTerm(searchTerm);
  
  }

  
   const searchPlaceHolder = {
    "Ticker":"Search Ticker, eg. AAPL or Apple",
    "Resource":"Search Information",
    "Economy":"Search Economy Indicators",
    "Industry/Sector":"Search Industry or Sector or Company"
   }
    
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", 
      maxWidth: "100%",width:"100%",height:"100%"}}>
       <SearchBar onInput={handleSearchChange} 
       placeholder={searchPlaceHolder[searchType]}>
        {type==="All" && <div style={{width:"25%",minWidth:"200px"}}>
       <SelectBox sx={{background:"white"}} variant='outlined' options={["Ticker","Industry/Sector","Economy","Resource"]} 
      value={searchType} onChange={(e,v)=>setSearchType(v)} label={"Search in"}/>
      </div>}
       </SearchBar>
      
    {searchType === "Industry/Sector" && <SearchCompanyIndSec searchTerm={searchTerm}/>}
    {searchType ==="Ticker" && <SearchTicker addType={addType} searchTerm={searchTerm}/>} 
    {searchType ==="Resource" && <SearchInfo searchTerm={searchTerm}/>}
    {searchType ==="Economy" && <SearchEconomy searchInput={searchTerm}/>}

      </div>
    </>
  );
}

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the Highcharts More module
require("highcharts/modules/accessibility")(Highcharts);

HighchartsMore(Highcharts);


export default function PortfolioTransactionTickerChart ({ priceData, transactions, ticker }) {
    const formattedPriceData = formatPriceData(priceData, ticker); 
    const formattedTransactionData = formatTransactionData(transactions, ticker);
  
    const options = {
      chart: {
        type: 'line'  // Use line for price series
      },
      title: {
        text: `Price and Transactions for ${ticker}`
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Price'
        }
      },
      series: [
        {
          name: `${ticker} Price`,
          data: formattedPriceData,
          type: 'line',
          // color: 'blue',
          tooltip: {
            pointFormat: `Price: {point.y}`
          }
        },
        {
          name: 'Transactions',
          type: 'scatter',  // Use scatter for transactions
          data: formattedTransactionData,
          marker: {
            enabled: true
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `${this.point.transactionType}`;
            },
            style: {
              color: 'black',
              fontSize: '16px'
            },
            align: 'right',
            verticalAlign: 'middle'
          },
          tooltip: {
            pointFormat: '{point.name}: {point.y} ({point.transactionType})'
          }
        }
      ],
      exporting:{
        enabled:false
      },
      credits: {
        enabled: false, // Hide Highcharts.com credits
      },
      accessibility: {
        enabled: false, // Include the accessibility module
      },
    };
  
    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  };
  
  
  
  const formatPriceData = (priceData, ticker) => {
    return priceData.map((entry) => [
      new Date(entry.Date || entry.date).getTime(),
      entry[ticker],
    ])
  
  };
  
  const formatTransactionData = (transactions, ticker) => {
    return transactions
      .filter(transaction => transaction.TICKER === ticker)
      .map(transaction => {
        return {
          x: new Date(transaction.date).getTime(),
          y: Number(transaction.price),
          name: transaction.NAME,
          ticker: transaction.TICKER,
          transactionType: transaction.transactionType,
          marker: {
            symbol: transaction.transactionType === 'Buy' ? 'triangle' : 'triangle-down',
            fillColor: transaction.transactionType === 'Buy' ? 'green' : 'red',
            radius: 6
          }
        };
      });
  };
  
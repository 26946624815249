import React, { useState, useEffect,useRef} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";


export default function useCorrelation(tickerList,dateFrequency,period) {

const [corr, setCorr] = useState([]);
const [corrLoading, setMarketInfoLoading] = useState(false);


useEffect(() => {
    getPriceInfo()
}, [tickerList,dateFrequency,period]);


async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/corr/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        dateFrequency:dateFrequency,
        period:period
        },
    });
   
    const marketInfo = response.data
    setCorr(JSON.parse(marketInfo))
    } catch (error) {
    console.error(error);
    } finally {
    setMarketInfoLoading(false);
    }
}
return {corr, corrLoading };
}
import React, { useEffect, useState, useRef } from 'react'
import SendIcon from '@mui/icons-material/Send';
import { Button, Paper, IconButton, Tooltip } from '@mui/material'
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { useAppInfo } from 'AppState';
import useHandleUserEvents from './useHandleUserEvents';
import { debounce } from 'lodash';
import { defaultMessageList, messagePlaceholders } from 'appState/initialStateAssistant';

export default function MessageBarBig() {
  const { stateApp, dispatchApp,stateAssistant} = useAppInfo();

  const [input,setInput] = useState()
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const { handleSend, handleVoiceRecording, handleShowContent, handleStopChat } = useHandleUserEvents();
  const {voiceRecording, chatStatus } = stateAssistant;

  const buttonRefs = useRef([]); // To store button references

  const placeholders = messagePlaceholders
  
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [placeholderText, setPlaceholderText] = useState('Ask anything finance...');
  const [textIndex, setTextIndex] = useState(0);
  const [typingDirection, setTypingDirection] = useState(1);
  // Typing effect for placeholder
  const [isWaiting, setIsWaiting] = useState(false); // New state for waiting

  useEffect(() => {
    const typingSpeed = typingDirection === 1 ? 100 : 10; // Faster speed for clearing text
    const waitTime = 1000; // Time to wait before starting to clear
  
    const startTypingEffect = () => {
      const timeout = setTimeout(() => {
        if (textIndex >= placeholders[placeholderIndex].length && typingDirection === 1 && !isWaiting) {
          // Wait before clearing text
          setIsWaiting(true);
          setTimeout(() => {
            setIsWaiting(false);
            setTypingDirection(-1); // Start clearing after the wait
          }, waitTime);
        } else if (textIndex <= 0 && typingDirection === -1) {
          setPlaceholderIndex((prev) => (prev + 1) % placeholders.length); // Move to the next placeholder
          setTypingDirection(1); // Start typing the next placeholder
        } else if (!isWaiting) {
          setTextIndex((prev) => prev + typingDirection); // Continue typing or clearing
        }
  
        setPlaceholderText(placeholders[placeholderIndex].slice(0, textIndex)); // Update placeholder text
      }, typingSpeed);
  
      return () => clearTimeout(timeout);
    };
  
    // Wait for the component to render, then start the typing effect
    const rafId = requestAnimationFrame(() => startTypingEffect());
  
    return () => cancelAnimationFrame(rafId);
  }, [textIndex, typingDirection, isWaiting, placeholderIndex]);
  
  


  const debouncedFilterMessages = debounce((value) => {
    if (value) {
      try {
        const regex = new RegExp(value, 'i');
        const matches = defaultMessageList.filter((msg) => regex.test(msg));
        setFilteredMessages(matches);
      } catch (error) {
        console.error("Invalid regular expression:", error);
        setFilteredMessages([]);
      }
    } else {
      setFilteredMessages([]);
    }
  }, 300);

  useEffect(() => {
    debouncedFilterMessages(input);
  }, [input]);

  useEffect(() => {
    if (focusedIndex >= 0 && focusedIndex < buttonRefs.current.length) {
      buttonRefs.current[focusedIndex]?.focus();
    }
  }, [focusedIndex]);

  const handleSendMessage = () => {
    if (stateApp.isLoggedIn) {
      handleSend(input);
      handleShowContent(true);
    } else {
      dispatchApp({ loginModal: true });
    }
  };

  const handleKeyDown = (e) => {
    if (filteredMessages.length > 0) {
      if (e.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => (prevIndex + 1) % filteredMessages.length);
      } else if (e.key === "ArrowUp") {
        setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredMessages.length) % filteredMessages.length);
      }
    }
    if (e.key === "Enter" && filteredMessages.length === 0) {
      handleSendMessage();
    }
  };

  return (
    <div
      id="assistant"
      onKeyDown={handleKeyDown}
      style={{
        display: "flex",
        width: "100%",
        gap: "0.5rem",
        boxSizing: "border-box",
        background: "white",
        borderRadius: filteredMessages.length > 0 ? "25px 25px 0 0":"30px",
        padding: "1rem",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Box shadow added here
      }}
    >
      <Button
        className={voiceRecording ? "recording" : ""}
        sx={{ minWidth: '0', padding: 0 }}
        onClick={handleVoiceRecording}
      >
        <MicIcon />
      </Button>

      <div
        className='fade-in-text'
        style={{
          whiteSpace: 'nowrap',
          position: 'relative',
          top: 0,
          left: 0,
          flex: 1,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <input
          style={{
            boxSizing: "border-box",
            outline: "none",
            border: "none",
            fontSize: "1.4rem",
            flex: 1,
            background: "none",
            width: '100%',
          }}
          type='text'
          placeholder={placeholderText}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </div>

      {chatStatus === "running" ? (
        <Button
          onClick={() => handleStopChat()}
          sx={{ minWidth: 0, margin: 0 }}
        >
          <StopIcon />
        </Button>
      ) : (
        <Button
          onClick={() => handleSendMessage()}
          sx={{ minWidth: 0, margin: 0 }}
        >
          <SendIcon />
        </Button>
      )}

      {filteredMessages.length > 0 && (
        <Paper
          style={{
            position: "absolute",
            zIndex: 100,
            top: "100%", // Adjust to place the popover at the bottom
            left: 0,
            width: "100%",
            maxHeight: "150px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0 0 8px 8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fafafa",
          }}
        >
          {filteredMessages.map((message, index) => (
            <Button
              disableFocusRipple
              key={index}
              ref={(el) => (buttonRefs.current[index] = el)}
              onClick={(e) => { e.preventDefault(); handleSend(message); }}
              sx={{
                textAlign: 'left',
                width: '100%',
                color: '#333',
                justifyContent: "flex-start",
                padding: "0.25rem 1rem",
                background: focusedIndex === index ? '#f0f0f0' : 'transparent'
              }}
            >
              {message}
            </Button>
          ))}
        </Paper>
      )}
    </div>
  );
}


import React from 'react'
import SelectBox from './SelectBox'
import { currencyData } from 'content/contentCurrencyCode'

export default function SelectCurrency({value="USD",onChange,variant="outlined",label}) {
    function handleChange(v){
        const currencyObj =  currencyData.find(currency => currency.code === v)
        onChange(currencyObj)
    }
  return (
    <SelectBox 
    label={label}
    variant={variant}
    value={value}
    onChange={(e,v)=>handleChange(v)}
    options={currencyData.map(obj=>obj.code)}>
  
    </SelectBox>
  )
}

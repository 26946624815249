import React from 'react'
import SelectBox from 'components/Inputs/SelectBox';
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import { Tooltip } from '@mui/material';
import { useAppInfo } from 'AppState';

export default function ReportDetails() {
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
  const reportNames = stateReportBuilder.reportNames
  
  function handleReportName(e, v) {
    if (reportNames.includes(v)) {
      const reportData = stateReportBuilder.reportData;
      const pageData = reportData[v];
  
      dispatchReportBuilder({
        reportNameSel: v,
        pages: pageData,
        pageCurrent: 0
      });
    }
  }
  return (
    <div style={{display:"flex"
        ,gap:"0.5rem",alignItems:"center"
        ,flexWrap:"wrap",boxSizing:"border-box"}}>
        <div style={{flex:1}}>
      <SelectBox 
        variant="outlined" fontSize="1rem" width="auto" 
        label={"Name"} 
        sx={{background:"#fefefe",borderRadius:"5px"}}  
        options={reportNames} value={stateReportBuilder.reportNameSel} onChange={handleReportName}/>
    </div>
    <Tooltip title="Add Report"><Add sx={{cursor:"pointer"}} onClick={()=>dispatchReportBuilder({ addNewReport: true })}/></Tooltip> 
    {reportNames.length>0 && <Tooltip title="Delete Report"><Delete sx={{cursor:"pointer"}} onClick={()=>dispatchReportBuilder({ deleteReport: true })}/></Tooltip> 
    }
    </div>
      
  )
}

import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useManagement(peerSelected) {
    const [mgmtInfo, setMgmtCoInfo] = useState([]);
    const [loadingMgmtInfo, setLoadingMgmtCo] = useState(false);
    const [error, setError] = useState(null);
    
    const previousPeerSelectedRef = useRef([]);
    
    useEffect(() => {
      const isPeerSelectedEqual =peerSelected.every(element => previousPeerSelectedRef.current.includes(element));
    
      if (!isPeerSelectedEqual) {
        getData();
        previousPeerSelectedRef.current = peerSelected;
      }
    }, [peerSelected]);
    
    async function getData() {
      setLoadingMgmtCo(true);
      try {
        const response = await axios.get(`${BASE_URL}/managementInfo/`, {
          params: {
            ticker: JSON.stringify(peerSelected),
          },
        });
        setMgmtCoInfo(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingMgmtCo(false);
      }
    }
    
    return {mgmtInfo,loadingMgmtInfo}
}

import { Button, IconButton, Typography } from '@mui/material'
import { useAppInfo } from 'AppState'
import ChatContent from 'chatGPT/ChatContent'
import DefaultMessageBox from 'chatGPT/DefaultMessageBox'
import MessageBarBig from 'chatGPT/MessageBarBig'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getGreeting } from 'utils/utilGeneral'
import useHandleUserEvents from 'chatGPT/useHandleUserEvents'
import MessageTape from 'chatGPT/MessageTape'
import styles from "./HomePageChatLanding.module.css"

export default function HomePageChatLanding() {
  const {stateApp,stateAssistant,dispatchAssistant} = useAppInfo()
  const {username} = stateApp
  const {chats} = stateAssistant
  const navigate = useNavigate()
  const greeting = getGreeting()
  const {handleShowContent,handleNewChat} = useHandleUserEvents()

  function TakeStockOfYour (){
    const introText = ["Financial Health","Goals","Portfolio","Trades","Opportunities","Risks"]

    const [text, setText] = useState('');
    const [counter, setCounter] = useState(0);
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCounter((prev) => prev >= introText.length - 1 ? 0 : prev + 1);
      }, 5000);  // Increased duration to fully see the typewriter effect before it switches

      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        setText(introText[counter]);
        setDisplayText('');
    }, [counter]);

    useEffect(() => {
        if (text.length > displayText.length) {
            const timeoutId = setTimeout(() => {
                setDisplayText(text.substr(0, displayText.length + 1));
            }, 150);  // Typing speed can be adjusted here

            return () => clearTimeout(timeoutId);
        }
    }, [displayText, text]);

    return (
        <Typography variant='h2' sx={{fontWeight:"bold",margin:0,padding:0,color:"var(--selectBlue)"}}>
        {/* {username ? `Hi ${username?.split(" ")[0]},` : "Welcome to TakeStock"} */}
        {text}
      </Typography> 
        // <h1 style={{ fontSize: "5rem",color:"var(--selectBlue)"}}>
        // {text} 
        // </h1>
    )

}

  return (
    <>
   <div style={{ display: "flex", flexDirection: "column", 
    boxSizing: "border-box", position: "relative",background:"white",minHeight:"calc(100vh - 50px)"}}>
  <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", 
    padding: "1rem", gap: "2rem", flex: 1, 
     boxSizing: "border-box",paddingTop:"100px"}}>
    <Typography variant='h3' sx={{ fontWeight: "bold", margin: 0, padding: 0,textAlign:"center"}}>
      Take stock<br/><span style={{color:"green"}}>your financials</span>
    </Typography>

    <div style={{ width: "50%", maxWidth: "800px", minWidth: "400px" }}>
      <MessageBarBig />
    </div>

    <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
      <DefaultMessageBox />
    </div>
  </div>

  {/* Position MessageTape at the bottom */}
  <div style={{width: "100%",padding:"2rem",boxSizing:"border-box",position:"relative"}}>

    <MessageTape/>
  </div>
  {/* <div style={{width: "100%",padding:"1rem",boxSizing:"border-box",position:"relative",display:"flex",justifyContent:"flex-end"}}>
  <Button style={{fontWeight:"bold",fontSize:"2rem"}}>Learn More</Button> 
  </div> */}
</div>
  </>
  )
}

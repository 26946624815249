import React from 'react'
import useRealTimePrice from 'data/eod/useRealTimePrice';
import { numberDecimal } from 'utils/utilGeneral';


export default function RealTimePriceTable({tickerSel}) {
  const { price ,loadingPrice} = useRealTimePrice({ tickerSel });
  const closePrice = (price && !loadingPrice) && parseFloat(price?.close)==0?price?.open:price?.close
  return (
    <>
    <td>
    {!loadingPrice && numberDecimal(closePrice)}
    </td>
    <td  style={{color:price?.change_p>0?"green":"red"}}>
    {!loadingPrice && numberDecimal(price?.change)}
    </td>
    <td style={{color:price?.change_p>0?"green":"red"}}>
      {!loadingPrice &&  numberDecimal(price?.change_p)} 
    </td>
    </>
  )
}

import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { dfin } from 'content/constants';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

export default function useSecIndList() {
    const {dispatchIndustry} = useAppInfo()
    const [loadingSecIndList, setLoadingSecIndList] = useState(false);
    const [error, setError] = useState(null);
   
  
    useEffect(() => {  
        getData();    
    }, []);
    
    async function getData() {
      setLoadingSecIndList(true);
      try {
        const response = await axios.get(`${BASE_URL}/sectorIndustryList`)

        const sectorArray = response.data.map((item) => item[dfin.sector]);
        const uniqueSectors = [...new Set(sectorArray)];
        const industryArray = response.data.map((item) => item[dfin.industry]);
        const uniqueIndustries = [...new Set(industryArray)];
        dispatchIndustry({
          uniqueSectors: uniqueSectors,
          uniqueIndustries: uniqueIndustries,
          secIndList: response.data
        });
        
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSecIndList(false);
      }
    }
    
}

import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useAddWatchlist = (watchlistSelected) => {
  const {dispatchWatchlist,stateWatchlist,stateApp} = useAppInfo()
  
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);
  
  const addWatchlist = async (watchlistTickers) => {
    try {
      const response = await axios.post(`${BASE_URL}/addWatchlist/`, {
        userId,
        username,
        watchlistName:watchlistSelected,
        watchlistTickers,
      });
      
      dispatchWatchlist({"watchlistSelected":watchlistSelected})
      setError("Success")
    } catch (err) {
      setError('Error adding Watchlist data');
    }
  };

  return { addWatchlist, error };
};

export default useAddWatchlist;
import React from 'react'
import TechnicalChart from 'components/Charts/TechnicalChart';
import PriceVsBenchmark from 'analytics/technicals/PriceVsBenchmark';
import Technicals from 'analytics/technicals/Technicals';
import PriceVsEconomy from 'analytics/economy/PriceVsEconomy';

export default function TickerCommonInfoList({info}) {

  const content = {    
    "Technical Chart":<TechnicalChart/>,
    "Price vs Peers":<Technicals tabName={"Trend"} addTicker={true}/>,
    "Performance":<Technicals tabName={"Performance"} addTicker={true}/>,
    "Volatility":<Technicals tabName={"Volatility"} addTicker={true}/>,
    "Correlation":<Technicals tabName={"Correlation"} addTicker={true}/>,
    "Beta":<Technicals tabName={"Beta"} addTicker={true}/>,
    "Indicators":<Technicals tabName={"Indicators"} addTicker={true}/>,
    "Price vs Benchmark":<PriceVsBenchmark/>,
    "Price vs Economy":<PriceVsEconomy/>,
    }
  return (
    <>
    {content[info] || null}
    </>
  )
}

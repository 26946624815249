import React, { useState } from 'react'
import TabGeneral from 'components/Tabs/TabGeneral'
import { Alert } from '@mui/material'
import HomePageTemplate from '../../home/HomePageTemplate'

export default function Support() {
  const [tabIndex,setTabIndex] = useState(0)
 
  function handleTabChange(index){
    setTabIndex(index)
  }

  return (
    <HomePageTemplate pageName='Support'>
   
   <div style={{ padding: "0rem 0rem 1rem 0rem", alignSelf: "center" }}>
  <TabGeneral tabList={["FAQ's","Data","Billing"]} tabContent={[]} tabIndex={tabIndex} handleTabChange={handleTabChange}/>
  </div>
 
    <Alert severity='warning' sx={{marginTop:"2rem",height:"200px",fontSize:"2rem"}}>Support are not available in the Beta Version!</Alert>
    
    </HomePageTemplate>
   
  )
}

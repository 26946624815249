import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import useFindTopic from 'data/resource/useFindTopic';
import useSearchTopic from 'data/resource/useSearchTopic';
import { useAppInfo } from 'AppState';
import { Button } from '@mui/material';

export default function SearchInfoResource({ searchTerm }) {
  const {stateResource} = useAppInfo()
  const {learnContentTopics} = stateResource
  const [navigateR, setNavigateR] = useState("Category");
  const [categorySel, setCategorySel] = useState("Frequently Asked");
  const [topicSel, setTopicSel] = useState("Stocks");
  const [headingSel, setHeadingSel] = useState("");
  const { findTopics, resourceTopics } = useFindTopic();
  const topicFilt = learnContentTopics.filter((obj) => obj.Category === categorySel);
  const topicContent = topicFilt[0]?.Topics || [];
  const {searchTopic, topicList} = useSearchTopic()
  const [questionList,setQuestionList] = useState([])
  const [takeaways, setTakeaways] = useState([]);
  
  useEffect(()=>{
    if(resourceTopics){
      setQuestionList(resourceTopics)
    }
    
  },[resourceTopics])
  useEffect(()=>{
    if (searchTerm !== ""){
    searchTopic(searchTerm)
    }
  },[searchTerm])
  
  useEffect(() => {
    findTopics(topicSel);
  }, [topicSel]);


  useEffect(()=>{
  if (topicList){
        setNavigateR("Questions")
        const categorySel = topicList[0]?.["Category"]
        const topic = topicList[0]?.["Topic"]
        setTopicSel(topic)
        setCategorySel(categorySel)
      }
    },[topicList])


  function handleHeading(obj) {
    setNavigateR("Info");
    setHeadingSel(obj.Heading);
  }

  
  useEffect(() => {
    if (questionList && headingSel) {
      const takeawayDetails = questionList.find((obj) => obj.Heading.toLowerCase() === headingSel.toLowerCase())?.Takeaways;
      const formattedTakeaways = takeawayDetails
      setTakeaways(formattedTakeaways);
    }
  }, [questionList, headingSel]);


  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>

      {(navigateR !== "Info" && questionList) &&
  questionList
    .filter(obj =>
      typeof obj.Heading === 'string' &&
      (typeof searchTerm === 'string' && searchTerm.trim() !== '' ?
        obj.Heading.split(' ').some(headingWord =>
          searchTerm.split(' ').some(searchWord =>
            headingWord.toLowerCase().includes(searchWord.toLowerCase())
          )
        ) : true)
    )
    .map((obj) => (
      <Button
        style={{
          whiteSpace: "break-spaces",
          textTransform:"none",
          textAlign: "left",
          borderRadius: "0",
          textOverflow: "ellipsis",
          overflow: "hidden",
          border: "none",
          width: "100%",justifyContent:"flex-start"
        }}
        key={obj.Heading}
        onClick={() => handleHeading(obj)}
      >
        <Typography>{obj.Heading}</Typography>
      </Button>
    ))}
      {(navigateR === "Info" && takeaways) && (
        <div style={{padding:"0.5rem"}}>
          <h3 style={{marginBottom:"1rem",fontWeight:"bold"}}>{headingSel}</h3>
          <div dangerouslySetInnerHTML={{ __html: takeaways}}></div>
        </div>
      )}
    </div>
  );
}

  


import { Button, Paper, Popover, Tooltip, Typography, Collapse, IconButton } from '@mui/material';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import { KeyboardArrowDown, KeyboardArrowUp, MenuOpen } from '@mui/icons-material';
import { navLinks } from 'nav/menu';
import Logo from 'logo/Logo';
import { createPortal } from 'react-dom';
import ProfileSettings from 'nav/ProfileSettings';
import TextWithBorders from 'components/Font/TextWithBorders';
import ProfileLogo from 'components/Logo/ProfileLogo';


export default function LeftSidebarMainExpanded() {

    const { dispatchSidebar, stateSidebar } = useAppInfo();
    const { sidebarLeftExpanded } = stateSidebar;
    const navigate = useNavigate();
    const location = useLocation();
    const pageName = location.pathname.split('/')[1];
    const subPage = location.pathname.split('/')[2];
    const [expandedMenus, setExpandedMenus] = useState({});
      
  
    const handleMenuToggle = (name) => {
      setExpandedMenus((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };

    const homeNav = ["About","Contact","Product","Pricing","Profile","Support","Terms","Disclaimer"]
    
    function handleNavigateHome(v){
      navigate(`/${v}`)
    }

    const handleSidebar = (v) => {
        navigate(`${v}`);
        // dispatchSidebar({"sidebarLeftExpanded":false})
      };

  return (
    <>
        <Paper
          elevation={1}
          onMouseLeave={() => !["Home",""].includes(pageName) && dispatchSidebar({"sidebarLeftExpanded":false})}
          style={{
            background: 'var(--bgWoodSmoke)',
            // position:["Home",""].includes(pageName) ?'relative':'absolute',
            position:"relative",
            width: 'calc(10% + 50px)',
            minWidth: '250px',
            height: '100vh',
            zIndex: 1000,
            overflow: 'auto',
            borderRadius: 0,
            top:0,
            display:"flex",flexDirection:"column"
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem',flex:1}}>
            { 
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center',position:"sticky",top:0,padding:"0.5rem 0",zIndex:10,background:"var(--bgWoodSmoke)"}}>
              <Logo type="long" width="150px" />
              <Button
                sx={{
                  marginLeft: 'auto',
                  minWidth: '0',
                  padding: 0,
                  flexDirection: 'column',
                  textTransform: 'none',
                }}
                onClick={() => dispatchSidebar({"sidebarLeftExpanded":false})}
              >
                <MenuOpenIcon />
              </Button>
            </div>}
            {navLinks.map((option, index) => (
              <div
                key={index}
                style={{
                  minWidth: '0',
                  padding: '0',
                  width: '100%',
                  boxSizing: 'border-box',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  borderRadius: '4px',
                  background:pageName===option.link && "#fafafa"
                }}
              >
               
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Button
                    onClick={() => {
                      if (!option.menu) {
                        handleSidebar(option.link);
                      } else {
                        handleMenuToggle(option.name);
                      }
                    }}
                     // '#5C5C5C'
                    // variant={pageName===option.link && "highlight"}
                    sx={{
                      display: 'flex',
                      color: pageName===option.link? 'var(--selectBlue)':"#fafafa",
                      padding: '0.4rem',
                      flex: 1,
                      justifyContent: 'flex-start',
                    }}
                  >
                    {option?.icon}
                    <Typography
                    style={{ marginLeft: '0.5rem',
                    color: pageName===option.link? 'var(--selectBlue)':"#fafafa",
                    textAlign: 'left',flex:1,fontSize:"0.9rem"}}>
                      {option.name}</Typography>
                    
                    {option?.menu && (expandedMenus[option.name] ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                  </Button>
                </div>
                {(option?.menu && expandedMenus[option.name]) && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
                      {option.menu.map((subOption) => (
                        <Button
                          key={subOption.name}
                          onClick={() => handleSidebar(`${option.link}/${subOption.link}`)}
                          sx={{ textAlign: 'left', justifyContent: 
                            'flex-start',
                            color: subPage===subOption.link?'var(--selectBlue)': pageName===option.link ? "var(--bgWoodSmoke)":"#fafafa",fontSize:"16px"}}
                        >
                         <Typography sx={{fontWeight:subPage===subOption.link && "bold"}}>
                         {subOption.name}
                          </Typography>
                        </Button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}

            {/* <div style={{display:"flex",flexDirection:"column",paddingTop:"2rem"}}>
            
            <TextWithBorders text={"TakeStock"} icon={<Logo/>}/>
            {homeNav.map(obj=>
              <Button sx={{justifyContent:"flex-start",padding:"0.25rem",paddingLeft:"1rem"}} onClick={()=>handleNavigateHome(obj)} key={obj}>
                {obj}
              </Button>
            )}
            </div> */}
           <div style={{marginTop:"auto",padding:"1rem 0rem",width:"100%"
            ,position:"sticky",bottom:"0",zIndex:1000,background:"var(--bgWoodSmoke)"}}>
                <ProfileSettings type='long'/>
           </div>
          </div>
        </Paper> 
    </>
  )
}

import React, { useState } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import { useAppInfo } from 'AppState';
import StepperVerticalTab from './StepperVeticalTab';



export default function GuideTemplate({name,steps,description,contentAfterHeading}) {
  const {dispatchResource,stateApp,dispatchApp} = useAppInfo()
  const [activeStep, setActiveStep] = useState(-1);

  function handleGetStarted(){
    if (stateApp.isLoggedIn){
    setActiveStep(0)
    dispatchResource({"guideDisplay":"content"})}
    else{
      dispatchApp({"loginModal":true})
    }
  }

  function handleContentStep(index){
    if (stateApp.isLoggedIn){
    setActiveStep(index)
    dispatchResource({"guideDisplay":"content"})}
    else{
      dispatchApp({"loginModal":true})
    }
  }
 
  return (
    <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%",padding:"0.25rem",boxSizing:"border-box"}}>
     
        {activeStep === -1 && 
        <div style={{display:"flex",width:"100%"
        ,flexWrap:"wrap",boxSizing:"border-box",padding:"1rem",flex:1}}>
        <div style={{width:"60%",borderRadius:"5px"
          ,padding:"1rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
          <div>
          <h2 style={{fontWeight:"bold"}}>{name}</h2><br/>
          <Typography dangerouslySetInnerHTML={{ __html: description }}/>
          </div>

          <div style={{position:"sticky",bottom:"10px",padding:"0.2rem"}}>
          <Button onClick={()=>handleGetStarted()} 
          variant='contained'>Start Guide</Button>
        </div>
        </div>
        <div style={{display:"flex",flexDirection:"column",flex:1
        ,minWidth:0,padding:"1rem",borderRadius:"5px",background:"#fafafa",minWidth:"400px"}}>
        <h3 style={{padding:"0.25rem"}}>Content</h3>
        {steps.map((step, index) => (
          <div key={index} style={{display:"flex",flexDirection:"column"
          ,justifyContent:"start",width:"100%"}}>
        
          <Button
            key={index}
            variant={index === activeStep ? "contained" : "text"}
            onClick={() => handleContentStep(index)}
            sx={{
              justifyContent:"flex-start",textTransform:"none",fontSize:"1rem",
              fontWeight:"bold",padding:"0.75rem"
              ,textAlign:"left",gap:"0.5rem"}}
          >
            <Typography style={{fontWeight:"bold"}}>{index + 1}.</Typography> <Typography style={{fontWeight:"bold"}}>{step.label}</Typography>
          </Button>
          </div>
        ))}

      </div>
     
      </div>}

      {activeStep >= 0 && <div style={{padding:"0",flex:1}}>
       <StepperVerticalTab
       name={name}
       contentAfterHeading={contentAfterHeading}
       handleActiveStep={(index)=>setActiveStep(index)} activeStep={activeStep} steps={steps} width={"100%"}/>
      </div>}

    

    </div>
  );
}

import React from 'react'
import { dfin } from 'content/constants'
import useCompanyNews from 'data/company/useCompanyNews'
import { useAppInfo } from 'AppState'
import LoadingSkeletonNews from 'components/Loading/LoadingSkeletonNews'
import styles from "./MarketNews.module.css"
import { Skeleton } from '@mui/material'

export default function CompanyNews() {
    const {stateStock,stateTicker} = useAppInfo()
  const tickerCountry = stateTicker.tickerInfo && stateTicker.tickerInfo.filter(obj=>obj[dfin.ticker]===stateStock.companySelected)[0]?.[dfin.country]
  const tickerNews = tickerCountry && tickerCountry==="USA"?stateStock.companySelected:"AAPL"
  const {newsCo,newCoLoading} = useCompanyNews(tickerNews)
  
  return (
    <>
    <div className={styles.news_container}>
    {newCoLoading && <LoadingSkeletonNews/>}
   
    {!newCoLoading && 
    newsCo.map((obj,index)=>
    <div key={index} 
    className={styles.news_content}
    onClick={()=>{window.open(obj.link, '_blank')}}
    >   
    <div > 
    {obj.thumbnail && obj.thumbnail.resolutions && obj.thumbnail.resolutions[0] && (
            <img src={obj.thumbnail.resolutions[0].url} 
            className='news_image'/>
          )}
    {(!obj.thumbnail || !obj.thumbnail.resolutions || !obj.thumbnail.resolutions[0]) && (
           <Skeleton variant="rect" className='news_image' />
          )}
  </div>
  <div>

  <a href={obj.link} style={{flex:1}} target='blank'>{obj.title}</a>

  <div style={{gap:"0.5rem",marginTop:"0.5rem"}}>
  <h5>
    {new Date(obj.providerPublishTime * 1000).toLocaleDateString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })}
  </h5>  
    <h5>Publisher: {obj.publisher}</h5>  
    </div>
    </div>
    </div>
    )}
    </div>
    </>
  )
}

import { useAppInfo } from 'AppState';
import { TotalSummaryDist } from 'myFinancials/Utils/Totals';
import { calculateTimeHorizon, getMonthStart, normalizeDate } from 'myFinancials/Utils/utils';
import {calculateMonthlyGoals, calculateMonthlyNetCashFlow, extrapolateCashflow } from 'myFinancials/Utils/utils'
import React, { useEffect, useState } from 'react'


export default function useCheckNewGoalSuccessFailure(goal=[]) {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {cashflowDistribution,goalsPeriodic} = stateMyFinancials  
    const {netGoalCashflowDist,netGoalAferWithdrawal} = cashflowDistribution

    
const processCashflow = (cashflowDist, goals) => {
    const processedMonths = new Set();
    
    return cashflowDist.map((cashflow) => {
        const monthStart = getMonthStart(new Date(cashflow.date));
        const normalizedMonth = normalizeDate(monthStart);

        if (!processedMonths.has(normalizedMonth)) {
            const correspondingGoal = goals.find((goalCashflow) =>
                normalizeDate(getMonthStart(new Date(goalCashflow.date))) === normalizedMonth
            );

            processedMonths.add(normalizedMonth);

            return {
                ...cashflow,
                amount: cashflow.amount - (correspondingGoal?.amount || 0),
            };
        }

        return cashflow;
    });
};

const processGoalDetails = (goals, netCashflowDist, netCashflowAssetWithdrawalDist) => {
    let remainingNetCashflowDist = [...netCashflowDist];
    let remainingNetCashflowAssetWithdrawalDist = [...netCashflowAssetWithdrawalDist];
    
    const perGoalDetails = goals.map((goal) => {
      const extrapolatedGoal = goal.forecastPeriod ? extrapolateCashflow({ fields: [goal] }) : [goal];
  
      const goalNet = calculateMonthlyGoals(remainingNetCashflowDist, extrapolatedGoal, "Net Cash After Meeting Goal");
      remainingNetCashflowDist = processCashflow(remainingNetCashflowDist, [goal]);
  
      const goalNetAfterWithdrawal = calculateMonthlyGoals(remainingNetCashflowAssetWithdrawalDist, extrapolatedGoal, goal.name);
      remainingNetCashflowAssetWithdrawalDist = processCashflow(remainingNetCashflowAssetWithdrawalDist, [goal]);
  
      const netGoalNegative = goalNet.filter((obj) => obj.amount < 0);
      const netGoalNegativeAfterWithdrawal = goalNetAfterWithdrawal.filter((obj) => obj.amount < 0);
  
      return {
        ...goal,
        cashflow: [...extrapolatedGoal, ...goalNet],
        successRateBeforeWithdrawal: netGoalNegative.length > 0 ? (1 - netGoalNegative.length / goalNet.length) * 100 : 100,
        successRateAfterWithdrawal: netGoalNegativeAfterWithdrawal.length > 0 ? (1 - netGoalNegativeAfterWithdrawal.length / goalNet.length) * 100 : 100,
      };
    });
  
    return {
      perGoalDetails
    };
  };
  


    return {processGoalDetails}

}





import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import useCompanyPressRelease from 'data/financialModelingPrep/News/useCompanyPressRelease'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CompanyPressRelease() {
  const {stateTicker} = useAppInfo()
  const {pressRelease} = useCompanyPressRelease(stateTicker.tickerStock)

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.25rem",width:"100%"}}>
        {pressRelease.length>0 && 
        pressRelease.map((obj,index)=>
        <React.Fragment key={index}>
            <div style={{display:"flex",flexDirection:"column",width:"100%"
            ,boxSizing:"border-box"}}>
                <Accordion sx={{boxShadow:"none",borderBottom:"1px solid #ddd"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="summary"
                >
                <div style={{display:"flex",flexDirection:"column"}}>
                <Typography >
                    {capitalizeFirstLetter(obj.title)}</Typography>
                
                <Typography fontSize={"small"} sx={{color:"gray"}}>
                    {new Date(obj.date).toLocaleDateString('en-US', {
                      weekday: 'short',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                  </div>
                  </AccordionSummary>
                  <AccordionDetails>
                <Typography>{capitalizeFirstLetter(obj.text)}</Typography>
                </AccordionDetails>
                </Accordion>
            </div>
        </React.Fragment>
        )}


    </div>
  )
}

function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
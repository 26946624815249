import { Button, Tooltip } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import BookmarkIcon from '@mui/icons-material/Bookmark';

function ColorPicker({ defaultColor = "black", onChange, title = "Color Picker", sx = {}, icon = "" }) {
    const [color, setColor] = useState('#ffffff'); // Initialize color state
    const inputRef = useRef(null);

    useEffect(() => {
        setColor(defaultColor)
    }, [defaultColor]);

    const handleColorPicker = () => {
        inputRef.current.click(); // Trigger the hidden input when the button is clicked
    };

    const handleColorChange = (v) => {
        setColor(v); // Update color state when the color picker changes
        onChange(v);
    };

    return (
        <Tooltip title={title}>
            <div style={{position:"relative"}}>
            {icon === "bookmark" && (
                    <BookmarkIcon
                        style={{
                            transform: "rotate(90deg)", 
                            color: color, // Set the bookmark icon color to the selected color
                            fontSize: '1.5rem' // Adjust the size for better appearance
                        }}
                    />
                )}

            {icon === "standard" && <Button
                onClick={handleColorPicker}
                style={{
                    width: '40px', // Adjusted width for better appearance
                    height: '40px', // Adjusted height for better appearance
                    borderRadius: '5px', // Keep the button's border-radius
                    border: '1px solid #ddd', // Border for better visibility
                    cursor: 'pointer', // Cursor as pointer to indicate clickability
                    backgroundColor: icon === "bookmark" ? '#ffffff' : color, // Background color logic based on the icon
                    ...sx,
                    position: 'relative', // Ensure the button is positioned relatively
                    overflow: 'hidden', // Prevents overflow of child elements
                }}
                aria-label="Select color" // Accessibility label for screen readers
            >
               
            </Button>}
             
            <input
                    ref={inputRef}
                    type="color"
                    value={color} // Bind input's value to state
                    onChange={(e) => handleColorChange(e.target.value)} // Handle changes
                    style={{
                        display: 'block', // Use block to fill the button area
                        opacity: 0, // Make the input invisible
                        width: '100%', // Ensure the input covers the entire button
                        height: '100%', // Ensure the input covers the entire button
                        position: 'absolute', // Position input absolutely
                        top: 0, // Align top edge with the parent container
                        left: 0, // Align left edge with the parent container
                        border: 'none' // No border
                    }}
                />
                </div>
        </Tooltip>
    );
}

export default ColorPicker;

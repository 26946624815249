import React from 'react'
import TableGen from 'components/Table/TableGen'
import useEconInfo from 'data/economy/useEconInfo';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import { useAppInfo } from 'AppState';

export default function EconOverviewLocal() {
  const {dispatchEcon} = useAppInfo()
  const { econInfo } = useEconInfo();
  const econInfoCols = ["Name", "Current Value", "units", "popularity", "Category", "SubCategory", "Date", "frequency"];
  const selEconInfo = getSpecificArrayObjKeys(econInfo, econInfoCols).sort((a, b) => b.popularity - a.popularity);
  
  function handleTableRow(row){
    const econId = econInfo.filter(obj=>obj.Name===row.Name)[0]?.id
    
    dispatchEcon({"menuSel":"search","contentSel":"search","econId":econId})
  }

  return (
    <>
    {(selEconInfo.length > 0) && 
    <TableGen handleTableRow={handleTableRow} data={selEconInfo} 
    tableHeader={"Economy Overview - USA"} tableLayout='fixed'/>}
    </>
    
  )
}

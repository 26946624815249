import React, { useState, useEffect, useRef } from 'react';
import styles from "./TabsClose.module.css";
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAppInfo } from 'AppState';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function TabsClose({ tabList, tabContent, deleteTab = false, 
  handleDeleteTab, handleSubTabChange, subTabIndex }) {
  const [tabIndex, setTabIndex] = useState(0);
  const { dispatchSidebar, stateSidebar } = useAppInfo();
  const [tabListOrder, setTabListOrder] = useState([]);
  const tabRefs = useRef([]);

  useEffect(() => {
    setTabListOrder(tabList);
  }, [tabList]);

  useEffect(() => {
    if (subTabIndex !== undefined) {
      setTabIndex(subTabIndex);
    }
  }, [subTabIndex]);

  function handleInfoTab(e, index) {
    e.stopPropagation();
    dispatchSidebar({"sidebarOpen":true,"showSidebarRight":true,"sidebarRightContent":"information","infoContent":tabList[index]});
  }

  function handleTabChange(e, index) {
    e.stopPropagation();
    setTabIndex(index);
    if (typeof handleSubTabChange === 'function') {
      handleSubTabChange(index);
    }
    scrollTabIntoView(index);
  }

  useEffect(() => {
    if (tabList.length > 0 && tabIndex > tabList.length - 1) {
      setTabIndex(tabList.length - 1);
    }
  }, [tabList, tabIndex]);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("tabIndex", index);
  };

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, index) {
    e.preventDefault();
    const draggedTabIndex = parseInt(e.dataTransfer.getData('tabIndex'), 10);
    if (draggedTabIndex !== index) {
      const newTabList = [...tabListOrder];
      const draggedTab = newTabList.splice(draggedTabIndex, 1)[0];
      newTabList.splice(index, 0, draggedTab);
      setTabListOrder(newTabList);
      // Assuming you have a method to update the tab list state or prop
      // updateTabList(newTabList);
    }
  }

  const tabContainerRef = useRef(null);
  const scrollTabs = (direction) => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollLeft += direction === 'left' ? -100 : 100;
    }
  };

  const scrollTabIntoView = (index) => {
    if (tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  const [showScrollButtons, setShowScrollButtons] = useState(false);

  useEffect(() => {
    function checkOverflow() {
      const current = tabContainerRef.current;
      if (current) {
        const isOverflowing = current.scrollWidth > current.clientWidth;
        setShowScrollButtons(isOverflowing);
      }
    }

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [tabListOrder]);

  return (
    <div className={styles.tabLayout}>
      <div style={{ display: "flex", gap: '1rem' }}>
        <div ref={tabContainerRef} className={styles["subTabs"]}>
          {tabListOrder.map((tabs, index) => (
            <button
              className={tabIndex === index ? styles.activeButton : styles.inactiveButton}
              style={{ padding: !deleteTab && "0.5rem" }}
              key={`tab-${tabs}-${index}`}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={handleDragOver}
              onClick={(e) => handleTabChange(e, index)}
              value={index}
              ref={(el) => tabRefs.current[index] = el}
            >
              <Typography variant="body" 
              style={{fontSize: !deleteTab && "1rem" }}>
                {tabs}
              </Typography>
              {stateSidebar.showInfoTag &&
                <div style={{ marginLeft: "auto" }}>
                  <Tooltip title="learn about the metric">
                  <InfoOutlinedIcon fontSize='small' onClick={(e) => handleInfoTab(e, index)} />
                  </Tooltip>
                </div>}
              {deleteTab && (
                <CloseIcon fontSize='small' 
                onClick={() => handleDeleteTab(index)}
                sx={{marginLeft: "auto",padding:0}}/>
              )}
            </button>
          ))}
        </div>
        {showScrollButtons && (
          <>
            <Button sx={{ padding: 0, minWidth: 0 }} onClick={() => scrollTabs("left")}><KeyboardArrowLeftIcon fontSize="small" /></Button>
            <Button sx={{ padding: 0, minWidth: 0 }} onClick={() => scrollTabs("right")}><KeyboardArrowRightIcon fontSize="small" /></Button>
          </>
        )}
      </div>
      <div className={styles["subTab_content"]} style={{ height: subTabIndex ? "0%" :"100%"}}>
        {tabContent[tabIndex]}
      </div>
    </div>
  );
}





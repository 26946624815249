
import { useAppInfo } from 'AppState';
import PriceValueChart from 'components/Charts/PriceValueChart';
import styles from "./PeerValue.module.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import PeerDcfExplaination from './PeerDcfExplaination';

export default function PeerDcf() {
    const {stateTicker,stateStock} = useAppInfo()
    const peerSelected = stateTicker.peerSelected
    const companySelected = stateStock.companySelected
    const [page, setPage] = useState(0);
  
    function handleNext() {
      const maxPageValue = Math.ceil(peerSelected.length / 4);
      setPage((page + 1) % maxPageValue);
    }
    
    function handlePrevious() {
      const maxPageValue = Math.ceil(peerSelected.length / 4);
      setPage(page === 0 ? maxPageValue - 1 : page - 1);
    }
    const startIndex = page * 4;
    const endIndex = Math.min(startIndex + 4, peerSelected.length);
    return (
      <>
      <PeerDcfExplaination/>
      <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <IconButton onClick={handlePrevious}><ArrowBackIosIcon/></IconButton>
        <IconButton onClick={handleNext}><ArrowForwardIosIcon/></IconButton>
        <div style={{marginLeft:"1rem"}}>Displaying {startIndex + 1}-{endIndex} of {peerSelected.length}</div>
        </div>
      
        <div id="scrollableDiv" style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
          {peerSelected.slice(startIndex, endIndex).map((company) => (
          <div key={company} 
          className={styles.container} 
          style={company===companySelected?{borderLeftColor:"3px solid var(--bgColor)"}:{}}>
          <PriceValueChart companySelected={company} />
          </div>
          ))}  
        </div>
     
      </>
    )
  }
  
import React from 'react'
import { useAppInfo } from 'AppState';
import CashflowChart from './CashflowChart';
import ExpenseFields from './ExpenseFields';

export default function ExpenseProjection() {
    const {stateMyFinancials,dispatchMyFinancials} = useAppInfo()
    const {expenseMonthly,cashflowDistribution} = stateMyFinancials
    const {expenseDist} = cashflowDistribution

  return (
    <div style={{overflow:"auto",display:"flex",flexDirection:"column",width:"100%"}}>
 <div style={{width:"100%",height:"400px",minHeight:"400px"}}>
    <CashflowChart data={expenseDist} title="Projected Expense"/>
    </div>
    
<div style={{flex:1,width:"100%"}}>
  <ExpenseFields allocation={false}/>
  </div>
    </div>
   
  )
}

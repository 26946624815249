import React from 'react'

export default function BorderDoubleBox() {
  return (
    <>
    <div style={{position:"absolute",top:0,left:0,height:"100%",width:"100%"
      ,borderRadius:"25px",border:"3px solid var(--lightBlue)",transform:"rotate(0deg)"}}></div>
      <div style={{position:"absolute",top:3,left:0,height:"100%",width:"100%"
      ,borderRadius:"25px",border:"3px solid green",transform:"rotate(0deg)"}}></div>
      </>
  )
}

import React, { useEffect,useState } from 'react'
import TabsClose from 'components/Tabs/TabsClose';
import MetricStockModal from 'components/Template/MetricStockModal';
import Histogram from 'components/Charts/Histogram';
import BarChartIndSec from 'components/Charts/BarChartIndSec';
import { useAppInfo } from 'AppState';
import { getSpecificArrayObjKeys } from "utils/utilGeneral";
import { dfin } from 'content/constants';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Tooltip } from '@mui/material';
import TableGen from 'components/Table/TableGen';
import { MetricSettingsBar } from 'components/Template/MetricSettings';


export default function IndSecMetricSummary({infoName,
  storeId,defaultMet,chartTypeDefault="Column",chartTypeOptions=["Column","Bar"],data,}) {
    const {stateIndustry} = useAppInfo()
    
    let barTable = []
    let summaryFor = dfin.gicSector
    if(!stateIndustry.indSecMode){
        barTable = stateIndustry.secOverview;}
      
      else{
        barTable = stateIndustry.indOverview
        summaryFor = dfin.gicIndustry
      }
    
    
    const [metricSelected, setMetricSelected] = useState([]);
    const [addMetrics, setAddMetrics] = useState(false);
    const [resetMetric, setResetMetric] = useState(false);
   
    const [selectedChartType, setChartType] = useState(chartTypeDefault);
    const chartType = chartTypeOptions
    const [dataLabelsChecked, setDataLabelsChecked] = useState(true);


    function handleAddTabs() {
        setAddMetrics(!addMetrics);
      };

    function handleResetMetric(){
    setMetricSelected(defaultMet)
    sessionStorage.setItem(
      `${storeId}`,
      JSON.stringify(defaultMet)
    );
    }

    function handleDeleteTab(index) {
    setMetricSelected((prevItems) => {
        // Filter out the item at the specified index
        const updatedItems = prevItems.filter((_, i) => i !== index);
        return updatedItems;
    });
    // Update the tabList and tabContent state with the updated values
    }

    
  const handleDataLabelsChange = (event) => {
    setDataLabelsChecked(event.target.checked);
    // Perform any additional logic or actions based on the checked state
  };



  const handleChartType = (e,v) => {
    setChartType(v);
  };

  function handleMetricSelected(option) {

    setMetricSelected((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });

    if (metricSelected.length > 0) {
      sessionStorage.setItem(
        `${storeId}`,
        JSON.stringify(metricSelected)
      );
    }
  }
  const [statSumma, setStatSumma] = useState(["mean"]);
  const generateMetricArray = (sourceArray,summaryType) =>
  sourceArray.map((col) => col + " " + summaryType);
 
  const subTabContent = metricSelected.map((metric) => (
    <>
    <div style={{width:"100%"}}>
      <TableGen data={getSpecificArrayObjKeys(barTable,
        [dfin.gicIndustry,
          dfin.gicSector,
          dfin.mScale,
        generateMetricArray([metric],"sum")[0],
        generateMetricArray([metric],"mean")[0],
        generateMetricArray([metric],"min")[0],
        generateMetricArray([metric],"max")],
        
        )}/>
        </div>
      <div style={{display:"flex",flexWrap:"wrap"}}>
      <Histogram  title={`Number of Companies - ${metric}`} orientation={selectedChartType} data={getSpecificArrayObjKeys(data,[metric])}/>

      <div>
      <BarChartIndSec orientation={selectedChartType}  categoryMetric={summaryFor} 
      data={barTable} metricSelected={generateMetricArray([metric],"mean")[0]} dataLabel={true} chartLegend={true}/>
     
      <BarChartIndSec orientation={selectedChartType}  categoryMetric={summaryFor} data={barTable} metricSelected={generateMetricArray([metric],"sum")[0]} dataLabel={true} chartLegend={true}/>
      </div>
      <div>
      <BarChartIndSec orientation={selectedChartType}  categoryMetric={summaryFor} data={barTable} metricSelected={generateMetricArray([metric],"max")[0]} dataLabel={true} chartLegend={true}/>
      <BarChartIndSec orientation={selectedChartType}  categoryMetric={summaryFor} data={barTable} metricSelected={generateMetricArray([metric],"min")[0]} dataLabel={true} chartLegend={true}/>
      </div>
      
     </div>
     </>
       
     ));
  
  useEffect(() => {
    setMetricSelected(defaultMet)

    }
    ,[defaultMet])

  return (
    <div className='containerColumnFlexNoGap'>
      <MetricSettingsBar 
      title={infoName}
      addMetric
      handleAddMetric={()=>setAddMetrics(true)}
      ></MetricSettingsBar>
        {addMetrics &&
          <MetricStockModal 
          handleModalClose={handleAddTabs} handleMetricSelected={handleMetricSelected} 
          checkbox={true}  metricSelected={metricSelected}/> 
            }
    
    <div className='conainterColumnFlex1'>
    <TabsClose
            tabList={metricSelected}
            tabContent={subTabContent}
            tabStyle="subTabs"
            tabOrientation="row"
            addTab={true}
            handleAddTabs={handleAddTabs}
            deleteTab={true}
            handleDeleteTab={handleDeleteTab}
          >
          </TabsClose>
          </div>
    </div>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import numeral from "numeral";
import 'numeral/locales';
import exportingModule from 'highcharts/modules/exporting';
import exportDataModule from 'highcharts/modules/export-data';
import fullscreenModule from 'highcharts/modules/full-screen';
import { dfin } from 'content/constants';
import { useAppInfo } from 'AppState';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';
import useFinancialMetricExchangeRate from './useFinancialMetricExchangeRate';

require("highcharts/modules/accessibility")(Highcharts);

exportingModule(Highcharts);
exportDataModule(Highcharts);
fullscreenModule(Highcharts);

export default function PeerFundamentalChart({
  data,metrics,xCategory,chartType="column",children,growthMode=false}) {

    const {stateTicker} = useAppInfo()
    const handleTickerChange = useHandleTickerChange()
    const {tickerStock} = stateTicker
    const exRate = useFinancialMetricExchangeRate({metric:metrics})

      const options = useMemo(() => {
        let dates = Array.from(new Set(data.map(obj => obj[dfin.period]))).sort();
        const tickers = Array.from(new Set(data.map(obj => obj[dfin.ticker]))).reverse();
        const selectedTickerIndex = tickers.indexOf(tickerStock);

        let seriesData;
        // #plots xAxis as Dates
        if (xCategory === "Dates" || ["area", "line"].includes(chartType)) {
          seriesData = SeriesDataWithXaxisDates({data,dates,tickers,growthMode,exRate,metrics})
        }
        
        // #plots xAxis as Tickers
        else{
            seriesData = SeriesDataWithXaxisTicker({data,dates,tickers,growthMode,exRate,metrics})
        }

     
        return {
          chart: {
            type: chartType,
            zoomType: 'xy',
            animation: false,
            accessibility: { enabled: false },
          },
          title: { text: metrics },
          series: seriesData,
          xAxis: {
            categories: (xCategory==="Dates" || ["area","line"].includes(chartType))?dates:tickers,
            plotBands: (!["area","line"].includes(chartType)) && [
              {
                color: 'rgba(0,0,0,.25)',
                from: selectedTickerIndex - 0.5,
                to: selectedTickerIndex + 0.5,
                zIndex: 1,
              },
            ],
            labels: {
              step: 1,
            },
          },
          yAxis: {
            title: {
              text: 'Value',
            },
            labels: {
              formatter: function () {
                return numeral(this.value).format('0.00a').toUpperCase();
              },
            },
          },
          tooltip: {
            formatter: function () {
              return this.series.name + ': ' + numeral(this.y).format('0,0.00').toUpperCase();
            },
          },
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            borderWidth: 0,
            x: -10,
            y: 10,
            itemStyle: {
              whiteSpace: 'normal',
            },
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  click: function () {
                    let ticker = this.category;
                    if (xCategory==="Dates" || ["area","line"].includes(chartType)){
                    ticker = this.series.name;}
                    handleTickerChange({ticker});
                  },
                },
              },
             
            },
          },
          exporting: {
            enabled: false,
          },
          navigation: {
            buttonOptions: {
              enabled: true,
            },
          },
          credits: {
            enabled: false,
          },
          accessibility: {
            enabled: false,
          },
        };
      }, [data, dfin, metrics, exRate, tickerStock,chartType]);
    
    return(<>
    <div style={{ height: "100%", width:"100%",position:"relative"}} >
            <HighchartsReact highcharts={Highcharts} 
             containerProps={{ style: { height: '100%' , width:"100%"} }}
            options={options}/>
            {children}
            </div>
            </>
    )   
}

function SeriesDataWithXaxisTicker({data,dates,tickers,growthMode,exRate,metrics}){
  const seriesData = dates.map((date,index) => {
    const tickerData = tickers.map((ticker) => {
      const currentObj = data.find(item => item[dfin.ticker] === ticker && item[dfin.period] === date);
      let currentValue = currentObj ? currentObj[metrics] : null;
      
      if (growthMode && index === 0){
        return {y:0}
      }
      if (growthMode && index > 0) {
        const previousDate = dates[index - 1];
        const previousObj = data.find(item => item[dfin.ticker] === ticker && item[dfin.period] === previousDate);
        const previousValue = previousObj ? previousObj[metrics] : null;
    
        if (currentValue !== null && previousValue !== null) {
          const growthValue = ((currentValue - previousValue) / previousValue) * 100;
          return { y: growthValue };
        }
        else{
          return { y: 0 };
        }
      }
      if (!growthMode){
      return { 
        y: currentValue !== null ? currentValue * (currentObj ? currentObj[exRate] : 1) : null };
      }
    })
    return {
      name: date,
      data: tickerData,
    };
  });

  return seriesData
}

function SeriesDataWithXaxisDates({data,dates,tickers,growthMode,exRate,metrics}){
  const seriesData = tickers.map(ticker => {
    const tickerData = dates.map((date, index) => {
      const currentObj = data.find(item => item[dfin.ticker] === ticker && item[dfin.period] === date);
      let currentValue = currentObj ? currentObj[metrics] : null;
      
      if (growthMode && index === 0){
        return {y:0}
      }
      if (growthMode && index > 0) {
        const previousDate = dates[index - 1];
        const previousObj = data.find(item => item[dfin.ticker] === ticker && item[dfin.period] === previousDate);
        const previousValue = previousObj ? previousObj[metrics] : null;
    
        if (currentValue !== null && previousValue !== null) {
          const growthValue = ((currentValue - previousValue) / previousValue) * 100;
          return { y: growthValue };
        }
        else{
          return { y: 0 };
        }
      }
      if (!growthMode){
      return { 
        y: currentValue !== null ? currentValue * (currentObj ? currentObj[exRate] : 1) : null };
      }
    })
    return {
      name: ticker,
      data: tickerData,
    };
  });

  return seriesData

}


function ChartDataCore({data,date,ticker,growthMode,exRate,metrics}){
  
  }
    
    // #GROUPING MULTIPLE TICKERS BY DATE


    // const chartOptions = {
    //     chart: {
    //       type: 'column',
    //     },
    //     title: {
    //       text: 'Ticker Data',
    //     },
    //     xAxis: {
    //       categories: [], // Populate with your dates
    //     },
    //     yAxis: {
    //       title: {
    //         text: 'Metric',
    //       },
    //     },
    //     series: [], // Populate with your ticker data
    //   };
     
    // const tickerGroups = {};
    // data.forEach(item => {
    // if (!tickerGroups[item.TICKER]) {
    //     tickerGroups[item.TICKER] = [];
    // }
    // tickerGroups[item.TICKER].push({ date: item.DATE, metric: item["TOTAL REVENUE"] });
    // });

    // // Populate chartOptions with the ticker data
    // Object.entries(tickerGroups).forEach(([ticker, tickerData]) => {
    // const seriesData = tickerData.map(item => item.metric);
    // chartOptions.series.push({ name: ticker, data: seriesData });
    // chartOptions.xAxis.categories = tickerData.map(item => item.date);
    // });
   
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RcContent from "./RcContent";
import RcLibrary from "./RcLibrary";
import { useAppInfo } from "AppState";

export default function Learn() {
  const { stateResource, dispatchResource } = useAppInfo();
  let searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const search = location.search;
  let idTopic = searchParams.get("topic");
  const menuList = stateResource.menuList;

  useEffect(() => {
    if (idTopic) {
      const tabList = Array.from(
        new Set([...menuList, `${decodeURIComponent(idTopic)}`])
      );

      dispatchResource({"menuList":tabList });
    }
  }, [idTopic]);

  useEffect(() => {
    if (pathName.includes("Learn")) {
      const fullPath = search ? `${pathName}${search}` : `${pathName}`;
      dispatchResource({"searchLearnHistory":[fullPath, ...stateResource.searchLearnHistory],
      });
    }
  }, [search, pathName]);



  return (
    <>
    {["Library", null].includes(idTopic) && <RcLibrary />}

    {!["Library", null].includes(idTopic) && <RcContent />}
    </>
  );
}

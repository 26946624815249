import { useAppInfo } from "AppState";
import { dfin } from "content/constants";
import useCompanyFinancials from "data/company/useCompanyFinancials";
import useMetricInfo from "data/company/useMetricInfo";
import useScreenerCompany from "data/company/useScreenerCompany";
import { useEffect } from "react";

export default function useSetStockInfo() {
    const {stateTicker,stateStock,dispatchStock} = useAppInfo()
    useMetricInfo();
    useCompanyFinancials();
    useScreenerCompany();
  
    useEffect(() => {
        if (stateStock.companySelected === null) {
          dispatchStock({"companySelected":"AAPL" });
        }
        const coInfo = stateStock.peerInfo.find(
          (obj) => obj[dfin.ticker] === stateStock.companySelected
        );
        dispatchStock({"coInfo":coInfo });
        const tickerDict = stateStock.peerInfo.reduce((result, item) => {
          result[item[dfin.ticker]] = item[dfin.coName];
          return result;
        }, {});
        dispatchStock({"tickerDict":tickerDict})
      
      }, [stateStock.companySelected]);

      useEffect(() => {
        const peerInfo = stateStock.screenInfo.filter((obj) =>
          stateTicker.peerSelected.includes(obj[dfin.ticker])
        );
        dispatchStock({"peerInfo":peerInfo });
        const coInfo = stateStock.screenInfo.find(
          (obj) => obj[dfin.ticker] === stateStock.companySelected
        );
        dispatchStock({"coInfo":coInfo});
      }, [stateTicker.peerSelected]);


    
}

import { fetchMarketNews } from 'data/financialModelingPrep/News/useMarketNews'
import React from 'react'

export default function useAssistantNewsEvents() {
    
    async function GetMarketNews() {
        const newsInfo = await fetchMarketNews("All")
        return newsInfo
      }

   return {GetMarketNews}
}

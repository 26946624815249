import { useAppInfo } from 'AppState';
import SearchButton from 'components/Inputs/SearchButton';
import React from 'react'

export default function SearchNav() {
    const {stateTicker,dispatchSearch} = useAppInfo()

    function handleSearch() {
        dispatchSearch({'searchClick':!stateTicker.searchClick });
      }

  return (
    <div style={{width:"250px"}} id="searchButton">
    <SearchButton placeholder="Ticker or Info (ctrl + k)" onClick={handleSearch}/>
    </div>
  )
}

import React from 'react'
import Checklist from './Checklist';

export default function InvestmentObjectiveChecklist() {
    
    function handleChange(){

    }
  return (
    <>
    <h1>Checklist: Investment Objectives & Goals Assessment</h1>
    <Checklist data={questions} onChange={handleChange} />
    </>
  )
}


const questions = [
    {
      question: "What is your primary investment goal?",
      options: [
        { label: "Capital preservation (Protecting initial investment)" },
        { label: "Income generation (Regular income from investments)" },
        { label: "Wealth accumulation (Long-term growth of capital)" },
        { label: "Retirement planning (Building a retirement fund)" },
        { label: "Education funding (Saving for education expenses)" },
        { label: "Other" }
      ]
    },
    {
      question: "What is your investment time horizon for this goal?",
      options: [
        { label: "Short-term (1-3 years)" },
        { label: "Medium-term (3-5 years)" },
        { label: "Long-term (5+ years)" }
      ]
    },
    {
      question: "How would you rate your risk tolerance for this particular investment goal?",
      options: [
        { label: "Conservative (Prefer minimal risk)" },
        { label: "Moderate (Willing to accept some risk for potential gains)" },
        { label: "Aggressive (Seeking higher returns despite higher risk)" }
      ]
    },
    {
      question: "What is the importance of liquidity for this investment goal?",
      options: [
        { label: "High liquidity needed (Ability to access funds quickly)" },
        { label: "Moderate liquidity needed (Some flexibility in accessing funds)" },
        { label: "Low liquidity needed (Prepared to lock in funds for the long term)" }
      ]
    },
    {
      question: "Do you have any specific preferences or restrictions for this investment goal?",
      options: [
        { label: "Ethical or socially responsible investments" },
        { label: "Avoidance of certain industries or sectors" },
        { label: "Preference for specific asset classes (e.g., stocks, bonds)" },
        { label: "Other (Please specify)" },
        { label: "No specific preferences or restrictions" }
      ]
    }
  ];

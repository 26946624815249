import React, { useState, useEffect } from 'react';

export default function NoMatchingResults({ handleChangeFilter, handleResetFilter }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timer);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div>
        <h1>No Matching Results</h1>
        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
          <button className='btn' onClick={handleChangeFilter}>Change Filter</button>
          <h2>or</h2>
          <button className='btn' onClick={handleResetFilter}>Reset All Filter</button>
        </div>
      </div>
    </div>
  );
}

import { Button, Tooltip } from '@mui/material'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';

export default function ExcelDownloadButton({wbName,data}) {


  const handleXLDownload = (wbName,data) => {
        // Create a worksheet from the array of objects
        const ws = XLSX.utils.json_to_sheet(data);
      
        // Create a new XLSX workbook
        const wb = XLSX.utils.book_new();
      
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
        // Save the workbook as an XLSX file
        XLSX.writeFile(wb, `${wbName}.xlsx`);
      };

  return (
    <Tooltip title="Download to Excel">
    <Button onClick={()=>handleXLDownload(wbName,data)}>
      <DownloadIcon />
    </Button>
  </Tooltip>
  )
}

import { Alert, Button } from '@mui/material'
import { assetTypes, defaultFieldBlank, goalTypes, liabilityTypes } from 'appState/initialStateMyFinancials'
import React, { useEffect, useMemo, useState } from 'react'
import { InputAmount, InputCompounding, InputDate, InputForecastPeriod, InputGrowthRate, InputInterestRate, InputLoanTerm, InputName, InputPaybackPeriod, InputPeriod, InputPriority, InputReturnExpected, InputTargetAllocation, InputType, InputVolatility } from 'myFinancials/FormFields/FieldFormCells';
import useFieldForm from 'myFinancials/FormFields/useFieldForm';

export default function LiabilityIndivdualEdit({liabilityData,onClose}) {

  const data = useMemo(() => [liabilityData], [liabilityData]); // Memoize liabilityData in an array
  const {
    fields,
    error,
    handleChange,
    handleDeleteField,
    handleAddField,
    handleRemoveField,
    handleSubmit,
  } = useFieldForm({storeName:"liabilities",data:data,defaultField:liabilityData})
  

  
  return (
  <>
  
{ <div style={{display:"flex",gap:"1rem",width:"100%",flexWrap:"wrap",height:"100%",boxSizing:"border-box"}}>
  <div style={{background:"#fafafa",width:"100%",minWidth:"300px",borderRadius:"5px"}}>
  <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
  {fields.map((fieldObj,index)=>
  <form onSubmit={(e)=>{handleSubmit(e);onClose()}} key={index} 
  style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem",width:"100%",boxSizing:"border-box"}}>
  <InputType disabled={!fieldObj?.edit} typeOptions={liabilityTypes} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputName disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputAmount disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputDate disabled={!fieldObj?.edit} label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputInterestRate label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputCompounding label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputPaybackPeriod label fieldObj={fieldObj} handleChange={handleChange}/>
  <InputLoanTerm label fieldObj={fieldObj} handleChange={handleChange}/>
  <Button variant='contained' type='submit'>Save</Button>
    </form>)}
  
  </div>
  
  </div>
 
</div>}
</>
  )
}
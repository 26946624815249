import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import SidebarContentTemplate from './SidebarContentTemplate'

export default function Table() {

  const content = [{Category:"Spreadsheet", Type: 'Excel', ImgUrl: 'https://static.thenounproject.com/png/1194546-200.png' },]  

  return (
    <>
    <SidebarContentTemplate content={content} showType={true}/>

    </>
  )
}
import React from 'react'
import { useAppInfo } from 'AppState'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import { dfin } from 'content/constants'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecGrowth() {
  const {stateIndustry} = useAppInfo()
  const defaultMet = [`${dfin.revenue} 5Y-GROWTH`,`${dfin.ebitda} 5Y-GROWTH`,`${dfin.netIncome} 5Y-GROWTH`,`${dfin.assets} 5Y-GROWTH`]

  return (
        <IndSecMetricSummary
        infoName={"Growth Summary"}
        defaultMet={defaultMet}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
       
  )
}


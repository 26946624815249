import { dfin } from "content/constants";
import { getSpecificArrayObjKeys, numberDecimal } from "utils/utilGeneral";


export default function getPerformanceDetails({portfolioSel,tickerCode,tickerCodeDict,prices}){
    let holdingDetails = [];
    let portfolioOverview = []
    let portfolioTransactionTable = []
    const currentDate = new Date(); // Get the current date

    if (portfolioSel.length>0 && tickerCode.length>0 && prices && tickerCodeDict){
          
          let transactionDetails = []
          let transactions = portfolioSel;
          let netPositions = {};
          let totalRealizedGainLoss = 0;
      
          transactions.forEach(({ TICKER, transactionType, quantity, price, ...extraDetails }) => {
            transactionDetails.push({ TICKER, transactionType, quantity, price, ...extraDetails })
            netPositions[TICKER] = netPositions[TICKER] || { quantity: 0, totalInvestment: 0, averagePrice: 0, extraDetails };
          
            if (transactionType === 'Buy') {
              netPositions[TICKER].quantity += parseFloat(quantity);
              netPositions[TICKER].totalInvestment += parseFloat(quantity) * parseFloat(price);
              netPositions[TICKER].averagePrice = netPositions[TICKER].totalInvestment / netPositions[TICKER].quantity;
            } else if (transactionType === 'Sell') {
              const sellValue =parseFloat(quantity) * parseFloat(price);
              const buyValue = parseFloat(quantity) * netPositions[TICKER].averagePrice;
              
              // Calculate realized gain/loss for each Sell transaction
              const realizedGainLoss = sellValue - buyValue;
              totalRealizedGainLoss += realizedGainLoss;
          
              netPositions[TICKER].quantity -= parseFloat(quantity);
              netPositions[TICKER].totalInvestment -= buyValue;
            }
      
          });
          
          
          Object.keys(netPositions).forEach(TICKER => {
           
            const netPosition = netPositions[TICKER];
            if (netPosition.quantity>0){
            const currentPrice = prices[tickerCodeDict?.[TICKER]]?.open
            // const currentPrice = statePortfolio.close[statePortfolio.close.length - 1][TICKER] || null;
            const nav = currentPrice * netPosition.quantity;
            const profitLoss = numberDecimal(nav - netPosition.totalInvestment);
            const profitLosspercent = numberDecimal((profitLoss / netPosition.totalInvestment) * 100);
            const transactionDate = new Date(netPosition.extraDetails.date);
            const timeDifference = currentDate - transactionDate;
            const holdingPeriodInDays = Math.round(timeDifference / (1000 * 60 * 60 * 24)).toFixed(0);
            
            holdingDetails.push({
              TICKER,
              ...netPosition.extraDetails,
              quantity: netPosition.quantity,
              "Investment Value": numberDecimal(netPosition.totalInvestment),
              "Average Price": numberDecimal( netPosition.totalInvestment / netPosition.quantity),
              "Current Price": numberDecimal(currentPrice),
              "Net Asset Value": numberDecimal(nav),
              "Profit/Loss":profitLoss,
              "Profit/Loss (%)":profitLosspercent,
              "Holding Period (days)": `${holdingPeriodInDays} days `,
               // Access extraDetails directly from netPosition
            });
          }});
          
         
        const totalInvestmentValue = holdingDetails.reduce((total, obj) => total + (obj["Investment Value"] || 0),0).toFixed(2);
        
        holdingDetails.forEach((obj) => {
          obj.Weight = obj["Investment Value"] / totalInvestmentValue;
        });
      
        const totalNAV = holdingDetails.reduce((total, obj) => total + (obj["Net Asset Value"] || 0), 0).toFixed(2);
        const totalProfitLoss = holdingDetails.reduce((total, obj) => total + (obj["Profit/Loss"] || 0),0).toFixed(2);
        const totalProfitLosspercent = ((totalProfitLoss/totalInvestmentValue)*100).toFixed(2)
        portfolioOverview = {"Investment Cost":totalInvestmentValue,
        "NAV":totalNAV,"Profit/Loss":totalProfitLoss,"PLpercent":totalProfitLosspercent,"Realized P/L":totalRealizedGainLoss}
       
        portfolioTransactionTable = getSpecificArrayObjKeys(transactionDetails,transactionTableColumns)
      }
      
    return {holdingDetails,portfolioOverview,portfolioTransactionTable}
}
const transactionTableColumns = [
  dfin.coLogo,
  dfin.ticker,
  dfin.coName,
  "investmentType",
  "transactionType",
  "date",
  "price",
  "quantity",
  "EXCHANGE"
]
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import React, { useEffect, useState } from 'react'

// There are 2 different exchange rates for different statemets - as on date and for the period

export default function useFinancialMetricExchangeRate({metric}) {
  
  const {stateStock} = useAppInfo()
  const {metrics:metricStocks} = stateStock
  const metricFinancials  = metricStocks?.metricFinancials

  const [exRate,setExRate] = useState("ExRate_forperiod")
  
  useEffect(()=>{
  const statementType = metricFinancials.filter(
    (obj) => obj.Metric === metric)[0]?.[dfin.statement];
  let exRate = "ExRate_forperiod";
  if (statementType ==="BS"){
      exRate = "ExRate_asonDate"}
  setExRate(exRate)
    },[metric])

    return exRate
}


import React, {
  useEffect,
  useState,
} from 'react';

import ScatterUI from 'components/Charts/ScatterUI';
import TreeMap from 'components/Charts/TreeMap';
import PaginationButtons from 'components/PaginationButtons';
import TableGen from 'components/Table/TableGen';

import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import PeerOverviewMetricComp
  from 'analytics/ticker/stocks/Peer/PeerOverviewMetricComp';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import TickerCard from './TickerCard';

export default function ScreenerStocksDataView({dataView,data}) {
    const [totalPages,setTotalPages] = useState(0)
    const [currentPage,setCurrentPage] = useState(1) 
  const {stateScreener,stateStock} = useAppInfo()
  const {scatterMetricsSel} = stateScreener
  const metricOptions = stateStock.metrics.numCols
  const [pageData,setPageData] = useState([])
  const [tickerSel,setTickerSel] = useState([])
  const {close,trendTable,marketInfoLoading} = useMultiTickerInfo(tickerSel)

  useEffect(() => {
    if (data.length>0){
    let startIndex =  (currentPage * 10) - 10
    let endIndex = currentPage * 10
    const page_data = data.slice(startIndex,endIndex)
    setTotalPages(data.length)
    setPageData(page_data)
    setTickerSel(page_data.map(obj => obj[dfin.ticker]));}
  }, [data,currentPage]);


  return (
    <div style={{display:"flex",flexDirection:"column",height:"100%",width:"100%",overflow:"auto"}}>
    {pageData.length>0 && 
    <div style={{flex:1,overflow:"auto"}}>
    {dataView === "Scatter" && <ScatterUI data={pageData} defaultMet={scatterMetricsSel}/>}
    {dataView==="Heatmap" && <TreeMap data={pageData}/>}
    {dataView==="Brand Visuals" &&     <PeerOverviewMetricComp
   data={pageData}
   infoName={"Brand Viusals"}
     storeId={"StockOpportunities"}
     defaultMet={[dfin.marketCap,dfin.ebitda,dfin.revenue]}
     metricOptions={metricOptions}
     chartTypeDefault={"BrandVisuals"}
     chartTypeOptions={["BrandVisuals"]}
   />}
   {dataView === "Information" && 
   <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.25rem"}}>
   {
   pageData.map((data,index)=>(
    <TickerCard key={index} data={data} tickerPrice={close}/>
   ))}</div>}
  {(dataView==="Table") && 
  <TableGen 
  tickerPrice={close} 
  pagination={false}
  tableLayout='auto'
  logo={true}
  data={getSpecificArrayObjKeys(pageData,stockCols)}/>}
  </div>}
  <PaginationButtons totalPages={totalPages} currentPage={currentPage} 
    itemsDisplaying={pageData.length}
    itemsPerPage={10} handlePage={(v)=>setCurrentPage(v)}/>  
  </div>
  )
}

const stockCols = [dfin.coLogo,dfin.ticker,dfin.coName,dfin.country,dfin.marketCap,dfin.fiancialRating,dfin.growthRating]

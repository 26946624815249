import React from "react";
import { useAppInfo } from "AppState";
import { Alert, Badge } from "@mui/material";
import ProfileLogo from "components/Logo/ProfileLogo";
import styles from "./Chats.module.css";

const Chats = () => {
  const { dispatchChats, stateApp, stateChats } = useAppInfo();
  const { userDetails } = stateApp;
  const { unreadChatIds, chats } = stateChats;

  const handleSelect = (u) => {
    let chatId = 
      userDetails?.uid > u?.uid
        ? userDetails.uid + u.uid
        : u.uid + userDetails.uid;
    dispatchChats({
      user: u,
      chatId: chatId,
      chatting: true
    });
  };

  return (
    <div className={styles.chatsContainer}>
      {chats && 
        <>
          {Object.keys(chats).length === 0 && (
            <Alert severity="warning">You have no Chats yet!</Alert>
          )}
          {Object.entries(chats)
            .sort((a, b) => {
              return b[1]?.lastMessage?.date?.toDate() - a[1]?.lastMessage?.date?.toDate();
            })
            .map((chat) => (
              <div
                className={styles.chatItem}
                key={chat[0]}
                onClick={() => handleSelect(chat[1].userInfo)}
              >
                <Badge 
                  badgeContent={unreadChatIds[chat[0]]} 
                  overlap="circular"
                  color="primary" 
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <ProfileLogo 
                    profileType="nonOwner" 
                    imgSrc={chat[1].userInfo?.photoURL} 
                    displayName={chat[1].userInfo?.displayName}
                  />
                </Badge>
                
                <div className={styles.chatDetails}>
                  <h4 className={styles.displayName}>{chat[1].userInfo?.displayName}</h4>
                  <h5 className={styles.lastMessage}>{chat[1].lastMessage?.text}</h5>
                </div>
              </div>
            ))}
        </>
      }
    </div>
  );
};

export default Chats;

import { Typography } from '@mui/material'
import React, { useState } from 'react'

export default function TextArea({label,value,onChange,placeholder,height="100%",required=true,resize=false,sx={}}) {
  const [overflow,setOverflow] = useState(false)
  return (
    <div style={{height:height,width:"100%",flex:1}}>
    <Typography>
        {label}</Typography>
   <textarea 
   onMouseEnter={()=>setOverflow(true)}
   onMouseLeave={()=>setOverflow(false)}
   required={required} value={value} onChange={(e)=>onChange(e.target.value)} 
   placeholder={placeholder}
   style={{ 
   fontSize:"1rem", 
   padding: "0.5rem",
   width: "100%",height:height,maxWidth:"100%",overflow:overflow?"auto":"hidden"
   ,boxSizing: "border-box", resize: resize ? "both":"none"
   ,outline:"none",border:"1px solid #ddd",borderRadius:"4px",...sx}} 
   name="textArea" id="textArea">
    </textarea>
    </div>
  )
}

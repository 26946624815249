import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import CashflowChart from '../Cashflow/CashflowChart'
import { extrapolateCashflow } from '../Utils/utils'
import { Button } from '@mui/material'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import { assetTypes } from 'appState/initialStateMyFinancials'
import TextInput from 'components/Inputs/TextInput'

export default function AssetProjection({
    chartHeight = "400px", 
    projectionType = "Projection", 
    amountType = "value", 
    editDetails = false, 
    showDate = false, 
    showField = true
}) {
    const { stateMyFinancials } = useAppInfo()
    const { assets, cashflowDistribution } = stateMyFinancials
    const { assetReturnDist, assetDist } = cashflowDistribution
    const [simulatedData, setSimulatedData] = useState([])
    const [hoveredItem, setHoveredItem] = useState()
    const [projectedData, setProjectedData] = useState([])
    const [selectedAssets, setSelectedAssets] = useState([])
    const [numSimulations,setNumSimulations] = useState(5)

    useEffect(() => {
        if (assets) {
            const filteredData = (amountType === "value" ? assetDist : assetReturnDist).filter(obj => obj?.selected)
            const selectedAssets = assets.filter(obj => obj?.selected)
            setSelectedAssets(selectedAssets)
            setProjectedData(filteredData)
        }
    }, [assetDist, assetReturnDist, amountType, assets])

    function handleSimulation() {
        let allSimulations = [];
        for (let i = 0; i < numSimulations; i++) {
            const simulationResult = extrapolateCashflow({
                fields: selectedAssets,
                growthKey: amountType === "value" ? "value" : "return",
                amountType: amountType === "value" ? "value" : "growth",
                showVolatility: true
            });
            allSimulations = [...allSimulations, ...simulationResult];
        }

        // Object to hold the summed amounts by date and name
        let summedResultsByDateName = {};

        // Aggregate amounts by date and name
        allSimulations.forEach(entry => {
            const dateStr = entry.date.toISOString().split('T')[0]; // Normalize the date to YYYY-MM-DD format
            const key = `${dateStr}|${entry.name}`; // Create a unique key for each date and name combination

            if (summedResultsByDateName[key]) {
                summedResultsByDateName[key].amount += entry.amount;
            } else {
                summedResultsByDateName[key] = { ...entry, amount: entry.amount };
            }
        });

        // Convert the object back to an array for rendering or further use
        let summedResults = Object.values(summedResultsByDateName);

        setSimulatedData(summedResults);
    }

    useEffect(() => {
        if (projectionType === "Simulated" && selectedAssets.length > 0) {
            handleSimulation()
        }
    }, [projectionType, selectedAssets])

    const data = projectionType === "Projection" ? projectedData : simulatedData

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {data &&
                <>
                  <div style={{width:"100%",height:chartHeight}}>
                  <CashflowChart 
                        hoveredItem={hoveredItem} 
                        data={data} 
                        title={`Projected Asset ${amountType === "value" ? "Value" : "Returns"}`} 
                        enableGrouping={false} 
                    />
                  </div>
                   
                    {projectionType === "Simulated" &&
                        <div style={{ position: "sticky", bottom: 0,padding: "0.5rem", display: "flex",gap:"1rem"}}>
                            <TextInput 
                            variant='outlined'
                            fontSize={"small"}
                            min={1}
                            max={5000}
                            sx={{width:"200px"}}
                            type='number' 
                            label='Number of Simulations' value={numSimulations} onChange={(v)=>setNumSimulations(v)}></TextInput>

                            <Button variant='contained' onClick={() => handleSimulation(30)}>Run Simulation</Button>
                        </div>
                    }
                    {showField &&
                        <div style={{ flex: 1 }}>
                            <FieldFormGen 
                                storeName={"assets"}
                                handleHover={(v) => setHoveredItem(v?.name)}
                                showCheckbox
                                data={assets}
                                forecastPeriod={true}
                                volatility={true}
                                returnExpected={true}
                                showType={true}
                                date={showDate}
                                typeOptions={assetTypes}
                                editDetails={editDetails}
                            />
                        </div>
                    }
                </>
            }
        </div>
    )
}

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function StickyTabs({ items, value, onChange }) {
  return (
    <div style={{
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      backgroundColor: '#fff',
      boxShadow: '0px 4px 2px -2px gray',
    }}>
      <Tabs
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        {items.map((item, index) => (
          <Tab
            key={item.id}
            label={item.name}
            onClick={() => document.getElementById(item.id).scrollIntoView({ behavior: 'smooth' })}
          />
        ))}
      </Tabs>
    </div>
  );
}

import React, { useState } from 'react';
import MonteCarloSimulation from './MonteCarloSimulation';
import InvestmentProjection from './InvestmentProjection';
import GoalSuccessFailureScenario from 'myFinancials/Goals/GoalSuccessFailureScenario';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextWithBorders from 'components/Font/TextWithBorders';
import AssetProjection from 'myFinancials/Asset/AssetProjection';
import ExpenseDetails from 'myFinancials/Cashflow/ExpenseDetails';
import Income from 'myFinancials/Cashflow/Income';
import AmortizationTable from 'myFinancials/Liability/AmortizationTable';
import NetCashFlowProjection from 'myFinancials/Cashflow/NetCashFlowProjection';
import ExpenseProjection from 'myFinancials/Cashflow/ExpenseProjection';
import GoalsProjection from 'myFinancials/Goals/GoalsProjection';

const scenariosInfo = [

  {
    category: "Success/Failure Scenarios",
    scenarios: [
      { title: "Goals Success/Failure", description: 
        "Analyze the success or failure of your financial goals under different scenarios.", 
        component: <GoalSuccessFailureScenario /> }
    ]
  },
  {
    category: "Forecast/Simulate Financials",
    scenarios: [
      { title: "Asset Return/Value Projection", description: 
        "Project & Simulate the value/return of Assets overtime.", 
        component: <AssetProjection projectionType='Simulated' amountType='return'/> },
      { title: "Liability Amortization", description: 
          "Get the amount of Interest & Principal to be paid over time", 
          component: <AmortizationTable/> },
      { title: "Expense - Inflation Projection", description: 
            "See how the expenses will look like with different inflation for different expenses.", 
            component: <ExpenseProjection/> },
      { title: "Income - Growth", description: 
              "Project the growth of your income", 
              component: <Income/> },
      { title: "Net Cashflow Projection", description: 
                "Project Net Cashflow Overtime", 
                component: <NetCashFlowProjection/> },
      { title: "Goals Projection", description: 
                "Project Goals Overtime", 
                component: <GoalsProjection showDetails/> }
      
    ]
  },
  {
    category: "Hypothetical Scenarios",
    scenarios: [
      { title: "Investment Withdrawal", description: "How much investment can I withdraw? Helpful to see if you can meet your retirement goals.", component: <MonteCarloSimulation /> },
      { title: "Income vs Investment", description: "Visualize the power of compounding. Just by investing a percent of your income per month you can accelerate your wealth.", component: <InvestmentProjection /> }
    ]
  },
];

export default function ScenarioInfo() {
  const [page, setPage] = useState(0);
  const [scenarioSelected, setScenarioSelected] = useState(null);

  const handleScenarioClick = (scenario) => {
    setScenarioSelected(scenario);
    setPage(1);
  };

  return (
    <>
      {page === 0 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
          <h2 style={{ padding: "2rem" }}>Explore Financial Scenarios</h2>
          {scenariosInfo.map((categoryObj, catIdx) => (
            <div key={catIdx} style={{ width: "70%", marginBottom: "1rem" }}>
              <TextWithBorders text={categoryObj.category}/>
              <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap", cursor: "pointer",marginTop:"1rem"}}>
                {categoryObj.scenarios.map((scenario, idx) => (
                  <div key={idx} onClick={() => handleScenarioClick(scenario)}
                    style={{ padding: "0.5rem", background: "#fafafa", borderRadius: "5px", width: "300px" }}>
                    <h4 style={{ fontWeight: "bold" }}>{scenario.title}</h4>
                    <h5 style={{paddingTop:"0.25rem"}}>{scenario.description}</h5>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ padding: "1rem" }}>
          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
          <IconButton onClick={() => setPage(0)}><ArrowBackIosIcon/></IconButton>
          <h3>Scenario / {scenarioSelected?.title}</h3>
            </div>


          {scenarioSelected?.component}
        </div>
      )}
    </>
  );
}

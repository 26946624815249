import axios from "axios";
import { BASE_URL } from "apiConfig";

export default async function GetPriceInfo(tickerList) {
    let marketInfoLoading = true
    let close = []
    let priceData = []
    let trendTable = []
    try {
      const response = await axios(`${BASE_URL}/multiTickerInfo/`, {
        params: {
          ticker: JSON.stringify(tickerList),
        },
      });

      const marketInfo = response.data;

      close = JSON.parse(marketInfo["close"]);
      priceData = JSON.parse(marketInfo["data"])
      trendTable = JSON.parse(marketInfo["dayTable"])
      if (tickerList.length === 1) {
        close.forEach((obj) => {
          if ("Close" in obj) {
            obj[tickerList[0]] = obj.Close;
            delete obj.Close;
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
     marketInfoLoading = false
    }
   

    return { close, trendTable, marketInfoLoading, priceData}
  }
import { useEffect} from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useFindUpload = () => {
  const {dispatchReportBuilder,stateApp} = useAppInfo()
  const userId = stateApp.userId

  const findData = async () => {
    try {
      if (userId) {
        const response =  await axios.post(`${BASE_URL}/findUploads/`, {
          userId
        });
        // Process the response data as needed
       const data = response.data;
       const images = data.reportImages
      dispatchReportBuilder({'uploads':images});
      }
    } catch (err) {
      // Handle the error
    }
  };

  useEffect(() => {
    if (userId) {
      findData();
    }
  }, [userId]);

  return null; // Since this hook is used for side effects, you don't need to return anything
};

export default useFindUpload;


import React, {useEffect, useMemo, useRef } from "react";
import { useAppInfo } from "AppState";
import styles from "./chat.module.css"
import ProfileLogo from "components/Logo/ProfileLogo";
import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig/firebaseConfig";

const Message = ({ message}) => {
  const { stateApp,stateChats } = useAppInfo();
  const {userDetails} = stateApp 
  const { user,chatId,unreadChat} = stateChats
  

  const ref = useRef();

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

const messageDate = message.date.toDate();
const options = { month: 'long', day: 'numeric', year: 'numeric' };
const formattedDate = messageDate.toLocaleDateString('en-US', options);

const timeOptions = { hour: '2-digit', minute: '2-digit' };
const formattedTime = messageDate.toLocaleTimeString('en-US', timeOptions);

const fullFormattedDateTime = `${formattedDate}, ${formattedTime}`;


const markMessageAsSeen = async (messageId) => {
  try {
    const chatRef = doc(db, "chats", chatId);
    const chatDoc = await getDoc(chatRef);
    if (chatDoc.exists()) {
      const messages = chatDoc.data().messages || [];
      let isUpdated = false;
      const updatedMessages = messages.map(msg => {
        if (msg.id === messageId && !msg.seen) {
          isUpdated = true;
          return { ...msg, seen: true };
        }
        return msg;
      });

      if (isUpdated) {
        await updateDoc(chatRef, {
          messages: updatedMessages
        });}

      const userChatsRef = doc(db, "userChats", userDetails.uid);
      const userChatDoc = await getDoc(userChatsRef);
      if (userChatDoc.exists()) {
        const data = userChatDoc.data();
        const userUnreadPath = `${chatId}.unreadCount`;
        const currentUnreadCount = data[chatId]?.unreadCount || 0;
        if (currentUnreadCount > 0) {
          await updateDoc(userChatsRef, {
            [userUnreadPath]: increment(-1)
          });
        }
      }
      
    }
  } catch (error) {
    console.error("Error updating message seen status:", error);
  }
};


  const unseenMessages = useMemo(() => {
    return !message?.seen && message?.senderId !== userDetails.uid;
  }, [message]);
  
  useEffect(() => {
    if (unseenMessages) {
      markMessageAsSeen(message?.id);
    }
  }, [unseenMessages]);  // Dependency now only on filtered unseen messages
  // useEffect(()=>{
  //   if (unreadChat>0){
  //     markMessageAsSeen(message?.id);
  //   }
  // },[unreadChat])

  return (
    <div
      ref={ref}
      className={`${styles.message} ${message?.senderId === userDetails?.uid && `${styles.owner}`}`}
    >
      <div>
        {message?.senderId === userDetails?.uid ? <ProfileLogo/> : <ProfileLogo profileType="user" imgSrc={user?.photoURL} displayName={message?.displayName}/>}
        
       
        
      </div>
      <div style={{background:"#F8F8F8",borderRadius:"5px"
      ,padding:"0.5rem",display:"flex",flexDirection:"column",maxWidth:"80%"}}>
        <h4 style={{color:"black"}}>{message?.text}</h4>
        {message?.img && <img style={{width:"100%",height:"auto",borderRadius:"5px",objectFit:"contain"}} src={message.img} alt="" />}
        
        <h5 style={{color:"black",display:"flex",float:"right",marginLeft:"auto"}}>
        {`${fullFormattedDateTime}`}
            
            </h5>
      </div>
    </div>
  );
};

export default Message;


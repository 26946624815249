import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useHandlePeers from './useHandlePeers';
import { useAppInfo } from 'AppState';

export function PeerAddButton() {
  return (
    <div>PeerAddButton</div>
  )
}

export function PeerAddTableButton({ticker}){
    const {stateTicker} = useAppInfo()
    const {handleAddToPeers} = useHandlePeers()
    const selectedItems = stateTicker.peerSelectedDisplay
    return (
        <>
        {selectedItems &&
            selectedItems.includes(ticker) ? (
              <CheckCircleOutlineIcon
                style={{ color: "green", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToPeers(ticker);
                }}
              />
            ) : (
              <AddIcon
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddToPeers(ticker);
                }}
              />
            )}
        </>
    )
}
import React, { useEffect, useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useNavigate } from 'react-router-dom';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';

const PackedBubbleChart = ({ data, keys }) => {
  const chartRef = useRef(null);

  const handleTickerChange = useHandleTickerChange()
  const navigate = useNavigate()
  function handleInfo(e,v){
    handleTickerChange({ticker:v})
    
  }

  function handleTrade(e,v){
      handleTickerChange({ticker:v,modal:false,navUrl:"/Trade"})
  }

  const formattedData = data.length>0 && Object.values(data.reduce((acc, item) => {
    const mainBubbleName = item[keys.mainBubble];
    const mainBubbleValue = item[keys.mainBubbleSize];
    const subBubbleName = item[keys.subBubble];
    const subBubbleValue = item[keys.subBubbleSize];
    const imgUrl = item[keys.imageURL]
    if (!acc[mainBubbleName]) {
      acc[mainBubbleName] = {
        name: mainBubbleName,
        data: [],
      };
    }

    acc[mainBubbleName].data.push({
      name: subBubbleName,
      value: subBubbleValue,
      marker:{symbol:imgUrl?`url(${imgUrl})`:"circle"}
    });

    return acc;
  }, {}));

  const options = useMemo(() => {
    if (formattedData.length>0) {
      return {
        chart: {
          type: 'packedbubble',
          animation: false,
          height:"650px",
          marginTop: 0,
          reflow: true,
        },
        
        plotOptions: {
          packedbubble: {
            minSize: '10%',
            maxSize: '220%',
            // zMin: 0,
            // zMax: 10,
            layoutAlgorithm:{
                splitSeries:false,
                enableSimulation: true,
            },
            // layoutAlgorithm: {
            //   gravitationalConstant: 0.02,
            //   splitSeries: false,
            //   seriesInteraction: false,
            //   dragBetweenSeries: true,
            //   parentNodeLimit: true,
            //   enableSimulation: false,
            //   dragBetweenSeries: true,
            // },
            dataLabels: {
              enabled: true,
              format: '{point.name}',
              style: {
                color: 'black',
                textOutline: 'none',
                fontWeight: 'normal',
              },
            },
            events: {
                click: function (event) {
                  const subBubbleName = event.point.name; // Extract subBubbleName from the clicked point
                  handleTickerChange(event, subBubbleName); // Pass subBubbleName to handleTickerChange
                },
                
              },
          },
         
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
              const company = this.point.name;
              const tooltipContent = `
                <b>${company}</b><br>
                ${this.series.name}<br>
                ${this.point.value}<br>
                <div style="display:flex;gap:0.5rem; margin-top:1rem">
                
                
                ${`<button id="infoButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Company Info</button>
                `}
              </div>
                `;
        
              setTimeout(() => {
                // Add event listeners to the buttons after rendering
                const infoButton = document.getElementById(`infoButton-${company}`);
                const tradeButton = document.getElementById(`tradeButton-${company}`);
                if (infoButton) {
                  infoButton.addEventListener('click', (e) => handleInfo(e,company));
                }
                if (tradeButton) {
                  tradeButton.addEventListener('click', (e) => handleTrade(e,company));
                }
              }, 0);
        
              return tooltipContent;
            },
            style: {
              pointerEvents: 'auto',
            },
          },
        // tooltip: {
        //   useHTML: true,
        //   pointFormat: '<b>{point.name}:</b> {point.value}',
        // },
        title: {
          text: '',
        },
        series: formattedData,
        credits:{
            enabled:false
        },
        exporting:{
            enabled:false
        }
      };
    } else {
      // Return a default or empty options object if formattedData is empty
      return;
    }
  }, [formattedData]);
  

  return (
    <>
    <div style={{height:"100%",overflowY:"auto",width:"100%"}}>
    {options && <HighchartsReact 
    // containerProps={{ style: { height: '100%',width:"100%"} }}
    highcharts={Highcharts} options={options} ref={chartRef} />}
    </div>
    </>
  );
};

export default PackedBubbleChart;

// <button id="tradeButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Trade</button>
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useDeleteReport = () => {
  const {stateReportBuilder,dispatchReportBuilder,stateApp} = useAppInfo()
  const userId = stateApp.userId

  const [error, setError] = useState(null);
  
  const deleteReport = async (reportName) => {
    try {
      dispatchReportBuilder({'reportSaved':false});
      const response = await axios.post(`${BASE_URL}/deleteReport/`, {
        userId,
        reportName:reportName
      });
      dispatchReportBuilder({'reportSaved':true});
      setError("Success")
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { deleteReport, error };
};

export default useDeleteReport;
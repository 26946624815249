import React from 'react'
import useRealTimePrice from 'data/eod/useRealTimePrice';
import styles from "./PriceTable.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { numberDecimal } from 'utils/utilGeneral';
import { dfin } from 'content/constants';

export default function PriceTableCell({data,handleDelete}) {
  const { price ,loadingPrice} = useRealTimePrice({ tickerSel:data[dfin.tickerCode] });
  const closePrice = (price && !loadingPrice) && parseFloat(price?.close)==0?price?.open:price?.close
  return (
    <>
    <td>
    {!loadingPrice && numberDecimal(closePrice)}
    </td>
    <td  style={{color:price?.change_p>0?"green":"red"}}>
    {!loadingPrice && numberDecimal(price?.change)}
    </td>
    <td style={{color:price?.change_p>0?"green":"red"}}>
      {!loadingPrice &&  numberDecimal(price?.change_p)} 
      <div
                   style={{position:"absolute"}}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDelete(data[dfin.ticker]);
                    }}
                    className={styles["closeIcon"]} // Add a CSS class for close icon
                  >
                    <CloseIcon fontSize="0.8rem" style={{color:"black"}}/>
                  </div>  
    </td>
    </>
  )
}

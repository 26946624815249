import React from 'react';

import AccordionTS from 'components/Surface/AccordionTS';
import { riskInfo } from 'content/contentRiskTolerance';

export default function RiskToleranceResult({ questionnaire, score }) {
    const calculateRiskCategory = () => {
        const scoreWeight = questionnaire.length * 3; // assuming each question has 3 possible answers each weighing 1 point
        const totalScore = score / scoreWeight * 100;
        return riskInfo.find(info => totalScore <= info.maxPercentile);
    };

    const userCategory = calculateRiskCategory();

    const renderAssetRows = (category, assetClasses, isHighlighted) => {
        let rows = [];
        let typeCount = {}; // This object will store counts for mainType spanning

        // Count each mainType for the current category
        assetClasses.forEach(asset => {
            typeCount[asset.mainType] = (typeCount[asset.mainType] || 0) + 1;
        });

        // Variables to manage the rowspan state
        let currentMainType = null;
        let mainTypeRowsLeft = 0;

        assetClasses.forEach((asset, index) => {
            let mainTypeCell = null;

            // If the current asset's mainType is different from the currentMainType or no rows left to span, reset the span counter
            if (asset.mainType !== currentMainType || mainTypeRowsLeft === 0) {
                currentMainType = asset.mainType;
                mainTypeRowsLeft = typeCount[asset.mainType];
                mainTypeCell = <td rowSpan={mainTypeRowsLeft}>{asset.mainType}</td>;
            }

            rows.push(
                <tr key={`${category}-${index}`} style={{ backgroundColor: isHighlighted ? '#c8e6c9' : 'transparent' }}>
                    {index === 0 && <td rowSpan={assetClasses.length}>{category}</td>}
                    {mainTypeCell}
                    <td>{asset.name}</td>
                    <td>{asset.assetRisk}</td>
                    <td>{asset.marketRisk}</td>
                    <td>{asset.liquidity}</td>
                </tr>
            );

            mainTypeRowsLeft--; // Decrease the rows left for the current mainType
        });

        return rows;
    };

    return (
        <>
            <div style={{ display: "flex",flexDirection: "column"}}>
                <h3>
                Risk Tolerance: {`"${userCategory.category}"`}
                </h3>
                <h4>{userCategory?.description}</h4><br/>
                {/* <div style={{ height: "200px", width: "400px" }}><GaugeChart score={score} /></div> */}
                
                <h3>Following are some asset classes suitable for you:</h3>
                <table style={{ width: '100%', marginTop: '20px', tableLayout: "fixed" }}>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Asset Class</th>
                            <th>Asset Sub Class</th>
                            <th>Asset Risk</th>
                            <th>Market Risk</th>
                            <th>Liquidity Risk</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userCategory && riskInfo.map((item, index) => item.category === userCategory.category && renderAssetRows(item.category, item.assetClasses, userCategory && item.category === userCategory.category))}
                    </tbody>
                </table>

                <AccordionTS summary={"More Risk Tolerance Levels"}>
                <table style={{ width: '100%', marginTop: '20px', tableLayout: "fixed" }}>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Asset Class</th>
                            <th>Asset Sub Class</th>
                            <th>Asset Risk</th>
                            <th>Market Risk</th>
                            <th>Liquidity Risk</th>
                        </tr>
                    </thead>
                    <tbody>
                        {riskInfo.map((item, index) => renderAssetRows(item.category, item.assetClasses, userCategory && item.category === userCategory.category))}
                    </tbody>
                </table>
                </AccordionTS>
            </div>
        </>
    );
}


const mergeArrays = (firstArray, secondArray) => {
    return secondArray.map(secondItem => {
        const firstItem = firstArray.find(firstItem => firstItem.mainType === secondItem.type);
        if (firstItem) {
            return {
                ...secondItem,
                selected:true,
                risk: firstItem.assetRisk,
                liquidity: firstItem.liquidity,
                targetAllocation: firstItem.targetAllocation,
                criterias: firstItem.criterias
            };
        }
        return secondItem;
    });
};






// Asset risk, market risk, and liquidity risk are important concepts in finance that help investors understand different dimensions of the risks involved in holding or trading various types of financial assets. Here's a breakdown of each term:

// 1. Asset Risk
// Asset risk refers to the risk associated with the inherent value and performance of a specific asset. It reflects the potential for loss in the asset's value due to factors that directly affect its investment quality or marketability. For example:

// For stocks, asset risk involves volatility in prices due to the company's operational performance, management decisions, industry changes, or economic factors.
// For bonds, it includes the risk of default by the issuer or changes in interest rates that affect bond prices inversely.
// 2. Market Risk
// Market risk, also known as systematic risk, is the risk of losses due to factors that affect the entire market or a segment of the market. This risk is generally unavoidable and not specific to a particular asset but to market-wide factors such as:

// Economic changes: Inflation, economic downturns, or changes in interest rates.
// Political instability: New regulations, government instability, or geopolitical tensions that can cause market-wide fluctuations.
// Market sentiment: Broad investor reactions to news or global events affecting general market confidence and behavior.
// Market risk cannot be eliminated through diversification alone because it impacts all assets to some degree.

// 3. Liquidity Risk
// Liquidity risk involves the ease with which an asset can be converted into cash without significantly affecting its market price. Assets that can be quickly sold or converted into cash with little impact on their price are considered liquid. Liquidity risk is characterized by:

// High liquidity: Assets such as major stock index shares or government bonds, which can be easily sold at market prices without significant losses.
// Low liquidity: Assets like real estate or specialized investment vehicles (like hedge funds or private equity), which may take time to sell and may suffer from price reductions to attract buyers.
// Importance of Understanding These Risks
// Investors need to assess these risks when choosing where to allocate their capital:

// Asset-specific considerations: Depending on an investor's risk tolerance, they might choose assets with lower asset risks, like government bonds, or higher risks, like stocks in emerging technologies.
// Diversification: By spreading investments across different assets, sectors, and geographical locations, investors can manage and mitigate some of the asset-specific and market risks.
// Liquidity needs: Investors should consider how quickly they might need to convert assets into cash. Those who require quick access might prefer highly liquid markets or assets, whereas those with a longer investment horizon might be more comfortable with assets that carry higher liquidity risk.
// Understanding these aspects helps in building a portfolio that aligns with an investor's financial goals, risk tolerance, and investment horizon.
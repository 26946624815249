import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { eodHistorialApiKey } from '../keys';

export default function usePriceChartEod({ tickerSel }) {
  const [priceInfo, setPrice] = useState([]);
  const [loadingPriceInfo, setLoadingPriceInfo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (tickerSel) {
        // Check if data exists in IndexedDB
        const storedData = await getStoredData(tickerSel);
        if (storedData) {
          setPrice(storedData);
        } else {
          await getMetricInfo();
        }
      }
    };

    fetchData();
  }, [tickerSel]);

  // GET COMPANY DATA
  async function getMetricInfo() {
    setLoadingPriceInfo(true);
    try {
      const responseMetric = await axios(
        `https://eodhd.com/api/eod/${tickerSel}?api_token=${eodHistorialApiKey}&fmt=json`
      );

      setPrice(responseMetric.data);

      await storeData(tickerSel, responseMetric.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingPriceInfo(false);
  }

  async function storeData(ticker, data) {
    const timestamp = new Date().getTime();
    const db = await openDatabase();
    const transaction = db.transaction('priceInfo', 'readwrite');
    const objectStore = transaction.objectStore('priceInfo');

    return new Promise((resolve, reject) => {
      const request = objectStore.put({ ticker, data, timestamp });

      request.onsuccess = (event) => {
        resolve();
      };

      request.onerror = (event) => {
        console.error('Error storing data in IndexedDB');
        reject('Error storing data in IndexedDB');
      };
    });
  }

  async function getStoredData(ticker) {
    const db = await openDatabase();
    const transaction = db.transaction('priceInfo', 'readonly');
    const objectStore = transaction.objectStore('priceInfo');

    return new Promise(async (resolve, reject) => {
      const request = objectStore.get(ticker);

      request.onsuccess = async (event) => {
        const storedData = event.target.result;
      
        if (storedData && isRecent(storedData?.timestamp)) {
          resolve(storedData.data);
        } else {
          resolve(null);
        }
      };

      request.onerror = (event) => {
        console.error('Error getting data from IndexedDB:', event.target.error);
        reject('Error getting data from IndexedDB');
      };
    });
  }

  function openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open('PriceChartDB', 1);

      request.onerror = (event) => {
        reject('Error opening database');
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const objectStore = db.createObjectStore('priceInfo', { keyPath: 'ticker' });
        objectStore.createIndex('ticker', 'ticker', { unique: true });
      };
    });
  }

  function isRecent(timestamp) {
    const now = new Date().getTime();
    const fifteenMinutesInMillis = 15 * 60 * 1000;
    return (now - timestamp) < fifteenMinutesInMillis;
  }

  return { priceInfo, loadingPriceInfo };
}

import React from 'react';

import { useAppInfo } from 'AppState';
import TechnicalChart from 'components/Charts/TechnicalChart';
import { assetCode, dfin } from 'content/constants';
import PriceVsEconomy from 'analytics/economy/PriceVsEconomy';
import SearchTickerDropdown from 'search/SearchTickerDropdown';
import Technicals from 'analytics/technicals/Technicals';
import CompanyBusiness
  from 'analytics/ticker/stocks/BusinessAndOwnership/CompanyBusiness';
import CompanyManagement
  from 'analytics/ticker/stocks/BusinessAndOwnership/CompanyManagement';
import CompanyCapitalStructure
  from 'analytics/ticker/stocks/Financials/Company/CompanyCapitalStructure';
import CompanyNews from 'newsEvents/CompanyNews';
import CompanyVsIndustryUI
  from 'analytics/ticker/stocks/VsIndustry/CompanyVsIndustryUI';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';
import Value from 'analytics/ticker/stocks/Value/Value';

import GuideTemplate from '../Template/GuideTemplate';

export default function StockPickingBottomUp({ search = false }) {
  const {stateTicker} = useAppInfo();

  const contentAfterHeading = search && (
    <div
      style={{
        padding: "0 1rem",
        width: "50%",
        boxSizing: "border-box",
        marginTop: "0.05rem",
      }}
    >
      <SearchTickerDropdown
        tickerType={assetCode?.Stocks}
        defaultSearchTerm={stateTicker.tickerStock}
      />
    </div>
  );
  const steps = StepContent()
  return (
    <>
      <GuideTemplate
        name={"Guide to Stock Analysis - Bottom Up Approach"}
        steps={steps}
        contentAfterHeading={contentAfterHeading}
        description = {description}
      />
     
    </>
  );
}


const description = "Stock picking through a bottom-up approach is a meticulous and often challenging process, but it can yield substantial returns when executed effectively.<br/><br/> This approach focuses on in-depth analysis of individual companies rather than broader market trends. Key aspects of this approach include evaluating a company's leadership and vision, scrutinizing financial metrics such as revenue, profitability, and cash flow, and assessing the company's ability to efficiently use capital via metrics like ROIC. Additionally, it involves comparing a company's financial health against its peers, estimating its intrinsic value using DCF analysis, and examining technical and market indicators. Staying informed with the latest news and understanding market timing are also crucial.<br/><br/> This comprehensive analysis helps investors make well-informed decisions, aiming to maximize returns while managing investment risks."

function StepContent(){
  const { dispatchTicker, dispatchStock, stateTicker } = useAppInfo();


const defaultMetGrowth = [`${dfin.revenue} 5Y-GROWTH`,`${dfin.ebitda} 5Y-GROWTH`,`${dfin.netIncome} 5Y-GROWTH`]
  

const steps = [
  {
    label: "Company Story, Vision & Management",
    keyPoints: [
      "Understand the company's origins, strategic vision, and the track record of its management.",
      "Evaluate how management decisions have aligned with company goals and their impact on performance."
    ],
    success: [
      "Consistent track record of achieving goals.",
      "Management recognized for industry leadership and innovation."
    ],
    warning: [
      "Discrepancies between management statements and outcomes.",
      "Frequent unexplained changes in leadership or strategy."
    ],
    content: (
      <>
        <CompanyBusiness />
        <CompanyManagement />
      </>
    ),
  },
  {
    label: "Financial Performance (Revenue, Gross Profit, Ebitda, Net Profit)",
    keyPoints: [
      "Examine key financial metrics to assess revenue generation, cost management, and profitability.",
      "Analyze financial trends over multiple periods for stability and growth insights."
    ],
    success: [
      "Sustained revenue growth and profitability above industry averages.",
      "Improved margins and efficient cost management."
    ],
    warning: [
      "Declining revenue or profits contrary to industry trends.",
      "Unexplained fluctuations in key financial metrics."
    ],
    content: (
      <>
        <CompanyFinancialsUI
          hideOnHover={true}
          report={false}
          chart={"area"}
          metricSel={[
            dfin.revenue,
            dfin.grossProfit,
            dfin.ebitda,
            dfin.netIncome,
          ]}
        />
        <CompanyVsIndustryUI defaultMet={defaultMetGrowth}/>
      </>
    ),
  },
  {
    label: "Free Cash Flow (Cash Flow from Operations - CAPEX) - Cash is King!",
    keyPoints: [
      "Assess ability to generate cash surplus after capital expenditures.",
      "Evaluate trends in free cash flow as an operational efficiency measure."
    ],
    success: [
      "Consistently positive free cash flow.",
      "Free cash flow growing relative to revenue and capex."
    ],
    warning: [
      "Long-term negative free cash flow.",
      "Significant divergence between net income and free cash flow."
    ],
    content: (
      <>
        <CompanyFinancialsUI
          hideOnHover={true}
          report={false}
          chart={"area"}
          metricSel={[dfin.fcf]}
        />
        <CompanyVsIndustryUI defaultMet={[dfin.fcf,dfin.cfo]}/>
      </>
    ),
  },
  {
    label: "Return on Invested Capital (ROIC) - Growth without ROIC is bad!",
    keyPoints: [
      "Evaluate effectiveness of capital use in generating earnings.",
      "Compare ROIC to industry benchmarks for competitive strength and efficiency."
    ],
    success: [
      "ROIC exceeds industry average and is stable or improving.",
      "Efficient capital use in profit generation."
    ],
    warning: [
      "ROIC consistently lower than cost of capital.",
      "Declining ROIC without strategic reinvestment plans."
    ],
    content: (
      <>
      <CompanyFinancialsUI
        hideOnHover={true}
        report={false}
        chart={"area"}
        metricSel={[dfin.roic]}
      />
      <CompanyVsIndustryUI defaultMet={[dfin.roic]}/>
      </>
    ),
  },
  {
    label: "Financial Stability - Debt to Equity, Current Ratio",
    keyPoints: [
      "Analyze debt levels compared to equity to understand leverage and risk.",
      "Evaluate liquidity ratios for short-term financial resilience."
    ],
    success: [
      "Stable or low debt-to-equity ratio relative to industry.",
      "Healthy liquidity ratios indicating financial flexibility."
    ],
    warning: [
      "High leverage with poor interest coverage.",
      "Liquidity ratios below industry average, indicating potential cash problems."
    ],
    content: (
      <>
        <CompanyCapitalStructure />
        <CompanyFinancialsUI
          hideOnHover={true}
          report={false}
          chart={"area"}
          metricSel={["CURRENT RATIO", "QUICK RATIO"]}
        />
        <CompanyVsIndustryUI defaultMet={[dfin.de,dfin.cr]}/>
      </>
    ),
  },
  {
    label: "Relative Value - vs Industry & Peers",
    keyPoints: [
      "Compare financial ratios and performance against peers for market standing.",
      "Assess valuation multiples like P/E, P/S, P/B relative to the industry."
    ],
    success: [
      "Superior margins and returns compared to peers.",
      "Valuation multiples justify growth potential and financial health."
    ],
    warning: [
      "Metrics significantly lag behind industry norms without clear reasons.",
      "Overvaluation relative to financial performance and growth prospects."
    ],
    content: <Value />,
  },
  {
    label: "Absolute Value - DCF",
    keyPoints: [
      "Use Discounted Cash Flow to estimate intrinsic value based on future cash flow projections.",
      "Adjust for risk and growth rates to refine valuation accuracy."
    ],
    success: [
      "DCF valuation suggests significant upside compared to market price.",
      "Robust cash flow projections supported by historical trends."
    ],
    warning: [
      "Overly optimistic future cash flows unsupported by historical performance.",
      "High sensitivity of valuation to changes in assumptions."
    ],
    content: <Value valuationType="DCF" />,
  },
  {
    label: "Technicals - Price, Volume and Indicators",
    keyPoints: [
      "Evaluate technical indicators and market timing for informed trading decisions.",
      "Examine historical price and volume data to forecast future movements."
    ],
    success: [
      "Consistent correlation between technical signals and market movements.",
      "Technical indicators consistently predict price movements accurately."
    ],
    warning: [
      "Technical signals that fail to predict market movements frequently.",
      "High volatility that makes technical predictions unreliable."
    ],
    content: (
      <>
      <div style={{height:"500px",width:"100%"}}>
        <TechnicalChart />
        </div>
        <Technicals tabName={"Indicators"} />
      </>
    ),
  },
  {
    label: "Timing - Industry, Market, Economy Condition & Trend",
    keyPoints: [
      "Analyze Market, Economy, and Industry Conditions to optimize investment timing.",
      "Identify trends to maximize investment potential."
    ],
    success: [
      "Accurate prediction of market turns based on economic and industry indicators.",
      "Successful identification of bullish and bearish phases for timely investment decisions."
    ],
    warning: [
      "Misreading economic indicators leading to poor timing decisions.",
      "Over-reliance on lagging indicators that result in missed opportunities."
    ],
    content:<>
    <PriceVsEconomy tickerType={['SPY']} infoName={"Economy vs Market"}/>
    </>
    },
  {
    label: "News",
    keyPoints: [
      "Stay updated with the latest company and industry news for informed investment decisions.",
      "Monitor developments related to regulatory changes, competitive dynamics, and market trends."
    ],
    success: [
      "Timely utilization of news updates to capitalize on market opportunities.",
      "Effective differentiation between market noise and impactful news."
    ],
    warning: [
      "Overreacting to news without sufficient analysis, leading to poor investment decisions.",
      "Ignoring significant news that could affect company fundamentals."
    ],
    content: <CompanyNews type="list" />,
  },
  {
    label: "Call to Action",
    keyPoints: [
      "After thorough analysis, decide whether to Trade or Invest based on your assessment.",
      "Trade if the short-term Risk/Reward ratio is favorable or events suggest a good bet.",
      "Invest for long-term value and strategic fit within your portfolio."
    ],
    success: [
      "Execution of trades or investments that align with pre-defined strategic goals and market analysis.",
      "Achievement of expected returns or preservation of capital based on risk management strategies."
    ],
    link: { link: "/Stocks/Trade", name: "Trade" },
  },
];

return steps

}




{/* <h1>What is a Bottom Up Approach?</h1>
<h2>
  
A bottom-up approach to stock investing is a strategy that focuses on analyzing individual companies rather than considering broader economic or market trends. In this approach, investors evaluate a company's fundamentals such as its financial statements, management team, competitive advantages, industry position, and growth prospects to determine whether it is a good investment opportunity.

Key characteristics of a bottom-up approach include:

Fundamental Analysis: Investors conduct thorough research on individual companies, analyzing financial metrics like revenue growth, earnings, cash flow, and balance sheet strength. They also consider qualitative factors such as the quality of management, product innovation, and competitive positioning.

Company-Specific Factors: Instead of relying on macroeconomic factors or market trends, investors emphasize factors specific to each company, such as its industry dynamics, market share, product differentiation, and potential for future growth.

Long-Term Perspective: Bottom-up investors typically have a long-term investment horizon, aiming to hold stocks for years rather than attempting to time short-term market fluctuations. They believe that by investing in fundamentally sound companies, they can achieve superior returns over time.

Diversification: While bottom-up investors focus on individual companies, they still aim to build a diversified portfolio to manage risk. By investing in companies across different industries and sectors, they reduce the impact of adverse events affecting any single company or sector.

Contrarian Opportunities: Bottom-up investors may also seek out contrarian opportunities, where they invest in companies that are undervalued or overlooked by the broader market due to temporary setbacks or negative sentiment. They believe that such companies have the potential for significant upside once the market recognizes their true value.

Overall, a bottom-up approach to stock investing emphasizes thorough research and analysis of individual companies to identify investment opportunities based on their intrinsic value and growth potential. It contrasts with a top-down approach, which focuses on broader economic trends and sector allocations before selecting specific stocks.





</h2> */}
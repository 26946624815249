import React from 'react'
import useEconInfo from 'data/economy/useEconInfo';
import { useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import EconomyMetricSelection from '../analytics/economy/EconomyMetricSelection';


export default function SearchEconomy({searchInput}) {
    const {dispatchSearch,dispatchEcon} = useAppInfo()
    const { econInfo } = useEconInfo();
    const navigate = useNavigate()
    
      function handleMetric (v){
        const econId = econInfo.filter((obj) => obj.Name === v)[0]?.id;
        dispatchEcon({"contentSel":"search","menuSel": "search" ,"econId":econId });
        navigate("/Analytics/Economy")
        dispatchSearch({"searchClick":false})
      }
  
  return (
    <EconomyMetricSelection 
    searchBar={false}
    title=''
    searchTerm={searchInput}
    modal={false}
    handleMetricSelected={handleMetric} checkbox={false} />
  )
}

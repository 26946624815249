import React, { useEffect, useState } from 'react'
import styles from "./LeftMenuCheckboxList.module.css"

export default function LeftMenuCheckboxList({checkList,handleCheckbox,selectedList=[],labelDict}) {

    const [menuOptions,setMenuOptions] = useState([])

    useEffect(()=>{
        if (Array.isArray(checkList)){
        setMenuOptions(checkList)}
    },[checkList])

    const handleDragStart = (event, index) => {
        // event.dataTransfer.setData('text/plain', index);
        event.dataTransfer.setData('dragIndex', index);
      };

    const handleDrop = (event, index) => {
        event.preventDefault();
        const dragIndex = event.dataTransfer.getData('dragIndex');
        const updatedMenuOptions = [...menuOptions];
        const draggedItem = updatedMenuOptions[dragIndex];
    
        // Remove the item from the old position
        updatedMenuOptions.splice(dragIndex, 1);
        
        updatedMenuOptions.splice(index, 0, draggedItem);
    
        setMenuOptions(updatedMenuOptions);
      };
  const handleDragOver = (event, index) => {
    event.preventDefault();
  };
  return (
    <div className={styles.paper}>
    {menuOptions.length>0 &&
    <> 
    {menuOptions.map((menu, index) => (
        
    <label
     draggable
     onDragStart={(e) => handleDragStart(e, index)}
     onDragOver={(e) => handleDragOver(e, index)}
     onDrop={(e) => handleDrop(e, index)}
      key={`menu-${menu}-${index}`}
      className={selectedList.includes(menu) ? styles.activeButton : styles.inactiveButton}
    >
      <input
        key={`checkbox-${menu}-${index}`}
        type="checkbox"
        value={menu}
        name={menu}
        onChange={handleCheckbox}
        checked={selectedList.includes(menu)}
      />
      {labelDict ? labelDict[menu]:menu}
    </label>
    ))}
    </>
    }
    </div>
  )
}


import { useEffect, useState } from "react";
import axios from 'axios';
import { financialModelingPrepApiKey } from "data/keys";

export default function useMarketNews(newsType) {
    const [news, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    const cryptoNews = `https://financialmodelingprep.com/api/v4/crypto_news?page=0&apikey=${financialModelingPrepApiKey}`
    const stockNews =  `https://financialmodelingprep.com/api/v3/stock_news?page=0&apikey=${financialModelingPrepApiKey}`
    const forexNews =  `https://financialmodelingprep.com/api/v4/forex_news?page=0&apikey=${financialModelingPrepApiKey}`
    const generalNews = `https://financialmodelingprep.com/api/v4/general_news?page=0&apikey=${financialModelingPrepApiKey}`
    let newsUrl = stockNews
    if (newsType === "Crypto"){
      newsUrl = cryptoNews
    }
    if (newsType === "Forex"){
      newsUrl = forexNews
    }
    if (newsType === "General"){
      newsUrl = generalNews
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(newsUrl);
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
        finally{ 
        setLoading(false)
        }
      };

        fetchData();

    }, []);
  
    return { news };
  }
  


export const fetchMarketNews = async (newsType) => {
      const cryptoNews = `https://financialmodelingprep.com/api/v4/crypto_news?page=0&apikey=${financialModelingPrepApiKey}`;
      const stockNews =  `https://financialmodelingprep.com/api/v3/stock_news?page=0&apikey=${financialModelingPrepApiKey}`;
      const forexNews =  `https://financialmodelingprep.com/api/v4/forex_news?page=0&apikey=${financialModelingPrepApiKey}`;
      const generalNews = `https://financialmodelingprep.com/api/v4/general_news?page=0&apikey=${financialModelingPrepApiKey}`;
  
      let newsUrls = [stockNews]; // Default is stock news
      if (newsType === "Crypto") {
        newsUrls = [cryptoNews];
      } else if (newsType === "Forex") {
        newsUrls = [forexNews];
      } else if (newsType === "General") {
        newsUrls = [generalNews];
      } else if (newsType === "All") {
        newsUrls = [cryptoNews, stockNews, forexNews, generalNews];
      }
  
      try {
          const allNews = await Promise.all(
              newsUrls.map(async (url) => {
                  const response = await axios.get(url);
                  return response.data;
              })
          );
          return allNews.flat(); // Combine all news into a single array
      } catch (error) {
          console.error(error);
          return error
      }
  };
  
import React, { useState } from 'react'
import PerformanceMulti from './PerformanceMulti'
import usePerformanceMulti from 'data/technicals/usePerformanceMulti'
import PerfVolaTable from './PerfVolaTable'
import TabLine from 'components/Tabs/TabLine'

export default function PerformanceClose({close,marketInfoLoading,tickerList,tickerDict}) {
  const [dateFrequency,setDateFrequency] = useState("Monthly")
  const [period,setPeriod] = useState(5)
  
  const {perfMultiTable,perfLoading} = usePerformanceMulti(tickerList, dateFrequency, period); 
 
  function handleDateFreq(e,v){
    setDateFrequency(v)
  }

  function handlePeriod(e,v){
    setPeriod(v)
  }
const tabContent = []

const [tabIndex,setTabIndex] = useState(0)
tabContent[0] = <PerfVolaTable tickerDict={tickerDict} close={close} marketInfoLoading={marketInfoLoading}/>

tabContent[1]=<PerformanceMulti tickerDict={tickerDict} perfMultiTable={perfMultiTable} 
period={period} dateFrequency={dateFrequency}
perfLoading={perfLoading} handlePeriod={handlePeriod} handleDateFreq={handleDateFreq}/>
function handlePerfTabChange(index){
  setTabIndex(index)
}
  return (
    <div className='containerColumnFlexNoGap'>
  
    <TabLine
    tabList={["Periodic","Seasonality"]} 
    tabIndex={tabIndex} handleTabChange={handlePerfTabChange}/>
    <div className='conatinerColumnFlex1'> 
    {tabContent[tabIndex]}
    </div>
    </div>
  )
}



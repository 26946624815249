import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

export default function useSectorInfo() {
    const {stateIndustry,dispatchIndustry} = useAppInfo()
    const [error, setError] = useState(null);
    let sectorSelected = stateIndustry.sectorSel
    if (stateIndustry.sectorSel.includes("All")){
      sectorSelected = stateIndustry.uniqueSectors  
    }
    useEffect(() => {  
      if (stateIndustry.sectorSel && stateIndustry.countrySel){
        getData();}
    }, [stateIndustry.sectorSel,stateIndustry.countrySel]);
    
    async function getData() {
      dispatchIndustry({"loadingSecInfo":true})
      try {
        const response = await axios.get(`${BASE_URL}/sectorInfo/`, {
          params: {
            sectors: JSON.stringify(sectorSelected),
            country:JSON.stringify(stateIndustry.countrySel)
          },
        });
        
        dispatchIndustry({"secInfo":JSON.parse(response.data)})
      } catch (error) {
        console.error(error);
      } finally {
        dispatchIndustry({"loadingSecInfo":false})
      }
    }
    
}



import React, { useEffect, useState } from 'react'

export default function AdvanceDeclineBar({performance,performancePeriod}) {
    const [advanceCount,setAdvanceCount] = useState()
    const [declineCount,setDeclineCount] = useState()
    const [neutralCount,setNeutralCount] = useState()
    useEffect(()=>{
    const advanceCount = performance.filter(item => item[performancePeriod] > 0).length;
    const declineCount = performance.filter(item => item[performancePeriod] < 0).length;
    const neutralCount = performance.length - (advanceCount + declineCount)
    setAdvanceCount(advanceCount)
    setDeclineCount(declineCount)
    setNeutralCount(neutralCount)
},[performance,performancePeriod])
  return (
        <div
              style={{
                display: 'flex',
                width:"70%",
                margin:"auto",
                borderRadius: '50px',
                background:"black",
                padding:"0.1rem",
                marginTop: '10px', // Adjusted margin for spacing
              }}
            >
              <div style={{borderRadius:"25px 0 0 25px", background: 'red', color: 'white',minWidth:"15%", width: `${(declineCount / performance.length) * 100}%`, textAlign: 'center' }}>
                {`Decline: ${declineCount}`}
              </div>
              <div style={{borderRadius:"0", background: '#ddd', color: 'black',minWidth:"15%", width: `${(neutralCount / performance.length) * 100}%`, textAlign: 'center' }}>
                {`Neutral: ${neutralCount}`}
              </div>
              <div style={{borderRadius:"0 25px 25px 0", background: 'green', 
              color: 'white', width: `${(advanceCount / performance.length) * 100}%`,minWidth:"15%", textAlign: 'center' }}>
                {`Advance: ${advanceCount}`}
              </div>
              
            </div>
  )
}

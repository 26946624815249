import React, { useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";

export default function useIconsReportBuilder() {
  const {dispatchReportBuilder} = useAppInfo()
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      
      const response = await axios.get(`${BASE_URL}/icons/`);
      const data = response.data;
      dispatchReportBuilder({"icons":data });
    } catch (error) {
      console.error(error);
    } 
    // finally {
      
    // }
  }
}

import React, { useState } from 'react';
import AddTransaction from './AddTransaction';
import Modal from 'components/Modal/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import { useAppInfo } from 'AppState';
import useAddPortfolio from 'data/user/portfolio/useAddPortfolio';
import { Button } from '@mui/material';


export default function PortfolioAddTransactionModal() {
  const {dispatchPortfolio,statePortfolio,stateApp} = useAppInfo()
  const [transactions, setTransactions] = useState([{ id: Date.now() }]);
  const [transactionDetails,setTransactionDetails] = useState([])

  function handleAddMoreTransaction() {   
    setTransactions((prevTransactions) => [...prevTransactions, { id: Date.now() }]);
  }

  function handleDeleteTransaction(id) {
    setTransactions((prevTransactions) => prevTransactions.filter((transaction) => transaction.id !== id));
    setTransactionDetails((prevTransactions) => prevTransactions.filter((transaction) => transaction.id !== id));
  }

  function handleAddTransactionModal() {
    dispatchPortfolio({"addTransaction":false})
  }
  function handleTransactionDetails(transaction) {
    setTransactionDetails((prevDetails) => {
      const existingIndex = prevDetails.findIndex((detail) => detail.id === transaction.id);

      if (existingIndex !== -1) {
        // Update existing transaction
        const updatedDetails = [...prevDetails];
        updatedDetails[existingIndex] = transaction;

        return updatedDetails;
      } else {
        // Append new transaction
        return [...prevDetails, transaction];
      }
    });
  }
  const {addPortfolio} = useAddPortfolio({dispatchPortfolio,stateApp})
  function handleAddPortfolio(e) {
    e.preventDefault();
    const { accountNameSel, portfolio } = statePortfolio;
    const accountDetails = transactionDetails;
    
    let updatedTransactions = []
    // Check if the account already exists in the portfolio
    const existingAccount = portfolio?portfolio[accountNameSel]:undefined;
    if(existingAccount){
      updatedTransactions = [
        ...existingAccount,
        ...accountDetails,
      ];
    }
    else{
      updatedTransactions = [...accountDetails]
    }
    
    const updatedPortfolio = {
      ...portfolio,
      [accountNameSel]: updatedTransactions,
    };
    

    addPortfolio(accountNameSel,accountDetails)
    dispatchPortfolio({'portfolio':updatedPortfolio,'addTransaction':false});
  }
 

  return (
    <Modal onClose={handleAddTransactionModal} width="80%">
      <form
      onSubmit={handleAddPortfolio} 
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          gap: '1rem',
          boxSizing: 'border-box',
        }}
      > 
      <div style={{display:"flex",alignItems:"center"}}><h2>Add Transactions</h2>
      
      </div>
        <div style={{ flex: 1, overflow: 'auto', paddingBottom: '4rem' }}>
          {transactions.map((transaction) => (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} key={transaction.id}>
              
              <AddTransaction id={transaction.id} handleTransactionDetails={handleTransactionDetails} key={transaction.id} />
              <Button onClick={() => handleDeleteTransaction(transaction.id)} >
                <CloseIcon sx={{ color: 'gray' }}/>
              </Button>
            </div>
          ))}
        </div>
        <div style={{ alignSelf: 'center' }}>
          <Button
            onClick={handleAddMoreTransaction}
          >
            <Add /> Add More Transactions
          </Button>
        </div>
        <div style={{ justifySelf: 'flex-end' }}>
          <Button type='submit' variant='contained'>Add to Portfolio</Button>
        </div>
      </form>
    </Modal>
  );
}

import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import { menuStocks, menuCrypto, menuETF } from 'nav/menu';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useHandleTickerChange = () => {
  const location = useLocation();
  const pageName = location.pathname.split("/")[1]
  const navigate = useNavigate();
  const { dispatchTicker,stateTicker,dispatchSearch,stateSearch,dispatchSidebar,stateSidebar,stateStock, 
    dispatchStock, stateWatchlist, dispatchWatchlist} = useAppInfo()
  const handleTickerChange = useCallback(({event, ticker, modal = true, navUrl = "/Analytics/Ticker",goToPage= true}) => {
    const tickerInfo = stateTicker.tickerInfo.filter(obj => obj[dfin.tickerCode] === ticker || obj[dfin.ticker] === ticker)[0];
    if (tickerInfo) {
      const tickerSelected = tickerInfo?.[dfin.ticker];
      const tickerType = tickerInfo?.["TYPE"];
      const tickerCode = tickerInfo?.["CODE"];
      const tickerName = tickerInfo?.["NAME"];
      const updatedSearchHistory = stateSearch.searchTickerHistory.filter(item => item[dfin.ticker] !== tickerSelected);
      const newSearchHistory = [tickerInfo, ...updatedSearchHistory].slice(0, 20);
      dispatchSearch({searchTickerHistory: newSearchHistory,})
      dispatchTicker({
        tickerSelInfo: tickerInfo,
        tickerName: tickerName,
        tickerCode: tickerCode,
        tickerSelected: tickerSelected,
        tickerType: tickerType
      });
      const pageAsset = /(\/|^)(Ticker|Trade|trade|reports|Report)(\/|$)/i.test(location.pathname);
      
      if (ticker !== null && ticker !== "SPY" && tickerType === assetCode?.Stocks) {
        dispatchTicker({ "tickerStockCode": tickerCode });
        dispatchStock({ "companySelected": tickerSelected });
      }

      if (tickerType !== assetCode?.Stocks) {
        let peersIn = stateTicker.peersIn;
        if (!["CUSTOM", "WATCHLIST", "PORTFOLIO", "ASSET CLASS"].includes(peersIn)) {
          dispatchTicker({ "peersIn": "ASSET CLASS" });
        }
      }

      if (tickerType === assetCode?.Stocks) {
        let peersIn = stateTicker.peersIn;
        if (peersIn === "ASSET CLASS") {
          peersIn = "INDUSTRY";
        }
        dispatchTicker({
          tickerStock: tickerSelected,
          tickerStockCode: tickerCode,
          peersIn: peersIn,
          menu: menuStocks
        });
      }

      if (tickerType === assetCode?.ETF) {
        dispatchTicker({
          tickerETF: tickerSelected,
          tickerETFCode: tickerCode,
          menu: menuETF
        });
      }

      if (tickerType === assetCode?.Crypto) {
        dispatchTicker({
          tickerCrypto: tickerSelected,
          tickerCryptoCode: tickerCode,
          menu: menuCrypto
        });
      }

      if (tickerType ===  assetCode?.["Fixed Income"]) {
        dispatchTicker({
          tickerBond: tickerSelected,
          tickerBondCode: tickerCode,
          menu: []
        });
      }

      if (tickerType === assetCode?.Forex) {
        dispatchTicker({
          tickerForex: tickerSelected,
          tickerForexCode: tickerCode
        });
      }

      if (["Opportunities", "Home"].includes(pageName) && goToPage) {
        navigate(navUrl);
      } else {
        if (modal && !pageAsset) {
          dispatchTicker({ "tickerModal": true });
        }
      }

      dispatchSidebar({ "sidebarOpen": true, "showSidebarRight": true });
      if (!stateSidebar.sidebarOpen && !["peers"].includes(stateSidebar.sidebarRightContent)) {
        dispatchSidebar({ "sidebarRightContent": "peers" });
      }
      dispatchSearch({ "searchClick": false });
    } else {
      dispatchTicker({ "noInfo": true });
    }
  }, [dispatchTicker, stateTicker, dispatchSidebar, stateSidebar, dispatchStock, stateWatchlist, dispatchWatchlist, location.pathname, navigate]);

  function handleInfo(e, v) {
    handleTickerChange(e, v);
  }
  
  function handleTrade(e, v) {
    e.stopPropagation()
    handleTickerChange(e, v, false,"/Trade/Order");
  }
  
  return handleTickerChange;
};



export default useHandleTickerChange;

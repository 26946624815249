import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useAppInfo } from 'AppState';
import { Button } from '@mui/material';

export default function GeneratePdf() {
  const { stateReportBuilder } = useAppInfo();
  const { pages } = stateReportBuilder;
  const pdfRef = useRef(null);

  const handleGeneratePDF = async () => {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'in',
      format: [4, 2],
    });

    for (let i = 0; i < pages.length; i++) {
      const pageElement = document.getElementById(`reportPage${i}`);

      if (pageElement) {
        // Ensure the page is in the viewport
        pageElement.scrollIntoView();

        // Delay to allow rendering
        await new Promise((resolve) => setTimeout(resolve, 500));

        const canvas = await html2canvas(pageElement, {
          foreignObjectRendering: false,
          letterRendering: 1,
          useCORS: true,
          allowTaint: true,
        });
        const imageData = canvas.toDataURL('image/png');

        if (i > 0) {
          pdf.addPage();
        }

        pdf.addImage(imageData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      }
    }

    pdf.save(`${stateReportBuilder.reportNameSel}.pdf`);
  };

  return (
    <Button
      style={{ textTransform: 'none' }}
      variant='contained'
      onClick={handleGeneratePDF}
    >
      Generate PDF
    </Button>
  );
}

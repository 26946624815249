import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import the Highcharts More module
import numeral from 'numeral';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { useNavigate } from 'react-router-dom';
import WatchlistAdd from 'watchlist/WatchlistAdd';
import useHandleTickerChange from 'analytics/ticker/hooks/useHandleTickerChange';


require("highcharts/modules/accessibility")(Highcharts);

HighchartsMore(Highcharts);

export default function ScatterPlot({xAxis,yAxis,colorMetrics,sizeMetrics,data,addPeers=false,exporting=false,plotStyle,}) {
      const [chartType,setChartType] = useState("bubble") 
      const {stateStock,close,dispatchTicker,stateTicker} = useAppInfo()
      const handleTickerChange = useHandleTickerChange()
      const companySelected = stateStock.companySelected
      const [watchlistTicker,setWatchlistTicker] = useState()
      const [addWatchlist, setAddWatchlist] = useState(false);
      const legendList = Array.from(new Set(data.map((data) => data[colorMetrics]))); // Get unique industry names
      const navigate = useNavigate()

      useEffect(()=>{
        if (plotStyle==="Bubble" || plotStyle==="Logo"){
          setChartType("bubble")
        }
        else {
          setChartType("scatter")
        }
      },[plotStyle])
      
      function handleInfo(e,v){
        handleTickerChange({ticker:v})
        
      }
    
      function handleTrade(e,v){
          navigate("/Trade")
          handleTickerChange({ticker:v,modal:false})
      }
      function handleAddPeers(e, v) {
        const updatedPeers = Array.from(new Set([...stateTicker.peerSelected, v]));
        const updatedPeersDisplay = Array.from(new Set([...stateTicker.peerSelectedDisplay, v]));
        
        dispatchTicker({
          peerSelected: updatedPeers,
          peerSelectedDisplay: updatedPeersDisplay
        });
      }

      function handleAddWatchlist(e,v) {
        setWatchlistTicker(v)
        setAddWatchlist(true);
      }
      function handleWatchListModal() {
        setAddWatchlist(!addWatchlist);
      }

      const formatNumber = (value) => {
        if (value >= 1e9) {
          return numeral(value / 1e9).format('0.0a') + 'B';
        } else if (value >= 1e6) {
          return numeral(value / 1e6).format('0.0a') + 'M';
        } else {
          return numeral(value).format('0,0.00');
        }
      };

      const chartData = data.map((data) => ({
        x: data[xAxis],
        y: data[yAxis],
        z: data[sizeMetrics],
        name: data[dfin.ticker],
        colors: data[colorMetrics],
        marker: {
          symbol: plotStyle === "Point"
          ? undefined  // If plotStyle is "Point", do nothing (use default symbol)
          : plotStyle === "Logo" && data[dfin.coLogo]
          ? `url(${data[dfin.coLogo]})`  // If Logo and coLogo data exists, use the logo as the symbol
          : data[dfin.ticker] === companySelected
          ? 'diamond'  // If ticker matches the selected company, use 'diamond' as the symbol
          : 'circle',  // Default to 'circle' for other cases
            fillColor: data[dfin.ticker] === companySelected && 'black', // Set the background color to black
            color: data[dfin.ticker] === companySelected && 'white', 
          },
      }));
      
      const options = {
        chart: {
        type: chartType,
        zoomType: 'xy',
        reflow: true,
        },
        title: {
          text: '',
        },
        xAxis: {
          title: {
            text: xAxis,
          },
          labels: {
            formatter: function () {
              return formatNumber(this.value);
            },
          },
        },
        yAxis: {
          title: {
            text: yAxis,
          },
          labels: {
            formatter: function () {
              return formatNumber(this.value);
            },
          },
        },
        series: legendList.map((color) => ({
            name: color,
            data: chartData.filter((data) => data.colors === color),
            colorByPoint: false,
          })),
          
          tooltip: {
            useHTML: true,
            formatter: function () {
              const company = this.point.name;
              const tooltipContent = `
                <b>${company}</b><br>
                ${this.series.name}<br>
                ${xAxis}: ${formatNumber(this.x)}<br>
                ${yAxis}: ${formatNumber(this.y)}<br>
                <div style="display:flex;gap:0.5rem; margin-top:1rem">
                
                
                ${addPeers ? `<button id="addPeersButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px; border:none">
                Add to Peers</button>` 
                : `<button id="infoButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Company Info</button>
                <button id="addWlButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px; border:none">+</button>`}
              </div>
                `;
        
              setTimeout(() => {
                // Add event listeners to the buttons after rendering
                const infoButton = document.getElementById(`infoButton-${company}`);
                const tradeButton = document.getElementById(`tradeButton-${company}`);
                const addPeersButton = document.getElementById(`addPeersButton-${company}`);
                const addWlButton = document.getElementById(`addWlButton-${company}`);
                if (infoButton) {
                  infoButton.addEventListener('click', (e) => handleInfo(e,company));
                }
                if (addWlButton) {
                  addWlButton.addEventListener('click', (e) => handleAddWatchlist(e,company));
                }
                if (addPeersButton) {
                  addPeersButton.addEventListener('click', (e) => handleAddPeers(e,company));
                }
        
                if (tradeButton) {
                  tradeButton.addEventListener('click', (e) => handleTrade(e,company));
                }
              }, 0);
        
              return tooltipContent;
            },
            style: {
              pointerEvents: 'auto',
            },
          },

        plotOptions: {
          bubble: {
            dataLabels: {
              enabled: true,
              format: '{point.name}', // Display the ticker as the data label
            },
            events: {
                click: function (event) {
                  
                  const name = event.point.name;
                  handleTickerChange({ticker:name})
                }}
          },
          
        },
        exporting:{
          enabled:exporting
        },
        credits: {
          enabled: false, // Hide Highcharts.com credits
        },
        accessibility: {
          enabled: false, // Include the accessibility module
        },
      };
    
      return (
        <>
        <div className='chart_wrapper' >
          <HighchartsReact highcharts={Highcharts} options={options} 
          containerProps={{ style: { height: '100%' , width:"100%"} }}/>
         
        </div>
        {addWatchlist && (
        <WatchlistAdd
          tickerName={watchlistTicker}
          openWl={addWatchlist}
          handleWlModal={handleWatchListModal}
        />
      )}
        </>
      );
    }


// <button id="tradeButton-${company}" class="btn" style="padding: 0.2rem; border-radius: 2px;border:none">Trade</button>
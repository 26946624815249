import React from 'react';
import Modal from 'components/Modal/Modal';
import { useAppInfo } from 'AppState';
import SearchUI from './SearchUI';

export default function SearchModal({type="All",addType="watchlist"}) {

  const {dispatchSearch,stateSearch} = useAppInfo();
  function handleSearchClick() {
    dispatchSearch({
      searchClick: false,
    });
    
  }

  return (
    <>
    {stateSearch.searchClick && 
    <Modal onClose={handleSearchClick} width='50%'>
      <SearchUI type={type} addType={addType}/>
    </Modal>}
    </>
  );
}



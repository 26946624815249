import React from 'react'
import useFetchAssistant from './useFetchAssistant';
import { useAppInfo } from 'AppState';

export default function useHandleUserEvents() {
  
  const {dispatchAssistant,stateAssistant,stateApp,dispatchApp} = useAppInfo()
  const {isLoggedIn} = stateApp
  const {fetchAssistant} = useFetchAssistant()
  // const {fetchResponse} = useFetchResponse()
  const {chats,voiceRecording} = stateAssistant

  function handleStopChat(){
    dispatchAssistant({chatStatus:"stop"})
  }

  function handleMessage(message){
    dispatchAssistant({chatGptMessage:message})
  }
  function handleSend(message) {
    if (!isLoggedIn){
      dispatchApp({"loginModal":true})
    }
    else{
    if (!message) {
      const chatUpdate = [...chats, { role: "assistant", content: "Hey there, how can I help you today?" }];
      dispatchAssistant({chats:chatUpdate})
    } else {
      const updatedChats = [...chats, { role: "user", content: message }, { role: "assistant", content: "#?$?#" }];
      dispatchAssistant({chats:updatedChats})
      fetchAssistant(message,updatedChats)
      // fetchResponse(message)
      dispatchAssistant({chatGptMessage:""})
    }
    }
  }

  function handleNewChat() {
    dispatchAssistant({chatStatus:"newChat"})
  }

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognition = new SpeechRecognition();
  const handleVoiceRecording = () => {
    if (voiceRecording) {
      recognition.stop();
      dispatchAssistant({voiceRecording:false});
    } else {
      recognition.start();
      recognition.onstart = () => {
        dispatchAssistant({voiceRecording:true});
      };
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        dispatchAssistant({chatGptMessage:transcript})
        setTimeout(() => {
            dispatchAssistant({showContent:true})
          handleSend(transcript); 
        }, 100);
      };
      recognition.onend = () => {
        dispatchAssistant({voiceRecording:false});
      };
    }
  };

  function handleShowContent(v){
    dispatchAssistant({showContent:v})
  }

  return {handleSend,handleVoiceRecording,handleShowContent,handleMessage,handleNewChat,handleStopChat}
}

import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import useTrendConditions from 'data/tradeCondition/useTrendCondition'
import useRelativeStrength from 'data/tradeCondition/useRelativeStrength'
import TableGen from 'components/Table/TableGen'
import { dfin } from 'content/constants'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function TrendConditions() {
  const {stateTicker} = useAppInfo()

  const {trendCondition,tcLoading} = useTrendConditions([stateTicker.tickerSelected])
  const {relativeStrength,rsLoading} = useRelativeStrength(stateTicker.tickerSelInfo?.[dfin.yfTicker],"SPY")
  const [tableData,setTableData] = useState([])

  useEffect(()=>{
    if (trendCondition && relativeStrength){
        const table = TableData(trendCondition,relativeStrength)
        setTableData(table)
    }
  },[trendCondition,relativeStrength])

  return (
    <>
    {rsLoading && <LoadingSkeletonTable/>}
    {(!rsLoading && trendCondition) && 
    <div>
    <TableGen pagination={false} tableLayout='fixed' data={tableData}/>
    </div>
    }
    </>
  )
}

function TableData (trendCondition,relativeStrength){
    const table = [
        {
          "Period": "Daily",
          "Trend": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {trendCondition?.daily} {getTrendIcon(trendCondition?.daily)}
            </div>
          ),
          "Relative Strength with S&P 500": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {relativeStrength?.daily} {getRelativeStrengthIcon(relativeStrength?.daily)}
            </div>
          ),
        },
        {
          "Period": "Weekly",
          "Trend": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {trendCondition?.weekly} {getTrendIcon(trendCondition?.weekly)}
            </div>
          ),
          "Relative Strength with S&P 500": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {relativeStrength?.weekly} {getRelativeStrengthIcon(relativeStrength?.weekly)}
            </div>
          ),
        },
        {
          "Period": "Monthly",
          "Trend": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {trendCondition?.monthly} {getTrendIcon(trendCondition?.monthly)}
            </div>
          ),
          "Relative Strength with S&P 500": (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {relativeStrength?.monthly} {getRelativeStrengthIcon(relativeStrength?.monthly)}
            </div>
          ),
        },
      ];

      return table
}

const getTrendIcon = (trend) => {
    switch (trend) {
      case 'Strong Uptrend':
      case 'Uptrend':
        return <TrendingUpIcon style={{ color: 'green' }} />;
      case 'Strong Downtrend':
      case 'Downtrend':
        return <TrendingDownIcon style={{ color: 'red' }} />;
      case 'Consolidation':
      default:
        return <TrendingFlatIcon style={{ color: 'grey' }} />;
    }
  };
  
  const getRelativeStrengthIcon = (rs) => {
    return rs === 'Increasing'
      ? <TrendingUpIcon style={{ color: 'green' }} />
      : rs === 'Decreasing'
      ? <TrendingDownIcon style={{ color: 'red' }} />
      : <TrendingFlatIcon style={{ color: 'grey' }} />;
  };
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState';
import TextFieldsOutlined from "@mui/icons-material/TextFieldsOutlined";
import { TextField, Tooltip } from "@mui/material";
import SelectBox from "components/Inputs/SelectBox";
import { fontStyles } from "content/fontStyles";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { handleObjectDetails } from '../utilsReport';
import ColorPicker from 'components/Inputs/ColorPicker';
import TextInput from 'components/Inputs/TextInput';


export default function TextSettings() {
    const {stateReportBuilder,dispatchReportBuilder} =  useAppInfo()
    const {pages,pageCurrent} = stateReportBuilder;
    const [fontSize,setFontSize] = useState()
    const [fontWeight,setFontWeight] = useState("normal")
    const [fontItalic,setFontItalic] = useState("normal")
    const [fontUnderline,setFontUnderline] = useState("none")
    const [fontStyleSel,setFontStyleSel] = useState(fontStyles[0])
    const [fontColor,setFontColor] = useState("black")
    useEffect(()=>{
      if (stateReportBuilder.objectInfocus && stateReportBuilder.objectInfocus.Type==="Text"){
      setFontSize(parseInt(stateReportBuilder.objectInfocus.style.fontSize))
      setFontWeight(stateReportBuilder.objectInfocus.style.fontWeight)
      setFontItalic(stateReportBuilder.objectInfocus.style.fontStyle)
      setFontUnderline(stateReportBuilder.objectInfocus.style.textDecoration)
      setFontColor(stateReportBuilder.objectInfocus.style.color)
    }
      
    },[stateReportBuilder.objectInfocus])
  
    

    function handleFontStyling(t,v){
      let newStyle;
      if (t==="fontWeight"){
      setFontWeight(v.fontWeight)
      newStyle = v}
      if (t==="fontItalic"){
        setFontItalic(v.fontStyle)
        newStyle = v
    }
      if (t==="fontUnderline"){
          setFontUnderline(v.textDecoration)
          newStyle = v
        }
     if (t==="fontSize"){
        setFontSize(v)
        newStyle = {fontSize:`${v}px`}
     }
     if (t==="fontFamily"){
         newStyle = {fontFamily:v}
     }
     if (t==="fontColor"){
        newStyle = v
    }
      const pageIndex = pageCurrent
      const item = stateReportBuilder.objectInfocus
      handleObjectDetails({newStyle,pages,pageIndex,item,dispatchReportBuilder})
    }
    function handleFontFamily(e,v){
        setFontStyleSel(v)
        handleFontStyling("fontFamily",v)
    }
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"
    ,alignItems:"center"}}>

    <div style={{display:"flex",gap:"0.5rem",flexWrap:"wrap",alignItems:"center"}}>
    <ColorPicker defaultColor={fontColor} 
    onChange={(v)=>handleFontStyling("fontColor",{"color":v})}/>
    <Tooltip title="Bold">
    <div
        onClick={() => {
          const newFontWeight = fontWeight === "bold" ? "normal" : "bold";
          handleFontStyling("fontWeight",{ fontWeight: newFontWeight });
        }}
        style={{
          cursor: "pointer",
          padding: "0.35rem",
          borderRadius: "4px",
          background: fontWeight === "bold" && "#ddd"
        }}
      >
        <h4 style={{ fontWeight: "bold" }}>B</h4>
      </div>
    </Tooltip>
    <Tooltip title="Italic">
    <div
        onClick={() => {
          const newFontStyle = fontItalic === "italic" ? "normal" : "italic";
          handleFontStyling("fontItalic",{ fontStyle: newFontStyle });
        }}
        style={{
          cursor: "pointer",
          padding: "0.35rem",
          borderRadius: "4px",
          background: fontItalic === "italic" && "#ddd"
        }}
      >
        <h4 style={{ fontStyle:"italic"}}>I</h4>
      </div>
    </Tooltip>
    <Tooltip title="Underline">
    <div
        onClick={() => {
          const newTextDeco = fontUnderline === "underline" ? "none" : "underline";
          handleFontStyling("fontUnderline",{ textDecoration: newTextDeco });
        }}
        style={{
          cursor: "pointer",
          padding: "0.35rem",
          borderRadius: "4px",
          background: fontUnderline === "underline" && "#ddd"
        }}
      >
        <h4 style={{textDecoration:"underline"}}>U</h4>
      </div>
    </Tooltip>
    </div>
    <TextInput label='Font Size' value={fontSize} 
    onChange={(v)=>handleFontStyling("fontSize",v)} type="number" 
    id="fontSizeSetting" name="fontSize" min="6" max="140"/>
    <SelectBox label={"Font Style"} options={fontStyles} value={fontStyleSel} 
    onChange={handleFontFamily}/>
   
    </div>
  )
}

import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from "apiConfig";

export default function useSearchTicker(search) {
    const [searchedTicker, setTickerInfo] = useState([]);
    
    useEffect(() => {
        if (search !== "" && search) {
        getTickerInfo();}
    }, [search]);


    async function getTickerInfo() {
        try {
        const response = await axios(`${BASE_URL}/tickerInfo/`, {
            params: {
            search: search,
            },
        });

        setTickerInfo(JSON.parse(response.data));
        } catch (error) {
        console.error(error);
        }
    }
    
    return ({searchedTicker})
  }
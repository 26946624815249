import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import styles from "./ReportLeftSidebar.module.css"
import { dfin } from 'content/constants';
import { Alert, Button, Typography } from '@mui/material';
import Modal from 'components/Modal/Modal';
import PageSettingsModal from 'components/Template/PageSettingsModal';


export default function Templates({content=[],showType}) {
    const {stateTicker,stateReportBuilder,dispatchReportBuilder,stateStock} = useAppInfo()
    const {defaultReports,pages} = stateReportBuilder
    const [addTemplateConfirm,setAddTemplateConfirm] = useState(false)
   function handleCategoryTemplate(v) {
    if (v !=="Company Overview"){
      return window.alert("Currently only Company Overview Template is Available.")
    }
    else {
      setAddTemplateConfirm(true)
    }

}

    function ApplyCompanyTemplate(){
      setAddTemplateConfirm(false)
      const companyReport = defaultReports.find(obj => obj.name === "Company");
      const addPages = companyReport.content.map(obj => ({ ...obj }));
      const pageIndex = pages.length;
    
      if (pages.length === 1 && pages[0]?.["PageContent"].length === 0) {
        dispatchReportBuilder({"pages":addPages });
      } else {
        addPages.forEach((obj, index) => {
          obj.PageIndex = pageIndex + index;
        });
        dispatchReportBuilder({"pages":[...stateReportBuilder.pages, ...addPages] });
      }

    }

  const coInfo = stateStock.peerInfo.find(obj=>obj[dfin.ticker]===stateStock.companySelected)
  
  const contentButtons = [
    {Category:"Company",Content:"Company Overview","ImgUrl":coInfo?.[dfin.coLogo]},
    {Category:"Sector",Content:"Energy","ImgUrl":'https://static.thenounproject.com/png/3509272-200.png'},
    {Category:"Sector",Content:"Healthcare","ImgUrl":'https://static.thenounproject.com/png/3349227-200.png'},
    {Category:"Sector",Content:"Materials","ImgUrl":'https://static.thenounproject.com/png/3858350-200.png'},
    {Category:"Sector",Content:"Information Technology","ImgUrl":'https://static.thenounproject.com/png/1677701-200.png'},
    {Category:"Sector",Content:"Industrials","ImgUrl":'https://static.thenounproject.com/png/81352-200.png'},
    {Category:"Sector",Content:"Financials","ImgUrl":'https://static.thenounproject.com/png/6321329-200.png'},
    {Category:"Sector",Content:"Communication Services","ImgUrl":'https://static.thenounproject.com/png/6324904-200.png'},
    {Category:"Sector",Content:"Consumer Discretionary","ImgUrl":'https://static.thenounproject.com/png/4750799-200.png'},
    {Category:"Sector",Content:"Consumer Staple","ImgUrl":'https://static.thenounproject.com/png/4816276-200.png'},
    {Category:"Sector",Content:"Utilities","ImgUrl":'https://static.thenounproject.com/png/5218159-200.png'},
    {Category:"Sector",Content:"Real Estate","ImgUrl":'https://static.thenounproject.com/png/1076586-200.png'},
    {Category:"Economy & Markets",Content:"Market","ImgUrl":'https://static.thenounproject.com/png/6195778-200.png'},
    {Category:"Economy & Markets",Content:"Economy","SVG":<span style={{height:"40px",width:"40px"}} class="fi fi-us fib"></span>},
    {Category:"Commodities",Content:"Gold","ImgUrl":'https://images.unsplash.com/photo-1610375461246-83df859d849d?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}, 
    {Category:"Commodities",Content:"Oil","ImgUrl":'https://images.unsplash.com/photo-1516199423456-1f1e91b06f25?q=80&w=2349&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}, 
    {Category:"Crypto",Content:"Crypto","ImgUrl":'https://images.unsplash.com/photo-1621504450181-5d356f61d307?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}, 
  
]

  const categoryListButtons = ["Company","Sector","Economy & Markets","Commodities","Crypto"]

  return (
    <>
    <div
        style={{
          display: 'flex',  
          flexDirection:"column",
          width: '100%',
          boxSizing: 'border-box',
          gap:"1rem",
          padding:"1rem",
          position:"relative"
        }}
      >
        
       {/* <div style={{background:"rgba(0,0,0,0.25)",position:"absolute",height:"100%",width:"100%",top:0,left:0}}>
        <Alert style={{marginTop:"200px"}} 
        severity="warning">Sorry, Templates are Currently Not Available.</Alert> 
        </div> */}
      {categoryListButtons.map((category)=>
       <div
       key={category}>
       <h4 style={{fontWeight:"bold",color:"gray",borderBottom:"1px solid #ddd"}}>{category}</h4>
      <div style={{display:"flex",width:"100%",flexWrap:"wrap",gap:"1rem",padding:"1rem 0"}}>
      {contentButtons.map((content)=>
      <>
      {content.Category===category &&
      <>
      <button
      // disabled={content.Content !== "Company Overview"} 
      className={styles.elements} style={{ cursor:"pointer",
      padding:"0.25rem",display:"flex",alignItems:"center",
      borderRadius:"25px",gap:"0.5rem",boxSizing:"border-box"}} 
      onClick={()=>handleCategoryTemplate(content.Content)}>
        {content.ImgUrl&&
        <img style={{width:"25px",height:"25px",objectFit:"contain",minWidth:"20%"}} 
        src={content.ImgUrl}></img>}
        {content.SVG&& content.SVG}
        <Typography style={{flex:1,textAlign:"left"}}>{content.Content}</Typography>
        </button>
        </>}
        </>
        )}
        </div>
        
        </div>)}
      </div>

      {addTemplateConfirm && 
         <PageSettingsModal title="Add Company Template" onClose={()=>setAddTemplateConfirm(false)}>
           <Button variant='contained' onClick={ApplyCompanyTemplate}>Add Template</Button>
        </PageSettingsModal>}
    </>
  )
}
import React, { useState } from 'react'
import useCompanyExchangeFilings from 'data/financialModelingPrep/useCompanyExchangeFilings'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import NoTickerInfo from 'components/NoTickerInfo'

export default function CompanyExchangeReports({reportType="10-k"}) {
  const {stateTicker} = useAppInfo()
  const {companyExFilings} = useCompanyExchangeFilings(stateTicker.tickerStock,reportType)
  const tableData = companyExFilings && getSpecificArrayObjKeys(companyExFilings,cols)
 

  return (
    <>
    {companyExFilings.length === 0 && <NoTickerInfo infoName='Security Exchange Filings'/>}
    {companyExFilings.length>0 && 
      <div><TableGen tableLayout='fixed' data={tableData}/></div>
      }</>
  )
}

const cols = ["fillingDate", "acceptedDate", "cik", "type","link","finalLink"]
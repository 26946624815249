import React, { useEffect, useState } from 'react'
import { defaultMetrics, dfin } from 'content/constants'
import CompanyVsIndustryUI from './CompanyVsIndustryUI'
import TabLine from 'components/Tabs/TabLine'

export default function MetricComparisonVsIndustry() {
   
  const tabInfo = [
    { name: "Market Stats", metric: defaultMetrics.MarketStats, metricCategory: "Market Stats" },
    { name: "Profit", metric: defaultMetrics.Profit, metricCategory: "Profit" },
    { name: "Value", metric: defaultMetrics.Value, metricCategory: "Value" },
    { name: "Financial Stability", metric: defaultMetrics.FinancialStability, metricCategory: "Averages" },
    { name: "Growth", metric: defaultMetrics.Growth, metricCategory: "Growth" },
    { name: "Size & Share", metric: defaultMetrics.Size, metricCategory: "Size & Share" }
  ];
  
  
  const [tabSel,setTabSel] = useState("Market Stats")
  const [defaultMet,setDefaultMet] = useState(defaultMetrics.MarketStats)
  const [metricCategory,setMetricCategory] = useState("Market Stats")
  useEffect(()=>{
    let tabInfoSel = tabInfo.filter(obj=>obj.name===tabSel)[0]
    setDefaultMet(tabInfoSel?.metric)
    setMetricCategory(tabInfoSel?.metricCategory)
  },[tabSel])
  
  const tabList = tabInfo.map(obj=>obj.name)

  return (
    <>
    
     <CompanyVsIndustryUI defaultMet={defaultMet}>
     <TabLine height='40px' tabList={tabList} tabIndex={tabList.indexOf(tabSel)} handleTabChange={(i)=>setTabSel(tabList[i])}/>
      </CompanyVsIndustryUI>

    </>
  )
}
import React, { useEffect, useState } from "react";
import styles from "./ResourceContentList.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { menuIcons } from "nav/menuIcons";
import LeftSidebarFloating from "sidebar/LeftSidebar/LeftSidebarFloating";
import { Tooltip } from "@mui/material";

export default function ResourceContentList({ content }) {
  let searchParams = new URLSearchParams(window.location.search);
  let idTopic = searchParams.get('topic');
  let idHeading = searchParams.get("heading")
  let idSubTopic = searchParams.get("subTopic")
  let headingSel = decodeURIComponent(idHeading)
  const location = useLocation()
  const pageName = location.pathname.split("/")[1]
  const [expandedStates, setExpandedStates] = useState({});
  const navigate = useNavigate();
  // Initialize the expanded state for each section


  
  useEffect(() => {
    if (idSubTopic) {
      setExpandedStates((prevStates) => ({ ...prevStates, [idSubTopic]: true }));
    }
  }, [idSubTopic]);


  const toggleExpansion = (index, subTopic) => {
  
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [subTopic] : !expandedStates[subTopic]
  }));
  };


  function handleContent(subTopic,heading) {
    const encodedHeader = encodeURIComponent(heading);
    const encodedTopic = encodeURIComponent(idTopic)
    const encodedSubTopic = encodeURIComponent(subTopic);
  
    if (pageName !== "Resource"){
      navigate({
        pathname:`${pageName}/Resource/Learn`,
        search:`?topic=${encodedTopic}&subTopic=${encodedSubTopic}&heading=${encodedHeader}`
      }  
      )
    }else{
    navigate({
      pathname:`/Resource/Learn`,
      search:`?topic=${encodedTopic}&subTopic=${encodedSubTopic}&heading=${encodedHeader}`
    }  
    );}
  }

  return (
    <LeftSidebarFloating
    header={idTopic}
    headerDetails={
      <div onClick={()=>navigate("/Resource/Learn")} 
      style={{marginLeft:"auto",color:"gray",display:"flex",alignItems:"center"}}>
               <Tooltip title={"Learn Home"}> {menuIcons.Learn}</Tooltip></div>
    }
    >
    <div className={styles.container} 
    style={{width:"100%",position:"relative",display:"flex",flexDirection:"column"}}>
      
      {/* <div style={{padding:"0.25rem",display:"flex",gap:"0.5rem",alignItems:"center",justifyContent:"center"}}>
        <h4 style={{fontWeight:"bold"}}>{idTopic}</h4></div> */}
      <div className={styles.childContainer} style={{flex:1}}>
     
      {Object.entries(content).map(([subTopic, headings], index) => (
        <div style={{boxSizing:"border-box", borderLeft:subTopic === idSubTopic?"4px solid var(--selectBlue)":"0px solid transparent"}} key={index}>
          <div
            style={{padding:"0.5rem 0"}}
            onClick={() => toggleExpansion(index, subTopic)}
            className={styles.subTopic}
            // onClick={(e) => handleTopicHeader(e, subTopic)}
          >
            {expandedStates[subTopic] ? (
              <ExpandMoreIcon
                fontSize="medium"
                onClick={() => toggleExpansion(index, subTopic)}
              />
            ) : (
              <KeyboardArrowRightIcon
                fontSize="medium"
                onClick={() => toggleExpansion(index, subTopic)}
              />
            )}
            <>{subTopic}</>
          </div>

          {expandedStates[subTopic] && (
            <div className={styles.chapterContent}>
              <ul>
                {headings.map((heading, itemIndex) => (
                  <li
                    style={heading === idHeading ? { background: "#f8f8f8" } : {}}
                    key={itemIndex}
                    onClick={() => handleContent(subTopic,heading)}
                  >
                    <h4 style={{fontWeight:heading === headingSel && "500",color:heading === headingSel && "var(--selectBlue)"}}>{heading}</h4>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
      </div>
  

    </div>
    </LeftSidebarFloating>
  );
}

const findKeyForElement = (obj, element) => {
  for (const [key, array] of Object.entries(obj)) {
    if (array.includes(element)) {
      return key;
    }
  }
  return null; // Return null if the element is not found in any array
};
import React from 'react'
import MarketInfo from '../../analytics/market/MarketInfo'

export default function Market() {

  return (
    <MarketInfo/>
  )
}




const stockPrompts = [
    "Explain the concept of a stock market index.",
    "What are blue-chip stocks, and why are they considered safe investments?",
    "Discuss the factors that influence stock prices.",
    "Explain the difference between common and preferred stocks.",
    "What is a dividend, and why do some stocks pay dividends while others don't?",
    "Describe the process of initial public offerings (IPOs) and their significance in the stock market.",
    "What is stock volatility, and how can it impact investment decisions?",
    "Discuss the role of stockbrokers and online trading platforms in stock investing.",
    "Explain the concept of a stock portfolio and its importance in diversifying investments.",
    "What are stock options and how do they work?",
    "Discuss the risks associated with stock investments and how to manage them.",
    "Explain the terms 'bull market' and 'bear market' in the context of stock trading.",
    "Describe the impact of economic indicators, such as GDP and inflation, on stock markets.",
    "Discuss the strategies for analyzing and selecting individual stocks for investment.",
    "Explain the concept of market orders and limit orders in stock trading.",
    "What are penny stocks, and what are the risks associated with investing in them?",
    "Discuss the role of dividends in a long-term investment strategy.",
    "Explain the importance of conducting due diligence before investing in a stock.",
    "Describe the major stock exchanges in the world, such as the NYSE and NASDAQ.",
    "What are stock splits, and how do they affect shareholders?",
    "Discuss the impact of company earnings reports on stock prices.",
    "Explain the concept of market capitalization and its significance for investors.",
    "Discuss the differences between growth stocks and value stocks.",
    "What are exchange-traded funds (ETFs) and how do they compare to individual stocks?",
    "Explain the risks and benefits of day trading in the stock market.",
  ];

export {stockPrompts}
import React from 'react'
import styles from "./TabGeneral.module.css"

export default function TabGeneral({tabList, tabContent=[],children,tabIndex,
  handleTabChange,height="100%",styling={"activeButton":"actBtnv1"},sx={},sxButton={}}) {
    return (
        <div className={styles["mainTabs"]} style={{height:height,...sx}}>
          
          <div className={styles["tabs"]}>
         
            <div className={styles["mainTabsButtonWrapper"]}>
            {tabList.map((tabs, index) => (
              <button
                style={{...sxButton}}
                className={tabIndex === index ? styles[styling["activeButton"]] : styles.inactiveButton}
                key={`tab-${tabs}-${index}`}
                onClick={() => handleTabChange(index)}
                value={index}
              >
                
              {tabs}
              </button>
            ))}
            
            </div>
            {children}
            
            </div>
          
        
      
            <div className={styles["mainTabsContent"]}>
            {tabContent[tabIndex]}
            </div>
        </div>
    
      );
    }

import React, { useState } from 'react';
import SelectBox from 'components/Inputs/SelectBox';
import TableGen from 'components/Table/TableGen';
import useVolatilityMulti from 'data/technicals/useVolatilityMulti';


export default function VolatilityMulti({tickerList,tickerDict}) {

  
  const [dateFrequency,setDateFrequency] = useState("Monthly")
  const [period,setPeriod] = useState(5)
  const { volaMultiTable } = useVolatilityMulti(tickerList, dateFrequency, period); 

  function handleDateFreq(e,v){
    setDateFrequency(v)
  }

  function handlePeriod(e,v){
    setPeriod(v)
  }

  const periodOptions= Array.from({ length: 10 }, (_, index) => index + 1);
  const tableFilter = (<div style={{display:"flex", gap:"1rem"}}>
  <div><SelectBox options={["Monthly","Quarterly",]} id={"DateFreq Type"} value={dateFrequency} onChange={handleDateFreq} label={"Date Frequency"}/>
</div>
<div><SelectBox options={periodOptions} id={"Period Type"} value={period} onChange={handlePeriod} label={"Years"}/>
</div>
</div>)

  return (
    <div>
      {volaMultiTable.length > 0 && (<TableGen tickerDict={tickerDict} data={volaMultiTable} color='redGreen' 
      tableHeader={`Volatility: ${dateFrequency} mean for ${period} years`}
      tableFilter={tableFilter} tableLayout='fixed'/>)}
    </div>
  );
}

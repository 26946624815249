import {useState } from 'react';
import axios from 'axios';
import { useAppInfo } from 'AppState';
import { BASE_URL } from 'apiConfig';

const useAddNote = () => {
  const {dispatchNotes,stateApp} = useAppInfo();
  const userId = stateApp.userId
  const username = stateApp.username
  const [error, setError] = useState(null);

  const addNote = async (noteName, noteData) => {
    try {
      const response = await axios.post(`${BASE_URL}/addNotes/`, {
        userId,
        username,
        noteData: noteData,
        noteName: noteName,
      });
      dispatchNotes({saved:true});
      setError('Success');
    } catch (err) {
      setError('Error adding Note data');
    }
  };

  return { addNote, error };
};

export default useAddNote;
// Importing necessary libraries and components/ first
import React, { useState, useEffect } from "react";
import ReactFlow, { Controls, Background } from 'reactflow';
import SendIcon from '@mui/icons-material/Send';
import SelectBox from "components/Inputs/SelectBox";
import { stockPrompts } from "content/chatGptPrompts";
import { Button, LinearProgress } from "@mui/material";
import OpenAI from 'openai';

// OpenAI API initialization
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPEN_AI,
  dangerouslyAllowBrowser: true 
});


export default function ChatGPT({ inputDefault, textField = true, showPrompt = true, showSubmit = true }) {
  const [input, setInput] = useState(inputDefault);
  const [completedCode, setCompletedCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [promptOptions, setPromptOptions] = useState(stockPrompts[0]);

  useEffect(() => {
    setInput(inputDefault);
  }, [inputDefault]);

  async function fetchData(message) {
    try {
      setLoading(true);
      const text = await openai.chat.completions.create({
        messages: [{ role: "system", content: message }],
        model: "gpt-3.5-turbo",
        max_tokens: 100
      });

      const responseText = text.choices[0].message.content.split("");
      setLoading(false);

      for (let i = 0; i < responseText.length; i++) {
        setTimeout(() => {
          setCompletedCode(responseText.slice(0, i + 1));
        }, i * 30);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setCompletedCode("An error occurred. Please try again.");
    }
  }

  function handleOptions(e, v) {
    setInput(v);
    setPromptOptions(v);
  }

  function handleClick() {
    if (input === "" && input) {
      setCompletedCode("Please ask me a question.");
    } else {
      fetchData(input);
    }
  }

  return (
    <div>
      {showPrompt && <SelectBox options={stockPrompts} value={promptOptions} onChange={handleOptions} />}
      <div style={{ display: "flex", marginTop: "1rem", gap: "0.2rem", width: "100%" }}>
        {textField && (
          <textarea
            value={input}
            onChange={(event) => setInput(event.target.value)}
            style={{ flex: 1 }}
            placeholder="Ask chatgpt..."
          />
        )}
        {showSubmit && <Button onClick={handleClick}>
          <SendIcon />
        </Button>}
      </div>
      {loading && <LinearProgress />}
      {completedCode && (
        <div>
          <p style={{ color: "black" }}>{completedCode}</p>
        </div>
      )}
    </div>
  );
}
import { useAppInfo } from 'AppState'
import { defaultFieldBlank, goalTypes } from 'appState/initialStateMyFinancials'
import CashflowChart from 'myFinancials/Cashflow/CashflowChart'
import FieldFormGen from 'myFinancials/FormFields/FieldFormGen'
import React, { useState } from 'react'

export default function GoalsProjectionEdit() {
    const {stateMyFinancials} = useAppInfo()
    const {cashflowDistribution,goalAnalytics,goalsPeriodic} = stateMyFinancials
  const {goalDist} = cashflowDistribution

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
    {goalDist && 
    <div style={{height:"500px"}}>
    <CashflowChart data={goalDist} title="Projected Goals"/></div>}
    <div style={{flex:1}}>
    <FieldFormGen showPriority 
    defaultField={defaultFieldBlank.Goals} 
    forecastPeriod={true} date={true} showType={true} 
    typeOptions={goalTypes} growthRate={true} period={true} 
    formName='Goals' storeName={"goalsPeriodic"} data={goalsPeriodic} categoryName='Goals'/>
    </div>
    </div>
  )
}

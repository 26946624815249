import React, { useEffect, useRef, useState } from 'react';
import { useAppInfo } from 'AppState';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import {ClickAwayListener, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import { handleObjectDetails } from '../utilsReport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ResizingBox from '../../components/DragResize/ResizingBox';
import {Settings } from '@mui/icons-material';
import ObjectSettings from '../ObjectSettings/ObjectSettings';

function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    let parentBox = elem.parentElement.getBoundingClientRect();
    return {
      top: box.top ,
      left: box.left,
      parentLeft : parentBox.left,
      parentTop:parentBox.top
    };
  }
function getAvailableSpace(elem) {
    const coordinates = getCoords(elem);
    const screenHeight = window.innerHeight;
    const spaceAtTop = coordinates.top;
    const spaceAtBottom = screenHeight - (coordinates.top + elem.offsetHeight);
    return { spaceAtTop, spaceAtBottom };
  }

  
export default function DragResizeObject ({children,  height = "100%", 
item,
pageIndex,
width = "100%",sx={} }) {
  const {stateReportBuilder,dispatchReportBuilder,stateStock} = useAppInfo()
  const {dragging,pageCurrent,pages,display} = stateReportBuilder;

  function handleDeleteIcon(id) {
    const updatedPages = pages.map(obj => {
      
      if (obj.PageIndex === pageCurrent) {
        // Update the PageContent for the matching object
        return {
          ...obj,
          PageContent: obj.PageContent.filter((item) => item.id !== id)
        };}
      return obj;
  })
    // Update the state with the modified pages array
    dispatchReportBuilder({"pages":updatedPages})

  }
  
  const dragMama = useRef(null);
  const isResizing = useRef(false);
  const isDragging = useRef(false);
  const initialPosV = useRef(0);
  const initialPosH = useRef(0);
  const initialSizeV = useRef(0);
  const initialSizeH = useRef(0);
  const initialFontSize = useRef();
  const [mouseEnter,setMouseEnter] = useState(false)
  
  const [mouseDown,setMouseDown]  = useState(false)
  let MARGIN = 5;

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleDragUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleDragUp);
    };
  }, [isDragging.current]);

function handleMouseDownResize(event) {
    dispatchReportBuilder({"objectInfocus":item})
   
      event.preventDefault()
      event.stopPropagation();

    var computedStyle = window.getComputedStyle(dragMama.current);
    initialFontSize.current = item.style
  ? parseInt(item.style.fontSize)
  : undefined

    var box = dragMama.current
    box.style.userSelect = "none"
    isResizing.current = true;
    // store the attributes of the WebBox at the time of the mousedown event.
    var computedStyle = window.getComputedStyle(box);
    
    let coordinates = getCoords(box);
    
    let b = {
        left: coordinates.left,    
        top: coordinates.top,
        height: parseInt(computedStyle.getPropertyValue('height')),
        width: parseInt(computedStyle.getPropertyValue('width')),
    }
  
    // store x and y as the distance between the cursor and the box's left and top.
    var x = (event.pageX ) - b.left;
    var y = (event.pageY )  - b.top;

    // var x = event.offsetLeft
    // var y = event.offsetTop
    
    // detect if the cursor is within marginal distance of the box's edges
    var onTopEdge = y <= MARGIN;
    var onLeftEdge = x <= MARGIN;
    var onRightEdge = x >= b.width - MARGIN;
    var onBottomEdge = y >= b.height - MARGIN;

    // determine whether we shift or resize
    var isResizingA = onRightEdge || onLeftEdge || onTopEdge || onBottomEdge;

    initialSizeV.current = b.height;
    initialSizeH.current = b.width;
   
    if(isResizingA) {

        function onMouseMoveResize(event) {
            // event.stopPropagation()
            event.preventDefault()
            if(onRightEdge) {
                dragMama.current.style.width =(event.clientX - b.left) + 'px';
            }
            if(onBottomEdge) {
                dragMama.current.style.height = (event.pageY  - b.top) + 'px'; 
            }

            if(onLeftEdge) {
              const newLeft = (event.pageX ) - coordinates.parentLeft;
              dragMama.current.style.left = newLeft + 'px'; // Adjusted the left position
                dragMama.current.style.width = (b.left - (event.pageX ) + b.width) + 'px';
            }
            if(onTopEdge) {
              const newTop = event.pageY  - coordinates.parentTop;
              dragMama.current.style.top = newTop + 'px';
                box.style.height = (b.top - (event.pageY ) + b.height) + 'px';
            }

            const newHeight = (event.pageY  - b.top) 
            let newStyle = {}
            if(initialFontSize && onBottomEdge && onRightEdge){
            const fontSizeRatio = (newHeight/b.height) * initialFontSize.current;
            newStyle ={fontSize :`${fontSizeRatio}px`};
            }
            const element = dragMama.current
            handleObjectDetails({newStyle,element,pages,pageIndex,item,dispatchReportBuilder})
            
        }
        function onMouseUpResize(event) {
          if (isResizing.current){
            // handleChanges(dragMama.current,pages,pageIndex,item,dispatchReportBuilder)}
            isResizing.current = false
            document.removeEventListener('mousemove', onMouseMoveResize);
            document.removeEventListener('mouseup', onMouseUpResize);
            
        }
        }
        document.addEventListener('mousemove', onMouseMoveResize);
        document.addEventListener('mouseup', onMouseUpResize);

    }
    else{
      dispatchReportBuilder({"draggingObject":false})
        isDragging.current = true;
        initialPosH.current = event.pageY ;
        initialPosV.current = event.pageX ;
    }


}



function handleDragMe(e) {
  // e.stopPropagation()
  if (e.defaultPrevented) return;
    // e.preventDefault()
    isDragging.current = true;
    initialPosH.current = e.clientY || e.touches[0].clientY;
    initialPosV.current = e.clientX || e.touches[0].clientX;
    dispatchReportBuilder({"objectInfocus":item,"draggingObject":true})
  }

  function handleMouseMove(e) {
    // e.stopPropagation()
    if (e.defaultPrevented) return;
    // e.preventDefault()
    // Add this line
if (isDragging.current) {
   dragMama.current.style.cursor = 'move';
   const pos1 = initialPosV.current - (e.clientX || e.touches[0].clientX);
   const pos2 = initialPosH.current - (e.clientY || e.touches[0].clientY);
   const element = dragMama.current;
   initialPosH.current = e.clientY;
   initialPosV.current = e.clientX;
   element.style.top = `${element.offsetTop - pos2}px`;
   element.style.left = `${element.offsetLeft - pos1}px`;
   
   }
   
}

  function handleDragUp(e) {
    // e.stopPropagation()
    dispatchReportBuilder({"draggingObject":false})
    if (isDragging.current){
    const element = dragMama.current
    handleObjectDetails({element,pages,pageIndex,item,dispatchReportBuilder})
    // handleChanges(dragMama.current,pages,pageIndex,item,dispatchReportBuilder)
  }
    isDragging.current = false;
    dragMama.current.style.cursor = 'auto';
  
   
  }
  const [rotate, setRotate] = useState(0);
  function handleMouseDownClick(){
    setMouseDown(true)
    dispatchReportBuilder({"objectInfocus":item,"draggingObject":true})
  }
  function handleZindex(type) {
    let zIndex;
    if (dragMama.current.childNodes.length > 0) {
      let maxChildIndex = -1;
      let minChildIndex = Infinity;
      Array.from(dragMama.current.childNodes).forEach(childNode => {
        const childZIndex = parseInt(window.getComputedStyle(childNode).zIndex, 10);
        if (type === "higher") {
          if (!isNaN(childZIndex) && childZIndex > maxChildIndex) {
            maxChildIndex = childZIndex;
          }
        } else {
          if (!isNaN(childZIndex) && childZIndex < minChildIndex) {
            minChildIndex = childZIndex;
          }
        }
      });
  
      if (maxChildIndex >= 0) {
        zIndex = maxChildIndex + 1;
      }
      
    }
    handleObjectDetails({ newStyle: { zIndex: zIndex }, pages, pageIndex, item, dispatchReportBuilder });
  }
  function handleDuplicate(){
    const timestamp = new Date().getTime();
    const uniqueId = `${item.Type}-${timestamp}`;
    const newItem = {...item,id:uniqueId,position:{...item.position,top:item.position.top+10,left:item.position.left+10}}

      const updatedPages = pages
    .map((obj) => {

      if (obj.PageIndex === pageCurrent) {
        // Update the PageContent for the matching object
        return {
          ...obj,
          PageContent: [
            ...(obj.PageContent || []), // Ensure PageContent is an array
            {
              ...newItem
            },
          ],
        };
      }
      // If the PageIndex doesn't match, return the original object
      return obj;
    })
    .sort((a, b) => a.PageIndex - b.PageIndex);
  
  dispatchReportBuilder({"pages":updatedPages });
  }
  
 
  function handleBorderMouseEnter(v){
    setMouseEnter(v)
    dispatchReportBuilder({"draggingObject":v})
  }
  const [objectSettings,setObjectSettings] = useState(false)

  return (
    <>
     <ClickAwayListener onClickAway={()=>setMouseDown(false)}>
      <div
      ref={dragMama}
        onClick={handleMouseDownClick}
        onMouseEnter={()=>handleBorderMouseEnter(true)}
        onMouseLeave={()=>handleBorderMouseEnter(false)}
        style={{
          position: 'absolute',
          boxSizing: "border-box",
          overflow:"visible",
          padding:"0rem",
          cursor:"move",
          border:(!stateReportBuilder.stopEdit && (mouseEnter || mouseDown))?"2px solid blue":"2px solid transparent",
          // background:"black",
          ...sx,
          width: width,
          height: height, // Spread the styles from the sx prop
        }}
      >
         
         {/* {(mouseDown) && <div style={{position:"absolute",left:"-53px",top:0,width:"50px"
         ,height:"100%",display:"flex",flexDirection:"column",alignItems:"center"}}>
          <Settings onClick={()=>setObjectSettings(true)} style={{color:"gray"}}/>
          </div>} */}
       


        {(mouseDown) &&
        <div style={{
                  position: 'absolute', 
                  top: getAvailableSpace(dragMama.current).spaceAtTop > 60 ? '-60px' : '',
                  bottom: getAvailableSpace(dragMama.current).spaceAtTop < 60 ? '-60px' : '',
                  boxShadow:"var(--boxShadowGeneral)",
                  right:"0%",
                  // transform:"translate(-50%,-50%)", 
                  borderRadius:"25px",
                  background:"white",
                  color:"gray",
                  zIndex:"100",
                  padding:"0.5rem",
                  display:"flex",justifyContent:"flex-end",width:"auto",gap:"1rem"}}>
              
                  <Tooltip title="Position Back"><FlipToBackIcon fontSize='small' onClick={()=>handleZindex("lower")}/></Tooltip>
                  <Tooltip title="Position Front">
                  <FlipToFrontIcon fontSize='small' onClick={()=>handleZindex("higher")}/></Tooltip>
                  <Tooltip title="Duplicate Content">
                  <ContentCopyIcon fontSize='small' onClick={handleDuplicate}/>
                  </Tooltip>
                  <Tooltip title="Settings">
                  <Settings fontSize='small' onClick={()=>setObjectSettings(true)}/>
                  </Tooltip>
                  <Tooltip title="Delete">
                  <CloseIcon
                  fontSize='small'
                  onClick={() => handleDeleteIcon(`${item.id}`)}/>
                  </Tooltip>

        {objectSettings && <ObjectSettings item={item} onClickAway={()=>setObjectSettings(false)}/>}
        </div>}
      {mouseDown &&
        <div style={{position: 'absolute', 
                  bottom: getAvailableSpace(dragMama.current).spaceAtBottom > 200 ? '0px' : '0',
                  left:"50%", 
                  boxShadow:"var(--boxShadowGeneral)",
                  background:"white",
                  zIndex:"100",
                  transform: 'translate(-50%,-50%)',
                  color:"gray",
                  padding:"0.5rem",
                  borderRadius:"50%",
                  cursor:"grab",
                  display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <OpenWithOutlinedIcon fontSize='small' onTouchStart={handleDragMe} onMouseDown={handleDragMe}/>
                  </div>}  
                    
                   
                    <div 
                    id="dragChildren"
                    onTouchStart={handleDragMe}
                    onTouchMove={handleMouseMove}
                    onTouchEnd={handleDragUp}  
                    onMouseDown={handleDragMe} 
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleDragUp}
                    style={{width:"100%",height:"100%",position:"relative",
                  }}>
                    
                    {children}
                    </div>
                    

        {(mouseDown && !stateReportBuilder.stopEdit) &&
        <>
        <ResizingBox handleMouseDownResize={handleMouseDownResize}/>

        </>}
      </div>
      </ClickAwayListener>
    </>
  );
};


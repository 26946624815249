import React, { useEffect, useRef, useState } from 'react'
import { getSpecificArrayObjKeys } from "utils/utilGeneral";

export default function StockMarketLine () {
    // const { close } = useMultiTickerInfo(["SPY"]);
    // // const [path, setPath] = useState("");
    // // const [predictorPath, setPredictorPath] = useState("");
  
    // // useEffect(() => {
    // //   if (close.length > 0) {
    // //     const { pathString, predictorPathString } = Path(close, "SPY");
    // //     setPath(pathString);
    // //     setPredictorPath(predictorPathString);
    // //   }
    // // }, [close]);
  
    const svgRef = useRef(null);

    useEffect(() => {
        const svg = svgRef.current;
        const pathElement = svg.querySelector('.stock-path');
        const predictorPathElement = svg.querySelector('.predictor-line');
  
        const animatePath = (element, delay = 0) => {
          const length = element.getTotalLength();
          element.style.strokeDasharray = length;
          element.style.strokeDashoffset = length;
  
          setTimeout(() => {
            element.style.transition = `stroke-dashoffset 10s linear`;
            element.style.strokeDashoffset = '0';
          }, delay * 1000);
        };
  
        animatePath(predictorPathElement, 0); // Predictor line starts first
        animatePath(pathElement, 2); // Stock path starts after the predictor line finishes
      
    }, []);
  
    return (
      <svg ref={svgRef} viewBox="0 0 800 400" 
      preserveAspectRatio="none" className="stock-market-line">
        <path className="predictor-line" 
        d="M0 300 Q 100 200 200 250 Q 300 300 400 200 Q 500 100 600 150 Q 700 200 800 100"
        stroke="var(--lightBlue)" fill="transparent" strokeWidth="2" />

          <path className="stock-path" 
                       d="M0 300 Q 100 200 200 250 Q 300 300 400 200 Q 500 100 600 150 Q 700 200 800 100"
        stroke="green" fill="transparent" strokeWidth="2"/>
      </svg>
    );
  };
  
  function Path(close, ticker) {
    const data = getSpecificArrayObjKeys(close, [ticker]).map((obj) => obj[ticker]);
  
    // Calculate the 200-day moving average
    const movingAverage = calculateMovingAverage(data, 20);
  
    // Use only the last 250 values for the stock path and moving average
    const recentData = data.slice(-250);
    const recentMovingAverage = movingAverage.slice(-250);
  
    const width = 800;
    const height = 400;
    const minValue = Math.min(...recentData);
    const maxValue = Math.max(...recentData);
    const xScale = width / (recentData.length - 1);
    const yScale = height / (maxValue - minValue);
  
    // Create an array of points for the sparkline
    const points = recentData.map((value, index) => {
      const x = index * xScale;
      const y = height - (value - minValue) * yScale;
      return { x, y, value };
    });
  
    // Create an array of points for the moving average line
    const movingAveragePoints = recentMovingAverage.map((value, index) => {
      const x = index * xScale;
      const y = height - (value - minValue) * yScale;
      return { x, y, value };
    });
  
    const pathString = `M${points.map(point => `${point.x},${point.y}`).join(' L')}`;
    const predictorPathString = `M${movingAveragePoints.map(point => `${point.x},${point.y}`).join(' L')}`;
  
    return { pathString, predictorPathString };
  }
  
  function calculateMovingAverage(data, period) {
    const result = [];
    for (let i = 0; i <= data.length - period; i++) {
      const sum = data.slice(i, i + period).reduce((acc, val) => acc + val, 0);
      result.push(sum / period);
    }
    return result;
  }
  
import Modal from 'components/Modal/Modal'
import React from 'react'

export default function PageSettingsModal({onClose,title="",children}) {
  return (
    <Modal height='auto' width='auto' onClose={()=>onClose(false)} sx={{borderRadius:"5px"}}>
<div style={{display:"flex",gap:"1rem",flexDirection:"column"}}>
<h2 style={{textAlign:"center",paddingBottom:"1rem"}}>{title}</h2>
{children}
</div>
    </Modal>
  )
}
import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useOptionsDerivatives(companySelected,selectedExpiry) {
    const [putOption, setPutInfo] = useState([]);
    const [callOption, setCallInfo] = useState([]);
    const [expiryDates, setExpiryDates] = useState([]);
    const[optionsLoading,setPriceLoading] = useState(true)
    useEffect(() => {
        getPriceInfo();
      }, [companySelected,selectedExpiry]);
    
      async function getPriceInfo() {
        setPriceLoading(true)
        try {
          const response = await axios(`${BASE_URL}/optionsDerivatives/`, {
            params: {
              ticker:  companySelected,
              expiry:selectedExpiry
            },
          });
        //   setPriceInfoCo(JSON.parse(responsePrice.data));
          const call = JSON.parse(response.data.callOption)
          const put = JSON.parse(response.data.putOption)
          const expDates = JSON.parse(response.data.expiryDates)
          setCallInfo(call)
          setPutInfo(put)
          setExpiryDates(expDates)
        } catch (error) {
          console.error(error);
        }finally{
            setPriceLoading(false)
        }
      }
  return {putOption,callOption,expiryDates,optionsLoading}
}

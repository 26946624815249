import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useESGscore(ticker) {
  const [esgScore, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://financialmodelingprep.com/api/v4/esg-environmental-social-governance-data?symbol=${ticker}&apikey=${financialModelingPrepApiKey}`
        );
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker) {
      fetchData();
    }
  }, [ticker]);

  return { esgScore };
}

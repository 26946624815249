import React, { useEffect, useState } from 'react'
import useEconSeries from 'data/economy/useEconSeries';
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo';
import TabsClose from 'components/Tabs/TabsClose';
import SelectBox from 'components/Inputs/SelectBox';
import ChartSynchronous from 'components/Charts/ChartSynchronous';
import LineChartMulti from 'components/Charts/LineChartMulti';
import { useAppInfo } from 'AppState';
import useEconInfo from 'data/economy/useEconInfo';
import EconomyMetricSelection from './EconomyMetricSelection';
import { MetricSettingsBar } from 'components/Template/MetricSettings';
import PageSettingsModal from 'components/Template/PageSettingsModal';
import useEconMultiSeries from 'data/economy/useEconMultiSeries';
import { intermarket } from 'content/contentMarket';
import TabLine from 'components/Tabs/TabLine';


export default function PriceVsEconomy({tickerType=["tickerSelected"], display="tickerSelected",infoName="Price vs Economy"}) {
    const {stateTicker} = useAppInfo()
    const [tickerSelected,setTickerSelected] = useState([])
    const [tabIndex,setTabIndex] = useState(0)
    const tabList = Object.values(intermarket)
    useEffect(()=>{
      if (display === "tickerSelected"){
        let tickerSel = [stateTicker.tickerSelected]
        if (!tickerType.includes("tickerSelected")){
          tickerSel = tickerType
        }
        setTickerSelected(tickerSel)
      }
      if (display === "marketTabs"){
        const key = Object.keys(intermarket).find(key => intermarket[key] === tabList[tabIndex]);
        setTickerSelected([key])
      }
     
    },[display,stateTicker.tickerSelected,tickerType,tabIndex])
  
    const {close,marketInfoLoading} = useMultiTickerInfo(tickerSelected)
    const [dataSet,setDataSet] = useState([])
    const {econInfo} = useEconInfo()
    const [defaultMetName,setDefaultMetName] = useState(["Federal Funds Effective Rate","M1"])
    // const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:stateTicker.tickerStockCode}) 
    const [priceCloseData,setPriceCloseData] = useState([])
    const [chartTypeEcon,setChartTypeEcon] = useState("area")
    const [chartTypePrice,setChartTypePrice] = useState("area")
    const [subTabIndex,setSubTabIndex] = useState(0)
    const [addInfo,setAddInfo] = useState(false)
    const [metricSelected,setMetricSelected] = useState(["Federal Funds Effective Rate","M1"])
    const [econId,setEconId] = useState("DFF")
    const [econIds,setEconIds] = useState([])
    const { econSeries, loadingEconSeries } = useEconSeries(econId);
    // const {econMultiSeries,loadingEconMultiSeries} = useEconMultiSeries(econIds)
  
    useEffect(()=>{
      if (econInfo.length>0){
       const econId = econInfo.filter(obj=>obj["Name"]===metricSelected[subTabIndex])[0]?.id
       setEconId(econId)
       const econIdUpdate = econInfo.filter(obj=>metricSelected.includes(obj["Name"])).map(obj=>obj.id)
       setEconIds(econIdUpdate)
      }
    },[subTabIndex,metricSelected,econInfo])

    useEffect(()=>{
        if (metricSelected.length>0 && econSeries && close.length>0){
        const marketData = {name:tickerSelected[0],data:transformDataForChart(close,"Date",tickerSelected[0])
          ,chartType:chartTypePrice}
        
        // const econMulitIndicator = convertDataForChart(econMultiSeries,econIds)

        const econSingleIndicator =  {name:econId,data:transformDataForChart(econSeries,"Date",econId),chartType:chartTypeEcon}
        const dataSet = [marketData,econSingleIndicator]
        setDataSet(dataSet)}
    },[metricSelected,econSeries,subTabIndex,defaultMetName,close,chartTypeEcon,chartTypePrice])

      

  function handleMetricChange(value) {  
    let metricSel;    
    if (Array.isArray(value)) {
      // If value is an array, set it as the new state directly
      metricSel = value
     
    } else {
      if (!metricSelected.includes(value)) {
       metricSel = [...metricSelected,value]
       
      } else {
        metricSel = metricSelected.filter((item) => item !== value)
      }
      
    }
    setMetricSelected(metricSel)
    
  }
  
  function handleDeleteTab(index) {
   
    setMetricSelected((prevItems) => {
      // Filter out the item at the specified index
      const updatedItems = prevItems.filter((_, i) => i !== index);
      return updatedItems;
    });
    // Update the tabList and tabContent state with the updated values
  }
  const [displayType,setDisplayType] = useState("Tabs")

  function handleDispalayType(v){
    setDisplayType(v)
  }

  const [chartSettings,setChartSettings] = useState(false)


  return (
    <div className='containerColumnFlexNoGap'>
    <MetricSettingsBar
     title={infoName}
     addMetric
     displaySettings
     handleAddMetric={()=>setAddInfo(true)}
     handleDisplaySettings={()=>setChartSettings(true)}
    />
    {display === "marketTabs" && 
    <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}/>
    }
    <div>
    <TabsClose
      handleSubTabChange={(v)=>setSubTabIndex(v)}
      subTabIndex={displayType==="Tabs"?subTabIndex:-1}
      tabList={metricSelected}
      tabContent={[]}
      deleteTab={true}
      handleDeleteTab={handleDeleteTab}
    ></TabsClose>
    </div>


    <div className='containerColumnFlex1'>
    {(dataSet.length>0 && displayType==="Single Chart") && 
    <LineChartMulti title='Price vs Economy' recession={true}  datasets={dataSet}/>
    }
    {(dataSet.length>0 && displayType==="Tabs") && 
    <LineChartMulti title='Price vs Economy' recession={true} datasets={dataSet}/>
    }
    {(displayType==="Multiple Charts" && dataSet.length>0) && 
      <ChartSynchronous
        datasets={dataSet}
      
      />}
    </div>


    {chartSettings && 
    <PageSettingsModal onClose={()=>setChartSettings(false)} title='Chart & Data Settings'>
    {/* <SelectBox width='200px' label={"Display Type"} options={["Tabs","Single Chart","Multiple Charts"]} value={displayType} onChange={(e,v)=>handleDispalayType(v)}></SelectBox> */}
    <SelectBox label={"Chart Price"} options={["line","area"]} value={chartTypePrice} onChange={(e,v)=>setChartTypePrice(v)}></SelectBox>
    <SelectBox label={"Chart Economy"} options={["line","area","column"]}
     value={chartTypeEcon} onChange={(e,v)=>setChartTypeEcon(v)}></SelectBox>
    </PageSettingsModal>
    }

    {addInfo && 
    <EconomyMetricSelection 
    onClose={()=>setAddInfo(false)}
    metricSelected={metricSelected} 
    handleMetricSelected={handleMetricChange}/>}
    

</div>
  )
}


function convertDataForChart(data, uniqueIndicators) {
  // Initialize the result array
  let result = [];

  // Loop through each unique indicator
  uniqueIndicators.forEach(indicator => {
      // Filter the data to get only entries for the current indicator
      let filteredData = data.filter(item => item.Indicator === indicator);

      // Map the filtered data to the desired structure
      let chartData = filteredData.map(item => ({
          date: new Date(item.Date).getTime(),
          value: item.Value
      }));

      // Push the structured data into the result array
      result.push({
          name: indicator,
          data: chartData,
          chartType: "line" // Example: default chart type, can be dynamic
      });
  });

  return result;
}

const transformDataForChart = (data, dateFieldName, valueFieldName) => {
    return data.map((item) => ({
      date: new Date(item[dateFieldName]).getTime(),
      value: item[valueFieldName],
      chartType: "line"
    }));
  };
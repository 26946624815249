import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import { formatNumber } from 'utils/utilGeneral';
import LoadingSkeletonPage from './Loading/LoadingSkeletonPage';

export default function ChartCard({loading=false,seeDetails=false,children, title, currentValue, description, link,unit}) {
  return (
    <Card 
      style={{ 
        width: '320px', 
        margin: '20px', 
        borderRadius: '5px', 
        overflow: 'hidden', 
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateY(-8px)';
        e.currentTarget.style.boxShadow = '0 14px 28px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateY(0)';
        e.currentTarget.style.boxShadow = '0 6px 16px rgba(0, 0, 0, 0.1)';
      }}
    >
      <Box 
        style={{
          padding: '16px',
          background: '#fafafa',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap:"wrap"
        }}
      >
        <Typography variant="p" style={{ fontWeight: 'bold', flex: 1 }}>{title}</Typography>
        <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginLeft: '8px' }}>{formatNumber(currentValue)}</Typography>
        <Typography variant='p' style={{fontSize:"0.9rem"}}>{unit}</Typography>
        </div>
        
      </Box>
      
      <div style={{
        height: "200px",
        width: "100%",
        overflow: "hidden",
        objectFit: "cover",
        transition: 'transform 0.3s ease',
        padding:"0.25rem",boxSizing:"border-box"
      }}>
        {loading && <LoadingSkeletonPage/>}
        {!loading && children}
      </div>
   
      <CardContent style={{ padding: '20px' }}>
        <Typography variant="body2" style={{ color: '#555', marginBottom: '12px' }}>{description}</Typography>
        {seeDetails && 
        <Button 
          href={link}
        >
          See Details
        </Button>}
      </CardContent>
    </Card>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PieChartGen = ({ data, metricKey, categoryKey,title="" }) => {
  const chartRef = useRef(null);
  const [seriesData,setSeriesData] = useState([])

  useEffect(() => {

      // Extracting unique categories
      const categories = [...new Set(data.map(item => item[categoryKey]))];

      // Creating series data
      const seriesData = categories.map(category => ({
        name: category,
        y: parseFloat(data.find(item => item[categoryKey] === category)[metricKey]),
      }));

      // Updating chart with new series data
      setSeriesData(
        [{
          type: 'pie',
          data: seriesData,
        }],
      );
  }, [data, metricKey, categoryKey]);

  const options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: title,
    },
    series:seriesData,
    credits: {
        enabled: false, // Hide Highcharts.com credits
      },
    exporting:{
      enabled:false
    }
  };

  return (
  <div style={{height:"100%",overflowY:"auto",width:"100%"}}>
      
  <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} 
  containerProps={{ style: { height: '100%',width:"100%"} }}/>

</div>)

};

export default PieChartGen;
import { Button } from '@mui/material'
import React, { useState } from 'react'
import ThreePIcon from '@mui/icons-material/ThreeP';
import Modal from 'components/Modal/Modal';
import ComingSoon from 'components/ComingSoon';

export default function FinancialAdvisorConnect({variant="highlight",sx={}}) {
  const [modal,setModal] = useState(false)
  return (
    <>
     <Button 
     onClick={()=>setModal(true)}
    sx={{justifyContent:"flex-start",...sx}}
    startIcon={<ThreePIcon/>} 
    variant={variant}>Connect with Financial Advisor</Button>

    {modal && <Modal onClose={()=>setModal(false)}>
        <ComingSoon name={"Connect with Financial Advisor is coming soon!"}/>
        </Modal>}
    </>
   
  )
}
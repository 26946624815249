import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useEconReportOverview() {
    const [econReportOverview, setEconInfo] = useState({});
    useEffect(() => {
        getMetricInfo();
      }, []);
    
      async function getMetricInfo() {
        try {
          const responseData = await axios(`${BASE_URL}/econReportOverview/`);
          setEconInfo(responseData.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {econReportOverview}
    
}

import React, { useEffect, useState } from 'react'
import useScreenerForex from 'data/screener/useScreenerForex'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import { colsScreener } from 'content/constants'
import ScreenerTemplate from './ScreenerTemplate'
import { applyFilters } from './utilsScreener'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'


export default function ForexScreener() {
  const {stateScreener} = useAppInfo()
  
  useScreenerForex()
  
  const [filteredData,setFilteredData] = useState([])
  useEffect(()=>{
   if (stateScreener.forex && stateScreener.forex.length>0 && !stateScreener.loadingForex){
   const data = getSpecificArrayObjKeys(stateScreener.forex,colsScreener)
   const filteredData =  applyFilters(stateScreener.filtersForex,data)
   setFilteredData(filteredData)
 
  }
  },[stateScreener.forex])
  function handleFilterUpdate(v){
    setFilteredData(v)
  } 
  return (
    <>
    
    <ScreenerTemplate loading={(stateScreener.forex.length>0 && !stateScreener.loadingForex)} filterName={"filtersForex"} data={getSpecificArrayObjKeys(stateScreener.forex,colsScreener)} handleFilterUpdate={handleFilterUpdate}>
    {stateScreener.loadingForex && <LoadingSkeletonTable length={50}/>}
    {(!stateScreener.loadingForex && filteredData.length>0) && <TableGen data={filteredData}/>}
    </ScreenerTemplate>
    </>

    
  )
}

import { formatNumber } from "utils/utilGeneral";
import * as XLSX from "xlsx";

const calculateGradientColor = (value, min, max,colorGradient,thresholdValue) => {
    // Normalize the value to be between 0 and 1 within the range [min, max]
    const normalizedValue = (value - min) / (max - min);
    let r = Math.round(255 * (1 - normalizedValue)); // Red component
    let g = Math.round(255 * normalizedValue); // Green component
    const b = 0; // Blue component (0 for pure yellow)


    if (colorGradient === "threshold") {
      // Gradient based on thresholds
      if (value >= thresholdValue) {
          // Ensure green is not too dark
          g = Math.round(255 * (1 - normalizedValue * 0.5));
          // Set red component to 0 for green color
          r = 0;
      } else {
          // Ensure red is not too dark
          r = Math.round(255 * (1 - normalizedValue));
          // Set green component to 0 for red color
          g = Math.round(255 * (normalizedValue));
      }
  } 
 
  if (colorGradient === "maxMin") {
    r = Math.round(255 * normalizedValue); // Red component
    g = Math.round(255 * (1 - normalizedValue)); // Green component
  }
 

    // Calculate the R, G, and B components/ based on the normalized value

    return `rgb(${r},${g},${b})`;
  };

const calculateMinMax = (data) => {
    let minValue = Infinity;
    let maxValue = -Infinity;

    data.forEach((item) => {
      for (const key in item) {
        if (typeof item[key] === "number" && !isNaN(item[key])) {
          minValue = Math.min(minValue, item[key]);
          maxValue = Math.max(maxValue, item[key]);
        }
      }
    });

    return { minValue, maxValue };
  };

  const formatValue = (value) => {
    if (typeof value === "string") {
      const timestamp = parseFloat(value);
      if (!isNaN(timestamp)) {
        const date = new Date(timestamp);
        if (!isNaN(date.getTime())) {
          // If the string represents a valid Unix timestamp, format as a date
          if (timestamp > 1000000000) {
            return date.toLocaleDateString("en-US");
          }
        }
      }
    } else if (typeof value === "number") {
      return formatNumber(value)
      // const absoluteValue = Math.abs(value);
      // if (absoluteValue > 1000) {
      //   // Format numbers with absolute values greater than 1000 with commas
      //   return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // } else if (absoluteValue < 1000) {
      //   // Round numbers with absolute values below 100 to 2 decimal places
      //   return value.toFixed(2);
      // }
    }
    else if (value instanceof Date) {
        const dateValue = new Date(value);
        const options = {
          day: "2-digit",
          month: "short",
          year: "numeric",
        };

        if (!isNaN(dateValue.getTime())) {
          value = dateValue.toLocaleDateString(
            undefined,
            options
          );
        }

    }

    // If not a valid date or number, return the original value
    return value;
  };


  const formatKey = (key) => {
    // Convert camelCase to Capitalized with spaces
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const standardizedData = (data) => {
    if (!data || !Array.isArray(data)) {
      return []; // Return an empty array if data is not an array or is undefined
    }

    return data
      .map((item) => {
        const ticker = item.Ticker || item.TICKER || item.symbol;
        if (ticker) {
          return { ...item, Ticker: ticker };
        } else {
          return { ...item };
        }
      })
      .map((item) => {
        delete item.TICKER;
        delete item.symbol;

        // Format the keys for each item in the array
        const formattedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            // Check if the key exists in the object
            const formattedKey = formatKey(key);
            let formattedValue = item[key];

            if (/\bdate\b/i.test(formattedKey) && formattedValue) {
              // Attempt to convert the value to a Date object
              const dateValue = new Date(formattedValue);
              const options = {
                day: "2-digit",
                month: "short",
                year: "numeric",
              };

              if (!isNaN(dateValue.getTime())) {
                formattedValue = dateValue
                // formattedValue = dateValue.toLocaleDateString(
                //   undefined,
                //   options
                // );
              }
            }

            formattedItem[formattedKey] = formattedValue;
          }
        }

        return formattedItem;
      });
  };

  const handleXLDownload = (wbName="Table") => {
    const ws = XLSX.utils.table_to_sheet(document.getElementById("tableGen"));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${wbName}.xlsx`);
  };
  function containsLink(str) {
    // Define a regular expression pattern
    var regex = /http:\/\/|https:\/\/|www\./;
   
    // Test the string against the regular expression
    return regex.test(str);
  }

  const resizeColumn = (e, key) => {
    e.stopPropagation()
    e.preventDefault(); // Prevent default selection behavior
  
    const th = e.currentTarget; // Use e.currentTarget instead of e.target
    const originalX = e.clientX; // Original mouse X position
    const initialWidth = th.offsetWidth; // Initial column width
  
    // Function to handle the mousemove event
    const handleMouseMove = (e) => {
      const width = initialWidth + e.clientX - originalX; // Calculate new width
      th.style.width = `${width}px`; // Set the new width
    };
  
    // Function to handle the mouseup event
    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  export const tableGrowthRate = ({ row, key, index,type="sub"}) => {
    // Helper function to calculate growth rate
    const calculateGrowthRate = (currentValue, previousValue) => {
      if (typeof currentValue === "number" && typeof previousValue === "number" && index !== 0) {
        const growth = ((currentValue / previousValue - 1) * 100).toFixed(2);
        return isNaN(growth) || !isFinite(growth) || Number(growth) === 0 ? '-' : `${growth}%`;
      }
      return null;
    };
  
    // Get the growth rate
    const growthRate = calculateGrowthRate(row[key], row[Object.keys(row)[index - 1]]);
    
    // Determine the color
    const growthColor = growthRate !== '-' && parseFloat(growthRate) > 0 ? 'green' : 'red';
  
    return (
      <>
        {growthRate && (
          <>
            {type !=="main" && <br />}
            <span style={{ fontSize: type==="main"?"1rem":"0.7rem", color: growthColor }}>
              {` ${growthRate}`}
            </span>
          </>
        )}
      </>
    );
  };


export {resizeColumn,calculateGradientColor,calculateMinMax,formatKey,standardizedData,formatValue,handleXLDownload,containsLink}


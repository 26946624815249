import { Alert, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "components/Inputs/TextInput";
import SelectBox from "components/Inputs/SelectBox";
import { useAppInfo } from "AppState";
import useRealTimePrice from "data/eod/useRealTimePrice";
import { dfin } from "content/constants";
import TechnicalChart from "components/Charts/TechnicalChart";
import { generateUniqueId } from "utils/utilGeneral";
import useAddPortfolio from "data/user/portfolio/useAddPortfolio";
import { createPortal } from "react-dom";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PortfolioAccountSelection from "myFinancials/Portfolio/PortfolioAccountSelection";


export default function Orders() {
  const { stateTicker, statePortfolio, dispatchPortfolio, stateApp} = useAppInfo();

  const { tickerSelInfo } = stateTicker;
  const { accountNameSel, accountNames, portfolio,} = statePortfolio;
  const { price } = useRealTimePrice({
    tickerSel: tickerSelInfo?.[dfin.tickerCode],
  });
  const navigate = useNavigate()
  const [orderType, setOrderType] = useState("Market");
  const [quantity, setQuantity] = useState(100);
  const [priceSel, setPrice] = useState();
  const [transacactionValid, setTransactionValid] = useState(false);
  const [transacactionType, setTransactionType] = useState("Buy");


  useEffect(() => {
    setPrice(price?.close);
  }, [price]);

  // useEffect(()=>{
  //   setTimeout(()=>{setTransactionValid(false)},5000)
  // },[transacactionValid])



  const { addPortfolio } = useAddPortfolio({ dispatchPortfolio, stateApp });
 


  function handleAddToPortfolio(transactionType = "Buy") {
    const transactionDetails = {
      id: generateUniqueId(),
      investmentType: tickerSelInfo?.["TYPE"],
      transactionType: transactionType,
      date: new Date(),
      quantity,
      price: priceSel,
      ...tickerSelInfo,
    };
    const accountDetails = [transactionDetails];

    let updatedTransactions = [];
    // Check if the account already exists in the portfolio
    const existingAccount = portfolio ? portfolio[accountNameSel] : undefined;
    if (existingAccount) {
      updatedTransactions = [...existingAccount, ...accountDetails];
    } else {
      updatedTransactions = [...accountDetails];
    }

    const updatedPortfolio = {
      ...portfolio,
      [accountNameSel]: updatedTransactions,
    };

    addPortfolio(accountNameSel, accountDetails);
    dispatchPortfolio({
      portfolio: updatedPortfolio,
      portfolioSel: updatedTransactions,
      addTransaction: false
    });
  }

  function handleTransaction(type) {
    handleAddToPortfolio(type);
    setTransactionValid(true);
    setTransactionType(type);
  }

  function handleNavigatePortfolio(){
    navigate("/Trade/Transactions")
  }

  return (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",padding:"0.25rem"
      }}
    >
      <Paper
        style={{
          flex: 1,
          padding: "0.25rem",
          boxSizing: "border-box",
          minWidth: "0",
          display:"flex",flexDirection:"column"
        }}
      >
     <TechnicalChart />
      </Paper>
      <Paper
        style={{
          width: "25%",
          height: "100%",
          padding: "1rem 0.25rem",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          gap: "1rem",
        }}
      >
        <PortfolioAccountSelection/>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flex: 1,
          }}
        >
          <TextInput
            variant="outlined"
            type="number"
            min={0}
            label="Quantity"
            value={100}
          />
          <SelectBox
            variant="outlined"
            size="large"
            label={"Order Type"}
            options={["Market", "Limit"]}
            value={orderType}
            onChange={(e, v) => setOrderType(v)}
          />
          {orderType === "Limit" && (
            <TextInput
              variant="outlined"
              type="text"
              min={0}
              label="Price"
              value={priceSel}
              onChange={(v) => setPrice(v)}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            position: "sticky",
            bottom: "0",
            zIndex: "10",
            background: "white",
          }}
        >
          <Button onClick={() => handleTransaction("Buy")} variant="contained">
            Buy
          </Button>
          <Button
            onClick={() => handleTransaction("Sell")}
            variant="contained"
            sx={{
              background: "red",
              "&:hover": {
                background: "red", // Maintain red background on hover
              },
            }}
          >
            Sell
          </Button>
        </div>
      </Paper>
      {transacactionValid &&
        createPortal(
          <Paper
            style={{
              background: "var(--bgWoodSmoke)",
              position: "absolute",
              zIndex: "1000",
              left: "60px",
              bottom: 0,
              height: "auto",
              width: "400px",
              padding: "1rem",
              display:"flex",flexDirection:"column",gap:"1rem"
            }}
          >
            <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
              <Typography sx={{color:"white"}}>Transaction Successful</Typography>
              <IconButton sx={{marginLeft:"auto"}} onClick={()=>setTransactionValid(false)}><Close sx={{color:"#F8F8F8"}}/></IconButton>
            </div>
            <Alert severity="success">
              {transacactionType === "Buy" ? "Bought" : "Sold"} {quantity} at ${priceSel}
            </Alert>
            <Button variant="contained" onClick={handleNavigatePortfolio}>See Transactions</Button>
          </Paper>,
          document.body
        )}
    </div>
  );
}

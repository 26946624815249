import React from 'react'
import AssetDetails from './AssetDetails'
import AssetAllocation from './AssetAllocation'
import AssetStepsToGrow from './AssetStepsToGrow'
import AssetProjections from './AssetProjections'
import AccordionTSCustom from 'components/Surface/AccordionTSCustom'

export default function AssetOverview() {
  return (
    <AssetDetails/>
   
  )
}
 // <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",flex:1}}>
    // <div style={{minWidth:"400px",flex:1,display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    // {/* <AssetAllocation chartHeight='300px' showDetails={false}/> */}
    // {/* <AssetProjections chartHeight='400px' showField={false}/> */}
    // </div>
    // <div style={{flex:1,minWidth:"400px",display:"flex",flexDirection:"column",gap:"1rem"}}>
    // {/* {tabContent[tabList[tabIndex]]} */}
    // <AssetDetails/>
    // </div>
    
    // </div>
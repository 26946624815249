import React,{useState,useEffect,useRef} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

export default function useIndustryOverview() {
    const {stateIndustry,dispatchIndustry} = useAppInfo()
    const [error, setError] = useState(null);

    useEffect(()=>{
      if (stateIndustry.industrySel && stateIndustry.countrySel){
        getData()}
    },[stateIndustry.industrySel,stateIndustry.countrySel])
    
    
    async function getData() {
      
      try {
        dispatchIndustry({"loadingIndOverview":true})
        const response = await axios.get(`${BASE_URL}/industryOverview/`, {
          params: {
            industry: JSON.stringify(stateIndustry.industrySel),
            country:JSON.stringify(stateIndustry.countrySel)
          },
        });

        dispatchIndustry({"indOverview":response.data})
      } catch (error) {
        setError(error);
      } finally {
        dispatchIndustry({"loadingIndOverview":false})
      }
    }
  
}
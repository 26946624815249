import React from 'react';

import TimeSeriesChart from 'components/Charts/TimeSeriesChart';
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine';
import TableGen from 'components/Table/TableGen';

import { useAppInfo } from 'AppState';
import usePriceChartEod from 'data/eod/usePriceChartEod';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';

export default function EtfMfProfile({generalData}) {
  const {stateTicker} = useAppInfo()
  const {priceInfo,loadingPriceInfo} = usePriceChartEod({tickerSel:stateTicker.tickerETFCode})

    const primaryInfo = generalData && getSpecificArrayObjKeys(generalData, primary_info).flatMap(obj => {
        return Object.entries(obj).map(([key, value]) => {
            const table = {};
            table["Metric"] = key;
            table["Value"] = value;
            return table;
        });
    });
    
    const tRating = generalData && getSpecificArrayObjKeys(generalData, technicalIndicators).flatMap(obj => {
      return Object.entries(obj).map(([key, value]) => {
          const table = {};
          table["Metric"] = key;
          table["Value"] = value;
          return table;
      });
  });

  return (
    <>
    
    
    <div style={{width:"100%",height:"100%",display:"flex",gap:"0.5rem",boxSizing:"border-box"}}>
    <div
          style={{
            border: "2px solid #F8F8F8",
            borderRadius: "5px",
            padding: "0.25rem",
            width: "50%",
            minWidth:"300px",
            boxSizing: "border-box",
          }}
        >
          <div style={{width:"100%",flex:1,height:"100%"}}>
          <div style={{display:"flex",flexDirection:"column",width:"100%",minHeight:"350px"
          ,height:"40%",border:"1px solid #F8F8F8",padding:"0.2rem",boxSizing:"border-box",background:"#F8F8F8",borderRadius:"5px"}}>
          <div style={{flex:1,width:"100%"}}>
          {!priceInfo.length > 0 && loadingPriceInfo && <LoadingSkeletonLine length={1} width="100%" height="100%"/>}
          {priceInfo.length > 0 && !loadingPriceInfo && (
              <TimeSeriesChart
                chartType='line'
                datePicker={false}
                seriesName="adjusted_close"
                data={getSpecificArrayObjKeys(priceInfo, [
                  "date",
                  "adjusted_close",
                ])}
              />
            )}
          </div>
          {/* <div style={{float:"right",marginLeft:"auto"}}><Button onClick={handleChart}><FullscreenIcon/></Button></div> */}
          </div>
    
    <div style={{flex:1}}>
   
    <TableGen tableLayout='auto' data={tRating}/>
    </div>
          </div>
          
          </div>
    {/* <div style={{width:"100%",height:"50%",minHeight:"300px"}}>
      {priceInfo.length>0 && !loadingPriceInfo && 
      <TimeSeriesChart  
      seriesName="adjusted_close" data={getSpecificArrayObjKeys(priceInfo,["date","adjusted_close"])}/>}
      </div> */}
    <div style={{width:"50%"}}>
    <div style={{flex:1}}>
   
   <TableGen tableLayout='auto' data={primaryInfo} tHead={false} pagination={false}/>
   </div>
    
    <div style={{border:"3px solid #F8F8F8"
     ,borderRadius:"5px",width:"100%",boxSizing:"border-box",flexWrap:"wrap",padding:"0.3rem"}}>
     <h3 style={{color:"gray",fontWeight:"bold"}}>Description</h3>
     
     <p style={{width:"100%"}}>{generalData[0]?.["DESCRIPTION"]}</p>
     </div>
     </div>
    </div>
    </>
  )
}


const primary_info = [
  "NAME",
  "TICKER",
  "COMPANY NAME",
  "COMPANY URL",
  "COUNTRY NAME",
  "CURRENCY SYMBOL",
  "DIVIDEND PAYING FREQUENCY",
  "DOMICILE",
  "ETF URL",
  "EXCHANGE",
  "CATEGORY",
  "HOLDINGS COUNT",
  "INCEPTION DATE",
  "ISIN",
  "NET EXPENSE RATIO",
  "OPEN FIGI",
  "TOTAL ASSETS",
  "YIELD",
  "MAX ANNUAL MGMT CHARGE",
]

const technicalIndicators= [
  "MORNING STAR CATEGORY BENCHMARK",
  "MORNING STAR RATIO",
  "MORNING STAR SUSTAINABILITY RATIO",
  "RETURNS 1Y",
  "RETURNS 3Y",
  "RETURNS 5Y",
  "RETURNS 10Y",
  "RETURNS YTD",
  "1Y VOLATILITY",
  "3Y EXP RETURN",
  "3Y SHARP RATIO",
  "3Y VOLATILITY",
  "50DAY MA",
  "52WEEK HIGH",
  "52WEEK LOW",
  "200DAY MA",
  "ANNUAL HOLDINGS TURNOVER",
  "AVERAGE MKT CAP MIL",
  "BETA",
]


import React from 'react'
import { useAppInfo } from 'AppState'
import IndSecMetricSummary from './IndSecMetricSummary'

export default function IndSecTechnicals() {
  const {stateIndustry} = useAppInfo()

  return (
        <IndSecMetricSummary
        infoName={"Technical Summary"}
        defaultMet={["TECHNICAL OVERALL SCORE","MOVING AVERAGES OVERALL SCORE","OSCILLATORS OVERALL SCORE"]}
        chartTypeDefault='Bar' data={stateIndustry.constiInfoAll}/>
  )
}
import React from 'react';
import { formatNumber } from 'utils/utilGeneral';

const RangeBar = ({ min, max, average, value, name }) => {
  // Calculate percentage position of value on the color bar
  const percent = ((value - min) / (max - min)) * 100;

  // Determine color based on whether the value is greater than the average
  const color = value > average ? 'green' : 'red';

  return (
    <div style={{ position: 'relative', width: '100%', height: '25px' }}>
      {/* Color bar */}
      <div
        style={{
          width: '100%',
          height: '10px',
          backgroundColor: 'lightgray',
          position: 'relative',
          borderRadius: "25px"
        }}
      >
        {/* Range */}
        <div
          style={{
            position: 'absolute',
            left: '0',
            bottom: '10px',
            // transform: 'translateY(-50%)',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            color: 'gray',
            fontSize: '12px',
          }}
        >
          <span>Min: {formatNumber(min)}</span>
          <span>Avg: {formatNumber(average)}</span>
          <span>Max: {formatNumber(max)}</span>
        </div>
        {/* Value line */}
        <div
          style={{
            position: 'absolute',
            left: `${percent}%`,
            top: '0',
            height: '20px',
            width: '2px',
            backgroundColor: 'black',
          }}
        ></div>
        {/* Colored bar */}
        <div
          style={{
            position: 'absolute',
            left: '0',
            borderRadius: "25px 0 0 25px",
            top: '0',
            height: '100%',
            width: `${percent}%`,
            backgroundColor: color,
            opacity: '1', // Adjust opacity as needed
          }}
        ></div>
      </div>
      {/* Value label */}
      <div
        style={{
          position: 'absolute',
          bottom: '-10px',
          left: `${percent}%`,
          transform: 'translateX(-50%)', // Center horizontally
          backgroundColor: 'white',
          padding: '2px 5px',
          borderRadius: '5px',
          border: '1px solid black',
          whiteSpace:"nowrap",fontSize:"0.8rem"
        }}
      >
        {name}: {formatNumber(value)}
      </div>
    </div>
  );
};

export default RangeBar;



import { pageColumns, scatterMetrics, screenFilters } from "content/contentScreenerStocks"

const pageHeader = Object.keys(pageColumns)

const initialStateScreener = {
    screenerType:"Stocks",
    stocks:[],
    crypto:[],
    etf:[],
    forex:[],
    bonds:[],
    loadingETF:true,
    loadingCrypto:true,
    loadingStocks:true,
    loadingForex:true,
    filterData: [],
    pageData:[],
    tableData:[],
    currentPage: 1,
    pageHeaderSel:Object.keys(pageColumns)[0],
    pageColumns:pageColumns,
    scatterMetrics:scatterMetrics,
    scatterMetricsSel:scatterMetrics[pageHeader[0]],
    columns:pageColumns[pageHeader[0]],
    tickerList:[],
    screenFilters:screenFilters,
    filters: {},
    filtersForex:{},
    filtersCrypto:{},
    filtersBond:{},
    filtersETF:{},
    sortedColumn:null,
    sortDirection:"asc",
    totalPages:1,
  quickScreenSel:[],
  screenerNameSel:undefined,
  screenerNamesUser:[],
  screenerDataUser:[],
  filterModal:false,
  quickScreenModal:false,
  createScreen:false,
  dataView:"Table"
  }
  
  export {initialStateScreener}
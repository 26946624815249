import axios from "axios";
import { BASE_URL } from "apiConfig";
import { useAppInfo } from "AppState";
import { useEffect } from "react";

export default function useScreenerStocks() {
  const { dispatchScreener, stateScreener } = useAppInfo()

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      dispatchScreener({"loadingStocks":true });

      const response = await axios.get(`${BASE_URL}/screenerStocks/`, {
        params: {
          ticker: JSON.stringify(stateScreener.tickerList),
          page: 1,
        },
      });
      const data = response.data;
      dispatchScreener({"stocks":data });
    } catch (error) {
      console.error(error);
    } finally {
      dispatchScreener({"loadingStocks":false });
    }
  }

}

import { Skeleton } from '@mui/material'
import React from 'react'

export default function LoadingSkeletonHeatmap() {
  return (
    <div style={{display:"flex",width:"100%",height:"100%",gap:"0.5rem",boxSizing:"border-box"}}>
        <Skeleton variant='rectangle' width={"20%"} height={"100%"} />
        <div style={{height:"100%",width:"40%",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
        <Skeleton variant='rectangle' width={"100%"} height={"40%"} />
        <Skeleton variant='rectangle' width={"100%"} height={"30%"} />
        <Skeleton variant='rectangle' width={"100%"} height={"30%"} />
        </div>
        
        <div style={{height:"100%",width:"40%",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
        {
   Array.from({ length: 5 }).map((_, index) => (
        <Skeleton variant={"rectangle"} key={index} width={"100%"} height={"20%"}/>))}
        </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import Histogram from 'components/Charts/Histogram'
import { dfin } from 'content/constants'
import RangeBar from './RangeBar'
import { Button, Tooltip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingSkeletonLine from 'components/Loading/LoadingSkeletonLine'
import useCalculateCompanyVsIndustryStatistics from './useCalculateCompanyVsIndustryStatistics'
import useCompanyVsIndustry from 'data/company/useCompanyVsIndustry'

export default function CompanyVsIndustry({coInfo,metric,histogram=false,width,type="Industry"}) {
  const tickerStock = coInfo?.[dfin.ticker]
  const {performance,summary} = useCalculateCompanyVsIndustryStatistics({coInfo:coInfo,metric:metric,type:type})
  const [expand,setExpand] = useState(histogram)
  const [mouseEnter,setMouseEnter] = useState(false)
  const histogramComment = `This is a distribution of ${type} - Yellow highlighlights where the company's metric lies in the ${type} `
  return (
    <div onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)} 
    style={{width:"100%",borderBottom:"1px solid #eeeeee",boxSizing:"border-box",background:mouseEnter && "#eeeeee"}}>
    <>
   <div  onClick={()=>setExpand(!expand)} style={{display:"flex",width:"100%",gap:"1rem"
   ,justifyContent:"center",padding:"1rem",boxSizing:"border-box",alignItems:"center",flexWrap:"wrap"}}>
    <Tooltip title={metric}>
    <h4 style={{width:"25%",minWidth:"150px",overflow:"hidden",whiteSpace:"nowrap"
      ,textOverflow:"ellipsis",flex:1}}>{metric}</h4></Tooltip>
    
    <div style={{flex:2,minWidth:"400px"
      ,display:"flex",alignItems:"center",gap:"2rem"}}>
    {performance.length === 0 && <LoadingSkeletonLine variant='rectangle'/>}
    {performance.length>0 &&
    <><RangeBar min={summary.Min} max={summary.Max} average={summary.Mean} value={summary.Company} name={tickerStock}/>
    <h4 style={{minWidth:"80px",paddingLeft:"1rem"}}>Percentile:{summary.Percentile}</h4>
    <Button sx={{marginLeft:"auto"}} onClick={()=>setExpand(!expand)}>
      {expand && <ExpandLessIcon/>}
      {!expand && <ExpandMoreIcon/>}
      </Button></>}
    </div>
    </div>
    {expand && 
    <div style={{width:"100%",hieght:expand?"50%":"auto"}}>
    {expand &&
    <Histogram data={performance} highlightNumber={summary?.Company} comment={
      histogramComment
    }/>}
   
    </div>}
    </>
    </div>
  )
}
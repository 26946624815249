import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { financialModelingPrepApiKey } from '../keys';

export default function useRevenueSegmentGeo(ticker) {
  const [revenueSegmentGeo, setRevenueSegment] = useState([]);

  useEffect(() => {
    const fetchRevenueSegment = async () => {
      try {
        const responseMetric = await axios.get(
          `https://financialmodelingprep.com/api/v4/revenue-geographic-segmentation?symbol=${ticker}&structure=flat&period=annual&apikey=${financialModelingPrepApiKey}`
        );
        const data = responseMetric?.data
        setRevenueSegment(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (ticker) {
      fetchRevenueSegment();
    }
  }, [ticker]);

  return { revenueSegmentGeo };
}
import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import { getSpecificArrayObjKeys } from "utils/utilGeneral"
import TableGen from 'components/Table/TableGen'
import { dfin, technicalIndicators, technicalRatings } from 'content/constants'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function IndSecConstituentsTechnicals() {
  const {stateIndustry} = useAppInfo()
  const [performance,setPerformance] = useState([])

  useEffect(()=>{
    const PerfScreen = getSpecificArrayObjKeys(stateIndustry.constiInfo,[dfin.coLogo,dfin.ticker,dfin.coName,...technicalIndicators,...technicalRatings])
    
    setPerformance(PerfScreen)
   
  },[stateIndustry.constiInfo])
  useEffect(()=>{

  },[])

  return (
    <>
    
    {!performance.length>0 && <LoadingSkeletonTable length={30}/>}
    {performance.length>0 &&
    <TableGen pagination={false} addToWatchlist={true} 
    logo={true} tableLayout='auto' data={performance} perfCols={[dfin.performance1D,dfin.performance1M,dfin.performance3M,dfin.performance6M,dfin.performance1Y]}/>
    }
    </>
  )
}

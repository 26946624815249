import React, { useState } from 'react'
import Lottie from 'lottie-react';
import animationFinancials from './FinancialsAnim.json';
import animationPortfolio from './Portfolio.json';
import animationAnalytics from './AnalyticsAnim.json';
import animationLearn from './LearnAnim.json';
import animationTrade from './TradeAnim.json';
import animationGoals from './GoalsAnim.json';
import animationSearching from "./SearchingDocument.json"
import animation404 from "./404.json"
import comingSoon from "./ComingSoon2.json"
import animatioPortfolioFit from "./PortfolioFitAnim.json"
import animationChart from "./Chart.json"
import animationHouse from "./House.json"
import animationMoney from "./Money.json"
import animationTravel from "./Travel.json"
import animationEducation from "./EducationBig.json"
import animationRetirement from "./Retirement.json"


export default function Animation({ type = "Financials",loop=true,autoplay=true}) {
  const [loopOnHover,setLoopOnHover] = useState(false)

  const info = {
    "Financials": animationFinancials,
    "Portfolio": animationPortfolio,
    "Analytics": animationAnalytics,
    "Learn": animationLearn,
    "Knowledge":animationLearn,
    "Trades": animationTrade,
    "Goals": animationGoals,
    "Searching":animationSearching,
    "Opportunities":animationSearching,
    "404":animation404,
    "Coming Soon":comingSoon,
    "PortfolioFit":animatioPortfolioFit,
    "Chart":animationChart,
    "Home":animationHouse,
    "Money":animationMoney,
    "Travel":animationTravel,
    "Education":animationEducation,
    "Retirement":animationRetirement,
  };

  return (
    <Lottie 
        animationData={info[type]} 
        loop={loop} 
        autoplay={autoplay} 
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      />
  );
}


import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useCompanyNews(companySelected) {
    const [newsCo, setNewsCo] = useState([]);
    const[newCoLoading,setNewsLoading] = useState(false)
    useEffect(() => {
        getCoNews();
      }, [companySelected]);
    
      async function getCoNews() {
        setNewsLoading(true)
        try {
          const response = await axios(`${BASE_URL}/newsCompany/`, {
            params: {
              ticker:  companySelected,
            },
          });
          setNewsCo(JSON.parse(response.data))
        } catch (error) {
          console.error(error);
        }finally{
            setNewsLoading(false)
        }
      }
  return {newsCo,newCoLoading}
}

import React from 'react'
import Guidance from './Guidance'
import Modal from 'components/Modal/Modal'

export default function GuideModal({onClose,guideType}) {
  return (
    <Modal sx={{borderRadius:"5px"}} width="90%" height="80%" onClose={()=>onClose(false)}>
    <div style={{width:"100%",height:"100%",overflow:"auto"}}>
    <Guidance menuType={guideType}/>
    </div>
    </Modal>
  )
}

import { TextField } from '@mui/material';
import React from 'react';

export default function TextInput({
  variant = "standard",
  size="medium",
  onChange,
  required = true,
  label = "",
  value,
  type = "text",
  sx,
  step = "any",
  min,
  max,
  fontSize,
  placeholder,
  disabled=false
}) {
  const stringValue = value === undefined ? '' : value.toString();

  return (
    <TextField 
      disabled={disabled}
      sx={{
        ...sx,
        '& .MuiInputBase-input': {
          fontSize: fontSize,
        },
      }}
      size={size}
      type={type}
      required={required}
      label={label}
      placeholder={placeholder}
      variant={variant}
      value={stringValue} // Ensure value is never undefined
      onChange={(e) => onChange(e.target.value)}
      inputProps={{ step, min, max }}
      fullWidth // This ensures the component covers the full width of the parent container
    />
  );
}
import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  MiniMap,
  useReactFlow,
  ReactFlowProvider,
  useNodesState,
  Panel,
  ControlButton,
  updateEdge
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Button } from '@mui/material';
import TextNode from './TextNode';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { useAppInfo } from 'AppState';
import EdgeTemplate from './EdgeTemplate';
import CustomNode from './CustomNode';

const nodeTypes = { textNode: TextNode };
const edgeTypes = {
  buttonedge: EdgeTemplate,
};

const getNodeId = () => `randomnode_${+new Date()}`;
const flowKey = 'example-flow';

function Flow() {
  const { stateMindmap, dispatchMindmap } = useAppInfo();
  const { nodes, edges } = stateMindmap;
  const { screenToFlowPosition } = useReactFlow();

  const [rfInstance, setRfInstance] = useState(null);
  const nodesHistory = useRef([]);
  const edgesHistory = useRef([]);
  const nodesFuture = useRef([]);
  const edgesFuture = useRef([]);
  const connectingNodeId = useRef(null);

  const updateHistory = useCallback((newNodes, newEdges) => {
    nodesHistory.current.push([...nodes]);
    edgesHistory.current.push([...edges]);
    nodesFuture.current = [];
    edgesFuture.current = [];
    dispatchMindmap({
      nodes: newNodes,
      edges: newEdges
    });
  }, [nodes, edges]);

  const onNodesChange = useCallback((changes) => {
    updateHistory(applyNodeChanges(changes, nodes), edges);
  }, [nodes, edges]);

  const onEdgesChange = useCallback(
    (changes) => {
      const updateEdges = applyEdgeChanges(changes, edges);
      dispatchMindmap({ edges: updateEdges });
    },
    [edges]
  );

  const onConnect = useCallback(
    (params) => {
      connectingNodeId.current = null;
      const updateEdges = addEdge(params, edges);
      dispatchMindmap({ edges: updateEdges });
    },
    [edges]
  );

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));
      if (flow) {
        dispatchMindmap({
          nodes: flow.nodes || [],
          edges: flow.edges || []
        });
        if (rfInstance) {
          rfInstance.setViewport(flow.viewport);
        }
      }
    };
    restoreFlow();
  }, [rfInstance]);

  const onAdd = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      type: 'textNode',
      data: { label: 'New Text' },
      position: { x: 0, y: 0 }
    };
    updateHistory([...nodes, newNode], [...edges]);
  }, [nodes, edges]);

  const onUndo = useCallback(() => {
    if (nodesHistory.current.length > 0 && edgesHistory.current.length > 0) {
      nodesFuture.current.unshift([...nodes]);
      edgesFuture.current.unshift([...edges]);
      dispatchMindmap({
        nodes: nodesHistory.current.pop(),
        edges: edgesHistory.current.pop()
      });
    }
  }, []);

  const onRedo = useCallback(() => {
    if (nodesFuture.current.length > 0 && edgesFuture.current.length > 0) {
      const futureNodes = nodesFuture.current.shift();
      const futureEdges = edgesFuture.current.shift();
      updateHistory(futureNodes, futureEdges);
    }
  }, []);

  const onClear = useCallback(() => {
    updateHistory([], []);
  }, []);

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        const id = getNodeId();
        const newNode = {
          id,
          type: "textNode",
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `New Text` },
          origin: [0.5, 0.0],
        };
        const newEdge = { id, type: 'buttonedge', source: connectingNodeId.current, target: id };
        const nodesUpdate = [...nodes, newNode];
        const edgesUpdate = [...edges, newEdge];
        dispatchMindmap({
          nodes: nodesUpdate,
          edges: edgesUpdate
        });
      }
    },
    [screenToFlowPosition, nodes, edges]
  );

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      const edgesUpdate = updateEdge(oldEdge, newConnection, edges);
      dispatchMindmap({ edges: edgesUpdate });
    },
    [edges]
  );

  return (
    <div style={{ height: '100%', width: "100%" }}>
      <ReactFlow
        style={{ background: "white" }}
        nodes={nodes}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        snapToGrid={true}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setRfInstance}
        onEdgeUpdate={onEdgeUpdate}
        fitView
      >
        <Background />
        <Controls>
          <ControlButton disabled={(!nodesHistory.current.length > 0 && !edgesHistory.current.length > 0)} onClick={onUndo} variant="contained">
            <UndoIcon />
          </ControlButton>
          <ControlButton disabled={(!nodesFuture.current.length > 0 && !edgesFuture.current.length > 0)} onClick={onRedo} variant="contained"><RedoIcon /></ControlButton>
          <ControlButton disabled={(!nodesHistory.current.length > 0 && !edgesHistory.current.length > 0)} onClick={onClear} variant="contained">
            <DeleteForeverIcon />
          </ControlButton>
        </Controls>

        <MiniMap nodeStrokeWidth={3} />
        <Panel position="top-right">
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <Button sx={{ padding: "0 0.25rem" }} onClick={onSave} variant="contained">Save</Button>
            <Button sx={{ padding: "0 0.25rem" }} onClick={onRestore} variant="contained">Restore</Button>
          </div>
        </Panel>
        <Panel position='bottom-center'>
          <Button onClick={onAdd} variant="contained">Add Text</Button>
        </Panel>
      </ReactFlow>
    </div>
  );
}

export default function Whiteboard() {
  return (
    <ReactFlowProvider>
      <Flow />
    </ReactFlowProvider>
  );
}



// const initialNodes = [
//     //   {
//     //     id: '1',
//     //     data: { label: 'Hello' },
//     //     position: { x: 0, y: 0 },
//     //     type: 'input',
//     //   },
//     //   {
//     //     id: '2',
//     //     data: { label: 'World' },
//     //     position: { x: 100, y: 100 },
//     //   },
//     //   {
//     //     id: '3',
//     //     data: { label: 'New' },
//     //     position: { x: 140, y: 140 },
//     //   },
//     //   {id: '4',type: 'selectorNode',data: { label:"Custom" },style: { border: '1px solid #777', padding: 10 },
//     //   position: { x: 300, y: 50 },
//     // },
//     ];
    
//     const initialEdges = [
//       // { id: '1-2', source: '1', target: '2', type: 'step' },
//       // { id: '2-3', source: '2', target: '3', type: 'step' },
//       // { id: '3-4', source: '3', target: '4', type: 'step' },
     
//     ];
import React from 'react'
import useSenateHoldings from 'data/government/useSenateHoldings'
import TableGen from 'components/Table/TableGen'

export default function PortfolioHoldingSenate() {
  const {senateHoldings} = useSenateHoldings()
  return (
    <div style={{width:"100%",height:"100%",overflow:"auto"}}>
    <TableGen tableLayout='fixed' data={senateHoldings}/>
    </div>
  )
}

import React,{useState,useEffect} from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useBondsScreener() {
    const [bondsOverview, setBondsOverview] = useState([]);
    useEffect(() => {
        getMetricInfo();
      }, []);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`${BASE_URL}/bondsOverview/`);
          setBondsOverview(responseMetric.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {bondsOverview}
    
}

import React from 'react';
import Modal from 'components/Modal/Modal';
import { useAppInfo } from 'AppState';
import SearchUI from 'search/SearchUI';


export default function AddPeersModal() {

  const {dispatchSearch,stateSearch} = useAppInfo();

  return (
    <>
    {stateSearch.addPeers && 
    <Modal onClose={()=>dispatchSearch({addPeers:false})} width='50%'>
      <SearchUI type={"Ticker"} addType={"Peers"}/>
    </Modal>}
    </>
  );
}
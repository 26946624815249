import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import ScoreTemplate from './ScoreTemplate';
import useESGscore from 'data/financialModelingPrep/useESGscores';
import { dfin } from 'content/constants';


export default function CompanyESGScoreBar({coInfo}) {
  const { esgScore } = useESGscore(coInfo?.[dfin.ticker]);
  const [currentEsgScore, setCurrentEsgScore] = useState({});

  useEffect(() => {
    if (esgScore.length > 0) {
      const currentEsgScore = esgScore[0];
      setCurrentEsgScore(currentEsgScore);
    } else {
      setCurrentEsgScore({});
    }
  }, [esgScore]);

  const [color,setColor] = useState("gray"); // Default color for no data
  const [score,setScore] = useState("N/A"); // Default score for no data
  const scoreDetails = [
    { Title: "Enviromental Score", Value: currentEsgScore?.environmentalScore},
    { Title: "Scocial Score", Value: currentEsgScore?.socialScore },
    { Title: "Governance Score", Value: currentEsgScore?.governanceScore }
  ];
  useEffect(()=>{
    if (Object.keys(currentEsgScore).length>0) {
      let color = "gray"
      let score = currentEsgScore.ESGScore;
      if (score < 30) {
          color = "red";
      } else if (score >= 30 && score <= 70) {
          color = "orange";
      } else {
          color = "green";
      }
      setScore(score)
      setColor(color)
  }
  },[currentEsgScore])
  
  const [mouseEnter,setMouseEnter] = useState(false)
  return (
    <>
      <ScoreTemplate scoreTitle={"ESG"} scoreOverall={score} scoreMax={100} scoreDetails={scoreDetails}/>
     
    </>
  );
}

const dataKeys = [
  "ESGScore",
  "acceptedDate",
  "cik",
  "companyName",
  "date",
  "environmentalScore",
  "formType",
  "governanceScore",
  "socialScore",
  "symbol",
  "url"
]
import { Button } from "@mui/material"
import { useAppInfo } from "AppState"
import { assetTypes } from "appState/initialStateMyFinancials"
import TabLine from "components/Tabs/TabLine"
import { useEffect, useState } from "react"
import AssetProjection from "./AssetProjection"


export default function AssetProjections ({chartHeight="400px",projectionType="Projection",editDetails=false,showDate=false,showField=true}){
    
    const [tabIndex,setTabIndex] = useState(0)

    return (
      <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
      <TabLine tabList={["Value","Return"]} tabIndex={tabIndex} handleTabChange={(v)=>setTabIndex(v)}/>
        
      {tabIndex === 0 && <AssetProjection projectionType={projectionType} amountType={"value"}/>}
        {tabIndex === 1 && <AssetProjection projectionType={projectionType} amountType={"return"}/>}
      </div>
  )
  }
  
  
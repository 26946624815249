import React from "react";
import { dfin } from "content/constants";
import CompanyEarningTranscripts from "newsEvents/CompanyEarningTranscripts";
import CompanyPressRelease from "newsEvents/CompanyPressRelease";
import PriceTargets from "analytics/technicals/PriceTargets";
import CompanyBusiness from "./stocks/BusinessAndOwnership/CompanyBusiness";
import CompanyInsider from "./stocks/BusinessAndOwnership/CompanyInsider";
import CompanyManagement from "./stocks/BusinessAndOwnership/CompanyManagement";
import CompanyShareholder from "./stocks/BusinessAndOwnership/CompanyShareholders";
import InsiderTransactionStats from "./stocks/BusinessAndOwnership/InsiderTransactionStats";
import SenateTransactions from "./stocks/BusinessAndOwnership/SenateTransactions";
import CompanyExchangeFilings from "./stocks/Reports/CompanyExchangeFilings";
import CompanyDerivativesOptions from "./stocks/Derivatives/CompanyDerivativesOptions";
import Dividend from "./stocks/Financials/Dividend";
import CompanyCapitalStructure from "./stocks/Financials/Company/CompanyCapitalStructure";
import CompanyEarningTrends from "./stocks/Financials/Company/CompanyEarningTrends";
import CompanyProfitability from "./stocks/Financials/Company/CompanyProfitability";
import CompanyNews from "../../newsEvents/CompanyNews";
import CompanyProfile from "./stocks/Overview/CompanyProfile";
import RevenueSegment from "./stocks/Financials/RevenueSegment";
import MetricComparisonVsIndustry from "./stocks/VsIndustry/MetricComparisonVsIndustry";
import FinancialsVsPrice from "./stocks/FinancialVsPrice";
import StatsVsPeers from "./stocks/Peer/StatsVsPeers";
import PeerOverviewMetricComp from "./stocks/Peer/PeerOverviewMetricComp";
import PeerProfile from "./stocks/Peer/PeerProfile";
import Value from "./stocks/Value/Value";
import { useAppInfo } from "AppState";
import TrendTable from "analytics/technicals/general/TrendTable";
import ScatterUI from "components/Charts/ScatterUI";
import FinancialsTemplate from "./stocks/Financials/FinancialsTemplate";

export default function StocksInfoList({ info }) {
  const { stateTicker, dispatchTicker, stateStock } = useAppInfo();

  const { close, marketInfoLoading, trendTable, tickerType } = stateTicker;
  const { peerInfo } = stateStock;

  const content = {
    Profile: <CompanyProfile />,
    Business: <CompanyBusiness />,
    Management: <CompanyManagement />,
    Shareholding: <CompanyShareholder />,
    "Insider Transactions": <CompanyInsider />,
    "Statements": <FinancialsTemplate title="Financial Statements"/>,
    "Common Size Statements": <FinancialsTemplate title="Common Size Statements" financialsType={"commonSize"} />,
    "Statement Breakdown": <FinancialsTemplate title="Statement Breakdown" financialsType={"statement"} period="single" />,
    "Ratios": <FinancialsTemplate title="Financial Ratios" financialsType={"ratio"}/>,
    "Revenue Segment": <RevenueSegment />,
    "Earning Trends": <CompanyEarningTrends />,
    Profitablity: <CompanyProfitability />,
    "Capital Structure": <CompanyCapitalStructure />,
    "Price vs Financials": <FinancialsVsPrice />,
    "vs Price": <FinancialsVsPrice />,
    "Stats vs Industry": <MetricComparisonVsIndustry />,
    "Stats vs Peers": <StatsVsPeers />,
    "Peer Profile": <PeerProfile />,
    "Peer Scatter": (
      <ScatterUI
        data={peerInfo}
        defaultMet={[dfin.marketCap, dfin.pe]}
        addPeersBar={true}
      />
    ),
    Scatter: (
      <ScatterUI
        data={peerInfo}
        defaultMet={[dfin.marketCap, dfin.pe]}
        addPeersBar={true}
      />
    ),
    "Peer Bar & Pie": (
      <PeerOverviewMetricComp
        addPeers={true}
        infoName={"Bar & Pie"}
        data={peerInfo}
        defaultMet={[dfin.marketCap, dfin.pe]}
      />
    ),
    "Financials vs Peers": <FinancialsTemplate title="Financials vs Peers" peers={true} />,
    "Financial Ratios vs Peers": <FinancialsTemplate title="Financial Ratios vs Peers" financialsType="ratio" peers={true} />,
    "Price Multiple": <Value />,
    "EV Multiple": <Value valuationType={"EV Multiple"} />,
    DCF: <Value valuationType={"DCF"} />,
    News: <CompanyNews />,
    Options: <CompanyDerivativesOptions />,
    Dividend: <Dividend />,
    "Price Target": <PriceTargets />,
    "Earning Transcripts": <CompanyEarningTranscripts />,
    "Press Release": <CompanyPressRelease />,
    "Security Exchange Filings": <CompanyExchangeFilings />,
    "Stats - Insider Transactions": <InsiderTransactionStats />,
    "Senate Transactions": <SenateTransactions />,
    "Trend Table": (
      <TrendTable
        data={trendTable}
        tickerPrice={close}
        marketInfoLoading={marketInfoLoading}
        addTicker={true}
      />
    ),
  };
  return <>{content[info]}</>;
}

import { assetCode, dfin, growthMetric } from "content/constants";
import { menuStocks } from "nav/menu";


  const initialStateTicker = {
    menu:menuStocks,
    menuSel:"Overview",
    subMenuSel:"Profile",
    contentList:[],
    tickerInfo: [],
    tickerSelInfo:[],
    tickerModal:false,
    peersIn: "INDUSTRY",
    peerSelected: [],
    peerSelectedDisplay:[],
    priceInfo:[],
    tickerSelected: "AAPL",
    tickerCode:"AAPL.US",
    tickerType:assetCode?.Stocks,
    tickerName:"",
    tickerStock:"AAPL",
    tickerStockCode:"AAPL.US",
    tickerETF : "SPY",
    tickerCrypto:"BTC",
    tickerForex:"EUR",
    tickerBond:"US10Y.GBOND",
    tickerETFCode:"SPY.US",
    tickerCryptoCode:"BTC-USD.CC",
    tickerBondCode:"US10Y.GBOND",
    tickerForexCode:"USDEUR.FOREX",
    noInfo:false,
    close:[],
    trendTable:[],
    marketInfoLoading:false,
  };

  const metricScores = [
    {
      category: "Size",
      selected: true,
      metrics: [
        { metric: dfin.revenue, weight: 1, selected: true },
        { metric: dfin.ebitda, weight: 1, selected: true },
        { metric: dfin.assets, weight: 1, selected: true },
        { metric: dfin.currentAssets, weight: 1, selected: true },
      ],
    },
    {
      category: "Growth",
      selected: true,
      metrics: [
        { metric: growthMetric(dfin.revenue, "5Y"), weight: 1, selected: true },
        { metric: growthMetric(dfin.grossProfit, "5Y"), weight: 1, selected: true },
        { metric: growthMetric(dfin.ebitda, "5Y"), weight: 1, selected: true },
        { metric: growthMetric(dfin.netProfit, "5Y"), weight: 1, selected: true },
        { metric: growthMetric(dfin.fcf, "5Y"), weight: 1, selected: true },
        { metric: growthMetric(dfin.assets, "5Y"), weight: 1, selected: true },
      ],
    },
    {
      category: "Profitability",
      selected: true,
      metrics: [
        { metric: dfin.grossProfitMargin, weight: 1, selected: true },
        { metric: dfin.ebitdaMargin, weight: 1, selected: true },
        { metric: dfin.netProfitMargin, weight: 1, selected: true },
      ],
    },
    {
      category: "Financial Stability",
      selected: true,
      metrics: [
        { metric: dfin.de, weight: 1, selected: true },
        { metric: dfin.cr, weight: 1, selected: true },
        // { metric: dfin.interestCoverage, weight: 1, selected: true },
      ],
    },
    {
      category: "Return on Capital",
      selected: true,
      metrics: [
        { metric: dfin.roic, weight: 1, selected: true },
        { metric: "ROA", weight: 1, selected: true },
        // { metric: dfin.roce, weight: 1, selected: true },
        // { metric: dfin.roe, weight: 1, selected: true },
      ],
    },
  ];
  

  
  const initialStateStock = {
    annualInfo:[],
    quarterInfo:[],
    coInfo:[],
    screenInfo:[],
    peerInfo:[],
    metrics:{metricScreener:[],numCols:[],txtCols:[],growthCols:[],avgCols:[],scoreCols:[],metricFinancials:[],metricFinancialStatement:{}},
    metricSelectedCategories:{},
    loadingFinancials:false,
    peerMode: false,
    tickerDict:{},
    companySelected: "AAPL",
    scoreDetails:[],
    financialsSettings:{reportMode:false,periodMode:false,growthMode:false,periodLen:5,totalPeriods:0,chartType:"area",singlePeriodDate:"",displayType:"Chart & Table"},
    overviewSettings:{displayType:"Chart"},
    metricScores:metricScores
  }
  
  export {initialStateStock,initialStateTicker}
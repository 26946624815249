import React from 'react'
import useEconInfo from 'data/economy/useEconInfo'
import EconSingleSeriesUI from './EconSingleSeriesUI';
import TabsClose from 'components/Tabs/TabsClose';
import { Paper } from '@mui/material';

export default function EconomyCategoryContent({category="Interest Rates"}) {
  const {econInfo} = useEconInfo()
  const categorySel = categoryDetails.filter(cat=>cat.name===category)[0]
  const tabList = categorySel?.tabList
  const categoryName = categorySel?.categoryName
  const filteredData = tabList.map((subCategory) =>
   econInfo.filter(
     (obj) => obj["Category"] === categoryName && (obj["Sub Category"] === subCategory || obj["Name"] === subCategory)
   )
 );

 const tabContent = filteredData.map((categoryData, index) => (
   <div key={`tab-${category}-${index}`} style={{ flex: 1,display:"flex",flexDirection:"column",gap:"0.5rem"}}>
     {categoryData.map((econCategory) => (
       <EconSingleSeriesUI key={econCategory["Name"]} econCategories={[econCategory]} />
     ))}
   </div>
 ));


  return (
  <Paper className='containerMainContent'>
  <TabsClose infoTag={false} key={category} tabList={tabList} tabContent={tabContent} />
  </Paper>
  )
}

const categoryDetails = [
    {name:"Interest Rates",categoryName:"Interest Rates",tabList:[
        'Fed Fund Rate',
        'Prime Bank Loan Rate',
        'Treasury Bills',
        'Treasury Inflation-Indexed Securities',
        'Inflation Rates',
        'Commercial Paper',
        'Corporate Bonds',
        'Mortgage Rates',
        'Interest Rates',
        'Others',
      ]},
    {name:"Employment",categoryName:'Population, Employment, & Labor Markets',tabList:['Employment', 'Population']},
    {name:"Monetary",categoryName:"Monetary Data",tabList:['M1 & Components', 'M2 & Components', 'Reserves', 'Monetary Base','Velocity']},
    {name:"National Accounts",categoryName:"National Accounts",tabList:["National Accounts","GDP Share"]},
    {name:"Business Indicators",categoryName:'Production & Business Activity',tabList:['Business Cycle Expansions & Contractions',
    'Key Industry Parameters']},
    {name:"Inflation",
    categoryName:"Inflation&Prices",
    tabList:[
        'Inflation, consumer prices',
    'Consumer Price Index for All Urban Consumers: All Items in U.S. City Average',
    'Producer Price Index by Commodity: All Commodities',
      ]},
]
    


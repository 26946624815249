import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Alert } from '@mui/material';
import { formatNumber } from 'utils/utilGeneral';

require("highcharts/modules/annotations")(Highcharts);

const Histogram = ({ data, width = "100%", orientation="column",rangeNumberFormat=""
,color="General",title="",highlightNumber,comment}) => {
  let chartOrientation = orientation.toLowerCase()
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    if (data) {
      const values = data.map(item => Object.values(item));
      // Set the bins and calculate frequency
      const maxRange = Math.ceil(Math.max(...values));
      const minRange = Math.floor(Math.min(...values));

      const numBins = 30; // Set the desired number of bins
      const binWidth = (maxRange - minRange) / numBins;

      const bins = Array.from({ length: numBins + 1 }, (_, index) => {
        const lowerBound = minRange + index * binWidth;
        const upperBound = index === numBins ? maxRange : lowerBound + binWidth;
        const formattedLowerBound = formatNumber(lowerBound);
        const formattedUpperBound = formatNumber(upperBound);
        return `${formattedLowerBound}${rangeNumberFormat} - ${formattedUpperBound}${rangeNumberFormat}`;
      });
      
        // specify the number you want to highlight

      const frequencyData = Array.from({ length: numBins }, (_, index) => {
        const lowerBound = minRange + index * binWidth;
        const upperBound = index === numBins ? maxRange : lowerBound + binWidth;
      
        const formattedLowerBound = formatNumber(Math.floor(lowerBound));
        const formattedUpperBound = formatNumber(index === numBins ? Math.ceil(upperBound) : upperBound);
      
        const count = values.filter(
          value => value >= lowerBound && (index === numBins-1 ? value <= upperBound : value < upperBound)
        ).length;
      
        const isHighlighted = highlightNumber >= lowerBound && highlightNumber <= upperBound;
      
        return {
          range: `${formattedLowerBound} - ${formattedUpperBound}`,
          count,
          isHighlighted,
        };
      });

      

      const options = {
        chart: {
          type: chartOrientation,
        },
        title: {
          text: title,
        },
        xAxis: {
          categories: bins,
          title: {
            text: 'Range',
          },
        },
        yAxis: {
          title: {
            text: 'Frequency',
          },
        },
        plotOptions: {
             column: {
              pointPadding: 0,
              borderWidth: 0,
              groupPadding: 0,
              dataLabels: [{
                enabled: true,
                outside: true,
                format: '{point.y}', // Display frequency inside the column
                
              }],
            },

            bar: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0,
                dataLabels: [{
                  enabled: true,
                  outside: true,
                  format: '{point.y}', // Display frequency inside the column
                  
                }],
              },
          },
          series: [
            {
              name: 'Frequency',
              data: frequencyData.map(item => ({
                y: item.count,
                color: item.isHighlighted ? 'yellow' : (color === "Performance" && (item.range.split(' ')[0] >= 0 ? 'green' : 'red')),
              })),
            },
          ],
        
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        accessibility: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        // annotations: [{
        //   labels: [{
        //       point: {
        //           x: 0,
        //           y: 0,
        //       },
        //       text: highlightNumber,}]}]
      };

      setChartOptions(options);
    }
  }, [data]);

  return (
    <>
    {comment && <Alert severity='info'>
      {comment} 
    </Alert>}
   
    <div style={{ height: '100%', overflowY: 'auto', width: width }}>
      
      {chartOptions && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height: '100%', width: '100%' } }}
        />
      )}

    </div>
    </>
  );
};

export default Histogram;

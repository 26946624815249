import OverviewTab from 'myFinancials/Template/OverviewTab'
import React from 'react'
import LiabilityDetails from './LiabilityDetails'

export default function LiabilityOverview() {
  return (
    <LiabilityDetails/>
  )
}



const fontStyles = [
    "sans-serif",
    "Arial",
    "Helvetica",
    "Verdana",
    "Tahoma",
    "Geneva"
  ];

export {fontStyles}
  
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';

const useAddReport = () => {
  const {dispatchReportBuilder,stateApp} = useAppInfo();
  const userId = stateApp.userId
const username = stateApp.username
  const [error, setError] = useState(null);

  const addReport = async (reportName, reportDetails) => {
    try {
      dispatchReportBuilder({'reportSaved':false });

      // Make use of setTimeout for a 3-second delay
      setTimeout(async () => {
        const response = await axios.post(`${BASE_URL}/addReport/`, {
          userId,
          username,
          reportData: reportDetails,
          reportName: reportName,
        });
        dispatchReportBuilder({'reportSaved':true});
        setError('Success');
      }, 1000); // 3000 milliseconds = 3 seconds
    } catch (err) {
      setError('Error adding Report data');
    }
  };

  return { addReport, error };
};

export default useAddReport;
import { useAppInfo } from 'AppState'
import CashflowChart from 'myFinancials/Cashflow/CashflowChart'
import React from 'react'
import GoalIndivdiualDetails from './GoalIndivdiualDetails'

export default function GoalsProjection({showDetails=false}) {
    const {stateMyFinancials} = useAppInfo()
    const {cashflowDistribution,goalAnalytics} = stateMyFinancials
  const {goalDist} = cashflowDistribution
  return (
    <div style={{overflow:"auto",display:"flex",flexDirection:"column",width:"100%"}}>
    {goalDist && 
    <div style={{width:"100%",height:"400px",minHeight:"400px"}}>
    <CashflowChart data={goalDist} title="Projected Goals"/>
    </div>
    }
    {showDetails && 
    <div style={{flex:1}}>
    <GoalIndivdiualDetails edit={false}/>
    </div>}
    </div>
  )
}

const initialStateOpportunities = {
    mScaleSel:["Mega Cap"],
    assetClassSel:"Stocks",
    tradeOptions:["Positional","Day Trade"],
    tradeOptionSel:"Positional",
    investmentOptionSel:"Growth",
    investmentOptions:["Growth","Dividend","Long Term Value"],
    dataView:"Information"
}

export {initialStateOpportunities}

import React, { useState } from 'react'

import useMovingAverage from 'data/technicals/useMovingAverage';
import { LinearProgress } from '@mui/material';
import PeriodSlider from 'components/Inputs/PeriodSlider';
import SelectBox from 'components/Inputs/SelectBox';
import TableGen from 'components/Table/TableGen';
import IndicatorTemplate from './IndicatorTemplate';
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable';

export default function MovingAverageCross({tickerList,tickerDict,maType="EMA"}) {
  const [dateFrequency,setDateFrequency] = useState("Daily")
  const [periodFast,setPeriodFast] = useState(50)
  const [periodSlow,setPeriodSlow] = useState(200)
  
  const {maCross,maLoading} = useMovingAverage(periodFast,periodSlow,maType,dateFrequency,tickerList)
  
  function handleDateFreq(e,v){
    setDateFrequency(v)
  }

  function handlePeriodSlow(event, v) {
    setPeriodSlow(event.target.value === '' ? '' : Number(event.target.value)); 
  }

  function handlePeriodFast(event, v) {
    setPeriodFast(event.target.value === '' ? '' : Number(event.target.value)); 
  }

  const settingContent = (   
  <> 
  <div><SelectBox options={["Daily","Weekly","Monthly"]} id={"DateFreq Type"} value={dateFrequency} onChange={handleDateFreq} label={"Date Frequency"}/></div>
  <div>
  <h3>{maType} Fast</h3>
  <PeriodSlider 
   max={200}
   min={1}
   defaultValue={periodFast}
   onChange={handlePeriodFast}
   handleInputChange = {handlePeriodFast} 
   period={dateFrequency}/>
  </div>
  <div>
  <h3>{maType} Slow</h3>
  <PeriodSlider 
   max={500}
   min={1}
   defaultValue={periodSlow}
   onChange={handlePeriodSlow}
   handleInputChange = {handlePeriodSlow} 
   period={dateFrequency}/>
  </div>
  </>)
  return(
    <IndicatorTemplate indicatorName={maType} showSettings={true} settingContent={settingContent}>
    {maLoading && <LoadingSkeletonTable length={tickerList.length} columns={5}/>}
        {(!maLoading && maCross.length>0) && 
        <TableGen tickerDict={tickerDict} data={maCross}/>}
        
    </IndicatorTemplate>

  )

}
import React from 'react'
import PriceChartMulti from 'components/Charts/PriceChartMulti'
import { useAppInfo } from 'AppState'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo'

export default function PriceVsBenchmark() {
    const {stateTicker} = useAppInfo()
    const {close,day,marketInfoLoading} = useMultiTickerInfo([stateTicker.tickerSelected,"SPY"])
    

  return (
    <PriceChartMulti
    close={close}
    marketInfoLoading={marketInfoLoading}
    tickerList={[stateTicker.tickerSelected,"SPY"]}
  />
  )
}

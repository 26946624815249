import React, { useEffect, useMemo, useState } from "react";
import exportingModule from "highcharts/modules/exporting";
import exportDataModule from "highcharts/modules/export-data";
import fullscreenModule from "highcharts/modules/full-screen";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import numeral from "numeral";
import { formatNumber } from "utils/utilGeneral";

require("highcharts/modules/accessibility")(Highcharts);

exportingModule(Highcharts);
exportDataModule(Highcharts);
fullscreenModule(Highcharts);

export default function FinancialsChart({
  chartType = "column",
  data,
  metricSelected,
  title,
  period,
  width = "100%",
  height = "100%",
  growthMode=false,
}) {
  const [series, setSeriesData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);

  useEffect(() => {
    data.sort((a, b) => {
      return a[period].toString().localeCompare(b[period.toString()]);
    });

    const chartData = metricSelected.map((metric) => ({
      name: metric,
      data: data.map((obj) => ({
        // x: obj[period], // Use the date string directly
        y: Number(obj[metric]),
      })),
    }));
    const categories = data.map((obj) => obj[period]);
    setSeriesData(chartData);
    setChartCategories(categories);
  }, [data, metricSelected]);


  const chartOptions = useMemo(() => {
    if (series.length === 0) {
      // Return an empty options object if the series is not available yet
      return {};
    }
    return {
      chart: {
        type: chartType,
        animation: false,
      },
      title: {
        text: `${title}`,
      },
      xAxis: {
        type: "category", // Change X axis to categories
        categories: chartCategories, // Use date strings directly
      },
      yAxis: {
        title: {
          text: "Value",
        },
        labels: {
          formatter: function () {
            if (growthMode){
              return `${formatNumber(this.value)}%`
            }
            else{
              return numeral(this.value).format("0.0a").toUpperCase();
            }
          },
        },
      },
      series: series,
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltip = `<b>Period: ${this.x}</b><br/>`; // Use the date string directly
          this.points.forEach((point) => {
            tooltip += `<span style="color:${point.color}">${
              point.series.name
            }: ${numeral(point.y).format("0.0a").toUpperCase()}</span><br/>`;
          });
          return tooltip;
        },
      },
      plotOptions: {
        column: {
          grouping: true,
        },
        pointPlacement: "on",
      },
      exporting: { enabled: false },
      credits: {
        enabled: false, // Hide Highcharts.com credits
      },
      accessibility: {
        enabled: true, // Include the accessibility module
      },
    };
  }, [series, chartType]);

  return (
    <div style={{ height: height, width: width, position: "relative" }}>
      {/* {series.length===0&& <LinearProgress/>} */}
      {series.length > 0 && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height: "100%", width: "100%" } }}
        />
      )}
    </div>
  );
}

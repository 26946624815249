import { useAppInfo } from 'AppState'
import Modal from 'components/Modal/Modal';
import React from 'react'
import AuthForm from './AuthForm';

export default function AuthModal() {
  const {stateApp,dispatchApp} = useAppInfo()
  function handleLogin() {
    dispatchApp({"loginModal":false });
  }
  return (
    <>
    {stateApp.loginModal && 
        <Modal onClose={()=>handleLogin(false)} width='30%' height='auto'>
                    <AuthForm/>
    </Modal>}
    </>
  )
}

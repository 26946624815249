import {useState } from 'react';
import axios from 'axios';
import { BASE_URL } from 'apiConfig';
import { useAppInfo } from 'AppState';


export default function useDeleteScreener() {
  const {stateApp} = useAppInfo()
  const userId = stateApp.userId

  const [error, setError] = useState(null);
  
  const deleteScreener = async (screenerName) => {
    try {
      const response = await axios.post(`${BASE_URL}/deleteScreeners/`, {
        userId,
        screenerName:screenerName
      });
      setError("Success")
    } catch (err) {
      setError('Error adding Note data');
    }
  };

  return { deleteScreener, error };
};
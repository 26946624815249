import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import FieldFormGen from '../FormFields/FieldFormGen'
import CashflowChart from './CashflowChart'
import { defaultFieldBlank} from 'appState/initialStateMyFinancials'




export default function Income() {
    const {stateMyFinancials} = useAppInfo()
    const {incomeMonthly,cashflowDistribution} = stateMyFinancials
    const {incomeDist} = cashflowDistribution


  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <div style={{width:"100%",height:"400px",minHeight:"400px"}}>
    <CashflowChart data={incomeDist} title="Projected Income"/>
    </div>

    <div className='containerColumnFlex1'>
    <FieldFormGen 
  defaultField={defaultFieldBlank.Income}
  date={true}
  forecastPeriod={true} growthRate={true} 
  period={true} formName='Income' storeName={"incomeMonthly"} data={incomeMonthly} 
  formHeading='Income Category'/>
    </div>
    </div>
  )
}





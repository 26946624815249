import React from 'react'
import FieldFormOnboarding from '../FormFields/FieldFormOnboarding'
import { assetTypes } from 'appState/initialStateMyFinancials'
import { generateUniqueId } from "utils/utilGeneral"
import { useAppInfo } from 'AppState'

export default function AssetOnboarding() {
  const defaultField =  { id:generateUniqueId(), name: "Savings Account",assetType:"Savings", 
  type: "Savings", amount: 5000, date:new Date(), currentAllocation: 0, targetAllocation: 0, differenceAllocation: 0, return: 2, volatility: 0,forecastPeriod:30,edit:true}
  
  
  return (
    <FieldFormOnboarding 
    defaultField={defaultField} 
    typeOptions={assetTypes} storeName={"assets"} 
    projectionType="assets" forecastPeriod={true} returnExpected={true}/>
  )
}

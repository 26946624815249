import React, {
  useEffect,
  useState,
} from 'react';

import TechnicalChart from 'components/Charts/TechnicalChart';
import TreeMap from 'components/Charts/TreeMap';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import { menuTrade } from 'nav/menu';
import PriceVsEconomy from 'analytics/economy/PriceVsEconomy';
import PriceVsBenchmark from 'analytics/technicals/PriceVsBenchmark';
import Technicals from 'analytics/technicals/Technicals';
import FinancialsVsPrice from 'analytics/ticker/stocks/FinancialVsPrice';
import TickerHeader from 'analytics/ticker/TickerHeader';
import TickerInfoList from 'analytics/ticker/TickerInfoList';
import Orders from '../../trade/Orders';
import TradeConditions from '../../trade/TradeConditions';
import Transactions from '../../trade/Transactions';
import PageComingSoon from '../PageComingSoon';
import { PageMainLayout, PageContentWithLeftMenuLayout } from 'layout/PageLayout';


export default function Trade() {
  const {stateTicker} = useAppInfo()
  const {tickerType} = stateTicker
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const subPage = pathname[2]
  const [mainMenuSel,setMainMenuSel] = useState("Order")
  const [menuSel,setMenuSel] = useState("")
  const [menuOptions,setMenuOptions] = useState(menuTrade)

  
  useEffect(()=>{
    if (subPage){
    const menuSel = menuTrade.filter(obj=>obj.link===subPage)[0]?.name
    setMainMenuSel(menuSel)}
    else{
     navigate(`/Trade/Order`)
    }
   },[subPage])
   

  useEffect(()=>{
   
    
    const mainMenuOptions = menuTrade.filter(obj=>obj.name===mainMenuSel)[0]
    let menuContent = mainMenuOptions?.menuOptions
    let contentList  = []
    if (!menuContent){
      setMenuSel(mainMenuSel)
      setMenuOptions([])
    }
    else{
        if (tickerType === assetCode?.Stocks && mainMenuSel === "Chart"){
          menuContent = [
            {name: "Technical Chart", content: []},
            {name:"Price vs Benchmark",content:[]},
            {name: "Price vs Peers", content: []},
            {name: "Price vs Economy", content: []},
            {name:"Price vs Financials",content:[]},
            {name: "Peer Scatter", content: []},
            {name: "Peer Bar & Pie", content: []},
            {name: "Heatmap", content: []}
          ]
        }
        const menuOptionSel =  menuContent.map(obj=>obj.name)[0]
        const contentList =  menuContent.filter(obj=>obj.name===menuOptionSel)[0]?.content
        setMenuSel(menuOptionSel)          
          setMenuOptions(menuContent)
        }
    }
  ,[mainMenuSel])


  const infoList = {
    "Order":<Orders/>,
    "Chart":<Chart info={menuSel}/>,
    "Trade Conditions":<TradeConditions info={menuSel}/>,
    "Portfolio Fit":<PageComingSoon name="Portfolio Fit"/>,
    "Transactions":<Transactions/>,
    "Technicals":<TickerInfoList infoName={menuSel}/>,
    "Visuals":<Visuals info={menuSel}/>
    
  }
 

  return (
      <PageMainLayout 
    pageName='Trade'
    menuOptions={menuTrade}
    menuSel={mainMenuSel}
    >

   <PageContentWithLeftMenuLayout 
   header={<TickerHeader/>}
   showMenu={menuOptions.length>0}
   menuName={mainMenuSel}
   menuOptions={menuOptions} 
   menuSel={menuSel} subMenuSel={""}
  handleMenuSel={(v)=>setMenuSel(v)}
   >
   
    {menuOptions.length > 0 && infoList[mainMenuSel]}

    {menuOptions.length === 0 && 
    <div className='containerColumnFlex1'>
      {infoList[mainMenuSel]}
      </div>}

    </PageContentWithLeftMenuLayout>
    </PageMainLayout>
  )
}





function Visuals({info}){
  const {stateTicker} = useAppInfo()
  const {tickerType} = stateTicker
  const content = {
    "Scatter":<TickerInfoList infoName={"Scatter"}/>,
    "Bar & Pie":<TickerInfoList infoName={"Peer Bar & Pie"}/>,
    "Heatmap":<TreeMap/>
  }
  return (
    <>
    {content[info]}
    {tickerType !== assetCode?.Stocks && 
    <div style={{position:"absolute",zIndex:"10000",top:0,left:0,background:"rgba(0,0,0,0.05)"
    ,backdropFilter:"blur(20px)"
    ,width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
      <h1 style={{fontWeight:"bold",fontSize:"3rem"}}>Only Available for Stocks</h1>
      </div>}
    </>
  )
}

function Chart ({info}){
  const {stateTicker} = useAppInfo()
  const {tickerType} = stateTicker
  const content = {
    "Technical Chart":<TechnicalChart/>,
    "Price vs Benchmark":<PriceVsBenchmark/>,
    "Price vs Peers":<Technicals tabName={"Trend"} addTicker={true}/>,
    "Price vs Economy":<PriceVsEconomy/>,
    "Price vs Financials":<FinancialsVsPrice/>,
    "Peer Scatter":<TickerInfoList infoName={"Scatter"}/>,
    "Peer Bar & Pie":<TickerInfoList infoName={"Peer Bar & Pie"}/>,
    "Heatmap":<TreeMap showSettings/>
  }

  return (<>
  {content[info]}
    </>
  )
}



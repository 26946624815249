import React, { useState } from 'react'
import { useAppInfo } from 'AppState'
import Text from './Text'
import Upload from './Upload'
import Table from './Table'
import Charts from './Charts'
import Elements from './Elements'
import Templates from './Templates'
import Icons from './Icons'
import GeneratePdf from './GeneratePdf'

export default function ReportLeftSidebarContent() {
  const {stateReportBuilder} = useAppInfo()
  const content = {
    "Templates":<Templates/>,
    "Text":<Text/>,
    "Elements":<Elements/>,
    "Icons":<Icons/>,
    'Table':<Table/>,
    "Charts":<Charts/>,
    "Upload":<Upload/>,
  }
  return (
   <>
          <div style={{height:"100%",width:"100%",overflow:"auto"}}>
          {content[stateReportBuilder.leftSidebarContent]}
          </div>

   </>

  )
}

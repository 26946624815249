import React, { useEffect, useRef, useState } from 'react'
import { useAppInfo } from 'AppState';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { handleAddPageAtEnd, handleDeletePage } from './utilsReport';

export default function ReportFooter({pageFooterRef}) {
    const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const pageFooterRefs = useRef([])
    const {pageFooter} = stateReportBuilder
  const {pages,pageCurrent} = stateReportBuilder

  const [sortPages, setSortPages] = useState([]);

  useEffect(() => {
    if (pages){
    const clonedPages = [...pages];
    clonedPages.sort((a, b) => a.PageIndex - b.PageIndex);
    setSortPages(clonedPages);}
  }, [pages]);


  
  useEffect(()=>{
    const targetElement = document.getElementById(`reportPage${pageCurrent}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  },[pageCurrent])
  
  function handlePage(PageIndex){
    
    const targetElement = document.getElementById(`reportPage${PageIndex}`);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    dispatchReportBuilder({"pageCurrent":PageIndex})
    scrollTabIntoView(PageIndex);
  }

  function handlePageFooter(){
    dispatchReportBuilder({"pageFooter":!pageFooter})
  }

  const scrollTabIntoView = (index) => {
    if (pageFooterRefs.current[index]) {
      pageFooterRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };
 
  return (
    <div style={{overflow:"visible",
    //   position:"fixed",
      display:"flex",
      position:"relative",
      gap:"1rem",
      width:"100%",padding:"0.2rem",
      // marginTop:pageFooter?"0":"120px",
      height:pageFooter?"90px":"0",background:"#F8F8F8",
      boxSizing:"border-box"}}>
         <div onClick={handlePageFooter} style={{position:"absolute",
        zIndex:100,
        color:"black",
        display:"flex",justifyContent:"center",alignItems:"center",
        transform: 'translateX(-50%)', // Center horizontally
        top:"-20px",left:"50%",height:"20px",width:"100px",background:"#F8F8F8",borderRadius:"10px 10px 0px 0px"}}> 
        {!pageFooter && <ExpandLessIcon/>}
        {pageFooter &&<ExpandMoreIcon/>}
        </div>
    {pageFooter && 
    <div ref={pageFooterRef} style={{position:"relative",height:"100%",width:"100%",display:"flex",gap:"1rem",
    boxSizing:"border-box",alignItems:"center",paddingLeft:"1rem",
overflow:"auto"}}>
       
        
        {sortPages.length>0 && sortPages.map((obj, index) => (
                    <div
                    draggable
                    onDragStart={(e) => handleDragStart(e,obj.PageIndex)} /* Add drag start event handler */
                    onDrop={(e) => handleDrop(e,obj.PageIndex,stateReportBuilder,dispatchReportBuilder)} /* Add drop event handler */
                    onDragOver={handleDragOver}
                    onClick={()=>handlePage(obj.PageIndex)}
                    key={index}
                    id = {`reportPageFooter${index}`}
                    ref={(el) => pageFooterRefs.current[index] = el}
                    style={{
                        background:"#ffffff",
                        border:pageCurrent===obj.PageIndex&&"3px solid blue",
                        // padding:"1rem",
                        width: '100px',
                        boxShadow: 'var(--boxShadowGeneral)',
                        flexShrink: 0,
                        borderRadius:"10px",
                       position:"relative",
                       height:"90%",boxSizing:"border-box",
                       overflow:"hidden"
                    }}
                    >
                    <div style={{padding:"0.2rem"
                    ,fontSize:"0.7rem",alignItems:"center",display:"flex"}}>
                      {obj.PageIndex + 1}
                    <DeleteIcon fontSize='small' sx={{color:"gray",marginLeft:"auto"}} onClick={(e)=>handleDeletePage(e,stateReportBuilder,dispatchReportBuilder)}/>
                    </div>
                    <div style={{width:"100%",overflow:"hidden"
                    ,textOverflow:"ellipsis",whiteSpace:"wrap",fontSize:"0.8rem",padding:"0 0.25rem"}}>
                      {obj.PageName}
                      {/* {obj.PageName} */}
                      </div>
                    
                    </div>
                ))}



                <div style={{position:"sticky",padding:"0 0.5rem",right:"0",zIndex:"100",background:"#F8F8F8",height:"100%",display:"flex",alignItems:"center"}}>
                 <Button
                    startIcon={<AddIcon/>}
                    variant='contained'
                    onClick={()=>handleAddPageAtEnd(stateReportBuilder,dispatchReportBuilder)}
                    id = {`addPageReportFooter`}
                    style={{
                        boxShadow: 'var(--boxShadowGeneral)',
                        fontWeight:"bold",whiteSpace:"noWrap"
                    }}
                    >
                    Add Page
                    </Button></div>
           </div>}
      </div>
  )
}





function handleDragStart(e,PageIndex) {
  e.dataTransfer.setData('text/plain', PageIndex);
}

function handleDragOver(e) {
  e.preventDefault();
}

function handleDrop(e,PageIndex,stateReportBuilder,dispatchReportBuilder) {
  const {pages,pageCurrent} = stateReportBuilder
  e.preventDefault();
  const draggedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
  const targetIndex = parseInt(PageIndex, 10);

  if (draggedIndex !== targetIndex) {
    const updatedPages = [...pages];
    const [draggedItem] = updatedPages.splice(draggedIndex, 1); // Remove the dragged item
    updatedPages.splice(targetIndex, 0, draggedItem); // Insert the dragged item at the target position

    // Update PageIndex for all items
    const updatedPagesWithIndex = updatedPages.map((page, index) => ({
      ...page,
      PageIndex: index,
    }));
    dispatchReportBuilder({"pages":updatedPagesWithIndex });
    if(pageCurrent===draggedIndex){
    dispatchReportBuilder({"pageCurrent":targetIndex });}
   
    const targetElement = document.getElementById(`reportPage${targetIndex}`);

    if (targetElement) {
      targetElement.scrollIntoView({  block: 'start',
      inline: 'nearest',
      alignToTop: true,
    });
    }
  }
}
import React,{useState,useEffect} from 'react'
import axios from "axios";

export default function useEconCalendar({selectedDates}) {
    const [econCalendar, setEconInfo] = useState([]);
    useEffect(() => {
        if (selectedDates){
            getMetricInfo();
        }
      }, [selectedDates]);
    
      // GET COMPANY DATA
      async function getMetricInfo() {
        try {
          const responseMetric = await axios(`https://eodhd.com/api/economic-events?api_token=64795e4c5f6224.06757647776&from=${selectedDates.from}&to=${selectedDates.to}&country=US&fmt=json`);
          setEconInfo(responseMetric.data);
        } catch (error) {
          console.error(error);
        }
      }
    
    return {econCalendar}
    
}



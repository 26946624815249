import React from 'react'

export default function ContentMenu({ items }) {

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div>
      <h4 style={{ fontWeight: "bold" }}>Content</h4>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {items.map((item) => (
          <li key={item.id} style={{ marginBottom: "0.5rem" }}>
            <button
              onClick={() => handleScroll(item.id)}
              style={{
                background: "none",
                border: "none",
                color: "#007BFF",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "1rem",
              }}
            >
              {item.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

import React from 'react'

export default function Toggle({toggleMode,handleToggle,toggleValues}) {
  return (
    <div style={{display:"flex",borderRadius:"10px"
    ,borderColor:"rgb(10, 22, 34)", border:"2px solid black",width:"100%",cursor:"pointer"}}>
    <button onClick={()=>handleToggle(false)} 
    style={{background:!toggleMode?"black":"none",color:!toggleMode?"white":"black",borderRadius:"7px",
    border:"none",fontSize:"0.8rem",
    textOverflow:"ellipses",overflow:"hidden",whiteSpace:"nowrap",flex:!toggleMode&&1}} 
    value={false}>{toggleValues[0]}</button>
    <button onClick={()=>handleToggle(true)} 
    style={{background:toggleMode?"black":"none",
      color:toggleMode?"white":"black",borderRadius:"7px",border:"none"
      ,fontSize:"0.8rem",textOverflow:"ellipses",overflow:"hidden",whiteSpace:"nowrap",flex:toggleMode&&1}}  
    value={true}>{toggleValues[1]}</button>
    </div>

    
  )
}
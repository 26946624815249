import React, { useEffect, useState } from 'react'
import { useAppInfo } from 'AppState'
import styles from "./ReportLeftSidebar.module.css"
import { Alert } from '@mui/material'

export default function SidebarContentTemplate({content=[],showType}) {
    const {stateDash,stateReportBuilder,dispatchReportBuilder} = useAppInfo()
    const {pageCurrent,pages} = stateReportBuilder
    const {dragging,dragId} = stateReportBuilder
    const [categoryList,setCategoryList] = useState([])
    const handleDragEnd = () => {
      dispatchReportBuilder({"dragging":false})
      };
    
    useEffect(()=>{
      const categoryList = content.length>0 && Array.from(new Set(content.map((obj)=>obj.Category)))
      setCategoryList(categoryList)
    },[content])
    
    
    const handleDragStart = (e, obj) => {
        e.stopPropagation()
        e.dataTransfer.setData('application/json', JSON.stringify(obj));
        dispatchReportBuilder({"dragging":true,"dragId":obj.id})
      };
    function handleContent(data){
      const timestamp = new Date().getTime();
      const uniqueId = `${timestamp}`;
      const updatedPages = pages.map(obj => {
        if (obj.PageIndex === pageCurrent) {
          // Update the PageContent for the matching object
          return {
            ...obj,
            PageContent: [
              ...(obj.PageContent || []), // Ensure PageContent is an array
              { ...data,
                id: uniqueId,
                position: { x: 0, y: 0 },

              }
            ]
          };
        }
        // If the PageIndex doesn't match, return the original object
        return obj;
      });
      

      dispatchReportBuilder({"pages":updatedPages})
    }
  return (
    <>
       
        <div
        style={{
          padding: '1rem',
          display: 'flex',
          flexDirection:"column",
          width: '100%',
          boxSizing: 'border-box',
          gap:"1rem",
          zIndex:1000
        }}
      >
        {categoryList.length>0 && categoryList.map((category,index)=>
        <div index={index}>
            <h4 style={{fontWeight:"bold",color:"gray",borderBottom:"1px solid #ddd"}}>
              {category==="Company Logo"?"Company Logo (Dynamic)":category}</h4>
            <div  style={{
          padding: '1rem 0',
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          flexWrap: 'wrap',
          gap:"1rem"
        }}>
        {category==="Company Logo" && <Alert> This logo changes with search!</Alert>}
        {category==="Spreadsheet" && <Alert 
        severity="warning">
          Excel is not currently Available! 
        </Alert> }
            {content.map((obj, index) => (
            <>
           {obj.Category === category &&
          <div onClick={()=>handleContent(obj)} 
          style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}  key={`${obj.Type}-${index}`}>
          
          {obj?.ImgUrl &&
          <>
          <img
            id={`${obj.Type}-${index}`}
            className={styles.imgUrl}
            src={obj.ImgUrl}
            draggable
            // alt="draggable"
            onDragStart={(e) => handleDragStart(e, obj)}
            onDragEnd={handleDragEnd}
            style={{
              height: '50px',
              width: "50px",
              objectFit: 'contain',
              cursor:"pointer",
              padding:"0.25rem",
              boxSizing:"border-box",
              border: dragging && dragId === `${obj.id}` && '3px solid blue',
            }}
          />
          <h4>{showType && obj.Type}</h4>
          </>}
          {obj?.Button &&
          <>
          <button
            className={styles.elements}
            id={`${obj.Type}-${index}`}
            draggable
            // alt="draggable"
            onDragStart={(e) => handleDragStart(e, obj)}
            onDragEnd={handleDragEnd}
            style={{
              objectFit: 'cover',
              borderRadius:"20px",
              maxWidth:"200px",
              overflow:"hidden",
              whiteSpace:"nowrap",
              textOverflow:"ellipsis",
              padding:"0.5rem",
              cursor:"pointer",
              border: dragging && dragId === `${obj.id}` && '3px solid blue',
              ...obj.style?.fpntSize
            }}
          >
            {obj.Button}
          </button>
          </>}

          {obj?.Shape &&
          <>
          <div
            className={styles.elements}
            id={`${obj.Type}-${index}`}
            draggable
            // alt="draggable"
            onDragStart={(e) => handleDragStart(e, obj)}
            onDragEnd={handleDragEnd}
           
          >
            {obj?.Shape}
          </div>
          </>}

          </div>
          
          }
        
          </>
        ))}
        </div>
        </div>)}


      </div>

    </>
  )
}
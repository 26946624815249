
import React, { useEffect, useRef, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useAppInfo } from 'AppState';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';

export default function BackgroundVideo() {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
  
   function togglePlay() {
      const video = videoRef.current;
      if (video.paused) {
        video.currentTime = 3; // Set the time to 3 seconds
        video.play();
        setIsPlaying(true);
      } else {
        video.currentTime = 3; // Set the time to 3 seconds
        video.pause();
        setIsPlaying(false);
      }
    };
  return (
    <>
    
        <div style={{width: '100%',height:"100vh",boxSizing:"border-box",margin:0,boxSizing:"border-box",overflow:"hidden",position:"relative"}}>
         <video ref={videoRef} autoPlay muted loop style={{ width: '100%', height: '100%', border: 'none',margin:0, padding: 0 ,objectFit:"cover"}}>
           <source src="\Bokeh.mp4" type="video/mp4" />
           Your browser does not support the video tag.
         </video>
      
       </div>
    </>
  )
}

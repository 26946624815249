import React from 'react';

import { useAppInfo } from 'AppState';
import { assetCode, dfin } from 'content/constants';
import SearchTickerDropdown from 'search/SearchTickerDropdown';
import DiscountRate from 'analytics/ticker/stocks/Value/DiscountRate';
import FairValue from 'analytics/ticker/stocks/Value/FairValue';
import FcfScenario from 'analytics/ticker/stocks/Value/FcfScenario';
import GrowthRate from 'analytics/ticker/stocks/Value/GrowthRate';
import CompanyFinancialsUI from 'analytics/ticker/stocks/CompanyFinancialsUI';

import GuideTemplate from '../Template/GuideTemplate';

export default function DiscountedCashFlow({search=true}) {
    const {stateTicker} = useAppInfo()
    const contentAfterHeading = search && (
        <div
          style={{
            padding: "0 1rem",
            width: "50%",
            boxSizing: "border-box",
            marginTop: "0.05rem",
          }}
        >
          <SearchTickerDropdown
            tickerType={assetCode?.Stocks}
            defaultSearchTerm={stateTicker.tickerStock}
          />
        </div>
      );
      
  return (
   <GuideTemplate description={description} contentAfterHeading={contentAfterHeading} 
   name={"Understanding DCF (Discounted Cash Flow): Valuation Method, Importance, and Calculation"} 
    steps={steps}/>
  )
}

const description = `
Discounted Cash Flow (DCF) analysis is a critical financial valuation technique used to estimate the intrinsic value of an investment based on its projected future cash flows. The method involves discounting these future cash flows back to their present value using a specified discount rate, reflecting the time value of money and investment risk. Essential inputs for a successful DCF analysis include accurate projections of cash flows, a realistic discount rate, an anticipated growth rate, and an estimation of terminal value at the end of the forecast period.<br\><br\>
While DCF is a powerful tool for valuation, it comes with significant challenges. The accuracy of a DCF valuation heavily depends on the quality of the future cash flow projections, which can be highly uncertain. Misjudgments in setting the discount rate or growth assumptions can lead to substantial errors, potentially resulting in overvaluation or undervaluation. Therefore, it's vital to approach DCF results with caution and complement them with other valuation methods to get a well-rounded view of an investment's worth. This analysis is particularly tricky for new companies or startups with unpredictable future cash flows.
`

const steps = [
    {
        label: "Introduction",
        keyPoints: [
          "Discounted Cash Flow (DCF) analysis is a widely used valuation method in finance for estimating the value of an investment based on its expected future cash flows.",
          "It is based on the principle that the value of money today is worth more than the same amount in the future, due to its potential earning capacity or interest rates.",
          "DCF calculates the present value of future cash flows by discounting them back to their present value using a discount rate.",
          "The key inputs for DCF analysis include cash flow projections, discount rate, growth rate and terminal value estimation.",
          "DCF analysis provides investors with a quantitative framework to assess the attractiveness of an investment opportunity."
        ],
        warning: [
          "DCF analysis relies heavily on future cash flow projections, which are inherently uncertain. Errors in forecasting can lead to inaccurate valuation estimates.",
          "Using inappropriate discount rates or growth assumptions can distort the valuation results, potentially leading to overvaluation or undervaluation of the investment.",
          "It's essential to exercise caution when interpreting DCF results and consider them alongside other valuation methods to obtain a more comprehensive understanding of the investment's value.",
          "DCF analysis may not be suitable for investments with negative free cash flows, as it assumes positive cash flows throughout the forecast period.",
          "DCF analysis may be challenging for new companies or startups where future cash flows are difficult to predict accurately."
        ],
        success: [
          "Understanding the fundamentals of DCF analysis and its importance in investment valuation.",
          "Identifying the key inputs required for DCF calculation, including cash flow projections, discount rate, growth rate and terminal value estimation."
        ]
      },
      {
        label: "Forecast Future Cash Flows",
        keyPoints: [
          "Forecasting future cash flows involves projecting the cash generated by the investment over a defined period, essential for valuation analysis.",
          "Consideration of accounting policies and estimates is vital to ensure the accuracy and reliability of cash flow forecasts, as changes in standards or estimates can significantly impact projections.",
          "FCFF (Free Cash Flow to Firm) is computed as Net Income + Non-cash Expenses - Changes in Working Capital - Capital Expenditures + Net Borrowing, representing cash available to all investors.",
          "Alternatively, FCFF can be derived as Cash Flow from Operations minus Capital Expenditure.",
          "FCFE (Free Cash Flow to Equity) is calculated as FCFF - Interest Expense * (1 - Tax Rate) + Net Borrowing - Debt Repayments, reflecting cash available to equity shareholders after debt obligations.",
          "FCF (Free Cash Flow) refers to FCFF, with the choice between FCFF and FCFE depending on the valuation objective and discount rate used—WACC for FCFF and cost of equity for FCFE."
        ],
        warning: [
          "Forecasting future cash flows involves significant uncertainty and relies on assumptions about future market conditions, industry trends, and company performance.",
          "Overly optimistic or pessimistic projections can lead to inaccurate valuation results. It's crucial to base forecasts on realistic and well-supported assumptions.",
          "Ensure that assumptions underlying cash flow projections are conservative and account for potential risks and uncertainties.",
          "Regularly review and update cash flow forecasts to reflect changes in market conditions, business performance, and external factors.",
          "Cash Flow from Operations is not equal to Free Cash Flow.",
          "Free Cash Flow (FCF) is a key metric used to evaluate a company's financial performance and is not readily available on financial statements. It requires adjustments to reported financials to derive an accurate measure of cash flow available to investors."
        ],
        success: [
          "Developing accurate and realistic cash flow projections based on thorough analysis of historical data, industry trends, and market outlook.",
          "Ensuring consistency and coherence in cash flow forecasts by aligning revenue projections with expense and capital expenditure estimates.",
          "Performing sensitivity analysis to assess the impact of varying assumptions on cash flow projections and valuation results.",
          "Incorporating scenario analysis to evaluate the resilience of cash flow projections under different economic or market scenarios.",
          "Understanding the concepts of Free Cash Flow to Firm (FCFF) and Free Cash Flow to Equity (FCFE) and their relevance in valuation analysis."
        ],
        content: <CompanyFinancialsUI hideOnHover={true} report={false} metricSel={[dfin.fcf,dfin.cfo]}/>
      },
      {
        label: "Forecast Period",
        keyPoints: [`Define the forecast period over which future cash flows will be projected. The forecast period typically ranges from 5 to 10 years and should reflect the visibility and predictability of cash flows for the investment.`],
        warning: [
          "Selecting an inappropriate forecast period may lead to inaccurate valuation estimates.",
          "Consider the company's growth prospects, industry dynamics, and market conditions when determining the appropriate forecast period."
        ],
        success: [
          "Choosing a forecast period that provides sufficient visibility and predictability of cash flows while capturing the company's growth potential.",
          "Performing sensitivity analysis to assess the impact of varying forecast periods on the valuation results."
        ]
      },
    {
      label: "Determine Discount Rate",
      keyPoints: ["The discount rate, also known as the required rate of return or cost of capital, represents the investor's expected rate of return for undertaking the investment risk."],
      warning: [
        "Selecting an inappropriate discount rate can significantly impact the valuation results. It's essential to use a discount rate that reflects the investment's risk profile and opportunity cost.",
        "Underestimating or overestimating the discount rate can lead to undervaluation or overvaluation of the investment, respectively."
      ],
      success: [
        "Determining an appropriate discount rate based on the investment's risk factors, including market risk, industry risk, and company-specific risk.",
        "Using a consistent and well-justified approach to estimate the discount rate, such as the Capital Asset Pricing Model (CAPM) or Weighted Average Cost of Capital (WACC)."
      ],
      content:<DiscountRate/>
    },
    // Add more steps here, including growth rate, terminal rate, and forecast period
    {
      label: "Growth Rate",
      keyPoints: ["Estimate the growth rate of future cash flows for the forecast period. This rate typically reflects the expected growth rate of the company or industry."],
      warning: [
        "Overly optimistic growth rate assumptions may lead to inflated valuation estimates.",
        "Ensure that growth rate assumptions are supported by thorough analysis of historical growth trends, market dynamics, and industry forecasts."
      ],
      success: [
        "Selecting a realistic and well-supported growth rate assumption that aligns with the company's growth prospects and industry outlook.",
        "Performing sensitivity analysis to assess the impact of varying growth rate assumptions on the valuation results."
      ],
      content:<GrowthRate/>
    },
    {
      label: "Terminal Growth Rate",
      keyPoints: ["Determine the terminal growth rate, which represents the perpetual growth rate of cash flows beyond the forecast period.", 
      "This rate is typically based on long-term industry growth rates or the economy's overall growth rate.",
      `The Terminal Growth Rate (TGR) is typically assumed to be between 0% and 2% in financial valuation models.`, 
      `A common basis for determining the TGR is the overall economy's growth rate, such as the GDP growth rate. This assumption reflects the long-term sustainable growth prospects for the company or industry beyond the forecast period.`],
      warning: [
        "Selecting an inappropriate terminal growth rate can significantly impact the terminal value calculation and overall valuation results.",
        "Ensure that the terminal growth rate assumption is reasonable and consistent with long-term economic and industry trends."
      ],
      success: [
        "Choosing a terminal growth rate assumption that reflects sustainable long-term growth prospects for the company or industry.",
        "Validating the terminal growth rate assumption through benchmarking against historical growth rates and industry forecasts."
      ]
    },
   
    {
        label: "Fair Value",
        keyPoints: [
          "Fair Value, also known as Intrinsic Value, is the estimated true worth of an asset or investment based on its fundamental characteristics and expected future cash flows.",
          `The intrinsic value of any asset is the value of the asset given a hypothetically complete understanding
          of the asset’s investment characteristics.`, `For any particular investor, an estimate
          of intrinsic value reflects his or her view of the “true” or “real” value of an asset.
          If one assumed that the market price of an equity security perfectly reflected its
          intrinsic value, “valuation” would simply require looking at the market price.`
        ],
        warning: [
          "Reliance solely on Fair Value estimates may not fully capture market sentiment and short-term fluctuations in investor behavior.",
          "Fair Value calculations are based on assumptions about future cash flows, growth rates, and discount rates, which are subject to uncertainty and may vary over time.",
          "It's essential to exercise caution when interpreting Fair Value estimates and consider them alongside other valuation methods, market trends, and qualitative factors to make informed investment decisions."
        ],
        success: [
          "Choosing a forecast period that provides sufficient visibility and predictability of cash flows while capturing the company's growth potential.",
          "Performing sensitivity analysis to assess the impact of varying assumptions on Fair Value estimates and investment decisions.",
          "Comparing Fair Value estimates with current market prices to identify potential investment opportunities.",
          "Regularly reviewing and updating Fair Value estimates based on changes in market conditions, business performance, and external factors."
        ],
        content:<FairValue/>
      },
      {
        label: "Sensitivity & Scenario Analysis",
        keyPoints: [
          "Scenario analysis involves evaluating the impact of different scenarios or assumptions on the valuation results.",
          "This analysis helps assess the sensitivity of the investment's value to changes in key variables, such as revenue growth rates, operating margins, and discount rates.",
          "By considering various scenarios, such as optimistic, base case, and pessimistic, investors can better understand the range of potential outcomes and associated risks.",
          "Scenario analysis provides valuable insights into the robustness of the investment thesis and helps inform decision-making under uncertainty."
        ],
        warning: [
          "Scenario analysis relies on assumptions about future market conditions, which may not accurately reflect actual outcomes.",
          "Overly optimistic or pessimistic scenarios may lead to biased valuation results and misinterpretation of investment risks.",
          "It's essential to use realistic and well-supported assumptions in scenario analysis and interpret the results in conjunction with other qualitative and quantitative factors."
        ],
        success: [
          "Performing scenario analysis enhances the robustness of the valuation process by considering multiple potential outcomes.",
          "Identifying key drivers of value and their impact on investment performance helps investors make informed decisions and manage risk effectively."
        ],
        content: <FcfScenario/>
      }
  ];
import React, { useState } from 'react'
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { useAppInfo } from 'AppState';
import InterestsIcon from '@mui/icons-material/Interests';
import StarsIcon from '@mui/icons-material/Stars';
import ReportLeftSidebarContent from './ReportLeftSidebarContent';
import { Button, ClickAwayListener, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LeftSidebarFloating from 'sidebar/LeftSidebar/LeftSidebarFloating';
import GeneratePdf from './GeneratePdf';
import ReportDetails from 'report/ReportDetails';



export default function ReportLeftSideBarFloat() {
  
  const {stateReportBuilder,dispatchReportBuilder} = useAppInfo()
  const reportNames = stateReportBuilder.reportNames
  
  const leftSidebarMenu = [
    { icon: <ViewQuiltOutlinedIcon fontSize='medium' />, label: 'Templates' },
    { icon: <TextFieldsOutlinedIcon fontSize='medium' />, label: 'Text' },
    { icon: <InterestsIcon fontSize='medium' />, label: 'Elements' },
    { icon: <StarsIcon fontSize='medium' />, label: 'Icons' },
    { icon: <SsidChartOutlinedIcon fontSize='medium' />, label: 'Charts' },
    { icon: <TableChartOutlinedIcon fontSize='medium' />, label: 'Table' },
    { icon: <FileUploadOutlinedIcon fontSize='medium' />, label: 'Upload' },
  ]
  
  function handleLeftSidebar(label,v){
    dispatchReportBuilder({"leftSidebar":v,"leftSidebarContent":label})  
  }





  return (
    
    <LeftSidebarFloating header={"Build your Report"} sx={{padding:"0.5rem",width:"20%"}}>
      <div style={{display:"flex",flexDirection:"column",width:"100%",height:"100%",flex:1}}>
    <div style={{padding:"0.5rem 0"}}>
    <ReportDetails/>
    </div>
    <div style={{flex:1}}>
    {!stateReportBuilder.leftSidebar && 
    <>
    {leftSidebarMenu.map(({ icon, label }) => (
    <div 
    key={label} style={{ display: "flex",flexDirection:"column"
    ,alignItems:"center",zIndex:1000}}>
      <Button  
      onClick={()=>handleLeftSidebar(label,true)}
      className={`sidebar-button ${stateReportBuilder.leftSidebar && label===stateReportBuilder.leftSidebarContent && "active"}`}
      sx={{minWidth:"0",justifyContent:"left",width:"100%",display: "flex",gap:"1rem"}}
      >
        {icon}
        <Typography sx={{fontSize:"1rem",textTransform:"none"}} style={{ margin: 0,color:(stateReportBuilder.leftSidebar && label===stateReportBuilder.leftSidebarContent)?"black":"gray"}}>{label}</Typography>
      </Button>
    </div>
  ))}
     </>}


     {stateReportBuilder.leftSidebar && 
        <>
        <div style={{display:"flex",gap:"1rem",width:"100%",boxSizing:"border-box",alignItems:"center",position:"sticky",top:0,background:"white",zIndex:"100"}}>
        <IconButton
        onClick={()=>{dispatchReportBuilder({"leftSidebar":false})}}
        ><ArrowBackIosNewIcon style={{fontSize:"medium"}} />
        </IconButton>
        <Typography style={{fontWeight:"bold"}}>
            {stateReportBuilder.leftSidebarContent}
        </Typography>
        </div>
        <ReportLeftSidebarContent/>
        </>
      }
      </div>
      <div style={{marginTop:"auto",display:"flex",flexDirection:"column",gap:"1rem",width:"100%"}}>
      <Button 
      variant='outlined'
      onClick={()=>{dispatchReportBuilder({ addNewReport: true })}}>Create New Report</Button>
      
      <GeneratePdf/>
      </div>
     </div>
</LeftSidebarFloating>
  )
}
import React, { useMemo, useState } from 'react'
import useTechnicalRating from 'data/technicals/useTechnicalRating'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';
import TabGeneral from 'components/Tabs/TabGeneral'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

solidGauge(Highcharts); 

export default function TradeSignals() {
    const {stateTicker} = useAppInfo()
    const tickerSelected = useMemo(() => [stateTicker.tickerSelected], [stateTicker.tickerSelected]);
    const [tabIndex,setTabIndex] = useState(0)
    const tabList = ["Daily","Weekly","Monthly"]
    const {technicalRating,tRLoading} = useTechnicalRating(tickerSelected,tabList[tabIndex].toLowerCase())
    const {
            overallRating,
            trendIndicators,
            oscillators,
            pivotTable
    } = technicalRating;
    
   
return (
    <>
        
<div style={{display:"flex",justifyContent:"center"}}>
<TabGeneral tabList={tabList} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)}/>
</div>
{tRLoading && <LoadingSkeletonTable sx={{width:"100%"}}/>}
{!tRLoading && <div style={{display:"flex",
flexDirection:"column",gap:"1rem",width:"100%"
}}>
{overallRating && <div style={{height:"200px",width:"300px",padding:"1rem",alignSelf:"center"}}>
    <GaugeChart rating = {overallRating}/></div>}

{(trendIndicators) && 
<div><TableGen tableLayout='fixed' data={trendIndicators}/></div>}
{(oscillators) && <div><TableGen tableLayout='fixed' data={oscillators}/></div>}


<h2 style={{borderBottom:"1px solid #ddd"}}>Pivots</h2>
{(pivotTable) && <div><TableGen pagination={false} tableLayout='fixed' data={pivotTable}/></div>}
</div>}
    </>
  )
}





const GaugeChart = ({ rating }) => {
    // Maps ratings to a numeric scale
    const ratingToScale = {
        'Strong Sell': 10,
        'Sell': 30,
        'Neutral': 50,
        'Buy': 70,
        'Strong Buy': 100
    };

    // Get numeric value for the gauge from the rating
    const gaugeValue = ratingToScale[rating] || 0;

    // Configuration for the gauge chart
    const options = {
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false
        },
        title: {
            text: null
        },
        pane: {
            startAngle: -90,
            endAngle: 90,
            background: null,
            center: ['50%', '75%'],
            size: '110%'
        },
       
        yAxis: {
            min: 0,
            max: 100,
            minorTickInterval: null,
            tickPixelInterval: 72,
            tickPosition: 'inside',
            tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
            tickLength: 20,
            tickWidth: 2,
            lineWidth: 0,
            plotBands: [{
                from: 0,
                to: 20,
                color: '#DF5353', // Red
                thickness: 20,
                label: {
                    text: 'Strong Sell',
                    style: {
                        color: 'black',
                        fontWeight: 'bold'
                    },
                    y: 20
                }
            }, {
                from: 20,
                to: 40,
                color: 'orange', // Orange
                thickness: 20,
                label: {
                    text: 'Sell',
                    style: {
                        color: 'black',
                        fontWeight: 'bold',
                    },
                    y: 20,
                    x:50
                }
            }, {
                from: 40,
                to: 60,
                color:'yellow', // Yellow
                thickness: 20,
                label: {
                    text: 'Neutral',
                    style: {
                        color: 'black',
                        fontWeight: 'bold'
                    },
                    y: 0,
                    x:120
                }
            }, {
                from: 60,
                to: 80,
                color: '#55BF3B', // Light green
                thickness: 20,
                label: {
                    text: 'Buy',
                    style: {
                        color: 'black',
                        fontWeight: 'bold'
                    },
                    y: 20
                }
            }, {
                from: 80,
                to: 100,
                color: '#55BF3B', // Green
                thickness: 20,
                label: {
                    text: 'Strong Buy',
                    style: {
                        color: 'black',
                        fontWeight: 'bold'
                    },
                    y: 20,
                    x:-40
                }
            }],
            labels: {
                enabled: false
            },
            title: {
                text: '',
            }
        },
        plotOptions: {
            gauge: {
                dial: {
                    radius: '100%'
                },
                pivot: {
                    radius: 5
                },
                dataLabels: {
                    enabled: true,
                    y: 5,
                    borderWidth: 0,
                    useHTML: true,
                    formatter: function () {
                        return `<div style="text-align:center;"><span style="font-size:20px;color:black;">${rating}</span></div>`;
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        series: [{
            name: '',
            data: [gaugeValue],
            tooltip: {
                enabled: false
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: 12,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'gray',
                radius: 6
            }
        }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: "100%", width: "100%" } }}
        />
    );
};


import { useAppInfo } from 'AppState';
import { marketHeaderDict } from 'content/contentMarket';
import useMarketTickerInfo from 'data/ticker/useMarketTickerInfo';
import React, { useEffect } from 'react'

export default function useSetMarketToday() {
  const {dispatchMarket} = useAppInfo()

  const {
    close: closeMkt,
    trendTable: trendTableMkt,
    marketInfoLoading: marketInfoLoadingMkt,
  } = useMarketTickerInfo(Object.keys(marketHeaderDict));
  
  useEffect(() => {
    if (!marketInfoLoadingMkt) {
      dispatchMarket({
        closeInterMarket: closeMkt,
        trendTableInterMarket: trendTableMkt,
        marketInfoLoadingInterMarket: marketInfoLoadingMkt
    });
    
    }
  }, [closeMkt]);
}

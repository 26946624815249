import React, { useEffect, useRef, useState } from "react";
import { useAppInfo } from "AppState";
import DragObjects from "../Objects/DragObjects";
import DragResizeObject from "../Objects/DragResizeObject";
import LoadingSkeletonPage from "components/Loading/LoadingSkeletonPage";
import PageToolBox from "./PageToolBox";


const DropZone = () => {
  const { stateReportBuilder, dispatchReportBuilder} = useAppInfo();
  const { draggingObject, pageCurrent, pages} = stateReportBuilder;
  const [sortPages, setSortPages] = useState([]);
 
  useEffect(() => {
    if (pages){
    const clonedPages = [...pages];
    clonedPages.sort((a, b) => a.PageIndex - b.PageIndex);
    setSortPages(clonedPages);}
  }, [pages]);


  function handlePageCurrent(index) {
    dispatchReportBuilder({"pageCurrent":index });
  }

  function handleDragOver(e) {
    e.preventDefault();
  }


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "2rem",
        width: "100%",
        boxSizing: "border-box",
        padding: "0.5rem 1rem",
        overflow:"hidden",
        // overflowY: "auto",
        position:"relative",
        height:"100%"
      }}
    >

      {!stateReportBuilder.reportLoaded && (
        <PageLoading/>
      )}

      

      {sortPages.length > 0 &&
        sortPages.map((obj, index) => (
          obj.PageIndex === pageCurrent &&
          <div
            id={`reportPage${obj.PageIndex}`}
            key={index}
            onClick={() => handlePageCurrent(obj.PageIndex)}
           
            // onPinch={handlePinch}
            // onTouchMove={handlePinch}
            style={{
              margin: "0",
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              flexShrink: 0,
              display:"flex",flexDirection:"column"
              // WebkitTransformOrigin: "0 0",
              // transformOrigin: "top center",
              // transform: stateReportBuilder.zoomLevel
            }}
          >
            
            <PageToolBox pageNumber={index+1} page={obj}/>
            <div
              id={"dropZoneReport"}
              // id={`reportPage${obj.PageIndex}`}
              // onWheel={(e)=>{e.preventDefault();e.stopPropagation();handleZoom(e,`reportPage${obj.PageIndex}`)}}
              onDrop={(e) => handleDropObject(e,stateReportBuilder,dispatchReportBuilder)}
              onDragOver={handleDragOver}
              style={{
                boxSizing: "border-box",
                padding: "0.5rem",
                border: pageCurrent !== obj.PageIndex && "1px solid #ddd",
                boxShadow:pageCurrent === obj.PageIndex && "var(--boxShadowGeneral)",
                overflow: draggingObject ? "visible" : "hidden",
                background:obj?.PageBackground || "white",
                position: "relative",
                flex:1,
                width: "100%",
                height: "100%",zIndex:1,borderRadius:"4px"
              }}
            >
              {obj.PageContent &&
                obj.PageContent.map((item,index) => (
                  <React.Fragment key={index}>
                    {item.Type && (
                      <>
                        <DragResizeObject
                          height={item.style?.height ? item.style?.height : "50%"}
                          width={item.style?.width ? item.style?.width: "50%"}
                          pageIndex={obj.PageIndex}
                          item={item}
                          sx={{
                            position: "absolute",
                            top: item.position
                              ? `${item.position.top}%`
                              : "50%",
                            left: item.position
                              ? `${item.position.left}%`
                              : "50%",
                            ...item.style,
                          }}
                        >
                          <DragObjects item={item} pageIndex={obj.PageIndex} />
                        </DragResizeObject>
                      </>
                    )}
                  </React.Fragment>
                ))}
            </div>
           
          </div>
        ))}
    </div>
  );
};

export default DropZone;


function PageLoading(){
  return (
    <div
    style={{
      zIndex:100,width:"100%",height:"100%",borderRadius:"5px"
      ,padding:"1rem",boxSizing:"border-box"
      // transform: "translate(50%,-50%)",
    }}
  >
    <LoadingSkeletonPage/>
  </div>
  )
}



const handleDropObject = (e,stateReportBuilder,dispatchReportBuilder) => {
  e.preventDefault();
  const boxParent = document.getElementById("dropZoneReport").getBoundingClientRect()
  
  let data;
  const jsonString = e.dataTransfer.getData("application/json");

  try {
    data = JSON.parse(jsonString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  if (data) {
    const timestamp = new Date().getTime();
    const uniqueId = `${timestamp}`;
    const mouseX = e.pageX - e.target.getBoundingClientRect().x;
    const mouseY = e.pageY - e.target.getBoundingClientRect().y;
    const top = (mouseY / boxParent.height) * 100;
    const left = (mouseX / boxParent.width) * 100;

    const updatedPages = stateReportBuilder.pages
      .map((obj) => {
        if (obj.PageIndex === stateReportBuilder.pageCurrent) {
          // Update the PageContent for the matching object
          return {
            ...obj,
            PageContent: [
              ...(obj.PageContent || []), // Ensure PageContent is an array
              {
                ...data,
                id: uniqueId,
                position: {
                  ...(data.position || {}), // Spread existing properties
                  top: top,
                  left: left,
                },
              },
            ],
          };
        }

        // If the PageIndex doesn't match, return the original object
        return obj;
      })
      .sort((a, b) => a.PageIndex - b.PageIndex);
    
    
    dispatchReportBuilder({"pages":updatedPages,"dragging":false});
  }
};



import { useEffect, useState } from "react";
import { financialModelingPrepApiKey } from "data/keys";
import axios from 'axios';

export default function useCompanyExecutives(ticker) {
    const [executives, setData] = useState([]);
    const [loading,setLoading] = useState(false)
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(
            `https://financialmodelingprep.com/api/v3/key-executives/${ticker}?apikey=${financialModelingPrepApiKey}
            `
          );
          setData(response.data);
        } catch (error) {
          console.error(error);
        }
        finally{ 
        setLoading(false)
        }
      };
  
      if (ticker) {
        fetchData();
      }
    }, [ticker]);
  
    return { executives ,loading};
  }


import React from 'react'
import GoalsProjectionEdit from './GoalsProjectionEdit'
import GoalRealizeSteps from './GoalRealizeSteps'
import GoalsOverview from './GoalsOverview'

export default function GoalInfo({info}) {
  const content = {
    "Overview":<GoalsOverview displayType='main'/>,
    "Steps to realize Goals":<GoalRealizeSteps/>,
    "Projections":<GoalsProjectionEdit/>
  }
  return (
    <>
    {content[info]}
    </>
  )
}

import TextInput from 'components/Inputs/TextInput';
import SelectBox from 'components/Inputs/SelectBox';
import DatePicker from 'components/Inputs/DatePicker';
import React from 'react';
import { compoundingOptions, paybackPeriodOptions, periodTypes } from 'appState/initialStateMyFinancials';
import ColorPicker from 'components/Inputs/ColorPicker';

export function InputColor({ handleChange, fieldObj, disabled = false,}) {
  return (
    <ColorPicker
      icon='bookmark'
      defaultColor={fieldObj?.color}
      onChange={(v) => handleChange('color', v, fieldObj.id)}
      disabled={disabled}
    />
  );
}

export function InputType({ handleChange, label = false, fieldObj, typeOptions = [], disabled = false, variant = "standard" }) {
  return (
    <SelectBox
      label={label ? 'Type' : ''}
      options={typeOptions}
      value={fieldObj?.type}
      onChange={(e, v) => handleChange('type', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputName({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      label={label ? 'Name' : ''}
      value={fieldObj?.name}
      onChange={(v) => handleChange('name', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputPriority({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Priority' : ''}
      min={1}
      value={fieldObj?.priority}
      onChange={(v) => handleChange('priority', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputAmount({ handleChange, label = false, fieldObj, currencySymbol = '$', disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? `Amount (${currencySymbol})` : ''}
      value={fieldObj?.amount}
      onChange={(v) => handleChange('amount', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputDate({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <DatePicker
      label={label ? 'Date' : ''}
      date={fieldObj?.date}
      handleDate={(v) => handleChange('date', new Date(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputPeriod({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <SelectBox
      label={label ? 'Period' : ''}
      options={periodTypes}
      value={fieldObj?.period}
      onChange={(e, v) => handleChange('period', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputForecastPeriod({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Forecast Period (Years)' : ''}
      value={fieldObj?.forecastPeriod}
      onChange={(v) => handleChange('forecastPeriod', Number(v), fieldObj.id)}
      disabled={fieldObj?.period==="One Time" || disabled}
      variant={variant}
    />
  );
}

export function InputGrowthRate({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Annual Growth/Inflation Rate (%)' : ''}
      value={fieldObj?.growthRate}
      onChange={(v) => handleChange('growthRate', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputReturnExpected({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Annual Expected Return (%)' : ''}
      value={fieldObj?.return}
      onChange={(v) => handleChange('return', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputInterestRate({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Annual Interest Rate (%)' : ''}
      value={fieldObj?.interestRate}
      onChange={(v) => handleChange('interestRate', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputVolatility({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Annual Expected Volatility (%)' : ''}
      value={fieldObj?.volatility}
      onChange={(v) => handleChange('volatility', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputCompounding({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <SelectBox
      label={label ? 'Compounding' : ''}
      options={compoundingOptions}
      value={fieldObj?.compounding}
      onChange={(e, v) => handleChange('compounding', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputPaybackPeriod({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <SelectBox
      label={label ? 'Payback Period' : ''}
      options={paybackPeriodOptions}
      value={fieldObj?.paybackPeriod}
      onChange={(e, v) => handleChange('paybackPeriod', v, fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

export function InputLoanTerm({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <div style={{ display: 'flex', gap: '0.5rem' }}>
      <TextInput
        type="number"
        label={label ? 'Loan Term (Years)' : ''}
        value={fieldObj?.loanTerm?.y}
        onChange={(v) => handleChange('loanTerm', { y: Number(v), m: fieldObj?.loanTerm?.m }, fieldObj.id)}
        disabled={disabled}
        variant={variant}
      />
      <TextInput
        type="number"
        label={label ? 'Loan Term (Months)' : ''}
        value={fieldObj?.loanTerm?.m}
        onChange={(v) => handleChange('loanTerm', { y: fieldObj?.loanTerm?.y, m: Number(v) }, fieldObj.id)}
        disabled={disabled}
        variant={variant}
      />
    </div>
  );
}

export function InputTargetAllocation({ handleChange, label = false, fieldObj, disabled = false, variant = "standard" }) {
  return (
    <TextInput
      type="number"
      label={label ? 'Target Allocation (%)' : ''}
      value={fieldObj?.targetAllocation}
      onChange={(v) => handleChange('targetAllocation', Number(v), fieldObj.id)}
      disabled={disabled}
      variant={variant}
    />
  );
}

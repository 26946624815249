import React from 'react'
import useIPO from 'data/newsEvents/useIPO'
import TableGen from 'components/Table/TableGen'
import LoadingSkeletonTable from 'components/Loading/LoadingSkeletonTable'

export default function IPO() {
  const {ipoInfo} = useIPO()
  const ipoInfoFilt = ipoInfo && ipoInfo.filter(obj=>obj["EXCHANGE"]==="US")
  return (
    <>
    {!ipoInfoFilt.length>0 && <LoadingSkeletonTable/>}
    {ipoInfoFilt && <TableGen data={ipoInfoFilt}/>}
    </>
  )
}

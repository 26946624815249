import React, { useEffect, useState } from 'react'
import Income from 'myFinancials/Cashflow/Income'
import ExpenseDetails from 'myFinancials/Cashflow/ExpenseDetails'
import AccordionTSCustom from 'components/Surface/AccordionTSCustom'
import { Alert, Button, ButtonGroup } from '@mui/material'
import { useAppInfo } from 'AppState'
import PopoverButton from 'components/Modal/PopoverButton'
import BorderBox from 'components/Layout/BorderBox'
import GoalAdd from './GoalAdd'
import AssetProjections from 'myFinancials/Asset/AssetProjections'
import GoalFunding from './GoalFunding'
import GoalSuccessFailureScenario from './GoalSuccessFailureScenario'

export default function GoalRealizeSteps() {
   
  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <GoalSuccessFailureScenario showInfo/>
   

    </div>


  )
}


function GoalRealizeStepsAccordian(){

  return ( <BorderBox sx={{display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    
    <h4 style={{fontWeight:"bold",color:"green",padding:"0.5rem"}}>Steps to Realize your Goals</h4>
    
    <AccordionTSCustom title={"1. Set Realistic Goals and Priortize them:"}>
    <Alert>Goal Setting is a key part of your financial planning and success.</Alert>
    <Alert>1. Set Realistic Goals & Priortize them</Alert>
    <GoalAdd variant='contained'/>
      </AccordionTSCustom>
        <AccordionTSCustom title={"2. Fund Goals from a Certain Source of Cash:"}>
        <GoalFunding/>
        </AccordionTSCustom>
        <AccordionTSCustom title={"3. Budget Expense & Manage Liabilities:"}>
        <div style={{display:"flex",gap:"0.5rem",flexWrap:"wrap"}}>
        <PopoverButton title='Expense Budegting' showLabel variant='outlined'>
          <ExpenseDetails/>
        </PopoverButton>
        <PopoverButton title='Liability Management' showLabel variant='outlined'>
          <ExpenseDetails/>
        </PopoverButton>
        </div>
        </AccordionTSCustom>
        <AccordionTSCustom title={"4. Have a stable Income & build long-term Assets:"}>
        <div style={{display:"flex",gap:"0.5rem",flexWrap:"wrap"}}>
        <PopoverButton title='Income Projection' showLabel variant='outlined'>
          <Income/>
        </PopoverButton>
        <PopoverButton title='Asset Projection' showLabel variant='outlined'>
        <AssetProjections showDate editDetails/>
        </PopoverButton>
        </div>
        </AccordionTSCustom>
        <AccordionTSCustom title={"5. Find Opportunities that will help meet your goals:"}>
        <Alert severity='warning'>Investing in a risky asset may not be the be the best options.</Alert>
        </AccordionTSCustom>
        </BorderBox>)
}
import { LinearProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppInfo } from "AppState";
import { dfin } from "content/constants";
import { getSpecificArrayObjKeys, numberDecimal } from "utils/utilGeneral";
import PeriodSlider from "components/Inputs/PeriodSlider";
import PriceValueCustom from "components/Charts/PriceValueCustom";
import ForecastTable from "./ForecastTable";
import useRealTimePrice from "data/eod/useRealTimePrice";

export default function FairValue() {
  const { stateStock, stateTicker } = useAppInfo();
  const companySelected = stateStock.companySelected;
  const [growthRate, setGrowthRate] = useState(10);
  const [discountRate, setDiscountRate] = useState(12);
  const [terminalRate, setTerminalRate] = useState(2);
  const [forecastPeriod, setForecastPeriod] = useState(10);
  const [fcfBeg,setFcfBeg] = useState()
  const [fairValueMultiple, setFairValueMultiple] = useState();
  const [fvTable, setFvTable] = useState();
  const [currentPeriod, setCurrentPeriod] = useState([]);
  const [statementInfo,setStatementInfo] = useState([])
  const { price } = useRealTimePrice({ tickerSel: stateTicker.tickerStockCode });

  function handleGrowth(e) {
    setGrowthRate(e.target.value);
  }
  function handleDiscount(e) {
    setDiscountRate(e.target.value);
  }
  function handleTerminal(e) {
    setTerminalRate(e.target.value);
  }
  function handleForecast(e) {
    setForecastPeriod(e.target.value);
  }



  useEffect(() => {
    const coAnnualInfo = stateStock.annualInfo.filter((obj) => obj[dfin.ticker] === companySelected);
    setStatementInfo(coAnnualInfo)
    let pvSum = 0;
    let baseFcf = 1;
    for (let n = 0; n <= forecastPeriod; n++) {
      const pv = baseFcf / (1 + discountRate / 100) ** n;
      pvSum += pv;
      if (n < forecastPeriod){
      baseFcf *= (1 + growthRate / 100);}
      
      
    }

    const terminalValue = (baseFcf * (1 + growthRate / 100)) / (discountRate / 100 - terminalRate / 100);
    const pvTerminal = terminalValue / Math.pow(1 + discountRate / 100, forecastPeriod);
    
    const fvMultiple = pvSum + pvTerminal;
    
    const fvInfo = getSpecificArrayObjKeys(coAnnualInfo, [
      dfin.ticker,
      dfin.period,
      dfin.fcf,
      dfin.nonOperationsValue,
      dfin.sharesOs,
    ]);
    // Iterate through the array of objects

    fvInfo.forEach((obj) => {
      let sum = 0;
      for (const key in obj) {
        if (key === dfin.fcf){
            sum += (obj[dfin.fcf] * fvMultiple)
        }
        if (key === dfin.nonOperationsValue){
            sum += obj[dfin.nonOperationsValue]
        }
        if (key === dfin.sharesOs) {
          sum = sum / obj[key];
        }
      }
      // Create a new key "FV Multiple" and assign the sum
      obj["FAIR VALUE"] = sum;
    });

    const maxPeriod = fvInfo.reduce((max, obj) => {
      const currentPeriod = obj.PERIOD;
      return currentPeriod > max ? currentPeriod : max;
    }, -Infinity);

    const currentPeriod = fvInfo.filter(
      (obj) => obj[dfin.period] === maxPeriod
    );

    if (currentPeriod.length > 0) {
      setFairValueMultiple(currentPeriod[0]["FAIR VALUE"].toFixed(2));
      setCurrentPeriod(currentPeriod[0]);
    }

    setFvTable(fvInfo);
  }, [growthRate, discountRate, terminalRate, forecastPeriod, stateStock.annualInfo,companySelected]);
  
  

  useEffect(()=>{
    if (currentPeriod){
        setFcfBeg(currentPeriod?.[dfin.fcf])
    }
  },[currentPeriod])

  
  return (
    <>
      {!fvTable && <LinearProgress />}
      {fvTable && (
        <div
          style={{
            display: "flex",
            gap: "1rem",
            background: "white",
            width:"100%"
          }}
        >
          <div
            style={{
              width: "20%",
              gap: "4rem",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              padding:"0.25rem 1rem",
              border:"1px solid #ddd",
              borderRadius:"5px",
              minWidth:"200px",
            }}
          >
                <h3 style={{fontWeight:"bold",color:"gray"}}>Inputs</h3>
            {/* {fcfBeg && 
            <TextField
            variant='standard'
            id="fcfValue"
            label="Free Cash Flow (Current Annual)"
            type="number"
            value={fcfBeg}
            onChange={(e)=>setFcfBeg(e.target.value)}
            InputLabelProps={{
            shrink: true,
            }}
        />} */}
            <PeriodSlider
              width="100%"
              header={"Growth Rate:"}
              step={0.1}
              min={0}
              max={100}
              defaultValue={growthRate}
              period="%"
              handleInputChange={handleGrowth}
              onChange={handleGrowth}
            />
            <PeriodSlider
              width="100%"
              header={"Discount Rate:"}
              step={0.1}
              min={0}
              max={100}
              defaultValue={discountRate}
              period="%"
              handleInputChange={handleDiscount}
              onChange={handleDiscount}
            />
            <PeriodSlider
              width="100%"
              header={"Terminal Growth Rate:"}
              step={0.1}
              min={0}
              max={10}
              defaultValue={terminalRate}
              period="%"
              handleInputChange={handleTerminal}
              onChange={handleTerminal}
            />
            <PeriodSlider
              width="100%"
              header={"Forecast Period:"}
              min={0}
              max={100}
              defaultValue={forecastPeriod}
              period="Years"
              handleInputChange={handleForecast}
              onChange={handleForecast}
            />
          </div>

          <div style={{ flex:1, height: "100%",padding:"0.25rem",
              border:"1px solid #ddd",
              borderRadius:"5px",minWidth:0}}>
            <div style={{display:"flex",gap:"2rem",padding:"0 1rem",width:"100%",boxSizing:"border-box",flexWrap:"wrap"}}>
            <h3 style={{ fontWeight: "bold"}}>
              Fair Value<br/>{fairValueMultiple}
            </h3>
            <h3 style={{ fontWeight: "bold"}}>
               Current Price<br/>
               {numberDecimal(price?.close)}
            </h3>
            <h3 style={{ fontWeight: "bold"}}>
                Margin of Safety<br/>
               <span style={{color:(fairValueMultiple - price?.close)/fairValueMultiple * 100 > 0?"green":"red"}}> {numberDecimal((fairValueMultiple - price?.close)/fairValueMultiple * 100)}%</span>
            </h3>

            </div>
            <PriceValueCustom data={fvTable} />
          </div>
        </div>
      )}

<div style={{width:"100%",marginTop:"1rem",border:"1px solid #ddd",borderRadius:"4px",boxSizing:"border-box",padding:"0.25rem"}}>
<h3 style={{color:"gray",fontWeight:"bold",paddingBottom:"1rem"}}>Detailed Calculation</h3>
{fcfBeg && <ForecastTable
    fcfBeg={fcfBeg} // Extracting Free Cash Flow data from fvTable
    discountRate={discountRate}
    growthRate={growthRate}
    forecastPeriod={forecastPeriod}
    terminalRate={terminalRate}
    sharesOutstanding={currentPeriod?.[dfin.sharesOs]}
    nonOperatingValue={currentPeriod?.[dfin.nonOperationsValue]}
  />}
  </div>
    </>
  );
}

//   debt=dfF['LONG TERM DEBT'] + dfF['SHORT LONG TERM DEBT']+dfF['CAPITAL LEASE OBLIGATIONS']
//   dfF['dfin.nonOperationsValue'] = dfF['CASH'] + dfF['SHORT TERM INVESTMENTS'] + dfF['LONG TERM INVESTMENTS'] - (dfF['MINORITY INTEREST']*-1) - debt
//   dfF['FAIR VALUE (30)'] = ((dfF['FREE CASH FLOW']*30)+(dfF['dfin.nonOperationsValue']))/dfF['SHARES']
//   dfF['FAIR VALUE (15)'] =((dfF['FREE CASH FLOW']*15)+(dfF['dfin.nonOperationsValue']))/dfF['SHARES']
//   dfF['FAIR VALUE (45)'] =((dfF['FREE CASH FLOW']*45)+(dfF['dfin.nonOperationsValue']))/dfF['SHARES']
//   dfF["Effective Interest Rate"] = dfF["INTEREST EXPENSE"]/(debt)
//   dfF["Effective Tax Rate"] =dfF["INCOME TAX EXPENSE"]/dfF["INCOME BEFORE TAX"]
//   dfF["DEBT %"] = debt/(debt+dfF["TOTAL STOCKHOLDER EQUITY"])
//   dfF["Equity %"] = 1 - dfF["DEBT %"]

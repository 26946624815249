import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import { BASE_URL } from 'apiConfig';

export default function useCompanyVsIndustry({tickerList}) {
    const [statsVsIndustry,setStatsVsIndustry] = useState([])
    const [loading,setLoading] = useState(true)
    const prevTickerList = useRef([]);

    useEffect(() => {
      if (JSON.stringify(prevTickerList.current) !== JSON.stringify(tickerList)) {
        getData(tickerList);
        prevTickerList.current = tickerList;
      }
    }, [tickerList]);
  

    async function getData(tickerList) {
        setLoading(true)
        try {
          const response = await axios.get(`${BASE_URL}/stocksIndustryMetric/`, {
            params: {
              ticker: JSON.stringify(tickerList),
            },
          });
      
          const data = response.data;
          setStatsVsIndustry(data)
         
        } catch (error) {
          console.error(error);
        } finally {
            setLoading(false)
        //   dispatchStock({"loadingFinancials":false})
        }
      }
  return {statsVsIndustry,loading}
}

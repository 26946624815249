import { useAppInfo } from 'AppState'
import { dfin } from 'content/constants'
import React from 'react'

export default function LogoTicker({tickerSel,fontSize="1rem",width="25px",height="25px",minWidth="25px"}) {
  const {stateTicker} = useAppInfo()
  const {tickerInfo} = stateTicker
  const tickerLogoUrl = tickerInfo.filter(obj=>obj[dfin.ticker]===tickerSel)[0]?.[dfin.coLogo]
  return (
    <>
    {tickerLogoUrl && <img src={tickerLogoUrl} 
    style={{objectFit:"contain",height:width,width:height,minWidth:minWidth}}/>}
    {!tickerLogoUrl && 
    <div style={{minWidth:minWidth,fontWeight:"bold",width:width,
    fontSize:fontSize,
    height:height,padding:"0.2rem",borderRadius:"50%",backgroundColor:"#e0e0e0",
    display:"flex",alignItems:"center",justifyContent:"center",boxSizing:"border-box"}}>
    {tickerSel && tickerSel.split()[0].charAt(0)}
      </div>}
    </>
    
  )
}

import React, { useEffect, useState } from 'react'
import PeriodSlider from 'components/Inputs/PeriodSlider'
import TableGen from 'components/Table/TableGen';
import { useAppInfo } from 'AppState';
import { dfin } from 'content/constants';
import { getSpecificArrayObjKeys } from 'utils/utilGeneral';
import { Alert, TextField } from '@mui/material';
import useRealTimePrice from 'data/eod/useRealTimePrice';
import ColorBar from 'components/Table/ColorBar';

export default function FcfScenario() {
        const {stateStock,stateTicker} = useAppInfo()
        const companySelected = stateStock.companySelected
        const { price } = useRealTimePrice({ tickerSel: stateTicker.tickerStockCode });
        const [fvInfo,setFvInfo] = useState()
        const [currentPeriod,setCurrentPeriod] = useState()
        const [fcfBeg,setFcfBeg] = useState()
        const [sharesOs,setSharesOs] = useState()
        const [period,setPeriod] = useState(10);
        const [tgr,setTgr] = useState(1)
        const [filteredArray,setFilteredArray] = useState([])
        const [grs,setGrs] = useState(1)
        const [gre,setGre] = useState(10)
        const [drs,setDrs] = useState(5)
        const [dre,setDre] = useState(15)
        
        
        useEffect(()=>{
          const coAnnualInfo = stateStock.annualInfo.filter((obj) => obj[dfin.ticker] === companySelected);
          const fvInfo = getSpecificArrayObjKeys(coAnnualInfo,[dfin.ticker,
            dfin.period,dfin.fcf,dfin.nonOperationsValue,dfin.sharesOs])
            const maxPeriod = fvInfo.reduce((max, obj) => {
              const currentPeriod = obj.PERIOD;
              return currentPeriod > max ? currentPeriod : max;
            }, -Infinity);
            
            const currentPeriod = fvInfo.filter((obj)=>obj[dfin.period]===maxPeriod)[0]
          
          setFvInfo(fvInfo)
          setCurrentPeriod(currentPeriod)
          setFcfBeg(currentPeriod?.[dfin.fcf])
          setSharesOs(currentPeriod?.["SHARES"])
        },[companySelected,stateStock.annualInfo])
       
        
        
        useEffect(()=>{
        
        if (fcfBeg){
        let nonOperatingValue = currentPeriod?.[dfin.nonOperationsValue]
        let fdf = FairValueMultiple(fcfBeg,sharesOs,period,tgr,nonOperatingValue)
        const filteredArray = fdf
        .filter((item) => {
          const gro = item.GrowthRate;
          return gro >= grs && gro <= gre;
        })
        .map((item) => {
          const newRow = { GrowthRate: item.GrowthRate };
          for (let dro = drs; dro <= dre; dro++) {
            const key = `DR ${dro}`;
            newRow[key] = Number(item[key]);
          }
          return newRow;
        });

        setFilteredArray(filteredArray)}
        },[tgr,period,grs,drs,fcfBeg,sharesOs])
        
        
        function handlePeriod(e,v){
             setPeriod(v)
        }

        function handleTerminalGr(e,v){
            setTgr(v)
        }
        function handleGr(e,v){
            setGrs(v)
            if (grs<=20){
            setGre(v+10)}
        }
        function handleDr(e,v){
            setDrs(v)
            if (drs<=20){
                setDre(v+10)}
        }
  
 
  return (
    <div style={{height:"100%",width:"100%",background:"white",display:"flex",gap:"1rem",boxSizing:"border-box",marginTop:"1rem"}}>
    <div style={{display:"flex"
    ,flexDirection:"column",gap:"2rem",width:"20%",padding:"0.25rem 1rem",border:"1px solid #ddd",borderRadius:"5px",minWidth:"200px"}}>
    <h3 style={{fontWeight:"bold",color:"gray"}}>Inputs</h3>
    <TextField
    variant='standard'
    id="fcfValue"
    label="Free Cash Flow (Current Annual)"
    type="number"
    value={fcfBeg}
    onChange={(e)=>setFcfBeg(e.target.value)}
    InputLabelProps={{
      shrink: true,
    }}
  />
    <PeriodSlider width="100%" header="Forecast Period(Years): " min={5} max={30} defaultValue={period} onChange={handlePeriod}  period='%'/>
    <PeriodSlider width="100%" header="Terminal Growth Rate: "  min={0} max={10} defaultValue={tgr} onChange={handleTerminalGr}  period='%'/>
    <PeriodSlider  width="100%"header="Growth Rate: " min={0} max={30} defaultValue={grs} onChange={handleGr}  period='%'/>
    <PeriodSlider width="100%" header="Discount Rate: " min={1} max={30} defaultValue={drs} handleInputChange={handleDr} onChange={handleDr} period='%'/>
    </div>
    <div style={{flex:1,border:"1px solid #ddd",borderRadius:"5px",padding:"0.25rem"}}>
    <h3 style={{fontWeight:"bold",color:"gray"}}>Fair Value Sensitivity Table</h3>
    {filteredArray.length>0 && 
    <div>
    <TableGen data={filteredArray} 
    color='redGreen' 
    colorThreshold={price?.close}
    colorGradient='threshold' tableLayout='fixed' colFirst='Growth Rate'/>
    </div>}
    <Alert severity='info'>Color green - indicates Fair Value greater than Price.<br/>
        Color red - indicates Fair Value less than Price</Alert>
    </div>
    {/* <h4>This table considers that the Free Cash at the beginning of period is 1.</h4> */}
    {/* <h4>This Fair Value doesn't include Non Operations Value: Cash + Investments - Debt.</h4> */}
    </div>
    
   


  )
}


function FairValueMultiple(fcfBeg,sharesOs,period,tgr,nonOperatingValue){
  const fdf = []
            for (let gro = 1; gro <= 30; gro++) {
                const rowData = { GrowthRate: gro };
                
                for (let dro = 1; dro <= 30; dro++) {
                    let cellValue = 0;
        
                    for (let i = 1; i <= period; i++) {
                        try {
                            cellValue += Math.pow(1 * (1 + gro / 100), i) / Math.pow(1 + dro / 100, i);
        
                            if (i === period) {
                                const tva = Math.pow(1 * (1 + gro / 100), i) * (1 + gro / 100);
                                const tv = tva / (dro / 100 - tgr);
                                cellValue += tv / Math.pow(1 + dro / 100, i + 1);
                            }
                        } catch (error) {
                            cellValue = 0;
                        }
                    }
                    
                    rowData[`DR ${dro}`] = parseFloat((cellValue * fcfBeg - nonOperatingValue)/sharesOs).toFixed(2);
                }
        
                fdf.push(rowData);
            }
  return fdf

}
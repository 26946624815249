import React from 'react';
import { Skeleton } from '@mui/material';

export default function LoadingSkeletonLine ({length=5,variant="rectangle",width="100%",height="20px"}) {
  return (
    <>
    {
   Array.from({ length: length }).map((_, index) => (
        <Skeleton variant={variant} key={index} width={width} height={height}/>))}
    </>
    // <div className={styles.loader}>
    //   <div className={styles.loaderItem}></div>
    //   <div className={styles.loaderItem}></div>
    //   <div className={styles.loaderItem}></div>
    // </div>
  );
};

import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import TextInput from 'components/Inputs/TextInput';
import { Button } from '@mui/material';
import BorderBox from 'components/Layout/BorderBox';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoBox from 'components/Layout/InfoBox';


function MonteCarloSimulation() {
    const [initialInvestment, setInitialInvestment] = useState(10000);
    const [annualContribution, setAnnualContribution] = useState(1000);
    const [annualContributionGrowthRate,setAnnualContributionGrowthRate] = useState(0)
    const [years, setYears] = useState(30);
    const [mean, setMean] = useState(7);
    const [stdDeviation, setStdDeviation] = useState(15);
    const [simulations, setSimulations] = useState(1000);
    const [withdrawalAmount, setWithdrawalAmount] = useState(4000);
    const [inflationRate, setInflationRate] = useState(2);
    const [annualProbabilities, setAnnualProbabilities] = useState([]);
    const [simulationDetails, setSimulationDetails] = useState([]);

    const runSimulation = () => {
        const { results, details } = monteCarloSimulation(initialInvestment, annualContribution,annualContributionGrowthRate/100, years, mean / 100, stdDeviation / 100, simulations, withdrawalAmount, inflationRate / 100);
        calculateAnnualProbabilities(results);
        setSimulationDetails(details);
    };

    useEffect(()=>{
        runSimulation()
    },[])

    const calculateAnnualProbabilities = (simResults) => {
        const probabilities = Array.from({ length: years }, () => 0);
        for (let year = 0; year < years; year++) {
            let successCount = simResults.filter(result => result[year] >= 0).length;
            probabilities[year] = (successCount / simulations) * 100;
        }
        setAnnualProbabilities(probabilities);
    };

    const options = {
        title: { text: 'Probability of Meeting Withdrawals Per Year' },
        xAxis: { title: { text: 'Year' }, categories: [...Array(years).keys()].map(x => x + 1) },
        yAxis: { title: { text: 'Probability (%)' } },
        series: [{ name: 'Probability', data: annualProbabilities }],
        credits: { enabled: false }
    };

    return (
        <>
            <div style={{display: "flex", width: "100%",padding: "0.5rem", boxSizing: "border-box", gap: "0.5rem" ,height:"100%"}}>
            <div style={{ width: "20%",minWidth:"20%"}}>
                    <form onSubmit={(e) => { e.preventDefault(); runSimulation(); }}>
                        <InfoBox>
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <TextInput label='Initial Investment' type="number" value={initialInvestment} onChange={v => setInitialInvestment(Number(v))} />
                                <TextInput label='Annual Contribution' type="number" value={annualContribution} onChange={v => setAnnualContribution(Number(v))} />
                                <TextInput label='Annual Contribution Growth Rate' type="number" value={annualContributionGrowthRate} onChange={v => setAnnualContributionGrowthRate(Number(v))} />
                                <TextInput label='Years' type="number" value={years} onChange={v => setYears(Number(v))} />
                                <TextInput label='Mean Return (%)' type="number" value={mean} onChange={v => setMean(Number(v))} />
                                <TextInput label='Volatility (Standard Deviation) %' type="number" value={stdDeviation} onChange={v => setStdDeviation(Number(v))} />
                                <TextInput label='Annual Withdrawal Amount' type="number" value={withdrawalAmount} onChange={v => setWithdrawalAmount(Number(v))} />
                                <TextInput label='Annual Inflation Rate (%)' type="number" value={inflationRate} onChange={v => setInflationRate(Number(v))} />
                                <TextInput label='Number of Simulations' type="number" value={simulations} onChange={v => setSimulations(Number(v))} />
                                <div><Button variant='contained' type="submit">Run Simulation</Button></div>
                            </div>
                        </InfoBox>
                    </form>
                </div>
                <div style={{ flex: 1,maxWidth:"100%",minWidth:0}}>
                    <>
                    <div style={{width:"100%",overflowY:"auto",flexGrow:1,height:"300px"}}>
                        {options && 
                            <HighchartsReact highcharts={Highcharts} options={options} 
                            containerProps={{ style: { height: '100%' , width:"100%"} }}/>
                        }
                        </div>
                    <SimulationTable simulationDetails={simulationDetails} annualProbabilities={annualProbabilities}/>
                    </>

                </div>
              
            </div>
        </>
    );
}

export default MonteCarloSimulation;

function SimulationTable({simulationDetails,annualProbabilities}){
    return (
        <table style={{ width: '100%', marginTop: '20px' ,tableLayout:"fixed"}}>
        <thead>
            <tr>
                <th>Year</th>
                <th>Beginning Balance</th>
                <th>Annual Contribution</th>
                <th>Return</th>
                <th>Withdrawal</th>
                <th>Inflation %</th>
                <th>Ending Balance</th>
                <th>Probability</th>
            </tr>
        </thead>
        <tbody>
            {simulationDetails.map((row, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.beginningBalance.toFixed(2)}</td>
                    <td>{row.contribution.toFixed(2)}</td>
                    <td>{row.return.toFixed(2)}</td>
                    <td>{row.withdrawal.toFixed(2)}</td>
                    <td>{row.inflation.toFixed(2)}</td>
                    <td>{row.endingBalance.toFixed(2)}</td>
                    <td>{annualProbabilities[index].toFixed(2)}%</td>
                </tr>
            ))}
        </tbody>
    </table>
    )
}

function monteCarloSimulation(initialInvestment, annualContribution,annualContributionGrowthRate, years, mean, stdDeviation, simulations, withdrawalAmount, inflationRate) {
    let results = [];
    let details = Array.from({ length: years }, () => ({
        beginningBalance: [],
        contribution: [],
        return: [],
        withdrawal: [],
        inflation: [],
        endingBalance: []
    }));

    for (let i = 0; i < simulations; i++) {
        let yearlyValues = [initialInvestment];
        let yearDetails = Array.from({ length: years }, () => ({}));

        for (let year = 1; year <= years; year++) {
            let lastValue = yearlyValues[yearlyValues.length - 1];
            let annualReturnRate = Math.random() * 2 * stdDeviation + (mean - stdDeviation);
            let annualReturn = lastValue * annualReturnRate;
            let adjustedWithdrawal = withdrawalAmount * Math.pow(1 + inflationRate, year - 1);
            let adjustedAnnualContribution = annualContribution * Math.pow(1 + annualContributionGrowthRate, year - 1)
            let netValue = lastValue + annualReturn - adjustedWithdrawal + adjustedAnnualContribution;

            // Store year details
            yearDetails[year - 1] = {
                beginningBalance: lastValue,
                contribution: adjustedAnnualContribution,
                return: annualReturn,
                withdrawal: adjustedWithdrawal,
                inflation: inflationRate,
                endingBalance: netValue >= 0 ? netValue : 0 // Ensure the ending balance is not negative for display
            };

            // Push net value or -1 if simulation fails this year
            yearlyValues.push(netValue >= 0 ? netValue : -1);
        }

        results.push(yearlyValues);

        // Aggregate details
        yearDetails.forEach((detail, index) => {
            details[index].beginningBalance.push(detail.beginningBalance);
            details[index].contribution.push(detail.contribution);
            details[index].return.push(detail.return);
            details[index].withdrawal.push(detail.withdrawal);
            details[index].inflation.push(detail.inflation);
            details[index].endingBalance.push(detail.endingBalance);
        });
    }

    // Compute averages of details for each year
    details = details.map(year => ({
        beginningBalance: average(year.beginningBalance),
        contribution: average(year.contribution),
        return: average(year.return),
        withdrawal: average(year.withdrawal),
        inflation: average(year.inflation),
        endingBalance: average(year.endingBalance)
    }));

    return { results, details };
}

// Helper function to calculate average
function average(array) {
    return array.reduce((a, b) => a + b, 0) / array.length;
}

function monteCarloSimulationSimple(initialInvestment, annualContribution,annualContributionGrowthRate, years, mean, stdDeviation, simulations, withdrawalAmount, inflationRate) {
    let results = [];
    for (let i = 0; i < simulations; i++) {
        let yearlyValues = [initialInvestment];
        for (let year = 1; year <= years; year++) {
            let lastValue = yearlyValues[yearlyValues.length - 1];
            let annualReturn = Math.random() * 2 * stdDeviation + (mean - stdDeviation);
            let adjustedWithdrawal = withdrawalAmount * Math.pow(1 + inflationRate, year - 1);
            let adjustedAnnualContribution = annualContribution * Math.pow(1 + annualContributionGrowthRate, year - 1)
            let netValue = lastValue * (1 + annualReturn) - adjustedWithdrawal + adjustedAnnualContribution;
            yearlyValues.push(netValue >= 0 ? netValue : -1); // Mark failed simulations with -1
        }
        results.push(yearlyValues);
    }
    return results;
}

function MonteCarloExplanation() {
    return (
        <Accordion>
             <AccordionSummary expandIcon={<ExpandMoreIcon />}><h2>Monte Carlo Simulation Explained</h2></AccordionSummary>
        
            <AccordionDetails>

            <p>
The Monte Carlo simulation is a computational technique that uses repeated random sampling to estimate the probabilistic outcomes of a process or function. In the context of financial planning, it's particularly useful for understanding the potential future values of investments given various risks and uncertainties. Here's how the simulation works and how each input affects the calculations:</p>
            <h2 style={{fontWeight:"bold"}}>Key Inputs</h2>
            <ul>
                <li>
                    <strong>Initial Investment:</strong> The amount of money initially invested. The future value of the investment grows from this base, with higher initial values typically leading to higher future values, all else being equal.
                </li>
                <li>
                    <strong>Annual Contribution:</strong> The amount added to the investment at the end of each year. Increasing this amount raises the growth potential of the investment by enlarging the capital base that generates returns.
                </li>
                <li>
                    <strong>Years:</strong> The duration over which the investment will compound. A longer period allows more time for the investment to accrue returns, enhancing the effect of compounding.
                </li>
                <li>
                    <strong>Mean Return (%):</strong> The expected average annual return rate of the investment. Higher mean returns forecast greater yearly growth of the investment.
                </li>
                <li>
                    <strong>Standard Deviation (%):</strong> A measure of the investment’s annual return volatility. Higher values indicate greater uncertainty and a wider range of potential yearly outcomes.
                </li>
                <li>
                    <strong>Number of Simulations:</strong> The count of individual simulation runs to perform. More simulations can provide a more statistically robust estimate of potential outcomes.
                </li>
            </ul>

            <h2 style={{fontWeight:"bold"}}>Calculation Process</h2>
            <p>
                Each simulation iteratively calculates the value of the investment year-by-year for the specified duration. For each year, the simulation:
            </p>
            <ol>
                <li>Generates a random annual return based on the normal distribution centered on the mean return with variability defined by the standard deviation.</li>
                <li>Applies this return to the investment's current value to find the new value for the year, after adding the annual contribution.</li>
                <li>Repeats this process each year, accumulating the results across the entire investment period.</li>
                <li>Once all years are processed for a simulation, the final value of the investment is recorded.</li>
            </ol>
            <p>
                After all simulations are completed, the results are analyzed to determine the range of possible outcomes, such as calculating specific percentiles to gauge risk and potential growth.
            </p>
            </AccordionDetails>
        </Accordion>
    );
}

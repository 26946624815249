import React, { useState } from 'react'
import EarningsCalendar from './EarningsCalendar'
import IPO from './IPO'
import DividendCalendar from './DividendCalendar'
import EconomyCalendar from 'analytics/economy/EconomyCalendar'
import MarketNews from './MarketNews'
import MergerAcquisition from './MergerAcquisition'
import ExchangeFilings from './ExchangeFilings'
import SenatorsTrading from './SenatorsTrading'

export default function NewsEventsInfo({menuSel,subMenuSel}) {
  const content = {
    "News":<MarketNews info={subMenuSel}/>,
    "Calendar":<Calendar info={subMenuSel}/>,
    "Merger & Acquisitions":<MergerAcquisition/>,
    "Filings":<ExchangeFilings/>,
    "Trading Activities":<SenatorsTrading/>
  }

  return (
    <>
    {content[menuSel]}
    </>
  )
}

function Calendar({info}){
    const content = {
        "Earnings":<EarningsCalendar/>,
    "IPO":<IPO/>,
    "Dividend":<DividendCalendar/>,
    "Economy":<EconomyCalendar/>,
    }
    return <>
    {content[info]}
    </>
}
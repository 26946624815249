import React, {
  useEffect,
  useState,
} from 'react';

import { Paper } from '@mui/material';

import { useAppInfo } from 'AppState';
import { menuIcons } from 'nav/menuIcons';
import CryptoPicking from 'resource/guidance/Crypto/CryptoPicking';
import ETFPicking from 'resource/guidance/EtfMf/ETFPicking';
import FinancialPlanningGuide
  from 'resource/guidance/FinancialPlanning/FinancialPlanningGuide';
import ForexAnalysis from 'resource/guidance/Forex/ForexAnalysis';
import EconomyVsMarket from 'resource/guidance/Market/EconomyVsMarket';
import MarketAnalysis from 'resource/guidance/Market/MarketAnalysis';
import MarketTimingModel from 'resource/guidance/Market/MarketTimingModel';
import PortfolioBuilderGuide from 'resource/guidance/Portfolio/PortfolioGuidance';
import PortfolioManagementGuide
  from 'resource/guidance/Portfolio/PortfolioManagementGuide';
import RiskManagement from 'resource/guidance/RiskManagement/RiskManagement';
import DiscountedCashFlow from 'resource/guidance/Stocks/DiscountedCashFlow';
import StockPickingBottomUp from 'resource/guidance/Stocks/StockPickingBottomUp';
import LeftSidebarPageMenu from 'sidebar/LeftSidebar/LeftSidebarPageMenu';

export default function Guidance({menuType="All"}) {
  const {stateResource,dispatchResource} = useAppInfo()
  const {guidanceSel,guidanceMenuSel,guideDisplay} = stateResource
  const menuSel = guidanceMenuSel
  const [contentList,setContentList] = useState([])
  
  const menuAll = [{name:"Market Analysis",content:["Market Analysis","Economy Factors vs Markets","Market Timing Model"], icon: "Overview"},
                {name:"Stocks",content:["Stock Analysis - Bottom Up","Stock Valuation - DCF"],},
                {name:"Crypto",content:["Crypto Analysis"]},
                {name:"ETF",content:["ETF Analysis"]},
                {name:"Forex",content:["Forex Analysis"]},  
                {name:"Portfolio",content:["Build Portfolio","Portfolio Management"]},
                {name:"Financial Planning",content:["Financial Planning"]},
                {name:"Risk Management",content:["Risk Management Overview"]},
                ]
  
  let menu = menuAll
  if (menuType === "Ticker"){
    menu = [ {name:"Stocks",content:["Stock Analysis - Bottom Up","Stock Valuation - DCF"],},
    {name:"Crypto",content:["Crypto Analysis"]},
    {name:"ETF",content:["ETF Analysis"]},
    {name:"Forex",content:["Forex Analysis"]}, ]
  }
  if (menuType === "Market"){
    menu = [ {name:"Market Analysis",content:["Market Analysis","Economy Factors vs Markets","Market Timing Model"], icon: "Overview"},]
  }
  if (menuType === "Economy"){
    menu = [ {name:"Economy",content:["Economy Factors vs Markets"], icon: "Overview"},]
  }
  if (menuType === "Financial Planning"){
    menu = [
      {name:"Financial Planning",content:["Financial Planning"]},
      {name:"Portfolio",content:["Build Portfolio","Portfolio Management"]}, 
      {name:"Risk Management",content:["Risk Management Overview"]},
    ]
  }

  useEffect(() => {
    if (menuType === "Ticker") {
      dispatchResource({
        guidanceMenuSel: "Stocks",
        guidanceSel: "Stock Analysis - Bottom Up"
      });
    }
    if (menuType === "Financial Planning") {
      dispatchResource({
        guidanceMenuSel: "Financial Planning",
        guidanceSel: "Financial Planning"
      });
    }
    if (menuType === "Economy") {
      dispatchResource({
        guidanceMenuSel: "Market Analysis",
        guidanceSel: "Economy Factors vs Markets"
      });
    }
    if (menuType === "Market") {
      dispatchResource({
        guidanceMenuSel: "Market Analysis",
        guidanceSel: "Market Analysis"
      });
    }
  }, [menuType]);
                
  const infoList = {
    "Market Analysis":<MarketAnalysis/>,
    "Economy Factors vs Markets":<EconomyVsMarket/>,
    "Stock Analysis - Bottom Up":<StockPickingBottomUp search={true}/>,
    'Crypto Analysis':<CryptoPicking/>,
    'ETF Analysis':<ETFPicking/>,
    'Forex Analysis':<ForexAnalysis/>,
    "Build Portfolio":<PortfolioBuilderGuide/>,
    "Portfolio Management":<PortfolioManagementGuide/>,
    "Financial Planning":<FinancialPlanningGuide/>,
    "Market Timing Model":<MarketTimingModel/>,
    "Risk Management Overview":<RiskManagement/>,
    "Stock Valuation - DCF":<DiscountedCashFlow/>,    
  }

  useEffect(()=>{
    dispatchResource({"guideDisplay":"home"})
  },[])

  useEffect(()=>{
    const contentList = menu.filter(obj=>obj.name === menuSel)[0]?.content
    setContentList(contentList)
  },[])

  function handleMenuSel(n) {
    const contentSel = menu.filter(obj => obj.name === n)[0]?.content[0];
    const contentList = menu.filter(obj => obj.name === n)[0]?.content;
    dispatchResource({
      guidanceMenuSel: n,
      guidanceSel: contentSel
    });
    setContentList(contentList);
  }
  
  function handleContentSel(n, v) {
    const contentList = menu.filter(obj => obj.name === n)[0]?.content;
    dispatchResource({
      guidanceMenuSel: n,
      guidanceSel: v
    });
    setContentList(contentList);
  }
 
  return (
    <div className='containerColumnFlex'>
 
    
    <div style={{flex:1,minWidth:0,display:"flex",height:"100%",width:"100%",gap:"0.3rem"
    ,boxSizing:"border-box",overflow:"auto",position:"relative"}}>
    <div style={{height:"100%",overflow:"auto",flex:1,boxSizing:"border-box",display:"flex",padding:"0.5rem",gap:"0.25rem"}}>
    
    {guideDisplay === "home" && <LeftSidebarPageMenu
    name={<div style={{display:"flex",padding:"0.5rem",alignItems:"center",gap:"0.25rem",color:"var(--selectBlue)"}}>
    {menuIcons.Guide} <h2>Guide</h2>
  </div>}
    menuOptions={menu} menuSel={menuSel} contentSel={guidanceSel} 
    handleContentSel={handleContentSel} handleMenuSel={handleMenuSel}
    />}
    <Paper style={{height:"100%",overflow:"auto",flex:1,boxSizing:"border-box"}}>
    {infoList[guidanceSel]}
    </Paper>
    </div>
    </div>
  
    </div>
  )
}

import React, { useState } from 'react';
import ProductFeatureTemplate from './ProductFeatureTemplate';
import GetStartedButton from './GetStartedButton';
import Animation from '../../animation/Animation';
import Video from 'components/Media/Video';

export default function ProductFeature3() {

  const content = <div style={{width:"100%",padding:"1rem",position:"relative",
  boxSizing:"border-box",display:"flex"}}>

    
    
    <div style={{flex:1,minWidth:0}}>
    <Video src={"\Analytics.mp4"}/>
    
    </div>

    <div style={{display:"flex",flexDirection:"column",gap:"2rem",width:"20%",alignItems:"center"}}>
    <div style={{width:"100%"}}>
    <Animation type='Analytics' loop={true}/>
    </div>
    <GetStartedButton/>
    </div>
    </div>

  return (
    <ProductFeatureTemplate id="pageScroll2" 
    content={content} pageNumber={2}/>
  );
}

import React from 'react'
import useEarningTrends from 'data/eod/useEarningTrends'
import { useAppInfo } from 'AppState'
import TableGen from 'components/Table/TableGen'

export default function CompanyEarningTrends() {
    const {stateStock} = useAppInfo()
    const tickerSel = stateStock.companySelected
   const {earningTrends} = useEarningTrends({tickerSel})
  return (
    <>
    {earningTrends && <TableGen data={earningTrends}/>}
    </>
  )
}

import { useState, useEffect} from "react";
import axios from "axios";
import { BASE_URL } from "apiConfig";

export default function useATR(tickerList) {

const [ATR, setATR] = useState([]);
const [ATRLoading, setMarketInfoLoading] = useState(false);

useEffect(() => {
    getPriceInfo();
}, [tickerList]);

async function getPriceInfo() {
    setMarketInfoLoading(true);
    try {
    const response = await axios(`${BASE_URL}/ATR/`, {
        params: {
        ticker: JSON.stringify(tickerList),
        // window:window,
        // overBought:overBought,
        // overSold:overSold,
        // dateFrequency:dateFrequency,
        },
    });
   
    const data = response.data
    
    setATR(JSON.parse(data))
   
    } catch (error) {
    console.error(error);
    } 
    setMarketInfoLoading(false);
    
}
return {ATR,ATRLoading};
}
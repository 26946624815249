import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useAppInfo } from "AppState";
import { Avatar, ClickAwayListener, Popover, Button, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SupportIcon from '@mui/icons-material/Support';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import styles from "./ProfileSettings.module.css";
import { handleSignOut } from 'auth/useHandleSignout';
import SettingsIcon from '@mui/icons-material/Settings';

export default function ProfileSettings({type="short"}) {
    const { stateApp } = useAppInfo();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { username } = stateApp;

    const handleMenuNav = (v) => {
        navigate(`/${v}`);
        setAnchorEl(null);
    };

    const handleAvatarClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'profile-popover' : undefined;

    const menuOptions = [
        { text: "Home", icon: <HomeIcon /> },
        { text: "Profile", icon: <PersonIcon /> },
        { text: "Support", icon: <SupportIcon /> },
        { text: "Contact", icon: <ContactMailIcon /> }
    ];


    return (
        <>
            <div style={{ position: "relative",width:"100%"}} className="profile" id='userProfile'>
            <Button
            aria-describedby={id}
                    onClick={handleAvatarClick}
                    style={{
                        background: type === "long" && "#3a3a3b",
                        width: "100%",
                        justifyContent: type === "long" ? "flex-start" : "center",
                        color: "white",
                        alignItems: "center",
                        gap: "0.5rem",boxSizing:"border-box",minWidth:"30px"
                    }}
                >
                    <Avatar
                        style={{ height: "30px", width: "30px" }}
                    >
                        {(!stateApp.googlePhoto && !username) && "P"}
                        {(!stateApp.googlePhoto && username) && username?.charAt(0)}
                        {stateApp.googlePhoto && <img src={stateApp.googlePhoto}
                            alt="Profile" style={{ objectFit: "cover" }} width={"100%"} height={"100%"} />}
                    </Avatar>
                    {type === "long" && <span style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{username?.split(" ")[0]}</span>}
                    {type === "long" && <SettingsIcon fontSize='small' style={{ marginLeft: "auto" }} />}
                </Button>
               

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{borderRadius: '15px', padding: '1rem', minWidth: '200px',zIndex:"100000000"}}
                    
                >
                    <div className={styles.profileModal}>
                    <div style={{display:"flex",gap:"1rem",flexWrap:"wrap",borderBottom: "1px solid #ddd", }}>
                    <Avatar
                    onClick={handleAvatarClick}
                    style={{ border: open ? "2px solid #00c3ff" : "2px solid black", height: "30px", width: "30px" }}
                >
                    {(!stateApp.googlePhoto && !username) && "P"}
                    {(!stateApp.googlePhoto && username) && username?.charAt(0)}
                    {stateApp.googlePhoto && <img src={stateApp.googlePhoto} alt="Profile" style={{ objectFit: "cover" }} width={"100%"} height={"100%"} />}
                </Avatar>
                        <Typography variant="h6" style={{ paddingBottom: '0.5rem' }}>
                           {username}
                        </Typography>
                    </div>
                    
                        <List>
                            {menuOptions.map((menu, index) => (
                                <ListItem button key={index} onClick={() => handleMenuNav(menu.text)}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.text} />
                                </ListItem>
                            ))}
                            <Divider />
                            <ListItem button onClick={handleSignOut}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign Out" />
                            </ListItem>
                        </List>
                    </div>
                </Popover>
            </div>
        </>
    );
}
